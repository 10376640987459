import Pagination from "./Pagination";
import { useEffect, useRef, useState } from "react";
import { Button } from "./Button";
import Model from "./Model";
import { Input } from "./Input";
import MasterTitle from "./MasterTitle";
import ads1 from "../Image/ads1.png";
import ads2 from "../Image/ads2.png";
import ads3 from "../Image/ads3.png";
import ads4 from "../Image/ads4.png";
import Records from "./Records";
import FloatNotification from "./FloatNotification";
import Select from "./Select";
import { PlusIcon } from "@heroicons/react/20/solid";
import DeletePopup from "./DeletePopup";
import ActionPopup from "./ActionPopup";
import { Typography } from "@mui/material";
import { Api } from "../Api/Api";
import { config } from "../Api/config";
import { ErrorToast, SuccessToast } from "./toast";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import NoDataFound from "./NoDataFound";
import { getDecryptedDataFromLocalStorage } from "../Api/encryption";

const LiveBanners = (props) => {
  let token = getDecryptedDataFromLocalStorage("token");
  const access = props?.access?.filter((e) => e?.name === "Advertisement")?.[0];
  const [isModel, setIsModel] = useState(false);
  const [modelType, setModelType] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [error, setError] = useState({});
  const cancelButtonRef = useRef(null);
  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const data = [
    { id: 1, name: "Active", statusValue: 1 },
    { id: 2, name: "Inactive", statusValue: 0 },
  ];
  const [isModelLoader, setisModelLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [cardData, setCardData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(dataCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  const [image, setImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const [imageData, setImageData] = useState({
    upload_img_link: "",
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(e.target.files[0]);
    setSelectedFile(file);
  };

  const handleResetImage = () => {
    setImage(null);
    setSelectedFile(null);
    setError({});
    setAddPaperGenerateBanners({ status: "", link: "" });
  };

  const handleChooseFileClick = () => {
    document.getElementById("fileUpload").click();
  };

  const [addPaperGenerateBanners, setAddPaperGenerateBanners] = useState({
    link: "",
    status: "",
  });

  const validation = () => {
    const linkPattern = /^(http|https):\/\/.+$/i;

    let errors = {};
    let formIsValid = true;
    if (!addPaperGenerateBanners?.link) {
      formIsValid = false;
      errors["link"] = "Please enter link.";
    } else if (!linkPattern.test(addPaperGenerateBanners.link.trim())) {
      formIsValid = false;
      errors["link"] = "Please enter a valid HTTP or HTTPS link.";
    }

    if (!addPaperGenerateBanners?.status) {
      formIsValid = false;
      errors["status"] = "Please select status.";
    }
    if (!selectedFile) {
      formIsValid = false;
      errors["image"] = "Please select image.";
    }
    setError(errors);
    return formIsValid;
  };

  const _getById = async (id) => {
    try {
      setisModelLoader(true);
      if (id) {
        const { data } = await Api.getRequest(
          `api/live-advertise/getById/${id || ""}`
        );
        const req = JSON.parse(data);

        if (req?.status === 200) {
          setisModelLoader(false);
          const statusName = req?.data?.status === 1 ? "Active" : "Inactive";
          const statusId = req?.data?.status === 1 ? 1 : 2;
          const statusValue = req?.data?.status === 1 ? "1" : "0";

          setImageData({
            upload_img_link: req?.data?.upload_img_link || "",
          });
          setSelectedFile({
            name: req?.data?.upload_img_link || "",
          });
          setAddPaperGenerateBanners({
            status: {
              id: statusId || "",
              name: statusName || "",
              statusValue: statusValue || "",
            },
            link: req?.data?.link || "",
          });
        } else {
          ErrorToast(req.message);
        }
      }
    } catch (error) {
      ErrorToast(error?.message);
      setisModelLoader(false);
    }
  };

  useEffect(() => {
    if (imageData.upload_img_link) {
      const existingFile = new File([], imageData.upload_img_link, {
        type: "image/jpeg",
      });
      setSelectedFile(existingFile);
    }
  }, [imageData.upload_img_link]);

  useEffect(() => {
    if (selectedId) {
      _getById(selectedId);
    }
  }, [selectedId]);

  const handelOnsubmit = async (e) => {
    e.preventDefault();

    if (validation()) {
      if (modelType === "edit") {
        let imgData = [];
        if (selectedFile?.name !== imageData?.upload_img_link) {
          const formData = new FormData();
          formData.append("image", selectedFile);
          const responseimg = await Api.axiospostRequest(
            "api/upload/single_file",
            formData
          );

          if (responseimg.status === 200) {
            imgData = [responseimg?.data?.data];
          }
        }

        const body = {
          ...(selectedFile?.name !== imageData?.upload_img_link && {
            upload_img_link: selectedFile
              ? imgData.length > 0
                ? imgData[0]
                : ""
              : "",
          }),
          link: addPaperGenerateBanners?.link,
          status: addPaperGenerateBanners?.status?.statusValue,
        };
        try {
          const response = await Api.putRequest(
            `api/live-advertise/update/${selectedId}`,
            body
          );

          if (response?.statusCode === 200) {
            SuccessToast(response?.data?.message);
            getQuestionType(recordsPerPage, currentPage);
            setIsModel(false);
            // handleResetImage();
            setAddPaperGenerateBanners({ status: "", link: "" });
            setSelectedId("");
          } else {
            ErrorToast(response?.data?.message);
          }
        } catch (error) {
          ErrorToast(error?.message);
        }
      } else {
        try {
          setAddPaperGenerateBanners({ status: "", link: "" });
          setSelectedId("");
          let imgData = [];

          if (selectedFile) {
            const formData = new FormData();
            formData.append("image", selectedFile);
            const responseimg = await Api.axiospostRequest(
              "api/upload/single_file",
              formData
            );

            if (responseimg.status === 200) {
              imgData = [responseimg?.data?.data];
            }
          }

          const body = {
            upload_img_link: selectedFile
              ? imgData.length > 0
                ? imgData[0]
                : ""
              : "",
            link: addPaperGenerateBanners?.link,
            status: addPaperGenerateBanners?.status?.statusValue,
            type:"live"
          };
          try {
            const response = await Api.postRequest(
              "api/live-advertise/create",
              body
            );

            const req2 = JSON.parse(response?.data);

            if (req2?.status === 200) {
              SuccessToast("Banners Generated Successfully");
              getQuestionType(recordsPerPage, currentPage);
              handleResetImage();
              // setIsModel(false);
              setAddPaperGenerateBanners({ status: "", link: "" });
              setSelectedId("");
            } else {
              ErrorToast(req2?.message);
            }
          } catch (error) {
            ErrorToast(error?.message);
          }
        } catch (error) {
          ErrorToast(error?.message);
        }
      }
    }
  };

  const deleteProductsData = async () => {
    try {
      const { data } = await Api.deleteRequest(
        `api/live-advertise/delete/${selectedID}`
      );

      const req = JSON.parse(data);
      if (req?.status === 200) {
        setOpenModel(false);
        setSelectedID();
        getQuestionType(recordsPerPage, currentPage);
        SuccessToast("Banner Delete Successfully");
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getQuestionType = async (limit, offset) => {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/live-advertise/?type=live&offset=${offset}&limit=${limit}`
      );
      const req = JSON.parse(data);
      setCardData(req?.data);
      setDataCount(req?.totalCount);
      setIsLoader(false);
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  useEffect(() => {
    getQuestionType(recordsPerPage, currentPage);
  }, [recordsPerPage, currentPage]);

  const handleQuestion = (name, value) => {
    setAddPaperGenerateBanners((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
  };

  return (
    <div>
      <div className="h-full flex items-center justify-center ">
        <div className="h-full flex-1 flex flex-col space-y-5 lg:space-y-0 lg:flex-row lg:space-x-10 w-full sm:px-6 ">
          <div className="flex-1 px-2 sm:px-0 w-full">
            <div>
              <div className="flex justify-between items-center mt-4">
                <div>
                  <MasterTitle
                    title="Live Batch Banners"
                    description="Below are the list of all the Live Batch Banners."
                  />
                </div>
                <div>
                  <Button
                    id=""
                    type="submit"
                    icon={PlusIcon}
                    label={"ADD LIVE BATCH BANNERS"}
                    onClick={() => {
                      setIsModel(true);
                      setModelType("add");
                    }}
                  />

                  <Model
                    title={
                      modelType === "add"
                        ? "Add Live Batch Banners"
                        : "Edit Live Batch Banners"
                    }
                    show={isModel}
                    onCancel={() => {
                      // handleResetImage();
                      setIsModel(false);
                    }}
                    onClose={() => {
                      setIsModel(false);
                      setSelectedId("");
                      handleResetImage();
                      setAddPaperGenerateBanners({ status: "", link: "" });
                    }}
                    onOk={(e) => handelOnsubmit(e)}
                    okText="SUBMIT"
                    cancelText="CLOSE"
                  >
                    <>
                      <form className="mb-2">
                        <label
                          htmlFor="fileUpload"
                          className="mb-3 block text-sm font-medium text-[#252F40] font-poppins"
                        >
                          Upload photo
                        </label>

                        <input
                          type="file"
                          id="fileUpload"
                          class="hidden"
                          onChange={handleImageChange}
                        />

                        <div className="flex items-center ">
                          <button
                            id=""
                            type="button"
                            label={"Choose file"}
                            onClick={handleChooseFileClick}
                            className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                          >
                            Choose file
                          </button>
                          <div className="ml-1 block text-base font-medium text-[#252F40] font-poppins ">
                            {selectedFile
                              ? selectedFile.name
                              : "No file chosen"}
                          </div>
                        </div>
                      </form>
                      <Typography
                        className="my-2"
                        variant="caption"
                        color={"error"}
                      >
                        {!selectedFile ? error?.image : ""}
                      </Typography>
                      <Input
                        label="Link"
                        type="text"
                        autoComplete="given-name"
                        name="Link"
                        required
                        className="mb-2 "
                        value={addPaperGenerateBanners?.link || ""}
                        onChange={(e) => handleQuestion("link", e.target.value)}
                      />
                      <Typography
                        className="my-2"
                        variant="caption"
                        color={"error"}
                      >
                        {!addPaperGenerateBanners?.link ||
                        addPaperGenerateBanners?.link
                          ? error?.link
                          : ""}
                      </Typography>
                      <Select
                        data={data || []}
                        name="status"
                        value={addPaperGenerateBanners?.status || ""}
                        onChange={(e) => handleQuestion("status", e)}
                        className="font-regular mb-2"
                        label="Status"
                        required
                        placeholder="Select Status"
                        type={"PaperGenerateBanners"}
                      />
                      <Typography variant="caption" color={"error"}>
                        {!addPaperGenerateBanners?.status ? error?.status : ""}
                      </Typography>
                    </>
                  </Model>
                </div>
              </div>
              <div className="sm:mb-0 overflow-hidden">
                <div className="flex flex-col mt-4">
                  <section className="bg-white rounded-2xl flex flex-col shadow-sm min-h-[calc(100vh-40px-16px-24px-38px-50px-40px-52px-16px)]">
                    <div className="flex justify-between items-center mt-6 px-8 mb-8">
                      {/* <Input
                id=""
                name="Search"
                type="text"
                autoComplete="given-name"
                required
                className="w-72"
              /> */}
                      <div></div>
                      <div>
                        <Records
                          recordsPerPage={recordsPerPage}
                          setCurrentPage={setCurrentPage}
                          setRecordsPerPage={setRecordsPerPage}
                          dataCount={dataCount}
                        />
                      </div>
                    </div>
                    <div className="h-[calc(100vh-40px-16px-24px-38px-50px-40px-52px-16px-42px-24px-62px-32px)] overflow-y-auto">
                      {!isLoader && cardData?.length <= 0 ? (
                        <NoDataFound />
                      ) : (
                        <div>
                          {isLoader ? (
                            <div
                              className="flex items-center justify-center"
                              style={{
                                height: "25rem",
                              }}
                            >
                              <CircularProgress />
                            </div>
                          ) : (
                            <div className="w-full  grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8 px-8 ">
                              {cardData.map((detail, id) => (
                                <div
                                  key={id}
                                  className="bg-white border border-[#EDEEF0] p-4 rounded-xl tracking-wide max-w-lg"
                                >
                                  <div className="flex flex-col md:flex-row justify-between items-center">
                                    <img
                                      alt=""
                                      src={detail.upload_img_link}
                                      className="max-w-[150px] w-full h-auto flex-shrink-0"
                                    />
                                    <div className="md:ml-5 mt-3 mb-5 flex flex-col md:w-1/2 relative">
                                      <div className="flex flex-col md:flex-row items-center justify-between md:justify-start">
                                        <Link
                                          to={detail.link}
                                          className="text-xs font-regular text-[#7B809A] font-roboto overflow-hidden max-w-[70%] md:max-w-full"
                                        >
                                          {detail.link.length > 50
                                            ? `${detail.link.substring(
                                                0,
                                                50
                                              )}...`
                                            : detail.link}{" "}
                                        </Link>
                                        {(access?.edit || access?.delete) && (
                                          <div className="mt-4 md:mt-0 absolute top-0 right-0">
                                            <ActionPopup
                                              onClick={() => {
                                                setIsModel(true);
                                                setModelType("edit");
                                                setSelectedId(detail?.id);
                                              }}
                                              onDelete={() => {
                                                setOpenModel(true);
                                                setSelectedID(detail.id);
                                              }}
                                              accessEdit={access?.edit}
                                              accessDelete={access?.delete}
                                            />
                                          </div>
                                        )}
                                      </div>
                                      <div className="space-x-3 flex mt-4 text-xs font-roboto">
                                        <p className="text-[#484C64] font-medium">
                                          Status
                                        </p>
                                        <p className="text-[#7B809A] font-regular">
                                          {detail.status}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      recordsPerPage={recordsPerPage}
                      data={cardData}
                      dataCount={dataCount}
                      numbers={numbers}
                      npage={npage}
                      lastIndex={lastIndex}
                      firstIndex={firstIndex}
                    />
                  </section>
                </div>

                {/* Delete */}
                <DeletePopup
                  title={"Delete"}
                  show={openModel}
                  description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
                  onCancel={() => {
                    setOpenModel(false);
                    setSelectedID();
                  }}
                  onClose={() => {
                    setOpenModel(false);
                    setSelectedID();
                  }}
                  onDelete={() => {
                    deleteProductsData();
                    setOpenModel(false);
                    setShowFloatNotification(true);
                  }}
                  cancelButtonRef={cancelButtonRef}
                  okText="Delete"
                  cancelText="Cancel"
                />

                {/* Delete */}
                <FloatNotification
                  show={showFloatNotification}
                  title={"Successfully deleted!"}
                  type="success"
                  description={"Data Deleted."}
                  onClick={() => {
                    setShowFloatNotification(false);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveBanners;
