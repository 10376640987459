import React, { useEffect, useRef, useState } from "react";
import { Input } from "./Input";
import Model from "./Model";
import { Button } from "./Button";
import MasterTitle from "./MasterTitle";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import Pagination from "./Pagination";
import Records from "./Records";
import { SortData } from "./SortData";
import FloatNotification from "./FloatNotification";
import { PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import Select from "./Select";
import Breadcrumbs from "./Breadcrumbs";
import DeletePopup from "./DeletePopup";
import ActionPopup from "./ActionPopup";
import { Typography, MenuItem, FormControl, InputLabel } from "@mui/material";
import { ErrorToast, SuccessToast } from "./toast";
import { Api } from "../Api/Api";
import SelectType from "./SelectType";
import { Listbox } from "@headlessui/react";
import CircularProgress from "@mui/material/CircularProgress";
import NoDataFound from "./NoDataFound";
import { getDecryptedDataFromLocalStorage } from "../Api/encryption";
import ActionButton from "./ActionButton";
import { Checkbox } from "./Checkbox";

const Topic = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Topic")?.[0];
  const [subjectData, setSubjectData] = useState([]);
  const [standardData, setStandardData] = useState([]);
  const [semesterData, setSemesterData] = useState([]);
  const [chapterData, setChapterData] = useState([]);
  const [subjectOutData, setSubjectOutData] = useState([]);
  const [standardOutData, setStandardOutData] = useState([]);
  const [semesterOutData, setSemesterOutData] = useState([]);
  const [chapterOutData, setChapterOutData] = useState([]);
  const [qTypeData, setQTypeData] = useState([]);
  const [isModel, setIsModel] = useState(false);
  const [modelType, setModelType] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const cancelButtonRef = useRef(null);
  const [isModelLoader, setisModelLoader] = useState(false);
  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const [filter, setFilter] = useState({
    board: "",
    medium: "",
    standard: "",
    semester: "",
    subject: "",
    chapter: "",
  });
  const [search, setSearch] = useState("");
  const [isResetFilter, setIsResetFilter] = useState(false);

  const [titleData, setTitleData] = useState([
    {
      title: "SR No.",
      sort: 1,
      fieldName: "type",
    },
    {
      title: "BOARD",
      sort: 1,
      fieldName: "board",
    },
    {
      title: "MEDIUM",
      sort: 1,
      fieldName: "medium",
    },
    {
      title: "STANDARD",
      sort: 1,
      fieldName: "standard",
    },
    {
      title: "SEMESTER",
      sort: 1,
      fieldName: "semester",
    },
    {
      title: "SUBJECT",
      sort: 1,
      fieldName: "subject",
    },
    {
      title: "CHAPTER",
      sort: 1,
      fieldName: "chapter",
    },
    {
      title: "TOPIC",
      sort: 1,
      fieldName: "topic",
    },
    {
      title: "VIDEO",
      sort: 1,
      fieldName: "video",
    },
    {
      title: "PDF",
      sort: 1,
      fieldName: "pdf",
    },
    {
      title: "OTHER",
      sort: 1,
      fieldName: "other",
    },
    {
      title: access?.edit ? "EDIT" : "",
      sort: 1,
      fieldName: "",
    },
    {
      title: access?.delete ? "DELETE" : "",
      sort: 1,
      fieldName: "",
    },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [selectedId, setSelectedId] = useState(null);
  const [standardDataCount, setStandardDataCount] = useState("");
  const [subjectDataCount, setSubjectDataCount] = useState("");
  const [semesterDataCount, setSemesterDataCount] = useState("");
  const [chapterDataCount, setChapterDataCount] = useState("");
  const [subjectOutDataCount, setSubjectOutDataCount] = useState("");
  const [semesterOutDataCount, setSemesterOutDataCount] = useState("");
  const [chapterOutDataCount, setChapterOutDataCount] = useState("");
  const [SelectTopicId, setSelectTopicId] = useState();
  const [questionData, setQuestionData] = useState([]);
  const [questionCount, setQuestionCount] = useState(0);
  const [selectionDataStandard, setSelectionDataStandard] = useState([]);
  const [selectionDataSubject, setSelectionDataSubject] = useState([]);
  const [selectionDataChapter, setSelectionDataChapter] = useState([]);
  const [selectionDataSemester, setSelectionDataSemester] = useState([]);
  const [subjectDataFilter, setSubjectDataFilter] = useState([]);
  const [standardDataFilter, setStandardDataFilter] = useState([]);
  const [semesterDataFilter, setSemesterDataFilter] = useState([]);
  const [chapterDataFilter, setChapterDataFilter] = useState([]);
  const [TopicData, setTopicData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [topicQuestion, setTopicQuestion] = useState({
    board: "",
    medium: "",
    standard: "",
    semester: "",
    subject: "",
    chapter: "",
    topic: "",
    videoLink: "",
    pdf: "",
    other: "",
    position: "",
  });

  const [limit1, setLimit1] = useState(1);
  const [limit2, setLimit2] = useState(1);
  const [limit3, setLimit3] = useState(1);
  const [limit4, setLimit4] = useState(1);
  const [limit5, setLimit5] = useState(1);
  const [dataCount, setDataCount] = useState(0);
  const [error, setError] = useState({});
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(dataCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [selectedOption, setSelectedOption] = useState("");
  const [mediumData, setMediumData] = useState([]);
  const [boardData, setBoardData] = useState([]);
  const [boardOutData, setBoardOutData] = useState([]);
  const [mediumOutData, setMediumOutData] = useState([]);
  const [mediumDataCount, setMediumDataCount] = useState("");
  const [boardDataCount, setBoardDataCount] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const [vedio, setVedio] = useState([]);
  const [pdf, setPdf] = useState([]);
  const [other, setOther] = useState([]);
  const [deleteType, setDeleteType] = useState("single");
  const pdfFileInputRef = useRef(null);
  const otherFileInputRef = useRef(null);

  const validation = () => {
    let errors = {};
    let formIsValid = true;
    if (!topicQuestion?.board) {
      formIsValid = false;
      errors["board"] = "Please select board.";
    }
    if (!topicQuestion?.medium) {
      formIsValid = false;
      errors["medium"] = "Please select medium.";
    }
    if (!topicQuestion?.standard) {
      formIsValid = false;
      errors["standard"] = "Please select standard.";
    }
    if (!topicQuestion?.semester) {
      formIsValid = false;
      errors["semester"] = "Please select semester.";
    }
    if (!topicQuestion?.subject) {
      formIsValid = false;
      errors["subject"] = "Please select subject.";
    }
    if (!topicQuestion?.chapter) {
      formIsValid = false;
      errors["chapter"] = "Please select chapter.";
    }
    if (!topicQuestion?.topic) {
      formIsValid = false;
      errors["topic"] = "Please enter topic.";
    }

    if (!topicQuestion?.videoLink && !topicQuestion?.pdf && !topicQuestion?.other) {
      formIsValid = false;
      errors["file"] =
        "Please upload & enter at least one file or link (video, pdf, or other).";
    }

    if (!topicQuestion?.position) {
      formIsValid = false;
      errors["position"] = "Please enter position.";
    }
    // if (
    //   selectedOption &&
    //   typeof topicQuestion[selectedOption.toLowerCase()] === "string"
    // ) {
    //   const fileType =
    //     topicQuestion[selectedOption.toLowerCase()].toLowerCase();
    //   if (
    //     (selectedOption === "Video" && !fileType.includes("video")) ||
    //     (selectedOption === "PDF" && !fileType.includes("pdf"))
    //   ) {
    //     formIsValid = false;
    //     errors[
    //       selectedOption.toLowerCase()
    //     ] = `Please upload a valid ${selectedOption} file.`;
    //   } else {
    //     errors[
    //       selectedOption.toLowerCase()
    //     ] = `Please upload  ${selectedOption} file.`;
    //   }
    // }
    setError(errors);
    return formIsValid;
  };

  const handleUploadChange = async (value, e) => {
    const file = e.target.files[0];

    setSelectedOption(value);
    if (!file) {
      setError({
        ...error,
        [selectedOption.toLowerCase()]: "Please select a file.",
      });

      return;
    }
    if (value === "pdf" || value === "other") {
      try {
        const fileName = file.name;
        const fileUrl = file;
        // const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
        setTopicQuestion({ ...topicQuestion, [value]: fileUrl });
        if (value === "pdf") {
          setPdf(fileName);
        }
        if (value === "other") {
          setOther(fileName);
        }
        setSelectedVideo(file);
        setError({ ...error, [selectedOption.toLowerCase()]: "" });
      } catch (error) {
        setError({
          ...error,
          [selectedOption.toLowerCase()]: "Error getting file URL.",
        });
      }
    }
  };

  const removeFile = (name, value) => {
    setTopicQuestion({ ...topicQuestion, [name]: null });
    if (name === "pdf") {
      setPdf(null);
    }
    if (name === "other") {
      setOther(null);
    }
  };

  const _getTopic = async (filter, search, limit, offset) => {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/topics?board=${filter?.board?.id || ""}&medium=${filter?.medium?.id || ""
        }&standard=${filter?.standard?.id || ""}&semester=${filter?.semester?.id || ""
        }&subject=${filter?.subject?.id || ""}&chapter=${filter?.chapter?.id || ""
        }&search=${search || ""}&limit=${limit || 10
        }&offset=${offset || 1}&isApproved=${""}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setTopicData(req?.data);
        setDataCount(req?.totalCount);
        setIsLoader(false);
      } else {
        ErrorToast(req?.message);
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const getSubjectDataFilter = async () => {
    try {
      let body = `?offset=${0}&limit=${0}`;
      const { data } = await Api.getRequest(`api/subject${body}`);
      const req = JSON.parse(data);
      setSubjectDataFilter((prevData) => [...prevData, ...(req?.data || "")]);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getStandardDataFilter = async () => {
    try {
      let body = `?offset=${0}&limit=${0}`;
      const { data } = await Api.getRequest(`api/standard${body}`);
      const req = JSON.parse(data);
      if (req && req.data && Array.isArray(req.data)) {
        setStandardDataFilter((prevData) => [...prevData, ...req?.data]);
      } else {
        setStandardDataFilter(req.data);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSemesterDataFilter = async (l) => {
    try {
      let body = `?offset=${0}&limit=${0}`;
      const { data } = await Api.getRequest(`api/semester${body}`);
      const req = JSON.parse(data);
      if (req && req.data && req.data.rows && Array.isArray(req.data.rows)) {
        setSemesterDataFilter((prevData) => [...prevData, ...req.data.rows]);
      } else {
        setSemesterDataFilter(req.data.rows);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getChapterDataFilter = async () => {
    try {
      let body = `?offset=${0}&limit=${0}`;
      const { data } = await Api.getRequest(`api/chapter${body}`);
      const req = JSON.parse(data);
      if (req && req.data && Array.isArray(req.data)) {
        setChapterDataFilter((prevData) => [...prevData, ...req?.data]);
      } else {
        setChapterDataFilter(req?.data);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  let lastUpdatedField;
  if (
    selectedOption === "video" ||
    selectedOption === "pdf" ||
    selectedOption === "other"
  ) {
    lastUpdatedField = selectedOption;
  }

  const handleClearFileInputs = () => {
    if (pdfFileInputRef.current) {
      pdfFileInputRef.current.value = "";
    }
    if (otherFileInputRef.current) {
      otherFileInputRef.current.value = "";
    }
  };

  const handleAddModelSubmit = async () => {
    let userData = getDecryptedDataFromLocalStorage("userData");
    let data, data1, data2;

    let videoFormData = new FormData();
    let pdfFormData = new FormData();
    let otherFormData = new FormData();
    if (validation()) {
      setIsLoading(true);
      if (modelType === "edit") {
        try {
          if (topicQuestion["videoLink"]) {
            if (typeof topicQuestion?.videoLink === 'string' && topicQuestion["videoLink"].includes("https://")) {
              data = {
                data: topicQuestion["videoLink"],
                success: true
              };
            }
          }
          if (topicQuestion["pdf"]) {
            if ( typeof topicQuestion?.pdf === 'string' &&topicQuestion["pdf"]?.includes("https://")) {
              data1 = {
                data: topicQuestion["pdf"],
                success: true
              };
            } else {
              pdfFormData.append("image", topicQuestion["pdf"]);
              const responsePdf = await Api.axiospostRequest(
                "api/upload/single_file",
                pdfFormData
              );
              data1 = responsePdf.data;
            }
          }
          if (topicQuestion["other"]) {
            if (typeof topicQuestion?.other === 'string' && topicQuestion?.other?.includes("https://")) {
              data2 = {
                data: topicQuestion["other"],
                success: true
              };
            } else {
              otherFormData.append("image", topicQuestion["other"]);
              const responseOther = await Api.axiospostRequest(
                "api/upload/single_file",
                otherFormData
              );
              data2 = responseOther.data;
            }
          }
          if (
            (data?.success && data1?.success && data2?.success) ||
            (data?.success && data1?.success) ||
            (data?.success && data2?.success) ||
            (data1?.success && data2?.success) ||
            data?.success ||
            data1?.success ||
            data2?.success
          ) {
            let body = {
              board: topicQuestion?.board?.id,
              medium: topicQuestion?.medium?.id,
              standard: topicQuestion?.standard?.id,
              semester: topicQuestion?.semester?.id,
              subject: topicQuestion?.subject?.id,
              chapter: topicQuestion?.chapter?.id,
              topics: topicQuestion?.topic,
              video: data?.data || "",
              pdf: data1?.data || "",
              other: data2?.data || "",
              position: topicQuestion?.position,
              userid: userData?.id,
            };
            const res = await Api.putRequest(
              `api/topics/update/${selectedId}`,
              body
            );
            if (res?.data?.status === 200) {
              SuccessToast(res?.data?.message);
              _getTopic(filter, search, recordsPerPage, currentPage);
              setTopicQuestion({
                board: "",
                medium: "",
                standard: "",
                semester: "",
                subject: "",
                chapter: "",
                topic: "",
                videoLink: "",
                pdf: "",
                other: "",
                position: "",
              });
              setTimeout(() => {
                setIsLoading(false);
              }, 2000);
              setIsModel(false);
              setSelectedId("");
            } else {
              ErrorToast("Something Wrong");
            }
          }
        } catch (error) {
          ErrorToast(error);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      } else {
        try {
          if (topicQuestion["videoLink"]) {
            if (typeof topicQuestion?.videoLink === 'string' && topicQuestion["videoLink"].includes("https://")) {
              data = {
                data: topicQuestion["videoLink"],
                success: true
              };
            }
          }
          if (topicQuestion["pdf"]) {
            pdfFormData.append("image", topicQuestion["pdf"]);
            const responsePdf = await Api.axiospostRequest(
              "api/upload/single_file",
              pdfFormData
            );
            data1 = responsePdf.data;
          }
          if (topicQuestion["other"]) {
            otherFormData.append("image", topicQuestion["other"]);
            const responseOther = await Api.axiospostRequest(
              "api/upload/single_file",
              otherFormData
            );
            data2 = responseOther.data;
          }
          if (
            (data?.success && data1?.success && data2?.success) ||
            (data?.success && data1?.success) ||
            (data?.success && data2?.success) ||
            (data1?.success && data2?.success) ||
            data?.success ||
            data1?.success ||
            data2?.success
          ) {
            let body = {
              board: topicQuestion?.board?.id,
              medium: topicQuestion?.medium?.id,
              standard: topicQuestion?.standard?.id,
              semester: topicQuestion?.semester?.id,
              subject: topicQuestion?.subject?.id,
              chapter: topicQuestion?.chapter?.id,
              topics: topicQuestion?.topic,
              video: data?.data,
              pdf: data1?.data,
              other: data2?.data,
              position: topicQuestion?.position,
              userid: userData?.id,
            };

            const response = await Api.postRequest("api/topics/create", body);
            const req = JSON.parse(response.data);
            if (req?.status === 200) {
              SuccessToast(req?.message);
              _getTopic(filter, search, recordsPerPage, currentPage);
              handleClearFileInputs();
              // pdfFileInputRef.current.value = "";
              // otherFileInputRef.current.value = "";

              setTopicQuestion((prevState) => ({
                ...prevState,
                topic: "",
                videoLink: "",
                pdf: "",
                other: "",
                position: "",
              }));
              setTimeout(() => {
                setIsLoading(false);
              }, 2000);
              // setIsModel(false);
              setSelectedId("");
            } else {
              ErrorToast("Something Wrong");
            }
          }
        } catch (error) {
          ErrorToast(error);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const _getById = async (id) => {
    try {
      setisModelLoader(true);
      if (id) {
        const { data } = await Api.getRequest(
          `api/topics/getTopicsByID/${id || ""}`
        );
        const req = JSON.parse(data);
        if (req?.status === 200) {
          setisModelLoader(false);
          const standard = standardDataFilter?.filter(
            (e) => e?.id == req?.data?.standard
          );
          const semester = semesterDataFilter?.filter(
            (e) => e?.id == req?.data?.semester
          );
          const subject = subjectDataFilter?.filter(
            (e) => e?.id == req?.data?.subject
          );
          const chapter = chapterDataFilter?.filter(
            (e) => e?.id == req?.data?.chapter
          );
          setTopicQuestion({
            board: req?.data?.StandardInfo?.boardInfo,
            medium: req?.data?.StandardInfo?.mediumInfo,
            standard: standard?.[0] || "",
            semester: semester?.[0] || "",
            subject: subject?.[0] || "",
            chapter: chapter?.[0] || "",
            topic: req?.data?.topics || "",
            videoLink: req?.data?.video || "",
            pdf: req?.data?.pdf || "",
            other: req?.data?.other || "",
            position: req?.data?.position || "",
          });

          const vedioName = req?.data?.video.substring(
            req?.data?.video.lastIndexOf("/") + 1
          );
          const pdfName = req?.data?.pdf.substring(
            req?.data?.pdf.lastIndexOf("/") + 1
          );
          const otherName = req?.data?.other.substring(
            req?.data?.other.lastIndexOf("/") + 1
          );
          setVedio(vedioName || "");
          setPdf(pdfName || "");
          setOther(otherName || "");
        } else {
          ErrorToast(req.message);
        }
      }
    } catch (error) {
      ErrorToast(error?.message);
      setisModelLoader(false);
    }
  };

  const getFileNameFromURL = (url) => {
    if (typeof url !== "string") {
      console.error("Invalid URL:", url);
      return "";
    }
    const parts = url.split("/");
    // const filename = parts.pop();
    const filename = parts[parts.length - 1];

    return decodeURIComponent(filename);
  };

  // useEffect(() => {
  //   if (topicQuestion?.video || vedio) {
  //     const videoFile = getFileNameFromURL(vedio);
  //     setVedio(videoFile);
  //   } else {
  //     setVedio([]);
  //   }
  //   if (topicQuestion?.pdf || pdf) {
  //     const pdfFile = getFileNameFromURL(pdf);
  //     setPdf(pdfFile);
  //   } else {
  //     setPdf([]);
  //   }
  //   if (topicQuestion?.other || other) {
  //     const otherFile = getFileNameFromURL(other);
  //     setOther(otherFile);
  //   } else {
  //     setOther([]);
  //   }
  // }, [selectedId]);

  const _deleteTopic = async (id) => {
    try {
      let data;
      if (deleteType === "multi") {
        const body = {
          ids: checkedItems,
        };
        const response = await Api.postRequest(`api/topics/delete`, body);
        data = response.data;
      } else {
        const body = {
          ids: [SelectTopicId],
        };
        const response = await Api.postRequest(`api/topics/delete`, body);
        data = response.data;
      }
      // const { data } = await Api.postRequest(
      //   `api/topics/delete/${SelectTopicId}`
      // );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        SuccessToast(req?.message);
        _getTopic(filter, search, recordsPerPage, currentPage);
        setOpenModel(false);
        setSelectTopicId("");
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error);
    }
  };

  const getBoardOutData = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setBoardOutData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getMediumOutData = async () => {
    try {
      let body = `?board=${filter?.board?.id}`;
      const { data } = await Api.getRequest(`api/medium${body}`);
      const req = JSON.parse(data);
      setMediumOutData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getStandardOutData = async () => {
    try {
      let body = `?medium=${filter?.medium?.id}`;
      const { data } = await Api.getRequest(`api/standard${body}`);
      const req = JSON.parse(data);
      setStandardOutData(req?.data);
    } catch (error) {
      ErrorToast(error);
    }
  };

  const getSemesterOutData = async () => {
    try {
      let body = `?standard=${filter?.standard?.id}`;
      const { data } = await Api.getRequest(`api/semester${body}`);
      const req = JSON.parse(data);
      setSemesterOutData(req?.data?.rows);
    } catch (error) {
      ErrorToast(error);
    }
  };

  const getSubjectOutData = async () => {
    try {
      let body = `?standard=${filter?.standard?.id}&semester=${filter?.semester?.id}`;
      const { data } = await Api.getRequest(`api/subject${body}`);
      const req = JSON.parse(data);
      setSubjectOutData(req?.data);
    } catch (error) {
      ErrorToast(error);
    }
  };

  const getChapterOutData = async () => {
    try {
      let body = `?standard=${filter?.standard?.id}&semester=${filter?.semester?.id}&subject=${filter?.subject?.id}`;
      const { data } = await Api.getRequest(`api/chapter${body}`);
      const req = JSON.parse(data);
      setChapterOutData(req?.data);
    } catch (error) {
      ErrorToast(error);
    }
  };

  const handleFilter = (name, value) => {
    setFilter({
      ...filter,
      [name]: value,
    });
    if (name === "board") {
      setFilter((oldValue) => ({
        ...oldValue,
        medium: "",
        standard: "",
        semester: "",
        subject: "",
        chapter: "",
      }));
      setMediumOutData([]);
      setStandardOutData([]);
      setSemesterOutData([]);
      setSubjectOutData([]);
      setChapterOutData([]);
    }
    if (name === "medium") {
      setFilter((oldValue) => ({
        ...oldValue,
        standard: "",
        semester: "",
        subject: "",
        chapter: "",
      }));

      setStandardOutData([]);
      setSemesterOutData([]);
      setSubjectOutData([]);
      setChapterOutData([]);
    }
    if (name === "standard") {
      setFilter((oldValue) => ({
        ...oldValue,
        semester: "",
        subject: "",
        chapter: "",
      }));
      setSemesterOutData([]);
      setSubjectOutData([]);
      setChapterOutData([]);
    }
    if (name === "semester") {
      setFilter((oldValue) => ({
        ...oldValue,
        chapter: "",
        subject: "",
      }));
      setSubjectOutData([]);
      setChapterOutData([]);
    }
    if (name === "subject") {
      setFilter((oldValue) => ({
        ...oldValue,
        chapter: "",
      }));
      setChapterOutData([]);
    }
  };

  useEffect(() => {
    if (filter?.board) {
      getMediumOutData();
    }
  }, [filter?.board]);

  useEffect(() => {
    if (filter?.medium) {
      getStandardOutData();
    }
  }, [filter?.medium]);

  useEffect(() => {
    if (filter?.standard) {
      getSemesterOutData();
    }
  }, [filter?.standard]);

  useEffect(() => {
    if (filter?.semester) {
      getSubjectOutData();
    }
  }, [filter?.semester]);

  useEffect(() => {
    if (filter?.subject) {
      getChapterOutData();
    }
  }, [filter?.subject]);

  const getBoardData = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setBoardData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getMediumData = async () => {
    try {
      let body = `?board=${topicQuestion?.board?.id}`;
      const { data } = await Api.getRequest(`api/medium${body}`);
      const req = JSON.parse(data);
      setMediumData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getStandardData = async () => {
    try {
      let body = `?board=${topicQuestion?.board?.id}&medium=${topicQuestion?.medium?.id}`;
      const { data } = await Api.getRequest(`api/standard${body}`);
      const req = JSON.parse(data);
      setSelectionDataStandard(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSemesterData = async () => {
    try {
      let body = `?standard=${topicQuestion?.standard?.id}`;
      const { data } = await Api.getRequest(`api/semester${body}`);
      const req = JSON.parse(data);
      setSelectionDataSemester(req?.data?.rows);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };
  const getSubjectData = async () => {
    try {
      let body = `?board=${topicQuestion?.board?.id}&medium=${topicQuestion?.medium?.id}&standard=${topicQuestion?.standard?.id}&semester=${topicQuestion?.semester?.id}`;
      const { data } = await Api.getRequest(`api/subject${body}`);
      const req = JSON.parse(data);
      setSelectionDataSubject(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getChapterData = async () => {
    try {
      let body = `?board=${topicQuestion?.board?.id}&medium=${topicQuestion?.medium?.id}&standard=${topicQuestion?.standard?.id}&semester=${topicQuestion?.semester?.id}&subject=${topicQuestion?.subject?.id}`;
      const { data } = await Api.getRequest(`api/chapter${body}`);
      const req = JSON.parse(data);
      setSelectionDataChapter(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const handelModelData = (name, value) => {
    setTopicQuestion((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
    if (name === "board") {
      setTopicQuestion((oldValue) => ({
        ...oldValue,
        medium: "",
        standard: "",
        semester: "",
        subject: "",
        chapter: "",
      }));
      setMediumData([]);
      setSelectionDataStandard([]);
      setSelectionDataSemester([]);
      setSelectionDataSubject([]);
      setSelectionDataChapter([]);
    }
    if (name === "medium") {
      setTopicQuestion((oldValue) => ({
        ...oldValue,
        standard: "",
        semester: "",
        subject: "",
        chapter: "",
      }));
      setSelectionDataStandard([]);
      setSelectionDataSemester([]);
      setSelectionDataSubject([]);
      setSelectionDataChapter([]);
    }
    if (name === "standard") {
      setTopicQuestion((oldValue) => ({
        ...oldValue,
        semester: "",
        subject: "",
        chapter: "",
      }));
      setSelectionDataSemester([]);
      setSelectionDataSubject([]);
      setSelectionDataChapter([]);
    }
    if (name === "semester") {
      setTopicQuestion((oldValue) => ({
        ...oldValue,
        chapter: "",
        subject: "",
      }));
      setSelectionDataSubject([]);
      setSelectionDataChapter([]);
    }
    if (name === "subject") {
      setTopicQuestion((oldValue) => ({
        ...oldValue,
        chapter: "",
      }));
      setSelectionDataChapter([]);
    }
  };

  useEffect(() => {
    if (topicQuestion?.board) {
      getMediumData();
    }
  }, [topicQuestion?.board]);

  useEffect(() => {
    if (topicQuestion?.medium) {
      getStandardData();
    }
  }, [topicQuestion?.medium]);

  useEffect(() => {
    if (topicQuestion?.standard) {
      getSemesterData();
    }
  }, [topicQuestion?.standard]);

  useEffect(() => {
    if (topicQuestion?.semester) {
      getSubjectData();
    }
  }, [topicQuestion?.semester]);

  useEffect(() => {
    if (topicQuestion?.subject) {
      getChapterData();
    }
  }, [topicQuestion?.subject]);

  useEffect(() => {
    getBoardOutData();
    getBoardData();
  }, []);

  useEffect(() => {
    getSubjectDataFilter();
    getStandardDataFilter();
    getSemesterDataFilter();
    getChapterDataFilter();
    if (selectedId) {
      _getById(selectedId);
    }
  }, [selectedId]);

  useEffect(() => {
    _getTopic(filter, search, recordsPerPage, currentPage);
  }, [currentPage, recordsPerPage]);

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) => {
      const isChecked = prevState.includes(id);

      const updatedItems = isChecked
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id];

      return updatedItems;
    });
  };

  const applyFilter = () => {
    _getTopic(filter, search, recordsPerPage, currentPage);
  };

  const resetFilter = () => {
    setIsResetFilter(true);
    setFilter({
      standard: "",
      semester: "",
      subject: "",
      chapter: "",
    });
    setMediumOutData([]);
    setStandardOutData([]);
    setSemesterOutData([]);
    setSubjectOutData([]);
    setChapterOutData([]);
  };

  useEffect(() => {
    if (isResetFilter) {
      _getTopic(filter, search, recordsPerPage, currentPage);
      setIsResetFilter(false);
    }
  }, [isResetFilter]);

  return (
    <div>
      <div className="h-full flex items-center justify-center ">
        <div className="h-full flex-1 flex flex-col space-y-5 lg:space-y-0 lg:flex-row lg:space-x-10 w-full sm:px-6 ">
          <div className="flex-1 px-2 sm:px-0 w-full">
            {/* <div className="flex justify-between items-center mt-4">
              <Breadcrumbs title="/ Topic" />
            </div> */}
            {/* <div className="w-full sm:px-6"> */}
            <div className="w-full mt-1 sm:px-0 flex md:flex-nowrap flex-wrap items-start gap-x-4">
              <div className="w-full">
                <Select
                  data={boardOutData || []}
                  value={filter?.board}
                  onChange={(e) => handleFilter("board", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Board"
                  type="board"
                  getBoard={getBoardOutData}
                />
              </div>
              <div className="w-full">
                <Select
                  data={mediumOutData || []}
                  value={filter?.medium}
                  onChange={(e) => handleFilter("medium", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Medium"
                  type="medium"
                  getMedium={getMediumOutData}
                />
              </div>{" "}
              <div className="w-full">
                <Select
                  data={standardOutData || []}
                  value={filter?.standard}
                  onChange={(e) => handleFilter("standard", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Standard"
                  type={"standard"}
                  getStandard={getStandardOutData}
                />
              </div>
              <div className="w-full">
                <Select
                  data={semesterOutData || []}
                  value={filter?.semester}
                  onChange={(e) => handleFilter("semester", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Semester"
                  type={"semester"}
                  getSemester={getSemesterOutData}
                />
              </div>
              <div className="w-full">
                <Select
                  data={subjectOutData || []}
                  value={filter?.subject}
                  onChange={(e) => handleFilter("subject", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Subject"
                  type={"subject"}
                  getSubject={getSubjectOutData}
                />
              </div>
              <div className="w-full">
                <Select
                  data={chapterOutData || []}
                  value={filter?.chapter}
                  onChange={(e) => handleFilter("chapter", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Chapter"
                  type={"chapter"}
                  getChapter={getChapterOutData}
                />
              </div>
              <div className="mt-3">
                <Button
                  onClick={() => applyFilter()}
                  id=""
                  type="button"
                  label={"SEARCH"}
                />
              </div>
              <div className="mt-3">
                <Button
                  onClick={() => resetFilter()}
                  id=""
                  type="button"
                  label={"RESET"}
                />
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center mt-2">
                <div>
                  <MasterTitle
                    title="Topic"
                    description="Below are the list of all the topic."
                  />
                </div>
                <div>
                  <div style={{ display: "flex", gap: "5px" }}>
                    {access?.delete && (
                      <Button
                        id=""
                        type="submit"
                        icon={TrashIcon}
                        label={"DELETE TOPIC"}
                        onClick={() => {
                          setDeleteType("multi");
                          if (checkedItems.length > 0) {
                            setOpenModel(true);
                          }
                        }}
                      />
                    )}
                    {access?.add && (
                      <Button
                        id=""
                        type="submit"
                        icon={PlusIcon}
                        label={"ADD TOPIC"}
                        onClick={() => {
                          setTopicQuestion({});
                          setIsModel(true);
                          setModelType("add");
                        }}
                      />
                    )}
                  </div>
                  <Model
                    title={modelType === "add" ? "Add Topic" : "Edit Topic"}
                    show={isModel}
                    onCancel={() => {
                      setIsModel(false);
                      // setTopicQuestion({});
                    }}
                    onClose={() => {
                      setIsModel(false);
                      setSelectedId("");
                    }}
                    onOk={() => {
                      handleAddModelSubmit();
                    }}
                    okText="SUBMIT"
                    cancelText="CLOSE"
                    width="max-w-3xl"
                    isLoading={isLoading}
                    disabled={isLoading}
                  >
                    {isModelLoader ? (
                      <tr
                        style={{
                          height: "120px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <td>
                          <CircularProgress />
                        </td>
                      </tr>
                    ) : (
                      <>
                        <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6 mb-3">
                          {/* <div className="w-full"> */}
                          <div className="w-full flex flex-col">
                            <Select
                              value={topicQuestion?.board}
                              // value={standardQuestion?.type?.name}
                              data={boardData || []}
                              onChange={(e) => handelModelData("board", e)}
                              className="font-regular mb-3"
                              label="Board"
                              required
                              placeholder="All Board"
                              type={"board"}
                              getBoard={getBoardData}
                            />
                            <Typography variant="caption" color={"error"}>
                              {!topicQuestion?.board ? error?.board : ""}
                            </Typography>
                          </div>
                          <div className="w-full flex flex-col">
                            <Select
                              // value={standardQuestion?.type?.name}
                              data={mediumData || []}
                              value={topicQuestion?.medium}
                              onChange={(e) => handelModelData("medium", e)}
                              className="font-regular mb-3"
                              label="Medium"
                              required
                              placeholder="All Medium"
                              type={"medium"}
                              getMedium={getMediumData}
                            />
                            <Typography variant="caption" color={"error"}>
                              {!topicQuestion?.medium ? error?.medium : ""}
                            </Typography>
                          </div>
                        </div>
                        <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6 mb-3">
                          <div className="w-full flex flex-col">
                            <Select
                              data={selectionDataStandard || []}
                              className="font-regular mb-3"
                              label="Standard"
                              required
                              placeholder="All Standard"
                              value={topicQuestion.standard || ""}
                              onChange={(e) => handelModelData("standard", e)}
                              type={"standard"}
                              getStandard={getStandardData}
                            />
                            <Typography variant="caption" color={"error"}>
                              {!topicQuestion?.standard ? error?.standard : ""}
                            </Typography>
                          </div>
                          <div className="w-full flex flex-col">
                            <Select
                              data={selectionDataSemester || []}
                              className="font-regular mb-3"
                              label="semester"
                              required
                              placeholder="All semester"
                              value={topicQuestion.semester || ""}
                              onChange={(e) => handelModelData("semester", e)}
                              type={"semester"}
                              getSemester={getSemesterData}
                            />
                            <Typography variant="caption" color={"error"}>
                              {!topicQuestion?.semester ? error?.semester : ""}
                            </Typography>
                          </div>
                        </div>

                        <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                          <div className="w-full flex flex-col">
                            <Select
                              data={selectionDataSubject || []}
                              className="font-regular mb-3"
                              label="subject"
                              required
                              placeholder="All subject"
                              value={topicQuestion.subject || ""}
                              onChange={(e) => handelModelData("subject", e)}
                              type={"subject"}
                              getSubject={getSubjectData}
                            />
                            <Typography variant="caption" color={"error"}>
                              {!topicQuestion?.subject ? error?.subject : ""}
                            </Typography>
                          </div>
                          <div className="w-full flex flex-col">
                            <Select
                              data={selectionDataChapter || []}
                              className="font-regular mb-3"
                              label="chapter"
                              required
                              placeholder="All chapter"
                              value={topicQuestion.chapter || ""}
                              onChange={(e) => handelModelData("chapter", e)}
                              type={"chapter"}
                              getChapter={getChapterData}
                            />
                            <Typography variant="caption" color={"error"}>
                              {!topicQuestion?.chapter ? error?.chapter : ""}
                            </Typography>
                          </div>
                        </div>

                        <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                          <div className="w-full flex flex-col">
                            <Input
                              label="Topic"
                              id=""
                              name="School Type"
                              type="text"
                              autoComplete="given-name"
                              required
                              className="w-full"
                              value={topicQuestion.topic || ""}
                              onChange={(e) =>
                                handelModelData("topic", e.target.value)
                              }
                            />
                            <Typography variant="caption" color={"error"}>
                              {!topicQuestion?.topic ? error?.topic : ""}
                            </Typography>
                          </div>

                          <div className="w-full flex flex-col">
                            <Input
                              label="Position"
                              id="firstname"
                              name="firstname"
                              type="text"
                              autoComplete="given-name"
                              required
                              className="w-full"
                              value={topicQuestion.position || ""}
                              onChange={(e) =>
                                handelModelData("position", e.target.value)
                              }
                            />
                            <Typography variant="caption" color={"error"}>
                              {!topicQuestion?.position ? error?.position : ""}
                            </Typography>
                          </div>
                        </div>
                        <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                          <div className="w-full flex flex-col">
                            <Listbox>
                              <FormControl fullWidth>
                                <Input
                                  label="Upload Video link"
                                  id="firstname"
                                  name="firstname"
                                  type="text"
                                  autoComplete="given-name"
                                  required
                                  className="w-full"
                                  value={topicQuestion.videoLink || ""}
                                  onChange={(e) =>
                                    handelModelData("videoLink", e.target.value)
                                  }
                                />
                                <Typography variant="caption" color={"error"}>
                                  {!topicQuestion?.file ? error?.file : ""}
                                </Typography>
                              </FormControl>
                            </Listbox>
                          </div>
                        </div>
                        <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                          <div className="w-full flex flex-col">
                            <Listbox>
                              <Listbox.Label className="mt-3 block text-sm font-medium text-gray-700">
                                Upload PDF
                              </Listbox.Label>
                              <FormControl fullWidth>
                                <Input
                                  ref={pdfFileInputRef}
                                  size="small"
                                  id={"pdf"}
                                  name={"pdf"}
                                  type="file"
                                  accept={".pdf"}
                                  // value={topicQuestion.pdf || ""}
                                  autoComplete="off"
                                  required
                                  style={{ marginTop: "8px" }}
                                  onChange={(e) => handleUploadChange("pdf", e)}
                                  value={pdf}
                                  removeFile={() => removeFile("pdf", pdf)}
                                />{" "}
                                {/* {modelType !== "add" && (
                                    <Typography style={{ fontSize: "12px" }}>
                                      {getFileNameFromUrl(topicQuestion.pdf) ||
                                        ""}
                                    </Typography>
                                  )} */}
                                <Typography variant="caption" color="error">
                                  {!topicQuestion["file"] ? error["file"] : ""}
                                </Typography>
                              </FormControl>
                            </Listbox>
                          </div>
                        </div>
                        <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                          <div className="w-full flex flex-col">
                            {" "}
                            <Listbox>
                              <Listbox.Label className="mt-3 block text-sm font-medium text-gray-700">
                                Other
                              </Listbox.Label>
                              <FormControl fullWidth>
                                <Input
                                  ref={otherFileInputRef}
                                  size="small"
                                  // disabled={!selectedOption}
                                  id={"other"}
                                  name={"other"}
                                  type="file"
                                  accept={"*"}
                                  autoComplete="off"
                                  required
                                  style={{ marginTop: "8px" }}
                                  onChange={(e) =>
                                    handleUploadChange("other", e)
                                  }
                                  value={other}
                                  removeFile={() => removeFile("other",other)}
                                />
                                {/* {modelType !== "add" && (
                                    <Typography style={{ fontSize: "12px" }}>
                                      {getFileNameFromUrl(
                                        topicQuestion.other
                                      ) || ""}
                                    </Typography>
                                  )} */}
                                <Typography variant="caption" color="error">
                                  {!topicQuestion["file"]
                                    ? error["file"]
                                    : ""}
                                </Typography>
                              </FormControl>
                            </Listbox>
                          </div>
                        </div>
                      </>
                    )}
                  </Model>
                </div>
              </div>
              <div className="sm:mb-0 overflow-hidden">
                <div className="flex flex-col mt-4">
                  <section
                    className="bg-white rounded-2xl flex flex-col shadow-sm "
                    style={{
                      width: "100%",
                      height: "75vh",
                      margin: "0 auto",
                      padding: "20px",
                    }}
                  >
                    {" "}
                    <div className="flex justify-between items-center px-8 mb-3">
                      <div style={{ display: "flex", gap: '5px' }}>
                        <Input
                          id=""
                          name="Search"
                          type="text"
                          placeholder="Search here..."
                          autoComplete="given-name"
                          required
                          className="w-72"
                          onChange={(e) => {
                            setSearch(e?.target?.value);
                            setCurrentPage(1);
                          }}
                          value={search}
                        />
                        <Button
                          onClick={() => applyFilter()}
                          id=""
                          type="button"
                          // label={"SEARCH"}
                          searchicon={true}
                        />
                      </div>
                      <div>
                        <Records
                          recordsPerPage={recordsPerPage}
                          setCurrentPage={setCurrentPage}
                          setRecordsPerPage={setRecordsPerPage}
                          dataCount={dataCount}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        overflowY: "auto",
                        maxHeight: "58vh",
                        height: "60vh",
                        width: "100%",
                      }}
                    >
                      {" "}
                      {!isLoader && TopicData?.length <= 0 ? (
                        <NoDataFound />
                      ) : (
                        <table style={{ width: "100%" }}>
                          <thead
                            style={{
                              position: "sticky",
                              top: 0,
                              backgroundColor: "#f4f7f8",
                              zIndex: 1,
                            }}
                          >
                            <tr
                              style={{
                                borderBottom: "1px solid #dddddd",
                                textAlign: "left",
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "#7B809A",
                                textTransform: "uppercase",
                                backgroundColor: "#f4f7f8",
                              }}
                            >
                              {" "}
                              <th
                                scope="col"
                                style={{ width: "3%", padding: "10px" }}
                              ></th>
                              {titleData.map(
                                (v, ind) =>
                                  v?.title && (
                                    <th
                                      key={ind}
                                      // className="py-4 p-8"
                                      style={{
                                        padding: "12px",
                                        backgroundColor: "#f4f7f8",
                                        position:
                                          v.title === "EDIT" ||
                                            v.title === "DELETE"
                                            ? "sticky"
                                            : "",
                                        right:
                                          v.title === "EDIT"
                                            ? "68px"
                                            : v.title === "DELETE"
                                              ? "0"
                                              : "",
                                      }}
                                    >
                                      <div className="flex justify-between">
                                        <span>{v.title}</span>
                                        {v.title !== "EDIT" &&
                                          v.title !== "DELETE" && (
                                            <span>
                                              <ChevronUpDownIcon
                                                onClick={() => {
                                                  let res = SortData(
                                                    TopicData,
                                                    v.fieldName,
                                                    v.sort
                                                  );

                                                  let copyData = [...titleData];
                                                  copyData[ind].sort =
                                                    v.sort > 0 ? -1 : 1;
                                                  setTitleData(copyData);
                                                  setTopicData(res);
                                                }}
                                                className="w-5 h-5"
                                              />
                                            </span>
                                          )}
                                      </div>
                                    </th>
                                  )
                              )}
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200">
                            {isLoader ? (
                              <tr style={{ height: "25rem" }}>
                                <td
                                  colSpan={10}
                                  style={{ textAlign: "center" }}
                                >
                                  <CircularProgress />
                                </td>
                              </tr>
                            ) : (
                              TopicData.map((data, id) => (
                                <tr
                                  key={id}
                                  className="my-4 p-8 text-[#000] font-reguler text-sm font-medium"
                                >
                                  <td className="px-6 py-3 whitespace-no-wrap">
                                    <Checkbox
                                      checked={checkedItems.includes(data.id)}
                                      onChange={() =>
                                        handleCheckboxChange(data.id)
                                      }
                                    />
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                    {data.id}
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                    {data?.boardInfo?.board_name}
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                    {data?.mediumInfo?.name}
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                    {data?.StandardInfo?.name}
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                    {data?.SemesterInfo?.semester}
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                    {data?.SubjectInfo?.subject}
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                    {data?.ChapterInfo?.chapter}
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                    {data.topics}
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                    {data.video}
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                    {data.pdf}
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                    {data.other}
                                  </td>
                                  {access?.edit && (
                                    <td
                                      className="relative whitespace-nowrap  p-8 py-2.5  text-left"
                                      style={{
                                        position: "sticky",
                                        right: "68px",
                                        backgroundColor: "white",
                                      }}
                                    >
                                      <ActionButton
                                        key={data.key}
                                        action="edit"
                                        onClick={() => {
                                          setIsModel(true);
                                          setModelType("edit");
                                          setSelectedId(data.id);
                                        }}
                                      />
                                    </td>
                                  )}
                                  {access?.delete && (
                                    <td
                                      className="relative whitespace-nowrap  p-6 py-2.5  text-left"
                                      style={{
                                        position: "sticky",
                                        right: "0",
                                        backgroundColor: "white",
                                      }}
                                    >
                                      <ActionButton
                                        key={data.key}
                                        action="delete"
                                        onClick={() => {
                                          setDeleteType("single");
                                          setOpenModel(true);
                                          setSelectTopicId(data?.id);
                                        }}
                                      />
                                    </td>
                                  )}
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      recordsPerPage={recordsPerPage}
                      data={TopicData}
                      numbers={numbers}
                      npage={npage}
                      lastIndex={lastIndex}
                      firstIndex={firstIndex}
                      dataCount={dataCount}
                    />
                  </section>
                </div>
                {/* Delete */}

                <DeletePopup
                  title={"Delete"}
                  show={openModel}
                  description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
                  onCancel={() => {
                    setOpenModel(false);
                    setSelectTopicId("");
                  }}
                  onClose={() => {
                    setOpenModel(false);
                    setSelectTopicId("");
                  }}
                  onDelete={() => {
                    _deleteTopic();
                  }}
                  cancelButtonRef={cancelButtonRef}
                  okText="Delete"
                  cancelText="Cancel"
                />

                {/* Delete */}
                <FloatNotification
                  show={showFloatNotification}
                  title={"Successfully deleted!"}
                  type="success"
                  description={"Data Deleted."}
                  onClick={() => {
                    setShowFloatNotification(false);
                  }}
                />
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topic;
