import { StandardActions } from "../action-types/standardActionType";

export const getStandardList = (payload) => {
  return {
    type: StandardActions.GET_STANDARD_DATA,
    payload,
  };
};

export const setStandardList = (payload) => {
  return {
    type: StandardActions.SET_STANDARD_DATA,
    payload,
  };
};
