import Pagination from "./Pagination";
import { useEffect, useRef, useState } from "react";
import {
  ChevronUpDownIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { Button } from "./Button";
import Model from "./Model";
import { Input } from "./Input";
import MasterTitle from "./MasterTitle";
import { Checkbox } from "./Checkbox";
import { SortData } from "./SortData";
import Records from "./Records";
import FloatNotification from "./FloatNotification";
import Select from "./Select";
import { PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import DeletePopup from "./DeletePopup";
import ActionPopup from "./ActionPopup";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast } from "./toast";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import NoDataFound from "./NoDataFound";
import CircularProgress from "@mui/material/CircularProgress";
import ActionButton from "./ActionButton";

const Standard = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Master")?.[0];
  const [modelType, setModelType] = useState("");
  const [isModel, setIsModel] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const cancelButtonRef = useRef(null);
  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [StandardData, setStandardData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [selectedId, setSelectedId] = useState(null);
  const [search, setSearch] = useState("");
  const [typeData, setTypeData] = useState([]);
  const [referenceInputData, setReferenceInputData] = useState({});
  const [shoolTypeData, setSchoolTypeData] = useState([]);
  const [mediumData, setMediumData] = useState([]);
  const [boardData, setBoardData] = useState([]);
  const [standardType, setStandardType] = useState("");
  const [standardName, setStandardName] = useState("");
  const [standardLabel, setStandardLabel] = useState("");
  const [standardPosition, setStandardPosition] = useState("");
  const [standardStatus, setStandardStatus] = useState("");
  const [isApp, setIsApp] = useState("");
  const [SelectStandardId, setSelectStandardId] = useState();
  const [standardQuestion, setStandardQuestion] = useState({
    medium: "",
    board: "",
    name: "",
    position: "",
    status: 0,
    isregistration: 0,
  });
  const [error, setError] = useState({});
  // const [statusRadioButton, setStatusRadioButton] = useState(0)
  const [mediumDataFilter, setMediumDataFilter] = useState([]);
  const [boardDataFilter, setBoardDataFilter] = useState([]);
  const [limit1, setLimit1] = useState(1);
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(dataCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [isLoader, setIsLoader] = useState(false);
  const [isModelLoader, setisModelLoader] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [deleteType, setDeleteType] = useState("single");
  const [isLoading, setIsLoading] = useState(false);
  const [titleData, setTitleData] = useState([
    {
      title: "BOARD",
      sort: 1,
      fieldName: "board",
    },
    {
      title: "MEDIUM",
      sort: 1,
      fieldName: "medium",
    },
    {
      title: "STANDARD",
      sort: 1,
      fieldName: "standard",
    },
    {
      title: "POSITION",
      sort: 1,
      fieldName: "position",
    },
    {
      title: "STATUS",
      sort: 1,
      fieldName: "status",
    },
    {
      title: "BATCH REGISTRATION",
      sort: 1,
      fieldName: "batch_registration",
    },
    {
      title: access?.edit ? "EDIT" : "",
      sort: 1,
      fieldName: "",
    },
    {
      title: access?.delete ? "DELETE" : "",
      sort: 1,
      fieldName: "",
    },
  ]);

  useEffect(() => {
    getStandardData(search, recordsPerPage, currentPage);
  }, [ recordsPerPage, currentPage]);

  const applyFilter = () => {
    getStandardData(search, recordsPerPage, currentPage);
  };

  const getStandardData = async (search, limit, offset) => {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/standard?offset=${offset}&limit=${limit}&search=${search || ""}`
      );
      const req = JSON.parse(data);
      setDataCount(req?.totalCount);
      setStandardData(req?.data);
      setIsLoader(false);
    } catch (error) {
      ErrorToast(error.message);
      setIsLoader(false);
    }
  };

  const getBoardData = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setBoardData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const getMediumData = async () => {
    try {
      let body = `?board=${standardQuestion?.board?.id}`;
      const { data } = await Api.getRequest(`api/medium${body}`);
      const req = JSON.parse(data);
      setMediumData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  useEffect(() => {
    getBoardData(0);
  }, []);

  useEffect(() => {
    if (standardQuestion?.board) {
      getMediumData(0);
    }
  }, [standardQuestion?.board]);

  const handleQuestionType = (name, value) => {
    setStandardQuestion((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
    if (name === "board") {
      setStandardQuestion((oldValue) => ({
        ...oldValue,
        medium: "",
        [name]: value,
      }));
      setMediumData([]);
    }
  };

  const validation = () => {
    let errors = {};
    let formIsValid = true;

    if (!standardQuestion?.medium) {
      formIsValid = false;
      errors["medium"] = "Please select medium.";
    }
    if (!standardQuestion?.board) {
      formIsValid = false;
      errors["board"] = "Please select board.";
    }
    if (!standardQuestion?.name) {
      formIsValid = false;
      errors["name"] = "Please enter name.";
    }
    if (!standardQuestion?.position) {
      formIsValid = false;
      errors["position"] = "Please enter position.";
    }
    if (
      standardQuestion?.status === undefined ||
      standardQuestion?.status === null
    ) {
      formIsValid = false;
      errors["status"] = "Please enter status.";
    }
    setError(errors);
    return formIsValid;
  };

  const handleAddModelSubmit = async () => {
    if (validation()) {
      setIsLoading(true);
      if (modelType === "edit") {
        try {
          let body = {
            medium: standardQuestion?.medium?.id,
            board: standardQuestion?.board?.id,
            name: standardQuestion?.name,
            position: standardQuestion?.position,
            status: standardQuestion?.status,
            isregistration: standardQuestion?.isregistration,
          };
          const data = await Api.putRequest(
            `api/standard/update/${selectedId}`,
            body
          );
          if (data?.data?.status === 200) {
            SuccessToast(data?.data?.message);
            getStandardData(search, recordsPerPage, currentPage);
            setStandardQuestion({
              medium: "",
              board: "",
              name: "",
              position: "",
              status: "",
              isregistration: "",
            });
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
            setIsModel(false);
            setSelectedId("");
            setMediumData([]);
          } else {
            ErrorToast("Something Wrong");
          }
        } catch (error) {
          ErrorToast(error);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      } else {
        try {
          let body = {
            medium: standardQuestion?.medium?.id,
            board: standardQuestion?.board?.id,
            name: standardQuestion?.name,
            position: standardQuestion?.position,
            status: standardQuestion?.status,
          };

          const { data } = await Api.postRequest("api/standard/create", body);
          const req = JSON.parse(data);
          if (req?.status === 200) {
            SuccessToast(req?.message);
            getStandardData(search, recordsPerPage, currentPage);
            setStandardQuestion((prevState) => ({
              ...prevState,
              name: "",
              position: "",
            }));
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
            // setIsModel(false);
            setSelectedId("");
          } else {
            ErrorToast(req?.message);
            // setTimeout(() => {
            //   setIsLoading(false);
            // }, 2000);
          }
        } catch (error) {
          ErrorToast(error);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const _deleteStanderedQuestion = async (id) => {
    try {
      let data;
      if (deleteType === "multi") {
        const body = {
          ids: checkedItems,
        };
        const response = await Api.postRequest(`api/standard/delete`, body);
        data = response.data;
      } else {
        const body = {
          ids: [SelectStandardId],
        };
        const response = await Api.postRequest(`api/standard/delete`, body);
        data = response.data;
      }

      const req = JSON.parse(data);
      if (req?.status === 200) {
        SuccessToast(req?.message);
        getStandardData(search, recordsPerPage, currentPage);
        setOpenModel(false);
        setSelectStandardId("");
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const _getById = async (id) => {
    try {
      setisModelLoader(true);
      if (id) {
        const { data } = await Api.getRequest(
          `api/standard/getStandardByID/${id || ""}`
        );
        const req = JSON.parse(data);
        if (req?.status === 200) {
          setisModelLoader(false);
          setStandardQuestion({
            board: req?.data?.boardInfo,
            medium: req?.data?.mediumInfo,
            name: req?.data?.name || "",
            position: req?.data?.position || "",
            status: req?.data?.status || 0,
            isregistration: req?.data?.isregistration || 0,
          });
        } else {
          ErrorToast(req.message);
        }
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const resetStandard = () => {
    setError({});
    setIsModel(false);
    setMediumData([]);
  };

  useEffect(() => {
    if (selectedId) {
      _getById(selectedId);
    }
  }, [selectedId]);

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) => {
      const isChecked = prevState.includes(id);

      const updatedItems = isChecked
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id];

      return updatedItems;
    });
  };

  return (
    <div>
      <div className="flex justify-between items-center mt-4">
        <div>
          <MasterTitle
            title="Standard"
            description="Below are all the list of standards."
          />
        </div>

        <div>
          <div style={{ display: "flex", gap: "5px" }}>
            {access?.delete && (
              <Button
                id=""
                type="submit"
                icon={TrashIcon}
                label={"DELETE STANDARD"}
                onClick={() => {
                  setDeleteType("multi");
                  if (checkedItems.length > 0) {
                    setOpenModel(true);
                  }
                }}
              />
            )}
            {access?.add && (
              <Button
                id=""
                type="submit"
                icon={PlusIcon}
                label={"ADD STANDARD"}
                onClick={() => {
                  setStandardQuestion({});
                  setIsModel(true);
                  setModelType("add");
                }}
              />
            )}
          </div>
          <Model
            selectedId={selectedId}
            title={modelType === "add" ? "Add Standard" : "Edit Standard"}
            show={isModel}
            onCancel={() => {
              setIsModel(true);
              // setStandardQuestion({});
            }}
            onClose={() => {
              setIsModel(false);
              setMediumData([]);
            }}
            onOk={() => handleAddModelSubmit()}
            okText="SUBMIT"
            cancelText="CLOSE"
            isLoading={isLoading}
            disabled={isLoading}
          >
            {isModelLoader ? (
              <tr
                style={{
                  height: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <td>
                  <CircularProgress />
                </td>
              </tr>
            ) : (
              <>
                <Select
                  value={standardQuestion?.board}
                  // value={standardQuestion?.type?.name}
                  data={boardData || []}
                  onChange={(e) => handleQuestionType("board", e)}
                  className="font-regular mb-3"
                  label="Board"
                  required
                  placeholder="All Board"
                  type={"board"}
                  getBoard={getBoardData}
                // isPage={true}
                />
                <Typography variant="caption" color={"error"}>
                  {!standardQuestion?.board ? error?.board : ""}
                </Typography>

                <Select
                  value={standardQuestion?.medium}
                  data={mediumData || []}
                  onChange={(e) => handleQuestionType("medium", e)}
                  className="font-regular mb-3"
                  label="Medium"
                  required
                  placeholder="All Medium"
                  type={"medium"}
                  getMedium={getMediumData}
                // isPage={true}
                />
                <Typography variant="caption" color={"error"}>
                  {!standardQuestion?.medium ? error?.medium : ""}
                </Typography>

                <Input
                  label="Name"
                  id=""
                  name="School Type"
                  type="text"
                  autoComplete="given-name"
                  required
                  className="w-full mb-3"
                  value={standardQuestion.name || ""}
                  onChange={(e) => handleQuestionType("name", e.target.value)}
                />
                <Typography variant="caption" color={"error"}>
                  {!standardQuestion?.name ? error?.name : ""}
                </Typography>

                <Input
                  label="Position"
                  id="firstname"
                  name="firstname"
                  type="text"
                  autoComplete="given-name"
                  required
                  className="w-full mb-3"
                  value={standardQuestion.position || ""}
                  onChange={(e) =>
                    handleQuestionType("position", e.target.value)
                  }
                />
                <Typography variant="caption" color={"error"}>
                  {!standardQuestion?.position ? error?.position : ""}
                </Typography>
                <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                  <div className="w-full flex flex-col">
                    <FormControl>
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        style={{
                          fontFamily: "poppins",
                          color: "#252F40",
                          opacity: "1",
                        }}
                      >
                        Status
                      </FormLabel>

                      <RadioGroup
                        row
                        required
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={(e) =>
                          handleQuestionType("status", e.target.value)
                        }
                        value={standardQuestion.status}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="Enabled"
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio />}
                          label="Disabled"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Typography variant="caption" color={"error"}>
                      {!standardQuestion?.status ? error?.status : ""}
                    </Typography>
                  </div>
                </div>
                <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                  <div className="w-full flex flex-col">
                    <FormControl>
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        style={{
                          fontFamily: "poppins",
                          color: "#252F40",
                          opacity: "1",
                        }}
                      >
                        Batch Registration
                      </FormLabel>

                      <RadioGroup
                        row
                        required
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={(e) =>
                          handleQuestionType("isregistration", e.target.value)
                        }
                        value={standardQuestion.isregistration}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Typography variant="caption" color={"error"}>
                      {!standardQuestion?.status ? error?.status : ""}
                    </Typography>
                  </div>
                </div>
              </>
            )}
          </Model>
        </div>
      </div>
      <div className="sm:mb-0 overflow-hidden">
        <div className="flex flex-col mt-4">
          <section
            className="bg-white rounded-2xl flex flex-col shadow-sm "
            style={{
              width: "100%",
              height: "75vh",
              margin: "0 auto",
              padding: "20px",
            }}
          >
            {" "}
            <div className="flex justify-between items-center mt-1 px-8 mb-3">
              <div  style={{ display: "flex", gap:'5px' }}>
              <Input
                id=""
                name="Search"
                type="text"
                placeholder="Search here..."
                autoComplete="given-name"
                required
                className="w-72"
                onChange={(e) => {
                  setSearch(e?.target?.value);
                  setCurrentPage(1);
                }}
                value={search}
              />
              <Button
                  onClick={() => applyFilter()}
                  id=""
                  type="button"
                  // label={"SEARCH"}
                  searchicon={true}
                />
              </div>
              <div>
                <Records
                  recordsPerPage={recordsPerPage}
                  setRecordsPerPage={setRecordsPerPage}
                  setCurrentPage={setCurrentPage}
                  dataCount={dataCount}
                />
              </div>
            </div>
            <div
              style={{
                overflowY: "auto",
                maxHeight: "58vh",
                height: "60vh",
                width: "100%",
              }}
            >
              {!isLoader && StandardData?.length <= 0 ? (
                <NoDataFound />
              ) : (
                <table style={{ width: "100%" }}>
                  <thead
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#f4f7f8",
                      zIndex: 1,
                    }}
                  >
                    <tr
                      style={{
                        borderBottom: "1px solid #dddddd",
                        textAlign: "left",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#7B809A",
                        textTransform: "uppercase",
                        backgroundColor: "#f4f7f8",
                      }}
                    >
                      {" "}
                      <th
                        scope="col"
                        style={{ width: "3%", padding: "10px" }}
                      ></th>
                      {titleData.map(
                        (v, ind) =>
                          v.title && (
                            <th
                              key={ind}
                              // className="py-2 px-8"
                              style={{
                                padding: "12px",
                                backgroundColor: "#f4f7f8",
                                position:
                                  v.title === "EDIT" || v.title === "DELETE"
                                    ? "sticky"
                                    : "",
                                right:
                                  v.title === "EDIT"
                                    ? "60px"
                                    : v.title === "DELETE"
                                      ? "0"
                                      : "",
                              }}
                            >
                              <div className="flex justify-between">
                                <span>{v.title}</span>
                                {v.title !== "EDIT" && v.title !== "DELETE" && (
                                  <span>
                                    <ChevronUpDownIcon
                                      onClick={() => {
                                        let res = SortData(
                                          StandardData,
                                          v.fieldName,
                                          v.sort
                                        );

                                        let copyData = [...titleData];
                                        copyData[ind].sort =
                                          v.sort > 0 ? -1 : 1;
                                        setTitleData(copyData);
                                        setStandardData(res);
                                      }}
                                      className="w-5 h-5"
                                    />
                                  </span>
                                )}
                              </div>
                            </th>
                          )
                      )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {isLoader ? (
                      <tr style={{ height: "25rem" }}>
                        <td colSpan={7} style={{ textAlign: "center" }}>
                          <CircularProgress />
                        </td>
                      </tr>
                    ) : (
                      StandardData?.map((data) => (
                        <tr
                          key={data.key}
                          className="my-4 p-8 text-[#000] font-sans text-base font-medium"
                        >
                          <td className="px-6 py-3 whitespace-no-wrap">
                            {/* <Checkbox type="checkbox" /> */}
                            <Checkbox
                              checked={checkedItems.includes(data.id)}
                              onChange={() => handleCheckboxChange(data.id)}
                            />
                          </td>
                          <td className="lg:table-cell whitespace-nowrap py-2.5 px-8">
                            {data?.boardInfo?.board_name}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap py-2.5 px-8">
                            {data?.mediumInfo?.name}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5">
                            {data?.name}
                          </td>
                          {/* <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.label}
                          </td> */}
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.position}
                          </td>

                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data.status === 1 ? (
                              <div className="inline-flex items-center rounded-full text-sm font-semibold leading-5 text-[#7B809A]  space-x-2">
                                <CheckCircleIcon className="w-6 text-green-600 " />
                                <span> enabled</span>
                              </div>
                            ) : (
                              <div className="inline-flex items-center rounded-full text-sm font-semibold leading-5 text-[#7B809A]  space-x-2">
                                <XCircleIcon className="w-6 text-red-600" />
                                <span> disabled</span>
                              </div>
                            )}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.isregistration ?? "-"}
                          </td>
                          {access?.edit && (
                            <td
                              className="relative whitespace-nowrap px-6 py-2.5 text-left"
                              style={{
                                position: "sticky",
                                right: "68px",
                                backgroundColor: "white",
                              }}
                            >
                              <ActionButton
                                key={data.key}
                                action="edit"
                                onClick={() => {
                                  setIsModel(true);
                                  setModelType("edit");
                                  setSelectedId(data.id);
                                }}
                              />
                            </td>
                          )}
                          {access?.delete && (
                            <td
                              className="relative whitespace-nowrap px-6 py-2.5 text-left"
                              style={{
                                position: "sticky",
                                right: "0",
                                backgroundColor: "white",
                              }}
                            >
                              <ActionButton
                                key={data.key}
                                action="delete"
                                onClick={() => {
                                  setDeleteType("single");
                                  setOpenModel(true);
                                  setSelectStandardId(data?.id);
                                }}
                              />
                            </td>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              recordsPerPage={recordsPerPage}
              data={StandardData}
              dataCount={dataCount}
              numbers={numbers}
              npage={npage}
              lastIndex={lastIndex}
              firstIndex={firstIndex}
            />
          </section>
        </div>

        {/* Delete */}
        <DeletePopup
          title={"Delete"}
          show={openModel}
          description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
          onCancel={() => {
            setOpenModel(false);
            setSelectStandardId("");
          }}
          onClose={() => {
            setOpenModel(false);
            setSelectStandardId("");
          }}
          onDelete={() => {
            _deleteStanderedQuestion();
          }}
          cancelButtonRef={cancelButtonRef}
          okText="Delete"
          cancelText="Cancel"
        />

        {/* Delete */}
        <FloatNotification
          show={showFloatNotification}
          title={"Successfully deleted!"}
          type="success"
          description={"Data Deleted."}
          onClick={() => {
            setShowFloatNotification(false);
          }}
        />
      </div>
    </div>
  );
};

export default Standard;
