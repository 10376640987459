import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { getDecryptedDataFromLocalStorage } from "../Api/encryption";
import { useDebounce } from "../hooks/use-debounce";
import { CKEditor } from "ckeditor4-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import MathJax from "react-mathjax";
import { useNavigate } from "react-router-dom";
import { PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { ChevronUpDownIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Radio, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import "katex/dist/katex.min.css";
import { Api } from "../Api/Api";
import ActionButton from "./ActionButton";
import Breadcrumbs from "./Breadcrumbs";
import { Button } from "./Button";
import { Checkbox } from "./Checkbox";
import DeletePopup from "./DeletePopup";
import FloatNotification from "./FloatNotification";
import { Input } from "./Input";
import MasterTitle from "./MasterTitle";
import Model from "./Model";
import NoDataFound from "./NoDataFound";
import Pagination from "./Pagination";
import Records from "./Records";
import Select from "./Select";
import { SortData } from "./SortData";
import { ErrorToast, SuccessToast } from "./toast";
import LatexTextRenderer from "./utils/LatexTextRenderer";
import LatexTextRendererMcq from "./utils/LatexTextRendererMcq";
import axios from "axios";
import { editorUrl } from "./constants/CkeditorUrl";

const TrueFalseData = [
  { id: 1, name: "True" },
  { id: 2, name: "False" },
];

const Question = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Question")?.[0];
  const cancelButtonRef = useRef(null);
  let userData = getDecryptedDataFromLocalStorage("userData");
  const [isModel, setIsModel] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [selectedQue, setSelectedQue] = useState([]);
  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [questionData, setQuestionData] = useState([]);
  const [questionCount, setQuestionCount] = useState(0);
  const [search, setSearch] = useState("");
  const [dynamicOffset, setDynamicOffset] = useState(1);
  const debouncedValue = useDebounce(search, 800);
  const [isLoader, setIsLoader] = useState(false);
  const [titleData, setTitleData] = useState([
    {
      title: "NO",
      sort: 1,
      fieldName: "no",
    },
    {
      title: "QUESTION",
      sort: 1,
      fieldName: "question",
    },
    {
      title: "ANSWER",
      sort: 1,
      fieldName: "question",
    },
    {
      title: "EDITED BY",
      sort: 1,
      fieldName: "approvedby",
    },
    {
      title: access?.edit ? "EDIT" : "",
      sort: 1,
      fieldName: "",
    },
    {
      title: access?.delete ? "DELETE" : "",
      sort: 1,
      fieldName: "",
    },
  ]);

  const [filter, setFilter] = useState({
    board: "",
    medium: "",
    standard: "",
    semester: "",
    subject: "",
    chapter: "",
    topic: "",
    questionType: "",
  });

  const [question, setQuestion] = useState({
    board: "",
    medium: "",
    standard: "",
    semester: "",
    subject: "",
    chapter: "",
    questionType: "",
    question: "",
    answer: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    option1_img: "",
    option2_img: "",
    option3_img: "",
    option4_img: "",
    perfact_match: [],
    isCheck: "",
    book_question: "",
    reason: "",
    reference: "",
  });

  const [error, setError] = useState({});
  const [semesterModalData, setSemesterModalData] = useState([]);
  const [semesterModalDataCount, setSemesterModalDataCount] = useState("");
  const [standardModalData, setStandardModalData] = useState([]);
  const [standardModalDataCount, setStandardModalDataCount] = useState("");
  const [subjectModalData, setSubjectModalData] = useState([]);
  const [subjectModalDataCount, setSubjectModalDataCount] = useState("");
  const [chapterModalData, setChapterModalData] = useState([]);
  const [chapterModalDataCount, setChapterModalDataCount] = useState("");
  const [referenceModalDataCount, setReferenceModalDataCount] = useState("");
  const [qTypeModalData, setQTypeModalData] = useState([]);
  const [referenceData, setReferenceData] = useState([]);
  const [qTypeModalDataCount, setQTypeModalDataCount] = useState("");
  const [subjectData, setSubjectData] = useState([]);
  const [standardData, setStandardData] = useState([]);
  const [semesterData, setSemesterData] = useState([]);
  const [chapterData, setChapterData] = useState([]);
  const [qTypeData, setQTypeData] = useState([]);
  const [limit5, setLimit5] = useState(1);
  const [qTypeDataCount, setQTypeDataCount] = useState("");
  const [SelectQuestionId, setSelectQuestionId] = useState();
  const [editorValue, setEditorValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [boardData, setBoardData] = useState([]);
  const [mediumData, setMediumData] = useState([]);
  const [inputs, setInputs] = useState([
    { id: 1, value3: 1, value1: "", value2: "", value4: "" },
  ]);
  const [DiffQuestion, setDiffQuestion] = useState({ question: "" });
  const [multiSelect, setMultiSelect] = useState([
    { id: 1, isCheck: true, value: "", image: "" },
    { id: 2, isCheck: false, value: "", image: "" },
    { id: 3, isCheck: false, value: "", image: "" },
    { id: 4, isCheck: false, value: "", image: "" },
  ]);
  const [singleSelect, setSingleSelect] = useState([
    { id: 1, isCheck: true, value: "", image: "" },
    { id: 2, isCheck: false, value: "", image: "" },
    { id: 3, isCheck: false, value: "", image: "" },
    { id: 4, isCheck: false, value: "", image: "" },
  ]);
  const updatedSingleSelect = [
    { id: 1, isCheck: true, value: "", image: "" },
    { id: 2, isCheck: false, value: "", image: "" },
    { id: 3, isCheck: false, value: "", image: "" },
    { id: 4, isCheck: false, value: "", image: "" },
  ];
  const updatedInputs = [{ id: 1, value3: 1, value1: "", value2: "" }];

  const [images, setImages] = useState([
    { id: 1, image: "" },
    { id: 2, image: "" },
    { id: 3, image: "" },
    { id: 4, image: "" },
  ]);
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(questionCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [checkedItems, setCheckedItems] = useState([]);
  const [deleteType, setDeleteType] = useState("single");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const editorRef = useRef(null);
  const ansRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupValue, setPopupValue] = useState("");
  const inputRef = useRef(null);
  const [isResetFilter, setIsResetFilter] = useState(false);

  useEffect(() => {
    function handleKeyDown(event) {
      if (
        (event.ctrlKey && event.key === "f") ||
        (event.metaKey && event.key === "f") ||
        (event.winKey && event.key === "f")
      ) {
        event.preventDefault();

        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    }

    // Add event listener when component mounts
    document.addEventListener("keydown", handleKeyDown);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isLoader]);

  const handlePopupInputChange = (e) => {
    setPopupValue(e.target.value);
  };

  const handlePopupConfirm = () => {
    setSearch(popupValue);
    setIsPopupOpen(false);
  };

  const parseJsonSafely = (jsonString) => {
    try {
      return JSON.parse(jsonString) || [];
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return [];
    }
  };

  const validQuestionTypes = [
    "જોડકા  (Match the Following)",
    "MCQ - Multiselect",
    "MCQ",
    "ખરા ખોટા (True-False)",
    "Image",
  ];

  const handleFilter = (name, value) => {
    setFilter({
      ...filter,
      [name]: value,
    });

    if (name === "board") {
      setFilter((oldValue) => ({
        ...oldValue,
        medium: "",
        standard: "",
        semester: "",
        subject: "",
        chapter: "",
        topic: "",
      }));
      setMediumData([]);
      setStandardData([]);
      setSemesterData([]);
      setSubjectData([]);
      setChapterData([]);
    }

    if (name === "medium") {
      setFilter((oldValue) => ({
        ...oldValue,
        standard: "",
        semester: "",
        subject: "",
        chapter: "",
        topic: "",
      }));
      setStandardData([]);
      setSemesterData([]);
      setSubjectData([]);
      setChapterData([]);
    }

    if (name === "standard") {
      setFilter((oldValue) => ({
        ...oldValue,
        semester: "",
        subject: "",
        chapter: "",
        topic: "",
      }));
      setSemesterData([]);
      setSubjectData([]);
      setChapterData([]);
    }
    if (name === "semester") {
      setFilter((oldValue) => ({
        ...oldValue,
        chapter: "",
        subject: "",
        topic: "",
      }));
      setSubjectData([]);
      setChapterData([]);
    }

    if (name === "subject") {
      setFilter((oldValue) => ({
        ...oldValue,
        chapter: "",
        topic: "",
      }));
      setChapterData([]);
    }

    if (name === "chapter") {
      setFilter((oldValue) => ({
        ...oldValue,
        topic: "",
        questionType: "",
      }));
      setQTypeData([]);
    }
  };

  useEffect(() => {
    getAllBoards(0);

    if (filter["board"] !== "" || question?.board !== "") {
      getAllMedium(0);
    }

    if (filter["medium"] !== "" || question?.medium !== "") {
      getStandardData(0);
    }

    if (filter["standard"] !== "" || question?.standard !== "") {
      getSemesterData(0);
    }

    if (filter["semester"] !== "" || question?.semester !== "") {
      getSubjectData(0);
    }

    if (filter["subject"] !== "" || question?.subject !== "") {
      getChapterData(0);
      getQTypeData(0);
    }

    if (filter["chapter"] !== "" || question?.chapter !== "") {
      getQTypeData(0);
    }
  }, [
    filter,
    question?.standard,
    question?.semester,
    question?.subject,
    question?.chapter,
  ]);

  const handleQuestion = (name, value) => {
    setQuestion((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));

    if (name === "board") {
      setQuestion((oldValue) => ({
        ...oldValue,
        medium: "",
        standard: "",
        semester: "",
        subject: "",
        chapter: "",
        topic: "",
        reference: "",
      }));
      setStandardModalData([]);
      setSemesterModalData([]);
      setSubjectModalData([]);
      setChapterModalData([]);
      setReferenceData([]);
    }
    if (name === "medium") {
      setQuestion((oldValue) => ({
        ...oldValue,
        standard: "",
        semester: "",
        subject: "",
        chapter: "",
        topic: "",
        reference: "",
      }));
      setStandardModalData([]);
      setSemesterModalData([]);
      setSubjectModalData([]);
      setChapterModalData([]);
      setReferenceData([]);
    }
    if (name === "standard") {
      setQuestion((oldValue) => ({
        ...oldValue,
        semester: "",
        subject: "",
        chapter: "",
        topic: "",
        reference: "",
      }));
      setSemesterModalDataCount("");
      setSemesterModalData([]);
      setSubjectModalData([]);
      setChapterModalData([]);
      setReferenceData([]);
    }
    if (name === "semester") {
      setQuestion((oldValue) => ({
        ...oldValue,
        chapter: "",
        subject: "",
        topic: "",
        reference: "",
      }));
      setSubjectModalDataCount("");
      setSubjectModalData([]);
      setChapterModalData([]);
      setReferenceData([]);
    }
    if (name === "subject") {
      setQuestion((oldValue) => ({
        ...oldValue,
        chapter: "",
        topic: "",
        reference: "",
      }));
      setChapterModalDataCount("");
      setChapterModalData([]);
      setReferenceData([]);
    }

    if (name === "chapter") {
      setQuestion((oldValue) => ({
        ...oldValue,
        questionType: "",
      }));
      setQTypeModalData([]);
    }
  };

  useEffect(() => {
    getBoardData(0);
  }, []);

  useEffect(() => {
    if (question["board"] != "") {
      getMediumData(0);
    }
  }, [question["board"]]);

  useEffect(() => {
    if (question["medium"] != "") {
      getStandardModalData(0);
    }
  }, [question["medium"]]);

  useEffect(() => {
    if (question["standard"] != "") {
      getSemesterModalData(0);
    }
  }, [question["standard"]]);

  useEffect(() => {
    if (question["semester"] != "") {
      getSubjectModalData(0);
    }
  }, [question["semester"]]);

  useEffect(() => {
    if (question["subject"] != "") {
      getChapterModalData(0);
      getRreferenceData(0);
    }
  }, [question["subject"]]);

  useEffect(() => {
    if (question["chapter"] != "") {
      getQTypeModalData(0);
    }
  }, [question["chapter"]]);

  const handleAnswer = (e, name) => {
    const value = e?.editor?.getData();
    setQuestion((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
  };

  const handleInputChange = (id, field, value) => {
    console.log('value=-==-=-=-=-', value)
    setInputs((prevInputs) => {
      const updatedInputs = prevInputs.map((input) =>
        input.id === id ? { ...input, [field]: value } : input
      );

      const lastInput = updatedInputs[updatedInputs.length - 1];
      if (lastInput.value1 !== "" && lastInput.value2 !== "") {
        return [
          ...updatedInputs,
          {
            id: prevInputs.length + 1,
            value3: prevInputs.length + 1,
            value1: "",
            value2: "",
            value4: "",
          },
        ];
      }
      return updatedInputs;
    });
  };

  useEffect(() => {
    const perfact_match = inputs.map((item, index) => ({
      question: item.value1,
      answer: item.value2,
      rightAnswer: item.value4,
    }));

    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      perfact_match: perfact_match,
    }));
  }, [inputs])


  const handleTrueFalseChange = (value) => {
    setQuestion((oldValue) => ({
      ...oldValue,
      ["answer"]: value?.name,
    }));
  };

  const handleAnswerMultiSelect = (name, value, i) => {
    const data = [...multiSelect];
    data[i][name] = value;
    setMultiSelect(data);
    const Option1 = data[0]?.value;
    const Option2 = data[1]?.value;
    const Option3 = data[2]?.value;
    const Option4 = data[3]?.value;
    const Option1_Img = data[0]?.image;
    const Option2_Img = data[1]?.image;
    const Option3_Img = data[2]?.image;
    const Option4_Img = data[3]?.image;
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      option1: Option1,
      option2: Option2,
      option3: Option3,
      option4: Option4,
      option1_img: Option1_Img,
      option2_img: Option2_Img,
      option3_img: Option3_Img,
      option4_img: Option4_Img,
    }));
    const selectedAnswers = data
      .filter((option) => option.isCheck)
      .map((option) => option.value)
      .join(", ");
    const ans = data.map((ans) => {
      if (ans?.isCheck) {
        setQuestion((prevQuestion) => ({
          ...prevQuestion,
          answer: selectedAnswers,
        }));
      }
    });
  };

  const handleAnswerSingleSelect = (name, value, i) => {
    const regex = /^(?![\s]).*$/;
    if (!regex.test(value)) {
      return; // or throw an error, or show an error message
    }

    const data =
      name === "isCheck"
        ? singleSelect?.map((e) => ({ ...e, isCheck: false }))
        : [...singleSelect];
    data[i][name] = value;

    setSingleSelect(data);
    const Option1 = data[0]?.value;
    const Option2 = data[1]?.value;
    const Option3 = data[2]?.value;
    const Option4 = data[3]?.value;
    const Option1_Img = data[0]?.image;
    const Option2_Img = data[1]?.image;
    const Option3_Img = data[2]?.image;
    const Option4_Img = data[3]?.image;
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      option1: Option1,
      option2: Option2,
      option3: Option3,
      option4: Option4,
      option1_img: Option1_Img,
      option2_img: Option2_Img,
      option3_img: Option3_Img,
      option4_img: Option4_Img,
    }));

    const ans = data.map(async (ans, index) => {
      let data1;
      if (ans?.isCheck) {
        if (ans?.image) {
          let ansImage = new FormData();
          if (
            typeof ans.image === "string" &&
            ans?.image?.includes("https://")
          ) {
            data1 = ans?.image;
          } else {
            ansImage.append("image", ans?.image);
            const res1 = await Api.axiospostRequest(
              "api/upload/single_file",
              ansImage
            );
            data1 = res1.data;
          }
        }
        setQuestion((prevQuestion) => ({
          ...prevQuestion,
          answer: ans?.value ? ans?.value : data1?.data,
          [`option${index + 1}_img`]: data1?.data,
        }));
      }
    });
  };

  const validation = () => {
    let errors = {};
    let formIsValid = true;
    if (!question?.board) {
      formIsValid = false;
      errors["board"] = "Please select board.";
    }
    if (!question?.medium) {
      formIsValid = false;
      errors["medium"] = "Please select medium.";
    }
    if (!question?.standard) {
      formIsValid = false;
      errors["standard"] = "Please select standard.";
    }
    if (!question?.semester) {
      formIsValid = false;
      errors["semester"] = "Please select semester.";
    }
    if (!question?.subject) {
      formIsValid = false;
      errors["subject"] = "Please select subject.";
    }
    if (!question?.chapter) {
      formIsValid = false;
      errors["chapter"] = "Please select chapter.";
    }
    if (!question?.questionType) {
      formIsValid = false;
      errors["questionType"] = "Please select questionType.";
    }
    // if (!question.reference) {
    //   formIsValid = false;
    //   errors["reference"] = "Please enter reference";
    // }
    if (question?.questionType?.name?.toLowerCase().includes("match the following")) {
      if (inputs.length === 1) {
        formIsValid = false;
        errors["question"] = "Please enter answer.";
      }
    } else {
      if (!question?.question) {
        formIsValid = false;
        errors["question"] = "Please enter proper question.";
      }
    }
    if (question?.questionType?.name === "તફાવત (Difference)") {
      if (inputs.length === 1) {
        formIsValid = false;
        errors["answer"] = "Please enter answer.";
      }
    } else if (question?.questionType?.name === "MCQ") {
      singleSelect?.map((e) => {
        if (!e?.value && !e?.image) {
          formIsValid = false;
          errors["value"] = "Please enter answer.";
        }
      });
    } else if (question?.questionType?.name === "MCQ - Multiselect") {
      multiSelect?.map((e) => {
        if (!e?.value) {
          formIsValid = false;
          errors["value"] = "Please enter answer.";
        }
      });
    } else {
      if (!question?.answer) {
        formIsValid = false;
        errors["answer"] = "Please enter answer.";
      }
    }
    if (question?.questionType?.name === "Image") {
      images?.map((e) => {
        if (!e?.image) {
          formIsValid = false;
          errors["image"] = "Please select images.";
        }
      });
    }
    setError(errors);
    return formIsValid;
  };

  const _getQuestion = async (filter, limit, offset, search) => {
    try {
      setIsLoader(true);

      const { data } = await Api.getRequest(
        `api/questions?board=${filter?.board?.id || ""}&medium=${filter?.medium?.id || ""
        }&standard=${filter?.standard?.id || ""}&semester=${filter?.semester?.id || ""
        }&subject=${filter?.subject?.id || ""}&chapter=${filter?.chapter?.id || ""
        }&topic=${filter?.topic?.id || ""}&qtype=${filter?.questionType?.id || ""
        }&search=${search || ""}&limit=${limit || 10}&offset=${offset || 1
        }&isApproved=${""}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setQuestionData(req?.data);
        setQuestionCount(req?.totalCount);
        setIsLoader(false);
      } else {
        ErrorToast(req?.message);
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  // const filteredInputs = inputs.filter(
  //   (input) => input.value1 !== "" || input.value2 !== ""
  // );

  const _addQuestion = async () => {
    if (validation()) {
      setIsLoading(true);
      const filteredInputs = inputs.filter(
        (input) => input.value1 !== "" || input.value2 !== ""
      );
      let data1, data2, data3, data4;

      let option1_img = new FormData();
      let option2_img = new FormData();
      let option3_img = new FormData();
      let option4_img = new FormData();

      try {
        const isDataChanged = "";
        //   JSON.stringify(question) === JSON.stringify(prevQuestion.current) &&
        //   JSON.stringify(prevQuestion.current) !== "{}";

        if (question?.option1_img) {
          if (
            typeof question?.option1_img === "string" &&
            question?.option1_img?.includes("https://")
          ) {
            data1 = {
              data: question?.option1_img,
              success: true,
            };
          } else {
            option1_img?.append("image", question?.option1_img);
            const res1 = await Api.axiospostRequest(
              "api/upload/single_file",
              option1_img
            );
            data1 = res1?.data;
          }
        }
        if (question?.option2_img) {
          if (
            typeof question?.option2_img === "string" &&
            question?.option2_img?.includes("https://")
          ) {
            data2 = {
              data: question?.option2_img,
              success: true,
            };
          } else {
            option2_img?.append("image", question?.option2_img);
            const res2 = await Api.axiospostRequest(
              "api/upload/single_file",
              option2_img
            );
            data2 = res2?.data;
          }
        }
        if (question?.option3_img) {
          if (
            typeof question?.option3_img === "string" &&
            question?.option3_img?.includes("https://")
          ) {
            data3 = {
              data: question?.option3_img,
              success: true,
            };
          } else {
            option3_img?.append("image", question?.option3_img);
            const res3 = await Api.axiospostRequest(
              "api/upload/single_file",
              option3_img
            );
            data3 = res3?.data;
          }
        }
        if (question?.option4_img) {
          if (
            typeof question?.option4_img === "string" &&
            question?.option4_img?.includes("https://")
          ) {
            data4 = {
              data: question?.option4_img,
              success: true,
            };
          } else {
            option4_img?.append("image", question?.option4_img);
            const res4 = await Api.axiospostRequest(
              "api/upload/single_file",
              option4_img
            );
            data4 = res4?.data;
          }
        }
        if (
          (data1?.success &&
            data2?.success &&
            data3?.success &&
            data4?.success) ||
          (data1?.success && data2?.success) ||
          (data1?.success && data3?.success) ||
          (data1?.success && data4?.success) ||
          (data2?.success && data3?.success) ||
          (data2?.success && data4?.success) ||
          (data3?.success && data4?.success) ||
          data1?.success ||
          data2?.success ||
          data3?.success ||
          data4?.success ||
          !data1 ||
          !data2 ||
          !data3 ||
          !data4
        ) {
          let body = {
            // board: question?.board?.id,
            // medium: question?.medium?.id,
            standard: question?.standard?.id,
            semester: question?.semester?.id,
            subject: question?.subject?.id,
            chapter: question?.chapter?.id,
            qtype: question?.questionType?.id,
            question: question?.question,
            // question?.questionType?.name === "જોડકા  (Match the Following)"
            //   ? `[${JSON.stringify(filteredInputs)?.slice(1, -1)}]`
            //   : question?.question,
            answer:
              question?.questionType?.name === "ખરા ખોટા (True-False)"
                ? question?.answer
                : question?.questionType?.name === "તફાવત (Difference)"
                  ? `[${JSON.stringify(inputs)?.slice(1, -1)}]`
                  : question?.questionType?.name === "MCQ - Multiselect"
                    ? question?.answer
                    : question?.questionType?.name === "MCQ"
                      ? question?.answer
                      : question?.questionType?.name === "Image"
                        ? `[${JSON.stringify(images)?.slice(1, -1)}]`
                        : question?.answer,
            option1: question?.option1,
            option2: question?.option2,
            option3: question?.option3,
            option4: question?.option4,
            option1_img: data1?.data,
            option2_img: data2?.data,
            option3_img: data3?.data,
            option4_img: data4?.data,
            userid: userData?.id,
            // answerOption: question?.reason,
            perfact_match: question?.perfact_match?.filter(
              (item) => item?.question !== "" || item?.answer !== ""
            ),
            m_equation: question?.isCheck === "m_question" ? 1 : 0,
            book_question: (question?.book_question || "0").toString(),
            html_question: question?.isCheck === "html_tag" ? 1 : 0,
            mcq_solution: (question?.reference?.id || "").toString(),
            isNew: true,
          };
          // if (isDataChanged) {
          const { data } = await Api.postRequest(`api/questions/create`, body);
          const req = JSON.parse(data);
          if (req?.status === 200) {
            SuccessToast(req?.message);
            _getQuestion(filter, recordsPerPage, currentPage, search);
            setQuestion((prevState) => ({ ...prevState, question: "" }));
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
            setMultiSelect(updatedSingleSelect);
            setSingleSelect(updatedSingleSelect);
            // setInputs(updatedInputs);
            setInputs([{ id: 1, value3: 1, value1: "", value2: "" }]);
            editorRef.current.editor.setData("");
            ansRef.current.editor.setData("");
            setQuestion((prevState) => ({
              ...prevState,
              question: "",
              answer: "",
              option1: "",
              option2: "",
              option3: "",
              option4: "",
              option1_img: "",
              option2_img: "",
              option3_img: "",
              option4_img: "",
              isCheck: "",
              book_question: "",
              perfact_match: [],
            }));
            // setIsModel(false);
          } else {
            ErrorToast(req?.message);
          }
          // } else {
          //   ErrorToast("change the data");
          // }
        }
      } catch (error) {
        ErrorToast(error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const _deleteQuestion = async (id) => {
    try {
      let data;
      if (deleteType === "multi") {
        const body = {
          ids: checkedItems,
        };
        const response = await Api.postRequest(`api/questions/delete`, body);
        data = response.data;
      } else {
        const body = {
          ids: [SelectQuestionId],
        };
        const response = await Api.postRequest(`api/questions/delete`, body);
        data = response.data;
      }
      // const { data } = await Api.postRequest(
      //   `api/questions/delete/${SelectQuestionId}`
      // );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        SuccessToast(req?.message);
        _getQuestion(filter, recordsPerPage, currentPage, search);
        setOpenModel(false);
        setSelectQuestionId("");
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error);
    }
  };

  const resetQuestion = () => {
    setQuestion({
      standard: "",
      semester: "",
      subject: "",
      chapter: "",
      questionType: "",
      question: "",
      answer: "",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      option1_img: "",
      option2_img: "",
      option3_img: "",
      option4_img: "",
      isCheck: "",
      reason: "",
      reference: "",
      perfact_match: [],
    });
    setError({});
    setIsModel(false);
  };

  const getAllBoards = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setBoardData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getAllMedium = async () => {
    try {
      const { data } = await Api.getRequest(
        `api/medium?board=${filter?.board?.id || question?.board?.id}`
      );
      const req = JSON.parse(data);
      setMediumData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getStandardData = async (l) => {
    try {
      let body = `?medium=${filter?.medium?.id || question?.medium?.id}`;
      const { data } = await Api.getRequest(`api/standard${body}`);
      const req = JSON.parse(data);
      setStandardData(req?.data);
    } catch (error) {
      ErrorToast(error);
    }
  };

  const getSemesterData = async (l) => {
    let body = `?standard=${filter?.standard?.id || question?.standard?.id}`;
    try {
      const response = await Api.getRequest(`api/semester${body}`);
      const req = JSON.parse(response?.data);
      setSemesterData(req?.data?.rows);
    } catch (error) {
      ErrorToast(error);
    }
  };

  const getSubjectData = async (l) => {
    try {
      let body = `?standard=${filter?.standard?.id || question?.standard?.id
        }&semester=${filter?.semester?.id || question?.semester?.id}`;
      const { data } = await Api.getRequest(`api/subject${body}`);
      const req = JSON.parse(data);
      setSubjectData(req?.data);
      getQTypeData(0)
    } catch (error) {
      ErrorToast(error);
    }
  };

  const getChapterData = async (l) => {
    try {
      let body = `?standard=${filter?.standard?.id || question?.standard?.id
        }&semester=${filter?.semester?.id || question?.semester?.id}&subject=${filter?.subject?.id || question?.subject?.id
        }`;
      const { data } = await Api.getRequest(`api/chapter${body}`);
      const req = JSON.parse(data);
      setChapterData(req?.data);
    } catch (error) {
      ErrorToast(error);
    }
  };

  const getQTypeData = async (l) => {
    try {
      if (qTypeDataCount !== qTypeData?.length) {
        let body = `?standard=${filter?.standard?.id || question?.standard?.id
          }&semester=${filter?.semester?.id || question?.semester?.id}&subject=${filter?.subject?.id || question?.subject?.id
          }&chapter=${(filter?.chapter?.id ?? " ") || question?.chapter?.id}`;
        setLimit5(l == 0 ? 2 : limit5 + 1);
        const { data } = await Api.getRequest(`api/qtype/chapter${body}`);
        const req = JSON.parse(data);
        setQTypeData(req?.data);
        setQTypeDataCount(req?.totalCount);
      }
    } catch (error) {
      ErrorToast(error);
    }
  };

  const getBoardData = async (l) => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setBoardData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getMediumData = async (l) => {
    try {
      let body = `?board=${question?.board?.id}`;
      const { data } = await Api.getRequest(`api/medium${body}`);
      const req = JSON.parse(data);
      setMediumData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getStandardModalData = async (l) => {
    try {
      let body = `?medium=${question?.medium?.id}`;
      const { data } = await Api.getRequest(`api/standard${body}`);
      const req = JSON.parse(data);
      setStandardModalData(req?.data);
      setStandardModalDataCount(req?.totalCount);
    } catch (error) {
      ErrorToast(error);
    }
  };

  const getSemesterModalData = async (l) => {
    try {
      let body = `?standard=${question?.standard?.id}`;
      const { data } = await Api.getRequest(`api/semester${body}`);
      const req = JSON.parse(data);
      setSemesterModalData(req?.data?.rows);
      setSemesterModalDataCount(req?.data?.count);
    } catch (error) {
      ErrorToast(error);
    }
  };

  const getSubjectModalData = async (l) => {
    try {
      let body = `?standard=${question?.standard?.id}&semester=${question?.semester?.id}`;
      const { data } = await Api.getRequest(`api/subject${body}`);
      const req = JSON.parse(data);
      setSubjectModalData(req?.data);
      setSubjectModalDataCount(req?.data?.count);
    } catch (error) {
      ErrorToast(error);
    }
  };

  const getChapterModalData = async (l) => {
    try {
      let body = `?standard=${question?.standard?.id}&semester=${question?.semester?.id}&subject=${question?.subject?.id}`;
      const { data } = await Api.getRequest(`api/chapter${body}`);
      const req = JSON.parse(data);
      setChapterModalData(req?.data);
      setChapterModalDataCount(req?.data?.count);
    } catch (error) {
      ErrorToast(error);
    }
  };

  const getQTypeModalData = async (l) => {
    try {
      // let body = `?standard=${question?.standard?.id}&semester=${question?.semester?.id}&subject=${question?.subject?.id}&chapter=${question?.chapter?.id}`;
      setLimit5(l == 0 ? 2 : limit5 + 1);
      const { data } = await Api.getRequest(`api/qtype`);
      const req = JSON.parse(data);
      setQTypeModalData(req?.data);
      setQTypeModalDataCount(req?.totalCount);
    } catch (error) {
      ErrorToast(error);
    }
  };

  const getRreferenceData = async (l) => {
    try {
      let body = `?board=${question?.board?.id}&medium=${question?.medium?.id}&standard=${question?.standard?.id}&semester=${question?.semester?.id}&subject=${question?.subject?.id}`;
      const { data } = await Api.getRequest(`api/reference_book${body}`);
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setReferenceData(req?.data);
        setReferenceModalDataCount(req?.totalCount);
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error);
    }
  };

  useEffect(() => {
    getSubjectData(0);
    getChapterData(0);
    getQTypeData(0);
    getSemesterModalData(0);
    getStandardModalData(0);
    getSubjectModalData(0);
    getChapterModalData(0);
    getQTypeModalData(0);
    // getRreferenceData(0);
  }, []);

  useEffect(() => {
    _getQuestion(filter, recordsPerPage, currentPage, search);
  }, [currentPage, recordsPerPage]);

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) => {
      const isChecked = prevState.includes(id);

      const updatedItems = isChecked
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id];

      return updatedItems;
    });
  };

  const applyFilter = () => {
    setDynamicOffset(1);
    _getQuestion(filter, recordsPerPage, 1, search);
  };

  const resetFilter = () => {
    setIsResetFilter(true);
    setFilter({
      board: "",
      medium: "",
      standard: "",
      semester: "",
      subject: "",
      chapter: "",
      topic: "",
      questionType: "",
    });
    setMediumData([]);
    setStandardData([]);
    setSemesterData([]);
    setSubjectData([]);
    setChapterData([]);
  };

  useEffect(() => {
    if (isResetFilter) {
      _getQuestion(filter, recordsPerPage, 1, search);
      setIsResetFilter(false);
    }
  }, [isResetFilter]);

  const renderLatex = (text) => {
    console.log("text🎈", text);
    const parts = text.split(/(\$[^\$]*\$)/);
    let renderedText = parts.map((part, index) => {
      // if (index % 2 === 0) {
      //   return part.includes("<") ? (
      //     <span key={index} dangerouslySetInnerHTML={{ __html: part }} />
      //   ) : (
      //     part
      //   );
      // } else {
      return <LatexTextRenderer key={index} text={part} />;
      // }
    });
    return renderedText;
  };

  // useEffect(() => {
  //   // Check if CKEDITOR is defined and then check for IE8 and show warning if necessary
  //   if (
  //     typeof window.CKEDITOR !== "undefined" &&
  //     window.CKEDITOR.env.ie &&
  //     window.CKEDITOR.env.version === 8
  //   ) {
  //     document.getElementById("ie8-warning").className = "tip alert";
  //   }
  // }, []);
  // useEffect(() => {
  //   // Check if CKEDITOR is defined and then check for IE8 and show warning if necessary
  //   if (
  //     typeof window.CKEDITOR !== "undefined" &&
  //     window.CKEDITOR.env.ie &&
  //     window.CKEDITOR.env.version === 8
  //   ) {
  //     document.getElementById("ie8-warning").className = "tip alert";
  //   }
  // }, []);

  const [htmlQuestionData, setHtmlQuestionData] = useState(""); // State to store HTML data
  const [htmlAnswerData, setHtmlAnswerData] = useState(""); // State to store HTML data

  const handleMathAndHTMLRendererQuestion = async (ckeditordata) => {
    try {
      let body = {
        // html: "$105 = 91 &times; 1 + 14$ &lt;br&gt;\\r\\n$91 = 14 &times; 6 + 7$ &lt;br&gt;\\r\\n$14 = 7 &times; 2 + 0$ &lt;br&gt;\\r\\nગુ.સા.અ. (105, 91) = 7&lt;br&gt;\\r\\nગુ.સા.અ. (a,b) $&times;$ લ.સા.અ. (a,b) = ab&lt;br&gt;\\r\\nગુ.સા.અ. (105, 91)&nbsp; $&times;$ લ.સા.અ.(105, 91) = 105 x 91 &lt;br&gt;\\r\\n7 x લ.સા.અ.(105, 91)&nbsp; = 105 $&times;$ 91 &lt;br&gt;\\r\\nલ.સા.અ.(105, 91) = $ \\\\frac {105 &times; 91}{7}&nbsp; $&lt;br&gt;\\r\\nલ.સા.અ.(105, 91)&nbsp; = 1365",
        html: ckeditordata.replaceAll("\\\\", "\\").replace(/&amp;/g, "&"),
      };
      const { data } = await Api.postRequest(
        `api/paper-generation/mobile/convertLaxTaXIntoMathMl`,
        body
      );

      const req = JSON.parse(data);
      if (req?.status === 200) {
        setHtmlQuestionData(req?.data);
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const handleMathAndHTMLRendererAnswer = async (ckeditordata) => {
    try {
      let body = {
        // html: "$105 = 91 &times; 1 + 14$ &lt;br&gt;\\r\\n$91 = 14 &times; 6 + 7$ &lt;br&gt;\\r\\n$14 = 7 &times; 2 + 0$ &lt;br&gt;\\r\\nગુ.સા.અ. (105, 91) = 7&lt;br&gt;\\r\\nગુ.સા.અ. (a,b) $&times;$ લ.સા.અ. (a,b) = ab&lt;br&gt;\\r\\nગુ.સા.અ. (105, 91)&nbsp; $&times;$ લ.સા.અ.(105, 91) = 105 x 91 &lt;br&gt;\\r\\n7 x લ.સા.અ.(105, 91)&nbsp; = 105 $&times;$ 91 &lt;br&gt;\\r\\nલ.સા.અ.(105, 91) = $ \\\\frac {105 &times; 91}{7}&nbsp; $&lt;br&gt;\\r\\nલ.સા.અ.(105, 91)&nbsp; = 1365",
        html: ckeditordata.replaceAll("\\\\", "\\").replace(/&amp;/g, "&"),
      };
      const { data } = await Api.postRequest(
        `api/paper-generation/mobile/convertLaxTaXIntoMathMl`,
        body
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setHtmlAnswerData(req?.data);
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const handleRendererQuestionRemove = () => {
    setHtmlQuestionData("");
  };

  const handleRendererAnswerRemove = () => {
    setHtmlAnswerData("");
  };
  return (
    <MathJax.Provider>
      <div className="h-full flex items-center justify-center ">
        <div className="h-full flex-1 flex flex-col space-y-5 lg:space-y-0 lg:flex-row lg:space-x-10 w-full sm:px-2.5">
          <div className="flex-1 px-2 sm:px-0 w-full">
            {/* <div className="flex justify-between items-center">
              <Breadcrumbs title="/ All Question" />
            </div> */}
            <div className="w-full mt-0 sm:px-0 flex md:flex-nowrap flex-wrap items-start gap-x-4">
              <div className="w-full">
                <Select
                  data={boardData || []}
                  value={filter?.board}
                  onChange={(e) => handleFilter("board", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Board"
                  type="board"
                  getBoard={getAllBoards}
                />
              </div>
              <div className="w-full">
                <Select
                  data={mediumData || []}
                  value={filter?.medium}
                  onChange={(e) => handleFilter("medium", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Medium"
                  type="medium"
                  getMedium={getAllMedium}
                />
              </div>{" "}
              <div className="w-full">
                <Select
                  data={standardData || []}
                  value={filter?.standard}
                  onChange={(e) => handleFilter("standard", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Standard"
                  type={"standard"}
                  getStandard={getStandardData}
                />
              </div>
              <div className="w-full">
                <Select
                  data={semesterData || []}
                  value={filter?.semester}
                  onChange={(e) => handleFilter("semester", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Semester"
                  type={"semester"}
                  getSemester={getSemesterData}
                />
              </div>
              <div className="w-full">
                <Select
                  data={subjectData || []}
                  value={filter?.subject}
                  onChange={(e) => handleFilter("subject", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Subject"
                  type={"subject"}
                  getSubject={getSubjectData}
                />
              </div>
              <div className="w-full">
                <Select
                  data={chapterData || []}
                  value={filter?.chapter}
                  onChange={(e) => handleFilter("chapter", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Chapter"
                  type={"chapter"}
                  getChapter={getChapterData}
                />
              </div>
              {/* <div className="w-full">
                <Select
                  data={data || []}
                  value={filter?.topic}
                  onChange={(e) => handleFilter("topic", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="Select Topic"
                />
              </div> */}
              <div className="w-full">
                <Select
                  data={qTypeData || []}
                  value={filter?.questionType}
                  onChange={(e) => handleFilter("questionType", e)}
                  placeholder="All Q.Type"
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  type={"qType"}
                  getQType={getQTypeData}
                />
              </div>
              <div className="mt-3">
                <Button
                  onClick={() => applyFilter()}
                  id=""
                  type="button"
                  label={"SEARCH"}
                />
              </div>
              <div className="mt-3">
                <Button
                  onClick={() => resetFilter()}
                  id=""
                  type="button"
                  label={"RESET"}
                />
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center  mt-2">
                <div>
                  <MasterTitle
                    title="All Question"
                    description="Below are the list of all the Question."
                  />
                </div>
                <div className="flex items-center space-x-2">
                  {selectedQue.length > 0 && (
                    <div>
                      <Button
                        isLight
                        id=""
                        type="submit"
                        icon={TrashIcon}
                        label={"MULTI DELETE"}
                        abc="bg-[#D11212]"
                      />
                    </div>
                  )}
                  <div>
                    {" "}
                    <div style={{ display: "flex", gap: "5px" }}>
                      {checkedItems.length > 0 && access?.delete && (
                        <Button
                          id=""
                          type="submit"
                          icon={TrashIcon}
                          label={"DELETE QUESTION"}
                          onClick={() => {
                            setDeleteType("multi");
                            if (checkedItems.length > 0) {
                              setOpenModel(true);
                            }
                          }}
                          color="black"
                          disabled={checkedItems.length === 0}
                        />
                      )}
                      {access?.add && (
                        <Button
                          id=""
                          type="submit"
                          icon={PlusIcon}
                          label={"ADD QUESTION"}
                          onClick={() => setIsModel(true)}
                        />
                      )}
                    </div>
                    <Model
                      title="Add Question"
                      show={isModel}
                      onCancel={() => {
                        setIsModel(false);
                        setQuestion({
                          standard: "",
                          semester: "",
                          subject: "",
                          chapter: "",
                          questionType: "",
                          question: "",
                          answer: "",
                          isCheck: "",
                          reason: "",
                          reference: "",
                        });
                        setError({});
                      }}
                      onClose={() => {
                        resetQuestion();
                      }}
                      isLoading={isLoading}
                      disabled={isLoading}
                      onOk={() => _addQuestion()}
                      okText="SUBMIT"
                      cancelText="CLOSE"
                      width="max-w-5xl"
                    >
                      <div className="flex  items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6 mb-3">
                        <div className="w-full">
                          <Select
                            value={question?.board}
                            data={boardData || []}
                            onChange={(e) => handleQuestion("board", e)}
                            className="font-regular"
                            label="Board"
                            required
                            type={"board"}
                            placeholder="All Board"
                            getBoard={getBoardData}
                          />
                          <Typography variant="caption" color={"error"}>
                            {!question?.board ? error?.board : ""}
                          </Typography>
                        </div>
                        <div className="w-full">
                          <Select
                            value={question?.medium}
                            data={mediumData || []}
                            onChange={(e) => handleQuestion("medium", e)}
                            className="font-regular"
                            label="Medium"
                            required
                            type={"medium"}
                            placeholder="All Medium"
                            getMedium={getMediumData}
                          />
                          <Typography variant="caption" color={"error"}>
                            {!question?.medium ? error?.medium : ""}
                          </Typography>
                        </div>
                      </div>
                      <div className="flex  items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6 mb-3">
                        <div className="w-full">
                          <Select
                            value={question?.standard}
                            data={standardModalData || []}
                            onChange={(e) => handleQuestion("standard", e)}
                            className="font-regular"
                            label="Standard"
                            required
                            type={"standard"}
                            placeholder="All Standard"
                            getStandard={getStandardModalData}
                          />
                          <Typography variant="caption" color={"error"}>
                            {!question?.standard ? error?.standard : ""}
                          </Typography>
                        </div>
                        <div className="w-full">
                          <Select
                            data={semesterModalData || []}
                            value={question?.semester}
                            onChange={(e) => handleQuestion("semester", e)}
                            label="Semester"
                            required
                            type={"semester"}
                            placeholder="All Semester"
                            getSemester={getSemesterModalData}
                          />
                          <Typography variant="caption" color={"error"}>
                            {!question?.semester ? error?.semester : ""}
                          </Typography>
                        </div>
                      </div>

                      <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                        <div className="w-full">
                          <Select
                            value={question?.subject}
                            data={subjectModalData || []}
                            onChange={(e) => handleQuestion("subject", e)}
                            className="font-regular "
                            label="Subject"
                            required
                            type={"subject"}
                            placeholder="All Subject"
                            getSubject={getSubjectModalData}
                          />
                          <Typography variant="caption" color={"error"}>
                            {!question?.subject ? error?.subject : ""}
                          </Typography>
                        </div>
                        <div className="w-full">
                          <Select
                            value={question?.chapter}
                            data={chapterModalData || []}
                            onChange={(e) => handleQuestion("chapter", e)}
                            className="font-regular "
                            label="Chapter"
                            required
                            type={"chapter"}
                            placeholder="All Chapter"
                            getChapter={getChapterModalData}
                          />
                          <Typography variant="caption" color={"error"}>
                            {!question?.chapter ? error?.chapter : ""}
                          </Typography>
                        </div>
                      </div>
                      <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                        <div className="w-full">
                          <Select
                            data={qTypeModalData || []}
                            label="Question Type"
                            value={question?.questionType}
                            onChange={(e) => handleQuestion("questionType", e)}
                            required
                            type={"qType"}
                            placeholder="All Q.Type"
                            getQType={getQTypeModalData}
                          />
                          <Typography variant="caption" color={"error"}>
                            {!question?.questionType ? error?.questionType : ""}
                          </Typography>
                        </div>
                        <div className="w-full ">
                          <Select
                            onChange={(e) => handleQuestion("reference", e)}
                            value={question?.reference}
                            data={referenceData || []}
                            label="Reference Material"
                            // required
                            placeholder="All Reference Material"
                            type={"reference"}
                            getRreference={getRreferenceData}
                          />
                          <Typography variant="caption" color={"error"}>
                            {!question?.reference ? error?.reference : ""}
                          </Typography>
                        </div>
                      </div>
                      <div className="w-full mb-3">
                        <div className="flex justify-between items-center">
                          <label className="mb-3 block text-sm font-medium text-[#252F40] font-poppins">
                            Question
                            <span className="text-red-500"> *</span>
                          </label>
                          <div style={{ display: "flex" }}>
                            <ActionButton
                              action="view"
                              onClick={() => {
                                if (question?.question) {
                                  handleMathAndHTMLRendererQuestion(
                                    question?.question
                                  );
                                }
                              }}
                            />
                            <XMarkIcon
                              className="w-6 h-6 cursor-pointer mt-2"
                              onClick={handleRendererQuestionRemove}
                            />
                          </div>
                        </div>
                        {question?.questionType &&
                          question?.questionType?.name?.toLowerCase().includes("match the following") ? (
                          <div className="rounded-md border border-gray-200 p-3 gap-4">
                            <CKEditor
                              editorUrl={editorUrl}
                              initData={question?.question}
                              name={"question"}
                              onInstanceReady={(instance) => {
                                editorRef.current = instance;
                              }}
                              onChange={(e) =>
                                handleQuestion("question", e.editor.getData())
                              }
                              config={{
                                extraPlugins: [
                                  "mathjax",
                                  "uploadimage",
                                  // "filebrowser",
                                ],
                                removeDialogTabs:
                                  "image:advanced;link:advanced",
                                mathJaxLib:
                                  "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-AMS_HTML",
                                autoParagraph: false,
                                filebrowserImageBrowseUrl: "/imageupload",
                                versionCheck: false,
                              }}
                            />
                            <label className="mb-2 mt-2 block text-sm font-medium text-gray-700">
                              Mismatch/Diff
                            </label>
                            {inputs?.map((input) => (
                              <>
                                <div key={input.id} className="flex p-2 gap-5">
                                  <div>
                                    <input
                                      readOnly
                                      value={input.value3}
                                      className="w-12 text-lg rounded-md border border-gray-200 px-3 py-1 text-gray-900 placeholder-[#A0AEC0]focus:border-blue-500k focus:bg-white focus:outline-none focus:ring-blue-500"
                                    />
                                  </div>
                                  <div>
                                    <input
                                      value={input.value1}
                                      onChange={(e) =>
                                        handleInputChange(
                                          input.id,
                                          "value1",
                                          e.target.value
                                        )
                                      }
                                      className="w-full appearance-none rounded-md border border-gray-200 px-3 py-2 text-gray-900 placeholder-[#A0AEC0]focus:border-blue-500k focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                                    />
                                  </div>
                                  <div>
                                    <input
                                      value={input.value2}
                                      onChange={(e) =>
                                        handleInputChange(
                                          input.id,
                                          "value2",
                                          e.target.value
                                        )
                                      }
                                      className="block  w-full appearance-none rounded-md border border-gray-200 px-3 py-2 text-gray-900 placeholder-[#A0AEC0]focus:border-blue-500k focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                                    />
                                  </div>
                                  <div>
                                    <input
                                      value={input.value4}
                                      onChange={(e) =>
                                        handleInputChange(
                                          input.id,
                                          "value4",
                                          e.target.value
                                        )
                                      }
                                      className="block  w-full appearance-none rounded-md border border-gray-200 px-3 py-2 text-gray-900 placeholder-[#A0AEC0]focus:border-blue-500k focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        ) : (
                          <>
                            <CKEditor
                              ref={editorRef}
                              editorUrl={editorUrl}
                              editor={ClassicEditor}
                              onInstanceReady={(instance) => {
                                editorRef.current = instance;
                              }}
                              onChange={(e) =>
                                handleQuestion("question", e.editor.getData())
                              }
                              config={{
                                extraPlugins: [
                                  "mathjax",
                                  "uploadimage",
                                  // "filebrowser",
                                ],
                                removeDialogTabs:
                                  "image:advanced;link:advanced",
                                mathJaxLib:
                                  "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-AMS_HTML",
                                // filebrowserImageUploadUrl:
                                //   "http://192.168.235.57:3004/single_file",
                                // filebrowserImageBrowseUrl:
                                //   "http://your-server.com/images", // URL for browsing images// URL for uploading images
                                autoParagraph: false,
                                // filebrowserBrowseUrl:
                                // "/apps/ckfinder/3.4.5/ckfinder.html",
                                filebrowserImageBrowseUrl: "/imageupload",
                                // filebrowserUploadUrl:
                                // "http://192.168.235.57:3004/single_file",
                                // filebrowserImageUploadUrl:
                                // "/imageupload",
                                versionCheck: false,
                              }}
                            />
                            {/* config={{
                                extraPlugins: 'easyimage',
                                cloudServices: {
                                  tokenUrl: 'https://example.com/cs-token-endpoint',
                                  uploadUrl: 'https://your-ckfinder-domain/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json',
                                },
                              }} */}
                          </>
                        )}
                        <Typography variant="caption" color={"error"}>
                          {!question?.question || inputs.length === 1
                            ? error?.question
                            : ""}
                        </Typography>
                      </div>
                      <>
                        {htmlQuestionData ? (
                          <div
                            className="w-full"
                            style={{
                              border: "1px solid #E5E7EB",
                              padding: "10px",
                            }}
                          >
                            <div
                              className="mathjax-rendered"
                              style={{
                                wordWrap: "break-word",
                              }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: htmlQuestionData,
                                }}
                              />
                            </div>
                            {/* )} */}
                          </div>
                        ) : (
                          ""
                        )}
                      </>

                      {question?.questionType &&
                        question?.questionType?.name === "તફાવત (Difference)" ? (
                        <div>
                          <div className="rounded-md border border-gray-200 p-3 gap-4">
                            <label className="mb-2 block text-sm font-medium text-gray-700">
                              Answer
                            </label>
                            {inputs.map((input) => (
                              <div key={input.id} className="flex p-2 gap-5">
                                <div>
                                  <input
                                    readOnly
                                    value={input.value3}
                                    className="w-12 text-lg rounded-md border border-gray-200 px-3 py-1 text-gray-900 placeholder-[#A0AEC0]focus:border-blue-500k focus:bg-white focus:outline-none focus:ring-blue-500"
                                  />
                                </div>
                                <div>
                                  <input
                                    value={input.value1}
                                    onChange={(e) =>
                                      handleInputChange(
                                        input.id,
                                        "value1",
                                        e.target.value
                                      )
                                    }
                                    className="w-60 appearance-none rounded-md border border-gray-200 px-3 py-2 text-gray-900 placeholder-[#A0AEC0]focus:border-blue-500k focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                                  />
                                </div>
                                <div>
                                  <input
                                    value={input.value2}
                                    onChange={(e) =>
                                      handleInputChange(
                                        input.id,
                                        "value2",
                                        e.target.value
                                      )
                                    }
                                    className="block  appearance-none rounded-md border border-gray-200 px-3 py-2 text-gray-900 placeholder-[#A0AEC0]focus:border-blue-500k focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                          <Typography variant="caption" color={"error"}>
                            {inputs.length === 1 ? error?.answer : ""}
                          </Typography>
                        </div>
                      ) : question?.questionType?.name ===
                        "MCQ - Multiselect" ? (
                        <div>
                          <div className="mt-6">
                            <label className="mb-3 block text-sm font-medium text-gray-700">
                              Multi-selected MCQ
                            </label>
                            {multiSelect?.map((e, i) => {
                              return (
                                <div className="flex space-x-4 items-center mb-4">
                                  <Checkbox
                                    type="checkbox"
                                    checked={e?.isCheck}
                                    onChange={(e) =>
                                      handleAnswerMultiSelect(
                                        "isCheck",
                                        e?.target?.checked,
                                        i
                                      )
                                    }
                                  />
                                  <div>
                                    <Input
                                      value={e?.value}
                                      required
                                      disabled={false}
                                      onChange={(e) =>
                                        handleAnswerMultiSelect(
                                          "value",
                                          e?.target?.value,
                                          i
                                        )
                                      }
                                    />
                                    <Typography
                                      variant="caption"
                                      color={"error"}
                                    >
                                      {!e?.value ? error?.value : ""}
                                    </Typography>
                                  </div>
                                  <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-4">
                                    <input
                                      onChange={(e) =>
                                        handleAnswerMultiSelect(
                                          "image",
                                          e.target.files[0],
                                          i
                                        )
                                      }
                                      type="file"
                                      id="fileUpload"
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <Typography variant="caption" color={"error"}>
                            {!question?.answer ? error?.answer : ""}
                          </Typography>
                        </div>
                      ) : question?.questionType?.name === "MCQ" ? (
                        <div>
                          <div className="mt-6">
                            <label className="mb-3 block text-sm font-medium text-gray-700">
                              MCQ
                            </label>
                            {singleSelect?.map((e, i) => {
                              return (
                                <div className="flex space-x-4 items-center mb-4">
                                  <Radio
                                    onChange={(e) =>
                                      handleAnswerSingleSelect(
                                        "isCheck",
                                        e?.target?.checked,
                                        i
                                      )
                                    }
                                    checked={e?.isCheck}
                                  />
                                  <div
                                    style={{ width: "-webkit-fill-available" }}
                                  >
                                    <Input
                                      value={e?.value}
                                      required
                                      disabled={false}
                                      onChange={(e) =>
                                        handleAnswerSingleSelect(
                                          "value",
                                          e?.target?.value,
                                          i
                                        )
                                      }
                                    />
                                    <Typography
                                      variant="caption"
                                      color={"error"}
                                    >
                                      {!e?.value && !e?.image
                                        ? error?.value
                                        : ""}
                                    </Typography>
                                  </div>
                                  <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-4">
                                    <input
                                      onChange={(e) =>
                                        handleAnswerSingleSelect(
                                          "image",
                                          e.target.files[0],
                                          i
                                        )
                                      }
                                      type="file"
                                      id="fileUpload"
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <Typography variant="caption" color={"error"}>
                            {!question?.answer ? error?.answer : ""}
                          </Typography>

                          <div className="flex items-center whitespace-nowrap ">
                            <Checkbox
                              label={"Equation Question."}
                              className={"mr-6 text-[#A0AEC0]"}
                              onClick={(e) => {
                                handleQuestion(
                                  "isCheck",
                                  e?.target?.checked ? "m_question" : ""
                                );
                              }}
                              checked={
                                question?.isCheck === "m_question"
                                  ? true
                                  : false
                              }
                            />
                            <Checkbox
                              label={"TextBook Question ."}
                              className={"mr-6 text-[#CC031F]"}
                              onClick={(e) => {
                                handleQuestion(
                                  "book_question",
                                  e?.target?.checked ? 1 : 0
                                );
                              }}
                              checked={question?.book_question ? true : false}
                            />
                            <Checkbox
                              label={"HTML Tag."}
                              className={"text-[#A0AEC0]"}
                              onClick={(e) => {
                                handleQuestion(
                                  "isCheck",
                                  e?.target?.checked ? "html_tag" : ""
                                );
                              }}
                              checked={
                                question?.isCheck === "html_tag" ? true : false
                              }
                            />
                          </div>
                        </div>
                      ) : question?.questionType?.name ===
                        "ખરા ખોટા (True-False)" ? (
                        <div className="w-full mb-3">
                          <Select
                            value={question?.answer}
                            data={TrueFalseData}
                            onChange={handleTrueFalseChange}
                            className="font-regular"
                            label="Select True false"
                            type={"TrueFalse"}
                            placeholder="select"
                            required
                          />
                          <Typography variant="caption" color={"error"}>
                            {!question?.answer ? error?.answer : ""}
                          </Typography>
                        </div>
                      ) : (
                        <>
                          <div className="w-full mb-3">
                            <div className="flex justify-between items-center">
                              <label className="mb-3 block text-sm font-medium text-[#252F40] font-poppins">
                                Answer
                                <span className="text-red-500"> *</span>
                              </label>
                              <div style={{ display: "flex" }}>
                                <ActionButton
                                  action="view"
                                  onClick={() => {
                                    handleMathAndHTMLRendererAnswer(
                                      question?.answer
                                    );
                                  }}
                                />
                                <XMarkIcon
                                  className="w-6 h-6 cursor-pointer mt-2"
                                  onClick={handleRendererAnswerRemove}
                                />
                              </div>
                            </div>
                            {/* <CKEditor
                                editorUrl="https://cdn.ckeditor.com/4.18.0/full-all/ckeditor.js"
                                value={question?.answer}
                                onInstanceReady={(instance) => {
                                  ansRef.current = instance;
                                }}
                                onChange={(e) => handleAnswer(e, "answer")}
                                config={{
                                  autoParagraph: false,
                                }}
                                  extraPlugins: ["filebrowser", "mathjax"],
                                  mathJaxLib:
                                    "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-AMS_HTML",

                                  filebrowserBrowseUrl:
                                    "/apps/ckfinder/3.4.5/ckfinder.html",
                                  filebrowserImageBrowseUrl:
                                    "/apps/ckfinder/3.4.5/ckfinder.html?type=Images",
                                  filebrowserUploadUrl:
                                    "/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files",
                                  filebrowserImageUploadUrl:
                                    "/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Images",
                                  removeButtons: "PasteFromWord",
                              /> */}
                            <CKEditor
                              editorUrl={editorUrl}
                              value={question?.answer}
                              onInstanceReady={(instance) => {
                                ansRef.current = instance;
                              }}
                              onChange={(e) => handleAnswer(e, "answer")}
                              config={{
                                extraPlugins: [
                                  "mathjax",
                                  "uploadimage",
                                  // "filebrowser",
                                ],
                                removeDialogTabs:
                                  "image:advanced;link:advanced",
                                mathJaxLib:
                                  "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-AMS_HTML",
                                autoParagraph: false,
                                filebrowserImageBrowseUrl: "/imageupload",
                                versionCheck: false,
                              }}
                            />
                            {/* <CKEditor
                              editor={ClassicEditor}
                              onReady={handleReady}
                              config={{
                                extraPlugins: "filebrowser",
                                filebrowserBrowseUrl:
                                  "https://filebrowser.s3.ap-south-1.amazonaws.com",
                                removeButtons: "PasteFromWord",
                              }}
                            /> */}

                            <Typography variant="caption" color={"error"}>
                              {!question?.answer ? error?.answer : ""}
                            </Typography>
                          </div>

                          {/* {(question?.questionType?.name ===
                            "જોડકા  (Match the Following)" ||
                            question?.questionType?.name ===
                              "MCQ - Multiselect" ||
                            question?.questionType?.name === "MCQ" ||
                            question?.questionType?.name ===
                              "ખરા ખોટા (True-False)" ||
                            question?.questionType?.name === "Image") && (
                            <>
                              <label className="mb-2 block text-sm font-medium text-gray-700">
                                Reason
                              </label>
                              <CKEditor
                                value={question?.reason}
                                onChange={(e) => handleAnswer(e, "reason")}
                                editorUrl="https://cdn.ckeditor.com/4.18.0/full-all/ckeditor.js"
                                config={{
                                  extraPlugins: [
                                    "mathjax",
                                    "uploadimage",
                                  ],
                                  removeDialogTabs:
                                    "image:advanced;link:advanced",
                                  mathJaxLib:
                                    "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-AMS_HTML",
                                  autoParagraph: false,
                                  filebrowserImageBrowseUrl: "/imageupload",
                                }}
                              />
                            </>
                          )} */}
                          <>
                            {htmlAnswerData ? (
                              <div
                                className="w-full"
                                style={{
                                  border: "1px solid #E5E7EB",
                                  padding: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                <div
                                  className="mathjax-rendered"
                                  style={{
                                    wordWrap: "break-word",
                                  }}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: htmlAnswerData,
                                    }}
                                  />
                                </div>
                                {/* )} */}
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                          <div className="flex items-center whitespace-nowrap ">
                            <Checkbox
                              label={"Equation Question."}
                              className={"mr-6 text-[#A0AEC0]"}
                              onClick={(e) => {
                                handleQuestion(
                                  "isCheck",
                                  e?.target?.checked ? "m_question" : ""
                                );
                              }}
                              checked={
                                question?.isCheck === "m_question"
                                  ? true
                                  : false
                              }
                            />
                            <Checkbox
                              label={"TextBook Question ."}
                              className={"mr-6 text-[#CC031F]"}
                              onClick={(e) => {
                                handleQuestion(
                                  "book_question",
                                  e?.target?.checked ? 1 : 0
                                );
                              }}
                              checked={question?.book_question ? true : false}
                            />
                            <Checkbox
                              label={"HTML Tag."}
                              className={"text-[#A0AEC0]"}
                              onClick={(e) => {
                                handleQuestion(
                                  "isCheck",
                                  e?.target?.checked ? "html_tag" : ""
                                );
                              }}
                              checked={
                                question?.isCheck === "html_tag" ? true : false
                              }
                            />
                          </div>
                        </>
                      )}
                    </Model>
                  </div>
                </div>
              </div>
              <div className="sm:mb-0 overflow-hidden">
                <div className="flex flex-col mt-1">
                  <section
                    className="bg-white rounded-2xl flex flex-col shadow-sm "
                    style={{
                      width: "100%",
                      height: "90vh",
                      margin: "0 auto",
                      padding: "10px",
                    }}
                  >
                    {" "}
                    <div className="flex justify-between items-center mt-0 px-4 mb-2">
                      <div style={{ display: "flex", gap: "5px" }}>
                        <Input
                          id=""
                          ref={inputRef}
                          name="Search"
                          type="text"
                          placeholder="Search here..."
                          autoComplete="given-name"
                          required
                          className="w-72"
                          onChange={(e) => {
                            setSearch(e?.target?.value);
                            setCurrentPage(1);
                          }}
                          value={search}
                        />
                        <Button
                          onClick={() => applyFilter()}
                          id=""
                          type="button"
                          // label={"SEARCH"}
                          searchicon={true}
                        />
                      </div>
                      {isPopupOpen && (
                        <div className="popup">
                          <input
                            type="text"
                            value={popupValue}
                            onChange={handlePopupInputChange}
                          />
                          <button onClick={handlePopupConfirm}>Confirm</button>
                        </div>
                      )}
                      <div>
                        <Records
                          recordsPerPage={recordsPerPage}
                          setRecordsPerPage={setRecordsPerPage}
                          setCurrentPage={setCurrentPage}
                          dataCount={questionCount}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        overflowY: "auto",
                        maxHeight: "80vh",
                        height: "80vh",
                        width: "100%",
                      }}
                    >
                      {!isLoader && questionData?.length <= 0 ? (
                        <NoDataFound />
                      ) : (
                        <table
                          style={{ width: "100%", borderCollapse: "separate" }}
                        >
                          <thead
                            style={{
                              position: "sticky",
                              top: 0,
                              backgroundColor: "#f4f7f8",
                              zIndex: 1,
                              border: "1px solid #000",
                            }}
                          >
                            <tr
                              style={{
                                borderBottom: "1px solid #dddddd",
                                textAlign: "left",
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "#7B809A",
                                textTransform: "uppercase",
                                backgroundColor: "#f4f7f8",
                                border: "1px solid #000",
                              }}
                            >
                              <th
                                scope="col"
                                style={{
                                  width: "3%",
                                  padding: "10px",
                                  border: "1px solid #000",
                                }}
                              ></th>
                              {titleData?.map(
                                (v, ind) =>
                                  v?.title && (
                                    <th
                                      key={ind}
                                      style={{
                                        padding: "6px",
                                        backgroundColor: "#f4f7f8",
                                        color: "#333",
                                        border: "1px solid #000",
                                        position:
                                          v.title === "EDIT" ||
                                            v.title === "DELETE"
                                            ? "sticky"
                                            : "",
                                        right:
                                          v.title === "EDIT"
                                            ? access?.delete
                                              ? "60px"
                                              : "0px"
                                            : v.title === "DELETE"
                                              ? "0"
                                              : "",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <span>{v.title}</span>
                                        {v.title !== "EDIT" &&
                                          v.title !== "DELETE" && (
                                            <span>
                                              <ChevronUpDownIcon
                                                onClick={() => {
                                                  let res = SortData(
                                                    questionData,
                                                    v.fieldName,
                                                    v.sort
                                                  );

                                                  let copyData = [...titleData];
                                                  copyData[ind].sort =
                                                    v.sort > 0 ? -1 : 1;
                                                  setTitleData(copyData);
                                                  setQuestionData(res);
                                                }}
                                                className="w-5 h-5"
                                              />
                                            </span>
                                          )}
                                      </div>
                                    </th>
                                  )
                              )}
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200">
                            {isLoader ? (
                              <tr style={{ height: "25rem" }}>
                                <td colSpan={6} style={{ textAlign: "center" }}>
                                  <CircularProgress />
                                </td>
                              </tr>
                            ) : (
                              questionData?.length > 0 &&
                              questionData?.map((data, id) => {
                                const options = [
                                  { label: "A.", text: data.option1 },
                                  { label: "B.", text: data.option2 },
                                  { label: "C.", text: data.option3 },
                                  { label: "D.", text: data.option4 },
                                ];
                                return (
                                  <tr
                                    key={id}
                                    style={{
                                      margin: "3px",
                                      padding: "6px",
                                      color: "#000",
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                      background:
                                        data?.book_question === "1"
                                          ? "#b7ddf6"
                                          : "",
                                    }}
                                  >
                                    <td
                                      style={{
                                        padding: "6px",
                                        width: "3%",
                                        border: "1px solid #000",
                                      }}
                                    >
                                      <Checkbox
                                        checked={checkedItems.includes(data.id)}
                                        onChange={() =>
                                          handleCheckboxChange(data.id)
                                        }
                                      />
                                    </td>
                                    <td
                                      style={{
                                        padding: "6px",
                                        width: "7%",
                                        border: "1px solid #000",
                                      }}
                                    >
                                      {data?.id}
                                    </td>
                                    <td
                                      style={{
                                        padding: "6px",
                                        width: "40%",
                                        whiteSpace: "normal",
                                        border: "1px solid #000",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          overflowWrap: "break-word",
                                        }}
                                      >
                                        {data?.m_equation !== "1" && (
                                          <>
                                            {/* && data?.QtypeInfo?.name !== "જોડકા  (Match the Following)"  */}
                                            {data?.m_equation !== "1" && (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: data?.finalquestion,
                                                }}
                                                className="table-container"
                                              />
                                            )}
                                            {data?.QtypeInfo?.name ===
                                              "MCQ" && (
                                                <div>
                                                  <ul>
                                                    <li>
                                                      A. {data?.option1}{" "}
                                                      {
                                                        <img
                                                          style={{
                                                            width: "160px",
                                                          }}
                                                          src={data?.option1_img}
                                                        />
                                                      }
                                                    </li>
                                                    <li>
                                                      B. {data?.option2}{" "}
                                                      {
                                                        <img
                                                          style={{
                                                            width: "160px",
                                                          }}
                                                          src={data?.option2_img}
                                                        />
                                                      }
                                                    </li>
                                                    <li>
                                                      C. {data?.option3}{" "}
                                                      {
                                                        <img
                                                          style={{
                                                            width: "160px",
                                                          }}
                                                          src={data?.option3_img}
                                                        />
                                                      }
                                                    </li>
                                                    <li>
                                                      D. {data?.option4}{" "}
                                                      {
                                                        <img
                                                          style={{
                                                            width: "160px",
                                                          }}
                                                          src={data?.option4_img}
                                                        />
                                                      }
                                                    </li>
                                                  </ul>
                                                </div>
                                              )}
                                          </>
                                        )}
                                        {data?.m_equation === "1" &&
                                          data?.QtypeInfo?.name === "MCQ" && (
                                            <div
                                              className="mathjax-rendered"
                                              style={{
                                                wordWrap: "break-word",
                                              }}
                                            >
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    data?.finalquestion.replace(
                                                      /\$(.*?)\$/g,
                                                      (match, p1) =>
                                                        `<LatexTextRenderer text="${p1}" />`
                                                    ),
                                                }}
                                              />
                                              <div
                                                className="mathjax-rendered"
                                                style={{
                                                  wordWrap: "break-word",
                                                }}
                                              >
                                                <LatexTextRendererMcq
                                                  text={data}
                                                />
                                              </div>
                                            </div>
                                          )}
                                        {/* {data?.m_equation === "1" &&
                                            data?.QtypeInfo?.name !== "MCQ" && (
                                              <div
                                                className="mathjax-rendered"
                                                style={{
                                                  wordWrap: "break-word",
                                                }}
                                              >
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      data?.question.replace(
                                                        /\$(.*?)\$/g,
                                                        (match, p1) =>
                                                          `<LatexTextRenderer text="${p1}" />`
                                                      ),
                                                  }}
                                                />
                                                </div>
                                              )}{" "} */}
                                        {data?.m_equation === "1" && (
                                          // <div
                                          //   className="mathjax-rendered"
                                          //   style={{
                                          //     // wordWrap: "break-word",
                                          //     flexDirection: "row",
                                          //     display: "flex",
                                          //     flexWrap: "wrap",
                                          //   }}
                                          // >
                                          //   {" "}
                                          //   {renderLatex(data?.question)}
                                          // </div>
                                          <div className="w-full">
                                            <div
                                              className="mathjax-rendered"
                                            // style={{
                                            //   wordWrap: "break-word",
                                            // }}
                                            >
                                              {data?.qtype != 1 && (
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    // __html: data?.question,
                                                    __html: data?.finalquestion,
                                                  }}
                                                  className="table-container"
                                                />
                                              )}
                                            </div>
                                            {/* )} */}
                                          </div>
                                        )}
                                        {data?.m_equation !== "1" &&
                                          data?.QtypeInfo?.name?.toLowerCase().includes("match the following") && (
                                            <div>
                                              <table
                                                style={{
                                                  // width: "100%",
                                                  borderCollapse: "collapse",
                                                }}
                                              >
                                                <thead>
                                                  <tr>
                                                    <th
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      Question
                                                    </th>
                                                    <th
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      Answer
                                                    </th>
                                                    <th
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      FinalAnswer
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {data?.perfact_metch.map(
                                                    (item, index) => (
                                                      <tr key={index}>
                                                        <td
                                                          style={{
                                                            border:
                                                              "1px solid black",
                                                            padding: "8px",
                                                          }}
                                                        >
                                                          {item.question}
                                                        </td>
                                                        <td
                                                          style={{
                                                            border:
                                                              "1px solid black",
                                                            padding: "8px",
                                                          }}
                                                        >
                                                          {item.finalanswer}
                                                        </td>
                                                        <td
                                                          style={{
                                                            border:
                                                              "1px solid black",
                                                            padding: "8px",
                                                          }}
                                                        >
                                                          {item.rightAnswer}
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </table>
                                              {/* "ABC" */}
                                            </div>
                                          )}
                                        {data?.m_equation === "1" &&
                                          data?.QtypeInfo?.name?.toLowerCase().includes("match the following") && (
                                            <div>
                                              <table
                                                style={{
                                                  // width: "100%",
                                                  borderCollapse: "collapse",
                                                }}
                                              >
                                                <thead>
                                                  <tr>
                                                    <th
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      Question
                                                    </th>
                                                    <th
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      Answer
                                                    </th>
                                                    <th
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      FinalAnswer
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {data?.perfact_metch.map(
                                                    (item, index) => (
                                                      <tr key={index}>
                                                        <td
                                                          style={{
                                                            border:
                                                              "1px solid black",
                                                            padding: "8px",
                                                          }}
                                                        >
                                                          {/* {item.question} */}
                                                          <LatexTextRenderer
                                                            text={
                                                              item?.finalquestion
                                                            }
                                                          />{" "}
                                                        </td>
                                                        <td
                                                          style={{
                                                            border:
                                                              "1px solid black",
                                                            padding: "8px",
                                                          }}
                                                        >
                                                          {/* {item.finalAnswer} */}
                                                          <LatexTextRenderer
                                                            text={
                                                              item.finalanswer
                                                            }
                                                          />{" "}
                                                        </td>
                                                        <td
                                                          style={{
                                                            border:
                                                              "1px solid black",
                                                            padding: "8px",
                                                          }}
                                                        >
                                                          {/* {item.finalAnswer} */}
                                                          <LatexTextRenderer
                                                            text={
                                                              item.rightAnswer
                                                            }
                                                          />{" "}
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </table>
                                              {/* "ABC" */}
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                    <td
                                      style={{
                                        padding: "6px",
                                        width: "40%",
                                        whiteSpace: "normal",
                                        border: "1px solid #000",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          overflowWrap: "break-word",
                                        }}
                                      >
                                        {data?.m_equation !== "1" &&
                                          data?.QtypeInfo.name !==
                                          "તફાવત (Difference)" &&
                                          data?.QtypeInfo.name !==
                                          "ખરા ખોટા (True-False)" &&
                                          data?.QtypeInfo?.name !== "MCQ" ? (
                                          <>
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: data?.finalAnswer,
                                              }}
                                              className="table-container"
                                            />
                                          </>
                                        ) : (
                                          ""
                                        )}

                                        {data?.QtypeInfo.name ===
                                          "ખરા ખોટા (True-False)" &&
                                          data?.answer === "0" ? (
                                          <div>False</div>
                                        ) : data?.QtypeInfo.name ===
                                          "ખરા ખોટા (True-False)" &&
                                          data?.answer === "1" ? (
                                          <div> True</div>
                                        ) : (
                                          ""
                                        )}

                                        {data?.QtypeInfo.name ===
                                          "ખરા ખોટા (True-False)" &&
                                          data?.answer === "False" ? (
                                          <div>False</div>
                                        ) : data?.QtypeInfo.name ===
                                          "ખરા ખોટા (True-False)" &&
                                          data?.answer === "True" ? (
                                          <div> True</div>
                                        ) : (
                                          ""
                                        )}
                                        {data?.m_equation !== "1" &&
                                          data?.QtypeInfo.name !==
                                          "તફાવત (Difference)" &&
                                          data?.QtypeInfo?.name === "MCQ" ? (
                                          <>
                                            {" "}
                                            {data?.answer ? (
                                              data?.answer.match(/\.(jpeg|jpg|gif|png)$/) != null ? (
                                                <img src={data.answer} alt="image" style={{ width: "160px" }} />
                                              ) : (
                                                <a href={data?.answer} target="_blank" rel="noopener noreferrer">
                                                  {data?.answer}
                                                </a>
                                              )
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        {data?.QtypeInfo.name ===
                                          "તફાવત (Difference)" ? (
                                          <div>
                                            <table
                                              style={{
                                                borderCollapse: "collapse",
                                              }}
                                            >
                                              <thead>
                                                {/* Conditionally render an additional row for the last item in data.perfact_metch */}
                                                {data?.perfact_metch.length >
                                                  0 && (
                                                    <tr
                                                      style={{
                                                        backgroundColor:
                                                          "#e1e1e1",
                                                      }}
                                                    >
                                                      <th
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >
                                                        <div
                                                          dangerouslySetInnerHTML={{
                                                            __html: data
                                                              .perfact_metch[0]
                                                              .finalquestion
                                                              ? data
                                                                .perfact_metch[0]
                                                                .finalquestion
                                                              : data
                                                                .perfact_metch[0]
                                                                .question,
                                                          }}
                                                        />
                                                      </th>
                                                      <th
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >
                                                        <div
                                                          dangerouslySetInnerHTML={{
                                                            __html: data
                                                              ?.perfact_metch[0]
                                                              ?.finalanswer
                                                              ? data
                                                                ?.perfact_metch[0]
                                                                ?.finalanswer
                                                              : data
                                                                ?.perfact_metch[0]
                                                                ?.answer,
                                                          }}
                                                        />
                                                      </th>
                                                    </tr>
                                                  )}
                                              </thead>
                                              <tbody>
                                                {/* Render all items in data.perfact_metch except the last one */}
                                                {data?.perfact_metch
                                                  .slice(1)
                                                  .map((item, index) => (
                                                    <tr key={index}>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >
                                                        <div
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              item.finalquestion
                                                                ? item.finalquestion
                                                                : item.question,
                                                          }}
                                                        />
                                                      </td>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >
                                                        <div
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              item.finalanswer
                                                                ? item.finalanswer
                                                                : item.answer,
                                                          }}
                                                        />
                                                        {/* {item.answer} */}
                                                      </td>
                                                    </tr>
                                                  ))}
                                              </tbody>
                                            </table>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        {data?.m_equation === "1" && (
                                          <div className="w-full">
                                            <div
                                              className="mathjax-rendered"
                                              style={{
                                                // wordWrap: "break-word",
                                                flexDirection: "row",
                                                display: "flex",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              {data?.finalAnswer ? (
                                                data?.finalAnswer.match(/\.(jpeg|jpg|gif|png)$/) != null ? (
                                                  <img src={data.finalAnswer} alt="image" style={{ width: "160px" }} />
                                                ) : (
                                                  <div
                                                  dangerouslySetInnerHTML={{
                                                    __html: data?.finalAnswer,
                                                  }}
                                                />
                                                )
                                              ) : (
                                                ""
                                              )}
                                              {/* <LatexTextRenderer text={data} />{" "} */}
                                            </div>
                                            {/* )} */}
                                          </div>
                                        )}
                                        {/* {data?.m_equation === "1" && (
                                          // <div
                                          //   className="mathjax-rendered"
                                          //   style={{
                                          //     // wordWrap: "break-word",
                                          //     flexDirection: "row",
                                          //     display: "flex",
                                          //     flexWrap: "wrap",
                                          //   }}
                                          // >
                                          //   {renderLatex(data?.answer)}
                                          // </div>
                                          <div className="w-full">
                                            <div
                                              className="mathjax-rendered"
                                              style={{
                                                wordWrap: "break-word",
                                              }}
                                            >
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: data?.finalAnswer,
                                                }}
                                              />
                                              <LatexTextRenderer
                                                text={
                                                  data
                                                }
                                              />{" "}
                                            </div>
                                            )}
                                          </div>
                                        )} */}
                                      </div>
                                    </td>
                                    <td
                                      className="lg:table-cell px-2 self-center text-xs py-1"
                                      style={{
                                        width: "130px",
                                        border: "1px solid #000",
                                      }}
                                    >
                                      <div style={{ width: "130px" }}>
                                        {data?.UserInfo !== null ? (
                                          <>
                                            {data?.UserInfo?.username && (
                                              <>
                                                User: {data?.UserInfo?.username}
                                                {data?.updatedAt !== null ? (
                                                  <>
                                                    <br />
                                                    -------------------
                                                    <br />
                                                    updatedAt:{" "}
                                                    {data.updatedAt &&
                                                      new Date(
                                                        data.updatedAt
                                                      ).toLocaleString(
                                                        "en-US",
                                                        {
                                                          timeZone:
                                                            "Asia/Kolkata",
                                                        }
                                                      )}
                                                  </>
                                                ) : (
                                                  "-"
                                                )}
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          "-"
                                        )}
                                      </div>
                                    </td>
                                    {access?.edit && (
                                      <td
                                        className="relative whitespace-nowrap  px-2"
                                        style={{
                                          position: "sticky",
                                          right: access?.delete
                                            ? "70px"
                                            : "0px",
                                          border: "1px solid #000",
                                          background:
                                            data?.book_question === "1"
                                              ? "#b7ddf6"
                                              : "white",
                                        }}
                                      >
                                        <ActionButton
                                          key={data.key}
                                          action="edit"
                                          onClick={() =>
                                            window.open(
                                              `/question/editquestion/${data?.id}`,
                                              "_blank"
                                            )
                                          }
                                        />
                                      </td>
                                    )}
                                    {access?.delete && (
                                      <td
                                        className="relative whitespace-nowrap  px-2"
                                        style={{
                                          position: "sticky",
                                          right: "0",
                                          backgroundColor: "white",
                                          border: "1px solid #000",
                                          background:
                                            data?.book_question === "1"
                                              ? "#b7ddf6"
                                              : "white",
                                        }}
                                      >
                                        <ActionButton
                                          key={data.key}
                                          action="delete"
                                          onClick={() => {
                                            setDeleteType("single");
                                            setOpenModel(true);
                                            setSelectQuestionId(data?.id);
                                          }}
                                        />
                                      </td>
                                    )}
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      recordsPerPage={recordsPerPage}
                      data={questionData}
                      numbers={numbers}
                      npage={npage}
                      lastIndex={lastIndex}
                      firstIndex={firstIndex}
                      dataCount={questionCount}
                    />
                  </section>
                </div>

                {/* Delete */}

                <DeletePopup
                  title={"Delete"}
                  show={openModel}
                  description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
                  onCancel={() => {
                    setOpenModel(false);
                    setSelectQuestionId("");
                  }}
                  onClose={() => {
                    setOpenModel(false);
                    setSelectQuestionId("");
                  }}
                  onDelete={() => {
                    _deleteQuestion();
                  }}
                  cancelButtonRef={cancelButtonRef}
                  okText="Delete"
                  cancelText="Cancel"
                />
                {/* Delete */}
                <FloatNotification
                  show={showFloatNotification}
                  title={"Successfully deleted!"}
                  type="success"
                  description={"Data Deleted."}
                  onClick={() => {
                    setShowFloatNotification(false);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MathJax.Provider>
  );
};

export default Question;
