import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "./Breadcrumbs";
import MasterTitle from "./MasterTitle";
import { Button } from "./Button";
import { ChevronUpDownIcon, PlusIcon } from "@heroicons/react/20/solid";
import { Input } from "./Input";
import Records from "./Records";
import NoDataFound from "./NoDataFound";
import Pagination from "./Pagination";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast, WarningToast } from "./toast";
import { SortData } from "./SortData";
import { CircularProgress } from "@mui/material";
import ActionPopup from "./ActionPopup";
import Model from "./Model";
import Select from "./Select";
import DeletePopup from "./DeletePopup";
import FloatNotification from "./FloatNotification";
import { getDecryptedDataFromLocalStorage } from "../Api/encryption";
import ActionButton from "./ActionButton";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import * as XLSX from "xlsx";
import Divider from "@mui/material/Divider";
import FileSaver from "file-saver";
import Selected from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardControlKeyIcon from "@mui/icons-material/KeyboardControlKey";

const UserReport = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Report")?.[0];
  const userData = getDecryptedDataFromLocalStorage("userData");
  const [selectedQue, setSelectedQue] = useState([]);
  const navigate = useNavigate();
  const [isModel, setIsModel] = useState(false);
  const [isModelForAddHistory, setIsModelForAddHistory] = useState(false);

  const [BookReportData, setBookReportData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [searchValue, setSearchValue] = useState("");
  const [searchAdmin, setSearchAdmin] = useState("");
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(dataCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [isLoader, setIsLoader] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [AllAdminData, setAllAdminData] = useState([]);
  const [AllChapterData, setAllChapterData] = useState([]);
  const [IsUserID, setIsUserID] = useState(null);
  const [isView, setIsView] = useState(false);
  const [isModelLoader, setisModelLoader] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [titleData, setTitleData] = useState([
    {
      title: "SR.No",
      sort: 1,
      fieldName: "sr_no",
    },
    {
      title: "NAME",
      sort: 1,
      fieldName: "name",
    },
    {
      title: "BOARD",
      sort: 1,
      fieldName: "board",
    },
    {
      title: "MEDIUM",
      sort: 1,
      fieldName: "medium",
    },
    {
      title: "STANDARD",
      sort: 1,
      fieldName: "standard",
    },
    {
      title: "SEMESTER",
      sort: 1,
      fieldName: "semester",
    },
    {
      title: "SUBJECT",
      sort: 1,
      fieldName: "semester",
    },
    {
      title: "DATE",
      sort: 1,
      fieldName: "date",
    },
    {
      title: access?.view ? "VIEW" : "",
      sort: 1,
      fieldName: "",
    },
  ]);

  const [QuestionTitleData, setQuestionTitleData] = useState([
    {
      title: "QUESTION",
      sort: 1,
      fieldName: "",
    },
  ]);

  const [chaptertiteData, setChapterTitleData] = useState([
    {
      title: "SR.No",
      sort: 1,
      fieldName: "sr_no",
    },
    {
      title: "CHAPTER NAME",
      sort: 1,
      fieldName: "name",
    },
    {
      title: "VIEW",
      sort: 1,
      fieldName: "",
    },
  ]);

  const [userFilter, setUserFilter] = useState({
    UserData: "",
  });

  const [selectQuestionData, setSelectQuestionData] = useState([]);
  const [isResetFilter, setIsResetFilter] = useState(false);
  const [error, setError] = useState({});
  const [BookHistoryData, setBookHistoryData] = useState({
    FullName: "",
    email: "",
    phoneNumber: "",
    paymentStatus: "",
    Address: "",
    finalAmount: "",
    pincode: "",
  });

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleSearchBookChange = (event) => {
    setSearchAdmin(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    setCurrentPage(1);
  };

  const getAllAdminData = async () => {
    try {
      const { data } = await Api.getRequest(`api/auth/getAllAdmin`);
      const req = JSON.parse(data);
      setAllAdminData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    getAllAdminData();
  }, []);

  useEffect(() => {
    getUserReportData(currentPage, recordsPerPage);
  }, [recordsPerPage, currentPage]);

  const getUserReportData = async () => {
    // if (searchAdmin && startDate && endDate) {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/user/get_admin_info_about_questions?offset=${currentPage}&limit=${recordsPerPage}&startDate=${startDate}&endDate=${endDate}&userId=${searchAdmin}`
      );
      const req = JSON.parse(data);
      if (req?.success === true) {
        setBookReportData(req?.data?.user);
        setDataCount(req?.data?.totalCount);
        setIsLoader(false);
      } else {
        ErrorToast(req?.message);
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
    // } else {
    //   WarningToast("Please first select filter.");
    // }
  };

  const handleSearchFilter = () => {
    setCurrentPage(1);
    getUserReportData(currentPage, recordsPerPage);
  };

  const resetFilter = () => {
    setIsResetFilter(true);
    setSearchAdmin("");
    setStartDate("");
    setEndDate("");
    setIsUserID("");
  };

  useEffect(() => {
    if (isResetFilter) {
      getUserReportData(recordsPerPage, currentPage);
      setIsResetFilter(false);
    }
  }, [isResetFilter]);

  const getChapterData = async () => {
    try {
      setisModelLoader(true);
      const { data } = await Api.getRequest(
        `api/user/get_questions?userId=${IsUserID}&startDate=${startDate}&endDate=${endDate}`
      );
      const req = JSON.parse(data);
      setAllChapterData(req?.data?.user);
      setisModelLoader(false);
    } catch (error) {
      ErrorToast(error?.message);
      setisModelLoader(false);
    }
  };

  useEffect(() => {
    if (IsUserID) {
      getChapterData();
    }
  }, [IsUserID]);

  const toggleView = (userID) => {
    if (userID === IsUserID) {
      setIsUserID("");
    } else {
      setIsUserID(userID);
    }
  };

  return (
    <div>
      <div className="h-full flex items-center justify-center ">
        <div className="h-full flex-1 flex flex-col space-y-5 lg:space-y-0 lg:flex-row lg:space-x-10 w-full sm:px-6 ">
          <div className="flex-1 px-2 sm:px-0 w-full">
            {/* <div className="flex justify-between items-center mt-4">
              <Breadcrumbs title="/ Report" />
            </div> */}
            <div>
              <div className="flex justify-between items-center mt-3">
                <div>
                  <MasterTitle
                    title="User Report"
                    description="Below are all the list of User Report."
                  />
                </div>
                <Model
                  title={"View Question"}
                  show={isModel}
                  onCancel={() => {
                    setIsModel(false);
                  }}
                  onClose={() => {
                    setIsModel(false);
                  }}
                  cancelText=""
                  okText=""
                  isLoading={isLoading}
                  disabled={isLoading}
                  width="max-w-3xl"
                >
                  <div
                    style={{
                      overflowY: "auto",
                      maxHeight: "58vh",
                      height: "30vh",
                      width: "100%",
                    }}
                  >
                    <table className="w-full">
                      <thead className="sticky top-0 bg-white z-10">
                        <tr
                          className="border-b border-gray-200 text-left text-xs leading-4 font-bold text-[#7B809A] tracking-wider"
                          style={{ backgroundColor: "#f4f7f8" }}
                        >
                          {QuestionTitleData.map(
                            (v, ind) =>
                              v?.title && (
                                <th key={ind} className="py-4 px-8 ">
                                  <div className="flex justify-between">
                                    <span>{v.title}</span>
                                  </div>
                                </th>
                              )
                          )}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {selectQuestionData.map((data, id) => (
                          <tr
                            key={id}
                            className="my-4 px-8 text-[#7B809A] font-reguler text-sm align-top"
                          >
                            <td className="lg:table-cell whitespace-nowrap px-8 py-2.5">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: data?.questions,
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Model>
              </div>
              <div className="w-full py-0 sm:px-0 flex items-center gap-x-4 mt-3 overflow-x-auto">
                <div className="w-full">
                  <FormControl fullWidth sx={{ marginTop: "5px" }}>
                    <Selected
                      displayEmpty
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="font-regular"
                      value={searchAdmin}
                      onChange={handleSearchBookChange}
                      size="small"
                      sx={{
                        border: "none",
                        background: "white",
                        height: "38px",
                        color: "grey",
                        mr: 1,
                        "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #E5F1EB",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem value="">
                        Select Admin
                      </MenuItem>
                      {AllAdminData?.map((row) => (
                        <MenuItem value={row?.id}>{row?.username}</MenuItem>
                      ))}
                    </Selected>
                  </FormControl>
                </div>
                <div className="w-full flex items-end">
                  <label
                    style={{ whiteSpace: "nowrap" }}
                    className="mb-3 mr-2 text-sm font-medium text-[#252F40] font-poppins"
                  >
                    From Date :
                  </label>
                  <div className="w-full">
                    <Input
                      disabled={false}
                      name=""
                      type="date"
                      className="font-regular"
                      value={startDate}
                      onChange={handleStartDateChange}
                    />
                  </div>
                </div>
                <div className="w-full flex items-end">
                  <label
                    style={{ whiteSpace: "nowrap" }}
                    className="mb-3 mr-2 text-sm font-medium text-[#252F40] font-poppins"
                  >
                    To Date :
                  </label>
                  <div className="w-full">
                    <Input
                      disabled={false}
                      name=""
                      // label="To Date :"
                      type="date"
                      className="font-regular"
                      placeholder="To Date"
                      value={endDate}
                      onChange={handleEndDateChange}
                    />
                  </div>
                </div>
                <div>
                  <Button
                    id=""
                    type="button"
                    label={"SEARCH"}
                    onClick={handleSearchFilter}
                  />
                </div>
                <div>
                  <Button
                    onClick={() => resetFilter()}
                    id=""
                    type="button"
                    label={"RESET"}
                  />
                </div>
              </div>
              <div className="sm:mb-0 overflow-hidden mt-2">
                <div className="flex flex-col mt-2">
                  <section
                    className="bg-white rounded-2xl flex flex-col shadow-sm "
                    style={{
                      width: "100%",
                      height: "75vh",
                      margin: "0 auto",
                      padding: "20px",
                    }}
                  >
                    {" "}
                    <div className="flex justify-between items-center mt-3 px-8 mb-2">
                      <div  style={{ display: "flex", gap:'5px' }}>
                      <Input
                        id=""
                        name="Search"
                        type="text"
                        placeholder="Search here..."
                        autoComplete="given-name"
                        required
                        className="w-72"
                        value={searchValue}
                        onChange={handleSearchChange}
                      />
                       <Button
                          onClick={() => handleSearchFilter()}
                          id=""
                          type="button"
                          // label={"SEARCH"}
                          searchicon={true}
                        />
                      </div>
                      <Records
                        recordsPerPage={recordsPerPage}
                        setCurrentPage={setCurrentPage}
                        setRecordsPerPage={setRecordsPerPage}
                        dataCount={dataCount}
                      />
                    </div>
                    <div
                      style={{
                        overflowY: "auto",
                        maxHeight: "58vh",
                        height: "60vh",
                        width: "100%",
                      }}
                    >
                      {" "}
                      {!isLoader && BookReportData?.length <= 0 ? (
                        <NoDataFound />
                      ) : (
                        <table style={{ width: "100%" }}>
                          <thead
                            style={{
                              position: "sticky",
                              top: 0,
                              backgroundColor: "#f4f7f8",
                              zIndex: 1,
                            }}
                          >
                            <tr
                              style={{
                                borderBottom: "1px solid #dddddd",
                                textAlign: "left",
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "#7B809A",
                                textTransform: "uppercase",
                                backgroundColor: "#f4f7f8",
                              }}
                            >
                              {" "}
                              {titleData.map(
                                (v, ind) =>
                                  v?.title && (
                                    <th
                                      key={ind}
                                      className="py-4 px-8 "
                                      style={{
                                        padding: "12px",
                                        backgroundColor: "#f4f7f8",
                                        position:
                                          v.title === "VIEW" ? "sticky" : "",
                                        right: v.title === "VIEW" ? "0" : "",
                                      }}
                                    >
                                      <div className="flex justify-between">
                                        <span>{v.title}</span>
                                        {v.title !== "VIEW" && (
                                          <span>
                                            <ChevronUpDownIcon
                                              onClick={() => {
                                                let res = SortData(
                                                  BookReportData,
                                                  v.fieldName,
                                                  v.sort
                                                );

                                                let copyData = [...titleData];
                                                copyData[ind].sort =
                                                  v.sort > 0 ? -1 : 1;
                                                setTitleData(copyData);
                                                setBookReportData(res);
                                              }}
                                              className="w-5 h-5"
                                            />
                                          </span>
                                        )}
                                      </div>
                                    </th>
                                  )
                              )}
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200">
                            {isLoader ? (
                              <tr style={{ height: "25rem" }}>
                                <td colSpan={8} style={{ textAlign: "center" }}>
                                  <CircularProgress />
                                </td>
                              </tr>
                            ) : (
                              BookReportData.map((data, id) => (
                                <>
                                  <tr className="my-4 px-8 text-[#7B809A] font-reguler text-sm align-top">
                                    <td className="lg:table-cell whitespace-nowrap px-8  py-2.5 ">
                                      {data?.userid}
                                    </td>
                                    <td className="lg:table-cell whitespace-nowrap px-8  py-2.5 ">
                                      {data?.UserInfo?.username}
                                    </td>
                                    <td className="lg:table-cell whitespace-nowrap px-8  py-2.5 ">
                                      {
                                        data?.StandardInfo?.boardInfo
                                          ?.board_name
                                      }
                                    </td>
                                    <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                                      {data?.StandardInfo?.mediumInfo?.name}
                                    </td>
                                    <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                                      {data?.StandardInfo?.name}
                                    </td>
                                    <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                                      {data?.SemesterInfo?.semester}
                                    </td>
                                    <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                                      {data?.SubjectInfo?.subject}
                                    </td>
                                    <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                                      {data.updatedAt !== null
                                        ? moment(data.updatedAt).format(
                                            "D MMMM, YYYY | hh:mm A"
                                          )
                                        : "-"}
                                    </td>
                                    {access?.view && (
                                      <td
                                        className="relative whitespace-nowrap  p-6 py-2.5  text-left"
                                        style={{
                                          position: "sticky",
                                          right: "0px",
                                          backgroundColor: "white",
                                        }}
                                      >
                                        <button
                                          type="button"
                                          onClick={() =>
                                            toggleView(data?.userid)
                                          }
                                        >
                                          {data?.userid == IsUserID ? (
                                            <KeyboardControlKeyIcon className="text-gray-700 hover:text-gray-900 cursor-pointer" />
                                          ) : (
                                            <>
                                              <KeyboardArrowDownIcon
                                                height={50}
                                                className="text-gray-700 hover:text-gray-900 cursor-pointer"
                                              />
                                            </>
                                          )}
                                        </button>
                                      </td>
                                    )}
                                  </tr>
                                  {data?.userid == IsUserID ? (
                                    <td colSpan={8}>
                                      <div
                                        className="flex justify-center"
                                        style={{
                                          overflowY: "auto",
                                          maxHeight: "50vh",
                                          height: "30vh",
                                        }}
                                      >
                                        <table
                                          className="w-full"
                                          style={{
                                            width: "90%",
                                            alignItems: "center",
                                            margin: "10px 0px 10px 0px",
                                          }}
                                        >
                                          <thead className="sticky top-0 bg-white z-10">
                                            <tr
                                              className="border-b border-gray-200 text-left text-xs leading-4 font-bold text-[#7B809A] tracking-wider"
                                              style={{
                                                backgroundColor: "#f4f7f8",
                                              }}
                                            >
                                              {chaptertiteData.map(
                                                (v, ind) =>
                                                  v?.title && (
                                                    <th
                                                      key={ind}
                                                      className="py-4 px-8 "
                                                    >
                                                      <div className="flex justify-between">
                                                        <span>{v.title}</span>
                                                      </div>
                                                    </th>
                                                  )
                                              )}
                                            </tr>
                                          </thead>
                                          <tbody className="divide-y divide-gray-200">
                                            {isModelLoader ? (
                                              <tr style={{ height: "10rem" }}>
                                                <td
                                                  colSpan={3}
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  <CircularProgress />
                                                </td>
                                              </tr>
                                            ) : (
                                              AllChapterData.map((data, id) => (
                                                <tr
                                                  key={id}
                                                  className="my-4 px-8 text-[#7B809A] font-reguler text-sm align-top"
                                                >
                                                  <td className="lg:table-cell whitespace-nowrap px-8  py-2.5 ">
                                                    {data?.chapter}
                                                  </td>
                                                  <td className="lg:table-cell whitespace-nowrap px-8 py-2.5">
                                                    <p>{data.chapterName}</p>
                                                  </td>
                                                  <td
                                                    className="relative whitespace-nowrap  p-6 py-2.5  text-left"
                                                    style={{
                                                      position: "sticky",
                                                      right: "0px",
                                                      backgroundColor: "white",
                                                    }}
                                                  >
                                                    <ActionButton
                                                      // key={data.key}
                                                      action="view"
                                                      onClick={() => {
                                                        if (
                                                          data?.AllChapterData
                                                            ?.question
                                                            ?.length !== 0
                                                        ) {
                                                          setIsModel(true);
                                                          setSelectQuestionData(
                                                            data?.question
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  </td>
                                                </tr>
                                              ))
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ))
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      recordsPerPage={recordsPerPage}
                      data={BookReportData}
                      numbers={numbers}
                      npage={npage}
                      lastIndex={lastIndex}
                      firstIndex={firstIndex}
                      dataCount={dataCount}
                    />
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserReport;
