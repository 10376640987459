import { call, put, takeLatest } from "redux-saga/effects";
import { fetchSemesterSuccess } from "../../actions/SemesterAction";
import { toast } from "react-toastify";
import { SemesterActions } from "../../action-types/SemesterActionType";
import { getSemesterApi } from "../requests/SemesterRequest";

function* fetchSemester() {
    // start loader here
    try {
        const SemsterData = yield call(getSemesterApi);

        if (SemsterData) {
            yield put(fetchSemesterSuccess(SemsterData));

        } else {
            yield toast.error(SemsterData.message)

        }
        // handle response here and set into reducer
    } catch (error) {
        yield toast.error(error.message)
        // handle error 
    } finally {
        //stop loader here
    }
}


export default function* SemesterSaga() {
    yield takeLatest(SemesterActions.FETCH_SEMESTER_REQUEST, fetchSemester);
}