import { QuestionActions } from "../action-types/QuestionActionType";


export const fetchQTypeRequest = () => ({
  type: QuestionActions.FETCH_QTYPE_REQUEST,
});

export const fetchQTypeSuccess = (qtypeData) => ({
  type: QuestionActions.FETCH_QTYPE_SUCCESS,
  payload: qtypeData,
});

export const fetchQTypeFailure = (error) => ({
  type: QuestionActions.FETCH_QTYPE_FAILURE,
  payload: error,
});

