import React, { useRef, useState, useEffect } from "react";
import { PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import { Button } from "./Button";
import MasterTitle from "./MasterTitle";
import { Input } from "./Input";
import Records from "./Records";
import { Checkbox } from "./Checkbox";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { SortData } from "./SortData";
import ActionPopup from "./ActionPopup";
import Model from "./Model";
import Select from "./Select";
import DeletePopup from "./DeletePopup";
import FloatNotification from "./FloatNotification";
import Pagination from "./Pagination";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast } from "./toast";
import NoDataFound from "./NoDataFound";
import CircularProgress from "@mui/material/CircularProgress";
import ActionButton from "./ActionButton";
import { Typography } from "@mui/material";

const ReferenceBook = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Master")?.[0];
  const [modelType, setModelType] = useState("add");
  const [isModel, setIsModel] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataCount, setDataCount] = useState(0);
  const [SelectedData, setSelectedData] = useState([]);
  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const cancelButtonRef = useRef(null);
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(dataCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [isLoader, setIsLoader] = useState(false);
  const [referenceData, setReferenceData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [error, setError] = useState({});
  const [boardData, setBoardData] = useState([]);
  const [mediumData, setMediumData] = useState([]);
  const [standardData, setStandardData] = useState([]);
  const [semesterData, setSemesterData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [deleteType, setDeleteType] = useState("single");
  const [isLoading, setIsLoading] = useState(false);
  const [ReferenceBookData, setReferenceBookData] = useState({
    medium: "",
    board: "",
    standard: "",
    semester: "",
    subject: "",
    bookname: "",
  });
  const [titleData, setTitleData] = useState([
    {
      title: "BOARD",
      sort: 1,
      fieldName: "board",
    },
    {
      title: "Medium",
      sort: 1,
      fieldName: "medium",
    },
    {
      title: "STANDARD",
      sort: 1,
      fieldName: "standard",
    },
    {
      title: "SEMESTER",
      sort: 1,
      fieldName: "semester",
    },
    {
      title: "SUBJECT",
      sort: 1,
      fieldName: "subject",
    },
    {
      title: "BOOK NAME",
      sort: 1,
      fieldName: "offer price",
    },
    {
      title: access?.edit ? "EDIT" : "",
      sort: 1,
      fieldName: "",
    },
    {
      title: access?.delete ? "DELETE" : "",
      sort: 1,
      fieldName: "",
    },
  ]);

  const handleSearchChange = (event) => {
    setCurrentPage(1);
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    getReferenceBookData();
  }, [recordsPerPage, currentPage]);

  const applyFilter = () => {
    getReferenceBookData();
  };

  const getReferenceBookData = async () => {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/reference_book?offset=${currentPage}&limit=${recordsPerPage}&search=${searchValue}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setReferenceData(req?.data);
        setDataCount(req?.totalCount);
        setIsLoader(false);
      } else {
        ErrorToast(req?.message);
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const deleteReferenceBookData = async () => {
    try {
      let data;
      if (deleteType === "multi") {
        const body = {
          ids: checkedItems,
        };
        const response = await Api.postRequest(
          `api/reference_book/delete`,
          body
        );
        data = response.data;
      } else {
        const body = {
          ids: [SelectedData?.id],
        };
        const response = await Api.postRequest(
          `api/reference_book/delete`,
          body
        );
        data = response.data;
      }

      // const { data } = await Api.postRequest(
      //   `api/reference_book/delete/${SelectedData?.id}`
      // );

      const req = JSON.parse(data);
      if (req?.status === 200) {
        setOpenModel(false);
        setSelectedData([]);
        getReferenceBookData();
        SuccessToast("Reference Book Delete Successfully");
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getBoardData = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setBoardData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getMediumData = async () => {
    try {
      let body = `?board=${ReferenceBookData?.board?.id}`;
      const { data } = await Api.getRequest(`api/medium${body}`);
      const req = JSON.parse(data);
      setMediumData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getStandardData = async () => {
    try {
      let body = `?board=${ReferenceBookData?.board?.id}&medium=${ReferenceBookData?.medium?.id}`;
      const { data } = await Api.getRequest(`api/standard${body}`);
      const req = JSON.parse(data);
      setStandardData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSemesterData = async () => {
    try {
      let body = `?board=${ReferenceBookData?.board?.id}&medium=${ReferenceBookData?.medium?.id}&standard=${ReferenceBookData?.standard?.id}`;
      const { data } = await Api.getRequest(`api/semester${body}`);
      const req = JSON.parse(data);
      setSemesterData(req?.data?.rows);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSubjectData = async (l) => {
    try {
      let body = `?board=${ReferenceBookData?.board?.id}&medium=${ReferenceBookData?.medium?.id}&standard=${ReferenceBookData?.standard?.id}&semester=${ReferenceBookData?.semester?.id}`;
      const { data } = await Api.getRequest(`api/subject${body}`);
      const req = JSON.parse(data);
      setSubjectData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    getBoardData();
  }, []);

  useEffect(() => {
    if (ReferenceBookData?.board) {
      getMediumData();
    }
  }, [ReferenceBookData?.board]);

  useEffect(() => {
    if (ReferenceBookData?.medium) {
      getStandardData();
    }
  }, [ReferenceBookData?.medium]);

  useEffect(() => {
    if (ReferenceBookData?.standard) {
      getSemesterData();
    }
  }, [ReferenceBookData?.standard]);

  useEffect(() => {
    if (ReferenceBookData?.semester) {
      getSubjectData();
    }
  }, [ReferenceBookData?.semester]);

  const handleReferenceBook = (name, value) => {
    setReferenceBookData((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));

    if (name === "board") {
      setReferenceBookData((oldValue) => ({
        ...oldValue,
        medium: "",
        standard: "",
        semester: "",
        subject: "",
        [name]: value,
      }));
      setMediumData([]);
      setStandardData([]);
      setSemesterData([]);
      setSubjectData([]);
    }
    if (name === "medium") {
      setReferenceBookData((oldValue) => ({
        ...oldValue,
        standard: "",
        semester: "",
        subject: "",
        [name]: value,
      }));
      setStandardData([]);
      setSemesterData([]);
      setSubjectData([]);
    }
    if (name === "standard") {
      setReferenceBookData((oldValue) => ({
        ...oldValue,
        semester: "",
        subject: "",
        [name]: value,
      }));
      setSemesterData([]);
      setSubjectData([]);
    }
    if (name === "semester") {
      setReferenceBookData((oldValue) => ({
        ...oldValue,
        subject: "",
        [name]: value,
      }));
      setSubjectData([]);
    }
  };

  const resetReferenceBook = () => {
    setReferenceBookData({
      medium: "",
      board: "",
      standard: "",
      semester: "",
      subject: "",
      bookname: "",
    });
    setError({});
    setIsModel(false);
    setMediumData([]);
    setStandardData([]);
    setSemesterData([]);
    setSubjectData([]);
  };

  const validation = () => {
    let errors = {};
    let formIsValid = true;
    if (!ReferenceBookData?.board) {
      formIsValid = false;
      errors["board"] = "Please select board.";
    }
    if (!ReferenceBookData?.medium) {
      formIsValid = false;
      errors["medium"] = "Please select medium.";
    }
    if (!ReferenceBookData?.standard) {
      formIsValid = false;
      errors["standard"] = "Please select standard.";
    }
    if (!ReferenceBookData?.semester) {
      formIsValid = false;
      errors["semester"] = "Please select semester.";
    }
    if (!ReferenceBookData?.subject) {
      formIsValid = false;
      errors["subject"] = "Please select subject.";
    }
    if (!ReferenceBookData?.bookname) {
      formIsValid = false;
      errors["bookname"] = "Please Enter Book Name.";
    }
    setError(errors);
    return formIsValid;
  };

  const addorEditReferenceBook = async () => {
    try {
      let body = {
        standard: ReferenceBookData?.standard?.id,
        semester: ReferenceBookData?.semester?.id,
        subject: ReferenceBookData?.subject?.id,
        book_name: ReferenceBookData?.bookname,
      };
      if (validation()) {
        setIsLoading(true);
        if (modelType === "add") {
          try {
            const { data } = await Api.postRequest(
              `api/reference_book/create`,
              body
            );
            const req = JSON.parse(data);
            if (req?.status === 200) {
              SuccessToast(req?.message);
              getReferenceBookData();
              // resetReferenceBook();
              setReferenceBookData((prevData) => ({
                ...prevData,
                // standard: "",
                // semester: "",
                // subject: "",
                bookname: "",
              }));
              setTimeout(() => {
                setIsLoading(false);
              }, 2000);
            } else {
              ErrorToast(req?.message);
            }
          } catch (error) {
            ErrorToast(error?.message);
            setIsLoading(false);
          } finally {
            setIsLoading(false);
          }
        } else {
          try {
            const { data } = await Api.putRequest(
              `api/reference_book/update/${SelectedData?.id}`,
              body
            );
            if (data?.status === 200) {
              SuccessToast(data?.message);
              getReferenceBookData();
              resetReferenceBook();
              setTimeout(() => {
                setIsLoading(false);
              }, 2000);
            } else {
              ErrorToast(data?.message);
            }
          } catch (error) {
            ErrorToast(error?.message);
            setIsLoading(false);
          } finally {
            setIsLoading(false);
          }
        }
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  useEffect(() => {
    setDefaultValues();
  }, [SelectedData]);

  const setDefaultValues = () => {
    setReferenceBookData({
      ...ReferenceBookData,
      board: SelectedData?.StandardInfo?.boardInfo,
      medium: SelectedData?.StandardInfo?.mediumInfo,
      standard: SelectedData?.StandardInfo,
      semester: SelectedData?.SemesterInfo,
      subject: SelectedData?.SubjectInfo,
      bookname: SelectedData?.book_name,
    });
  };

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) => {
      const isChecked = prevState.includes(id);

      const updatedItems = isChecked
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id];

      return updatedItems;
    });
  };

  return (
    <div>
      <div className="flex justify-between items-center mt-4">
        <div>
          <MasterTitle
            title="Reference Book"
            description="Below are all the list of reference book."
          />
        </div>
        <div>
          {" "}
          <div style={{ display: "flex", gap: "5px" }}>
            {access?.delete && (
              <Button
                id=""
                type="submit"
                icon={TrashIcon}
                label={"DELETE  REFERENCE BOOK"}
                onClick={() => {
                  setDeleteType("multi");
                  if (checkedItems.length > 0) {
                    setOpenModel(true);
                  }
                }}
              />
            )}
            {access?.add && (
              <Button
                id=""
                type="submit"
                icon={PlusIcon}
                label={"ADD REFERENCE BOOK"}
                onClick={() => {
                  setIsModel(true);
                  setModelType("add");
                }}
              />
            )}
          </div>
          <Model
            title={
              modelType === "add" ? "Add Reference book" : "Edit Reference book"
            }
            show={isModel}
            onCancel={() => {
              // setSelectedData([]);
              // resetReferenceBook();
              setIsModel(false);
            }}
            onClose={() => {
              setSelectedData([]);
              resetReferenceBook();
              setIsModel(false);
            }}
            onOk={() => {
              addorEditReferenceBook();
            }}
            okText="SUBMIT"
            cancelText="CLOSE"
            isLoading={isLoading}
            disabled={isLoading}
          >
            <Select
              value={ReferenceBookData?.board}
              data={boardData || []}
              onChange={(e) => handleReferenceBook("board", e)}
              className="font-regular mb-3"
              label="Board"
              required
              placeholder="All Board"
              type={"board"}
              getBoard={getBoardData}
              isPage={true}
            />
            <Typography variant="caption" color={"error"}>
              {!ReferenceBookData?.board ? error?.board : ""}
            </Typography>

            <Select
              value={ReferenceBookData?.medium}
              data={mediumData || []}
              onChange={(e) => handleReferenceBook("medium", e)}
              className="font-regular mb-3"
              label="Medium"
              required
              placeholder="All Medium"
              type={"medium"}
              getMedium={getMediumData}
              isPage={true}
            />
            <Typography variant="caption" color={"error"}>
              {!ReferenceBookData?.medium ? error?.medium : ""}
            </Typography>
            <Select
              value={ReferenceBookData?.standard}
              data={standardData || []}
              onChange={(e) => handleReferenceBook("standard", e)}
              label="Standard"
              className="w-full mb-2"
              required
              type={"standard"}
              placeholder="All Standard"
              getStandard={getStandardData}
            />

            <div className="text-red-500 text-sm">
              {!ReferenceBookData?.standard ? error?.standard : ""}
            </div>

            <Select
              value={ReferenceBookData?.semester}
              data={semesterData || []}
              onChange={(e) => handleReferenceBook("semester", e)}
              label="Semester"
              className="w-full mb-3"
              required
              type={"semester"}
              placeholder="All Semester"
              getSemester={getSemesterData}
            />
            <div className="text-red-500 text-sm">
              {!ReferenceBookData?.semester ? error?.semester : ""}
            </div>

            <Select
              value={ReferenceBookData?.subject}
              data={subjectData || []}
              onChange={(e) => handleReferenceBook("subject", e)}
              label="Subject"
              className="w-full mb-3"
              required
              type={"subject"}
              placeholder="All Subject"
              getSubject={getSubjectData}
            />
            <div className="text-red-500 text-sm">
              {!ReferenceBookData?.subject ? error?.subject : ""}
            </div>
            <Input
              type="text"
              label="Book Name"
              autoComplete="given-name"
              placeholder="Book Name here..."
              required
              className="w-full mt-2 mb-3"
              value={ReferenceBookData?.bookname}
              onChange={(e) => handleReferenceBook("bookname", e.target.value)}
            />
            <div className="text-red-500 text-sm">
              {!ReferenceBookData?.bookname ? error?.bookname : ""}
            </div>
          </Model>
        </div>
      </div>
      <div className="sm:mb-0 overflow-hidden">
        <div className="flex flex-col mt-4">
          <section
            className="bg-white rounded-2xl flex flex-col shadow-sm "
            style={{
              width: "100%",
              height: "75vh",
              margin: "0 auto",
              padding: "20px",
            }}
          >
            {" "}
            <div className="flex justify-between items-center mt-6 px-8 mb-3">
              <div  style={{ display: "flex", gap:'5px' }}>
              <Input
                id=""
                name="Search"
                type="text"
                placeholder="Search here..."
                autoComplete="given-name"
                required
                className="w-72"
                value={searchValue}
                onChange={handleSearchChange}
              />
                <Button
                  onClick={() => applyFilter()}
                  id=""
                  type="button"
                  // label={"SEARCH"}
                  searchicon={true}
                />
              </div>
              <div>
                <Records
                  recordsPerPage={recordsPerPage}
                  setCurrentPage={setCurrentPage}
                  setRecordsPerPage={setRecordsPerPage}
                  dataCount={dataCount}
                />
              </div>
            </div>
            <div
              style={{
                overflowY: "auto",
                maxHeight: "58vh",
                height: "60vh",
                width: "100%",
              }}
            >
              {" "}
              {!isLoader && referenceData?.length <= 0 ? (
                <NoDataFound />
              ) : (
                <table style={{ width: "100%" }}>
                  <thead
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#f4f7f8",
                      zIndex: 1,
                    }}
                  >
                    <tr
                      style={{
                        borderBottom: "1px solid #dddddd",
                        textAlign: "left",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#7B809A",
                        textTransform: "uppercase",
                        backgroundColor: "#f4f7f8",
                      }}
                    >
                      {" "}
                      <th
                        scope="col"
                        style={{ width: "3%", padding: "10px" }}
                      ></th>
                      {titleData.map(
                        (v, ind) =>
                          v.title && (
                            <th
                              key={ind}
                              className="py-2 px-3 "
                              style={{
                                padding: "12px",
                                backgroundColor: "#f4f7f8",
                                position:
                                  v.title === "EDIT" || v.title === "DELETE"
                                    ? "sticky"
                                    : "",
                                right:
                                  v.title === "EDIT"
                                    ? "68px"
                                    : v.title === "DELETE"
                                    ? "0"
                                    : "",
                              }}
                            >
                              <div className="flex justify-between">
                                <span>{v.title}</span>
                                {v.title !== "EDIT" && v.title !== "DELETE" && (
                                  <span>
                                    <ChevronUpDownIcon
                                      onClick={() => {
                                        let res = SortData(
                                          referenceData,
                                          v.fieldName,
                                          v.sort
                                        );

                                        let copyData = [...titleData];
                                        copyData[ind].sort =
                                          v.sort > 0 ? -1 : 1;
                                        setTitleData(copyData);
                                        setReferenceData(res);
                                      }}
                                      className="w-5 h-5"
                                    />
                                  </span>
                                )}
                              </div>
                            </th>
                          )
                      )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {isLoader ? (
                      <tr style={{ height: "25rem" }}>
                        <td colSpan={5} style={{ textAlign: "center" }}>
                          <CircularProgress />
                        </td>
                      </tr>
                    ) : (
                      referenceData?.map((data, id) => (
                        <tr
                          key={id}
                          className="my-4 px-3 text-[#000] font-lato text-sm font-medium"
                        >
                          <td className="px-6 py-3 whitespace-no-wrap">
                            {/* <Checkbox type="checkbox" /> */}
                            <Checkbox
                              checked={checkedItems.includes(data.id)}
                              onChange={() => handleCheckboxChange(data.id)}
                            />
                          </td>

                          <td className="lg:table-cell whitespace-nowrap py-2.5 px-3">
                            {data?.StandardInfo?.boardInfo?.board_name}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap py-2.5 px-3">
                            {data?.StandardInfo?.mediumInfo?.name}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap py-2.5 px-3">
                            {data?.StandardInfo?.name}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-3 py-2.5 ">
                            {data?.SemesterInfo?.semester}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-3 py-2.5 ">
                            {data?.SubjectInfo?.subject}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-3 py-2.5 ">
                            {data?.book_name}
                          </td>
                          {access?.edit && (
                            <td
                              className="relative whitespace-nowrap  px-4 py-2.5  text-left"
                              style={{
                                position: "sticky",
                                right: "68px",
                                backgroundColor: "white",
                              }}
                            >
                              <ActionButton
                                key={data.key}
                                action="edit"
                                onClick={() => {
                                  setIsModel(true);
                                  setSelectedData(data);
                                  setModelType("edit");
                                }}
                              />
                            </td>
                          )}
                          {access?.delete && (
                            <td
                              className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                              style={{
                                position: "sticky",
                                right: "0",
                                backgroundColor: "white",
                              }}
                            >
                              <ActionButton
                                key={data.key}
                                action="delete"
                                onClick={() => {
                                  setDeleteType("single");
                                  setOpenModel(true);
                                  setSelectedData(data);
                                }}
                              />
                            </td>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              recordsPerPage={recordsPerPage}
              data={referenceData}
              numbers={numbers}
              npage={npage}
              lastIndex={lastIndex}
              firstIndex={firstIndex}
              dataCount={dataCount}
            />
          </section>
        </div>
        {/* Delete */}
        <DeletePopup
          title={"Delete"}
          show={openModel}
          description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
          onCancel={() => {
            setOpenModel(false);
            setSelectedData([]);
          }}
          onClose={() => {
            setOpenModel(false);
            setSelectedData([]);
          }}
          onDelete={() => {
            deleteReferenceBookData();
            // setOpenModel(false);
            // setShowFloatNotification(true);
          }}
          cancelButtonRef={cancelButtonRef}
          okText="Delete"
          cancelText="Cancel"
        />
        {/* Delete */}
        <FloatNotification
          show={showFloatNotification}
          title={"Successfully deleted!"}
          type="success"
          description={"Data Deleted."}
          onClick={() => {
            setShowFloatNotification(false);
          }}
        />
      </div>
    </div>
  );
};

export default ReferenceBook;
