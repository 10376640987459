import React, { useState } from "react";
import {
  Typography,
  Modal,
  Box,
  ImageListItem,
  Divider,
  Button,
} from "@mui/material";
import Success from "../Image/Success.png";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #FFFFFF",
  boxShadow: 24,
  padding: "20px ",
  borderRadius: "12px",
  outline: "none",
};

const OrderPaymentSuccessModel = (Props) => {
  return (
    <Modal
      open={Props.isOrderPaymentSuccessModel}
      onClose={() => Props.handleClosePaymentSuccessModel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-start",
          }}
        >
          <ImageListItem
            sx={{ cursor: "pointer", width: "15px" }}
            onClick={Props.handleClosePaymentSuccessModel}
          >
            <img src={Close} alt="" />
          </ImageListItem>
        </Box> */}

        <Box>
   
        <Box
          sx={{
            // backgroundColor: "#F3F6F9",
            padding: "25px",
            marginTop: "10px",
            borderRadius: "10px",
          }}
        >
             
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
            component="img"
            sx={{
              height: "5rem",
              width: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            alt=""
            src={Success}
          />
        </Box>
        <Box sx={{marginTop:"10px"}}>
            <Typography
            align="center"
              sx={{
                fontWeight: "600",
                fontSize: "16px",
              }}
            >
             Your Order is Confirmed.
            </Typography>
            <Typography
            align="center"
              sx={{
                fontWeight: "600",
                fontSize: "16px",
              }}
            >
             Order will be delivered within 7 days.
            </Typography>
            </Box>
          </Box>
      
          <Box
            sx={{
              marginTop: "10px",
              marginBottom:"10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "15px",
            }}
          >
            <Button
              variant="contained"
              onClick={Props.handleClosePaymentSuccessModel}

              sx={{
                borderRadius: "30px",
                padding: "10px 30px 10px 30px",
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default OrderPaymentSuccessModel;
