import { Dialog, Transition } from "@headlessui/react";
import Pagination from "./Pagination";
import { Fragment, useEffect, useRef, useState } from "react";
import { Button } from "./Button";
import Model from "./Model";
import { Input } from "./Input";
import MasterTitle from "./MasterTitle";
import adsBanner from "../Image/AddsImage.png";
import adsBanner2 from "../Image/AddsImage2.png";
import {
  ChevronUpDownIcon,
  EyeIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Records from "./Records";
import FloatNotification from "./FloatNotification";
import { PlusIcon } from "@heroicons/react/20/solid";
import DeletePopup from "./DeletePopup";
import { config } from "../Api/config";
import { Checkbox, Typography } from "antd";
import ActionPopup from "./ActionPopup";
import image1 from "../Image/image1.png";
import image2 from "../Image/image2.png";
import image3 from "../Image/image3.png";
import { SortData } from "./SortData";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast } from "./toast";
import NoDataFound from "./NoDataFound";
import CircularProgress from "@mui/material/CircularProgress";
import Breadcrumbs from "./Breadcrumbs";
import { getDecryptedDataFromLocalStorage } from "../Api/encryption";
import ActionButton from "./ActionButton";
import { Avatar } from "@mui/material";
import Select from "./Select";
import { Link } from "react-router-dom";

const LearningImages = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Advertisement")?.[0];
  let token = getDecryptedDataFromLocalStorage("token");
  const [isModel, setIsModel] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const cancelButtonRef = useRef(null);
  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [img, setImg] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [modelType, setModelType] = useState("");
  const [userCount, setUserCount] = useState(0);
  const [isModelLoader, setisModelLoader] = useState(false);
  const [addPaperGenerateBanners, setAddPaperGenerateBanners] = useState({
    link: "",
    // title: "",
    status: "",
  });
  const data = [
    { id: 1, name: "Active", statusValue: true },
    { id: 2, name: "Inactive", statusValue: false },
  ];
  // const [selectedOption, setSelectedOption] = useState("website");
  const [isLoader, setIsLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [titleData, setTitleData] = useState([
    {
      title: "IMAGE",
      sort: 1,
      fieldName: "image",
    },
    // {
    //   title: "TITLE",
    //   sort: 1,
    //   fieldName: "title",
    // },
    {
      title: "STATUS",
      sort: 1,
      fieldName: "status",
    },
    {
      title: "REDIRECT LINK",
      sort: 1,
      fieldName: "redirect link",
    },
    {
      title: access?.edit ? "EDIT" : "",
      sort: 1,
      fieldName: "",
    },
    {
      title: access?.delete ? "DELETE" : "",
      sort: 1,
      fieldName: "",
    },
  ]);
  const [advertisementData, setAdvertisementData] = useState([]);
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(userCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  let [isOpen, setIsOpen] = useState(false);
  const [SelectAdvertisementImageId, setSelectAdvertisementImageId] =
    useState();
  const [imageData, setImageData] = useState({
    image: "",
  });
  const [image, setImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState({});
  const [checkedItems, setCheckedItems] = useState([]);
  const [deleteType, setDeleteType] = useState("single");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(e.target.files[0]);
    setSelectedFile(file);
  };

  // const handleRadioChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const handleQuestion = (name, value) => {
    setAddPaperGenerateBanners((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
  };

  const handleResetImage = () => {
    setImage(null);
    setSelectedFile(null);
    setAddPaperGenerateBanners({
      status: "",
      // title: "",
      link: "",
    });
    // setSelectedOption("websActiveite");
  };

  const handleChooseFileClick = () => {
    document.getElementById("fileUpload").click();
  };

  const validation = () => {
    const linkPattern = /^(http|https):\/\/.+$/i;
    let errors = {};
    let formIsValid = true;
    if (!selectedFile) {
      formIsValid = false;
      errors["image"] = "Please select image.";
    }
    // if (!addPaperGenerateBanners?.title) {
    //   formIsValid = false;
    //   errors["title"] = "Please select title.";
    // }

    if (!addPaperGenerateBanners?.status) {
      formIsValid = false;
      errors["status"] = "Please select status.";
    }
    if (!addPaperGenerateBanners?.link) {
      formIsValid = false;
      errors["link"] = "Please enter link.";
    } else if (!linkPattern.test(addPaperGenerateBanners.link.trim())) {
      formIsValid = false;
      errors["link"] = "Please enter a valid HTTP or HTTPS link.";
    }

    setError(errors);
    return formIsValid;
  };

  const handelOnsubmit = async (e) => {
    if (validation()) {
      e.preventDefault();
      if (modelType === "add") {
        let imgData = [];
        if (selectedFile) {
          const formData = new FormData();
          formData.append("image", selectedFile);
          const responseimg = await Api.axiospostRequest(
            "api/upload/single_file",
            formData
          );

          if (responseimg.status === 200) {
            imgData = [responseimg?.data?.data];
          }
        }

        const body = {
          upload_img_link: selectedFile ? (imgData.length > 0 ? imgData[0] : "") : "",
          link: addPaperGenerateBanners?.link,
          type: "Youth",
          status: addPaperGenerateBanners?.status?.statusValue,
        };
        try {
            const { data } = await Api.postRequest(
              "api/banner-advertise/create",
              body
            );
            const req = JSON.parse(data);
            // setIsModel(false);
            SuccessToast(req?.message);
            handleResetImage();
            _getUser(search, recordsPerPage, currentPage);
          
        } catch (error) {
          ErrorToast(error?.message);
        } finally {
        }
      } else {
        let imgData = [];
        if (selectedFile?.name !== imageData?.image) {
          const formData = new FormData();
          formData.append("image", selectedFile);
          const responseimg = await Api.axiospostRequest(
            "api/upload/single_file",
            formData
            );
            
            if (responseimg.status === 200) {
              imgData = [responseimg?.data?.data];
            }
          }
          const body = {
            ...(selectedFile?.name !== imageData?.image && {
              upload_img_link: selectedFile ? (imgData.length > 0 ? imgData[0] : "") : "",
            }),
            link: addPaperGenerateBanners?.link,
            status: addPaperGenerateBanners?.status?.statusValue,

          };
        try {
          const { data } = await Api.putRequest(
            `api/banner-advertise/update/${selectedId}`,
            body
          );
          if (data?.status === 200) {
            SuccessToast(data?.message);
            setIsModel(false);
            _getUser(search, recordsPerPage, currentPage);
            handleResetImage();
          } else {
            ErrorToast(data?.message);
          }
        } catch (error) {
          ErrorToast(error?.message);
        } finally {
          setSelectedId(null);
        }
      }
    }
  };

  const _getUser = async (search, limit, offset) => {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/banner-advertise?limit=${limit}&offset=${offset}&search=${
          search || ""
        }`
      );
      const res = JSON.parse(data);

      if (res?.status === 200) {
        const modifyData = res?.data?.map((e) => {
          return {
            ...e,
          };
        });

        setAdvertisementData(modifyData);
        setUserCount(res?.totalCount);
        setIsLoader(false);
      } else {
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  // const _deleteAdvertisementImage = async (id) => {
  //   try {
  //     let data;
  //     if (deleteType === "multi") {
  //       const body = {
  //         ids: checkedItems,
  //       };
  //       const response = await Api.postRequest(
  //         `api/both-advertise/delete`,
  //         body
  //       );
  //       data = response.data;
  //     } else {
  //       const body = {
  //         ids: [selectedId],
  //       };
  //       const response = await Api.postRequest(
  //         `api/both-advertise/delete`,
  //         body
  //       );
  //       data = response.data;
  //     }

  //     // const { data } = await Api.postRequest(
  //     //   `api/both-advertise/delete/${selectedId}`
  //     // );
  //     const req = JSON.parse(data);
  //     if (req?.status === 200) {
  //       SuccessToast(req?.message);
  //       _getUser(search, recordsPerPage, currentPage);
  //       setOpenModel(false);
  //       setSelectedId(null);
  //     } else {
  //       ErrorToast(req?.message);
  //     }
  // } catch (error) {
  //   ErrorToast(error?.message);
  // }
  // };

  const _deleteAdvertisementImage = async (id) => {
    try{
    const { data } = await Api.deleteRequest(
      `api/banner-advertise/delete/${id}`
    );

    const req = JSON.parse(data);
    if (req?.status === 200) {
      setOpenModel(false);
      setSelectedId();
      _getUser(search, recordsPerPage, currentPage);
      SuccessToast("Banner Delete Successfully");
    } else {
      ErrorToast(req?.message);
    }
  } catch (error) {
    ErrorToast(error?.message);
  }
  };

  useEffect(() => {
    if (modelType === "edit") {
      const getSingleTopic = async () => {
        setisModelLoader(true);
        try {
          const { data } = await Api.getRequest(
            `api/banner-advertise/getById/${selectedId}`
          );
          const req = JSON.parse(data);
          if (req?.status === 200) {
            setisModelLoader(false);
            const statusName = req?.data?.status === 1 ? "Active" : "Inactive";
            const statusId = req?.data?.status === 1 ? 1 : 2;
            const statusValue = req?.data?.status === 1 ? true : false;

            setAddPaperGenerateBanners({
              // title: req?.data?.title,
              // type: req?.data?.type,
              link: req?.data?.link,
              status: {
                id: statusId || "",
                name: statusName || "",
                statusValue: statusValue || "",
              },
            });
            setSelectedFile({
              name: req?.data?.upload_img_link || "",
            });
            setImageData({
              image: req?.data?.upload_img_link || "",
            });
            setImage(req?.data?.upload_img_link);
          } else {
            ErrorToast(req?.message);
          }
        } catch (error) {
          ErrorToast(error?.message);
        } finally {
          setModelType("");
          setisModelLoader(false);
        }
      };
      getSingleTopic();
    }
  }, [modelType, selectedId]);

  useEffect(() => {
    if (imageData.image) {
      const existingFile = new File([], imageData?.image, {
        type: "image/jpeg",
      });
      setSelectedFile(existingFile);
    }
  }, [imageData.image]);

  useEffect(() => {
    _getUser(search, recordsPerPage, currentPage);
  }, [search, recordsPerPage, currentPage]);

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) => {
      const isChecked = prevState.includes(id);

      const updatedItems = isChecked
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id];

      return updatedItems;
    });
  };

  return (
    <div>
      <div className="h-full flex items-center justify-center ">
        <div className="h-full flex-1 flex flex-col space-y-5 lg:space-y-0 lg:flex-row lg:space-x-10 w-full sm:px-6 ">
          <div className="flex-1 px-2 sm:px-0 w-full">
            <div>
              <div className="flex justify-between items-center mt-4">
                <div>
                  <MasterTitle
                    title="Learning Images"
                    description="Below are the list of all the learning image."
                  />
                </div>
                <div>
                  <div style={{ display: "flex", gap: "5px" }}>
                    {/* {access?.delete && (
                      <Button
                        id=""
                        type="submit"
                        icon={TrashIcon}
                        label={"DELETE IMAGES"}
                        onClick={() => {
                          setDeleteType("multi");
                          if (checkedItems.length > 0) {
                            setOpenModel(true);
                          }
                        }}
                      />
                    )} */}
                    {access?.add && (
                      <Button
                        id=""
                        type="submit"
                        icon={PlusIcon}
                        label={"ADD IMAGE"}
                        onClick={() => {
                          setIsModel(true);
                          setModelType("add");
                          handleResetImage();
                        }}
                      />
                    )}
                  </div>
                  <Model
                    selectedId={selectedId}
                    title={modelType === "add" ? "Add Image" : "Edit Image"}
                    show={isModel}
                    onCancel={() => {
                      // handleResetImage();
                      setIsModel(false);
                    }}
                    onClose={() => {
                      setIsModel(false);
                      setSelectedId("");
                      handleResetImage();
                      setAddPaperGenerateBanners({ status: "", link: "" });
                    }}
                    onOk={(e) => handelOnsubmit(e)}
                    okText="SUBMIT"
                    cancelText="CLOSE"
                  >
                    {isModelLoader ? (
                      <tr
                        style={{
                          height: "120px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <td>
                          <CircularProgress />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {/* <>
                        <Input
                          label="Title"
                          type="text"
                          autoComplete="given-name"
                          name="title"
                          required
                          className="mb-2 "
                          value={addPaperGenerateBanners?.title}
                          onChange={(e) =>
                            handleQuestion("title", e.target.value)
                          }
                        />
                        <Typography
                          className="my-2"
                          variant="caption"
                          style={{ color: "#d32f2f" }}
                        >
                          {!addPaperGenerateBanners?.title ? error?.title : ""}
                        </Typography>
                        <label className="mb-3 block text-sm font-medium text-[#252F40] font-poppins">
                          Redirect type
                          {<span className="text-red-500"> *</span>}
                        </label>
                        <div className="flex gap-2 mb-3">
                          <div class="flex items-center ">
                            <input
                              checked={selectedOption === "app"}
                              onChange={handleRadioChange}
                              id="default-radio-1"
                              type="radio"
                              value="app"
                              name="default-radio"
                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300  dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              htmlFor="default-radio-1"
                              class="ms-2 text-sm font-normal text-gray-900 dark:text-gray-300"
                            >
                              App
                            </label>
                          </div>
                          <div class="flex items-center">
                            <input
                              checked={selectedOption === "website"}
                              onChange={handleRadioChange}
                              id="default-radio-2"
                              type="radio"
                              value="website"
                              name="default-radio"
                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300  dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              htmlFor="default-radio-2"
                              class="ms-2 text-sm font-normal text-gray-900 dark:text-gray-300"
                            >
                              website
                            </label>
                          </div>
                        </div>
                        <Input
                          label="Link"
                          type="text"
                          autoComplete="given-name"
                          name="Link"
                          required
                          className="mb-2 "
                          value={addPaperGenerateBanners?.link}
                          onChange={(e) =>
                            handleQuestion("link", e.target.value)
                          }
                        />
                        <Typography
                          className="my-2"
                          variant="caption"
                          style={{ color: "#d32f2f" }}
                        >
                          {!addPaperGenerateBanners?.link ? error?.link : ""}
                        </Typography>
                        <form className="mb-2">
                          <label
                            htmlFor="fileUpload"
                            className="mb-3 block text-sm font-medium text-[#252F40] font-poppins"
                          >
                            Upload photo
                          </label>
                          <input
                            type="file"
                            id="fileUpload"
                            class="hidden"
                            onChange={handleImageChange}
                          ></input>
                          <div className="flex justify-between">
                            <div className="flex items-center ">
                              <button
                                id=""
                                type="button"
                                label={"Choose file"}
                                onClick={handleChooseFileClick}
                                className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                              >
                                Choose file
                              </button>
                              <div className="ml-1 block text-base font-medium text-[#252F40] font-poppins ">
                                {selectedFile?.name}
                              </div>
                            </div>
                          </div>
                        </form>
                        <Typography
                          className="my-2"
                          variant="caption"
                          style={{ color: "#d32f2f" }}
                        >
                          {!selectedFile ? error?.image : ""}
                        </Typography>
                      </> */}
                        <>
                          <form className="mb-2">
                            <label
                              htmlFor="fileUpload"
                              className="mb-3 block text-sm font-medium text-[#252F40] font-poppins"
                            >
                              Upload photo
                            </label>

                            <input
                              type="file"
                              id="fileUpload"
                              class="hidden"
                              onChange={handleImageChange}
                            />

                            <div className="flex items-center ">
                              <button
                                id=""
                                type="button"
                                label={"Choose file"}
                                onClick={handleChooseFileClick}
                                className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                              >
                                Choose file
                              </button>
                              <div className="ml-1 block text-base font-medium text-[#252F40] font-poppins ">
                                {selectedFile
                                  ? selectedFile.name
                                  : "No file chosen"}
                              </div>
                            </div>
                          </form>
                          <Typography
                            className="my-2"
                            variant="caption"
                            color={"error"}
                          >
                            {!selectedFile ? error?.image : ""}
                          </Typography>
                          <Input
                            label="Link"
                            type="text"
                            autoComplete="given-name"
                            name="Link"
                            required
                            className="mb-2 "
                            value={addPaperGenerateBanners?.link || ""}
                            onChange={(e) =>
                              handleQuestion("link", e.target.value)
                            }
                          />
                          <Typography
                            className="my-2"
                            variant="caption"
                            color={"error"}
                          >
                            {!addPaperGenerateBanners?.link ||
                            addPaperGenerateBanners?.link
                              ? error?.link
                              : ""}
                          </Typography>
                          <Select
                            data={data || []}
                            name="status"
                            value={addPaperGenerateBanners?.status || ""}
                            onChange={(e) => handleQuestion("status", e)}
                            className="font-regular mb-2"
                            label="Status"
                            required
                            placeholder="Select Status"
                            type={"PaperGenerateBanners"}
                          />
                          <Typography variant="caption" color={"error"}>
                            {!addPaperGenerateBanners?.status
                              ? error?.status
                              : ""}
                          </Typography>
                        </>
                      </>
                    )}
                  </Model>
                </div>
              </div>
              <div className="sm:mb-0 overflow-hidden ">
                <div className="flex flex-col mt-4">
                  <section className="bg-white rounded-2xl flex flex-col shadow-sm min-h-[calc(100vh-40px-16px-24px-38px-50px-40px-52px-16px)]">
                    <div className="flex justify-between items-center mt-6 px-8 mb-8">
                      {/* <Input
                        id=""
                        name="Search"
                        type="text"
                        placeholder="Search here"
                        autoComplete="given-name"
                        required
                        className="w-72"
                        onChange={(e) => setSearch(e?.target?.value)}
                        value={search}
                      /> */}
                      <div></div>
                      <div>
                        <Records
                          recordsPerPage={recordsPerPage}
                          setRecordsPerPage={setRecordsPerPage}
                          setCurrentPage={setCurrentPage}
                          dataCount={userCount}
                        />
                      </div>
                    </div>
                    <div className="h-[calc(100vh-40px-16px-24px-38px-50px-40px-52px-16px-42px-24px-62px-32px)] overflow-y-auto">
                      {!isLoader && advertisementData?.length <= 0 ? (
                        <NoDataFound />
                      ) : (
                        <div>
                          {isLoader ? (
                            <div
                              className="flex items-center justify-center"
                              style={{
                                height: "25rem",
                              }}
                            >
                              <CircularProgress />
                            </div>
                          ) : (
                            <div className="w-full  grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8 px-8 ">
                              {advertisementData.map((detail, id) => (
                                <div
                                  key={id}
                                  className="bg-white border border-[#EDEEF0] p-4 rounded-xl tracking-wide max-w-lg"
                                >
                                  <div className="flex flex-col md:flex-row justify-between items-center">
                                    <img
                                      alt=""
                                      src={detail.upload_img_link}
                                      className="max-w-[150px] w-full h-auto flex-shrink-0"
                                    />
                                    <div className="md:ml-5 mt-3 mb-5 flex flex-col md:w-1/2 relative">
                                      <div className="flex flex-col md:flex-row items-center justify-between md:flex-start">
                                        <Link
                                          to={detail.link}
                                          className="text-xs font-regular text-[#7B809A] font-roboto overflow-hidden max-w-[60%] md:max-w-full"
                                        >
                                          {detail.link.length > 50
                                            ? `${detail.link.substring(
                                                0,
                                                50
                                              )}...`
                                            : detail.link}
                                        </Link>
                                        {(access?.edit || access?.delete) && (
                                          <div className="mt-4 md:mt-0 absolute top-0 right-0">
                                            <ActionPopup
                                              onClick={() => {
                                                setIsModel(true);
                                                setModelType("edit");
                                                setSelectedId(detail?.id);
                                              }}
                                              onDelete={() => {
                                                setOpenModel(true);
                                                setSelectedId(detail.id);
                                              }}
                                              accessEdit={access?.edit}
                                              accessDelete={access?.delete}
                                            />
                                          </div>
                                        )}
                                      </div>
                                      <div className="space-x-3 flex mt-4 text-xs font-roboto">
                                        <p className="text-[#484C64] font-medium">
                                          Status
                                        </p>
                                        <p className="text-[#7B809A] font-regular">
                                          {detail.status}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    {/* <div className="h-[calc(100vh-40px-16px-24px-38px-50px-40px-52px-16px-42px-24px-62px-32px)] overflow-y-auto">
                      {!isLoader && advertisementData?.length <= 0 ? (
                        <NoDataFound />
                      ) : (
                        <table className="w-full">
                          <thead
                            className="sticky top-0 bg-white"
                            style={{ zIndex: 1 }}
                          >
                            <tr className="border-b border-gray-200 text-left text-xs leading-4 font-bold text-[#7B809A] tracking-wider">
                              <th
                                scope="col"
                                className="relative w-12 px-6 sm:w-16 sm:px-8"
                              ></th>
                              {titleData.map((v, ind) => (
                                <th
                                  key={ind}
                                  className="py-2 px-6 "
                                  style={{
                                    backgroundColor: "white",
                                    position:
                                      v.title === "EDIT" || v.title === "DELETE"
                                        ? "sticky"
                                        : "",
                                    right:
                                      v.title === "EDIT"
                                        ? "68px"
                                        : v.title === "DELETE"
                                        ? "0"
                                        : "",
                                  }}
                                >
                                  <div className="flex justify-between">
                                    <span>{v.title}</span>
                                    {v.title !== "EDIT" &&
                                      v.title !== "DELETE" && (
                                        <span>
                                          <ChevronUpDownIcon
                                            onClick={() => {
                                              let res = SortData(
                                                advertisementData,
                                                v.fieldName,
                                                v.sort
                                              );

                                              let copyData = [...titleData];
                                              copyData[ind].sort =
                                                v.sort > 0 ? -1 : 1;
                                              setTitleData(copyData);
                                              setAdvertisementData(res);
                                            }}
                                            className="w-5 h-5"
                                          />
                                        </span>
                                      )}
                                  </div>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200">
                            {isLoader ? (
                              <tr style={{ height: "25rem" }}>
                                <td colSpan={5} style={{ textAlign: "center" }}>
                                  <CircularProgress />
                                </td>
                              </tr>
                            ) : (
                              advertisementData?.length > 0 &&
                              advertisementData.map((data, id) => (
                                <tr
                                  key={id}
                                  className="my-4 px-6 text-[#7B809A] font-reguler text-sm"
                                >
                                  <td className="px-6 py-3 whitespace-no-wrap">
                                    <Checkbox
                                      checked={checkedItems.includes(data.id)}
                                      onChange={() =>
                                        handleCheckboxChange(data.id)
                                      }
                                    />
                                  </td>
                                  <td className=" lg:table-cell whitespace-nowrap py-2.5 px-6">
                                    <div className="flex items-center">
                                      <div className="flex-shrink-0 w-10 h-10 ">
                                        <Avatar
                                          alt="Image"
                                          src={data?.upload_img_link}
                                          sx={{
                                            borderRadius: "0%",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td className=" lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                    {data.status}
                                  </td>
                                  <td className=" lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                    <a href={data.link}>{data.link}</a>
                                  </td>
                                  {access?.edit && (
                                    <td
                                      className="relative whitespace-nowrap  px-6 py-2.5 text-left"
                                      style={{
                                        position: "sticky",
                                        right: "68px",
                                        backgroundColor: "white",
                                      }}
                                    >
                                      <ActionButton
                                        key={data.key}
                                        action="edit"
                                        onClick={() => {
                                          setIsModel(true);
                                          setModelType("edit");
                                          setSelectedId(data.id);
                                        }}
                                      />
                                    </td>
                                  )}
                                  {access?.delete && (
                                    <td
                                      className="relative whitespace-nowrap  px-6 py-2.5 text-left"
                                      style={{
                                        position: "sticky",
                                        right: "0",
                                        backgroundColor: "white",
                                      }}
                                    >
                                      <ActionButton
                                        key={data.key}
                                        action="delete"
                                        onClick={() => {
                                          setDeleteType("single");
                                          setSelectedId(data.id);
                                          setOpenModel(true);
                                        }}
                                      />
                                    </td>
                                  )}
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      )}
                    </div> */}
                    {/* <div className="w-full">
              <div className="h-[calc(100vh-40px-16px-24px-38px-50px-40px-52px-16px-42px-24px-62px-32px)] overflow-y-auto">
                <div className="grid grid-cols-3 gap-y-6 gap-x-8 px-8">
                  {cardData.length === 0 ? (
                    <tr>
                      <td colspan="2">No matching records found</td>
                    </tr>
                  ) : (
                    cardData
                      .slice(lastIndex, lastIndex + recordsPerPage)
                      .map((detail, id) => (
                        <div className="h-fit group" key={id}>
                          <div className="relative overflow-hidden">
                            <img
                              className="h-full w-full rounded-lg object-cover"
                              src={detail.img}
                              alt=""
                            />
                            <div className="absolute h-full w-full rounded-lg bg-black/40 flex items-center justify-center -bottom-10 group-hover:bottom-0 opacity-0 group-hover:opacity-100 transition-all duration-300">
                              <div className="space-x-3 flex items-center justify-center">
                                <div className="bg-white text-white rounded-full w-12 h-12 flex items-center justify-center">
                                  <detail.eye
                                    className="w-6 h-6 text-black"
                                    onClick={() => {
                                      openModal();
                                      setImg(detail.img);
                                    }}
                                  />
                                </div>
                                {access?.delete && (
                                  <div
                                    className="bg-white text-white rounded-full w-12 h-12 flex items-center justify-center"
                                    onClick={() => setOpenModel(true)}
                                  >
                                    <detail.tarsh className="w-6 h-6 text-red-600" />
                                  </div>
                                )}
                              </div>
                              <Transition appear show={isOpen} as={Fragment}>
                                <Dialog
                                  as="div"
                                  className="relative z-10"
                                  onClose={closeModal}
                                >
                                  <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                                  </Transition.Child>

                                  <div className="fixed inset-0 overflow-y-auto">
                                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                                      <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                      >
                                        <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl align-middle shadow-xl transition-all">
                                          <div className="mb-3 flex justify-end">
                                            <XMarkIcon
                                              className="w-6 h-6 text-white text-right"
                                              onClose={closeModal}
                                            />
                                          </div>
                                          <img
                                            className="w-full h-full rounded-lg object-cover"
                                            src={img}
                                            alt="adsImg"
                                          />
                                        </Dialog.Panel>
                                      </Transition.Child>
                                    </div>
                                  </div>
                                </Dialog>
                              </Transition>
                            </div>
                          </div>
                        </div>
                      ))
                  )}
                </div>
              </div>
            </div> */}
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      recordsPerPage={recordsPerPage}
                      data={advertisementData}
                      dataCount={userCount}
                      numbers={numbers}
                      npage={npage}
                      lastIndex={lastIndex}
                      firstIndex={firstIndex}
                    />
                  </section>
                </div>

                {/* Delete */}
                <DeletePopup
                  title={"Delete"}
                  show={openModel}
                  description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
                  onCancel={() => {
                    setOpenModel(false);
                  }}
                  onClose={() => {
                    setOpenModel(false);
                  }}
                  onDelete={() => {
                    _deleteAdvertisementImage(selectedId);
                    setOpenModel(false);
                    // setShowFloatNotification(true);
                  }}
                  cancelButtonRef={cancelButtonRef}
                  okText="Delete"
                  cancelText="Cancel"
                />
                {/* Delete */}
                <FloatNotification
                  show={showFloatNotification}
                  title={"Successfully deleted!"}
                  type="success"
                  description={"Image Deleted."}
                  onClick={() => {
                    setShowFloatNotification(false);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearningImages;
