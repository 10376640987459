export const SortData = (data = [], fildName, sort = 1) => {
  let sortableItems = [...data];

  return sortableItems.sort((a, b) => {
    if (a[fildName] < b[fildName]) {
      return sort > 0 ? -1 : 1;
    } else {
      return sort > 0 ? 1 : -1;
    }
  });
};
