import Pagination from "./Pagination";
import { useEffect, useRef, useState } from "react";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { Button } from "./Button";
import Model from "./Model";
import { Input } from "./Input";
import MasterTitle from "./MasterTitle";
import { Checkbox } from "./Checkbox";
import Records from "./Records";
import { SortData } from "./SortData";
import FloatNotification from "./FloatNotification";
import Select from "./Select";
import { PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import DeletePopup from "./DeletePopup";
import ActionPopup from "./ActionPopup";
import { Api } from "../Api/Api";
import { Typography } from "@mui/material";
import { ErrorToast, SuccessToast } from "./toast";
import NoDataFound from "./NoDataFound";
import CircularProgress from "@mui/material/CircularProgress";
import ActionButton from "./ActionButton";

const Chapter = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Master")?.[0];
  const [modelType, setModelType] = useState("");
  const [isModel, setIsModel] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const cancelButtonRef = useRef(null);
  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [ChapterData, setChapterData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(dataCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [error, setError] = useState({});
  const [chapterInputData, setChapterInputData] = useState({});
  const [subjectData, setSubjectData] = useState([]);
  const [standardData, setStandardData] = useState([]);
  const [semesterData, setSemesterData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [SelectChapterId, setSelectChapterId] = useState();
  const [isModelLoader, setisModelLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [mediumData, setMediumData] = useState([]);
  const [boardData, setBoardData] = useState([]);

  const [checkedItems, setCheckedItems] = useState([]);
  const [deleteType, setDeleteType] = useState("single");
  const [isLoading, setIsLoading] = useState(false);
  const [titleData, setTitleData] = useState([
    {
      title: "BOARD",
      sort: 1,
      fieldName: "board",
    },
    {
      title: "MEDIUM",
      sort: 1,
      fieldName: "medium",
    },
    {
      title: "STANDARD",
      sort: 1,
      fieldName: "standard",
    },
    {
      title: "SEMESTER",
      sort: 1,
      fieldName: "semester",
    },
    {
      title: "SUBJECT",
      sort: 1,
      fieldName: "subject",
    },
    {
      title: "CHAPTER NUMBER",
      sort: 1,
      fieldName: "chapter number",
    },
    {
      title: "CHAPTER NAME",
      sort: 1,
      fieldName: "chapter name",
    },
    {
      title: "POSITION",
      sort: 1,
      fieldName: "position",
    },
    {
      title: access?.edit ? "EDIT" : "",
      sort: 1,
      fieldName: "",
    },
    {
      title: access?.delete ? "DELETE" : "",
      sort: 1,
      fieldName: "",
    },
  ]);

  const validation = () => {
    let errors = {};
    let formIsValid = true;
    if (!chapterInputData?.medium) {
      formIsValid = false;
      errors["medium"] = "Please select medium.";
    }
    if (!chapterInputData?.board) {
      formIsValid = false;
      errors["board"] = "Please select board.";
    }
    if (!chapterInputData?.standard) {
      formIsValid = false;
      errors["standard"] = "Please select standard.";
    }
    if (!chapterInputData?.semester) {
      formIsValid = false;
      errors["semester"] = "Please select semester.";
    }
    if (!chapterInputData?.subject) {
      formIsValid = false;
      errors["subject"] = "Please select subject.";
    }
    if (!chapterInputData?.chapterNumber) {
      formIsValid = false;
      errors["chapterNumber"] = "Please enter chapter number.";
    }
    if (!chapterInputData?.chapterName) {
      formIsValid = false;
      errors["chapterName"] = "Please select chapter name.";
    }
    if (!chapterInputData?.position) {
      formIsValid = false;
      errors["position"] = "Please enter position.";
    }
    setError(errors);
    return formIsValid;
  };

  const getChapterData = async (search, limit, offset) => {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/chapter?offset=${offset}&limit=${limit}&search=${search || ""}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setChapterData(req?.data);
        setDataCount(req?.totalCount);
        setIsLoader(false);
      } else {
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const handleAddModelSubmit = async () => {
    try {
      if (validation()) {
        setIsLoading(true);
        if (modelType === "edit") {
          try {
            let body = {
              board: chapterInputData?.board?.id,
              medium: chapterInputData?.medium?.id,
              standard: Number(chapterInputData?.standard?.id),
              semester: Number(chapterInputData?.semester?.id),
              subject: Number(chapterInputData?.subject?.id),
              chapter_no: chapterInputData?.chapterNumber,
              chapter: chapterInputData?.chapterName,
              position: chapterInputData?.position,
            };
            const data = await Api.putRequest(
              `api/chapter/update/${selectedId}`,
              body
            );
            if (data?.data?.status === 200) {
              SuccessToast(data?.data?.message);
              getChapterData(search, recordsPerPage, currentPage);
              setChapterInputData({});
              setIsModel(false);
              setSelectedId("");
              setTimeout(() => {
                setIsLoading(false);
              }, 2000);
              setMediumData([]);
              setStandardData([]);
              setSemesterData([]);
              setSubjectData([]);
            } else {
              ErrorToast(data?.data?.message);
            }
          } catch (error) {
            ErrorToast(error);
            setIsLoading(false);
          } finally {
            setIsLoading(false);
          }
        } else {
          try {
            let body = {
              board: chapterInputData?.board?.id,
              medium: chapterInputData?.medium?.id,
              standard: Number(chapterInputData?.standard?.id),
              semester: Number(chapterInputData?.semester?.id),
              subject: Number(chapterInputData?.subject?.id),
              chapter_no: chapterInputData?.chapterNumber,
              chapter: chapterInputData?.chapterName,
              position: chapterInputData?.position,
            };
            const { data } = await Api.postRequest("api/chapter/create", body);
            const res = JSON.parse(data);
            if (res?.status === 200) {
              SuccessToast(res?.message);
              getChapterData(search, recordsPerPage, currentPage);
              // setChapterInputData({});
              setChapterInputData((prevState) => ({
                ...prevState,
                chapterName: "",
                chapterNumber: "",
                position: "",
              }));
              setTimeout(() => {
                setIsLoading(false);
              }, 2000);
              // setIsModel(false);
              setSelectedId("");
            } else {
              ErrorToast(res?.message);
            }
          } catch (error) {
            ErrorToast(error);
            setIsLoading(false);
          } finally {
            setIsLoading(false);
          }
        }
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const getBoardData = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setBoardData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getMediumData = async () => {
    try {
      let body = `?board=${chapterInputData?.board?.id}`;
      const { data } = await Api.getRequest(`api/medium${body}`);
      const req = JSON.parse(data);
      setMediumData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getStandardData = async () => {
    try {
      let body = `?medium=${chapterInputData?.medium?.id}`;
      const { data } = await Api.getRequest(`api/standard${body}`);
      const req = JSON.parse(data);
      setStandardData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSemesterData = async () => {
    try {
      let body = `?standard=${chapterInputData?.standard?.id}`;
      const { data } = await Api.getRequest(`api/semester${body}`);
      const req = JSON.parse(data);
      setSemesterData(req?.data?.rows);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSubjectData = async () => {
    try {
      let body = `?semester=${chapterInputData?.semester?.id}`;
      const { data } = await Api.getRequest(`api/subject${body}`);
      const req = JSON.parse(data);
      setSubjectData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    getBoardData();
  }, []);

  useEffect(() => {
    if (chapterInputData?.board) {
      getMediumData();
    }
  }, [chapterInputData?.board]);

  useEffect(() => {
    if (chapterInputData?.medium) {
      getStandardData();
    }
  }, [chapterInputData?.medium]);

  useEffect(() => {
    if (chapterInputData?.standard) {
      getSemesterData();
    }
  }, [chapterInputData?.standard]);

  useEffect(() => {
    if (chapterInputData?.semester) {
      getSubjectData();
    }
  }, [chapterInputData?.semester]);

  const handleChange = (name, value) => {
    setChapterInputData((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));

    if (name === "board") {
      setChapterInputData((oldValue) => ({
        ...oldValue,
        medium: "",
        standard: "",
        semester: "",
        subject: "",
        [name]: value,
      }));
      setMediumData([]);
      setStandardData([]);
      setSemesterData([]);
      setSubjectData([]);
    }
    if (name === "medium") {
      setChapterInputData((oldValue) => ({
        ...oldValue,
        standard: "",
        semester: "",
        subject: "",
      }));
      setStandardData([]);
      setSemesterData([]);
      setSubjectData([]);
    }
    if (name === "standard") {
      setChapterInputData((oldValue) => ({
        ...oldValue,
        semester: "",
        subject: "",
      }));
      setSemesterData([]);
      setSubjectData([]);
    }
    if (name === "semester") {
      setChapterInputData((oldValue) => ({
        ...oldValue,
        subject: "",
      }));
      setSubjectData([]);
    }
  };

  const handleOpenModal = () => {
    setIsModel(true);
    setChapterInputData({});
    setModelType("add");
  };

  const _deleteChapter = async (id) => {
    try {
      let data;
      if (deleteType === "multi") {
        const body = {
          ids: checkedItems,
        };
        const response = await Api.postRequest(`api/chapter/delete`, body);
        data = response.data;
      } else {
        const body = {
          ids: [SelectChapterId],
        };
        const response = await Api.postRequest(`api/chapter/delete`, body);
        data = response.data;
      }
      // const { data } = await Api.postRequest(
      //   `api/chapter/delete/${SelectChapterId}`
      // );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        SuccessToast(req?.message);
        getChapterData(search, recordsPerPage, currentPage);
        setOpenModel(false);
        setSelectChapterId("");
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const _getById = async (id) => {
    try {
      setisModelLoader(true);
      if (id) {
        const { data } = await Api.getRequest(
          `api/chapter/getChapterByID/${id || ""}`
        );
        const req = JSON.parse(data);
        if (req?.status === 200) {
          setisModelLoader(false);
          setChapterInputData({
            board: req?.data?.StandardInfo?.boardInfo || "",
            medium: req?.data?.StandardInfo?.mediumInfo || "",
            standard: req?.data?.StandardInfo || {},
            semester: req?.data?.SemesterInfo || {},
            subject: req?.data?.SubjectInfo || {},
            chapterNumber: req?.data?.chapter_no || {},
            chapterName: req?.data?.chapter || {},
            position: req?.data?.position || {},
          });
        } else {
          ErrorToast(req.message);
        }
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    if (selectedId) {
      _getById(selectedId);
    }
  }, [selectedId]);

  useEffect(() => {
    getChapterData(search, recordsPerPage, currentPage);
  }, [ recordsPerPage, currentPage]);

  const applyFilter = () => {
    getChapterData(search, recordsPerPage, currentPage);
  };

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) => {
      const isChecked = prevState.includes(id);

      const updatedItems = isChecked
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id];

      return updatedItems;
    });
  };

  return (
    <div>
      <div className="flex justify-between items-center mt-4">
        <div>
          <MasterTitle
            title="Chapter"
            description="Below are all the list of Chapter."
          />
        </div>

        <div>
          {" "}
          <div style={{ display: "flex", gap: "5px" }}>
            {access?.delete && (
              <Button
                id=""
                type="submit"
                icon={TrashIcon}
                label={"DELETE CHAPTER"}
                onClick={() => {
                  setDeleteType("multi");
                  if (checkedItems.length > 0) {
                    setOpenModel(true);
                  }
                }}
              />
            )}
            {access?.add && (
              <Button
                id=""
                type="submit"
                icon={PlusIcon}
                label={"ADD CHAPTER"}
                onClick={() => {
                  handleOpenModal();
                }}
              />
            )}
          </div>
          <Model
            selectedId={selectedId}
            title={modelType === "add" ? "Add Chapter" : "Edit Chapter"}
            show={isModel}
            onCancel={() => {
              // setChapterInputData({});
              setIsModel(false);
            }}
            onClose={() => {
              setIsModel(false);
              setMediumData([]);
              setStandardData([]);
              setSemesterData([]);
              setSubjectData([]);
            }}
            onOk={() => {
              handleAddModelSubmit();
            }}
            okText="SUBMIT"
            cancelText="CLOSE"
            isLoading={isLoading}
            disabled={isLoading}
          >
            {isModelLoader ? (
              <tr
                style={{
                  height: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <td>
                  <CircularProgress />
                </td>
              </tr>
            ) : (
              <>
                {" "}
                <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6 ">
                  <div className="w-full">
                    <Select
                      value={chapterInputData?.board || ""}
                      // value={standardQuestion?.type?.name}
                      data={boardData || []}
                      onChange={(e) => handleChange("board", e)}
                      className="font-regular mb-3"
                      label="Board"
                      required
                      placeholder="All Board"
                      type={"board"}
                      getBoard={getBoardData}
                      isPage={true}
                    />
                    <Typography variant="caption" color={"error"}>
                      {!chapterInputData?.board ? error?.board : ""}
                    </Typography>
                  </div>
                  <div className="w-full flex flex-col">
                    <Select
                      value={chapterInputData?.medium || ""}
                      // value={standardQuestion?.type?.name}
                      data={mediumData || []}
                      onChange={(e) => handleChange("medium", e)}
                      className="font-regular mb-3"
                      label="Medium"
                      required
                      placeholder="All Medium"
                      type={"medium"}
                      getMedium={getMediumData}
                      isPage={true}
                    />
                    <Typography variant="caption" color={"error"}>
                      {!chapterInputData?.medium ? error?.medium : ""}
                    </Typography>
                  </div>
                </div>
                <Select
                  value={chapterInputData?.standard}
                  data={standardData || []}
                  onChange={(e) => handleChange("standard", e)}
                  className="font-regular mb-3"
                  label="Standard"
                  required
                  placeholder="All Standard"
                  type={"standard"}
                  getStandard={getStandardData}
                  isPage={true}
                />
                <Typography variant="caption" color={"error"}>
                  {!chapterInputData?.standard ? error?.standard : ""}
                </Typography>
                <Select
                  value={chapterInputData?.semester}
                  data={semesterData || []}
                  onChange={(e) => handleChange("semester", e)}
                  className="font-regular mb-3"
                  label="Semester"
                  required
                  placeholder="All Semester"
                  type={"semester"}
                  getSemester={getSemesterData}
                  isPage={true}
                />
                <Typography variant="caption" color={"error"}>
                  {!chapterInputData?.semester ? error?.semester : ""}
                </Typography>
                <Select
                  value={chapterInputData?.subject}
                  data={subjectData || []}
                  onChange={(e) => handleChange("subject", e)}
                  className="font-regular mb-3"
                  label="Subject"
                  required
                  placeholder="All Subject"
                  type={"subject"}
                  getSubject={getSubjectData}
                  isPage={true}
                />
                <Typography variant="caption" color={"error"}>
                  {!chapterInputData?.subject ? error?.subject : ""}
                </Typography>
                {/* <Input
              label="Standard"
              type="text"
              autoComplete="given-name"
              required
              className="w-full mb-3"
              value={standard}
              onChange={(e) => setStandard(e.target.value)}
            />
            <Input
              label="Semester"
              type="text"
              autoComplete="given-name"
              required
              className="w-full mb-3"
              value={semester}
              onChange={(e) => setSemester(e.target.value)}
            /> */}
                {/* <Input
              label="Subject"
              type="text"
              autoComplete="given-name"
              required
              className="w-full mb-3"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            /> */}
                <Input
                  label="Chapter Number"
                  type="text"
                  autoComplete="given-name"
                  required
                  className="w-full mb-3"
                  value={chapterInputData?.chapterNumber || ""}
                  onChange={(e) =>
                    handleChange("chapterNumber", e.target.value)
                  }
                />
                <Typography variant="caption" color={"error"}>
                  {!chapterInputData?.chapterNumber ? error?.chapterNumber : ""}
                </Typography>
                <Input
                  label="Chapter Name"
                  type="text"
                  autoComplete="given-name"
                  required
                  className="w-full mb-3"
                  value={chapterInputData?.chapterName || ""}
                  onChange={(e) => handleChange("chapterName", e.target.value)}
                />
                <Typography variant="caption" color={"error"}>
                  {!chapterInputData?.chapterName ? error?.chapterName : ""}
                </Typography>
                <Input
                  label="Position"
                  id="firstname"
                  name="firstname"
                  type="text"
                  autoComplete="given-name"
                  required
                  className="w-full mb-3"
                  value={chapterInputData.position || ""}
                  onChange={(e) => handleChange("position", e.target.value)}
                />
                <Typography variant="caption" color={"error"}>
                  {!chapterInputData?.position ? error?.position : ""}
                </Typography>
              </>
            )}
          </Model>
        </div>
      </div>
      <div className="sm:mb-0 overflow-hidden ">
        <div className="flex flex-col mt-4">
          <section className="bg-white rounded-2xl flex flex-col shadow-sm h-full " style={{
                      width: "100%",
                      height: "75vh",
                      margin: "0 auto",
                      padding: "10px",
                    }}>
            <div className="flex justify-between items-center mt-6 px-8 mb-3">
              <div  style={{ display: "flex", gap:'5px' }}>
              <Input
                id=""
                name="Search"
                type="text"
                placeholder="Search here..."
                autoComplete="given-name"
                required
                className="w-72"
                onChange={(e) => {
                  setSearch(e?.target?.value);
                  setCurrentPage(1);
                }}
                value={search}
              />
                 <Button
                    onClick={() => applyFilter()}
                    id=""
                    type="button"
                    // label={"SEARCH"}
                    searchicon={true}
                  />
              </div>
              <div>
                <Records
                  recordsPerPage={recordsPerPage}
                  setRecordsPerPage={setRecordsPerPage}
                  setCurrentPage={setCurrentPage}
                  dataCount={dataCount}
                />
              </div>
            </div>
            <div className="h-[calc(100vh-40px-16px-24px-38px-50px-40px-52px-16px-42px-24px-62px-32px)] overflow-y-auto"  style={{
                overflowY: "auto",
                maxHeight: "58vh",
                height: "60vh",
                width: "100%",
              }}>
              {!isLoader && ChapterData?.length <= 0 ? (
                <NoDataFound />
              ) : (
                <table className="w-full">
                  <thead
                    className="sticky top-0 bg-white"
                    style={{ zIndex: 1 }}
                  >
                    <tr className="border-b border-gray-200 text-left text-xs leading-4 font-bold text-[#7B809A] tracking-wider">
                      <th
                        scope="col"
                        className="relative w-12 px-6 sm:w-16 sm:px-8"
                      ></th>
                      {titleData.map(
                        (v, ind) =>
                          v.title && (
                            <th
                              key={ind}
                              className="py-4 px-8 "
                              style={{
                                backgroundColor: "white",
                                position:
                                  v.title === "EDIT" || v.title === "DELETE"
                                    ? "sticky"
                                    : "",
                                right:
                                  v.title === "EDIT"
                                    ? "68px"
                                    : v.title === "DELETE"
                                    ? "0"
                                    : "",
                              }}
                            >
                              <div className="flex justify-between">
                                <span>{v.title}</span>
                                {v.title !== "EDIT" && v.title !== "DELETE" && (
                                  <span>
                                    <ChevronUpDownIcon
                                      onClick={() => {
                                        let res = SortData(
                                          ChapterData,
                                          v.fieldName,
                                          v.sort
                                        );

                                        let copyData = [...titleData];
                                        copyData[ind].sort =
                                          v.sort > 0 ? -1 : 1;
                                        setTitleData(copyData);
                                        setChapterData(res);
                                      }}
                                      className="w-5 h-5"
                                    />
                                  </span>
                                )}
                              </div>
                            </th>
                          )
                      )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {isLoader ? (
                      <tr style={{ height: "25rem" }}>
                        <td colSpan={7} style={{ textAlign: "center" }}>
                          <CircularProgress />
                        </td>
                      </tr>
                    ) : (
                      ChapterData.map((data) => (
                        <tr
                          key={data.key}
                          className="my-4 p-8 text-[#000] font-reguler text-sm font-medium"
                        >
                          <td className="px-6 py-3 whitespace-no-wrap">
                            {/* <Checkbox type="checkbox" />   */}
                            <Checkbox
                              checked={checkedItems.includes(data.id)}
                              onChange={() => handleCheckboxChange(data.id)}
                            />
                          </td>
                          <td className="lg:table-cell whitespace-nowrap py-2.5 px-8">
                            {data.boardInfo?.board_name || "-"}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap py-2.5 px-8">
                            {data.mediumInfo?.name || "-"}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap  py-2.5  px-8">
                            {data.StandardInfo.name}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data.SemesterInfo.semester}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data.SubjectInfo.subject}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data.chapter_no}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data.chapter}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.position}
                          </td>
                          {access?.edit && (
                            <td
                              className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                              style={{
                                position: "sticky",
                                right: "68px",
                                backgroundColor: "white",
                              }}
                            >
                              <ActionButton
                                key={data.key}
                                action="edit"
                                onClick={() => {
                                  setIsModel(true);
                                  setModelType("edit");
                                  setSelectedId(data.id);
                                }}
                              />
                            </td>
                          )}
                          {access?.delete && (
                            <td
                              className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                              style={{
                                position: "sticky",
                                right: "0",
                                backgroundColor: "white",
                              }}
                            >
                              <ActionButton
                                key={data.key}
                                action="delete"
                                onClick={() => {
                                  setDeleteType("single");
                                  setOpenModel(true);
                                  setSelectChapterId(data?.id);
                                }}
                              />
                            </td>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              recordsPerPage={recordsPerPage}
              data={ChapterData}
              numbers={numbers}
              npage={npage}
              lastIndex={lastIndex}
              firstIndex={firstIndex}
              dataCount={dataCount}
            />
          </section>
        </div>
        {/* Delete */}
        <DeletePopup
          title={"Delete"}
          show={openModel}
          description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
          onCancel={() => {
            setOpenModel(false);
            setSelectChapterId("");
          }}
          onClose={() => {
            setOpenModel(false);
            setSelectChapterId("");
          }}
          onDelete={() => {
            _deleteChapter();
          }}
          cancelButtonRef={cancelButtonRef}
          okText="Delete"
          cancelText="Cancel"
        />

        {/* Delete */}
        <FloatNotification
          show={showFloatNotification}
          title={"Successfully deleted!"}
          type="success"
          description={"Data Deleted."}
          onClick={() => {
            setShowFloatNotification(false);
          }}
        />
      </div>
    </div>
  );
};

export default Chapter;
