import { Dialog, Transition } from "@headlessui/react";
import Pagination from "./Pagination";
import { Fragment, useEffect, useRef, useState } from "react";
import { Button } from "./Button";
import Model from "./Model";
import { Input } from "./Input";
import MasterTitle from "./MasterTitle";
import adsBanner from "../Image/AddsImage.png";
import adsBanner2 from "../Image/AddsImage2.png";
import {
  ChevronUpDownIcon,
  EyeIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Records from "./Records";
import FloatNotification from "./FloatNotification";
import { PlusIcon } from "@heroicons/react/20/solid";
import DeletePopup from "./DeletePopup";
import { config } from "../Api/config";
import { Checkbox, Typography } from "antd";
import ActionPopup from "./ActionPopup";
import image1 from "../Image/image1.png";
import image2 from "../Image/image2.png";
import image3 from "../Image/image3.png";
import { SortData } from "./SortData";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast } from "./toast";
import NoDataFound from "./NoDataFound";
import CircularProgress from "@mui/material/CircularProgress";
import Breadcrumbs from "./Breadcrumbs";
import { getDecryptedDataFromLocalStorage } from "../Api/encryption";
import ActionButton from "./ActionButton";
import { Avatar } from "@mui/material";
import Select from "./Select";
import { Link } from "react-router-dom";
import QRCode from "react-qr-code";
import { toPng } from "html-to-image";

const GenerateQRCode = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Master")?.[0];
  const cancelButtonRef = useRef(null);
  let token = getDecryptedDataFromLocalStorage("token");
  const [isModel, setIsModel] = useState(false);

  const [titleData, setTitleData] = useState([
    {
      title: "Sr No.",
      sort: 1,
      fieldName: "sr_no",
    },
    {
      title: "QR CODE",
      sort: 1,
      fieldName: "qr_code",
    },
    {
      title: "LINK",
      sort: 1,
      fieldName: "link",
    },
    {
      title: access?.delete ? "DOWNLOAD" : "",
      sort: 1,
      fieldName: "",
    },
  ]);
  const [generateQRCodeData, setGenerateQRCodeData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [dataCount, setDataCount] = useState(0);
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(dataCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [isLoader, setIsLoader] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [selectDeleteId, setSelectDeleteId] = useState('');

  const qrCodeRefs = useRef([]);
  const [error, setError] = useState({});
  const [selectedLink, setSelectedLink] = useState("");

  const handleChangeLink = (e) => {
    setSelectedLink(e.target.value);
  };

  const getGenerateQRCodeData = async (limit, offset) => {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/qrgenerator?offset=${offset}&limit=${limit}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setGenerateQRCodeData(req?.data);
        setDataCount(req?.totalCount);
        setIsLoader(false);
      } else {
        ErrorToast(req?.message);
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  useEffect(() => {
    getGenerateQRCodeData(recordsPerPage, currentPage);
  }, [recordsPerPage, currentPage]);

  const validation = () => {
    const linkPattern = /^(http|https):\/\/.+$/i;
    let errors = {};
    let formIsValid = true;
    if (!selectedLink) {
      formIsValid = false;
      errors["link"] = "Please enter link.";
    } else if (!linkPattern.test(selectedLink.trim())) {
      formIsValid = false;
      errors["link"] = "Please enter a valid HTTP or HTTPS link.";
    }

    setError(errors);
    return formIsValid;
  };

  const handelOnsubmit = async (e) => {
    if (validation()) {
      e.preventDefault();
      try {
        const body = {
          link_res: selectedLink,
        };
        try {
          const response = await Api.postRequest(
            "api/qrGenerator/create",
            body
          );

          const req2 = JSON.parse(response?.data);

          if (req2?.status === 200) {
            SuccessToast(req2?.message);
            getGenerateQRCodeData(recordsPerPage, currentPage);
            setSelectedLink("");
            setIsModel(false);
          } else {
            ErrorToast(req2?.message);
          }
        } catch (error) {
          ErrorToast(error?.message);
        }
      } catch (error) {
        ErrorToast(error?.message);
      }
    }
  };

  const handleDownload = (id) => {
    const qrCodeRef = qrCodeRefs.current[id];
    if (!qrCodeRef) return;
    toPng(qrCodeRef, { width: 350, height: 350 })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `qr-code-${id}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((error) => {
        console.error("Error generating QR code image:", error);
      });
  };

  const handleDelete = async () => {
    try {
      const { data } = await Api.deleteRequest(
        `api/qrgenerator/delete/${selectDeleteId}`
      );

      const req = JSON.parse(data);
      if (req?.status === 200) {
        setOpenDeleteModel(false);
        getGenerateQRCodeData(recordsPerPage, currentPage);
        SuccessToast("Subscription Price Delete Successfully");
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  return (
    <div>
      <div className="h-full flex items-center justify-center ">
        <div className="h-full flex-1 flex flex-col space-y-5 lg:space-y-0 lg:flex-row lg:space-x-10 w-full sm:px-6 ">
          <div className="flex-1 px-2 sm:px-0 w-full">
            <div>
              <div className="flex justify-between items-center mt-4">
                <div>
                  <MasterTitle
                    title="Generate QR Code"
                    description="Below are the list of all the QR Code."
                  />
                </div>
                <div>
                  <div style={{ display: "flex", gap: "5px" }}>
                    {access?.add && (
                      <Button
                        id=""
                        type="submit"
                        icon={PlusIcon}
                        label={"ADD LINK"}
                        onClick={() => {
                          setIsModel(true);
                          setSelectedLink("");
                        }}
                      />
                    )}
                  </div>
                  <Model
                    title={"Add Link"}
                    show={isModel}
                    onCancel={() => {
                      setIsModel(false);
                      setSelectedLink("");
                    }}
                    onClose={() => {
                      setIsModel(false);
                      setSelectedLink("");
                    }}
                    onOk={(e) => handelOnsubmit(e)}
                    okText="SUBMIT"
                    cancelText="CLOSE"
                  >
                    <>
                      <Input
                        label="Link"
                        type="text"
                        autoComplete="given-name"
                        name="Link"
                        required
                        className="mb-2 "
                        value={selectedLink || ""}
                        onChange={(e) => handleChangeLink(e)}
                      />
                      <Typography
                        className="my-2"
                        variant="caption"
                        color={"error"}
                      >
                        {!selectedLink || selectedLink ? error?.link : ""}
                      </Typography>
                    </>
                  </Model>
                </div>
              </div>
              <div className="sm:mb-0 overflow-hidden ">
                <div className="flex flex-col mt-4">
                  <section
                    className="bg-white rounded-2xl flex flex-col shadow-sm "
                    style={{
                      width: "100%",
                      height: "75vh",
                      margin: "0 auto",
                      padding: "20px",
                      marginTop: "30px",
                    }}
                  >
                    <div className="flex justify-between items-center px-8 mb-2">
                      <div></div>
                      <Records
                        setCurrentPage={setCurrentPage}
                        recordsPerPage={recordsPerPage}
                        setRecordsPerPage={setRecordsPerPage}
                        dataCount={dataCount}
                      />
                    </div>

                    <div
                      style={{
                        overflowY: "auto",
                        maxHeight: "58vh",
                        height: "60vh",
                        width: "100%",
                      }}
                    >
                      {" "}
                      {!isLoader && generateQRCodeData?.length <= 0 ? (
                        <NoDataFound />
                      ) : (
                        <table style={{ width: "100%" }}>
                          <thead
                            style={{
                              position: "sticky",
                              top: 0,
                              backgroundColor: "#f4f7f8",
                              zIndex: 1,
                            }}
                          >
                            <tr
                              style={{
                                borderBottom: "1px solid #dddddd",
                                textAlign: "left",
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "#7B809A",
                                textTransform: "uppercase",
                                backgroundColor: "#f4f7f8",
                              }}
                            >
                              {" "}
                              {titleData.map(
                                (v, ind) =>
                                  v?.title && (
                                    <th
                                      key={ind}
                                      className="py-4 px-8 "
                                      style={{
                                        padding: "12px",
                                        backgroundColor: "#f4f7f8",
                                        position:
                                          v.title === "DOWNLOAD"
                                            ? "sticky"
                                            : "",
                                        right:
                                          v.title === "DOWNLOAD" ? "0" : "",
                                      }}
                                    >
                                      <div className="flex justify-between">
                                        <span>{v.title}</span>
                                        {v.title !== "DOWNLOAD" && (
                                          <span>
                                            <ChevronUpDownIcon
                                              onClick={() => {
                                                let res = SortData(
                                                  generateQRCodeData,
                                                  v.fieldName,
                                                  v.sort
                                                );

                                                let copyData = [...titleData];
                                                copyData[ind].sort =
                                                  v.sort > 0 ? -1 : 1;
                                                setTitleData(copyData);
                                                setGenerateQRCodeData(res);
                                              }}
                                              className="w-5 h-5"
                                            />
                                          </span>
                                        )}
                                      </div>
                                    </th>
                                  )
                              )}
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200">
                            {isLoader ? (
                              <tr style={{ height: "25rem" }}>
                                <td
                                  colSpan={14}
                                  style={{ textAlign: "center" }}
                                >
                                  <CircularProgress />
                                </td>
                              </tr>
                            ) : (
                              generateQRCodeData.map((data, id) => (
                                <tr
                                  key={id}
                                  className="my-4 px-8 text-[#000] font-reguler text-sm align-top"
                                >
                                  <td className="lg:table-cell whitespace-nowrap px-8  py-2.5 ">
                                    {data?.id}
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap px-8 py-2.5">
                                    <div>
                                      <QRCode
                                        size={50}
                                        value={data?.link_res}
                                        viewBox={`0 0 256 256`}
                                        ref={(ref) =>
                                          (qrCodeRefs.current[id] = ref)
                                        } // Access .current property
                                      />
                                    </div>
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap px-8 py-2.5">
                                    {data?.link_res}
                                  </td>
                                  {access?.view && (
                                    <td
                                      className="relative whitespace-nowrap  p-6 py-2.5  text-left"
                                      style={{
                                        position: "sticky",
                                        right: "0px",
                                        backgroundColor: "white",
                                      }}
                                    >
                                      <ActionButton
                                        // key={data.key}
                                        action="download"
                                        onClick={() => handleDownload(id)}
                                      />
                                      <ActionButton
                                        // key={data.key}
                                        action="delete"
                                        onClick={() => {setOpenDeleteModel(true); setSelectDeleteId(data?.id)}}
                                      />
                                    </td>
                                  )}
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      recordsPerPage={recordsPerPage}
                      data={generateQRCodeData}
                      numbers={numbers}
                      npage={npage}
                      lastIndex={lastIndex}
                      firstIndex={firstIndex}
                      dataCount={dataCount}
                    />

                  </section>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <DeletePopup
        title={"Delete"}
        show={openDeleteModel}
        description="Are you sure you want to delete this Data ? All data
        of this Data will be permanently removed from our
        servers forever. This action cannot be undone."
        onCancel={() => {
          setOpenDeleteModel(false);
        }}
        onClose={() => {
          setOpenDeleteModel(false);
        }}
        onDelete={() => {
          handleDelete()
          // setOpenDeleteModel(false);
          // setShowFloatNotification(true);
        }}  
        cancelButtonRef={cancelButtonRef}
        okText="Delete"
        cancelText="Cancel"
      />
    </div>
  );
};

export default GenerateQRCode;
