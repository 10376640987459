import {
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
  makeStyles,
  useMediaQuery,
  TextField,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ErrorToast, SuccessToast } from "./toast";
import { Api } from "../Api/Api";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { useDebounce } from "../hooks/use-debounce";
import Select from "./Select";
import { MdClose } from "react-icons/md";
import Book from "./Book";
import EditPrintLogo from "../Image/YouthLogo.png";
import cartIcon from "../Image/cart-icon-28358.png";
import shoppingcart from "../Image/shopping-cart.png";
import { TiTick } from "react-icons/ti";
import Divider from "@mui/material/Divider";
import { AuthLayout } from "./AuthLayout";
import {
  getDecryptedDataFromLocalStorage,
  saveEncryptedDataToLocalStorage,
} from "../Api/encryption";
import MultiSelectAutocomplete from "./MultiSelectAutoComplete";
import NoDataFound from "./NoDataFound";
import { toast } from "react-toastify";

function valuetext(value) {
  return `${value}°C`;
}

const minDistance = 10;

const BookModule = () => {
  const isTebView = useMediaQuery("(max-width: 768px)");
  const isMobileView = useMediaQuery("(max-width: 500px)");
  const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
  const [standardLimit, setStandardLimit] = useState(1);
  const [standardOutDataCount, setStandardOutDataCount] = useState("");
  const [standardData, setStandardData] = useState([]);
  const [papergenfilter, setpapergenfilter] = useState({
    standard: "",
  });
  const [value1, setValue1] = React.useState([1, 2000]);
  const debouncedValue = useDebounce(value1, 800);
  const [ProductsData, setProductsData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [value, setValue] = useState([]);
  const [values, setValues] = useState([]);
  const [isLoader, setIsLoader] = useState(false);

  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    // standard: standardID,
    // book: "",
    fullName: "",
    email: "",
    fullAddress: "",
    pincode: "",
    mobileNo: "",
    // qty: "",
    // total: "",
    // discount: "",
    // courierCharge: "",
    // finalAmount: "",
    // refercode: "",
  });

  const closeBuyModal = () => {
    setIsBuyModalOpen(false);
    setIsCartModalOpen(true);
  };


  const handleOrderPaymentSuccess = () => {
    
    setIsBuyModalOpen(false);
  };

  const [selectedStandards, setSelectedStandards] = useState([]);

  const handleStandardChange = (newValue) => {
    setSelectedStandards(newValue);
  };

  const getProductsData = async () => {
    try {
      setIsLoader(true);
      const standardIds = selectedStandards.map((option) =>
        option.id.toString()
      );
      const body = {
        minPrice: value1[0],
        maxPrice: value1[1],
        standard: standardIds,
        // standard: Array.isArray(papergenfilter?.standard)
        //   ? papergenfilter?.standard
        //   : typeof papergenfilter?.standard === "string"
        //   ? papergenfilter?.standard
        //       .split(",")
        //       .map((value) => parseFloat(value.trim()))
        //       .filter((value) => !isNaN(value))
        //   : [],
      };

      const { data } = await Api.postRequest(
        `api/books/getAllBooksWithFilter`,
        body
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setProductsData(req?.data);
        setDataCount(req?.totalCount);
        handleSort(sortOption);

        const prices = req.data.map((book) => book.price);
        const max = Math.max(...prices);
        setMaxPrice(2000);
        setIsLoader(false);
      } else {
        ErrorToast(req?.message);
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const [sortedData, setSortedData] = useState(ProductsData);
  const [sortOption, setSortOption] = useState("default");
  useEffect(() => {
    handleSort(sortOption);
  }, [sortOption, ProductsData]);

  const handleSort = (option) => {
    const sortedArray = [...ProductsData];

    sortedArray.sort((a, b) => {
      const priceA = a.price - a.discount_price;
      const priceB = b.price - b.discount_price;

      if (option === "lowToHigh") {
        return priceA - priceB;
      } else if (option === "highToLow") {
        return priceB - priceA;
      } else {
        return 0;
      }
    });

    setSortedData(sortedArray);
  };

  const handleSortChange = (event) => {
    const selectedOption = event.target.value;
    setSortOption(selectedOption);
    handleSort(selectedOption);
  };

  useEffect(() => {
    getProductsData();
  }, [debouncedValue, selectedStandards, papergenfilter]);

  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };

  const getStandardData = async (l) => {
    try {
      const { data } = await Api.getRequest(`api/standard`);
      const req = JSON.parse(data);
      setStandardData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const handleFilter = (name, value) => {
    setpapergenfilter({
      ...papergenfilter,
      [name]: value,
    });
  };

  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelectedItems = (selectedItems) => {
    const Array = selectedItems.map((a) => a.id);
    const selectedItemsString = Array.join(", ");
    setpapergenfilter((oldValue) => ({
      ...oldValue,
      standard: selectedItemsString,
    }));
  };

  useEffect(() => {
    handleSelectedItems(selectedItems);
  }, [selectedItems]);

  const [cartItems, setCartItems] = useState(() => {
    const storedCart = getDecryptedDataFromLocalStorage("cart");
    return storedCart ? storedCart : [];
  });

  const [productCount, setProductCount] = useState(() => {
    const storedProductCount = getDecryptedDataFromLocalStorage("productCount");
    return storedProductCount ? storedProductCount : {};
  });
  const addToCart = (book) => {
    toast.success("Book has been added to your cart", {
      autoClose: 500, // Duration in milliseconds
    });
    // SuccessToast("Book has been added to your cart");
    const existingIndex = cartItems.findIndex((item) => item.id === book.id);

    const updatedBook = {
      ...book,
      price: book.price - book.discount_price, // Compute the new price
      quantity: 1,
    };

    if (existingIndex !== -1) {
      const updatedCart = [...cartItems];
      updatedCart[existingIndex].quantity += 1;
      setCartItems(updatedCart);
      saveEncryptedDataToLocalStorage("cart", updatedCart);
    } else {
      const updatedCart = [...cartItems, updatedBook];
      setCartItems(updatedCart);
      saveEncryptedDataToLocalStorage("cart", updatedCart);
    }

    const productName = book.id;
    const updatedProductCount = { ...productCount }; // Copy the productCount object
    updatedProductCount[productName] =
      (updatedProductCount[productName] || 0) + 1; // Update the count
    setProductCount(updatedProductCount); // Update the state
    saveEncryptedDataToLocalStorage("productCount", updatedProductCount);
  };

  const addToCartAndBuyNow = (book) => {
    const existingIndex = cartItems.findIndex((item) => item.id === book.id);

    const updatedBook = {
      ...book,
      price: book.price - book.discount_price, // Compute the new price
      quantity: 1,
    };

    if (existingIndex !== -1) {
      const updatedCart = [...cartItems];
      updatedCart[existingIndex].quantity += 1;
      setCartItems(updatedCart);
      saveEncryptedDataToLocalStorage("cart", updatedCart);
    } else {
      const updatedCart = [...cartItems, updatedBook];
      setCartItems(updatedCart);
      saveEncryptedDataToLocalStorage("cart", updatedCart);
    }

    const productName = book.id;
    const updatedProductCount = { ...productCount }; // Copy the productCount object
    updatedProductCount[productName] =
      (updatedProductCount[productName] || 0) + 1; // Update the count
    setProductCount(updatedProductCount); // Update the state
    saveEncryptedDataToLocalStorage("productCount", updatedProductCount);
    openCartModal();
  };

  const openCartModal = () => {
    setIsCartModalOpen(true);
  };

  const closeCartModal = () => {
    setIsCartModalOpen(false);
    setFormData({
      // standard: "",
      // book: "",
      fullName: "",
      email: "",
      fullAddress: "",
      pincode: "",
      mobileNo: "",
      // qty: "",
      // total: "",
      // discount: "",
      // courierCharge: "",
      // finalAmount: "",
      // refercode: "",
    });
  };

  const handleRemoveCartData = (index) => {
    const productName = cartItems[index]?.id;
    delete productCount[productName];
    const updatedCart = cartItems.filter((_, i) => i !== index);
    setCartItems(updatedCart);
    saveEncryptedDataToLocalStorage("cart", updatedCart);
    saveEncryptedDataToLocalStorage("productCount", productCount);
    if (updatedCart?.length === 0) {
      closeCartModal();
    }
  };

  const handleQuantityChange = (index, newQuantity) => {
    const productName = cartItems[index]?.id;
    const updatedProductCount = { ...productCount }; // Copy the productCount object
    updatedProductCount[productName] = parseInt(newQuantity, 10) || 1; // Update the quantity
    setProductCount(updatedProductCount); // Update the state
    saveEncryptedDataToLocalStorage("productCount", updatedProductCount);
    const updatedCart = [...cartItems];
    updatedCart[index].quantity = parseInt(newQuantity, 10) || 1;
    setCartItems(updatedCart);
    saveEncryptedDataToLocalStorage("cart", updatedCart);
  };

  useEffect(() => {
    getStandardData(1);
  }, []);

  const totalQTYCount = cartItems.reduce(
    (totalQuantity, item) => totalQuantity + (productCount[item.id] || 0),
    0
  );

  const totalPrices = cartItems.reduce(
    (total, item, index) => total + item.price * (productCount[item.id] || 1),
    0
  );

  const groupedBooks = sortedData.reduce((grouped, book) => {
    const title = book.standardaInfo?.name || "Other"; // Use 'Other' if name is missing
    if (!grouped[title]) {
      grouped[title] = [];
    }
    grouped[title].push(book);
    return grouped;
  }, {});

  const [courierCharge, setcourierCharge] = React.useState("");
  const [referCode, setreferCode] = React.useState("");
  const [verifyData, setVerifyData] = useState({});
  const [discount, setDiscount] = useState(0);
  const [finalAmount, setFinalAmount] = React.useState(0);
  const [discount_price, setDiscountPrice] = React.useState();

  useEffect(() => {
    const baseCharge = 40;
    const courierCharge = Math.ceil(totalQTYCount / 2) * baseCharge;
    setcourierCharge(courierCharge);
  }, [totalQTYCount]);

  const handleChangeReferCode = (event) => {
    setreferCode(event.target.value);
  };

  const verifyPromoCode = async () => {
    try {
      let body = `${referCode}`;
      const { data } = await Api.getRequest(`api/promocode/order?name=${body}`);
      const req = JSON.parse(data);
      setVerifyData(req.data);
      setDiscount(req?.data?.discountValue || 0);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    // if (referCode !== "") {
    verifyPromoCode();
    // }
  }, [referCode]);

  useEffect(() => {
    const calculateFinalAmount = () => {
      const total = parseFloat(totalPrices || 0);
      const discountT = parseFloat(discount || 0);
      const courierChargeE = parseFloat(courierCharge || 0);
      const discountAmount = (discountT / 100) * total;
      setDiscountPrice(discountAmount);
      const discountedTotal = total - discountAmount;
      const finalAmount = discountedTotal + courierChargeE;
      return finalAmount.toFixed(2);
    };
    const newFinalAmount = calculateFinalAmount();
    setFinalAmount(newFinalAmount);

    // setFormData({
    //   ...formData,
    //   qty: totalQTYCount,
    //   total: totalPrice,
    //   finalAmount: newFinalAmount,
    // });
  }, [courierCharge, discount, totalQTYCount, totalPrices]);

  return (
    <div
      style={{
        // padding: isMobileView ? "12px" : "20px",
        backgroundColor: "#FFFFFF",
      }}
      className="relative  min-h-screen justify-center"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: 0,
          zIndex: 100,
        }}
        className="text-white bg-gradient-to-b from-[#118FE3] to-[#1879D4]  focus:outline-none font-medium text-sm px-5 py-2.5 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
      >
        <img src={EditPrintLogo} style={{ width: "55px" }} />
        <div
          style={{
            borderRadius: "50%",
            padding: "0px 10px 0px 10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.1)", // Opacity set to 0.8
          }}
        >
          <img
            src={shoppingcart}
            onClick={cartItems.length > 0 ? openCartModal : null}
            style={{ width: "30px" }}
            // className="text-white bg-gray-700 hover:bg-gray-900 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 mt-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
          />
          <div
            style={{
              position: "absolute",
              top: "15px",
              right: "21px",
              backgroundColor: "red",
              color: "white",
              padding: "5px",
              borderRadius: "50%",
              width: "18px", // Set the width
              height: "18px", // Set the height
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {cartItems.length}
          </div>
        </div>
      </div>
      <Grid
        container
        spacing={2}
        style={{ flexDirection: isTebView ? "column" : "" }}
      >
        <Grid
          item
          xs={isTebView ? 0 : 3}
          style={{ padding: "30px 20px 0px 40px" }}
        >
          <div
            style={{
              padding: "10px",
              borderRadius: "10px",
              backgroundColor: "#F0F8FF",
              height: "100%",
            }}
          >
            <h1 className="text-[#000000] mt-3 mb-3 font-semibold text-base font-poppins">
              Refine your Search
            </h1>
            <div
              style={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <div
                style={{
                  padding: "20px 30px 20px 30px",
                  width: "100%",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "10px",
                }}
              >
                <h1
                  style={{
                    color: "#000000",
                    fontSize: "17px",
                    fontWeight: "450",
                    marginBottom: "10px",
                  }}
                  // className="text-[#000000] font-semibold text-base font-poppins"
                  // style={{ marginTop: isMobileView ? "10px" : "30px" }}
                >
                  Price Range
                </h1>
                <div
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    gap: "10px",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      padding: "5px 0px 5px 5px",
                      width: "50%",
                      borderRadius: "5px",
                      border: "1px solid #D9D9D9",
                    }}
                  >
                    {value1[0]}
                  </div>
                  <div style={{ color: "#D9D9D9", paddingTop: "5px" }}>-</div>
                  <div
                    style={{
                      padding: "5px 0px 5px 5px",
                      width: "50%",
                      borderRadius: "5px",
                      border: "1px solid #D9D9D9",
                    }}
                  >
                    {value1[1]}
                  </div>
                </div>
                <Slider
                  getAriaLabel={() => "Minimum distance"}
                  value={value1}
                  onChange={handleChange1}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  disableSwap
                  max={maxPrice}
                />
              </div>
              <div
                style={{
                  padding: "20px 30px 20px 30px",
                  width: "100%",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "10px",
                }}
              >
                <h1
                  style={{
                    color: "#000000",
                    fontSize: "17px",
                    fontWeight: "450",
                    marginBottom: "15px",
                  }}
                >
                  Standard
                </h1>

                <MultiSelectAutocomplete
                  data={standardData}
                  value={selectedStandards}
                  onChange={handleStandardChange}
                  getOptionLabel={(option) => option.name}
                />
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={isTebView ? 0 : 9}>
          <div
            style={{
              padding: "10px",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: isMobileView ? "flex-start" : "center",
                flexDirection: isMobileView ? "column-reverse" : "",
                padding: isMobileView ? "0 10px" : "",
              }}
            >
              <h1
                style={{
                  color: "#000000",
                  fontSize: "17px",
                  fontWeight: "450",
                }}
              >
                {dataCount} Results Found
              </h1>
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <h1
                    // className={`${
                    //   isMobileView ? "hidden" : ""
                    // }`}
                    style={{
                      color: "#000000",
                      fontSize: "17px",
                      fontWeight: "450",
                    }}
                  >
                    Sort By :
                  </h1>
                  <select
                    value={sortOption}
                    onChange={handleSortChange}
                    style={{
                      padding: "6px",
                      margin: "15px 15px 15px 8px",
                      color: "#128FE4",
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #D9D9D9",
                      borderRadius: "5px",
                    }}
                    // className="text-white bg-gradient-to-b from-[#1190E4] to-[#1976d2] hover:from-[#1976d2] hover:to-[#0d47a1] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 mt-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                  >
                    <option value="default">Default</option>
                    <option value="lowToHigh">Low to High</option>
                    <option value="highToLow">High to Low</option>
                  </select>
                </div>
              </div>
            </div>
            <div
              style={{
                // border: "solid 1px black",
                // borderRadius: "15px",
                padding: "10px",
                height: "78vh",
                overflowY: "auto",
                // display: "column",
                flexWrap: "wrap",
                marginTop: "10px",
                justifyContent:
                  Object.entries(groupedBooks).length === 0 ? "center" : "left",
                display:
                  Object.entries(groupedBooks).length === 0 ? "flex" : "column",
                alignItems:
                  Object.entries(groupedBooks).length === 0 ? "center" : "",
              }}
              className="relative   md:px-12 lg:px-0 bg-white rounded-2xl  shadow-sm min-h-[calc(100vh-40px-16px-24px-38px-50px-40px-52px-16px)]"
            >
              {!isLoader && Object.entries(groupedBooks).length === 0 ? (
                <NoDataFound />
              ) : (
                <div>
                  {isLoader ? (
                    <tr
                      style={{
                        height: "120px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <td>
                        <CircularProgress />
                      </td>
                    </tr>
                  ) : (
                    Object.entries(groupedBooks).map(([title, books]) => (
                      <div key={title}>
                        <div style={{ padding: "5px 0px 5px 0px" }}>
                          <h2>{title}</h2>
                        </div>
                        <Divider sx={{ marginBottom: "5px" }} />
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "left",
                          }}
                        >
                          {books.map((bookData, index) => {
                            const uploadImages = JSON?.parse(
                              bookData?.upload_img
                            );
                            return (
                              <div
                                key={index}
                                style={{
                                  // border: "solid 1px gray",
                                  background: "#F0F8FF",
                                  borderRadius: "12px",
                                  padding: isMobileView ? "15px" : "20px",
                                  width: isTebView ? "170px" : "225px",
                                  position: "relative",
                                  margin: "5px",
                                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                  // opacity:"50%"
                                }}
                              >
                                {bookData?.discount_price && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      right: "10px",
                                      backgroundColor: "red",
                                      color: "white",
                                      padding: "5px",
                                      borderRadius: "50%",
                                    }}
                                  >
                                    {`-${Math.round(
                                      (bookData?.discount_price /
                                        bookData?.price) *
                                        100
                                    )}%`}
                                  </div>
                                )}
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={uploadImages[0]}
                                    alt="books"
                                    style={{
                                      width: isMobileView ? "131px" : "160px",
                                      height: isMobileView ? "177px" : "220px",
                                      boxShadow:
                                        "0px 2px 8px rgba(0, 0, 0, 0.1)",
                                    }}
                                  />
                                  <h1
                                    className="text-[#676767] font-semibold text-base font-poppins"
                                    style={{
                                      marginTop: "5px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {bookData.name}
                                  </h1>
                                  {/* <h3
                                  className="text-[#676767]  text-sm font-poppins "
                                  style={{
                                    width: "170px",
                                    textAlign: "center",
                                  }}
                                >
                                  {bookData.description}
                                </h3> */}
                                  <div style={{ display: "flex", gap: "7px" }}>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        color: "red",
                                        fontWeight: "500",
                                      }}
                                    >
                                      ₹
                                      {bookData.price - bookData.discount_price}
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "15px",
                                        display: "flex",
                                        alignItems: "flex-end",
                                        textDecoration: "line-through",
                                      }}
                                    >
                                      ₹{bookData.price}
                                    </p>
                                  </div>
                                </div>
                                <button
                                  onClick={() => addToCart(bookData)}
                                  className="text-white bg-gradient-to-b from-[#1190E4] to-[#1976d2] hover:from-[#1976d2] hover:to-[#0d47a1] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 mt-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                                >
                                  Add to Cart
                                </button>
                                <button
                                  onClick={() => addToCartAndBuyNow(bookData)}
                                  style={{
                                    border: "1px solid #1976d2",
                                    backgroundColor: "#FFFFFF",
                                    padding: "5px",
                                    borderRadius: "10px",
                                    color: "#1976d2",
                                  }}
                                >
                                  Buy Now
                                </button>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ))
                  )}
                </div>
              )}
              <div
                style={{
                  display: isCartModalOpen ? "flex" : "none",
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1000,
                }}
              >
                <div
                  style={{
                    backgroundColor: "#fff",
                    // padding: "20px",
                    borderRadius: "8px",
                    maxWidth: "600px",
                    width: "100%",
                    position: "relative",
                    margin: "10px",
                  }}
                >
                  <div style={{ margin: "30px" }}>
                    {/* Close icon in the top right corner */}
                    <div
                      style={{
                        position: "absolute",
                        top: "35px",
                        right: "30px",
                        cursor: "pointer",
                      }}
                      onClick={closeCartModal}
                    >
                      <MdClose size={24} />
                    </div>

                    <h1
                      style={{
                        fontSize: "1.5rem",
                        marginBottom: "15px",
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                    >
                      Your Cart
                    </h1>
                  </div>
                  <Divider sx={{ marginBottom: "5px" }} />

                  <div
                    style={{
                      maxHeight: "40vh",
                      overflowY: "auto",
                    }}
                  >
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                      }}
                    >
                      <tbody>
                        {cartItems.map((item, index) => {
                          const uploadImages = JSON?.parse(item?.upload_img);
                          const productName = item.name;

                          return (
                            <tr
                              key={index}
                              style={{
                                // borderBottom: "1px solid #ddd",
                                fontSize: "1rem",
                                padding: "5px",
                              }}
                            >
                              <td>
                                <div
                                  style={{
                                    margin: "20px 30px 0px 30px",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    borderRadius: "5px",
                                    border: "1px solid #D9D9D9",
                                  }}
                                >
                                  <div style={{ padding: "15px" }}>
                                    <img
                                      src={uploadImages[0]}
                                      alt={productName}
                                      style={{
                                        minWidth: "110px",
                                        maxWidth: "120px",
                                        minHeight: "140px",
                                      }}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      padding: "15px",
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>
                                      <div
                                        style={{
                                          // marginBottom: "5px",
                                          color: "#000000",
                                          fontSize: "18px",
                                          fontWeight: "450",
                                        }}
                                      >
                                        {productName}
                                      </div>
                                      <div
                                        style={{
                                          color: "#128FE4",
                                          fontSize: "18px",
                                          fontWeight: "450",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        ₹
                                        {item.price *
                                          (productCount[item.id] || 1)}
                                      </div>
                                      <div
                                        style={{
                                          color: "#000000",
                                          opacity: "55%",
                                          fontSize: "15px",
                                          // fontWeight: "450",
                                          marginBottom: "8px",
                                        }}
                                      >
                                        Quantity :
                                      </div>
                                      <div>
                                        <input
                                          className="block w-20 appearance-none rounded-md border border-gray-200 px-3 py-2 text-gray-900 placeholder-[#A0AEC0]focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                                          id={`quantity-${index}`}
                                          type="number"
                                          min="1"
                                          value={productCount[item.id] || 1}
                                          onChange={(e) =>
                                            handleQuantityChange(
                                              index,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        // padding: "15px",
                                        cursor: "pointer",
                                        // paddingLeft:"80px",
                                        // width: "24%",
                                        // display: "flex",
                                        // justifyContent: "flex-end",
                                      }}
                                      onClick={() =>
                                        handleRemoveCartData(index)
                                      }
                                    >
                                      <MdClose size={24} />
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div
                    style={{
                      margin: "0px 30px 0px 30px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontWeight: "450",
                        marginTop: "10px",
                        fontSize: "18px",
                      }}
                    >
                      Total Quantity
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontWeight: "450",
                        marginTop: "10px",
                        fontSize: "18px",
                      }}
                    >
                      {cartItems.reduce(
                        (totalQuantity, item) =>
                          totalQuantity + (productCount[item.id] || 0),
                        0
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      margin: "0px 30px 0px 30px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontWeight: "450",
                        marginTop: "10px",
                        fontSize: "18px",
                      }}
                    >
                      Total Price
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontWeight: "450",
                        marginTop: "10px",
                        fontSize: "18px",
                      }}
                    >
                      ₹
                      {cartItems.reduce(
                        (total, item, index) =>
                          total + item.price * (productCount[item.id] || 1),
                        0
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      margin: "0px 30px 0px 30px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontWeight: "450",
                        marginTop: "10px",
                        fontSize: "18px",
                      }}
                    >
                      Courier Charge
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontWeight: "450",
                        marginTop: "10px",
                        fontSize: "18px",
                      }}
                    >
                      ₹{courierCharge}
                    </div>
                  </div>
                  {discount > 0 || referCode !== "" ? (
                    <div
                      style={{
                        margin: "0px 30px 0px 30px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          fontWeight: "450",
                          marginTop: "10px",
                          fontSize: "18px",
                        }}
                      >
                        Discount
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          fontWeight: "450",
                          marginTop: "10px",
                          fontSize: "18px",
                        }}
                      >
                        <span style={{ color: "green", fontSize: "15px" }}>
                          {" "}
                          ({parseInt(discount)}%)
                        </span>{" "}
                        ₹{discount_price?.toFixed(2)}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    style={{
                      margin: "0px 30px 0px 30px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontWeight: "450",
                        marginTop: "15px",
                        fontSize: "18px",
                      }}
                    >
                      Referral code
                    </div>
                    <div
                      style={{
                        // display:"flex",
                        // justifyContent: "center",
                        textAlign: "end",
                        fontWeight: "450",
                        marginTop: "10px",
                        fontSize: "18px",
                      }}
                    >
                      <Box sx={{ display: "inline" }}>
                        <div>
                          <TextField
                            size="small"
                            style={{
                              // marginTop: "8px",
                              backgroundColor: "#fff",
                              width: "70%",
                            }}
                            className="font-regular"
                            fullWidth
                            value={referCode}
                            onChange={(e) => handleChangeReferCode(e)}
                            onBlur={(e) => handleChangeReferCode(e)}
                            id="outlined-basic"
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <React.Fragment>
                                  {verifyData?.name === referCode && (
                                    <InputAdornment position="end">
                                      <TiTick
                                        style={{
                                          color: "#4CAF50",
                                          fontSize: "2em",
                                        }}
                                      />
                                    </InputAdornment>
                                  )}
                                </React.Fragment>
                              ),
                            }}
                          />
                        </div>
                        <div>
                          <Typography variant="caption" color={"error"}>
                            {verifyData === undefined || verifyData === null
                              ? referCode !== ""
                                ? "Invalid promo code"
                                : ""
                              : ""}
                          </Typography>
                        </div>
                      </Box>
                    </div>
                  </div>
                  <div
                    style={{
                      margin: "0px 30px 0px 30px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontWeight: "450",
                        marginTop: "10px",
                        fontSize: "18px",
                      }}
                    >
                      Total
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontWeight: "450",
                        marginTop: "10px",
                        fontSize: "18px",
                      }}
                    >
                      ₹{finalAmount}
                    </div>
                  </div>
                  <div style={{ padding: "15px" }}>
                    <button
                      style={{
                        width: "100%",
                        borderRadius: "30px",
                        padding: "15px",
                      }}
                      onClick={() => {
                        if (cartItems.length > 0) {
                          setIsCartModalOpen(false);
                          setIsBuyModalOpen(true);
                        }
                      }}
                      className="text-white bg-gradient-to-b from-[#1190E4] to-[#1976d2] hover:from-[#1976d2] hover:to-[#0d47a1] focus:outline-none font-medium text-sm px-5 py-2.5 mt-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                    >
                      Buy Now
                    </button>
                  </div>
                </div>
              </div>

              <Book
                isBuyModalOpen={isBuyModalOpen}
                closeBuyModal={closeBuyModal}
                // standardID={
                //   Array.isArray(papergenfilter?.standard)
                //     ? papergenfilter?.standard
                //     : typeof papergenfilter?.standard === "string"
                //     ? papergenfilter?.standard
                //         .split(",")
                //         .map((value) => parseFloat(value.trim()))
                //         .filter((value) => !isNaN(value))
                //     : []
                // }
                bookData={cartItems}
                productCount={productCount}
                totalQTYCount={totalQTYCount}
                totalPrice={totalPrices}
                discount={discount}
                finalAmount={finalAmount}
                referCode={referCode}
                courierCharge={courierCharge}
                verifyData={verifyData}
                formData={formData}
                setFormData={setFormData}
                handleOrderPaymentSuccess={handleOrderPaymentSuccess}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default BookModule;
