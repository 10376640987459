import { Typography } from "antd";
import { Button } from "./Button";
import MasterTitle from "./MasterTitle";
import { useRef, useState } from "react";
import { Api } from "../Api/Api";
import { useLocation } from "react-router-dom"; // Import useLocation from react-router-dom

const ImageUpload = () => {
  const [image, setImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const editorRef = useRef(null); // Define editorRef
  const location = useLocation(); // Use useLocation hook to get the current URL
  const [locationData, setLocationData] = useState(location);
  const [imageUploadLoader, setImageUploadLoader] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(e.target.files[0]);
    setSelectedFile(file);
  };
  const handleResetImage = () => {
    setImage(null);
    setSelectedFile(null);
  };
  const handleChooseFileClick = () => {
    document.getElementById("fileUpload").click();
  };

  const handleSubmit = async (e) => {
    setImageUploadLoader(true);
    let imgData = [];
    if (selectedFile) {
      const formData = new FormData();
      formData.append("image", selectedFile);
      const responseimg = await Api.axiospostRequest(
        "api/upload/single_file",
        formData
      );

      if (responseimg.status === 200) {
        // const imageUrl = responseimg?.data?.data;
        setLocationData((oldValue) => ({
          ...oldValue,
          state: responseimg?.data?.data,
        }));
        setImageUploadLoader(false);
        var funcNum = getUrlParam("CKEditorFuncNum");
        var fileUrl = responseimg?.data?.data;
        window.opener.CKEDITOR.tools.callFunction(funcNum, fileUrl);
        window.close();
      }
    }
  };
  function getUrlParam(paramName) {
    var reParam = new RegExp("(?:[?&]|&)" + paramName + "=([^&]+)", "i");
    var match = window.location.search.match(reParam);
    return match && match.length > 1 ? match[1] : null;
  }
  return (
    <div>
      <div className="sm:mb-0 overflow-hidden ">
        <div className="flex flex-col mt-4">
          <div className="flex items-start lg:justify-between flex-col sm:flex-row sm:space-y-0 mt-10 px-8  my-14">
            <div>
              <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-4 mb-4 ">
                <div>
                  <label
                    htmlFor="fileUpload"
                    className="mb-3 block text-sm font-medium text-[#252F40] font-poppins"
                  >
                    Upload file
                  </label>
                  <input
                    type="file"
                    id="fileUpload"
                    accept="image/*"
                    class="hidden"
                    onChange={handleImageChange}
                  ></input>
                  <div className="flex items-center ">
                    <button
                      id=""
                      type="button"
                      label={"Choose file"}
                      onClick={handleChooseFileClick}
                      className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                    >
                      Choose file
                    </button>
                    <div className="ml-1 block text-base font-medium text-[#252F40] font-poppins ">
                      {selectedFile?.name}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6 mb-3 max-w-lg mt-10">
                <div className="w-96">
                  {!imageUploadLoader ? (
                    <Button
                      id=""
                      type="button"
                      label={"SUBMIT"}
                      abc="w-full "
                      onClick={selectedFile && handleSubmit}
                    />
                  ) : (
                    <Button
                      id=""
                      type="button"
                      label={"Loading..."}
                      abc="w-full"
                    >
                      Loading...
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="max-w-2xl xl:mr-32">
              {image && (
                <img
                  src={URL.createObjectURL(image)}
                  alt="file"
                  className="h-full w-full"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageUpload;
