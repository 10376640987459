import Pagination from "./Pagination";
import { useEffect, useRef, useState } from "react";
import {
  ChevronUpDownIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { Button } from "./Button";
import Model from "./Model";
import { Input } from "./Input";
import MasterTitle from "./MasterTitle";
import { Checkbox } from "./Checkbox";
import { SortData } from "./SortData";
import Records from "./Records";
import FloatNotification from "./FloatNotification";
import Select from "./Select";
import { PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import DeletePopup from "./DeletePopup";
import ActionPopup from "./ActionPopup";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast } from "./toast";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import NoDataFound from "./NoDataFound";
import CircularProgress from "@mui/material/CircularProgress";
import ActionButton from "./ActionButton";

const Semester = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Master")?.[0];
  const [modelType, setModelType] = useState("");
  const [isModel, setIsModel] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const cancelButtonRef = useRef(null);
  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [semesterData, setSemesterData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [selectedId, setSelectedId] = useState(null);
  const [search, setSearch] = useState("");
  const [shoolTypeData, setSchoolTypeData] = useState([]);
  const [mediumData, setMediumData] = useState([]);
  const [boardData, setBoardData] = useState([]);
  const [SelectStandardId, setSelectStandardId] = useState();
  const [typeDataCount, setTypeDataCount] = useState("");
  const [mediumDataCount, setMediumDataCount] = useState("");
  const [boardDataCount, setBoardDataCount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [standardQuestion, setStandardQuestion] = useState({
    medium: "",
    board: "",
    standard: "",
    semester: "",
    name: "",
    position: "",
    isregistration: 0
  });
  const [error, setError] = useState({});
  const [standardDataFilter, setStandardDataFilter] = useState([]);
  const [selectionDataStandard, setSelectionDataStandard] = useState([]);
  const [standardDataCount, setStandardDataCount] = useState("");
  const [mediumDataFilter, setMediumDataFilter] = useState([]);
  const [boardDataFilter, setBoardDataFilter] = useState([]);
  const [limit1, setLimit1] = useState(1);
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(dataCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [isLoader, setIsLoader] = useState(false);
  const [isModelLoader, setisModelLoader] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [deleteType, setDeleteType] = useState("single");
  const [titleData, setTitleData] = useState([
    {
      title: "BOARD",
      sort: 1,
      fieldName: "board",
    },
    {
      title: "MEDIUM",
      sort: 1,
      fieldName: "medium",
    },
    {
      title: "STANDARD",
      sort: 1,
      fieldName: "standard",
    },
    {
      title: "SEMESTER",
      sort: 1,
      fieldName: "semester",
    },
    {
      title: "POSITION",
      sort: 1,
      fieldName: "position",
    },
    {
      title: "BATCH REGISTRATION",
      sort: 1,
      fieldName: "batch_registration",
    },
    {
      title: access?.edit ? "EDIT" : "",
      sort: 1,
      fieldName: "",
    },
    {
      title: access?.delete ? "DELETE" : "",
      sort: 1,
      fieldName: "",
    },
  ]);

  useEffect(() => {
    getSemesterData(search, recordsPerPage, currentPage);
  }, [ recordsPerPage, currentPage]);

  const applyFilter = () => {
    getSemesterData(search, recordsPerPage, currentPage);
  };

  const getSemesterData = async (search, limit, offset) => {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/semester?offset=${offset}&limit=${limit}&search=${search || ""}`
      );
      const req = JSON.parse(data);

      if (req?.status === 200) {
        setDataCount(req?.data?.count);
        setSemesterData(req?.data);
      } else {
        ErrorToast(req?.message);
      }
      setIsLoader(false);
    } catch (error) {
      ErrorToast(error.message);
      setIsLoader(false);
    }
  };

  const getBoardData = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setBoardData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getMediumData = async () => {
    try {
      let body = `?board=${standardQuestion?.board?.id}`;
      const { data } = await Api.getRequest(`api/medium${body}`);
      const req = JSON.parse(data);
      setMediumData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getStandardData = async () => {
    try {
      let body = `?medium=${standardQuestion?.medium?.id}`;
      const { data } = await Api.getRequest(`api/standard${body}`);
      const req = JSON.parse(data);
      setSelectionDataStandard(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    getBoardData();
  }, []);

  useEffect(() => {
    if (standardQuestion?.board) {
      getMediumData();
    }
  }, [standardQuestion?.board]);

  useEffect(() => {
    if (standardQuestion?.medium) {
      getStandardData();
    }
  }, [standardQuestion?.medium]);

  const handleQuestionType = (name, value) => {
    if (name === "board") {
      setStandardQuestion((oldValue) => ({
        ...oldValue,
        medium: "",
        standard: "",
        [name]: value,
      }));
      setMediumData([]);
      setSelectionDataStandard([]);
    } else if (name === "medium") {
      setStandardQuestion((oldValue) => ({
        ...oldValue,
        standard: "",
        [name]: value,
      }));
      setSelectionDataStandard([]);
    } else {
      setStandardQuestion((oldValue) => ({
        ...oldValue,
        [name]: value,
      }));
    }
  };

  const validation = () => {
    let errors = {};
    let formIsValid = true;
    if (!standardQuestion?.medium) {
      formIsValid = false;
      errors["medium"] = "Please select medium.";
    }
    if (!standardQuestion?.board) {
      formIsValid = false;
      errors["board"] = "Please select board.";
    }
    if (!standardQuestion?.standard) {
      formIsValid = false;
      errors["standard"] = "Please enter standard.";
    }
    if (!standardQuestion?.semester) {
      formIsValid = false;
      errors["semester"] = "Please enter semester.";
    }
    if (!standardQuestion?.position) {
      formIsValid = false;
      errors["position"] = "Please enter position.";
    }
    setError(errors);
    return formIsValid;
  };

  const handleAddModelSubmit = async () => {
    if (validation()) {
      setIsLoading(true);
      if (modelType === "edit") {
        try {
          let body = {
            medium: standardQuestion?.medium?.id,
            board: standardQuestion?.board?.id,
            standard: standardQuestion?.standard?.id,
            semester: standardQuestion?.semester,
            position: standardQuestion?.position,
            status: standardQuestion?.status,
            isregistration: standardQuestion?.isregistration,
          };

          const data = await Api.putRequest(
            `api/semester/update/${selectedId}`,
            body
          );

          if (data?.statusCode === 200) {
            SuccessToast(data?.data?.message);
            getSemesterData(search, recordsPerPage, currentPage);
            setStandardQuestion({
              medium: "",
              board: "",
              standard: "",
              semester: "",
              position: "",
              status: "",
              isregistration: 0,
            });
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
            setIsModel(false);
            setSelectedId("");
            setMediumData([]);
            setSelectionDataStandard([]);
          } else {
            ErrorToast(data?.data?.message);
            // setIsModel(false);
          }
        } catch (error) {
          ErrorToast(error);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      } else {
        try {
          let body = {
            medium: standardQuestion?.medium?.id,
            board: standardQuestion?.board?.id,
            standard: standardQuestion?.standard?.id,
            semester: standardQuestion?.semester,
            position: standardQuestion?.position,
          };

          const { statusCode, data } = await Api.postRequest(
            "api/semester/create",
            body
          );

          const req = JSON.parse(data);
          if (req?.status === 200) {
            SuccessToast(req?.message);
            getSemesterData(search, recordsPerPage, currentPage);
            setStandardQuestion((prevState) => ({
              ...prevState,
              semester: "",
              position: "",
            }));
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
            // setIsModel(false);
            setSelectedId("");
          } else {
            ErrorToast(req?.message);
          }
        } catch (error) {
          ErrorToast(error);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const _deleteStanderedQuestion = async (id) => {
    try {
      let data;
      if (deleteType === "multi") {
        const body = {
          ids: checkedItems,
        };
        const response = await Api.postRequest(`api/semester/delete`, body);
        data = response.data;
      } else {
        const body = {
          ids: [SelectStandardId],
        };
        const response = await Api.postRequest(`api/semester/delete`, body);
        data = response.data;
      }

      // const { data } = await Api.deleteRequest(
      //   `api/semester/delete/${SelectStandardId}`
      // );

      const res = JSON.parse(data);
      if (res?.status === 200) {
        SuccessToast(res?.message);
        setOpenModel(false);
        getSemesterData(search, recordsPerPage, currentPage);
        setSelectStandardId("");
      } else {
        ErrorToast(res?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const _getById = async (id) => {
    try {
      setisModelLoader(true);
      if (id) {
        const { data } = await Api.getRequest(
          `api/semester/getSemesterByID/${id || ""}`
        );
        const req = JSON.parse(data);
        if (req?.status === 200) {
          setisModelLoader(false);

          setStandardQuestion({
            board: req?.data?.boardInfo || "",
            medium: req?.data?.mediumInfo || "",
            standard: req?.data?.StandardInfo || "",
            semester: req?.data?.semester || "",
            position: req?.data?.position || "",
            isregistration: req?.data?.isregistration || 0,
          });
        } else {
          ErrorToast(req.message);
        }
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  useEffect(() => {
    if (selectedId) {
      _getById(selectedId);
    }
  }, [selectedId]);

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) => {
      const isChecked = prevState.includes(id);

      const updatedItems = isChecked
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id];

      return updatedItems;
    });
  };

  return (
    <div>
      <div className="flex justify-between items-center mt-4">
        <div>
          <MasterTitle
            title="Semester"
            description="Below are all the list of semesters."
          />
        </div>

        <div>
          {" "}
          <div style={{ display: "flex", gap: "5px" }}>
            {access?.delete && (
              <Button
                id=""
                type="submit"
                icon={TrashIcon}
                label={"DELETE SEMESTER"}
                onClick={() => {
                  setDeleteType("multi");
                  if (checkedItems.length > 0) {
                    setOpenModel(true);
                  }
                }}
              />
            )}
            {access?.add && (
              <Button
                id=""
                type="submit"
                icon={PlusIcon}
                label={"ADD SEMESTER"}
                onClick={() => {
                  setStandardQuestion({});
                  setIsModel(true);
                  setModelType("add");
                }}
              />
            )}
          </div>
          <Model
            selectedId={selectedId}
            title={modelType === "add" ? "Add Semester" : "Edit Semester"}
            show={isModel}
            onCancel={() => {
              // setIsModel(true);
              setIsModel(false);
              setStandardQuestion({});
            }}
            onClose={() => {
              setIsModel(false);
              setMediumData([]);
              setSelectionDataStandard([]);
            }}
            onOk={() => handleAddModelSubmit()}
            okText="SUBMIT"
            cancelText="CLOSE"
            isLoading={isLoading}
            disabled={isLoading}
          >
            {isModelLoader ? (
              <tr
                style={{
                  height: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <td>
                  <CircularProgress />
                </td>
              </tr>
            ) : (
              <>
                <Select
                  value={standardQuestion?.board}
                  // value={standardQuestion?.type?.name}
                  data={boardData || []}
                  onChange={(e) => handleQuestionType("board", e)}
                  className="font-regular mb-3"
                  label="Board"
                  required
                  placeholder="All Board"
                  type={"board"}
                  getBoard={getBoardData}
                // isPage={true}
                />
                <Typography variant="caption" color={"error"}>
                  {!standardQuestion?.board ? error?.board : ""}
                </Typography>

                <Select
                  value={standardQuestion?.medium}
                  // value={standardQuestion?.type?.name}
                  data={mediumData || []}
                  onChange={(e) => handleQuestionType("medium", e)}
                  className="font-regular mb-3"
                  label="Medium"
                  required
                  placeholder="All Medium"
                  type={"medium"}
                  getMedium={getMediumData}
                // isPage={true}
                />
                <Typography variant="caption" color={"error"}>
                  {!standardQuestion?.medium ? error?.medium : ""}
                </Typography>
                <Select
                  data={selectionDataStandard || []}
                  className="font-regular mb-3"
                  label="Standard"
                  required
                  placeholder="All Standard"
                  value={standardQuestion.standard || ""}
                  onChange={(e) => handleQuestionType("standard", e)}
                  type={"standard"}
                  getStandard={getStandardData}
                // isPage={true}
                />
                <Typography variant="caption" color={"error"}>
                  {!standardQuestion?.standard ? error?.standard : ""}
                </Typography>

                <Input
                  label="Semester"
                  id=""
                  name="semester"
                  type="text"
                  autoComplete="given-name"
                  required
                  className="w-full mb-3"
                  value={standardQuestion.semester || ""}
                  onChange={(e) =>
                    handleQuestionType("semester", e.target.value)
                  }
                />
                <Typography variant="caption" color={"error"}>
                  {!standardQuestion?.semester ? error?.semester : ""}
                </Typography>

                <Input
                  label="Position"
                  id="firstname"
                  name="firstname"
                  type="text"
                  autoComplete="given-name"
                  required
                  className="w-full mb-3"
                  value={standardQuestion.position || ""}
                  onChange={(e) =>
                    handleQuestionType("position", e.target.value)
                  }
                />
                <Typography variant="caption" color={"error"}>
                  {!standardQuestion?.position ? error?.position : ""}
                </Typography>
                <div className="w-full flex flex-col">
                  <FormControl>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      style={{
                        fontFamily: "poppins",
                        color: "#252F40",
                        opacity: "1",
                      }}
                    >
                      Batch Registration*
                    </FormLabel>

                    <RadioGroup
                      row
                      required
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(e) =>
                        handleQuestionType("isregistration", e.target.value)
                      }
                      value={standardQuestion.isregistration}

                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

              </>
            )}
          </Model>
        </div>
      </div>
      <div className="sm:mb-0 overflow-hidden">
        <div className="flex flex-col mt-4">
          <section
            className="bg-white rounded-2xl flex flex-col shadow-sm "
            style={{
              width: "100%",
              height: "75vh",
              margin: "0 auto",
              padding: "20px",
            }}
          >
            {" "}
            <div className="flex justify-between items-center mt-6 px-8 mb-4">
              <div  style={{ display: "flex", gap:'5px' }}>
              <Input
                id=""
                name="Search"
                type="text"
                placeholder="Search here..."
                autoComplete="given-name"
                required
                className="w-72"
                onChange={(e) => {
                  setSearch(e?.target?.value);
                  setCurrentPage(1);
                }}
                value={search}
              />
                <Button
                  onClick={() => applyFilter()}
                  id=""
                  type="button"
                  // label={"SEARCH"}
                  searchicon={true}
                />
              </div>
              <div>
                <Records
                  recordsPerPage={recordsPerPage}
                  setRecordsPerPage={setRecordsPerPage}
                  setCurrentPage={setCurrentPage}
                  dataCount={dataCount}
                />
              </div>
            </div>
            <div
              style={{
                overflowY: "auto",
                maxHeight: "58vh",
                height: "60vh",
                width: "100%",
              }}
            >
              {" "}
              {!isLoader && semesterData?.length <= 0 ? (
                <NoDataFound />
              ) : (
                <table style={{ width: "100%" }}>
                  <thead
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#f4f7f8",
                      zIndex: 1,
                    }}
                  >
                    <tr
                      style={{
                        borderBottom: "1px solid #dddddd",
                        textAlign: "left",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#7B809A",
                        textTransform: "uppercase",
                        backgroundColor: "#f4f7f8",
                      }}
                    >
                      {" "}
                      <th
                        scope="col"
                        style={{ width: "3%", padding: "10px" }}
                      ></th>
                      {titleData.map(
                        (v, ind) =>
                          v.title && (
                            <th
                              key={ind}
                              // className="py-2 px-8"
                              style={{
                                padding: "12px",
                                backgroundColor: "#f4f7f8",
                                position:
                                  v.title === "EDIT" || v.title === "DELETE"
                                    ? "sticky"
                                    : "",
                                right:
                                  v.title === "EDIT"
                                    ? "60px"
                                    : v.title === "DELETE"
                                      ? "0"
                                      : "",
                              }}
                            >
                              <div className="flex justify-between">
                                <span>{v.title}</span>
                                {v.title !== "EDIT" && v.title !== "DELETE" && (
                                  <span>
                                    <ChevronUpDownIcon
                                      onClick={() => {
                                        let res = SortData(
                                          semesterData,
                                          v.fieldName,
                                          v.sort
                                        );

                                        let copyData = [...titleData];
                                        copyData[ind].sort =
                                          v.sort > 0 ? -1 : 1;
                                        setTitleData(copyData);
                                        setSemesterData(res);
                                      }}
                                      className="w-5 h-5"
                                    />
                                  </span>
                                )}
                              </div>
                            </th>
                          )
                      )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {isLoader ? (
                      <tr style={{ height: "25rem" }}>
                        <td colSpan={6} style={{ textAlign: "center" }}>
                          <CircularProgress />
                        </td>
                      </tr>
                    ) : (
                      semesterData?.rows?.map((data) => (
                        <tr
                          key={data.key}
                          className="my-4 p-8 text-[#000] font-reguler text-sm font-medium"
                        >
                          <td className="px-6 py-3 whitespace-no-wrap">
                            {/* <Checkbox type="checkbox" /> */}
                            <Checkbox
                              checked={checkedItems.includes(data.id)}
                              onChange={() => handleCheckboxChange(data.id)}
                            />
                          </td>
                          <td className="lg:table-cell whitespace-nowrap py-2.5 px-8">
                            {data?.boardInfo?.board_name || "-"}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap py-2.5 px-8">
                            {data?.mediumInfo?.name || "-"}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5">
                            {data?.StandardInfo?.name || "-"}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5">
                            {data?.semester || "-"}
                          </td>
                          {/* <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                          {data?.label}
                        </td> */}
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.position || "-"}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.isregistration ?? "-"}
                          </td>

                          {access?.edit && (
                            <td
                              className="relative whitespace-nowrap px-6 py-2.5 text-left"
                              style={{
                                position: "sticky",
                                right: "68px",
                                backgroundColor: "white",
                              }}
                            >
                              <ActionButton
                                key={data.key}
                                action="edit"
                                onClick={() => {
                                  setIsModel(true);
                                  setModelType("edit");
                                  setSelectedId(data.id);
                                }}
                              />
                            </td>
                          )}
                          {access?.delete && (
                            <td
                              className="relative whitespace-nowrap px-6 py-2.5 text-left"
                              style={{
                                position: "sticky",
                                right: "0",
                                backgroundColor: "white",
                              }}
                            >
                              <ActionButton
                                key={data.key}
                                action="delete"
                                onClick={() => {
                                  setDeleteType("single");
                                  setOpenModel(true);
                                  setSelectStandardId(data?.id);
                                }}
                              />
                            </td>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              recordsPerPage={recordsPerPage}
              data={semesterData}
              dataCount={dataCount}
              numbers={numbers}
              npage={npage}
              lastIndex={lastIndex}
              firstIndex={firstIndex}
            />
          </section>
        </div>

        {/* Delete */}
        <DeletePopup
          title={"Delete"}
          show={openModel}
          description="Are you sure you want to delete this Data ? All data
          of this Data will be permanently removed from our
          servers forever. This action cannot be undone."
          onCancel={() => {
            setOpenModel(false);
            setSelectStandardId("");
          }}
          onClose={() => {
            setOpenModel(false);
            setSelectStandardId("");
          }}
          onDelete={() => {
            _deleteStanderedQuestion();
          }}
          cancelButtonRef={cancelButtonRef}
          okText="Delete"
          cancelText="Cancel"
        />

        {/* Delete */}
        <FloatNotification
          show={showFloatNotification}
          title={"Successfully deleted!"}
          type="success"
          description={"Data Deleted."}
          onClick={() => {
            setShowFloatNotification(false);
          }}
        />
      </div>
    </div>
  );
};

export default Semester;
