import React from "react";

export function ElipsVerticalIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        id="Group_13710"
        data-name="Group 13710"
        transform="translate(-1224 -334)"
      >
        <rect
          id="Rectangle_2193"
          data-name="Rectangle 2193"
          width="24"
          height="24"
          transform="translate(1224 334)"
          fill="#fff"
        />
        <g
          id="icon_16px_menukebab"
          data-name="icon/16px/menukebab"
          transform="translate(1234 338)"
        >
          <circle
            id="Ellipse_13"
            data-name="Ellipse 13"
            cx="2"
            cy="2"
            r="2"
            fill="#7b809a"
          />
          <circle
            id="Ellipse_14"
            data-name="Ellipse 14"
            cx="2"
            cy="2"
            r="2"
            transform="translate(0 6)"
            fill="#7b809a"
          />
          <circle
            id="Ellipse_15"
            data-name="Ellipse 15"
            cx="2"
            cy="2"
            r="2"
            transform="translate(0 12)"
            fill="#7b809a"
          />
        </g>
      </g>
    </svg>
  );
}
