import { SemesterActions } from "../action-types/SemesterActionType"

export const fetchSemesterRequest = () => ({
    type: SemesterActions.FETCH_SEMESTER_REQUEST,
})

export const fetchSemesterSuccess = (semesterData) => ({
    type: SemesterActions.FETCH_SEMESTER_SUCCESS,
    payload: semesterData
})

export const fetchSemesterFailure = (error) => ({
    type: SemesterActions.FETCH_SEMESTER_FAILURE,
    payload: error,
})