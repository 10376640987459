import React, { lazy } from "react";
import Tabs from "./Tabs";

import Admin from "./Admin";
import User from "./User";
import PartnerCollab from "./PartnerCollab";

const UserAccess = (props) => {
  let categories = [
    {
      name: "Admin",
      component: <Admin access={props?.access} />,
      path: "/access/admin",
    },
    {
      name: "Partner Collab",
      component: <PartnerCollab access={props?.access} />,
      path: "/access/partner-collab",
    },
  ];
  return <Tabs categories={categories} title="Access" />;
};

export default UserAccess;
