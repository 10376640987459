import React, { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const DashboardAmout = ({
  categories,
  bookOrderAmountCounts,
  subscriptionAmountCounts,
}) => {
  useEffect(() => {
    if (categories && bookOrderAmountCounts && subscriptionAmountCounts) {
      const chartOptions = {
        // chart: {
        //   marginBottom: 80,
        //   marginTop: 60,
        // },
        title: false,
        xAxis: {
          categories: categories,
        },
        yAxis: {
          title: {
            text: "Revenue",
          },
          stackLabels: {
            enabled: false, // top of the bar count
          },
        },
        legend: {
          align: "left",
          x: 70,
          verticalAlign: "top",
          y: 70,
          floating: true,
          backgroundColor:
            Highcharts.defaultOptions.legend.backgroundColor || "white",
          borderColor: "#CCC",
          borderWidth: 1,
          shadow: false,
        },
        series: [
          {
            name: "Book Order Amount Counts",
            data: Object.values(bookOrderAmountCounts),
          },
          {
            name: "Subscription Amount Counts",
            data: Object.values(subscriptionAmountCounts),
          },
        ],
        credits: {
          enabled: false,
        },
      };

      // Create the chart
      const chart = Highcharts.chart("container", chartOptions);

      // Clean up function
      return () => {
        chart.destroy();
      };
    }
  }, []);

  return <div id="container" style={{ height: "auto", width: "auto" }}></div>;
};

export default DashboardAmout;
