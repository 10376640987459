import React, { useEffect, useRef, useState } from "react";
import { Input } from "./Input";
import { TrashIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import Pagination from "./Pagination";
import Model from "./Model";
import MasterTitle from "./MasterTitle";
import { Button } from "./Button";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "./Checkbox";
import { SortData } from "./SortData";
import Select from "./Select";
import { PlusIcon } from "@heroicons/react/20/solid";
import Records from "./Records";
import Breadcrumbs from "./Breadcrumbs";
import DeletePopup from "./DeletePopup";
import ActionPopup from "./ActionPopup";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";
import { Listbox } from "@headlessui/react";
import { CKEditor } from "ckeditor4-react";
import { Api } from "../Api/Api";
import { Radio, Typography } from "@mui/material";
import { ErrorToast, SuccessToast } from "./toast";
import { useDebounce } from "../hooks/use-debounce";
import LatexTextRenderer from "./utils/LatexTextRenderer";
import NoDataFound from "./NoDataFound";
import CircularProgress from "@mui/material/CircularProgress";
import { getDecryptedDataFromLocalStorage } from "../Api/encryption";
import ActionButton from "./ActionButton";
import LatexTextRendererMcq from "./utils/LatexTextRendererMcq";

const ApproveQuestion = (props) => {
  const access = {
    ...props?.access?.filter((e) => e?.name === "Approve Question")?.[0],
    // approve: true,
  };
  const navigate = useNavigate();
  const cancelButtonRef = useRef(null);

  let userData = getDecryptedDataFromLocalStorage("userData");

  const [openModel, setOpenModel] = useState(false);
  const [selectedQue, setSelectedQue] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [questionData, setQuestionData] = useState([]);
  const [questionCount, setQuestionCount] = useState(0);
  const [search, setSearch] = useState("");
  const debouncedValue = useDebounce(search, 900);
  const [isLoader, setIsLoader] = useState(false);
  const [isResetFilter, setIsResetFilter] = useState(false);
  const [titleData, setTitleData] = useState([
    {
      title: "NO",
      sort: 1,
      fieldName: "no",
    },
    {
      title: "QUESTION",
      sort: 1,
      fieldName: "question",
    },
    {
      title: "ANSWER",
      sort: 1,
      fieldName: "question",
    },

    {
      title: "APPROVED BY",
      sort: 1,
      fieldName: "approvedby",
    },
    {
      title: access?.edit ? "EDIT" : "",
      sort: 1,
      fieldName: "",
    },
    {
      title: access?.delete ? "DELETE" : "",
      sort: 1,
      fieldName: "",
    },
  ]);
  const [filter, setFilter] = useState({
    board: "",
    medium: "",
    standard: "",
    semester: "",
    subject: "",
    chapter: "",
    questionType: "",
  });
  const [boardData, setBoardData] = useState([]);
  const [mediumData, setMediumData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [standardData, setStandardData] = useState([]);
  const [semesterData, setSemesterData] = useState([]);
  const [chapterData, setChapterData] = useState([]);
  const [qTypeData, setQTypeData] = useState([]);

  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(questionCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [ApproveQuestionId, setApproveQuestionId] = useState();

  const handleFilter = (name, value) => {
    setFilter({
      ...filter,
      [name]: value,
    });
    if (name === "board") {
      setFilter((oldValue) => ({
        ...oldValue,
        medium: "",
        standard: "",
        semester: "",
        subject: "",
        chapter: "",
      }));
      setMediumData([]);
      setStandardData([]);
      setSemesterData([]);
      setSubjectData([]);
      setChapterData([]);
    }

    if (name === "medium") {
      setFilter((oldValue) => ({
        ...oldValue,
        standard: "",
        semester: "",
        subject: "",
        chapter: "",
      }));
      setStandardData([]);
      setSemesterData([]);
      setSubjectData([]);
      setChapterData([]);
    }

    if (name === "standard") {
      setFilter((oldValue) => ({
        ...oldValue,
        semester: "",
        subject: "",
        chapter: "",
      }));
      setSemesterData([]);
      setSubjectData([]);
      setChapterData([]);
    }

    if (name === "semester") {
      setFilter((oldValue) => ({
        ...oldValue,
        chapter: "",
        subject: "",
      }));
      setSubjectData([]);
      setChapterData([]);
    }
    if (name === "subject") {
      setFilter((oldValue) => ({
        ...oldValue,
        chapter: "",
      }));
      setChapterData([]);
    }

    if (name === "chapter") {
      setFilter((oldValue) => ({
        ...oldValue,
        questionType: "",

      }));
      setQTypeData([]);
    }

  };

  const _getQuestion = async (filter, search, limit, offset) => {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/questions?board=${filter?.board?.id || ""}&medium=${filter?.medium?.id || ""
        }&standard=${filter?.standard?.id || ""}&semester=${filter?.semester?.id || ""
        }&subject=${filter?.subject?.id || ""}&chapter=${filter?.chapter?.id || ""
        }&qtype=${filter?.questionType?.id || ""}&search=${search || ""
        }&limit=${limit || 10}&offset=${offset || 1}&isApproved=0`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setQuestionData(req?.data);
        setQuestionCount(req?.totalCount);
        setIsLoader(false);
      } else {
        ErrorToast(req?.message);
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const _deleteApproveQuestion = async (id) => {
    try {
      const { data } = await Api.postRequest(
        `api/questions/delete/${ApproveQuestionId}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        SuccessToast(req?.message);
        _getQuestion(filter, search, recordsPerPage, currentPage);
        setOpenModel(false);
        setApproveQuestionId("");
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getAllBoards = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setBoardData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getAllMedium = async () => {
    try {
      const { data } = await Api.getRequest(
        `api/medium?board=${filter?.board?.id || ""}`
      );
      const req = JSON.parse(data);
      setMediumData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getStandardData = async () => {
    try {
      let body = `?medium=${filter?.medium?.id || ""}`;
      const { data } = await Api.getRequest(`api/standard${body}`);
      const req = JSON.parse(data);
      setStandardData(req?.data);
    } catch (error) {
      ErrorToast(error.message);
    }
  };

  const getSemesterData = async () => {
    try {
      let body = `?standard=${filter?.standard?.id || ""}`;
      const { data } = await Api.getRequest(`api/semester${body}`);
      const req = JSON.parse(data);
      setSemesterData(req?.data?.rows);
    } catch (error) {
      ErrorToast(error.message);
    }
  };

  const getSubjectData = async () => {
    try {
      let body = `?standard=${filter?.standard?.id || ""}&semester=${filter?.semester?.id || ""
        }`;
      const { data } = await Api.getRequest(`api/subject${body}`);
      const req = JSON.parse(data);
      setSubjectData(req?.data);
    } catch (error) {
      ErrorToast(error.message);
    }
  };
  const getChapterData = async () => {
    try {
      let body = `?standard=${filter?.standard?.id || ""}&semester=${filter?.semester?.id || ""
        }&subject=${filter?.subject?.id || ""}`;
      const { data } = await Api.getRequest(`api/chapter${body}`);
      const req = JSON.parse(data);
      setChapterData(req?.data);
    } catch (error) {
      ErrorToast(error.message);
    }
  };

  const getQTypeData = async (l) => {
    try {
      let body = `?standard=${filter?.standard?.id || ""}&semester=${filter?.semester?.id || ""
      }&subject=${filter?.subject?.id || ""}&chapter=${filter?.chapter?.id || ""}`;
      const { data } = await Api.getRequest(`api/qtype/chapter${body}`);
      const req = JSON.parse(data);
      setQTypeData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    getAllBoards();
    // getQTypeData();
  }, []);

  useEffect(() => {
    if (filter["board"] !== "") {
      getAllMedium();
    }
  }, [filter["board"]]);

  useEffect(() => {
    if (filter["medium"] !== "") {
      getStandardData();
    }
  }, [filter["medium"]]);

  useEffect(() => {
    if (filter["standard"] !== "") {
      getSemesterData();
    }
  }, [filter["standard"]]);
  useEffect(() => {
    if (filter["semester"] !== "") {
      getSubjectData();
    }
  }, [filter["semester"]]);
  useEffect(() => {
    if (filter["subject"] !== "") {
      getChapterData();
      getQTypeData();
    }
  }, [filter["subject"]]);
  useEffect(() => {
    if (filter["chapter"] !== "") {
      getQTypeData();
    }
  }, [filter["chapter"]]);


  useEffect(() => {
    _getQuestion(filter, search, recordsPerPage, currentPage);
  }, [currentPage, recordsPerPage]);

  const applyFilter = () => {
    _getQuestion(filter, search, recordsPerPage, currentPage);
  };

  const resetFilter = () => {
    setIsResetFilter(true);
    setFilter({
      board: "",
      medium: "",
      standard: "",
      semester: "",
      subject: "",
      chapter: "",
      questionType: "",
    });
    setMediumData([]);
    setStandardData([]);
    setSemesterData([]);
    setSubjectData([]);
    setChapterData([]);
  };

  useEffect(() => {
    if (isResetFilter) {
      _getQuestion(filter, search, recordsPerPage, currentPage);
      setIsResetFilter(false);
    }
  }, [isResetFilter]);

  const renderLatex = (text) => {
    const parts = text.split(/(\$[^\$]*\$)/);
    let renderedText = parts.map((part, index) => {
      // if (index % 2 === 0) {
      //   return part.includes("<") ? (
      //     <span key={index} dangerouslySetInnerHTML={{ __html: part }} />
      //   ) : (
      //     part
      //   );
      // } else {
      return <LatexTextRenderer key={index} text={part} />;
      // }
    });
    return renderedText;
  };

  return (
    <>
      <div className="h-full flex items-center justify-center ">
        <div className="h-full flex-1 flex flex-col space-y-5 lg:space-y-0 lg:flex-row lg:space-x-10 w-full sm:px-2">
          <div className="flex-1 px-2 sm:px-0 w-full">
            {/* <div className="flex justify-between items-center">
              <Breadcrumbs title="/ Approve Question" /> */}
            {/* <Input
                    id=""
                    name="Search"
                    type="text"
                    placeholder="Search here"
                    autoComplete="given-name"
                    required
                    className="w-56"
                  /> */}
            {/* </div> */}
            <div className="w-full sm:px-0 flex md:flex-nowrap flex-wrap items-start gap-x-4">
              <div className="w-full">
                <Select
                  data={boardData || []}
                  value={filter?.board}
                  onChange={(e) => handleFilter("board", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Board"
                  type="board"
                  getBoard={getAllBoards}
                />
              </div>
              <div className="w-full">
                <Select
                  data={mediumData || []}
                  value={filter?.medium}
                  onChange={(e) => handleFilter("medium", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Medium"
                  type="medium"
                  getMedium={getAllMedium}
                />
              </div>{" "}
              <div className="w-full">
                <Select
                  data={standardData || []}
                  value={filter?.standard}
                  onChange={(e) => handleFilter("standard", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Standard"
                  type={"standard"}
                  getStandard={getStandardData}
                />
              </div>
              <div className="w-full">
                <Select
                  data={semesterData || []}
                  value={filter?.semester}
                  onChange={(e) => handleFilter("semester", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Semester"
                  type={"semester"}
                  getSemester={getSemesterData}
                />
              </div>
              <div className="w-full">
                <Select
                  data={subjectData || []}
                  value={filter?.subject}
                  onChange={(e) => handleFilter("subject", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Subject"
                  type={"subject"}
                  getSubject={getSubjectData}
                />
              </div>
              <div className="w-full">
                <Select
                  data={chapterData || []}
                  value={filter?.chapter}
                  onChange={(e) => handleFilter("chapter", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Chapter"
                  type={"chapter"}
                  getChapter={getChapterData}
                />
              </div>
              <div className="w-full">
                <Select
                  data={qTypeData || []}
                  value={filter?.questionType}
                  onChange={(e) => handleFilter("questionType", e)}
                  placeholder="All Q.Type"
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  type={"qType"}
                  getQType={getQTypeData}
                />
              </div>
              <div className="mt-3">
                <Button
                  onClick={() => applyFilter()}
                  id=""
                  type="button"
                  label={"SEARCH"}
                />
              </div>
              <div className="mt-3">
                <Button
                  onClick={() => resetFilter()}
                  id=""
                  type="button"
                  label={"RESET"}
                />
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center  mt-2">
                {/* <div>
                  <MasterTitle
                    title="Approve Question"
                    description="Below are the list of Approve the Question."
                  />
                </div> */}
                <div className="flex items-center space-x-2">
                  {selectedQue?.length > 0 && (
                    <div>
                      <Button
                        isLight
                        id=""
                        type="submit"
                        icon={TrashIcon}
                        label={"MULTI DELETE"}
                        abc="bg-[#D11212]"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="sm:mb-0 overflow-hidden">
                <div className="flex flex-col mt-1">
                  <section
                    className="bg-white rounded-2xl flex flex-col shadow-sm "
                    style={{
                      width: "100%",
                      height: "90vh",
                      margin: "0 auto",
                      padding: "20px",
                    }}
                  >
                    <div className="flex justify-between items-center mt-1 px-4 mb-4">
                      <div  style={{ display: "flex", gap:'5px' }}>
                      <Input
                        id=""
                        name="Search"
                        type="text"
                        placeholder="Search here..."
                        autoComplete="given-name"
                        required
                        className="w-72"
                        onChange={(e) => {
                          setSearch(e?.target?.value);
                          setCurrentPage(1);
                        }}
                        value={search}
                      />
                       <Button
                          onClick={() => applyFilter()}
                          id=""
                          type="button"
                          // label={"SEARCH"}
                          searchicon={true}
                        />
                      </div>
                      <div>
                        <Records
                          recordsPerPage={recordsPerPage}
                          setRecordsPerPage={setRecordsPerPage}
                          setCurrentPage={setCurrentPage}
                          dataCount={questionCount}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        overflowY: "auto",
                        maxHeight: "80vh",
                        height: "80vh",
                        width: "100%",
                      }}
                    >
                      {" "}
                      {!isLoader && questionData?.length <= 0 ? (
                        <NoDataFound />
                      ) : (
                        <table style={{ width: "100%", borderCollapse:'separate'}}>
                          <thead
                            style={{
                              position: "sticky",
                              top: 0,
                              backgroundColor: "#f4f7f8",
                              zIndex: 1,
                              border: "1px solid #000",
                            }}
                          >
                            <tr
                              style={{
                                borderBottom: "1px solid #dddddd",
                                textAlign: "left",
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "#7B809A",
                                textTransform: "uppercase",
                                backgroundColor: "#f4f7f8",
                                border: "1px solid #000",
                              }}
                            >
                              {" "}
                              {/* <th
                                  scope="col"
                                  className="relative w-12 px-6 sm:w-16 sm:px-8"
                                ></th> */}
                              {titleData?.map(
                                (v, ind) =>
                                  v?.title && (
                                    <th
                                      key={ind}
                                      style={{
                                        padding: "12px",
                                        backgroundColor: "#f4f7f8",
                                        border: "1px solid #000",
                                        position:
                                          v.title === "EDIT" ||
                                            v.title === "DELETE"
                                            ? "sticky"
                                            : "",
                                        right:
                                          v.title === "EDIT"
                                            ? "60px"
                                            : v.title === "DELETE"
                                              ? "0"
                                              : "",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <span>{v.title}</span>
                                        {v.title !== "EDIT" &&
                                          v.title !== "DELETE" && (
                                            <span>
                                              <ChevronUpDownIcon
                                                onClick={() => {
                                                  let res = SortData(
                                                    questionData,
                                                    v.fieldName,
                                                    v.sort
                                                  );

                                                  let copyData = [...titleData];
                                                  copyData[ind].sort =
                                                    v.sort > 0 ? -1 : 1;
                                                  setTitleData(copyData);
                                                  setQuestionData(res);
                                                }}
                                                className="w-5 h-5"
                                              />
                                            </span>
                                          )}
                                      </div>
                                    </th>
                                  )
                              )}
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200">
                            {isLoader ? (
                              <tr style={{ height: "25rem" }}>
                                <td colSpan={6} style={{ textAlign: "center" }}>
                                  <CircularProgress />
                                </td>
                              </tr>
                            ) : (
                              questionData?.length > 0 &&
                              questionData?.map((data, id) => (
                                <tr
                                  key={id}
                                  style={{
                                    margin: "3px",
                                    padding: "6px",
                                    color: "#000",
                                    fontWeight:'bold',
                                    fontSize: "14px",
                                    background:
                                      data?.book_question === "1"
                                        ? "#b7ddf6"
                                        : "",
                                  }}
                                >
                                  <td
                                    style={{
                                      padding: "12px",
                                      width: "7%",
                                      border: "1px solid #000",
                                    }}
                                  >
                                    {" "}
                                    {data.id}
                                  </td>

                                  <td
                                    style={{
                                      padding: "12px",
                                      width: "40%",
                                      whiteSpace: "normal",
                                      border: "1px solid #000",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    {" "}
                                    <div
                                      style={{
                                        width: "100%",
                                        overflowWrap: "break-word",
                                      }}
                                    >
                                      {data?.m_equation !== "1" && (
                                        <>
                                          {data?.m_equation !== "1" && (
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: data?.question,
                                              }}
                                            />
                                          )}
                                          {data?.QtypeInfo?.name === "MCQ" && (
                                            <div>
                                              <ul>
                                                <li>A .{data?.option1}</li>
                                                <li>B .{data?.option2}</li>
                                                <li>C .{data?.option3}</li>
                                                <li>D .{data?.option4}</li>
                                              </ul>
                                            </div>
                                          )}
                                        </>
                                      )}
                                      {data?.m_equation === "1" &&
                                        data?.QtypeInfo?.name === "MCQ" && (
                                          <div
                                            className="mathjax-rendered"
                                            style={{
                                              wordWrap: "break-word",
                                            }}
                                          >
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: data?.finalquestion.replace(
                                                  /\$(.*?)\$/g,
                                                  (match, p1) =>
                                                    `<LatexTextRenderer text="${p1}" />`
                                                ),
                                              }}
                                            />
                                            <div
                                              className="mathjax-rendered"
                                              style={{
                                                wordWrap: "break-word",
                                              }}
                                            >
                                              <LatexTextRendererMcq
                                                text={data}
                                              />
                                            </div>
                                            {/* <div>
                                                 
                                                  <ul>
                                                    {options.map(
                                                      (option, index) => (
                                                        <LatexTextRendererMcq
                                                          key={index}
                                                          option={option}
                                                        />
                                                      )
                                                    )}
                                                  </ul>
                                                </div> */}
                                          </div>
                                        )}
                                      {data?.m_equation === "1" &&
                                        data?.QtypeInfo?.name !== "MCQ" && (
                                          <div
                                            className="mathjax-rendered"
                                            style={{
                                              // wordWrap: "break-word",
                                              flexDirection: "row",
                                              display: "flex",
                                              flexWrap: "wrap",
                                            }}
                                          >
                                            {data?.qtype != 1 && <div
                                              dangerouslySetInnerHTML={{
                                                // __html: data?.question,
                                                __html: data?.finalquestion,
                                              }}
                                            />}
                                          </div>
                                        )}{" "}
                                      {data?.m_equation !== "1" &&
                                        data?.QtypeInfo?.name?.toLowerCase().includes("match the following") && (
                                          <div>
                                            <table
                                              style={{
                                                // width: "100%",
                                                borderCollapse: "collapse",
                                              }}
                                            >
                                              <thead>
                                                <tr>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    Question
                                                  </th>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    Answer
                                                  </th>
                                                  <th
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      FinalAnswer
                                                    </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {data?.perfact_metch.map(
                                                  (item, index) => (
                                                    <tr key={index}>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >
                                                        {item.question}
                                                      </td>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >
                                                        {item.answer}
                                                      </td>
                                                      <td
                                                          style={{
                                                            border:
                                                              "1px solid black",
                                                            padding: "8px",
                                                          }}
                                                        >
                                                          {item.rightAnswer}
                                                        </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                            {/* "ABC" */}
                                          </div>
                                        )}
                                      {data?.m_equation === "1" &&
                                        data?.QtypeInfo?.name?.toLowerCase().includes("match the following") && (
                                          <div>
                                            <table
                                              style={{
                                                // width: "100%",
                                                borderCollapse: "collapse",
                                              }}
                                            >
                                              <thead>
                                                <tr>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    Question
                                                  </th>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    Answer
                                                  </th>
                                                  <th
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      FinalAnswer
                                                    </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {data?.perfact_metch.map(
                                                  (item, index) => (
                                                    <tr key={index}>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >
                                                        {/* {item.question} */}
                                                        <LatexTextRenderer
                                                          text={item?.finalquestion}
                                                        />{" "}
                                                      </td>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >
                                                        {/* {item.answer} */}
                                                        <LatexTextRenderer
                                                          text={item.finalanswer}
                                                        />{" "}
                                                      </td>
                                                      <td
                                                          style={{
                                                            border:
                                                              "1px solid black",
                                                            padding: "8px",
                                                          }}
                                                        >
                                                          {/* {item.finalAnswer} */}
                                                          <LatexTextRenderer
                                                            text={item.rightAnswer}
                                                          />{" "}
                                                        </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                            {/* "ABC" */}
                                          </div>
                                        )}
                                    </div>
                                  </td>
                                  <td
                                    style={{
                                      padding: "12px",
                                      width: "40%",
                                      whiteSpace: "normal",
                                      border: "1px solid #000",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    {" "}
                                    <div
                                      style={{
                                        width: "100%",
                                        overflowWrap: "break-word",
                                      }}
                                    >
                                      {data?.m_equation !== "1" &&
                                        data?.QtypeInfo.name !==
                                        "તફાવત (Difference)" &&
                                        data?.QtypeInfo.name !==
                                        "ખરા ખોટા (True-False)" &&
                                        data?.QtypeInfo?.name !== "MCQ" ? (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: data?.answer,
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}

                                      {data?.QtypeInfo.name ===
                                        "ખરા ખોટા (True-False)" &&
                                        data?.answer === "0" ? (
                                        <div>False</div>
                                      ) : data?.QtypeInfo.name ===
                                        "ખરા ખોટા (True-False)" &&
                                        data?.answer === "1" ? (
                                        <div> True</div>
                                      ) : (
                                        ""
                                      )}

                                      {data?.QtypeInfo.name ===
                                        "ખરા ખોટા (True-False)" &&
                                        data?.answer === "False" ? (
                                        <div>False</div>
                                      ) : data?.QtypeInfo.name ===
                                        "ખરા ખોટા (True-False)" &&
                                        data?.answer === "True" ? (
                                        <div> True</div>
                                      ) : (
                                        ""
                                      )}
                                      {data?.m_equation !== "1" &&
                                        data?.QtypeInfo.name !==
                                        "તફાવત (Difference)" &&
                                        data?.QtypeInfo?.name === "MCQ" ? (
                                        <> {data?.answer}</>
                                      ) : (
                                        ""
                                      )}
                                      {data?.QtypeInfo.name ===
                                        "તફાવત (Difference)" ? (
                                        <div>
                                          <table
                                            style={{
                                              borderCollapse: "collapse",
                                            }}
                                          >
                                            <thead>
                                              {/* Conditionally render an additional row for the last item in data.perfact_metch */}
                                              {data?.perfact_metch.length >
                                                0 && (
                                                  <tr
                                                    style={{
                                                      backgroundColor: "#e1e1e1",
                                                    }}
                                                  >
                                                    <th
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      {
                                                        data.perfact_metch[
                                                          data.perfact_metch
                                                            .length - 1
                                                        ].question
                                                      }
                                                    </th>
                                                    <th
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      {
                                                        data.perfact_metch[
                                                          data.perfact_metch
                                                            .length - 1
                                                        ].answer
                                                      }
                                                    </th>
                                                  </tr>
                                                )}
                                            </thead>
                                            <tbody>
                                              {/* Render all items in data.perfact_metch except the last one */}
                                              {data?.perfact_metch
                                                .slice(0, -1)
                                                .map((item, index) => (
                                                  <tr key={index}>
                                                    <td
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      {item.question}
                                                    </td>
                                                    <td
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      {item.answer}
                                                    </td>
                                                  </tr>
                                                ))}
                                            </tbody>
                                          </table>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {data?.m_equation === "1" && (
                                        <div
                                          className="mathjax-rendered"
                                          style={{
                                            // wordWrap: "break-word",
                                            flexDirection: "row",
                                            display: "flex",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          <div
                                                dangerouslySetInnerHTML={{
                                                  __html: data?.finalAnswer,
                                                }}
                                              />
                                        </div>
                                      )}
                                    </div>
                                  </td>

                                  <td
                                    className="lg:table-cell px-6 py-2.5"
                                    style={{
                                      width: "130px",
                                      border: "1px solid #000",
                                    }}
                                  >
                                    <div style={{ width: "130px" }}>
                                      {data?.UserInfo?.username &&
                                        data?.updatedAt && (
                                          <>
                                            User: {data?.UserInfo?.username}
                                            <br />
                                            --------------------------
                                            <br />
                                            updatedAt:{" "}
                                            {data.updatedAt &&
                                              new Date(
                                                data.updatedAt
                                              ).toLocaleString("en-US", {
                                                timeZone: "Asia/Kolkata",
                                              })}
                                          </>
                                        )}
                                    </div>
                                  </td>

                                  {access?.edit && (
                                    <td
                                      className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                                      style={{
                                        position: "sticky",
                                        right: "70px",
                                        border: "1px solid #000",
                                        background:
                                          data?.book_question === "1"
                                            ? "#b7ddf6"
                                            : "white",
                                      }}
                                    >
                                      <ActionButton
                                        key={data.key}
                                        action="edit"
                                        onClick={() =>
                                          window.open(
                                            `/question/editquestion/${data?.id}?approveorreject=true&isApprove=false`,
                                            "_blank"
                                            // {
                                            //   state: {
                                            //     approveorreject: true,
                                            //   },
                                            // }
                                          )
                                        }
                                      />
                                    </td>
                                  )}
                                  {access?.delete && (
                                    <td
                                      className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                                      style={{
                                        position: "sticky",
                                        border: "1px solid #000",
                                        right: "0",
                                        background:
                                          data?.book_question === "1"
                                            ? "#b7ddf6"
                                            : "white",
                                      }}
                                    >
                                      <ActionButton
                                        key={data.key}
                                        action="delete"
                                        onClick={() => {
                                          setOpenModel(true);
                                          setApproveQuestionId(data?.id);
                                        }}
                                      />
                                    </td>
                                  )}
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      recordsPerPage={recordsPerPage}
                      data={questionData}
                      numbers={numbers}
                      npage={npage}
                      lastIndex={lastIndex}
                      firstIndex={firstIndex}
                      dataCount={questionCount}
                    />
                  </section>
                </div>

                {/* Delete */}

                <DeletePopup
                  title={"Delete"}
                  show={openModel}
                  description="Are you sure you want to delete this Data ? All data
                of this Data will be permanently removed from our
                servers forever. This action cannot be undone."
                  onCancel={() => {
                    setOpenModel(false);
                    setApproveQuestionId("");
                  }}
                  onClose={() => {
                    setOpenModel(false);
                    setApproveQuestionId("");
                  }}
                  onDelete={() => {
                    _deleteApproveQuestion();
                  }}
                  cancelButtonRef={cancelButtonRef}
                  okText="Delete"
                  cancelText="Cancel"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApproveQuestion;
