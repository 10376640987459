import {
  ChevronUpDownIcon,
  EyeIcon,
  EyeSlashIcon,
  PlusIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useRef, useState } from "react";
import { SortData } from "./SortData";
import ActionPopup from "./ActionPopup";
import Pagination from "./Pagination";
import DeletePopup from "./DeletePopup";
import FloatNotification from "./FloatNotification";
import { useNavigate } from "react-router-dom";
import MasterTitle from "./MasterTitle";
import Model from "./Model";
import { Button } from "./Button";
import { Input } from "./Input";
import { Checkbox } from "./Checkbox";
import { Grid, Typography } from "@mui/material";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast } from "./toast";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import moment from "moment";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import CircularProgress from "@mui/material/CircularProgress";
import NoDataFound from "./NoDataFound";
import ActionButton from "./ActionButton";
import { TrashIcon } from "@heroicons/react/20/solid";
import Divider from "@mui/material/Divider";
import Records from "./Records";

const access = [
  {
    name: "Dashboard",
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
  {
    name: "Master",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Promocode",
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Topic",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Question",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Approve Question",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Advertisement",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Notification",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Shop",
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
  {
    name: "Paper",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Report",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Access",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Product",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Book History",
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Student App",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Standard App",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  // {
  //   name: "Register User",
  //   view: false,
  //   add: false,
  //   edit: false,
  //   delete: false,
  // },
  // {
  //   name: "Notice",
  //   view: false,
  //   add: false,
  //   edit: false,
  //   delete: false,
  // },
  // {
  //   name: "RTO",
  //   view: false,
  //   add: false,
  //   edit: false,
  //   delete: false,
  // },
  // {
  //   name: "Semester",
  //   view: false,
  //   add: false,
  //   edit: false,
  //   delete: false,
  // },
  // {
  //   name: "Equation",
  //   view: false,
  //   add: false,
  //   edit: false,
  //   delete: false,
  // },
  // {
  //   name: "Maths",
  //   view: false,
  //   add: false,
  //   edit: false,
  //   delete: false,
  // },
  // {
  //   name: "Subscription Price",
  //   view: false,
  //   add: false,
  //   edit: false,
  //   delete: false,
  // },
];

const PartnerCollab = (props) => {
  const accessRole = props?.access?.filter((e) => e?.name === "Access")?.[0];
  const navigate = useNavigate();
  const cancelButtonRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [SelectUserId, setSelectUserId] = useState();
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [userCount, setUserCount] = useState(0);
  const [userDataTable, setUserDataTable] = useState([]);
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const [isModel, setIsModel] = useState(false);
  const [error, setError] = useState({});
  const [openModel, setOpenModel] = useState(false);
  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const npage = Math.ceil(userCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [isLoader, setIsLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [titleData, setTitleData] = useState([
    {
      title: "Sr No.",
      sort: 1,
      fieldName: "SrNo",
    },
    {
      title: "USER NAME",
      sort: 1,
      fieldName: "username",
    },
    {
      title: "EMAIL",
      sort: 1,
      fieldName: "email",
    },
    {
      title: "PASSWORD",
      sort: 1,
      fieldName: "password",
    },
    {
      title: "PHONE",
      sort: 1,
      fieldName: "phone",
    },
    {
      title: "CITY",
      sort: 1,
      fieldName: "city",
    },
    // {
    //   title: "ONLINE",
    //   sort: 1,
    //   fieldName: "online",
    // },
    {
      title: "CREATED AT",
      sort: 1,
      fieldName: "createdat",
    },
    // {
    //   title: "COUNT",
    //   sort: 1,
    //   fieldName: "count",
    // },
    {
      title: accessRole?.edit ? "EDIT" : "",
      sort: 1,
      fieldName: "",
    },
    {
      title: accessRole?.delete ? "DELETE" : "",
      sort: 1,
      fieldName: "",
    },
  ]);
  const [accessData, setAccessData] = useState(access);
  const [editData, setEditData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const [deleteType, setDeleteType] = useState("single");
  const [userData, setUserData] = useState({
    userName: "",
    email: "",
    password: "",
    phoneNumber: "",
    city: "",
  });
  const [isResetFilter, setIsResetFilter] = useState(false);

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const togglePasswordVisibility = (id) => {
    const data = userDataTable?.map((e) => {
      return {
        ...e,
        isShow: e?.id === id ? !e?.isShow : e?.isShow,
      };
    });
    setUserDataTable(data);
  };

  const handleAccessData = (name, value, i) => {
    const data = [...accessData];
    data[i][name] = value;
    setAccessData(data);
  };

  const handleChange = (name, value) => {
    setUserData((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
  };

  const phoneNumberRegex = /^\d{10}$/;

  const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

  const emailPattern =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const validation = () => {
    let errors = {};
    let formIsValid = true;
    if (!userData?.userName) {
      formIsValid = false;
      errors["userName"] = "Please Enter User Name.";
    }
    if (!userData?.email) {
      formIsValid = false;
      errors["email"] = "Please Enter Email ID.";
    } else if (!emailPattern.test(userData?.email)) {
      formIsValid = false;
      errors["isValidEmail"] = "Please Valid Email ID.";
    }
    if (!userData?.password) {
      formIsValid = false;
      errors["password"] = "Please Enter Password.";
    } else if (!passwordRegex.test(userData?.password)) {
      formIsValid = false;
      errors["isValidPassword"] =
        "Please Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character.";
    }
    // if (!userData?.phoneNumber) {
    //   formIsValid = false;
    //   errors["phoneNumber"] = "Please Enter Phone Number.";
    // } else if (!phoneNumberRegex.test(userData?.phoneNumber)) {
    //   formIsValid = false;
    //   errors["isValidPhoneNumber"] = "Please Enter a 10-digit Phone Number.";
    // }
    if (!userData?.city) {
      formIsValid = false;
      errors["city"] = "Please Enter City.";
    }
    setError(errors);
    return formIsValid;
  };

  const _getUser = async (limit, offset) => {
    try {
      setIsLoader(true);
      // let adjustedOffset = offset;
      // if (searchValue) {
      //   // If searchValue is present, start offset at 1
      //   adjustedOffset = 1;
      // }

      const { data } = await Api.getRequest(
        `api/auth/getAllPartner?limit=${limit}&offset=${offset}&search=${searchValue}&startDate=${startDate}&endDate=${endDate}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        const modifyData = req?.data?.map((e) => {
          return {
            ...e,
            isShow: false,
          };
        });
        setUserDataTable(modifyData);
        setUserCount(req?.totalCount);
        setIsModel(false);
        setIsLoader(false);
      } else {
        setIsLoader(false);
      }
    } catch (error) {}
  };

  const _getUserById = async (id) => {
    const { data } = await Api.getRequest(`api/auth/getUserByID/${id || ""}`);
    const req = JSON.parse(data);
    if (req?.status === 200) {
      setEditData(req?.data);
      setIsModel(true);
    } else {
      ErrorToast(req?.massage);
    }
  };

  const _deleteUser = async () => {
    try {
      let data;
      if (deleteType === "multi") {
        const body = {
          ids: checkedItems,
        };
        const response = await Api.postRequest(`api/auth/delete`, body);
        data = response.data;
      } else {
        const body = {
          ids: [SelectUserId],
        };
        const response = await Api.postRequest(`api/auth/delete`, body);
        data = response.data;
      }

      const req = JSON.parse(data);
      if (req?.status === 200) {
        SuccessToast(req?.message);
        _getUser(recordsPerPage, currentPage);
        setOpenModel(false);
        setSelectUserId("");
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {}
  };

  const _addUser = async () => {
    if (validation()) {
      setIsLoading(true);
      const stringArray = accessData?.map((e) => JSON.stringify(e));
      const joinedString = stringArray?.join(",");
      try {
        let body = {
          username: userData?.userName,
          email: userData?.email,
          mobile: userData?.phoneNumber,
          password: userData?.password,
          user_type: "",
          school_name: "",
          city: userData?.city,
          access: joinedString,
          type: "partner",
          roll: 1,
        };

        if (editData) {
          const data = await Api.putRequest(
            `api/auth/updatePartnerByAdmin/${editData?.id}`,
            body
          );
          if (data?.data?.status === 200) {
            SuccessToast(data?.data?.message);
            _getUser(recordsPerPage, currentPage);
            setIsModel(false);
            setUserData({});
            setAccessData(access);
            setError({});
            setEditData(null);
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
          } else {
            ErrorToast(data?.data?.message);
            setIsLoading(false);
          }
        } else {
          const { data } = await Api.postRequest(
            `api/auth/createPartnerByAdmin`,
            body
          );
          const req = JSON.parse(data);
          if (req?.status === 200) {
            SuccessToast(req?.message);
            _getUser(recordsPerPage, currentPage);
            // setIsModel(false);
            setUserData({});
            setAccessData(access);
            setError({});
            setEditData(null);
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
          } else {
            ErrorToast(req?.message);
            setIsLoading(false);
          }
          setIsLoading(false);
        }
      } catch (error) {
        ErrorToast(error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    _getUser(recordsPerPage, currentPage);
  }, [recordsPerPage, currentPage]);

  useEffect(() => {
    if (editData) {
      setUserData({
        userName: editData?.username,
        email: editData?.userInfo?.email,
        phoneNumber: editData?.mobile,
        password: editData?.password,
        userPost: "",
        institudeName: "",
        city: editData?.userInfo?.city,
      });
    }
  }, [editData]);

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) => {
      const isChecked = prevState.includes(id);

      const updatedItems = isChecked
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id];

      return updatedItems;
    });
  };

  const handleSearchFilter = () => {
    if (
      new Date(endDate) > new Date(startDate) ||
      (startDate === "" && endDate === "")
    ) {
      setCurrentPage(1);
      _getUser(recordsPerPage, 1);
    } else {
      ErrorToast("End date must be greater than start date");
    }
  };

  const resetFilter = () => {
    setIsResetFilter(true);
    setSearchValue("");
    setStartDate("");
    setEndDate("");
  };

  useEffect(() => {
    if (isResetFilter) {
      _getUser(recordsPerPage, 1);
      setIsResetFilter(false);
    }
  }, [isResetFilter]);

  return (
    <div className="h-full flex items-center justify-center ">
      <div className="h-full flex-1 flex flex-col space-y-5 lg:space-y-0 lg:flex-row lg:space-x-10 w-full sm:px-0">
        <div className="flex-1 px-2 sm:px-0 w-full">
          <div className="flex justify-between items-center">
            <div>
              <MasterTitle
                title="All Partners"
                description="Below are the list of all the Partners."
              />
            </div>
            <div className="flex items-center space-x-2">
              <div>
                {" "}
                <div style={{ display: "flex", gap: "5px" }}>
                  {accessRole?.delete && (
                    <Button
                      id=""
                      type="submit"
                      icon={TrashIcon}
                      label={"DELETE PARTNER"}
                      onClick={() => {
                        setDeleteType("multi");
                        if (checkedItems.length > 0) {
                          setOpenModel(true);
                        }
                      }}
                    />
                  )}
                  {accessRole?.add && (
                    <Button
                      id=""
                      type="submit"
                      icon={PlusIcon}
                      label={"ADD PARTNER"}
                      onClick={() => setIsModel(true)}
                    />
                  )}
                </div>
                <Model
                  title={`${editData ? "Edit" : "Add"} Partner`}
                  show={isModel}
                  onCancel={() => {
                    setIsModel(false);
                    // setUserData({});
                    // setError({});
                    // setEditData(null);
                  }}
                  onClose={() => {
                    setIsModel(false);
                    setUserData({});
                    setError({});
                    setEditData(null);
                  }}
                  onOk={() => _addUser()}
                  okText="SUBMIT"
                  cancelText="CLOSE"
                  width="max-w-2xl"
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  <div className="flex flex-col mt-4">
                    <section className="bg-white rounded-2xl flex flex-col shadow-sm min-h-[calc(60vh-40px-16px-24px-38px-50px-40px-52px-16px)]">
                      <Grid container spacing={2} mb={3}>
                        <Grid item md={12} xs={12}>
                          <Input
                            name="userName"
                            type="text"
                            placeholder="Enter your username"
                            label="Partner Name"
                            required
                            onChange={(e) =>
                              handleChange("userName", e?.target?.value.trim())
                            }
                            value={userData?.userName || ""}
                          />
                          <Typography variant="caption" color={"error"}>
                            {!userData?.userName ? error?.userName : ""}
                          </Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Input
                            name="email"
                            type="email"
                            placeholder="Enter your email"
                            label="Email"
                            required
                            onChange={(e) =>
                              handleChange("email", e?.target?.value)
                            }
                            value={userData?.email || ""}
                          />
                          <Typography variant="caption" color={"error"}>
                            {!userData?.email ? error?.email : ""}
                          </Typography>
                          <Typography variant="caption" color={"error"}>
                            {!emailPattern.test(userData?.email)
                              ? error?.isValidEmail
                              : ""}
                          </Typography>
                        </Grid>{" "}
                        <Grid item md={12} xs={12}>
                          <Input
                            name="password"
                            type="password"
                            placeholder="Enter your password"
                            label="Password"
                            required
                            onChange={(e) =>
                              handleChange("password", e?.target?.value)
                            }
                            value={userData?.password || ""}
                          />
                          <Typography variant="caption" color={"error"}>
                            {!userData?.password ? error?.password : ""}
                          </Typography>
                          <Typography variant="caption" color={"error"}>
                            {!passwordRegex.test(userData?.password)
                              ? error?.isValidPassword
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Input
                            name="phoneNumber"
                            type="number"
                            placeholder="Enter your phone  number"
                            label="Phone Number"
                            onChange={(e) =>
                              handleChange("phoneNumber", e?.target?.value)
                            }
                            value={userData?.phoneNumber || ""}
                          />
                          {/* <Typography variant="caption" color={"error"}>
                            {!userData?.phoneNumber ? error?.phoneNumber : ""}
                          </Typography>
                          <Typography variant="caption" color={"error"}>
                            {!phoneNumberRegex.test(userData?.phoneNumber)
                              ? error?.isValidPhoneNumber
                              : ""}
                          </Typography> */}
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Input
                            name="city"
                            type="text"
                            placeholder="Enter your City"
                            label="City"
                            required
                            onChange={(e) =>
                              handleChange("city", e?.target?.value)
                            }
                            value={userData?.city || ""}
                          />
                          <Typography variant="caption" color={"error"}>
                            {!userData?.city ? error?.city : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </section>
                  </div>
                </Model>
              </div>
            </div>
          </div>
          <div className="w-full py-2 sm:px-0 flex items-center gap-x-4">
            <div className="w-full">
              <Input
                required
                disabled={false}
                name=""
                className="font-regular mt-10"
                placeholder="Search here..."
                value={searchValue}
                onChange={handleSearchChange}
              />
            </div>
            <div className="w-full">
              <Input
                disabled={false}
                name=""
                label="From Date :"
                type="date"
                className="font-regular mt-2"
                value={startDate}
                onChange={handleStartDateChange}
              />
            </div>

            <div className="w-full">
              <Input
                disabled={false}
                name=""
                label="To Date :"
                type="date"
                className="font-regular mt-2"
                placeholder="To Date"
                value={endDate}
                onChange={handleEndDateChange}
              />
            </div>
            <div className="mt-10">
              <Button
                id=""
                type="button"
                label={"SEARCH"}
                onClick={handleSearchFilter}
              />
            </div>
            <div className="mt-10">
              <Button
                onClick={() => resetFilter()}
                id=""
                type="button"
                label={"RESET"}
              />
            </div>
          </div>
          <div className="sm:mb-0 overflow-hidden">
            <div className="flex flex-col mt-2">
              <section
                className="bg-white rounded-2xl flex flex-col shadow-sm "
                style={{
                  width: "100%",
                  height: "75vh",
                  margin: "0 auto",
                  padding: "10px",
                }}
              >
                {" "}
                <div className="flex justify-between items-center mt-3 px-8 mb-4">
                  <div></div>
                  <Records
                    setCurrentPage={setCurrentPage}
                    recordsPerPage={recordsPerPage}
                    setRecordsPerPage={setRecordsPerPage}
                    dataCount={userCount}
                  />
                </div>
                <div
                  style={{
                    overflowY: "auto",
                    maxHeight: "58vh",
                    height: "60vh",
                    width: "100%",
                  }}
                >
                  {" "}
                  {!isLoader && userDataTable?.length <= 0 ? (
                    <NoDataFound />
                  ) : (
                    <table style={{ width: "100%" }}>
                      <thead
                        style={{
                          position: "sticky",
                          top: 0,
                          backgroundColor: "#f4f7f8",
                          zIndex: 1,
                        }}
                      >
                        <tr
                          style={{
                            borderBottom: "1px solid #dddddd",
                            textAlign: "left",
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#7B809A",
                            textTransform: "uppercase",
                            backgroundColor: "#f4f7f8",
                          }}
                        >
                          {" "}
                          <th
                            scope="col"
                            style={{ width: "3%", padding: "10px" }}
                          ></th>
                          {titleData?.map(
                            (v, ind) =>
                              v?.title && (
                                <th
                                  key={ind}
                                  // className="py-2 px-6 "
                                  style={{
                                    padding: "12px",
                                    backgroundColor: "#f4f7f8",
                                    position:
                                      v.title === "EDIT" || v.title === "DELETE"
                                        ? "sticky"
                                        : "",
                                    right:
                                      v.title === "EDIT"
                                        ? "68px"
                                        : v.title === "DELETE"
                                        ? "0"
                                        : "",
                                  }}
                                >
                                  <div className="flex justify-between">
                                    <span>{v.title}</span>
                                    {v.title !== "EDIT" &&
                                      v.title !== "DELETE" && (
                                        <span>
                                          <ChevronUpDownIcon
                                            onClick={() => {
                                              let res = SortData(
                                                userDataTable,
                                                v.fieldName,
                                                v.sort
                                              );

                                              let copyData = [...titleData];
                                              copyData[ind].sort =
                                                v.sort > 0 ? -1 : 1;
                                              setTitleData(copyData);
                                              setUserDataTable(res);
                                            }}
                                            className="w-5 h-5"
                                          />
                                        </span>
                                      )}
                                  </div>
                                </th>
                              )
                          )}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {isLoader ? (
                          <tr style={{ height: "25rem" }}>
                            <td colSpan={11} style={{ textAlign: "center" }}>
                              <CircularProgress />
                            </td>
                          </tr>
                        ) : (
                          userDataTable?.length > 0 &&
                          userDataTable?.map((data, id) => (
                            <tr
                              key={id}
                              className="my-3 px-6 text-[#000] font-reguler text-sm font-medium"
                            >
                              <td className="px-6 py-3 whitespace-no-wrap">
                                <Checkbox
                                  checked={checkedItems.includes(data.id)}
                                  onChange={() => handleCheckboxChange(data.id)}
                                />
                              </td>
                              <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                {data.id}
                              </td>
                              <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                {data?.username ||
                                  data?.userInfo?.first_name ||
                                  "-"}
                              </td>
                              <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                {data?.userInfo?.email
                                  ? data?.userInfo?.email
                                  : "-"}
                              </td>{" "}
                              <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                <div className="flex items-center">
                                  {data.isShow
                                    ? data.password
                                    : "*".repeat(data.password?.length)}
                                  <div
                                    className="pl-3 cursor-pointer"
                                    onClick={() =>
                                      togglePasswordVisibility(data?.id)
                                    }
                                  >
                                    {data?.isShow ? (
                                      <EyeSlashIcon className="w-4 h-4" />
                                    ) : (
                                      <EyeIcon className="w-4 h-4" />
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                {data.mobile ? data.mobile : "-"}
                              </td>
                              <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                {data.userInfo?.city
                                  ? data.userInfo?.city
                                  : "-"}
                              </td>
                              <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                {data.createdAt !== null
                                  ? moment(data.createdAt).format(
                                      "D MMMM, YYYY | hh:mm A"
                                    )
                                  : "-"}
                              </td>
                              {accessRole?.edit && (
                                <td
                                  className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                                  style={{
                                    position: "sticky",
                                    right: "68px",
                                    backgroundColor: "white",
                                  }}
                                >
                                  <ActionButton
                                    key={data.key}
                                    action="edit"
                                    onClick={() => _getUserById(data?.id)}
                                  />
                                </td>
                              )}
                              {accessRole?.delete && (
                                <td
                                  className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                                  style={{
                                    position: "sticky",
                                    right: "0",
                                    backgroundColor: "white",
                                  }}
                                >
                                  <ActionButton
                                    key={data.key}
                                    action="delete"
                                    onClick={() => {
                                      setDeleteType("single");
                                      setOpenModel(true);
                                      setSelectUserId(data?.id);
                                    }}
                                  />
                                </td>
                              )}
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  recordsPerPage={recordsPerPage}
                  data={userDataTable}
                  dataCount={userCount}
                  numbers={numbers}
                  npage={npage}
                  lastIndex={lastIndex}
                  firstIndex={firstIndex}
                />
              </section>
            </div>

            {/* Delete */}

            <DeletePopup
              title={"Delete"}
              show={openModel}
              description="Are you sure you want to delete this Data ? All data
              of this Data will be permanently removed from our
              servers forever. This action cannot be undone."
              onCancel={() => {
                setOpenModel(false);
                setSelectUserId("");
              }}
              onClose={() => {
                setOpenModel(false);
                setSelectUserId("");
              }}
              onDelete={() => {
                _deleteUser();
              }}
              cancelButtonRef={cancelButtonRef}
              okText="Delete"
              cancelText="Cancel"
            />
            {/* Delete */}
            <FloatNotification
              show={showFloatNotification}
              title={"Successfully deleted!"}
              type="success"
              description={"Data Deleted."}
              onClick={() => {
                setShowFloatNotification(false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerCollab;
