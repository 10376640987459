import React, { useState, useEffect } from "react";
import { Input, TextArea } from "./Input";
import MasterTitle from "./MasterTitle";
import { ArrowLongLeftIcon } from "@heroicons/react/24/outline";
import { HomeIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast } from "./toast";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { getDecryptedDataFromLocalStorage } from "../Api/encryption";

const OrderComment = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Order History")?.[0];
  const navigate = useNavigate();
  let userData = getDecryptedDataFromLocalStorage("userData");
  const [BookReportData, setBookReportData] = useState([]);
  const [searchPhoneValue, setSearchPhoneValue] = useState("");

  const [commentValues, setCommentValues] = useState([]);

  const handleColorChange = (event, id) => {
    const updatedCommentValues = [...commentValues];
    updatedCommentValues[id] = {
      ...updatedCommentValues[id],
      selectedColor: event.target.value,
    };
    setCommentValues(updatedCommentValues);
  };

  const handleBookCommentChange = (event, id) => {
    const updatedCommentValues = [...commentValues];
    updatedCommentValues[id] = {
      ...updatedCommentValues[id],
      BookCommentValue: event.target.value,
    };
    setCommentValues(updatedCommentValues);
  };

  const handleSearchPhoneChange = (event) => {
    setSearchPhoneValue(event.target.value);
  };

  const getBookReportData = async () => {
    try {
      const { data } = await Api.getRequest(
        `api/subscription/getSubscriptionCommentByMobile/${searchPhoneValue}`
      );
      const req = JSON.parse(data);

      if (req?.success === true) {
        setBookReportData(req?.data);
      } else {
        setBookReportData([]);
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const handleSearchBookComment = () => {
    if (searchPhoneValue !== "") {
      getBookReportData();
    } else {
      ErrorToast("Please enter Phone Number.");
    }
  };
  const handleAddComment = async (Bookdata) => {
    const commentData = commentValues[Bookdata.id];
    
    if (commentData && commentData.BookCommentValue !== "") {
      const payload = {
        userId: userData?.id,
        description:
          commentData.BookCommentValue || Bookdata.comment.description,
        color: commentData.selectedColor || "BLACK",
        subscriptionId: Bookdata.id,
        subscriptionUserId: Bookdata?.userInfo?.id,
        mobile: Bookdata?.userInfo?.mobile,
      };

      try {
        const { data } = await Api.putRequest(
          `api/subscription/comment/update`,
          payload
        );

        if (data?.status === 200) {
          SuccessToast("Comment Added Successfully");
        } else {
          ErrorToast(data?.message);
        }
      } catch (error) {
        ErrorToast(error?.message);
      }
    } else {
      ErrorToast("Please enter Comment.");
    }
  };
  return (
    <div className="w-full sm:p-6 ">
      {/* <div className="flex justify-between items-center">
        <nav className="flex">
          <ol className="inline-flex items-center space-x-1 md:space-x-3 text-[#7B809A] text-sm font-regular font-roboto">
            <li className="inline-flex items-center">
              <div className="inline-flex items-center ">
                <HomeIcon className="w-4 h-4" />
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <p>/</p>
                <div className="ml-1 ">Order History</div>
              </div>
            </li>
            <li aria-current="page">
              <div className="flex items-center">
                <p>/</p>
                <p className="ml-1 md:ml-2">
                  <span className=" text-[#344767]">Order Comment</span>
                </p>
              </div>
            </li>
          </ol>
        </nav>

        <Input
          id=""
          name="Search"
          type="text"
          placeholder="Search here"
          autoComplete="given-name"
          required
          className="w-56"
        />
      </div> */}

      <div className="flex justify-between items-center mt-2">
        <div className="flex space-x-2">
          <div onClick={() => navigate("/paper-generation/order-history")}>
            <ArrowLongLeftIcon className="w-6 h-6 text-[#344767]" />
          </div>
          <MasterTitle
            title="Add Order Comment "
            description="Below are the list of all the add order comment."
          />
        </div>
      </div>
      <section className="bg-white rounded-2xl shadow-sm overflow-hidden mt-6 sm:p-6">
        <div className="mt-6">
          <h1 className="mb-3 text-sm font-medium text-[#252F40] font-poppins">
            Phone
          </h1>
          <div className=" flex-row  md:flex items-center">
            <div
              className="relative w-full lg:mr-3 xl:max-w-lg "
              data-te-input-wrapper-init
            >
              <Input
                id=""
                name="Search"
                type="number"
                autoComplete="given-name"
                required
                className="w-full"
                value={searchPhoneValue}
                onChange={handleSearchPhoneChange}
              />
            </div>
            <button
              onClick={handleSearchBookComment}
              className=" inline-flex items-center px-4 py-2.5 text-white bg-gradient-to-b from-[#1190E4] to-[#3552D8] rounded-md text-sm "
            >
              SEARCH
            </button>
          </div>
          {BookReportData && BookReportData.length === 0 ? (
            <div className="mt-12 text-center my-[20rem]">
              <h1 className="text-lg font-medium font-poppins text-[#344767]">
                No data found for this selection.
              </h1>
            </div>
          ) : (
            <div className="overflow-y-auto h-[calc(100vh-40px-16px-24px-50px-50px-40px-52px-16px-42px-24px-62px-32px)]">
              <table className="w-full mt-2">
                <thead>
                  <tr className="border-b border-gray-200 text-left text-xs leading-4 font-bold text-[#7B809A] tracking-wider">
                    <th className="py-4 px-3 ">CUSTOMER NAME</th>
                    <th className="py-4 px-3 ">EMAIL</th>
                    <th className="py-4 px-3 ">MOBILE</th>
                    <th className="py-4 px-3 ">COMMENTS</th>
                    <th className="py-4 px-3 ">ACTION</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {/* {BookReportData.map((data, id) => ( */}
                  <tr
                    //   key={id}
                    className="my-4 px-3 text-[#7B809A] font-reguler text-sm align-center"
                  >
                    <td className="hidden lg:table-cell whitespace-nowrap px-3 py-2.5 ">
                      {BookReportData?.userInfo?.username || "-"}
                    </td>
                    <td className="hidden lg:table-cell whitespace-nowrap px-3 py-2.5 ">
                      {BookReportData?.userInfo?.userInfo?.email || "-"}
                    </td>
                    <td className="hidden lg:table-cell whitespace-nowrap px-3 py-2.5 ">
                      {BookReportData?.userInfo?.mobile || "-"}
                    </td>
                    <td className="hidden lg:table-cell whitespace-nowrap px-3 py-2.5 ">
                      <TextArea
                        name="description"
                        placeholder="COMMENT here..."
                        type="text"
                        autoComplete="given-name"
                        required
                        className="w-full mb-2"
                        style={{ height: "120px" }}
                        value={
                          commentValues[BookReportData?.id]?.BookCommentValue
                        }
                        defaultValue={BookReportData?.comment?.description}
                        onChange={(event) =>
                          handleBookCommentChange(event, BookReportData?.id)
                        }
                      />
                    </td>
                    <td className="hidden lg:table-cell whitespace-nowrap px-3 py-2.5 ">
                      <div style={{ display: "grid", placeItems: "center" }}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={
                              commentValues[BookReportData?.id]
                                ?.selectedColor || "BLACK"
                            }
                            onChange={(event) =>
                              handleColorChange(event, BookReportData?.id)
                            }
                          >
                            <FormControlLabel
                              value="BLACK"
                              control={<Radio size="small" />}
                              label="BLACK"
                            />
                            <FormControlLabel
                              value="RED"
                              control={<Radio size="small" />}
                              label="RED"
                            />
                            <FormControlLabel
                              value="BLUE"
                              control={<Radio size="small" />}
                              label="BLUE"
                            />
                            <FormControlLabel
                              value="GREEN"
                              control={<Radio size="small" />}
                              label="GREEN"
                            />
                            <FormControlLabel
                              value="BLUE PURPLE"
                              control={<Radio size="small" />}
                              label="BLUE PURPLE"
                            />
                          </RadioGroup>
                        </FormControl>
                        <div>
                          <button
                            onClick={() => handleAddComment(BookReportData)}
                            style={{ width: "130px", marginTop: "10px" }}
                            className="inline-flex items-center px-4 py-2.5 text-white bg-gradient-to-b from-[#1190E4] to-[#3552D8] rounded-md text-sm hover:from-[#3552D8] hover:to-[#1190E4] transition duration-300"
                          >
                            ADD COMMENT
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  {/* ))} */}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default OrderComment;
