import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "./Breadcrumbs";
import MasterTitle from "./MasterTitle";
import { Button } from "./Button";
import { ChevronUpDownIcon, PlusIcon } from "@heroicons/react/20/solid";
import { Input } from "./Input";
import Records from "./Records";
import NoDataFound from "./NoDataFound";
import Pagination from "./Pagination";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast } from "./toast";
import { SortData } from "./SortData";
import { CircularProgress, Switch } from "@mui/material";
import ActionPopup from "./ActionPopup";
import Model from "./Model";
import Select from "./Select";
import DeletePopup from "./DeletePopup";
import FloatNotification from "./FloatNotification";
import { getDecryptedDataFromLocalStorage } from "../Api/encryption";
import ActionButton from "./ActionButton";

const PromoCode = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Promocode")?.[0];
  const [modelType, setModelType] = useState("add");
  const [isModel, setIsModel] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [promoCodeData, setPromoCodeData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataCount, setDataCount] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(dataCount / recordsPerPage);
  const numbers =
    npage && npage > 0 ? [...Array(npage + 1).keys()].slice(1) : [];
  const cancelButtonRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const [SelectedData, setSelectedData] = useState([]);
  const [error, setError] = useState({});
  const [PartnerLimit, setPartnerLimit] = useState(0);

  const [promoCodeSelectedData, setPromoCodeSelectedData] = useState({
    name: "",
    assignPartner: "",
    discountValue: "",
    partnerdiscount: "",
    // discountType: "",
  });
  const [AllPartner, setAllPartner] = useState([]);

  const getAllPartner = async () => {
    try {
      const { data } = await Api.getRequest(`api/auth/getAllPartner`);
      const req = JSON.parse(data);
      setAllPartner(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const setDefaultValues = () => {
    setPromoCodeSelectedData({
      ...promoCodeSelectedData,
      name: SelectedData?.name,
      discountValue: SelectedData?.discountValue,
      partnerdiscount: SelectedData?.partnerdiscount,
      assignPartner: SelectedData?.userInfo,
      //   discountType: SelectedData?.discountType,
    });
  };

  useEffect(() => {
    setDefaultValues();
  }, [SelectedData]);

  const resetSubscriptionPrice = () => {
    setPromoCodeSelectedData({
      name: "",
      discountValue: "",
      partnerdiscount: "",
      assignPartner: "",
      //   discountType: "",
    });
    setError({});
    setIsModel(false);
  };

  const [titleData, setTitleData] = useState([
    {
      title: "NAME",
      sort: 1,
      fieldName: "name",
    },
    {
      title: "ASSIGN PARTNER",
      sort: 1,
      fieldName: "assignPartner",
    },
    {
      title: "DISCOUNT(%)",
      sort: 1,
      fieldName: "discountValue",
    },
    {
      title: "PARTNER DISCOUNT(%)",
      sort: 1,
      fieldName: "partnerdiscount",
    },
    {
      title: "ACTIVE",
      sort: 1,
      fieldName: "active",
    },
    {
      title: access?.edit ? "EDIT" : "",
      sort: 1,
      fieldName: "",
    },
    {
      title: access?.delete ? "DELETE" : "",
      sort: 1,
      fieldName: "",
    },
  ]);

  const validation = () => {
    let errors = {};
    let formIsValid = true;
    if (!promoCodeSelectedData?.name) {
      formIsValid = false;
      errors["name"] = "Please Enter name.";
    }
    // if (!promoCodeSelectedData?.assignPartner) {
    //   formIsValid = false;
    //   errors["assignPartner"] = "Please Enter assign Partner value.";
    // }
    if (!promoCodeSelectedData?.discountValue) {
      formIsValid = false;
      errors["discountValue"] = "Please Enter discount value.";
    }
    if (!promoCodeSelectedData?.partnerdiscount) {
      formIsValid = false;
      errors["partnerdiscount"] = "Please Enter partner discount value.";
    }
    // if (!promoCodeSelectedData?.discountType) {
    //   formIsValid = false;
    //   errors["discountType"] = "Please Enter discount value.";
    // }
    setError(errors);
    return formIsValid;
  };

  const getPromoCodeData = async () => {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/promocode/?offset=${currentPage}&limit=${recordsPerPage}&search=${searchValue}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setPromoCodeData(req?.data);
        setDataCount(req?.count);
        setIsLoader(false);
      } else {
        ErrorToast(req?.message);
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoading(false);
    }
  };

  const addorEditPromoCode = async () => {
    let body = {
      name: promoCodeSelectedData?.name,
      discountValue: promoCodeSelectedData?.discountValue,
      partnerPercentage: promoCodeSelectedData?.partnerdiscount,
      discountType: "percentage",
      userId: promoCodeSelectedData?.assignPartner?.id,
      //   discountType: promoCodeSelectedData?.discountType,
    };
    if (validation()) {
      setIsLoading(true);
      if (modelType === "add") {
        try {
          const { data } = await Api.postRequest(`api/promocode/create`, body);
          const req = JSON.parse(data);
          if (req?.status === 201) {
            SuccessToast(req?.message);
            getPromoCodeData();
            resetSubscriptionPrice();
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
          } else {
            ErrorToast(req?.message);
          }
        } catch (error) {
          ErrorToast(error?.message);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      } else {
        try {
          const { data } = await Api.putRequest(
            `api/promocode/update/${SelectedData?.id}`,
            body
          );
          if (data?.status === 200) {
            SuccessToast(data?.message);
            getPromoCodeData();
            resetSubscriptionPrice();
            setIsModel(false);
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
          } else {
            ErrorToast(data?.message);
          }
        } catch (error) {
          ErrorToast(error?.message);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const deletePromoCodeData = async () => {
    try {
      const { data } = await Api.deleteRequest(
        `api/promocode/delete/${SelectedData?.id}`
      );

      const req = JSON.parse(data);
      if (req?.status === 200) {
        setOpenModel(false);
        setSelectedData([]);
        getPromoCodeData();
        SuccessToast("Subscription Price Delete Successfully");
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getPromoCodeData();
  }, [recordsPerPage, currentPage ]);

  const applyFilter = () => {   
    getPromoCodeData();
  };

  useEffect(() => {
    getAllPartner();
  }, []);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    setCurrentPage(1);
  };

  const handlePromocodeData = (name, value) => {
    setPromoCodeSelectedData((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
  };

  const toggleActiveStatus = async (data) => {
    const body = {
      status: data?.status === 0 ? 1 : 0,
    };
    try {
      const response = await Api.putRequest(
        `api/promocode/update/${data?.id}`,
        body
      );
      if (response?.statusCode === 200) {
        SuccessToast(response?.data?.message);
        getPromoCodeData();
        // getAddSoundData(recordsPerPage, currentPage);
      } else {
        ErrorToast(response?.data?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <div>
      <div className="h-full flex items-center justify-center ">
        <div className="h-full flex-1 flex flex-col space-y-5 lg:space-y-0 lg:flex-row lg:space-x-10 w-full sm:px-6 ">
          <div className="flex-1 px-2 sm:px-0 w-full">
            {/* <div className="flex justify-between items-center mt-4">
              <Breadcrumbs title="/ Promo Code" />
            </div> */}
            <div>
              <div className="flex justify-between items-center mt-3">
                <div>
                  <MasterTitle
                    title="Promo Code"
                    description="Below are all the list of Promo Code."
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ padding: "5px" }}>
                    {access?.add && (
                      <Button
                        id=""
                        type="submit"
                        icon={PlusIcon}
                        label={"ADD PROMO CODE"}
                        onClick={() => {
                          setIsModel(true);
                          setModelType("add");
                        }}
                      />
                    )}
                  </div>
                  <Model
                    title={
                      modelType === "add" ? "Add Promo Code" : "Edit Promo Code"
                    }
                    show={isModel}
                    onCancel={() => {
                      // setSelectedData([]);
                      // resetSubscriptionPrice();
                      setIsModel(false);
                    }}
                    onClose={() => {
                      setSelectedData([]);
                      resetSubscriptionPrice();
                    }}
                    onOk={() => {
                      addorEditPromoCode();
                    }}
                    okText="SUBMIT"
                    cancelText="CLOSE"
                    isLoading={isLoading}
                    disabled={isLoading}
                  >
                    <Input
                      type="text"
                      label="Name"
                      autoComplete="given-name"
                      placeholder="Name here..."
                      required
                      className="w-full mt-2 mb-3"
                      value={promoCodeSelectedData?.name}
                      onChange={(e) =>
                        handlePromocodeData("name", e.target.value)
                      }
                    />
                    <div className="text-red-500 text-sm">
                      {!promoCodeSelectedData?.name ? error?.name : ""}
                    </div>

                    <Select
                      value={promoCodeSelectedData?.assignPartner}
                      data={AllPartner || []}
                      onChange={(e) => handlePromocodeData("assignPartner", e)}
                      label="Assign Partner"
                      className="w-full mb-2"
                      type={"assignPartner"}
                      placeholder="All Assign Partner"
                      getPartner={getAllPartner}
                    />

                    {/* <div className="text-red-500 text-sm">
                      {!promoCodeSelectedData?.assignPartner ? error?.assignPartner : ""}
                    </div> */}

                    <Input
                      type="number"
                      label="Discount(%)"
                      autoComplete="given-name"
                      placeholder="Discount here..."
                      required
                      className="w-full mt-2 mb-3"
                      value={promoCodeSelectedData?.discountValue}
                      onChange={(e) =>
                        handlePromocodeData("discountValue", e.target.value)
                      }
                    />
                    <div className="text-red-500 text-sm">
                      {!promoCodeSelectedData?.discountValue
                        ? error?.discountValue
                        : ""}
                    </div>

                    <Input
                      type="number"
                      label="Partners Discount(%)"
                      autoComplete="given-name"
                      placeholder="Partners Discount here..."
                      required
                      className="w-full mt-2 mb-3"
                      value={promoCodeSelectedData?.partnerdiscount}
                      onChange={(e) =>
                        handlePromocodeData("partnerdiscount", e.target.value)
                      }
                    />
                    <div className="text-red-500 text-sm">
                      {!promoCodeSelectedData?.partnerdiscount
                        ? error?.partnerdiscount
                        : ""}
                    </div>

                    {/* <Input
                      type="text"
                      label="Discount Type"
                      autoComplete="given-name"
                      placeholder="Discount Type here..."
                      required
                      className="w-full mt-2 mb-3"
                      value={promoCodeSelectedData?.discountType}
                      onChange={(e) =>
                        handlePromocodeData("discountType", e.target.value)
                      }
                    />
                    <div className="text-red-500 text-sm">
                      {!promoCodeSelectedData?.discountType
                        ? error?.discountType
                        : ""}
                    </div> */}
                    {/* <Select
                      value={promoCodeSelectedData?.discountType}
                      data={data || []}
                      onChange={(e) => handlePromocodeData("discountType", e)}
                      label="discount Type"
                      className="w-full mb-2"
                      required
                      type={"discountType"}
                      placeholder="discountType"
                    />

                    <div className="text-red-500 text-sm">
                      {!promoCodeSelectedData?.discountType
                        ? error?.discountType
                        : ""}
                    </div> */}
                  </Model>
                </div>
              </div>

              <div className="sm:mb-0 overflow-hidden">
                <div className="flex flex-col mt-4">
                  <section
                    className="bg-white rounded-2xl flex flex-col shadow-sm "
                    style={{
                      width: "100%",
                      height: "85vh",
                      margin: "0 auto",
                      padding: "20px",
                    }}
                  >
                    {" "}
                    <div className="flex justify-between items-center mt-6 px-8 mb-8">
                      <div style={{ display: "flex", gap:'5px' }}>
                      <Input
                        id=""
                        name="Search"
                        type="text"
                        placeholder="Search here..."
                        autoComplete="given-name"
                        required
                        className="w-72"
                        value={searchValue}
                        onChange={handleSearchChange}
                      />
                       <Button
                          onClick={() => applyFilter()}
                          id=""
                          type="button"
                          label={"SEARCH"}
                        />
                      </div>
                      <div>
                        <Records
                          recordsPerPage={recordsPerPage}
                          setCurrentPage={setCurrentPage}
                          setRecordsPerPage={setRecordsPerPage}
                          dataCount={dataCount}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        overflowY: "auto",
                        maxHeight: "58vh",
                        height: "60vh",
                        width: "100%",
                      }}
                    >
                      {" "}
                      {!isLoader && promoCodeData?.length <= 0 ? (
                        <NoDataFound />
                      ) : (
                        <table style={{ width: "100%" }}>
                          <thead
                            style={{
                              position: "sticky",
                              top: 0,
                              backgroundColor: "#f4f7f8",
                              zIndex: 1,
                            }}
                          >
                            <tr
                              style={{
                                borderBottom: "1px solid #dddddd",
                                textAlign: "left",
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "#7B809A",
                                textTransform: "uppercase",
                                backgroundColor: "#f4f7f8",
                              }}
                            >
                              <th
                                scope="col"
                                style={{ width: "3%", padding: "10px" }}
                              ></th>

                              {titleData.map(
                                (v, ind) =>
                                  v.title && (
                                    <th
                                      key={ind}
                                      // className="py-2 px-3 "
                                      style={{
                                        padding: "12px",
                                        backgroundColor: "#f4f7f8",
                                        position:
                                          v.title === "EDIT" ||
                                          v.title === "DELETE"
                                            ? "sticky"
                                            : "",
                                        right:
                                          v.title === "EDIT"
                                            ? "68px"
                                            : v.title === "DELETE"
                                            ? "0"
                                            : "",
                                      }}
                                    >
                                      <div className="flex justify-between">
                                        <span>{v.title}</span>
                                        {v.title !== "EDIT" &&
                                          v.title !== "DELETE" && (
                                            <span>
                                              <ChevronUpDownIcon
                                                onClick={() => {
                                                  let res = SortData(
                                                    promoCodeData,
                                                    v.fieldName,
                                                    v.sort
                                                  );

                                                  let copyData = [...titleData];
                                                  copyData[ind].sort =
                                                    v.sort > 0 ? -1 : 1;
                                                  setTitleData(copyData);
                                                  setPromoCodeData(res);
                                                }}
                                                className="w-5 h-5"
                                              />
                                            </span>
                                          )}
                                      </div>
                                    </th>
                                  )
                              )}
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200">
                            {isLoader ? (
                              <tr style={{ height: "25rem" }}>
                                <td colSpan={6} style={{ textAlign: "center" }}>
                                  <CircularProgress />
                                </td>
                              </tr>
                            ) : (
                              promoCodeData?.map((data, id) => (
                                <tr
                                  key={id}
                                  className="my-4 px-3 text-[#000] font-reguler text-sm font-medium"
                                >
                                  <td className="px-6 py-3 whitespace-no-wrap"></td>
                                  <td className=" lg:table-cell whitespace-nowrap py-2.5 px-3">
                                    {data?.name}
                                  </td>
                                  <td className=" lg:table-cell whitespace-nowrap py-2.5 px-3">
                                    {data?.userInfo?.username}
                                  </td>
                                  <td className=" lg:table-cell whitespace-nowrap px-3 py-2.5 ">
                                    {`${parseFloat(data?.discountValue).toFixed(
                                      0
                                    )}%`}
                                  </td>
                                  <td className=" lg:table-cell whitespace-nowrap px-3 py-2.5 ">
                                    {`${parseFloat(
                                      data?.partnerPercentage
                                    ).toFixed(0)}%`}
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                    <box
                                      onClick={() => toggleActiveStatus(data)}
                                    >
                                      <Switch
                                        {...label}
                                        checked={data?.status === 0 ? 1 : 0}
                                      />
                                    </box>
                                  </td>
                                  {/* <td className="hidden lg:table-cell whitespace-nowrap px-3 py-2.5 ">
                                        {data?.discountType}
                                      </td> */}

                                  {access?.edit && (
                                    <td
                                      className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                                      style={{
                                        position: "sticky",
                                        right: "68px",
                                        backgroundColor: "white",
                                      }}
                                    >
                                      <ActionButton
                                        key={data.key}
                                        action="edit"
                                        onClick={() => {
                                          setIsModel(true);
                                          setSelectedData(data);
                                          setModelType("edit");
                                        }}
                                      />
                                    </td>
                                  )}
                                  {access?.delete && (
                                    <td
                                      className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                                      style={{
                                        position: "sticky",
                                        right: "0",
                                        backgroundColor: "white",
                                      }}
                                    >
                                      <ActionButton
                                        key={data.key}
                                        action="delete"
                                        onClick={() => {
                                          setOpenModel(true);
                                          setSelectedData(data);
                                        }}
                                      />
                                    </td>
                                  )}
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      recordsPerPage={recordsPerPage}
                      data={promoCodeData}
                      numbers={numbers}
                      npage={npage}
                      lastIndex={lastIndex}
                      firstIndex={firstIndex}
                      dataCount={dataCount}
                    />
                  </section>
                </div>
                {/* Delete */}
                <DeletePopup
                  title={"Delete"}
                  show={openModel}
                  description="Are you sure you want to delete this Data ? All data
        of this Data will be permanently removed from our
        servers forever. This action cannot be undone."
                  onCancel={() => {
                    setOpenModel(false);
                    setSelectedData([]);
                  }}
                  onClose={() => {
                    setOpenModel(false);
                    setSelectedData([]);
                  }}
                  onDelete={() => {
                    deletePromoCodeData();
                    // setOpenModel(false);
                    // setShowFloatNotification(true);
                  }}
                  cancelButtonRef={cancelButtonRef}
                  okText="Delete"
                  cancelText="Cancel"
                />
                {/* Delete */}
                <FloatNotification
                  show={showFloatNotification}
                  title={"Successfully deleted!"}
                  type="success"
                  description={"Data Deleted."}
                  onClick={() => {
                    setShowFloatNotification(false);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromoCode;
