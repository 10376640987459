import { ChapterActions } from "../action-types/chapterActionType";

const initialState = {
    chapter: [],
};

export default function chapterReducer(state = initialState, action) {
  switch (action.type) {
    case ChapterActions.GET_CHAPTER_DATA:
      return {
        ...state,
      };
    case ChapterActions.SET_CHAPTER_DATA:
      return {
        ...state,
        chapter: action.payload,
      };
    default:
      return state;
  }
}
