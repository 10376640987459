import { SubjectActions } from "../action-types/subjectActionType";

const initialState = {
    subject: [],
};

export default function subjectReducer(state = initialState, action) {
  switch (action.type) {
    case SubjectActions.GET_SUBJECT_DATA:
      return {
        ...state,
      };
    case SubjectActions.SET_SUBJECT_DATA:
      return {
        ...state,
        subject: action.payload,
      };
    default:
      return state;
  }
}
