import Pagination from "./Pagination";
import { useRef, useState } from "react";
import { Button } from "./Button";
import Model from "./Model";
import { Input } from "./Input";
import MasterTitle from "./MasterTitle";
import ads1 from "../Image/ads1.png";
import ads2 from "../Image/ads2.png";
import ads3 from "../Image/ads3.png";
import ads4 from "../Image/ads4.png";
import Records from "./Records";
import FloatNotification from "./FloatNotification";
import { Link } from "react-router-dom";
import Select from "./Select";
import { PlusIcon } from "@heroicons/react/20/solid";
import DeletePopup from "./DeletePopup";
import ActionPopup from "./ActionPopup";

const AdsYouth = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Student App")?.[0];
  const [isModel, setIsModel] = useState(false);
  const [modelType, setModelType] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const cancelButtonRef = useRef(null);
  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const cardData = [
    {
      id: "1",
      img: ads1,
      link: "https://www.google.com/imgres?imgurl=https%3A%2F 2Fassets.materialup.com%2Fuploads%2Fae7adf05 e25c-4606-94be-3c56a07a1d9a%",
      status: 0,
    },
    {
      id: "2",
      img: ads2,
      link: "https://www.google.com/imgres?imgurl=https%3A%2F 2Fassets.materialup.com%2Fuploads%2Fae7adf05 e25c-4606-94be-3c56a07a1d9a%",
      status: 1,
    },
    {
      id: "3",
      img: ads3,
      link: "https://www.google.com/imgres?imgurl=https%3A%2F 2Fassets.materialup.com%2Fuploads%2Fae7adf05 e25c-4606-94be-3c56a07a1d9a%",
      status: 2,
    },
    {
      id: "4",
      img: ads2,
      link: "https://www.google.com/imgres?imgurl=https%3A%2F 2Fassets.materialup.com%2Fuploads%2Fae7adf05 e25c-4606-94be-3c56a07a1d9a%",
      status: 3,
    },
    {
      id: "5",
      img: ads4,
      link: "https://www.google.com/imgres?imgurl=https%3A%2F 2Fassets.materialup.com%2Fuploads%2Fae7adf05 e25c-4606-94be-3c56a07a1d9a%",
      status: 4,
    },
    {
      id: "6",
      img: ads1,
      link: "https://www.google.com/imgres?imgurl=https%3A%2F 2Fassets.materialup.com%2Fuploads%2Fae7adf05 e25c-4606-94be-3c56a07a1d9a%",
      status: 5,
    },
    {
      id: "7",
      img: ads1,
      link: "https://www.google.com/imgres?imgurl=https%3A%2F 2Fassets.materialup.com%2Fuploads%2Fae7adf05 e25c-4606-94be-3c56a07a1d9a%",
      status: 5,
    },
    {
      id: "8",
      img: ads1,
      link: "https://www.google.com/imgres?imgurl=https%3A%2F 2Fassets.materialup.com%2Fuploads%2Fae7adf05 e25c-4606-94be-3c56a07a1d9a%",
      status: 5,
    },
    {
      id: "9",
      img: ads1,
      link: "https://www.google.com/imgres?imgurl=https%3A%2F 2Fassets.materialup.com%2Fuploads%2Fae7adf05 e25c-4606-94be-3c56a07a1d9a%",
      status: 5,
    },
    {
      id: "10",
      img: ads1,
      link: "https://www.google.com/imgres?imgurl=https%3A%2F 2Fassets.materialup.com%2Fuploads%2Fae7adf05 e25c-4606-94be-3c56a07a1d9a%",
      status: 5,
    },
    {
      id: "11",
      img: ads1,
      link: "https://www.google.com/imgres?imgurl=https%3A%2F 2Fassets.materialup.com%2Fuploads%2Fae7adf05 e25c-4606-94be-3c56a07a1d9a%",
      status: 5,
    },
    {
      id: "12",
      img: ads1,
      link: "https://www.google.com/imgres?imgurl=https%3A%2F 2Fassets.materialup.com%2Fuploads%2Fae7adf05 e25c-4606-94be-3c56a07a1d9a%",
      status: 5,
    },

    {
      id: "13",
      img: ads1,
      link: "https://www.google.com/imgres?imgurl=https%3A%2F 2Fassets.materialup.com%2Fuploads%2Fae7adf05 e25c-4606-94be-3c56a07a1d9a%",
      status: 5,
    },
    {
      id: "14",
      img: ads1,
      link: "https://www.google.com/imgres?imgurl=https%3A%2F 2Fassets.materialup.com%2Fuploads%2Fae7adf05 e25c-4606-94be-3c56a07a1d9a%",
      status: 5,
    },
    {
      id: "15",
      img: ads1,
      link: "https://www.google.com/imgres?imgurl=https%3A%2F 2Fassets.materialup.com%2Fuploads%2Fae7adf05 e25c-4606-94be-3c56a07a1d9a%",
      status: 5,
    },
    {
      id: "16",
      img: ads1,
      link: "https://www.google.com/imgres?imgurl=https%3A%2F 2Fassets.materialup.com%2Fuploads%2Fae7adf05 e25c-4606-94be-3c56a07a1d9a%",
      status: 5,
    },
    {
      id: "17",
      img: ads1,
      link: "https://www.google.com/imgres?imgurl=https%3A%2F 2Fassets.materialup.com%2Fuploads%2Fae7adf05 e25c-4606-94be-3c56a07a1d9a%",
      status: 5,
    },
    {
      id: "18",
      img: ads1,
      link: "https://www.google.com/imgres?imgurl=https%3A%2F 2Fassets.materialup.com%2Fuploads%2Fae7adf05 e25c-4606-94be-3c56a07a1d9a%",
      status: 5,
    },
    {
      id: "19",
      img: ads1,
      link: "https://www.google.com/imgres?imgurl=https%3A%2F 2Fassets.materialup.com%2Fuploads%2Fae7adf05 e25c-4606-94be-3c56a07a1d9a%",
      status: 5,
    },
    {
      id: "20",
      img: ads1,
      link: "https://www.google.com/imgres?imgurl=https%3A%2F 2Fassets.materialup.com%2Fuploads%2Fae7adf05 e25c-4606-94be-3c56a07a1d9a%",
      status: 5,
    },
  ];
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(cardData.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  const data = [
    { id: 1, name: "All Standard" },
    { id: 2, name: "All Standard Two" },
  ];

  return (
    <div>
      <div className="flex justify-between items-center mt-10">
        <div>
          <MasterTitle
            title="3rd Party Ads Youth"
            description="Below are the list of all the 3rd Party Ads Youth."
          />
        </div>
        <div>
          {access?.add && (
            <Button
              id=""
              type="submit"
              icon={PlusIcon}
              label={"ADD 3 PARTY ADS YOUTH"}
              onClick={() => {
                setIsModel(true);
                setModelType("add");
              }}
            />
          )}

          <Model
            title={
              modelType === "add"
                ? "Add 3rd Party Ads Youth"
                : "Edit 3rd Party Ads Youth"
            }
            show={isModel}
            onCancel={() => {
              setIsModel(false);
            }}
            onClose={() => {
              setIsModel(false);
            }}
            onOk={() => {
              setIsModel(false);
            }}
            okText="SUBMIT"
            cancelText="CLOSE"
          >
            <form className="mb-4">
              <label
                htmlFor="fileUpload"
                className="mb-3 block text-sm font-medium text-[#252F40] font-poppins"
              >
                Upload photo
              </label>
              <input type="file" id="fileUpload" />
            </form>
            <Input
              label="Link"
              type="text"
              autoComplete="given-name"
              required
              className="mb-3"
            />
            <Select
              data={data}
              className="font-regular mb-3"
              label="Status"
              required
            />
          </Model>
        </div>
      </div>
      <div className="sm:mb-0 overflow-hidden">
        <div className="flex flex-col mt-4">
          <section className="bg-white rounded-2xl flex flex-col shadow-sm min-h-[calc(100vh-40px-16px-24px-38px-50px-40px-52px-16px)]">
            <div className="flex justify-between items-center mt-6 px-8 mb-8">
              <Input
                id=""
                name="Search"
                type="text"
                autoComplete="given-name"
                required
                className="w-72"
              />
              <div>
                <Records
                  recordsPerPage={recordsPerPage}
                  setRecordsPerPage={setRecordsPerPage}
                />
              </div>
            </div>
            <div className="h-[calc(100vh-40px-16px-24px-38px-50px-40px-52px-16px-42px-24px-62px-32px)] overflow-y-auto">
              <div className="w-full  grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8 px-8 ">
                {cardData.length === 0 ? (
                  <div>No matching records found</div>
                ) : (
                  cardData
                    .slice(lastIndex, lastIndex + recordsPerPage)
                    .map((detail, id) => (
                      <div
                        className="bg-white border border-[#EDEEF0] p-4 rounded-xl tracking-wide max-w-lg overflow-hidden"
                        key={id}
                      >
                        <div className="flex">
                          <img
                            alt="mountain"
                            className="w-45 h-45"
                            src={detail.img}
                          />
                          <div className="ml-5">
                            <div className="flex items-start justify-between ">
                              <Link to="/">
                                <div className="text-xs font-regular text-[#7B809A] font-roboto ">
                                  {detail.link}
                                </div>
                              </Link>
                              {(access?.edit || access?.delete) && (
                                <div>
                                  <ActionPopup
                                    onClick={() => {
                                      setIsModel(true);
                                      setModelType("edit");
                                    }}
                                    onDelete={() => {
                                      setOpenModel(true);
                                    }}
                                    accessEdit={access?.edit}
                                    accessDelete={access?.delete}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="space-x-3 flex mt-4">
                              <p className="text-[#484C64] font-medium text-sm">
                                Status
                              </p>
                              <p className="text-[#7B809A] font-regular text-sm">
                                {detail.status}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                )}
              </div>
            </div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              recordsPerPage={recordsPerPage}
              data={cardData}
              numbers={numbers}
              npage={npage}
              lastIndex={lastIndex}
              firstIndex={firstIndex}
            />
          </section>
        </div>

        {/* Delete */}
        <DeletePopup
          title={"Delete"}
          show={openModel}
          description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
          onCancel={() => {
            setOpenModel(false);
          }}
          onClose={() => {
            setOpenModel(false);
          }}
          onDelete={() => {
            setOpenModel(false);
            setShowFloatNotification(true);
          }}
          cancelButtonRef={cancelButtonRef}
          okText="Delete"
          cancelText="Cancel"
        />

        {/* Delete */}
        <FloatNotification
          show={showFloatNotification}
          title={"Successfully deleted!"}
          type="success"
          description={"Data Deleted."}
          onClick={() => {
            setShowFloatNotification(false);
          }}
        />
      </div>
    </div>
  );
};

export default AdsYouth;
