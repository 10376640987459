import CryptoJS from "crypto-js";

const ENCRYPTION_KEY = "braincubertechnologies";

const encryptData = (data) => {
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    ENCRYPTION_KEY
  ).toString();
  return encryptedData;
};

const decryptData = (encryptedData) => {
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY);
  const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};

const saveEncryptedDataToLocalStorage = (key, data) => {
  const encryptedData = encryptData(data);
  localStorage.setItem(key, JSON.stringify(encryptedData));
};

const getDecryptedDataFromLocalStorage = (key) => {
  const encryptedData = JSON.parse(localStorage.getItem(key));
  if (encryptedData) {
    const decryptedData = decryptData(encryptedData);
    return decryptedData;
  }
  return null;
};

export { saveEncryptedDataToLocalStorage, getDecryptedDataFromLocalStorage };
