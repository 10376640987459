import React, { useEffect, useRef, useState } from "react";
import { Button } from "./Button";
import MasterTitle from "./MasterTitle";
import { Input } from "./Input";
import Pagination from "./Pagination";
import { Api } from "../Api/Api";
import { ErrorToast } from "./toast";
import DeletePopup from "./DeletePopup";
import ActionPopup from "./ActionPopup";
import FloatNotification from "./FloatNotification";
import { PlusIcon } from "@heroicons/react/20/solid";
import Model from "./Model";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";


import { Listbox } from "@headlessui/react";
import { SortData } from "./SortData";
import Records from "./Records";
import { useDebounce } from "../hooks/use-debounce";
import {
  ChevronUpDownIcon, EyeSlashIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import NoDataFound from "./NoDataFound";
import CircularProgress from "@mui/material/CircularProgress";
import SelectBox from "./Select";
import moment from "moment";

const History = (props) => {
  const access = {
    ...(props?.access?.find((e) => e?.name === "Paper") ?? {}),
    preview: true,
  };
  const [titleData, setTitleData] = useState([
    {
      title: "PAPER ID",
      sort: 1,
      fieldName: "id",
    },
    {
      title: "PAPER TYPE",
      sort: 1,
      fieldName: "paper_type",
    },

    {
      title: "User Name",
      sort: 1,
      fieldName: "user_name",
    },
    {
      title: "MOBILE",
      sort: 1,
      fieldName: "mobile",
    },
    {
      title: "EXAM DATE",
      sort: 1,
      fieldName: "exam_date",
    },
    {
      title: "SCHOOL NAME",
      sort: 1,
      fieldName: "school_name",
    },
    {
      title: "STANDARD NAME",
      sort: 1,
      fieldName: "standard_name",
    },
    {
      title: "SUBJECT NAME",
      sort: 1,
      fieldName: "subject_name",
    },
    {
      title: "CREATED DATE AND TIME",
      sort: 1,
      fieldName: "created_data",
    },
    {
      title: "PAPER COUNT",
      sort: 1,
      fieldName: "paper_count",
    },
    {
      title: access?.preview ? "ACTION" : "",
      sort: 1,
      fieldName: "",
    },
  ]);
  const [limit1, setLimit1] = useState(1);
  const [limit2, setLimit2] = useState(1);
  const [limit3, setLimit3] = useState(1);
  const [limit4, setLimit4] = useState(1);
  const [openModel, setOpenModel] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [historyData, setHistoryData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [selectedId, setSelectedId] = useState(null);
  const [modelType, setModelType] = useState("");
  const [isModel, setIsModel] = useState(false);
  const [error, setError] = useState({});
  const [isCollapse, setIsCollapse] = useState(false);
  const cancelButtonRef = useRef(null);
  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const [search, setSearch] = useState("");
  const npage = Math.ceil(dataCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [selectedOption, setSelectedOption] = useState("");
  const [filterPeparType, setFilterPeparType] = useState("");
  const debouncedValue = useDebounce(search, 800);
  const [isLoader, setIsLoader] = useState(false);
  const [SearchSchoolName, setSearchSchoolName] = useState("");
  const [filter, setFilter] = useState({
    standard: "",
    subject: "",
    semester: "",
  });
  const handleSearchSchoolNameChange = (event) => {
    setSearchSchoolName(event.target.value);
  };
  const [isResetFilter, setIsResetFilter] = useState(false);
  const [boardOutData, setBoardOutData] = useState([]);
  const [mediumOutData, setMediumOutData] = useState([]);
  const [standardOutData, setStandardOutData] = useState([]);
  const [subjectOutData, setSubjectOutData] = useState([]);
  const [semesterOutData, setSemesterOutData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const getBoardOutData = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setBoardOutData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getMediumOutData = async () => {
    try {
      const { data } = await Api.getRequest(`api/medium?board=${filter?.board?.id || ""}`);
      const req = JSON.parse(data);
      setMediumOutData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };
  const getStandardOutData = async (l) => {
    try {
      let body = `?medium=${filter?.medium?.id || ""}&board=${filter?.board?.id || ""}`;
      const { data } = await Api.getRequest(`api/standard${body}`);
      const req = JSON.parse(data);
      setStandardOutData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSemesterOutData = async () => {
    try {
      let body = `?standard=${filter?.standard?.id}`;
      const { data } = await Api.getRequest(`api/semester${body}`);
      const req = JSON.parse(data);
      setSemesterOutData(req?.data?.rows);
    } catch (error) {
      ErrorToast(error);
    }
  };

  const getSubjectOutData = async () => {
    try {
      let body = `?standard=${filter?.standard?.id}&semester=${filter?.semester?.id}`;
      const { data } = await Api.getRequest(`api/subject${body}`);
      const req = JSON.parse(data);
      setSubjectOutData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    getBoardOutData();
  }, []);

  useEffect(() => {
    if (filter?.board) {
      getMediumOutData();
    }
  }, [filter?.board]);

  useEffect(() => {
    if (filter?.medium) {
      getStandardOutData();
    }
  }, [filter?.medium]);
  useEffect(() => {
    if (filter?.standard) {
      getSemesterOutData();
    }
  }, [filter?.standard]);
  useEffect(() => {
    if (filter?.semester) {
      getSubjectOutData();
    }
  }, [filter?.semester]);


  const handleFilter = (name, value) => {
    setFilter({
      ...filter,
      [name]: value,
    });
    if (name === "standard") {
      setFilter((oldValue) => ({
        ...oldValue,
        subject: "",
        semester: "",
      }));
      setSubjectOutData([]);
      setSemesterOutData([]);
    }
    if (name === "semester") {
      setFilter((oldValue) => ({
        ...oldValue,
        subject: "",
      }));
      setSubjectOutData([]); 
    }
  };

  const _getHistory = async (limit, offset) => {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/paper-generation/paper-list?paper_type=${filterPeparType || ""
        }&search=${search || ""}&limit=${limit || 50}&offset=${offset || 1
        }&school_name=${SearchSchoolName || ""}&standard=${filter?.standard?.id || ""
        }&subject=${filter?.subject?.id || ""}&startDate=${startDate || ""
        }&endDate=${endDate || ""}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setHistoryData(req?.data);
        setDataCount(req?.totalCount);
        setIsLoader(false);
      } else {
        ErrorToast(req?.message);
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  useEffect(() => {
    _getHistory(recordsPerPage, currentPage);
  }, [currentPage, recordsPerPage]);

  const _previewHistory = async (data) => {
    if (data?.pdf_url) {
      // Open the PDF in a new tab
      window.open(data.pdf_url, "_blank");
    }
    // try {
    //   const { data } = await Api.postRequest(`api/topics/delete/${id}`);
    //   const req = JSON.parse(data);
    //   if (req?.status === 200) {
    //     SuccessToast(req?.message);
    //     _getTopic(filterPeparType, search, recordsPerPage, currentPage);
    //   } else {
    //     ErrorToast(req?.message);
    //   }
    // } catch (error) {
    //   ErrorToast(error?.message);
    // }
  };

  const handleOptionChange = (e) => {
    const { value } = e.target;
    setSelectedOption(value);
    setFilterPeparType(value);
  };

  const applyFilter = () => {
    setCurrentPage(1);
    _getHistory(recordsPerPage, currentPage);
  };

  const resetFilter = () => {
    setIsResetFilter(true);
    setSearchSchoolName("");
    setSelectedOption("");
    setFilterPeparType("");
    setFilter({
      standard: "",
      subject: "",
    });
    setStartDate("");
    setEndDate("");
    setSubjectOutData([]);
  };

  useEffect(() => {
    if (isResetFilter) {
      _getHistory(recordsPerPage, currentPage);
      setIsResetFilter(false);
    }
  }, [isResetFilter]);

  return (
    <div className="w-full sm:px-0">
      <div className="w-full sm:px-0 flex flex-col items-start gap-x-4 mr-4">
      {!isCollapse && <EyeIcon className="w-7 h-7 text-black-700 hover:text-yellow-500 cursor-pointer" onClick={()=>setIsCollapse(!isCollapse)} />}
        {isCollapse &&<EyeSlashIcon className="w-7 h-7 text-black-700 hover:text-green-500 cursor-pointer" onClick={()=>setIsCollapse(!isCollapse)} />}

        {isCollapse && <><div className="w-full flex items-center flex-wrap sm:flex-nowrap gap-x-4">

          <div className="w-full">
            <Input
              required
              disabled={false}
              name=""
              type="text"
              className="font-regular"
              placeholder="Search school name here..."
              value={SearchSchoolName}
              onChange={handleSearchSchoolNameChange}
            />
          </div>
          <div className="w-full mt-3">
            <FormControl fullWidth>
              <Select
                size="small"
                value={selectedOption}
                labelId="select-placeholder"
                className="font-regular mb-3"
                onChange={(e) => handleOptionChange(e)}
                displayEmpty
                sx={{
                  border: "none",
                  background: "white",
                  mr: 1,
                  height: "37px",
                  borderColor: "gray",
                  color: "gray",
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #E5F1EB",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem value="" >
                  Select Paper Type
                </MenuItem>
                <MenuItem value={"manually"}>Manually</MenuItem>
                <MenuItem value={"auto"}>Auto</MenuItem>
                <MenuItem value={"random"}>Random</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="w-full mb-3">
            <SelectBox
              data={boardOutData || []}
              value={filter?.board}
              onChange={(e) => handleFilter("board", e)}
              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
              placeholder="All Board"
              type="board"
              getBoard={getBoardOutData}
            />
          </div>
          <div className="w-full mb-3">
            <SelectBox
              data={mediumOutData || []}
              value={filter?.medium}
              onChange={(e) => handleFilter("medium", e)}
              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
              placeholder="All Medium"
              type="medium"
              getMedium={getMediumOutData}
            />
          </div>
          <div className="w-full mb-3">
            <SelectBox
              data={standardOutData || []}
              value={filter?.standard}
              onChange={(e) => handleFilter("standard", e)}
              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
              placeholder="All Standard"
              type={"standard"}
              getStandard={getStandardOutData}
            />
          </div>
          <div className="w-full mb-3">
            <SelectBox
              data={semesterOutData || []}
              value={filter?.semester}
              onChange={(e) => handleFilter("semester", e)}
              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
              placeholder="All Semester"
              type={"semester"}
              getSemester={getSemesterOutData}
            />
          </div>
          <div className="w-full mb-3">
            <SelectBox
              data={subjectOutData || []}
              value={filter?.subject}
              onChange={(e) => handleFilter("subject", e)}
              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
              placeholder="All Subject"
              type={"subject"}
              getSubject={getSubjectOutData}
            />
          </div>
        </div>
          <div className="w-full flex flex-wrap sm:flex-nowrap mt-3 gap-x-4">
            <div className="w-full flex items-end">
              <label
                style={{ whiteSpace: "nowrap" }}
                className="mb-3 mr-2 text-sm font-medium text-[#252F40] font-poppins"
              >
                From Date :
              </label>
              <div className="w-full">
                <Input
                  disabled={false}
                  name=""
                  type="date"
                  className="font-regular"
                  value={startDate}
                  onChange={handleStartDateChange}
                />
              </div>
            </div>
            <div className="w-full flex items-end">
              <label
                style={{ whiteSpace: "nowrap" }}
                className="mb-3 mr-2 text-sm font-medium text-[#252F40] font-poppins"
              >
                To Date :
              </label>
              <div className="w-full">
                <Input
                  disabled={false}
                  name=""
                  type="date"
                  className="font-regular"
                  placeholder="To Date"
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </div>
            </div>
            <div className="">
              <Button
                onClick={() => applyFilter()}
                id=""
                type="button"
                label={"SEARCH"}
              />
            </div>
            <div className="">
              <Button
                onClick={() => resetFilter()}
                id=""
                type="button"
                label={"RESET"}
              />
            </div>
          </div></>}


      </div>
      <div className="flex justify-between items-center mt-2">
        {/* <div>
          <MasterTitle
            title="Paper History"
            description="Below are the list of all the paper history."
          />
        </div> */}
      </div>
      <div className="sm:mb-0 overflow-hidden">
        <div className="flex flex-col mt-1">
          <section
            className="bg-white rounded-2xl flex flex-col shadow-sm "
            style={{
              width: "100%",
              height: isCollapse ? "75vh" : "90vh",
              margin: "0 auto",
              padding: "5px",
            }}
          >
            {" "}
            <div className="flex justify-between items-center px-8 mb-3">
              <div style={{ display: "flex", gap: '5px' }}>
                <Input
                  id=""
                  name="Search"
                  type="text"
                  placeholder="Search here..."
                  autoComplete="given-name"
                  required
                  className="w-72"
                  onChange={(e) => {
                    setSearch(e?.target?.value);
                    setCurrentPage(1);
                  }}
                  value={search}
                />
                <Button
                  onClick={() => applyFilter()}
                  id=""
                  type="button"
                  // label={"SEARCH"}
                  searchicon={true}
                />
              </div>
              <div>
                <Records
                  recordsPerPage={recordsPerPage}
                  setCurrentPage={setCurrentPage}
                  setRecordsPerPage={setRecordsPerPage}
                />
              </div>
            </div>
            <div
              style={{
                overflowY: "auto",
                maxHeight: !isCollapse ? '80vh' : "95vh",
                height: !isCollapse ? '80vh' : "95vh",
                width: "100%",
              }}
            >
              {" "}
              {!isLoader && historyData?.length <= 0 ? (
                <NoDataFound />
              ) : (
                <table style={{ width: "100%" }}>
                  <thead
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#f4f7f8",
                      zIndex: 1,
                    }}
                  >
                    <tr
                      style={{
                        borderBottom: "1px solid #dddddd",
                        textAlign: "left",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#7B809A",
                        textTransform: "uppercase",
                        backgroundColor: "#f4f7f8",
                      }}
                    >
                      {titleData.map(
                        (v, ind) =>
                          v?.title && (
                            <th 
                            key={ind} 
                            // className="py-4 p-8 "
                            style={{
                              padding: "12px",
                              backgroundColor: "#f4f7f8",
                              position: 
                              v.title === "ACTION" || v.title === "PAPER COUNT" 
                              ? "sticky" : "",
                              right: 
                              v.title === "PAPER COUNT" 
                              ? "68px" 
                              : v.title === "ACTION"
                              ? "0px" :'',
                            }}>
                              <div className="flex justify-between">
                                <span>{v.title}</span>
                                {v.title !== "ACTION" && v.title !== "PAPER COUNT" && (
                                  <span>
                                    <ChevronUpDownIcon
                                      onClick={() => {
                                        let res = SortData(
                                          historyData,
                                          v.fieldName,
                                          v.sort
                                        );

                                        let copyData = [...titleData];
                                        copyData[ind].sort =
                                          v.sort > 0 ? -1 : 1;
                                        setTitleData(copyData);
                                        setHistoryData(res);
                                      }}
                                      className="w-5 h-5"
                                    />
                                  </span>
                                )}
                              </div>
                            </th>
                          )
                      )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {isLoader ? (
                      <tr style={{ height: "25rem" }}>
                        <td colSpan={10} style={{ textAlign: "center" }}>
                          <CircularProgress />
                        </td>
                      </tr>
                    ) : (
                      historyData.map((data, id) => (
                        <tr
                          key={id}
                          className="my-4 p-8 text-[#000] font-bold text-sm"
                          style={{
                            backgroundColor:
                              data?.isSubscriptionActive
                                ? "#8cfb8c"
                                : "#fb6868",
                          }}
                        >
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.id}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.paper_type}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.user?.userInfo?.first_name}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.user?.mobile}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.exam_date}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.school_name}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.standard_name}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.subject_name}
                          </td>
                          <td className="hidden lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                            {data.createdAt !== null
                              ? moment(data.createdAt).format(
                                "D MMMM, YYYY | hh:mm A"
                              )
                              : "-"}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 "
                           style={{
                            position: "sticky",
                            right: "68px",
                            backgroundColor: "white",
                          }}>
                            {data?.paperCount}
                          </td>
                          {access?.preview && (
                            <td className="relative whitespace-nowrap  p-8 py-2.5  text-left"
                            style={{
                              position: "sticky",
                              right: "0",
                              zIndex: 1,
                              backgroundColor: "white",
                            }}>
                              <ActionPopup
                                onPreview={() => {
                                  _previewHistory(data);
                                }}
                                accessPreview={access?.preview}
                              />
                            </td>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              recordsPerPage={recordsPerPage}
              data={historyData}
              numbers={numbers}
              npage={npage}
              lastIndex={lastIndex}
              firstIndex={firstIndex}
              dataCount={dataCount}
            />
          </section>
        </div>
        {/* Delete */}

        <DeletePopup
          title={"Delete"}
          show={openModel}
          description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
          onCancel={() => {
            setOpenModel(false);
          }}
          onClose={() => {
            setOpenModel(false);
          }}
          onDelete={() => {
            setOpenModel(false);
            setShowFloatNotification(true);
          }}
          cancelButtonRef={cancelButtonRef}
          okText="Delete"
          cancelText="Cancel"
        />

        <FloatNotification
          show={showFloatNotification}
          title={"Successfully deleted!"}
          type="success"
          description={"Data Deleted."}
          onClick={() => {
            setShowFloatNotification(false);
          }}
        />
      </div>
    </div>
  );
};

export default History;
