import React, { useState, useEffect } from "react";
import Breadcrumbs from "./Breadcrumbs";
import { Input } from "./Input";
import { ArrowSmallRightIcon, UserIcon } from "@heroicons/react/20/solid";
import { Link, useNavigate } from "react-router-dom";
import MiniRagisterUser from "./MiniRagisterUser";
import MiniPaperGeneration from "./MiniPaperGeneration";
import MiniOrderHistory from "./MiniOrderHistory";
import DashboardChart from "./DashboardChart";
import PieChart from "./PieChart";
import Select from "./Select";
import { Api } from "../Api/Api";
import { ErrorToast, WarningToast } from "./toast";
import { Box, CircularProgress, Menu, Typography } from "@mui/material";
import DashboardAmout from "./DashboardAmoutChart";
import DateEditCalendar from "./DateEditCalendar";
import Calander from "../Image/Calander.svg";
import moment from "moment";
import { setWeek } from "date-fns";
import { useDebounce } from "../hooks/use-debounce";
import MasterTitle from "./MasterTitle";

const Dashboard = () => {
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [anchorEl, setAnchorEl] = useState();
  const [anchorElEnd, setAnchorElEnd] = useState();
  const open = Boolean(anchorEl);
  const openEnd = Boolean(anchorElEnd);
  const [AllCount, setAllCount] = useState();
  const [graphData, setGraphData] = useState();
  const [lineChartData, setLineChartData] = useState();
  const [all, setAll] = useState(true);
  const [thisWeek, setThisWeek] = useState(false);
  const [thisMonth, setThisMonth] = useState(false);
  const [thisYear, setThisYear] = useState(false);
  const debouncedStartDate = useDebounce(startDate, 800);
  const debouncedEndDate = useDebounce(endDate, 800);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingChart, setLoadingChart] = useState(false);
  const [data, setData] = useState([
    {
      id: 1,
      name: "All",
      selected: "true",
    },
    {
      id: 2,
      name: "This Week",
      selected: "false",
    },
    {
      id: 3,
      name: "This Month",
      selected: "false",
    },
    {
      id: 4,
      name: "This Year",
      selected: "false",
    },
  ]);
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if needed
    return `${year}-${month}-${day}`;
  };

  const _getAllCount = async (limit, offset) => {
    try {
      const body = `?startDate=${startDate}&endDate=${endDate}&All=${All}&thisweek=${week}&thisMonth=${month}&thisYear=${year}`;
      const { data } = await Api.getRequest(`api/user/getStatistics${body}`);
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setAllCount(req?.data);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const oneMonthAgoDate = new Date();
    oneMonthAgoDate.setMonth(oneMonthAgoDate.getMonth() - 1);

    // setStartDate(formatDate(oneMonthAgoDate));
    // setEndDate(formatDate(currentDate));
    setSelectedOptions({
      id: 1,
      name: "All",
      selected: "true",
    });
  }, []);

  const handleSelectChange = (name, value) => {
    let startDateValue = "";
    let endDateValue = "";

    const today = moment();
    const currentYear = today.year();
    const currentMonth = today.month();
    const currentDay = today.date();

    switch (value?.name) {
      case "This Week":
        const startOfWeek = moment().startOf("week");
        startDateValue = startOfWeek.format("YYYY-MM-DD");
        endDateValue = today.format("YYYY-MM-DD");
        break;

      case "This Month":
        const firstDayOfMonth = moment().startOf("month");
        const lastDayOfMonth = moment().endOf("month");
        startDateValue =
          currentDay === lastDayOfMonth.date()
            ? moment().startOf("month").add(1, "days").format("YYYY-MM-DD")
            : firstDayOfMonth.format("YYYY-MM-DD");
        endDateValue = today.format("YYYY-MM-DD");
        break;

      case "This Year":
        const firstDayOfYear = moment().startOf("year");
        const lastDayOfYear = moment().endOf("year");
        startDateValue =
          currentMonth === 11 && currentDay === 31
            ? moment().startOf("year").add(1, "year").format("YYYY-MM-DD")
            : firstDayOfYear.format("YYYY-MM-DD");
        endDateValue = today.format("YYYY-MM-DD");
        break;

      default:
        break;
    }

    const updatedData = data.map((option) => ({
      ...option,
      selected: option.name === value?.name ? "true" : "false",
    }));

    const selectedOption = updatedData.find(
      (option) => option.selected === "true"
    );

    setSelectedOptions(value);
    setData(updatedData);
    setStartDate(startDateValue);
    setEndDate(endDateValue);
  };

  useEffect(() => {
    if (selectedOptions?.name === "All") {
      setAll(true);
      setThisWeek(false);
      setThisMonth(false);
      setThisYear(false);
    } else if (selectedOptions?.name === "This Week") {
      setAll(false);
      setThisWeek(true);
      setThisMonth(false);
      setThisYear(false);
    } else if (selectedOptions?.name === "This Month") {
      setAll(false);
      setThisWeek(false);
      setThisMonth(true);
      setThisYear(false);
    } else if (selectedOptions?.name === "This Year") {
      setAll(false);
      setThisWeek(false);
      setThisMonth(false);
      setThisYear(true);
    }
  }, [selectedOptions, data]);

  const All = all;
  const week = thisWeek;
  const month = thisMonth;
  const year = thisYear;

  const _getGraphData = async () => {
    try {
      setLoading(true);
      const body = `?startDate=${startDate}&endDate=${endDate}&All=${All}&thisweek=${week}&thisMonth=${month}&thisYear=${year}`;
      const { data } = await Api.getRequest(
        `api/user/getGraphDetailsNew${body}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setGraphData(req?.data);
        setLoading(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };
  const _getLineChartData = async () => {
    try {
      setLoadingChart(true);
      const body = `?startDate=${startDate}&endDate=${endDate}&All=${All}&thisweek=${week}&thisMonth=${month}&thisYear=${year}`;
      const { data } = await Api.getRequest(
        `api/user/getAmountStatistics${body}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setLineChartData(req?.data);
        setLoadingChart(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    _getGraphData();
    _getLineChartData();
    _getAllCount();
  }, [all, thisWeek, thisMonth, thisYear]);

  // useEffect(() => {
  //   _getAllCount();
  // }, [startDate, endDate, all, thisWeek, thisMonth, thisYear]);

  const transformGraphData = (graphData) => {
    if (!graphData) {
      console.error("Graph data is undefined or null");
      return { categories: [], series: [] }; // Return empty arrays to avoid further errors
    }

    const years = Object.keys(graphData?.userCounts || {});

    // Create categories for the chart
    const categories = years.map((year) => year);

    const series = [
      {
        name: "Paper Generate",
        data: years.map((year) => graphData.paperGenerationCounts?.[year] || 0),
      },
      {
        name: "User",
        data: years.map((year) => graphData.userCounts?.[year] || 0),
      },
      {
        name: "Shop",
        data: years.map((year) => graphData.bookOrderCounts?.[year] || 0),
      },
      {
        name: "Subscriber",
        data: years.map((year) => graphData.subscriptionCounts?.[year] || 0),
      },
    ];

    return { categories, series };
  };

  const { categories, series } = transformGraphData(graphData);

  const transformGraphData2 = (graphData) => {
    if (!graphData) {
      console.error("Graph data is undefined or null");
      return { categories: [], series: [] }; // Return empty arrays to avoid further errors
    }

    const years = Object.keys(graphData?.userCounts || {});

    // Create categories for the chart
    const categories = years.map((year) => year);

    const series = [
      {
        name: "Paper Generate",
        data: years.map((year) => graphData.paperGenerationCounts?.[year] || 0),
      },
      {
        name: "User",
        data: years.map((year) => graphData.userCounts?.[year] || 0),
      },
    ];

    return { categories, series };
  };

  const subscriptionCount = AllCount?.subscriptionPriceCount || 0;
  const bookOrderCount = AllCount?.bookOrderPriceCount || 0;

  const autoPaperGeneration = AllCount?.autoPaperGenerationCount || 0;
  const manualPaperGeneration = AllCount?.manualPaperGenerationCount || 0;
  const randomPaperGeneration = AllCount?.randomPaperGenerationCount || 0;

  const categoriesData = Object.keys(
    lineChartData?.bookOrderAmountCounts || ""
  );

  const handleClick = (event) => {
    setAnchorElEnd(null);
    setAnchorEl(event.currentTarget);
  };

  const handleClickEnd = (event) => {
    setAnchorEl(null);
    setAnchorElEnd(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElEnd(null);
    setAnchorEl(null);
  };

  const handleCloseEnd = () => {
    setAnchorElEnd(null);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (startDate && endDate) {
      const startDateMoment = moment(startDate);
      const endDateMoment = moment(endDate);
      if (endDateMoment.isSameOrAfter(startDateMoment)) {
        _getGraphData();
        _getLineChartData();
        _getAllCount();
      } else {
        WarningToast("End date is not greater than the start date");
        setEndDate();
      }
    }
  }, [startDate, endDate]);

  const handleSaveDateStart = (value) => {
    if (endDate) {
      const maxEndDate = moment(endDate).subtract(3, "months");
      if (moment(value).isAfter(maxEndDate)) {
        const formattedStartDate = moment(value).format("YYYY-MM-DD");
        setStartDate(formattedStartDate);
      } else {
        WarningToast(
          "Start date must be within three months before the end date."
        );
      }
    } else {
      const formattedStartDate = moment(value).format("YYYY-MM-DD");
      setStartDate(formattedStartDate);
    }
  };

  const handleSaveDateEnd = (value) => {
    if (startDate) {
      const maxEndDate = moment(startDate).add(3, "months");
      if (moment(value).isBefore(maxEndDate)) {
        const formattedEndDate = moment(value, "YYYY-MM-DD").format(
          "YYYY-MM-DD"
        );
        setEndDate(formattedEndDate);
      } else {
        WarningToast("End date must be within three months of the start date.");
      }
    } else {
      WarningToast("End date cannot be before start date");
    }
  };

  return (
    <>
      <div className="w-full sm:px-6 overflow-x-hidden overflow-y-auto">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <MasterTitle
              title="Dashboard"
              description="Below are all the details of dashboard."
            />
            {/* <Breadcrumbs title="/ Dashboard" /> */}
          </div>

          <div>
            <div className="flex items-center justify-end lg:space-x-2 gap-2">
              {" "}
              {/* <Input type="date" className="w-56" placeholder="Select Date" /> */}
              <div className="w-full py-6 flex items-center">
                <Typography
                  sx={{ pr: 1, pl: 1, color: "#000000", fontWeight: "600" }}
                >
                  From
                </Typography>
                <Box
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={(event) => handleClick(event)}
                  sx={{
                    background: "#FFFFFF",
                    borderRadius: "8px",
                    width: "9rem",
                    padding: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                >
                  <Typography sx={{ color: "#000000", fontSize: "14px" }}>
                    {startDate || "YYYY-MM-DD"}
                  </Typography>
                  <img src={Calander} width="20" height="20" />
                </Box>
                <Typography
                  sx={{ pr: 1, pl: 1, color: "#000000", fontWeight: "600" }}
                >
                  To
                </Typography>
                <Box
                  aria-controls={openEnd ? "basic-menu-end" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openEnd ? "true" : undefined}
                  onClick={(event) => handleClickEnd(event)}
                  sx={{
                    background: "#FFFFFF",
                    borderRadius: "8px",
                    width: "9rem",
                    padding: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                >
                  <Typography sx={{ color: "#000000", fontSize: "14px" }}>
                    {endDate || "YYYY-MM-DD"}
                  </Typography>
                  <img src={Calander} width="20" height="20" />
                </Box>
                {open && (
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <DateEditCalendar
                      handleCloseCalendar={handleClose}
                      saveTheDate={handleSaveDateStart}
                      date={startDate}
                    />
                  </Menu>
                )}
                {openEnd && (
                  <Menu
                    id="basic-menu-end"
                    anchorEl={anchorElEnd}
                    open={openEnd}
                    onClose={handleCloseEnd}
                  >
                    <DateEditCalendar
                      handleCloseCalendar={handleCloseEnd}
                      saveTheDate={handleSaveDateEnd}
                    />
                  </Menu>
                )}
              </div>
              <div className="w-full">
                <Select
                  id=""
                  value={selectedOptions}
                  name="Search"
                  type="thisYear"
                  placeholder="This month"
                  autoComplete="given-name"
                  data={data}
                  // getFullYear={getYear}
                  onChange={(e) => handleSelectChange("thisYear", e)}
                  className="mb-2 font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3] w-full pr-24"
                  isthisYear={true}
                />
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="grid grid-cols-1 lg:grid-cols-6 gap-2">
          <div
            style={{
              // minHeight: "20vh",
              background: "#fff",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              borderRadius: "0.375rem",
              padding: "1rem",
            }}
          >
            <div className="flex items-center">
              <div className="h-10 w-10 flex-shrink-0">
                <div className="h-10 w-10 rounded-full bg-gradient-to-b from-[#3E3D45] to-[#202020]  flex items-center justify-center text-xl font-medium ">
                  <UserIcon className="text-white w-6 h-6" />
                </div>
              </div>
              <div className="ml-2">
                <div className="font-regular text-[#7B809A] text-left text-sm">
                  Users
                </div>
                <div className="text-[#344767] font-semibold text-sm">
                  {AllCount?.userCount}
                </div>
              </div>
            </div>
            <div className="sm:flex sm:items-center justify-between mt-3">
              <div className="">
                <Link
                  to="/paper-generation/user"
                  className="text-sm font-regular text-[#212121] flex items-center"
                >
                  View All
                  <span>
                    <ArrowSmallRightIcon className="w-6 h-6 ml-1" />
                  </span>
                </Link>
              </div>
              <div className="text-right ">
                <h1 className="text-[#4CAF50] font-bold text-sm">
                  +{(AllCount?.growth?.userGrowth || 0)?.toFixed(2)}%
                </h1>
                <p className="block text-[#7B809A] font-regular text-sm">
                  this month
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              // minHeight: "20vh",
              background: "#fff",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              borderRadius: "0.375rem",
              padding: "1rem",
            }}
          >
            <div className="flex items-center">
              <div className="h-10 w-10 flex-shrink-0">
                <div className="h-10 w-10 rounded-full bg-gradient-to-b from-[#E83976] to-[#DA1F63]  flex items-center justify-center text-xl font-medium ">
                  <UserIcon className="text-white w-6 h-6" />
                </div>
              </div>
              <div className="ml-2">
                <div className="font-regular text-[#7B809A] text-left text-sm">
                  Student
                </div>
                <div className="text-[#344767] font-semibold text-sm">
                  {AllCount?.studentCount}
                </div>
              </div>
            </div>
            <div className="sm:flex sm:items-center justify-between mt-3">
              <div
                className=""
                onClick={() => {
                  navigate("/paper-generation/user", {
                    state: { UserType: "student" },
                  });
                }}
              >
                <Link className="text-sm font-regular text-[#DA1F63] flex items-center">
                  View All
                  <span>
                    <ArrowSmallRightIcon className="w-6 h-6 ml-1" />
                  </span>
                </Link>
              </div>
              <div className="text-right ">
                <h1 className="text-[#4CAF50] font-bold text-sm">
                  +{(AllCount?.growth?.studentGrowth || 0)?.toFixed(2)}%
                </h1>
                <p className="block text-[#7B809A] font-regular text-sm">
                  this month
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              // minHeight: "20vh",
              background: "#fff",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              borderRadius: "0.375rem",
              padding: "1rem",
              // marginTop: "1.5rem",
              // marginLeft: "0rem",
              // marginRight: "0rem",
            }}
          >
            <div
              // style={{ minHeight: "8vh" }}
              className="flex items-center"
            >
              <div className="h-10 w-10 flex-shrink-0">
                <div className="h-10 w-10 rounded-full bg-gradient-to-b from-[#62B966] to-[#4BA64F]  flex items-center justify-center text-xl font-medium ">
                  <UserIcon className="text-white w-6 h-6" />
                </div>
              </div>
              <div className="ml-2">
                <div className="font-regular text-[#7B809A] text-left text-sm">
                  Teacher
                </div>
                <div className="text-[#344767] font-semibold text-sm">
                  {AllCount?.teacherCount}
                </div>
              </div>
            </div>
            <div className="sm:flex sm:items-center justify-between mt-3">
              <div
                className=""
                onClick={() => {
                  navigate("/paper-generation/user", {
                    state: { UserType: "teacher" },
                  });
                }}
              >
                <Link className="text-sm font-regular text-[#4CA750] flex items-center">
                  View All
                  <span>
                    <ArrowSmallRightIcon className="w-6 h-6 ml-1" />
                  </span>
                </Link>
              </div>
              <div className="text-right ">
                <h1 className="text-[#4CAF50] font-bold text-sm">
                  +{(AllCount?.growth?.teacherGrowth || 0)?.toFixed(2)}%
                </h1>
                <p className="block text-[#7B809A] font-regular text-sm">
                  this month
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              // minHeight: "20vh",
              background: "#fff",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              borderRadius: "0.375rem",
              padding: "1rem",
              // marginTop: "1.5rem",
              // marginLeft: "0rem",
              // marginRight: "0rem",
            }}
          >
            <div
              // style={{ minHeight: "8vh" }}
              className="flex items-center"
            >
              <div className="h-10 w-10 flex-shrink-0">
                <div className="h-10 w-10 rounded-full bg-gradient-to-b from-[#429CEE] to-[#1F79E9]  flex items-center justify-center text-xl font-medium ">
                  <UserIcon className="text-white w-6 h-6" />
                </div>
              </div>
              <div className="ml-2">
                <div className="font-regular text-[#7B809A] text-left text-sm">
                  Shop
                </div>
                <div className="text-[#344767] font-semibold text-sm">
                  {AllCount?.bookOrderCount}
                </div>
              </div>
            </div>
            <div className="sm:flex sm:items-center justify-between mt-3">
              <div className="">
                <Link
                  to="/shop/books"
                  className="text-sm font-regular text-[#207AE9] flex items-center"
                >
                  View All
                  <span>
                    <ArrowSmallRightIcon className="w-6 h-6 ml-1" />
                  </span>
                </Link>
              </div>
              <div className="text-right ">
                <h1 className="text-[#4CAF50] font-bold text-sm">
                  +{(AllCount?.growth?.bookOrderGrowth || 0)?.toFixed(2)}%
                </h1>
                <p className="block text-[#7B809A] font-regular text-sm">
                  this month
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              // minHeight: "20vh",
              background: "#fff",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              borderRadius: "0.375rem",
              padding: "1rem",
              // marginTop: "1.5rem",
              // marginLeft: "0rem",
              // marginRight: "0rem",
            }}
          >
            <div
              // style={{ minHeight: "8vh" }}
              className="flex items-center"
            >
              <div className="h-10 w-10 flex-shrink-0">
                <div className="h-10 w-10 rounded-full bg-gradient-to-b from-[#885BDA] to-[#885BDA]  flex items-center justify-center text-xl font-medium ">
                  <UserIcon className="text-white w-6 h-6" />
                </div>
              </div>
              <div className="ml-2">
                <div className="font-regular text-[#7B809A] text-left text-sm">
                  Subscription
                </div>
                <div className="text-[#344767] font-semibold text-sm">
                  {AllCount?.subscriptionCount}
                </div>
              </div>
            </div>
            <div className="sm:flex sm:items-center justify-between mt-3">
              <div className="">
                <Link
                  to="/paper-generation/order-history"
                  className="text-sm font-regular text-[#885BDA] flex items-center"
                >
                  View All
                  <span>
                    <ArrowSmallRightIcon className="w-6 h-6 ml-1" />
                  </span>
                </Link>
              </div>
              <div className="text-right ">
                <h1 className="text-[#4CAF50] font-bold text-sm">
                  +{(AllCount?.growth?.subscriptionGrowth || 0)?.toFixed(2)}%
                </h1>
                <p className="block text-[#7B809A] font-regular text-sm">
                  this month
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              background: "#fff",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              borderRadius: "0.375rem",
              padding: "1rem",
            }}
          >
            <div className="flex items-center">
              <div className="h-10 w-10 flex-shrink-0">
                <div className="h-10 w-10 rounded-full bg-gradient-to-b from-[#f49fd2] to-[#f49fd2]  flex items-center justify-center text-xl font-medium ">
                  <UserIcon className="text-white w-6 h-6" />
                </div>
              </div>
              <div className="ml-2">
                <div className="font-regular text-[#7B809A] text-left text-sm">
                  Paper Generation
                </div>
                <div className="text-[#344767] font-semibold text-sm">
                  {AllCount?.paperGenerationCount}
                </div>
              </div>
            </div>
            <div className="sm:flex sm:items-center justify-between mt-3">
              <div className="">
                <Link
                  to="/paper-generation/paper-history"
                  className="text-sm font-regular text-[#f49fd2] flex items-center"
                >
                  View All
                  <span>
                    <ArrowSmallRightIcon className="w-6 h-6 ml-1" />
                  </span>
                </Link>
              </div>
              <div className="text-right ">
                <h1 className="text-[#4CAF50] font-bold text-sm">
                  +{(AllCount?.growth?.paperGenerationGrowth || 0)?.toFixed(2)}%
                </h1>
                <p className="block text-[#7B809A] font-regular text-sm">
                  this month
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:space-x-6">
          <div
            className="flex flex-col w-full lg:w-1/2 mt-3 shadow-sm rounded-md overflow-hidden bg-white p-4"
            style={{ minHeight: "40vh" }}
          >
            {loadingChart ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "38vh",
                }}
              >
                {" "}
                <CircularProgress />
              </div>
            ) : (
              <>
                <DashboardAmout
                  categories={categoriesData}
                  bookOrderAmountCounts={lineChartData?.bookOrderAmountCounts}
                  subscriptionAmountCounts={
                    lineChartData?.subscriptionAmountCounts
                  }
                />
              </>
            )}
          </div>
          <div
            className="flex flex-col w-full lg:w-1/2 mt-3 shadow-sm rounded-md overflow-hidden bg-white p-4"
            style={{ minHeight: "40vh" }}
          >
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "38vh",
                }}
              >
                {" "}
                <CircularProgress />
              </div>
            ) : (
              <DashboardChart categories={categories} series={series} />
            )}
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:space-x-6">
          <div className="flex flex-col w-full lg:w-1/2 mt-6 shadow-sm rounded-md overflow-hidden bg-white p-4">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h1 className="text-[#344767] font-semibold text-base font-poppins">
                  Registered User
                </h1>
                <p className="text-[#7B809A] font-regular text-sm mt-1 font-poppins">
                  Below are the list of all the Registered User.
                </p>
              </div>

              <button
                type="button"
                onClick={() => navigate("/paper-generation/user")}
                className="w-auto flex items-center justify-center rounded-md bg-[#F3F6F8]  px-4 py-2 text-sm font-medium text-black shadow-sm whitespace-nowrap"
              >
                View All
              </button>
            </div>
            <MiniRagisterUser />
          </div>
          <div className="flex flex-col w-full lg:w-1/2 mt-6 shadow-sm rounded-md overflow-hidden bg-white p-4">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h1 className="text-[#344767] font-semibold text-base font-poppins">
                  Paper History
                </h1>
                <p className="text-[#7B809A] font-regular text-sm mt-1 font-poppins">
                  Below are the list of all the paper history.
                </p>
              </div>
              <button
                type="button"
                onClick={() => navigate("/paper-generation/paper-history")}
                className="w-auto flex items-center justify-center rounded-md bg-[#F3F6F8]  px-4 py-2 text-sm font-medium text-black shadow-sm whitespace-nowrap"
              >
                View All
              </button>
            </div>
            <MiniPaperGeneration />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:space-x-6">
          <div className="w-full lg:w-1/2 mt-6 shadow-sm rounded-md overflow-hidden bg-white p-4 ">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h1 className="text-[#344767] font-semibold text-base font-poppins">
                  Order History
                </h1>
                <p className="text-[#7B809A] font-regular text-sm mt-1 font-poppins">
                  Below are the list of all the order history.
                </p>
              </div>
              <button
                type="button"
                onClick={() => navigate("/paper-generation/order-history")}
                className="w-auto flex items-center justify-center rounded-md bg-[#F3F6F8]  px-4 py-2 text-sm font-medium text-black shadow-sm whitespace-nowrap"
              >
                View All
              </button>
            </div>
            <div>
              <MiniOrderHistory />
            </div>
          </div>
          <div className="outer-container flex flex-wrap w-full lg:w-1/1 mt-6 gap-3">
            <div
              className="flex flex-col w-full shadow-sm rounded-md overflow-hidden bg-white p-4"
              style={{ width: "55%" }}
            >
              <div className=" mb-4">
                <p className="text-lg font-semibold text-center">Paper</p>
              </div>
              <PieChart
                AllCount={AllCount}
                seriesData={[
                  autoPaperGeneration,
                  manualPaperGeneration,
                  randomPaperGeneration,
                ]}
                paper
              />
            </div>
            <div
              className="flex flex-col w-full shadow-sm rounded-md overflow-hidden bg-white p-4"
              style={{ width: "43%" }}
            >
              <div className=" mb-4">
                <p className="text-lg font-semibold text-center">App</p>
              </div>
              <PieChart
                AllCount={AllCount}
                seriesData={[subscriptionCount, bookOrderCount]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
