import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";

const MultiSelectAutocomplete = ({
  data,
  value,
  onChange,
  getOptionLabel,
  label,
}) => {
  return (
    <Autocomplete
      id="tags-filled"
      size="small"
      multiple
      disableCloseOnSelect
      options={data}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextField {...params} placeholder="Select Standards" />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <Checkbox checked={value.includes(option)} />
          {getOptionLabel(option)}
        </li>
      )}
    />
  );
};

export default MultiSelectAutocomplete;
