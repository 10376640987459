import {
  ChevronUpDownIcon,
  EyeIcon,
  EyeSlashIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useRef, useState } from "react";
import { SortData } from "./SortData";
import ActionPopup from "./ActionPopup";
import Pagination from "./Pagination";
import DeletePopup from "./DeletePopup";
import FloatNotification from "./FloatNotification";
import { useNavigate } from "react-router-dom";
import MasterTitle from "./MasterTitle";
import Model from "./Model";
import { Button } from "./Button";
import { Input } from "./Input";
import { Checkbox } from "./Checkbox";
import { Grid, Typography } from "@mui/material";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast } from "./toast";
import NoDataFound from "./NoDataFound";
import CircularProgress from "@mui/material/CircularProgress";
import ActionButton from "./ActionButton";
import { TrashIcon } from "@heroicons/react/20/solid";
import Records from "./Records";
import { useDebounce } from "../hooks/use-debounce";
import { getDecryptedDataFromLocalStorage } from "../Api/encryption";

const access = [
  {
    name: "Dashboard",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Master",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Promocode",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Topic",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Question",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Approve Question",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Advertisement",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Notification",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Shop",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Paper",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Report",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Access",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  // {
  //   name: "Product",
  //   view: false,
  //   add: false,
  //   edit: false,
  //   delete: false,
  // },
  // {
  //   name: "Book History",
  //   view: false,
  //   add: false,
  //   edit: false,
  //   delete: false,
  // },
  {
    name: "Student App",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  {
    name: "Standard App",
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  // {
  //   name: "Register User",
  //   view: false,
  //   add: false,
  //   edit: false,
  //   delete: false,
  // },
  // {
  //   name: "Notice",
  //   view: false,
  //   add: false,
  //   edit: false,
  //   delete: false,
  // },
  // {
  //   name: "RTO",
  //   view: false,
  //   add: false,
  //   edit: false,
  //   delete: false,
  // },
  // {
  //   name: "Semester",
  //   view: false,
  //   add: false,
  //   edit: false,
  //   delete: false,
  // },
  // {
  //   name: "Equation",
  //   view: false,
  //   add: false,
  //   edit: false,
  //   delete: false,
  // },
  // {
  //   name: "Maths",
  //   view: false,
  //   add: false,
  //   edit: false,
  //   delete: false,
  // },
  // {
  //   name: "Subscription Price",
  //   view: false,
  //   add: false,
  //   edit: false,
  //   delete: false,
  // },
];

const Admin = (props) => {
  const accessRole = props?.access?.filter((e) => e?.name === "Access")?.[0];
  let adminUserData = getDecryptedDataFromLocalStorage("userData");
  const navigate = useNavigate();
  const cancelButtonRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [SelectUserId, setSelectUserId] = useState();
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [userCount, setUserCount] = useState(0);
  const [userDataTable, setUserDataTable] = useState([]);
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const [isModel, setIsModel] = useState(false);
  const [error, setError] = useState({});
  const [isModelLoader, setisModelLoader] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const npage = Math.ceil(userCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [search, setSearch] = useState("");
  const debouncedValue = useDebounce(search, 800);
  const [titleData, setTitleData] = useState([
    {
      title: "USER NAME",
      sort: 1,
      fieldName: "username",
    },
    {
      title: "MOBILE",
      sort: 1,
      fieldName: "mobile",
    },
    {
      title: "PASSWORD",
      sort: 1,
      fieldName: "password",
    },
    {
      title: accessRole?.edit ? "EDIT" : "",
      sort: 1,
      fieldName: "",
    },
    {
      title: accessRole?.delete && adminUserData?.type === "superadmin" ? "DELETE" : "",
      sort: 1,
      fieldName: "",
    },
  ]);
  const [accessData, setAccessData] = useState(access);
  const [editData, setEditData] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [deleteType, setDeleteType] = useState("single");
  const [isLoading, setIsLoading] = useState(false);
  const togglePasswordVisibility = (id) => {
    const data = userDataTable?.map((e) => {
      return {
        ...e,
        isShow: e?.id === id ? !e?.isShow : e?.isShow,
      };
    });
    setUserDataTable(data);
  };

  const [userData, setUserData] = useState({
    userName: "",
    password: "",
    phoneNumber: "",
  });

  const handleChange = (name, value) => {
    setUserData((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
  };

  const phoneNumberRegex = /^\d{10}$/;
  const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

  const validation = () => {
    let errors = {};
    let formIsValid = true;
    if (!userData?.userName) {
      formIsValid = false;
      errors["userName"] = "Please Enter User Name.";
    }
    if (!userData?.password) {
      formIsValid = false;
      errors["password"] = "Please Enter Password.";
    } else if (!passwordRegex.test(userData?.password)) {
      formIsValid = false;
      errors["isValidPassword"] =
        "Please Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character.";
    }
    if (!userData?.phoneNumber) {
      formIsValid = false;
      errors["phoneNumber"] = "Please Enter Phone Number.";
    } else if (!phoneNumberRegex.test(userData?.phoneNumber)) {
      formIsValid = false;
      errors["isValidPhoneNumber"] = "Please Enter a 10-digit Phone Number.";
    }
    setError(errors);
    return formIsValid;
  };

  const _getUser = async (limit, offset, search) => {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/auth/getAllAdmin?limit=${limit}&offset=${offset}&search=${
          search || ""
        }`
      );
      const req = JSON.parse(data);
      if (req) {
        if (req?.status === 200) {
          const modifyData = req?.data?.map((e) => {
            return {
              ...e,
              isShow: false,
            };
          });
          setUserDataTable(modifyData);
          setUserCount(req?.totalCount);
          setIsModel(false);
          setIsLoader(false);
        } else {
          setIsLoader(false);
        }
      } else {
        ErrorToast("Something Is wrong");
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const _getUserById = async (id) => {
    try {
      setisModelLoader(true);
      const { data } = await Api.getRequest(`api/auth/getUserByID/${id || ""}`);
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setisModelLoader(false);
        setEditData(req?.data);
        setIsModel(true);
      } else {
        ErrorToast(req?.massage);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setisModelLoader(false);
    }
  };

  const _deleteUser = async () => {
    try {
      let data;
      if (deleteType === "multi") {
        const body = {
          ids: checkedItems,
        };
        const response = await Api.postRequest(`api/auth/delete`, body);
        data = response.data;
      } else {
        const body = {
          ids: [SelectUserId],
        };
        const response = await Api.postRequest(`api/auth/delete`, body);
        data = response.data;
      }
      // const { data } = await Api.postRequest(`api/auth/delete/${SelectUserId}`);
      const req = JSON.parse(data);
      if (req?.status === 200) {
        SuccessToast(req?.message);
        _getUser(recordsPerPage, currentPage);
        setOpenModel(false);
        setSelectUserId("");
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const _addUser = async () => {
    if (validation()) {
      setIsLoading(true);
      const stringArray = accessData?.map((e) => JSON.stringify(e));
      const joinedString = stringArray?.join(",");
      try {
        let body = {
          username: userData?.userName,
          password: userData?.password,
          mobile: userData?.phoneNumber,
          type: "admin",
          roll: 1,
          isAdmin: true,
          access: joinedString,
        };
        if (editData) {
          try {
            const data = await Api.putRequest(
              `api/auth/updateUserByAdmin/${editData?.id}`,
              body
            );
            if (data?.data?.status === 200) {
              SuccessToast(data?.data?.message);
              _getUser(recordsPerPage, currentPage);
              // setIsModel(false);
              setUserData({});
              setAccessData(access);
              setError({});
              setEditData(null);
              setTimeout(() => {
                setIsLoading(false);
              }, 2000);
            } else {
              setIsLoading(false);
              ErrorToast(data?.data?.message);
            }
          } catch (error) {
            setIsLoading(false);
            ErrorToast(error?.message);
          }
        } else {
          try {
            const { data } = await Api.postRequest(`api/auth/admin/register`, body);
            const req = JSON.parse(data);
            if (req?.status === 200) {
              SuccessToast(req?.message);
              _getUser(recordsPerPage, currentPage);
              setIsModel(false);
              setUserData({});
              setAccessData(access);
              setError({});
              setEditData(null);
              setTimeout(() => {
                setIsLoading(false);
              }, 2000);
            } else {
              ErrorToast(req?.message);
              setIsLoading(false);
            }
          } catch (error) {
            ErrorToast(error?.message);
          }
        }
      } catch (error) {
        ErrorToast(error?.message);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    _getUser(recordsPerPage, currentPage, search);
  }, [ recordsPerPage, currentPage]);

  const applyFilter = () => {
    _getUser(recordsPerPage, currentPage, search);
  }
  useEffect(() => {
    if (editData) {
      const jsonObjects = editData?.access?.split("},{");

      // Add missing curly braces to each JSON object
      const jsonObjectsWithBraces = jsonObjects?.map((obj) => {
        if (obj.startsWith("{")) {
          return obj + "}";
        } else if (obj.endsWith("}")) {
          return "{" + obj;
        } else {
          return "{" + obj + "}";
        }
      });

      // Parse the JSON objects and create an array
      const dataArray = jsonObjectsWithBraces?.map((obj) => JSON.parse(obj));
      setUserData({
        userName: editData?.username.trim(),
        password: editData?.password.trim(),
        phoneNumber: editData?.mobile,
      });
      if (dataArray) {
        setAccessData(dataArray);
      } else {
        setAccessData(access);
      }
    }
  }, [editData]);

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) => {
      const isChecked = prevState.includes(id);

      const updatedItems = isChecked
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id];

      return updatedItems;
    });
  };

  const [initialCheck, setInitialCheck] = useState(true); // State to track initial check

  const handleViewChange = (e, i) => {
    const isChecked = e.target.checked;
    handleAccessData("view", isChecked, i);

    // Update ADD, EDIT, DELETE checkboxes if it's not the initial check
    if (!initialCheck) {
      handleAccessData("add", isChecked, i);
      handleAccessData("edit", isChecked, i);
      handleAccessData("delete", isChecked, i);
    }
  };

  const handleAccessData = (name, value, i) => {
    const data = [...accessData];
    data[i][name] = value;
    setAccessData(data);
  };

  return (
    <div className="h-full flex items-center justify-center ">
      <div className="h-full flex-1 flex flex-col space-y-5 lg:space-y-0 lg:flex-row lg:space-x-10 w-full sm:px-2">
        <div className="flex-1 px-2 sm:px-0 mt-2 w-full">
          <div className="flex justify-between items-center">
            <div>
              <MasterTitle
                title="All Admin"
                description="Below are the list of all the admin."
              />
            </div>
            <div className="flex items-center space-x-2">
              <div>
                {" "}
                <div style={{ display: "flex", gap: "5px" }}>
                  {accessRole?.delete && (
                    <Button
                      id=""
                      type="submit"
                      icon={TrashIcon}
                      label={"DELETE ADMIN"}
                      onClick={() => {
                        setDeleteType("multi");
                        if (checkedItems.length > 0) {
                          setOpenModel(true);
                        }
                      }}
                    />
                  )}
                  {accessRole?.add && (
                    <Button
                      id=""
                      type="submit"
                      icon={PlusIcon}
                      label={"ADD ADMIN"}
                      onClick={() => setIsModel(true)}
                    />
                  )}
                </div>
                <Model
                  title={`${editData ? "Edit" : "Add"} Admin`}
                  show={isModel}
                  onCancel={() => {
                    // setIsModel(false);
                    setUserData({});
                    setAccessData([
                      {
                        name: "Dashboard",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      {
                        name: "Master",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      {
                        name: "Promocode",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      {
                        name: "Topic",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      {
                        name: "Question",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      {
                        name: "Approve Question",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      {
                        name: "Advertisement",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      {
                        name: "Notification",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      {
                        name: "Shop",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      {
                        name: "Paper",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      {
                        name: "Report",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      {
                        name: "Access",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      // {
                      //   name: "Product",
                      //   view: false,
                      //   add: false,
                      //   edit: false,
                      //   delete: false,
                      // },
                      // {
                      //   name: "Book History",
                      //   view: false,
                      //   add: false,
                      //   edit: false,
                      //   delete: false,
                      // },
                      {
                        name: "Student App",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      {
                        name: "Standard App",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      // {
                      //   name: "Register User",
                      //   view: false,
                      //   add: false,
                      //   edit: false,
                      //   delete: false,
                      // },
                      // {
                      //   name: "Notice",
                      //   view: false,
                      //   add: false,
                      //   edit: false,
                      //   delete: false,
                      // },
                      // {
                      //   name: "RTO",
                      //   view: false,
                      //   add: false,
                      //   edit: false,
                      //   delete: false,
                      // },
                      // {
                      //   name: "Semester",
                      //   view: false,
                      //   add: false,
                      //   edit: false,
                      //   delete: false,
                      // },
                      // {
                      //   name: "Equation",
                      //   view: false,
                      //   add: false,
                      //   edit: false,
                      //   delete: false,
                      // },
                      // {
                      //   name: "Maths",
                      //   view: false,
                      //   add: false,
                      //   edit: false,
                      //   delete: false,
                      // },
                      // {
                      //   name: "Subscription Price",
                      //   view: false,
                      //   add: false,
                      //   edit: false,
                      //   delete: false,
                      // },
                    ]);
                    setError({});
                    setEditData(null);
                  }}
                  onClose={() => {
                    setIsModel(false);
                    setUserData({});
                    setAccessData([
                      {
                        name: "Dashboard",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      {
                        name: "Master",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      {
                        name: "Promocode",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      {
                        name: "Topic",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      {
                        name: "Question",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      {
                        name: "Approve Question",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      {
                        name: "Advertisement",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      {
                        name: "Notification",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      {
                        name: "Shop",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      {
                        name: "Paper",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      {
                        name: "Report",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      {
                        name: "Access",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      // {
                      //   name: "Product",
                      //   view: false,
                      //   add: false,
                      //   edit: false,
                      //   delete: false,
                      // },
                      // {
                      //   name: "Book History",
                      //   view: false,
                      //   add: false,
                      //   edit: false,
                      //   delete: false,
                      // },
                      {
                        name: "Student App",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      {
                        name: "Standard App",
                        view: false,
                        add: false,
                        edit: false,
                        delete: false,
                      },
                      // {
                      //   name: "Register User",
                      //   view: false,
                      //   add: false,
                      //   edit: false,
                      //   delete: false,
                      // },
                      // {
                      //   name: "Notice",
                      //   view: false,
                      //   add: false,
                      //   edit: false,
                      //   delete: false,
                      // },
                      // {
                      //   name: "RTO",
                      //   view: false,
                      //   add: false,
                      //   edit: false,
                      //   delete: false,
                      // },
                      // {
                      //   name: "Semester",
                      //   view: false,
                      //   add: false,
                      //   edit: false,
                      //   delete: false,
                      // },
                      // {
                      //   name: "Equation",
                      //   view: false,
                      //   add: false,
                      //   edit: false,
                      //   delete: false,
                      // },
                      // {
                      //   name: "Maths",
                      //   view: false,
                      //   add: false,
                      //   edit: false,
                      //   delete: false,
                      // },
                      // {
                      //   name: "Subscription Price",
                      //   view: false,
                      //   add: false,
                      //   edit: false,
                      //   delete: false,
                      // },
                    ]);
                    setError({});
                    setEditData(null);
                  }}
                  onOk={() => _addUser()}
                  okText="SUBMIT"
                  cancelText="RESET"
                  width="max-w-3xl"
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  {isModelLoader ? (
                    <tr
                      style={{
                        height: "120px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <td>
                        <CircularProgress />
                      </td>
                    </tr>
                  ) : (
                    <>
                      <div className="flex flex-col mt-4">
                        <section className="bg-white rounded-2xl flex flex-col shadow-sm min-h-[calc(100vh-40px-16px-24px-38px-50px-40px-52px-16px)]">
                          <Grid container spacing={2} mb={3}>
                            <Grid item md={6} xs={12}>
                              <Input
                                name="userName"
                                type="text"
                                placeholder="Enter your username"
                                label="User Name"
                                required
                                onChange={(e) =>
                                  handleChange(
                                    "userName",
                                    e?.target?.value.trim()
                                  )
                                }
                                value={userData?.userName || ""}
                              />
                              <Typography variant="caption" color={"error"}>
                                {!userData?.userName ? error?.userName : ""}
                              </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <Input
                                name="password"
                                type="password"
                                placeholder="Enter your password"
                                label="Password"
                                required
                                onChange={(e) =>
                                  handleChange("password", e?.target?.value)
                                }
                                value={userData?.password || ""}
                              />
                              <Typography variant="caption" color={"error"}>
                                {!userData?.password ? error?.password : ""}
                              </Typography>
                              <Typography variant="caption" color={"error"}>
                                {!passwordRegex.test(userData?.password)
                                  ? error?.isValidPassword
                                  : ""}
                              </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <Input
                                name="phoneNumber"
                                type="number"
                                placeholder="Enter your phone  number"
                                label="Phone Number"
                                required
                                onChange={(e) =>
                                  handleChange("phoneNumber", e?.target?.value)
                                }
                                value={userData?.phoneNumber || ""}
                              />
                              <Typography variant="caption" color={"error"}>
                                {!userData?.phoneNumber
                                  ? error?.phoneNumber
                                  : ""}
                              </Typography>
                              <Typography variant="caption" color={"error"}>
                                {!phoneNumberRegex.test(userData?.phoneNumber)
                                  ? error?.isValidPhoneNumber
                                  : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                          <div className="h-[calc(100vh-40px-16px-24px-38px-50px-40px-52px-16px-42px-24px-62px-32px)] overflow-y-auto">
                            <table className="w-full">
                              <thead
                                style={{
                                  position: "sticky",
                                  top: 0,
                                  backgroundColor: "#f4f7f8",
                                  zIndex: 1,
                                }}
                              >
                                <tr
                                  style={{
                                    borderBottom: "1px solid #dddddd",
                                    textAlign: "left",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    color: "#7B809A",
                                    textTransform: "uppercase",
                                    backgroundColor: "#f4f7f8",
                                  }}
                                >
                                  <th
                                    scope="col"
                                    className="relative w-12 px-6"
                                  >
                                    Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="relative w-12  px-4"
                                  >
                                    View
                                  </th>
                                  <th
                                    scope="col"
                                    className="relative w-12  px-4"
                                  >
                                    Add
                                  </th>
                                  <th
                                    scope="col"
                                    className="relative w-12  px-4"
                                  >
                                    Edit
                                  </th>
                                  <th
                                    scope="col"
                                    className="relative w-12  px-4"
                                  >
                                    Delete
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200">
                                {accessData?.map((data, i) => (
                                  <tr
                                    key={i}
                                    className="my-3 px-6 text-[#7B809A] font-reguler text-xs"
                                  >
                                    <td className="lg:table-cell whitespace-nowrap px-6 py-2.5">
                                      {data?.name}
                                    </td>
                                    <td className="lg:table-cell whitespace-nowrap px-6 py-2.5">
                                      <Checkbox
                                        type="checkbox"
                                        checked={data?.view}
                                        onChange={(e) => handleViewChange(e, i)}
                                      />
                                    </td>
                                    <td className="lg:table-cell whitespace-nowrap px-6 py-2.5">
                                      <Checkbox
                                        type="checkbox"
                                        checked={data?.add && data?.view}
                                        onChange={(e) =>
                                          handleAccessData(
                                            "add",
                                            e.target.checked,
                                            i
                                          )
                                        }
                                        disabled={!data?.view}
                                      />
                                    </td>
                                    <td className="lg:table-cell whitespace-nowrap px-6 py-2.5">
                                      <Checkbox
                                        type="checkbox"
                                        checked={data?.edit && data?.view}
                                        onChange={(e) =>
                                          handleAccessData(
                                            "edit",
                                            e.target.checked,
                                            i
                                          )
                                        }
                                        disabled={!data?.view}
                                      />
                                    </td>
                                    <td className="lg:table-cell whitespace-nowrap px-6 py-2.5">
                                      <Checkbox
                                        type="checkbox"
                                        checked={data?.delete && data?.view}
                                        onChange={(e) =>
                                          handleAccessData(
                                            "delete",
                                            e.target.checked,
                                            i
                                          )
                                        }
                                        disabled={!data?.view}
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </section>
                      </div>
                    </>
                  )}
                </Model>
              </div>
            </div>
          </div>
          <div className="sm:mb-0 overflow-hidden">
            <div className="flex flex-col mt-2">
              <section
                className="bg-white rounded-2xl flex flex-col shadow-sm "
                style={{
                  width: "100%",
                  height: "75vh",
                  margin: "0 auto",
                  padding: "20px",
                }}
              >
                {" "}
                <div className="flex justify-between items-center px-8 mb-2">
                  <div>
                    <div  style={{ display: "flex", gap:'5px' }}>
                    <Input
                      id=""
                      // ref={inputRef}
                      name="Search"
                      type="text"
                      placeholder="Search here..."
                      autoComplete="given-name"
                      required
                      className="w-72"
                      onChange={(e) => {
                        setSearch(e?.target?.value);
                        setCurrentPage(1);
                      }}
                      value={search}
                    />
                     <Button
                          onClick={() => applyFilter()}
                          id=""
                          type="button"
                          // label={"SEARCH"}
                          searchicon={true}
                        />
                    </div>
                  </div>
                  <Records
                    setCurrentPage={setCurrentPage}
                    recordsPerPage={recordsPerPage}
                    setRecordsPerPage={setRecordsPerPage}
                    dataCount={userCount}
                  />
                </div>
                <div
                  style={{
                    overflowY: "auto",
                    maxHeight: "58vh",
                    height: "60vh",
                    width: "100%",
                  }}
                >
                  {" "}
                  {!isLoader && userDataTable?.length <= 0 ? (
                    <NoDataFound />
                  ) : (
                    <table style={{ width: "100%" }}>
                      <thead
                        style={{
                          position: "sticky",
                          top: 0,
                          backgroundColor: "#f4f7f8",
                          zIndex: 1,
                        }}
                      >
                        <tr
                          style={{
                            borderBottom: "1px solid #dddddd",
                            textAlign: "left",
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#7B809A",
                            textTransform: "uppercase",
                            backgroundColor: "#f4f7f8",
                          }}
                        >
                          {" "}
                          <th
                            scope="col"
                            style={{ width: "3%", padding: "10px" }}
                          ></th>{" "}
                          {titleData?.map(
                            (v, ind) =>
                              v?.title && (
                                <th
                                  key={ind}
                                  // className="py-2 px-6 "
                                  style={{
                                    padding: "12px",
                                    backgroundColor: "#f4f7f8",
                                    position:
                                      v.title === "EDIT" || v.title === "DELETE"
                                        ? "sticky"
                                        : "",
                                    right:
                                      v.title === "EDIT"
                                        ? "68px"
                                        : v.title === "DELETE"
                                        ? "0"
                                        : "",
                                  }}
                                >
                                  <div className="flex justify-between">
                                    <span>{v.title}</span>
                                    {v.title !== "EDIT" &&
                                      v.title !== "DELETE" && (
                                        <span>
                                          <ChevronUpDownIcon
                                            onClick={() => {
                                              let res = SortData(
                                                userDataTable,
                                                v.fieldName,
                                                v.sort
                                              );

                                              let copyData = [...titleData];
                                              copyData[ind].sort =
                                                v.sort > 0 ? -1 : 1;
                                              setTitleData(copyData);
                                              // setuserData(res);
                                            }}
                                            className="w-5 h-5"
                                          />
                                        </span>
                                      )}
                                  </div>
                                </th>
                              )
                          )}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {isLoader ? (
                          <tr style={{ height: "25rem" }}>
                            <td colSpan={6} style={{ textAlign: "center" }}>
                              <CircularProgress />
                            </td>
                          </tr>
                        ) : (
                          userDataTable?.length > 0 &&
                          userDataTable?.map((data, id) => (
                            <tr
                              key={id}
                              className="my-3 px-6 text-[#000] font-reguler text-sm font-medium"
                            >
                              {" "}
                              <td className="px-6 py-3 whitespace-no-wrap">
                                <Checkbox
                                  checked={checkedItems.includes(data.id)}
                                  onChange={() => handleCheckboxChange(data.id)}
                                />
                              </td>
                              <td className=" lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                {data.username}
                              </td>
                              <td className=" lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                {data.mobile}
                              </td>
                              <td className=" lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                <div className="flex items-center">
                                  {data.isShow
                                    ? data.password
                                    : "*".repeat(data.password?.length)}
                                  <div
                                    className="pl-3 cursor-pointer"
                                    onClick={() =>
                                      togglePasswordVisibility(data?.id)
                                    }
                                  >
                                    {data?.isShow ? (
                                      <EyeSlashIcon className="w-4 h-4" />
                                    ) : (
                                      <EyeIcon className="w-4 h-4" />
                                    )}
                                  </div>
                                </div>
                              </td>
                              {accessRole?.edit && (
                                <td
                                  className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                                  style={{
                                    position: "sticky",
                                    right: "68px",
                                    backgroundColor: "white",
                                  }}
                                >
                                  <ActionButton
                                    key={data.key}
                                    action="edit"
                                    onClick={() => _getUserById(data?.id)}
                                  />
                                </td>
                              )}
                              {accessRole?.delete && adminUserData?.type === "superadmin" && (
                                <td
                                  className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                                  style={{
                                    position: "sticky",
                                    right: "0",
                                    backgroundColor: "white",
                                  }}
                                >
                                  <ActionButton
                                    key={data.key}
                                    action="delete"
                                    onClick={() => {
                                      setDeleteType("single");
                                      setOpenModel(true);
                                      setSelectUserId(data?.id);
                                    }}
                                  />
                                </td>
                              )}
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  recordsPerPage={recordsPerPage}
                  data={userDataTable}
                  dataCount={userCount}
                  numbers={numbers}
                  npage={npage}
                  lastIndex={lastIndex}
                  firstIndex={firstIndex}
                />
              </section>
            </div>

            {/* Delete */}

            <DeletePopup
              title={"Delete"}
              show={openModel}
              description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
              onCancel={() => {
                setOpenModel(false);
                setSelectUserId("");
              }}
              onClose={() => {
                setOpenModel(false);
                setSelectUserId("");
              }}
              onDelete={() => {
                _deleteUser();
              }}
              cancelButtonRef={cancelButtonRef}
              okText="Delete"
              cancelText="Cancel"
            />
            {/* Delete */}
            <FloatNotification
              show={showFloatNotification}
              title={"Successfully deleted!"}
              type="success"
              description={"Data Deleted."}
              onClick={() => {
                setShowFloatNotification(false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
