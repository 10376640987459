import { toast } from "react-toastify";
import { QuestionActions } from "../../action-types/QuestionActionType";
import { getQuestionTypeApi } from "../requests/questionTypeRequest";
import { call, put, takeLatest } from "redux-saga/effects";
import { fetchQTypeSuccess } from "../../actions/QuestionTypeAction";

function* fetchQType() {
  // start loader here
  try {
    const qtypeData = yield call(getQuestionTypeApi);

    if (qtypeData) {
      yield put(fetchQTypeSuccess(qtypeData));

    } else {
      yield toast.error(qtypeData.message)

    }
    // handle response here and set into reducer
  } catch (error) {
    yield toast.error(error.message)
    // handle error 
  } finally {
    //stop loader here
  }
}


export default function* QuestionSaga() {
  yield takeLatest(QuestionActions.FETCH_QTYPE_REQUEST, fetchQType);
}