// CommonActionPopup.jsx

import React from "react";
import {
  PencilIcon,
  EyeIcon,
  TrashIcon,
  ArrowDownIcon,
} from "@heroicons/react/24/outline";

const ActionButton = ({ action, onClick }) => {
  return (
    <td
      className="relative whitespace-nowrap px-4 py-2.5 text-left"
      //   style={{ position: "sticky", right: "0" }}
    >
      <button type="button" onClick={onClick}>
        {action === "edit" ? (
          <PencilIcon className="w-5 h-5 text-black-700 hover:text-green-500 cursor-pointer" />
        ) : action === "view" ? (
          <EyeIcon className="w-5 h-5 text-black-700 hover:text-blue-700 cursor-pointer hover:animate-pulse" />
        ) : action === "download" ? (
          <ArrowDownIcon className="w-5 h-5 text-black-700 hover:text-yellow-500 cursor-pointer hover:animate-bounce" />
        ) : (
          <TrashIcon className="w-5 h-5 text-black-700 cursor-pointer hover:text-red-500 hover:animate-ping" />
        )}
      </button>
    </td>
  );
};

export default ActionButton;
