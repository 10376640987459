import { TopicActions } from "../action-types/topicActionType";

const initialState = {
    topic: [],
};

export default function topicReducer(state = initialState, action) {
  switch (action.type) {
    case TopicActions.GET_TOPIC_DATA:
      return {
        ...state,
      };
    case TopicActions.SET_TOPIC_DATA:
      return {
        ...state,
        topic: action.payload,
      };
    default:
      return state;
  }
}
