export function Checkbox({ ...props }) {
  return (
    <div className={`relative flex items-start ${props.className}`}>
      <div className="flex items-center h-5">
        <input
          {...props}
          id={props.label}
          aria-describedby={props.label}
          name={props.label}
          type="checkbox"
          className="h-4 w-4 focus:ring-0 border-gray-300 rounded"
        />
      </div>
      <div className={`ml-3 text-sm ${props.className}`}>
        <label htmlFor={props.label} className="">
          {props.label}
        </label>
      </div>
    </div>
  );
}
