import React, { lazy } from "react";
import Tabs from "./Tabs";

import RandomPaperGeneration from "./random-paper-generate";
import AutoPaperGeneration from "./auto-paper-generation";
import History from "./History";
import SetExpiryDate from "./SetExpiryDate";
import HelpSection from "./HelpSection";
import InfoPlan from "./infoPlan";

import OrderHistory from "./OrderHistory";
import FollowupOrder from "./FollowupOrder";
import User from "./User";
import Notice from "./Notice";

import BatchRegistration from "./BatchRegistration";

import BatchBenifits from "./BatchBenifits";
import RefundPolicy from "./RefundPolicy";
import AutoPaperFlow from "./auto-paper-flow";


const PaperGeneration = (props) => {
  let categories = [
    // {
    //   name: "Random Paper Generation",
    //   component: <RandomPaperGeneration access={props?.access} />,
    //   path: "/paper-generation/random",
    // },
    {
      name: "Order History",
      component: <OrderHistory access={props?.access} />,
      path: "/paper-generation/order-history",
    },

    {
      name: "Followup Order",
      component: <FollowupOrder access={props?.access} />,
      path: "/paper-generation/followup-order",
    },
    {
      name: "Set Expiry Date",
      component: <SetExpiryDate access={props?.access} />,
      path: "/paper-generation/set-expiry-date",
    },
    {
      name: "User",
      component: <User access={props?.access} />,
      path: "/paper-generation/user",
    },
    {
      name: "Batch User",
      component: <BatchRegistration access={props?.access} />,
      path: "/paper-generation/batch-user",
    },
    {
      name: "Paper History",
      component: <History access={props?.access} />,
      path: "/paper-generation/paper-history",
    },
    {
      name: "Auto Generate Notice",
      component: <AutoPaperGeneration access={props?.access} />,
      path: "/paper-generation/auto",
    },
    // {
    //   name: "Auto Paper Flow",
    //   component: <AutoPaperFlow access={props?.access} />,
    //   path: "/paper-generation/auto-flow",
    // },
    {
      name: "Notice",
      component: <Notice access={props?.access} />,
      path: "/paper-generation/notice",
    },
    {
      name: "Help Section",
      component: <HelpSection access={props?.access} />,
      path: "/paper-generation/help-section",
    },
    {
      name: "Plan Info",
      component: <InfoPlan access={props?.access} />,
      path: "/paper-generation/plan-info",
    },
    {
      name: "Batch Benifits",
      component: <BatchBenifits access={props?.access} />,
      path: "/paper-generation/batch-benifits",
    },
    {
      name: "Refund Policy",
      component: <RefundPolicy access={props?.access} />,
      path: "/paper-generation/refund-policy",
    },
  ];
  return <Tabs categories={categories} title="Paper Generation" />;
};

export default PaperGeneration;
