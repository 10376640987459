import React, { useRef, useState, useEffect } from "react";
import { Button } from "./Button";
import { ErrorToast, SuccessToast } from "./toast";
import { Api } from "../Api/Api";

const InfoPlan = () => {
  const [error, setError] = useState({});
  const fileInputRef = useRef(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Api.getRequest("api/purchaseplan/");

        const purchaseplan = JSON.parse(response?.data);
        console.log("purchaseplan---", purchaseplan);
        if (purchaseplan?.status === 200) {
          if (purchaseplan.data?.url) {
            setDataLoaded(true);
            setSelectedFile(purchaseplan.data?.url);
          }
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        ErrorToast("Error fetching data");
      }
    };
    if (!dataLoaded) {
      fetchData();
    }
  }, [dataLoaded]);

  const handleUpload = async () => {
    if (fileInputRef.current.files.length === 0) {
      setError({ upload: "Please select a file." });
      return;
    }
    const selectedFile = fileInputRef?.current.files[0];
    if (!selectedFile.type.includes("pdf")) {
      setError({ upload: "Please select a PDF file." });
      return;
    }
    const formData = new FormData();
    console.log("selectedFile---------->", selectedFile, formData);
    formData.append("image", selectedFile);
    try {
      setIsLoading(true);
      const { data } = await Api.axiospostRequest(
        "api/upload/single_file",
        formData
      );

      const payload = {
        url: data?.data,
      };

      const uploadData = await Api.postRequest(
        `api/purchaseplan/create`,
        payload
      );
      const purchaseplan = JSON.parse(uploadData?.data);

      console.log("uploadData----------->", purchaseplan);
      if (purchaseplan?.status === 200) {
        SuccessToast("purchase Plan PDF Updated Successfully");
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      } else {
        ErrorToast(data?.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(URL.createObjectURL(file));
    setImage(e.target.files[0]);
  };

  return (
    <div className="w-full sm:px-6">
      <div>
        <div className="sm:mb-0 overflow-hidden ">
          <div className="flex flex-col mt-4">
            <section className="bg-white rounded-2xl flex flex-col shadow-sm min-h-[calc(100vh-40px-16px-24px-38px-50px-40px)]">
              <div className="px-8">
                <div className="mt-10 mb-3">
                  <h1 className="text-2xl font-bold">Upload Plan PDF</h1>
                </div>

                <div className="max-w-xl">
                  <div className="w-full space-y-6">
                    <div className="mb-3">
                      <label
                        htmlFor="fileUpload"
                        className="block text-sm font-medium text-[#252F40] font-poppins"
                      >
                        Upload PDF
                      </label>
                      {selectedFile && (
                        <div
                          onClick={() => window.open(selectedFile)}
                          className="mb-3"
                        >
                          <img
                            src={
                              "https://static.vecteezy.com/system/resources/previews/023/234/824/original/pdf-icon-red-and-white-color-for-free-png.png"
                            }
                            alt="Selected file"
                            className="w-1/5"
                          />
                        </div>
                      )}
                      <input
                        ref={fileInputRef}
                        type="file"
                        id="fileUpload"
                        accept="application/pdf"
                        className="mt-1 block w-full"
                        onChange={handleImageChange}
                      />
                    </div>
                    {error.upload && (
                      <div className="text-red-500 text-sm">{error.upload}</div>
                    )}

                    <div className="flex items-center">
                      <Button
                        label={isLoading ? "Uploading..." : "Upload"}
                        type="button"
                        onClick={handleUpload}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoPlan;
