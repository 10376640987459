import { call, put, takeLatest } from 'redux-saga/effects';
import { SubjectActions } from '../../action-types/subjectActionType';
import { getSubjectDataApi } from '../requests/subjectRequest';
import { setSubjectList } from '../../actions/subjectAction';

function* getSubjectHandler(action) {
    // start loader here
    try {
      const { data } = yield call(getSubjectDataApi, action.payload);
      yield put(setSubjectList(data.rows));
      // handle response here and set into reducer
    } catch (error) {
    } finally {
      //stop loader here
    }
  }

  export default function* subjectSaga() {
    yield takeLatest(SubjectActions.GET_SUBJECT_DATA, getSubjectHandler);
  }