import React from "react";
import Chart from "react-apexcharts";
const PieChart = ({ AllCount, seriesData, paper }) => {
  return (
    <>
      <div className="flex justify-center">
        <Chart
          type="donut"
          width={200}
          height={200}
          series={seriesData}
          options={{
            chart: {
              stacked: true,
            },
            plotOptions: {
              pie: {
                expandOnClick: false,
                donut: {
                  labels: {
                    show: true,
                    total: {
                      show: true,
                      showAlways: true,
                      fontSize: "14px",
                    },
                  },
                },
              },
            },
            dataLabels: {
              enabled: false,
            },

            yaxis: {
              title: {
                text: "Growth",
              },
              min: 0,
              max: 1000,
              style: {
                color: "#7B809A",
              },
            },
            legend: {
              show: false,
              position: "top",
            },
          }}
        />
      </div>

      <div className="px-3">
        <dl
          className={`grid ${
            paper ? "lg:grid-cols-3" : "lg:grid-cols-2"
          } text-center divide-x`}
        >
          <div className="flex flex-col">
            <dt className="text-sm text-gray-600">
              {paper ? "Auto Paper Generation Count" : "Subscription Count"}
            </dt>
            <div className="text-base font-semibold text-gray-900 flex justify-center items-center">
              {paper
                ? AllCount?.autoPaperGenerationCount
                : AllCount?.subscriptionPriceCount}
              <span className="ml-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  xmlnsSvgjs="http://svgjs.com/svgjs"
                  width="512"
                  height="512"
                  x="0"
                  y="0"
                  viewBox="0 0 32 32"
                  xmlSpace="preserve"
                  className="w-3 h-3"
                >
                  <g>
                    <path
                      d="M29.604 10.528 17.531 23.356a2.102 2.102 0 0 1-3.062 0L2.396 10.528c-.907-.964-.224-2.546 1.1-2.546h25.008c1.324 0 2.007 1.582 1.1 2.546z"
                      fill="#81B622"
                      data-original="#000000"
                    ></path>
                  </g>
                </svg>
              </span>
              <p className="text-xs text-[#81B622] ml-1">
                {(paper
                  ? AllCount?.growth?.autoPaperGenerationGrowth
                  : AllCount?.growth?.subscriptionGrowth || 0
                )?.toFixed(2)}
                %
              </p>
            </div>
          </div>
          <div className="flex flex-col">
            <dt className="text-sm text-gray-600">
              {paper ? "Manual Paper Generation Count" : " Book Order Count"}
            </dt>
            <div className="text-base font-semibold text-gray-900 flex justify-center items-center">
              {paper
                ? AllCount?.manualPaperGenerationCount
                : AllCount?.bookOrderPriceCount}
              <span className="ml-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  xmlnsSvgjs="http://svgjs.com/svgjs"
                  width="512"
                  height="512"
                  x="0"
                  y="0"
                  viewBox="0 0 32 32"
                  xmlSpace="preserve"
                  className="w-3 h-3"
                >
                  <g>
                    <path
                      d="M29.604 10.528 17.531 23.356a2.102 2.102 0 0 1-3.062 0L2.396 10.528c-.907-.964-.224-2.546 1.1-2.546h25.008c1.324 0 2.007 1.582 1.1 2.546z"
                      fill="#81B622"
                      data-original="#000000"
                    ></path>
                  </g>
                </svg>
              </span>
              <p className="text-xs text-[#81B622] ml-1">
                {(paper
                  ? AllCount?.growth?.manualPaperGenerationGrowth
                  : AllCount?.growth?.bookOrderGrowth || 0
                )?.toFixed(2)}
                %
              </p>
            </div>
          </div>
          {paper ? (
            <div className="flex flex-col">
              <dt className="text-sm text-gray-600">
                Random Paper Generation Count
              </dt>
              <div className="text-base font-semibold text-gray-900 flex justify-center items-center">
                {AllCount?.randomPaperGenerationCount}
                <span className="ml-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xmlnsSvgjs="http://svgjs.com/svgjs"
                    width="512"
                    height="512"
                    x="0"
                    y="0"
                    viewBox="0 0 32 32"
                    xmlSpace="preserve"
                    className="w-3 h-3"
                  >
                    <g>
                      <path
                        d="M29.604 10.528 17.531 23.356a2.102 2.102 0 0 1-3.062 0L2.396 10.528c-.907-.964-.224-2.546 1.1-2.546h25.008c1.324 0 2.007 1.582 1.1 2.546z"
                        fill="#81B622"
                        data-original="#000000"
                      ></path>
                    </g>
                  </svg>
                </span>
                <p className="text-xs text-[#81B622] ml-1">
                  {(AllCount?.growth?.randomPaperGenerationGrowth || 0).toFixed(
                    2
                  )}
                  %
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
        </dl>
      </div>
    </>
  );
};

export default PieChart;
