import {
  ChevronUpDownIcon,
  EyeIcon,
  EyeSlashIcon,
  PlusIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useRef, useState } from "react";
import { SortData } from "./SortData";
import ActionPopup from "./ActionPopup";
import Pagination from "./Pagination";
import DeletePopup from "./DeletePopup";
import FloatNotification from "./FloatNotification";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import MasterTitle from "./MasterTitle";
import Model from "./Model";
import { Button } from "./Button";
import { Input, TextArea } from "./Input";
import { Checkbox } from "./Checkbox";
import { Grid, Typography } from "@mui/material";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast, WarningToast } from "./toast";
import Select from "./Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import moment from "moment";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import CircularProgress from "@mui/material/CircularProgress";
import NoDataFound from "./NoDataFound";
import ActionButton from "./ActionButton";
import { TrashIcon } from "@heroicons/react/20/solid";
import Divider from "@mui/material/Divider";
import Records from "./Records";
import Selected from "@mui/material/Select";
import { CKEditor } from "ckeditor4-react";
import { getDecryptedDataFromLocalStorage } from "../Api/encryption";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs from "dayjs";
const BatchRegistration = (props) => {
  const accessRole = props?.access?.filter((e) => e?.name === "Paper")?.[0];
  const LoginUserData = getDecryptedDataFromLocalStorage("userData");
  const navigate = useNavigate();
  const cancelButtonRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [SelectUserId, setSelectUserId] = useState();
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [userCount, setUserCount] = useState(0);
  const [userDataTable, setUserDataTable] = useState([]);

  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const [isModel, setIsModel] = useState(false);
  const [isModelNotification, setIsModelNotification] = useState(false);
  const [isPurchaseStatus, setIsPurchaseStatus] = useState("");
  const [isExportLoader, setIsExportLoader] = useState(false);

  const [error, setError] = useState({});
  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const npage = Math.ceil(userCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [isLoader, setIsLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [titleData, setTitleData] = useState([
    {
      title: "Sr No.",
      sort: 1,
      fieldName: "SrNo",
    },
    {
      title: "STUDENT NAME",
      sort: 1,
      fieldName: "username",
    },
    {
      title: "EMAIL",
      sort: 1,
      fieldName: "email",
    },
    {
      title: "PHONE",
      sort: 1,
      fieldName: "phone",
    },
    {
      title: "CITY",
      sort: 1,
      fieldName: "city",
    },
    {
      title: "FEES",
      sort: 1,
      fieldName: "fees",
    },
    // {
    //   title: "ONLINE",
    //   sort: 1,
    //   fieldName: "online",
    // },
    {
      title: "CREATED AT",
      sort: 1,
      fieldName: "createdat",
    },
    {
      title: "BOARD",
      sort: 1,
      fieldName: "board",
    },
    {
      title: "MEDIUM",
      sort: 1,
      fieldName: "medium",
    },
    {
      title: "STANDARD",
      sort: 1,
      fieldName: "standard",
    },
    {
      title: "SEMESTER",
      sort: 1,
      fieldName: "semester",
    },
    {
      title: "SUBJECTS",
      sort: 1,
      fieldName: "subjects",
    },
    // {
    //   title: accessRole?.edit ? "EDIT" : "",
    //   sort: 1,
    //   fieldName: "",
    // },
    // {
    //   title: accessRole?.delete ? "DELETE" : "",
    //   sort: 1,
    //   fieldName: "",
    // },
  ]);
  const [editData, setEditData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const [deleteType, setDeleteType] = useState("single");
  const [userData, setUserData] = useState({
    userName: "",
    email: "",
    phoneNumber: "",
    userPost: "",
    instituteName: "",
    city: "",
  });

  const [userFilter, setUserFilter] = useState({
    board: "",
    medium: "",
    standard: "",
    semester: "",
  });

  const [BookNotificationData, setBookNotificationData] = useState([]);
  const ansRef = useRef(null);

  const [errorNotification, setErrorNotification] = useState({});

  const [NotificationModelData, setNotificationModelData] = useState({
    title: "",
    description: "",
    type: "",
    htmldata: "",
    linkdata: "",
    bookdata: "",
  });

  const [NotificationFilter, setNotificationFilter] = useState({
    board: "",
    medium: "",
    standard: "",
    semester: "",
    subject: "",
  });

  const [boardModelData, setBoardModelData] = useState([]);
  const [mediumModelData, setMediumModelData] = useState([]);
  const [standardModelData, setStandardModelData] = useState([]);
  const [semesterModelData, setSemesterModelData] = useState([]);
  const [subjectModelData, setSubjectModelData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const [isResetFilter, setIsResetFilter] = useState(false);
  const [boardData, setBoardData] = useState([]);
  const [mediumData, setMediumData] = useState([]);
  const [standardData, setStandardData] = useState([]);
  const [semesterData, setSemesterData] = useState([]);

  const location = useLocation();
  const { UserType } = location?.state || "";
  const [selecteUserType, setSelectedUserType] = useState(UserType);

  const currentdatetime = moment().format("YYYY-MM-DDTHH:mm:ss");

  const [selectedManualTrxDateTime, setSelectedManualTrxDateTime] =
    useState(null);
  const handleChangeManualTrxDateTime = (newValue) => {
    setSelectedManualTrxDateTime(newValue);
  };

  const handlePurchaseChange = (event) => {
    setIsPurchaseStatus(event.target.value);
  };

  // const [selectAll, setSelectAll] = useState(false);

  const handleUserTypeChange = (event) => {
    setSelectedUserType(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleChange = (name, value) => {
    setUserData((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
  };

  const phoneNumberRegex = /^\d{10}$/;

  const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

  const emailPattern =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const validation = () => {
    let errors = {};
    let formIsValid = true;
    if (!userData?.userName) {
      formIsValid = false;
      errors["userName"] = "Please Enter User Name.";
    }
    if (!userData?.email) {
      formIsValid = false;
      errors["email"] = "Please Enter Email ID.";
    } else if (!emailPattern.test(userData?.email)) {
      formIsValid = false;
      errors["isValidEmail"] = "Please Valid Email ID.";
    }
    if (!userData?.phoneNumber) {
      formIsValid = false;
      errors["phoneNumber"] = "Please Enter Phone Number.";
    } else if (!phoneNumberRegex.test(userData?.phoneNumber)) {
      formIsValid = false;
      errors["isValidPhoneNumber"] = "Please Enter a 10-digit Phone Number.";
    }
    if (!userData?.userPost) {
      formIsValid = false;
      errors["userPost"] = "Please Select User Type.";
    }
    if (!userData?.instituteName) {
      formIsValid = false;
      errors["instituteName"] = "Please Enter Institute Name.";
    }
    if (!userData?.city) {
      formIsValid = false;
      errors["city"] = "Please Enter City.";
    }
    setError(errors);
    return formIsValid;
  };

  const _getUser = async (limit, offset) => {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/batch-registration?&limit=${limit || ""}&offset=${offset || ""
        }&search=${searchValue || ""
        }&board=${userFilter?.board?.id || ""
        }&medium=${userFilter?.medium?.id || ""}&standard=${userFilter?.standard?.id || ""}&semester=${userFilter?.semester?.id || ""}`
      );

      const req = JSON.parse(data);
      if (req?.status === 200) {
        const modifyData = req?.data?.map((e) => {
          return {
            ...e,
            isShow: false,
          };
        });
        setUserDataTable(modifyData);
        setUserCount(req?.totalCount || 50);
        setCheckedItems([]);
        setIsModel(false);
        setIsLoader(false);
      } else {
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const _getUserById = async (id) => {
    try {
      const { data } = await Api.getRequest(`api/auth/getUserByID/${id || ""}`);
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setEditData(req?.data);
        setIsModel(true);
      } else {
        ErrorToast(req?.massage);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const _addUser = async () => {
    if (validation()) {
      setIsLoading(true);

      try {
        let body = {
          first_name: userData?.userName,
          email: userData?.email,
          mobile: userData?.phoneNumber,
          user_type: userData?.userPost,
          school_name: userData?.instituteName,
          city: userData?.city,
          type: "user",
          roll: 1,
        };

        if (editData) {
          try {
            const data = await Api.putRequest(
              `api/auth/updateUserByAdmin/${editData?.id}`,
              body
            );
            if (data?.data?.status === 200) {
              SuccessToast(data?.data?.message);
              _getUser(recordsPerPage, currentPage);
              setIsModel(false);
              setUserData({});
              setError({});
              setEditData(null);
              setTimeout(() => {
                setIsLoading(false);
              }, 2000);
            } else {
              ErrorToast(data?.data?.message);
            }
          } catch (error) {
            ErrorToast(error?.message);
          }
        } else {
          try {
            const { data } = await Api.postRequest(
              `api/auth/createUserByAdmin`,
              body
            );
            const req = JSON.parse(data);
            if (req?.status === 200) {
              SuccessToast(req?.message);
              // _getUser(recordsPerPage, currentPage);
              // setIsModel(false);
              setUserData({});
              setError({});
              setEditData(null);
              setTimeout(() => {
                setIsLoading(false);
              }, 2000);
            } else {
              ErrorToast(req?.message);
            }
          } catch (error) {
            ErrorToast(error?.message);
          }
        }
      } catch (error) {
        ErrorToast(error?.message);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    _getUser(recordsPerPage, currentPage);
  }, [recordsPerPage, currentPage]);

  useEffect(() => {
    if (editData) {
      setUserData({
        userName: editData?.username,
        email: editData?.userInfo?.email,
        phoneNumber: editData?.mobile,
        userPost: editData?.userInfo?.user_type,
        instituteName: editData?.userInfo?.school_name,
        city: editData?.userInfo?.city,
      });
    }
  }, [editData]);

  const onExportHandler = async () => {
    setIsExportLoader(true);
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    try {
      const { data } = await Api.getRequest(
        `api/auth/getAllUser?search=${searchValue}&startDate=${startDate}&endDate=${endDate}&board=${userFilter?.board?.id || ""
        }&medium=${userFilter?.medium?.id || ""}&standard=${userFilter?.standard?.id || ""
        }`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        const exportFormShape = req?.data
          .sort((a, b) => b.id - a.id)
          .map((user) => {
            return {
              "Sr No.": user?.id,
              "User Name": user?.username || user?.userInfo?.first_name || "-",
              Email: user?.userInfo?.email || "-",
              Phone: user?.mobile || "-",
              "User Type": user?.userInfo?.user_type
                ? user?.userInfo?.user_type
                : "-",
              "Institude Name": user?.userInfo?.school_name || "-",
              City: user?.userInfo?.city || "-",
              "Created At": moment(user?.createdAt).format(
                "D MMMM, YYYY | hh:mm A"
              ),
              "Purchase Count": user?.subscriptionCount || "-",
              board: user?.userInfo?.boardInfo?.board_name
                ? user?.userInfo?.boardInfo?.board_name
                : "-",
              Medium: user?.userInfo?.mediumInfo?.name
                ? user?.userInfo?.mediumInfo?.name
                : "-",
              Standard: user?.userInfo?.standardInfo?.name
                ? user?.userInfo?.standardInfo?.name
                : "-",
              Comment: user?.comment?.description
                ? user?.comment?.description
                : "-",
            };
          });

        const ws = XLSX.utils.json_to_sheet(exportFormShape);

        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, {
          bookType: "xlsx",
          type: "array",
        });

        const dataBlob = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(dataBlob, "All User" + fileExtension);
        setIsExportLoader(false);
      } else {
        setIsExportLoader(false);
        // Handle error or other cases
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsExportLoader(false);
    }
  };

  const handleSearchFilter = () => {
    setCurrentPage(1);
    _getUser(recordsPerPage, 1);
  };

  const resetFilter = () => {
    navigate(window.location.pathname, { replace: true });
    setSelectedUserType("");
    setIsPurchaseStatus("");
    setIsResetFilter(true);
    setSearchValue("");
    setStartDate("");
    setEndDate("");
    setUserFilter({
      board: "",
      medium: "",
      standard: "",
      semester: "",
    });
    setMediumData([]);
    setStandardData([]);
  };

  useEffect(() => {
    if (isResetFilter) {
      _getUser(recordsPerPage, 1);
      setIsResetFilter(false);
    }
  }, [isResetFilter]);

  const getBoardData = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setBoardData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getMediumData = async () => {
    try {
      let body = `?board=${userFilter?.board?.id}`;
      const { data } = await Api.getRequest(`api/medium${body}`);
      const req = JSON.parse(data);
      setMediumData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getStandardData = async () => {
    try {
      let body = `?board=${userFilter?.board?.id}&medium=${userFilter?.medium?.id}`;
      const { data } = await Api.getRequest(`api/standard${body}`);
      const req = JSON.parse(data);
      setStandardData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSemesterData = async () => {
    try {
      let body = `?standard=${userFilter?.standard?.id}`;
      const { data } = await Api.getRequest(`api/semester${body}`);
      const req = JSON.parse(data);
      setSemesterData(req?.data?.rows);
    } catch (error) {
      ErrorToast(error);
    }
  }

  const handleFilter = (name, value) => {
    setUserFilter({
      ...userFilter,
      [name]: value,
    });
    if (name === "board") {
      setUserFilter((oldValue) => ({
        ...oldValue,
        medium: "",
        standard: "",
        semester: "",
        [name]: value,
      }));
      setMediumData([]);
      setStandardData([]);
      setSemesterData([]);
    }
    if (name === "medium") {
      setUserFilter((oldValue) => ({
        ...oldValue,
        standard: "",
        semester: "",
        [name]: value,
      }));
      setStandardData([]);
      setSemesterData([]);
    }
    if (name === "standard") {
      setUserFilter((oldValue) => ({
        ...oldValue,
        semester: "",
        [name]: value,
      }));
      setSemesterData([]);
    }
  };

  useEffect(() => {
    if (userFilter["board"] !== "") {
      getMediumData();
    }
  }, [userFilter["board"]]);

  useEffect(() => {
    if (userFilter["medium"] !== "") {
      getStandardData();
    }
  }, [userFilter["medium"]]);

  useEffect(() => {
    if (userFilter["standard"] !== "") {
      getSemesterData();
    }
  }, [userFilter["standard"]]);

  useEffect(() => {
    getBookNotificationData();
    getBoardData();
    getBoardModelData();
  }, []);

  const getBookNotificationData = async () => {
    try {
      const { data } = await Api.getRequest(`api/books`);
      const req = JSON.parse(data);
      setBookNotificationData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getBoardModelData = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setBoardModelData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getMediumModelData = async () => {
    try {
      let body = `?board=${NotificationFilter?.board?.id}`;
      const { data } = await Api.getRequest(`api/medium${body}`);
      const req = JSON.parse(data);
      setMediumModelData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getStandardModelData = async () => {
    try {
      let body = `?board=${NotificationFilter?.board?.id}&medium=${NotificationFilter?.medium?.id}`;
      const { data } = await Api.getRequest(`api/standard${body}`);
      const req = JSON.parse(data);
      setStandardModelData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSemesterModelData = async () => {
    try {
      let body = `?board=${NotificationFilter?.board?.id}&medium=${NotificationFilter?.medium?.id}&standard=${NotificationFilter?.standard?.id}`;
      const { data } = await Api.getRequest(`api/semester${body}`);
      const req = JSON.parse(data);
      setSemesterModelData(req?.data?.rows);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSubjectModelData = async () => {
    try {
      let body = `?board=${NotificationFilter?.board?.id}&medium=${NotificationFilter?.medium?.id}&standard=${NotificationFilter?.standard?.id}&semester=${NotificationFilter?.semester?.id}`;
      const { data } = await Api.getRequest(`api/subject${body}`);
      const req = JSON.parse(data);
      setSubjectModelData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const handleFilterModel = (name, value) => {
    setNotificationFilter({
      ...NotificationFilter,
      [name]: value,
    });
    if (name === "board") {
      setNotificationFilter((oldValue) => ({
        ...oldValue,
        medium: "",
        standard: "",
        semester: "",
        subject: "",
        [name]: value,
      }));
      setMediumModelData([]);
      setStandardModelData([]);
      setSemesterModelData([]);
      setSubjectModelData([]);
    }
    if (name === "medium") {
      setNotificationFilter((oldValue) => ({
        ...oldValue,
        standard: "",
        semester: "",
        subject: "",
        [name]: value,
      }));
      setStandardModelData([]);
      setSemesterModelData([]);
      setSubjectModelData([]);
    }
    if (name === "standard") {
      setNotificationFilter((oldValue) => ({
        ...oldValue,
        semester: "",
        subject: "",
        [name]: value,
      }));
      setSemesterModelData([]);
      setSubjectModelData([]);
    }
    if (name === "semester") {
      setNotificationFilter((oldValue) => ({
        ...oldValue,
        subject: "",
        [name]: value,
      }));
      setSubjectModelData([]);
    }
  };

  useEffect(() => {
    if (NotificationFilter["board"] !== "") {
      getMediumModelData();
    }
  }, [NotificationFilter["board"]]);

  useEffect(() => {
    if (NotificationFilter["medium"] !== "") {
      getStandardModelData();
    }
  }, [NotificationFilter["medium"]]);

  useEffect(() => {
    if (NotificationFilter["standard"] !== "") {
      getSemesterModelData();
    }
  }, [NotificationFilter["standard"]]);

  useEffect(() => {
    if (NotificationFilter["semester"] !== "") {
      getSubjectModelData();
    }
  }, [NotificationFilter["semester"]]);

  // const handleSelectAll = () => {
  //   if (selectAll) {
  //     // If all checkboxes are already selected, deselect all
  //     setCheckedItems([]);
  //   } else {
  //     // Otherwise, select all checkboxes
  //     const allIds = userDataTable.map((data) => data.id);
  //     setCheckedItems(allIds);
  //   }
  //   // Toggle the select all state
  //   setSelectAll(!selectAll);
  // };

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) => {
      const isChecked = prevState.includes(id);

      const updatedItems = isChecked
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id];

      return updatedItems;
    });
  };


  const validationNotification = () => {
    const linkPattern = /^(http|https):\/\/.+$/i;

    let errors = {};
    let formIsValid = true;

    if (!NotificationModelData?.title) {
      formIsValid = false;
      errors["title"] = "Please select title.";
    }
    if (!NotificationModelData?.description) {
      formIsValid = false;
      errors["description"] = "Please select description.";
    }
    if (!NotificationModelData?.type) {
      formIsValid = false;
      errors["type"] = "Please select type.";
    }

    if (NotificationModelData?.type === "html") {
      if (!NotificationModelData?.htmldata) {
        formIsValid = false;
        errors["htmldata"] = "Please enter Body.";
      }
    }

    if (NotificationModelData?.type === "link") {
      if (!NotificationModelData?.linkdata) {
        formIsValid = false;
        errors["linkdata"] = "Please enter link.";
      } else if (!linkPattern.test(NotificationModelData.linkdata.trim())) {
        formIsValid = false;
        errors["linkdata"] = "Please enter a valid HTTP or HTTPS link.";
      }
    }

    if (NotificationModelData?.type === "book") {
      if (!NotificationModelData?.bookdata) {
        formIsValid = false;
        errors["bookdata"] = "Please select book.";
      }
    }
    if (
      NotificationModelData?.type === "subject" ||
      NotificationModelData?.type === "subject_student"
    ) {
      if (!NotificationFilter?.board) {
        formIsValid = false;
        errors["board"] = "Please select board.";
      }
      if (!NotificationFilter?.medium) {
        formIsValid = false;
        errors["medium"] = "Please select medium.";
      }
      if (!NotificationFilter?.standard) {
        formIsValid = false;
        errors["standard"] = "Please select standard.";
      }
      if (!NotificationFilter?.semester) {
        formIsValid = false;
        errors["semester"] = "Please select semester.";
      }
      if (!NotificationFilter?.subject) {
        formIsValid = false;
        errors["subject"] = "Please select subject.";
      }
    }
    setErrorNotification(errors);
    return formIsValid;
  };

  const handleChangeNotification = (name, value) => {
    setNotificationModelData((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
    if (name === "type") {
      setNotificationModelData((oldValue) => ({
        ...oldValue,
        htmldata: "",
        linkdata: "",
        bookdata: "",
        [name]: value,
      }));
      setNotificationFilter({
        board: "",
        medium: "",
        standard: "",
        semester: "",
        subject: "",
      });
      setMediumModelData([]);
      setStandardModelData([]);
      setSemesterModelData([]);
      setSubjectModelData([]);
    }
  };

  const handleAnswerNotification = (e, name) => {
    const value = e?.editor?.getData();
    setNotificationModelData((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
  };

  const handleAddEditModelSubmit = async () => {
    if (validationNotification()) {
      setIsLoading(true);
      try {
        let imgData = [];
        if (selectedFile) {
          const formData = new FormData();
          formData.append("image", selectedFile);
          const responseimg = await Api.axiospostRequest(
            "api/upload/single_file",
            formData
          );

          if (responseimg.status === 200) {
            imgData = [responseimg?.data?.data];
          }
        }
        // image: selectedFile ? (imgData.length > 0 ? imgData[0] : "") : "",
        let body = {
          ...(imgData.length > 0 && {
            image: imgData[0],
          }),
          title: NotificationModelData?.title,
          body: NotificationModelData?.description,
          type: NotificationModelData?.type,
          data:
            NotificationModelData?.type === "html"
              ? NotificationModelData?.htmldata
              : NotificationModelData?.type === "link"
                ? NotificationModelData?.linkdata
                : NotificationModelData?.type === "book"
                  ? NotificationModelData?.bookdata.toString()
                  : NotificationModelData?.type === "subject" ||
                    NotificationModelData?.type === "subject_student"
                    ? JSON.stringify(NotificationFilter?.subject)
                    : "",
        };
        const response = await Api.postRequest("api/notification/create", body);
        const req = JSON.parse(response.data);
        if (req?.status === 200) {
          try {
            let body = {
              notificationId: req?.data?.id,
              customUserIds: checkedItems,
              timescheduler: selectedManualTrxDateTime?.format(
                "YYYY-MM-DDTHH:mm:ss"
              ),
            };
            const { data } = await Api.postRequest(
              `api/notification/userPushNotification?user_type=${selecteUserType || ""
              }&search=${searchValue || ""}&startDate=${startDate || ""
              }&endDate=${endDate || ""}&board=${userFilter?.board?.id || ""
              }&medium=${userFilter?.medium?.id || ""}&standard=${userFilter?.standard?.id || ""
              }`,
              body
            );

            const req2 = JSON.parse(data);
            if (req2?.status === 200) {
              SuccessToast(req2?.message);
              // setIsModelNotification(false);
              // setNotificationModelData({});
              // setErrorNotification({});
              setSelectedFile(null);
              setNotificationFilter((prevState) => ({
                ...prevState,
                subject: "",
              }));
              // _getUser(recordsPerPage, currentPage);
              setTimeout(() => {
                setIsLoading(false);
              }, 2000);
            } else {
              ErrorToast(req2?.message);
              setIsLoading(false);
            }
          } catch (error) {
            ErrorToast(error?.message);
            setIsLoading(false);
          }
        } else {
          console.log("hello🎈");
          ErrorToast("Something wrong please check");
        }
      } catch (error) {
        ErrorToast(error?.message);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const openNotificationModel = () => {
    setIsModelNotification(true);
  };

  const handleResetNotification = () => {
    setNotificationModelData({});
    setErrorNotification({});
    setSelectedManualTrxDateTime(null);
    setSelectedFile(null);
  };

  const handleChooseFileClick = () => {
    document.getElementById("fileUpload").click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  return (
    <div className="h-full flex items-center justify-center ">
      <div className="h-full flex-1 flex flex-col space-y-5 lg:space-y-0 lg:flex-row lg:space-x-10 w-full sm:px-2">
        <div className="flex-1 px-2 sm:px-0 w-full">
          <div className="flex justify-between items-center">
            <div>
              <MasterTitle
                title="All User"
                description="Below are the list of all the betch registered user."
              />
            </div>
            {/* <div className="flex items-center space-x-2 overflow-x-auto">
                {LoginUserData?.type === "superadmin" && (
                  <div>
                    {!isExportLoader ? (
                      <Button
                        isLight
                        id=""
                        type="submit"
                        icon={ArrowDownTrayIcon}
                        label={"EXPORT"}
                        onClick={() =>
                          userDataTable?.length > 0 ? onExportHandler() : null
                        }
                      />
                    ) : (
                      <Button
                        isLight
                        id=""
                        type="submit"
                        icon={ArrowDownTrayIcon}
                        label={"Loading..."}
                      >
                        Loading...
                      </Button>
                    )}
                  </div>
                )}
                {accessRole?.edit && (
                  <div>
                    <Button
                      id=""
                      type="button"
                      label={"USER COMMENT"}
                      onClick={() => navigate("/paper-generation/user-comment")}
                    />
                  </div>
                )}
                <div>
                  {" "}
                  <div style={{ display: "flex", gap: "5px" }}>
                    {accessRole?.add && (
                      <Button
                        id=""
                        type="submit"
                        icon={PlusIcon}
                        label={"ADD USER"}
                        onClick={() => setIsModel(true)}
                      />
                    )}
                    {accessRole?.add && (
                      <Button
                        id=""
                        type="button"
                        label={
                          checkedItems?.length === 0
                            ? "SEND NOTIFICATION TO ALL"
                            : "SEND NOTIFICATION"
                        }
                        onClick={() => openNotificationModel()}
                      />
                    )}
                  </div>
                  <Model
                    title={`${editData ? "Edit" : "Add"} User`}
                    show={isModel}
                    onCancel={() => {
                      setIsModel(false);
                      // setUserData({});
                      // setError({});
                      // setEditData(null);
                    }}
                    onClose={() => {
                      setIsModel(false);
                      setUserData({});
                      setError({});
                      setEditData(null);
                    }}
                    onOk={() => _addUser()}
                    okText="SUBMIT"
                    cancelText="CLOSE"
                    width="max-w-3xl"
                    isLoading={isLoading}
                    disabled={isLoading}
                  >
                    <div className="flex flex-col mt-4">
                      <section className="bg-white rounded-2xl flex flex-col shadow-sm min-h-[calc(60vh-40px-16px-24px-38px-50px-40px-52px-16px)]">
                        <Grid container spacing={2} mb={3}>
                          <Grid item md={6} xs={12}>
                            <Input
                              name="userName"
                              type="text"
                              placeholder="Enter your username"
                              label="User Name"
                              required
                              onChange={(e) =>
                                handleChange("userName", e?.target?.value)
                              }
                              value={userData?.userName || ""}
                            />
                            <Typography variant="caption" color={"error"}>
                              {!userData?.userName ? error?.userName : ""}
                            </Typography>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Input
                              name="email"
                              type="email"
                              placeholder="Enter your email"
                              label="Email"
                              required
                              onChange={(e) =>
                                handleChange("email", e?.target?.value)
                              }
                              value={userData?.email || ""}
                            />
                            <Typography variant="caption" color={"error"}>
                              {!userData?.email ? error?.email : ""}
                            </Typography>
                            <Typography variant="caption" color={"error"}>
                              {!emailPattern.test(userData?.email)
                                ? error?.isValidEmail
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Input
                              name="phoneNumber"
                              type="number"
                              placeholder="Enter your phone  number"
                              label="Phone Number"
                              required
                              onChange={(e) =>
                                handleChange("phoneNumber", e?.target?.value)
                              }
                              value={userData?.phoneNumber || ""}
                            />
                            <Typography variant="caption" color={"error"}>
                              {!userData?.phoneNumber ? error?.phoneNumber : ""}
                            </Typography>
                            <Typography variant="caption" color={"error"}>
                              {!phoneNumberRegex.test(userData?.phoneNumber)
                                ? error?.isValidPhoneNumber
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <label className="mb-3 block text-sm font-medium text-[#252F40] font-poppins">
                              Select User Type
                              <span className="text-red-500 text-sm"> * </span>
                            </label>
                            <FormControl fullWidth>
                              <Selected
                                labelId="select-placeholder"
                                value={userData?.userPost || ""}
                                className="font-regular mb-3"
                                displayEmpty
                                onChange={(e) =>
                                  handleChange("userPost", e?.target?.value)
                                }
                                size="small"
                              >
                                <MenuItem
                                  value=""
                                  disabled
                                  sx={{ fontSize: "0.8rem" }}
                                >
                                  Select User Type
                                </MenuItem>
                                <MenuItem
                                  value="teacher"
                                  sx={{ fontSize: "0.8rem" }}
                                >
                                  Teacher
                                </MenuItem>
                                <MenuItem
                                  value="student"
                                  sx={{ fontSize: "0.8rem" }}
                                >
                                  Student
                                </MenuItem>
                              </Selected>
                            </FormControl>
  
                            <Typography variant="caption" color={"error"}>
                              {!userData?.userPost ? error?.userPost : ""}
                            </Typography>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Input
                              name="instituteName"
                              type="text"
                              placeholder="Enter your Institute Name"
                              label="Institute Name"
                              required
                              onChange={(e) =>
                                handleChange("instituteName", e?.target?.value)
                              }
                              value={userData?.instituteName || ""}
                            />
                            <Typography variant="caption" color={"error"}>
                              {!userData?.instituteName
                                ? error?.instituteName
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Input
                              name="city"
                              type="text"
                              placeholder="Enter your City"
                              label="City"
                              required
                              onChange={(e) =>
                                handleChange("city", e?.target?.value)
                              }
                              value={userData?.city || ""}
                            />
                            <Typography variant="caption" color={"error"}>
                              {!userData?.city ? error?.city : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </section>
                    </div>
                  </Model>
                  <Model
                    title={"Send Notification"}
                    show={isModelNotification}
                    onCancel={() => {
                      setIsModelNotification(false);
                      handleResetNotification();
                    }}
                    onClose={() => {
                      setIsModelNotification(false);
                      handleResetNotification();
                    }}
                    onOk={() => {
                      handleAddEditModelSubmit();
                    }}
                    okText="SUBMIT"
                    cancelText="CLOSE"
                    isLoading={isLoading}
                    disabled={isLoading}
                    width="max-w-3xl"
                  >
                    <div className="w-full">
                      <Input
                        label="Notification Title"
                        id="title"
                        name="title"
                        type="text"
                        autoComplete="given-name"
                        required
                        className="w-full mb-3"
                        value={NotificationModelData?.title}
                        onChange={(e) =>
                          handleChangeNotification("title", e?.target?.value)
                        }
                      />
                      <Typography variant="caption" color={"error"}>
                        {!NotificationModelData?.title
                          ? errorNotification?.title
                          : ""}
                      </Typography>
                    </div>
                    <div className="w-full">
                      <TextArea
                        label="Description"
                        id="description"
                        name="description"
                        type="text"
                        autoComplete="given-name"
                        required
                        className="w-full mb-3"
                        value={NotificationModelData?.description}
                        onChange={(e) =>
                          handleChangeNotification(
                            "description",
                            e?.target?.value
                          )
                        }
                      />
                      <Typography variant="caption" color={"error"}>
                        {!NotificationModelData?.description
                          ? errorNotification?.description
                          : ""}
                      </Typography>
                    </div>
                    <div className="w-full">
                      <label className="mb-2 block text-sm font-medium text-[#252F40] font-poppins">
                        Type <span className="text-red-500 text-sm"> * </span>
                      </label>
                    </div>
                    <div className="w-full">
                      <FormControl fullWidth>
                        <Selected
                          size="small"
                          labelId="select-placeholder"
                          className="font-regular mb-3"
                          value={NotificationModelData?.type || ""}
                          onChange={(e) =>
                            handleChangeNotification("type", e?.target?.value)
                          }
                          displayEmpty
                          sx={{
                            border: "none",
                            background: "white",
                            mr: 1,
                            height: "37px",
                            borderColor: "gray",
                            color: "gray",
                            "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #E5F1EB",
                            },
                            "& .MuiSelect-select": {
                              fontSize: "0.8rem",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        >
                          <MenuItem value="" disabled sx={{ fontSize: "0.8rem" }}>
                            Select Type
                          </MenuItem>
                          <MenuItem value={"simple"} sx={{ fontSize: "0.8rem" }}>
                            SIMPLE
                          </MenuItem>
                          <MenuItem value={"html"} sx={{ fontSize: "0.8rem" }}>
                            HTML
                          </MenuItem>
                          <MenuItem value={"link"} sx={{ fontSize: "0.8rem" }}>
                            LINK
                          </MenuItem>
                          <MenuItem value={"book"} sx={{ fontSize: "0.8rem" }}>
                            BOOK
                          </MenuItem>
                          <MenuItem value={"subject"} sx={{ fontSize: "0.8rem" }}>
                            SUBJECT-TEACHER
                          </MenuItem>
                          <MenuItem
                            value={"subject_student"}
                            sx={{ fontSize: "0.8rem" }}
                          >
                            SUBJECT-STUDENT
                          </MenuItem>
                        </Selected>
                      </FormControl>
                      <Typography variant="caption" color={"error"}>
                        {!NotificationModelData?.type
                          ? errorNotification?.type
                          : ""}
                      </Typography>
                    </div>
                    {NotificationModelData?.type === "html" ? (
                      <div className="w-full mb-3">
                        <label className="mb-3 block text-sm font-medium text-[#252F40] font-poppins">
                          Body
                          <span className="text-red-500"> *</span>
                        </label>
                        <CKEditor
                          editorUrl="https://cdn.ckeditor.com/4.18.0/standard-all/ckeditor.js"
                          initData={NotificationModelData?.htmldata}
                          onInstanceReady={(instance) => {
                            ansRef.current = instance;
                          }}
                          onChange={(e) =>
                            handleAnswerNotification(e, "htmldata")
                          }
                          config={{
                            autoParagraph: false,
                          }}
                        />
                        <Typography variant="caption" color={"error"}>
                          {!NotificationModelData?.htmldata
                            ? errorNotification?.htmldata
                            : ""}
                        </Typography>
                      </div>
                    ) : (
                      ""
                    )}
                    {NotificationModelData?.type === "link" ? (
                      <div className="w-full">
                        <Input
                          label="Body"
                          type="text"
                          autoComplete="given-name"
                          name="link"
                          placeholder="Type here link..."
                          required
                          className="mb-2 "
                          value={NotificationModelData?.linkdata || ""}
                          onChange={(e) =>
                            handleChangeNotification("linkdata", e.target.value)
                          }
                        />
                        <Typography
                          className="my-2"
                          variant="caption"
                          color={"error"}
                        >
                          {!NotificationModelData?.linkdata ||
                          NotificationModelData?.linkdata
                            ? errorNotification?.linkdata
                            : ""}
                        </Typography>
                      </div>
                    ) : (
                      ""
                    )}
                    {NotificationModelData?.type === "book" ? (
                      <>
                        <div className="w-full">
                          <label className="mb-2 block text-sm font-medium text-[#252F40] font-poppins">
                            Body <span className="text-red-500 text-sm"> * </span>
                          </label>
                        </div>
                        <div className="w-full">
                          <FormControl fullWidth sx={{ marginTop: "5px" }}>
                            <Selected
                              displayEmpty
                              size="small"
                              labelId="select-placeholder"
                              className="font-regular mb-3"
                              value={NotificationModelData?.bookdata || ""}
                              onChange={(e) =>
                                handleChangeNotification(
                                  "bookdata",
                                  e?.target?.value
                                )
                              }
                              sx={{
                                border: "none",
                                background: "white",
                                mr: 1,
                                height: "37px",
                                borderColor: "gray",
                                color: "gray",
                                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
                                  {
                                    border: "1px solid #E5F1EB",
                                  },
                                "& .MuiSelect-select": {
                                  fontSize: "0.8rem",
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              <MenuItem value="" disabled>
                                Select Book
                              </MenuItem>
                              {[
                                ...new Set(
                                  BookNotificationData.map((item) => item.name)
                                ),
                              ].map((uniqueName) => (
                                <MenuItem
                                  key={uniqueName}
                                  value={
                                    BookNotificationData.find(
                                      (item) => item.name === uniqueName
                                    ).id
                                  }
                                >
                                  {uniqueName}
                                </MenuItem>
                              ))}
                            </Selected>
                          </FormControl>
                          <Typography
                            className="my-2"
                            variant="caption"
                            color={"error"}
                          >
                            {!NotificationModelData?.bookdata
                              ? errorNotification?.bookdata
                              : ""}
                          </Typography>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {NotificationModelData?.type === "subject" ||
                    NotificationModelData?.type === "subject_student" ? (
                      <>
                        <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                          <div className="w-full">
                            <Select
                              data={boardModelData || []}
                              value={NotificationFilter?.board}
                              onChange={(e) => handleFilterModel("board", e)}
                              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                              placeholder="All Board"
                              type="board"
                              getBoard={getBoardModelData}
                            />
                            <Typography variant="caption" color={"error"}>
                              {!NotificationFilter?.board
                                ? errorNotification?.board
                                : ""}
                            </Typography>
                          </div>
                          <div className="w-full">
                            <Select
                              data={mediumModelData || []}
                              value={NotificationFilter?.medium}
                              onChange={(e) => handleFilterModel("medium", e)}
                              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                              placeholder="All Medium"
                              type="medium"
                              getMedium={getMediumModelData}
                            />
                            <Typography variant="caption" color={"error"}>
                              {!NotificationFilter?.medium
                                ? errorNotification?.medium
                                : ""}
                            </Typography>
                          </div>
                        </div>
                        <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                          <div className="w-full">
                            <Select
                              data={standardModelData || []}
                              value={NotificationFilter?.standard}
                              onChange={(e) => handleFilterModel("standard", e)}
                              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                              placeholder="All Standard"
                              type={"standard"}
                              getStandard={getStandardModelData}
                            />
                            <Typography variant="caption" color={"error"}>
                              {!NotificationFilter?.standard
                                ? errorNotification?.standard
                                : ""}
                            </Typography>
                          </div>
                          <div className="w-full">
                            <Select
                              data={semesterModelData || []}
                              value={NotificationFilter?.semester}
                              onChange={(e) => handleFilterModel("semester", e)}
                              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                              placeholder="All Semester"
                              type={"semester"}
                              getSemester={getSemesterModelData}
                            />
                            <Typography variant="caption" color={"error"}>
                              {!NotificationFilter?.semester
                                ? errorNotification?.semester
                                : ""}
                            </Typography>
                          </div>
                        </div>
                        <div className="w-full mb-3">
                          <Select
                            data={subjectModelData || []}
                            value={NotificationFilter?.subject}
                            onChange={(e) => handleFilterModel("subject", e)}
                            className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                            placeholder="All Subject"
                            type={"subject"}
                            getSubject={getSubjectModelData}
                          />
                          <Typography variant="caption" color={"error"}>
                            {!NotificationFilter?.subject
                              ? errorNotification?.subject
                              : ""}
                          </Typography>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <div className="w-full mb-3">
                      <label
                        htmlFor="fileUpload"
                        className="mb-1 block text-sm font-medium text-[#252F40] font-poppins"
                      >
                        Scheduler Date Time (optional)
                      </label>
                      <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          fullWidth
                          components={["DateTimePicker", "DateTimePicker"]}
                        >
                          <DateTimePicker
                            views={["year", "day", "hours", "minutes", "seconds"]}
                            value={selectedManualTrxDateTime}
                            disablePast
                            onChange={handleChangeManualTrxDateTime}
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                              seconds: renderTimeViewClock,
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    <div className="w-full">
                      <label
                        htmlFor="fileUpload"
                        className="mb-3 block text-sm font-medium text-[#252F40] font-poppins"
                      >
                        Upload photo (optional)
                      </label>
  
                      <input
                        type="file"
                        id="fileUpload"
                        class="hidden"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
  
                      <div className="flex items-center ">
                        <button
                          id=""
                          type="button"
                          label={"Choose file"}
                          onClick={handleChooseFileClick}
                          className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                        >
                          Choose file
                        </button>
                        <div className="ml-1 block text-base font-medium text-[#252F40] font-poppins ">
                          {selectedFile ? selectedFile.name : "No file chosen"}
                        </div>
                      </div>
                    </div>
                  </Model>
                </div>
              </div> */}
          </div>
          <div className="w-full sm:px-0 flex flex-col items-start gap-x-4 mr-4">
            <div className="w-full flex flex-wrap sm:flex-nowrap gap-x-4">

              <div className="w-full">
                <Select
                  data={boardData || []}
                  value={userFilter?.board}
                  onChange={(e) => handleFilter("board", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Board"
                  type="board"
                  getBoard={getBoardData}
                />
              </div>
              <div className="w-full">
                <Select
                  data={mediumData || []}
                  value={userFilter?.medium}
                  onChange={(e) => handleFilter("medium", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Medium"
                  type="medium"
                  getMedium={getMediumData}
                />
              </div>
              <div className="w-full">
                <Select
                  data={standardData || []}
                  value={userFilter?.standard}
                  onChange={(e) => handleFilter("standard", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Standard"
                  type={"standard"}
                  getStandard={getStandardData}
                />
              </div>
              <div className="w-full">
                <Select
                  data={semesterData || []}
                  value={userFilter?.semester}
                  onChange={(e) => handleFilter("semester", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Semester"
                  type={"semester"}
                  getStandard={getSemesterData}
                />
              </div>
              {/* <div className="w-full mt-3">
                <FormControl fullWidth>
                  <Selected
                    size="small"
                    value={selecteUserType || ""}
                    labelId="select-placeholder"
                    id="demo-simple-select"
                    onChange={handleUserTypeChange}
                    displayEmpty
                    sx={{
                      border: "none",
                      background: "white",
                      mr: 1,
                      height: "37px",
                      borderColor: "gray",
                      color: "gray",
                      "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #E5F1EB",
                      },
                      "& .MuiSelect-select": {
                        fontSize: "0.8rem",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem value="" sx={{ fontSize: "0.8rem" }}>
                      Select User Type
                    </MenuItem>
                    <MenuItem value="teacher" sx={{ fontSize: "0.8rem" }}>
                      Teacher
                    </MenuItem>
                    <MenuItem value="student" sx={{ fontSize: "0.8rem" }}>
                      Student
                    </MenuItem>
                  </Selected>
                </FormControl>
              </div>
              <div className="w-full mt-3">
                <FormControl fullWidth>
                  <Selected
                    size="small"
                    value={isPurchaseStatus}
                    labelId="select-placeholder"
                    className="font-regular"
                    name="isPurchaseStatus"
                    onChange={handlePurchaseChange}
                    displayEmpty
                    sx={{
                      border: "none",
                      background: "white",
                      // mr: 1,
                      height: "37px",
                      borderColor: "gray",
                      color: "gray",
                      "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #E5F1EB",
                      },
                      "& .MuiSelect-select": {
                        fontSize: "0.8rem",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem value="" sx={{ fontSize: "0.8rem" }}>
                      Select Purchase Status
                    </MenuItem>
                    <MenuItem value="true" sx={{ fontSize: "0.8rem" }}>
                      Purchase
                    </MenuItem>
                    <MenuItem value="false" sx={{ fontSize: "0.8rem" }}>
                      Non Purchase
                    </MenuItem>
                  </Selected>
                </FormControl>
              </div> */}
            </div>
            <div className="w-full flex justify-between flex-wrap sm:flex-nowrap mt-3 gap-x-4">
              <div>
                <div>
                  <Input
                    required
                    disabled={false}
                    name=""
                    className="font-regular"
                    placeholder="Search User or Mobile here..."
                    value={searchValue}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
              {/* <div className="w-full flex items-end">
                <label
                  style={{ whiteSpace: "nowrap" }}
                  className="mb-3 mr-2 text-sm font-medium text-[#252F40] font-poppins"
                >
                  From Date :
                </label>
                <div className="w-full">
                  <Input
                    disabled={false}
                    name=""
                    type="date"
                    className="font-regular"
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                </div>
              </div>
              <div className="w-full flex items-end">
                <label
                  style={{ whiteSpace: "nowrap" }}
                  className="mb-3 mr-2 text-sm font-medium text-[#252F40] font-poppins"
                >
                  To Date :
                </label>
                <div className="w-full">
                  <Input
                    disabled={false}
                    name=""
                    // label="To Date :"
                    type="date"
                    className="font-regular"
                    placeholder="To Date"
                    value={endDate}
                    onChange={handleEndDateChange}
                  />
                </div>
              </div> */}
              <div className="flex gap-2">
                <div>
                  <Button
                    id=""
                    type="button"
                    label={"SEARCH"}
                    onClick={handleSearchFilter}
                  />
                </div>
                <div>
                  <Button
                    onClick={() => resetFilter()}
                    id=""
                    type="button"
                    label={"RESET"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="sm:mb-0 overflow-hidden">
            <div className="flex flex-col mt-2">
              <section
                className="bg-white rounded-2xl flex flex-col shadow-sm "
                style={{
                  width: "100%",
                  height: "75vh",
                  margin: "0 auto",
                  padding: "20px",
                }}
              >
                <div className="flex justify-between items-center px-8 mb-2">
                  <div></div>
                  <Records
                    setCurrentPage={setCurrentPage}
                    recordsPerPage={recordsPerPage}
                    setRecordsPerPage={setRecordsPerPage}
                    dataCount={userCount}
                  />
                </div>
                <div className="h-[calc(100vh-40px-16px-24px-38px-50px-40px-52px-16px-42px-24px-62px-32px)] overflow-y-auto">
                  {!isLoader && userDataTable?.length <= 0 ? (
                    <NoDataFound />
                  ) : (
                    <table style={{ width: "100%" }}>
                      <thead
                        style={{
                          position: "sticky",
                          top: 0,
                          backgroundColor: "#f4f7f8",
                          zIndex: 1,
                        }}
                      >
                        <tr
                          style={{
                            borderBottom: "1px solid #dddddd",
                            textAlign: "left",
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#7B809A",
                            textTransform: "uppercase",
                            backgroundColor: "#f4f7f8",
                          }}
                        >
                          {/* <th
                            className="px-6 py-3 whitespace-no-wrap"
                            scope="col"
                            style={{
                              width: "3%",
                              padding: "25px",
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            <Checkbox
                                checked={selectAll}
                                onChange={handleSelectAll}
                              />
                          </th> */}
                          {titleData?.map(
                            (v, ind) =>
                              v?.title && (
                                <th
                                  key={ind}
                                  // className="py-2 px-6 "
                                  style={{
                                    padding: "12px",
                                    backgroundColor: "#f4f7f8",
                                    position:
                                      v.title === "EDIT" || v.title === "DELETE"
                                        ? "sticky"
                                        : "",
                                    right:
                                      v.title === "EDIT"
                                        ? "0px"
                                        : "",
                                  }}
                                >
                                  <div className="flex justify-between">
                                    <span>{v.title}</span>
                                    {v.title !== "EDIT" &&
                                      v.title !== "DELETE" && (
                                        <span>
                                          <ChevronUpDownIcon
                                            onClick={() => {
                                              let res = SortData(
                                                userDataTable,
                                                v.fieldName,
                                                v.sort
                                              );

                                              let copyData = [...titleData];
                                              copyData[ind].sort =
                                                v.sort > 0 ? -1 : 1;
                                              setTitleData(copyData);
                                              setUserDataTable(res);
                                            }}
                                            className="w-5 h-5"
                                          />
                                        </span>
                                      )}
                                  </div>
                                </th>
                              )
                          )}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {isLoader ? (
                          <tr style={{ height: "25rem" }}>
                            <td colSpan={16} style={{ textAlign: "center" }}>
                              <CircularProgress />
                            </td>
                          </tr>
                        ) : (
                          userDataTable?.length > 0 &&
                          userDataTable?.map((data, id) => (
                            <tr
                              key={id}
                              className="my-3 px-6 text-[#000] font-reguler text-sm"
                              style={{
                                background:
                                  data?.subscriptionCount > 0 ? "#b7ddf6" : "",
                              }}
                            >
                              {/* <td className="px-6 py-3 whitespace-no-wrap">
                                <Checkbox
                                  checked={checkedItems.includes(data.id)}
                                  onChange={() => handleCheckboxChange(data.id)}
                                />
                              </td> */}
                              <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                {data?.id}
                              </td>
                              <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                {data?.student_name ||
                                  "-"}
                              </td>
                              <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                {data?.email
                                  ? data?.email
                                  : "-"}
                              </td>
                              <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                {data?.phone}
                              </td>
                              <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                {data.city
                                  ? data.city
                                  : "-"}
                              </td>
                              <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                {data.fees
                                  ? data.fees
                                  : "-"}
                              </td>

                              <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                {data.createdAt !== null
                                  ? moment(data.createdAt).format(
                                    "D MMMM, YYYY | hh:mm A"
                                  )
                                  : "-"}
                              </td>
                              <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                {data.boardInfo?.board_name
                                  ? data.boardInfo?.board_name
                                  : "-"}
                              </td>
                              <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                {data?.mediumInfo?.name
                                  ? data?.mediumInfo?.name
                                  : "-"}
                              </td>
                              <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                {data?.standardInfo?.name
                                  ? data?.standardInfo?.name
                                  : "-"}
                              </td>
                              <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                {data?.semesterInfo?.semester
                                  ? data?.semesterInfo?.semester
                                  : "-"}
                              </td>
                              <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                {data?.subjects?.map((sub, index) => (
                                  <span key={index}>{sub},{' '}</span>
                                ))}
                              </td>
                              {/* {accessRole?.edit && (
                                <td
                                  className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                                  style={{
                                    position: "sticky",
                                    right: "0px",
                                    // backgroundColor: "white",
                                    backgroundColor:
                                      data?.subscriptionCount > 0
                                        ? "#b7ddf6"
                                        : "white",
                                  }}
                                >
                                  <ActionButton
                                    key={data.key}
                                    action="edit"
                                    onClick={() => _getUserById(data?.id)}
                                  />
                                </td>
                              )} */}
                              {/* {accessRole?.delete && (
                                  <td
                                    className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                                    style={{
                                      position: "sticky",
                                      right: "0",
                                      // backgroundColor: "white",
                                      background:
                                        data?.subscriptionCount > 0
                                          ? "#b7ddf6"
                                          : "white",
                                    }}
                                  >
                                    <ActionButton
                                      key={data.key}
                                      action="delete"
                                      onClick={() => {
                                        setDeleteType("single");
                                        setOpenModel(true);
                                        setSelectUserId(data?.id);
                                      }}
                                    />
                                  </td>
                                )} */}
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  recordsPerPage={recordsPerPage}
                  data={userDataTable}
                  dataCount={userCount}
                  numbers={numbers}
                  npage={npage}
                  lastIndex={lastIndex}
                  firstIndex={firstIndex}
                />
              </section>
            </div>

            {/* Delete */}

            {/* Delete */}
            <FloatNotification
              show={showFloatNotification}
              title={"Successfully deleted!"}
              type="success"
              description={"Data Deleted."}
              onClick={() => {
                setShowFloatNotification(false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatchRegistration;
