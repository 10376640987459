import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const DashboardChart = ({ categories, series }) => {
  // const maxDataValue = Math.max(
  //   ...series.flatMap((data) => data.data)
  // ); // Find the maximum value in the series data

  // const tickInterval = 5000; // Define the interval for tick positions

  // const tickPositions = [];
  // for (let i = 0; i <= maxDataValue; i += tickInterval) {
  //   tickPositions.push(i);
  // }
  const options = {
    chart: {
      type: "column",
    },
    title: false,
    xAxis: {
      categories: categories,
    },

    yAxis: {
      title: {
        text: "Growth",
      },
      stackLabels: {
        enabled: false, // top of the bar count
      },
    },
    legend: {
      align: "left",
      x: 70,
      verticalAlign: "top",
      y: 70,
      floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || "white",
      borderColor: "#CCC",
      borderWidth: 1,
      shadow: false,
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: false, //hide count of data
        },
        pointWidth: 20, //bar width
        colors: ["#EEF1F6", "#885BDA", "#B89CE8", "#DCCDF3"],
      },
    },
    series: series.map((serie, index) => ({
      ...serie,
      color:
        index === 0
          ? "#EEF1F6"
          : index === 1
          ? "#DCCDF3"
          : index === 2
          ? "#B89CE8"
          : "#885BDA",
    })),
    credits: {
      enabled: false,
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default DashboardChart;
