import React, { useState, useEffect } from "react";
import Breadcrumbs from "./Breadcrumbs";
import { Box, Typography, Select, Menu } from "@mui/material";
import { Input } from "./Input";
import { ArrowSmallRightIcon, UserIcon } from "@heroicons/react/20/solid";
import { Link, useNavigate } from "react-router-dom";
import Selected from "./Select";
import { Api } from "../Api/Api";
import { ErrorToast, WarningToast } from "./toast";
import Highcharts from "highcharts";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import DateEditCalendar from "./DateEditCalendar";
import Calander from "../Image/Calander.svg";
import MasterTitle from "./MasterTitle";

const PartnerDashboard = () => {
  const navigate = useNavigate();
  const [AllCount, setAllCount] = useState();
  const [all, setAll] = useState(true);
  const [thisWeek, setThisWeek] = useState(false);
  const [thisMonth, setThisMonth] = useState(false);
  const [thisYear, setThisYear] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [graphData, setGraphData] = useState();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [anchorEl, setAnchorEl] = useState();
  const [anchorElEnd, setAnchorElEnd] = useState();
  const open = Boolean(anchorEl);
  const openEnd = Boolean(anchorElEnd);
  const [data, setData] = useState([
    {
      id: 1,
      name: "All",
      selected: "true",
    },
    {
      id: 2,
      name: "This Week",
      selected: "false",
    },
    {
      id: 3,
      name: "This Month",
      selected: "false",
    },
    {
      id: 4,
      name: "This Year",
      selected: "false",
    },
  ]);

  const handleSelectChange = (name, value) => {
    let startDateValue = "";
    let endDateValue = "";

    const today = moment();
    const currentYear = today.year();
    const currentMonth = today.month();
    const currentDay = today.date();

    switch (value?.name) {
      case "This Week":
        const startOfWeek = moment().startOf("week");
        startDateValue = startOfWeek.format("YYYY-MM-DD");
        endDateValue = today.format("YYYY-MM-DD");
        break;

      case "This Month":
        const firstDayOfMonth = moment().startOf("month");
        const lastDayOfMonth = moment().endOf("month");
        startDateValue =
          currentDay === lastDayOfMonth.date()
            ? moment().startOf("month").add(1, "days").format("YYYY-MM-DD")
            : firstDayOfMonth.format("YYYY-MM-DD");
        endDateValue = today.format("YYYY-MM-DD");
        break;

      case "This Year":
        const firstDayOfYear = moment().startOf("year");
        const lastDayOfYear = moment().endOf("year");
        startDateValue =
          currentMonth === 11 && currentDay === 31
            ? moment().startOf("year").add(1, "year").format("YYYY-MM-DD")
            : firstDayOfYear.format("YYYY-MM-DD");
        endDateValue = today.format("YYYY-MM-DD");
        break;

      default:
        break;
    }

    const updatedData = data.map((option) => ({
      ...option,
      selected: option.name === value?.name ? "true" : "false",
    }));

    const selectedOption = updatedData.find(
      (option) => option.selected === "true"
    );

    setSelectedOptions(value);
    setData(updatedData);
    setStartDate(startDateValue);
    setEndDate(endDateValue);
  };

  useEffect(() => {
    if (selectedOptions?.name === "All") {
      setAll(true);
      setThisWeek(false);
      setThisMonth(false);
      setThisYear(false);
    } else if (selectedOptions?.name === "This Week") {
      setAll(false);
      setThisWeek(true);
      setThisMonth(false);
      setThisYear(false);
    } else if (selectedOptions?.name === "This Month") {
      setAll(false);
      setThisWeek(false);
      setThisMonth(true);
      setThisYear(false);
    } else if (selectedOptions?.name === "This Year") {
      setAll(false);
      setThisWeek(false);
      setThisMonth(false);
      setThisYear(true);
    }
  }, [selectedOptions]);

  const All = all;
  const week = thisWeek;
  const month = thisMonth;
  const year = thisYear;

  const _getGraphData = async () => {
    try {
      setLoading(true);
      const body = `?startDate=${startDate}&endDate=${endDate}&All=${All}&thisweek=${week}&thisMonth=${month}&thisYear=${year}`;
      const { data } = await Api.getRequest(
        `api/user/getPartnerStatistics${body}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setGraphData(req?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    _getGraphData();
  }, [all, thisWeek, thisMonth, thisYear]);

  useEffect(() => {
    if (startDate && endDate) {
      const startDateMoment = moment(startDate);
      const endDateMoment = moment(endDate);
      if (endDateMoment.isSameOrAfter(startDateMoment)) {
        _getGraphData();
      } else {
        WarningToast("End date is not greater than the start date");
        setEndDate("");
      }
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (!graphData) return;
    const [firstKey] = Object.keys(graphData?.bookOrderCounts);
    const keys = Object.keys(graphData?.bookOrderCounts);
    const lastKey = keys[keys.length - 1];
    const bookOrderCounts = Object.values(graphData?.bookOrderCounts);
    const bookOrderAmountCounts = Object.values(
      graphData?.bookOrderAmountCounts
    );
    const chartData1 = {
      title: {
        text: "",
        align: "left",
      },
      subtitle: {
        text: "",
        align: "left",
      },
      yAxis: {
        title: {
          text: "Number of Counts",
        },
      },
      xAxis: {
        categories: Object.keys(graphData.bookOrderCounts), // Use the years as categories
        accessibility: {
          rangeDescription: `Range: ${firstKey} to ${lastKey}`,
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
      },
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
        },
      },
      series: [
        {
          name: "Book Order Counts",
          data: bookOrderCounts,
        },
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              },
            },
          },
        ],
      },
    };
    const chartData2 = {
      title: {
        text: "",
        align: "left",
      },
      subtitle: {
        text: "",
        align: "left",
      },
      yAxis: {
        title: {
          text: "Number of Counts",
        },
      },
      xAxis: {
        categories: Object.keys(graphData.bookOrderCounts), // Use the years as categories
        accessibility: {
          rangeDescription: `Range: ${firstKey} to ${lastKey}`,
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
      },
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
        },
      },
      series: [
        {
          name: "Book Order Amount Counts",
          data: bookOrderAmountCounts,
          color: "#885BDA",
        },
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              },
            },
          },
        ],
      },
    };
    try {
      Highcharts.chart("container1", chartData1);
      Highcharts.chart("container2", chartData2);
    } catch (error) {
      console.error("Error initializing Highcharts:", error);
    }
  }, [graphData]);

  const handleClick = (event) => {
    setAnchorElEnd(null);
    setAnchorEl(event.currentTarget);
  };

  const handleClickEnd = (event) => {
    setAnchorEl(null);
    setAnchorElEnd(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElEnd(null);
    setAnchorEl(null);
  };

  const handleCloseEnd = () => {
    setAnchorElEnd(null);
    setAnchorEl(null);
  };

  const handleSaveDateStart = (value) => {
    if (endDate) {
      const maxEndDate = moment(endDate).subtract(3, "months");
      if (moment(value).isAfter(maxEndDate)) {
        const formattedStartDate = moment(value).format("YYYY-MM-DD");
        setStartDate(formattedStartDate);
      } else {
        WarningToast(
          "Start date must be within three months before the end date."
        );
      }
    } else {
      const formattedStartDate = moment(value).format("YYYY-MM-DD");
      setStartDate(formattedStartDate);
    }
  };

  const handleSaveDateEnd = (value) => {
    if (startDate) {
      const maxEndDate = moment(startDate).add(3, "months");
      if (moment(value).isBefore(maxEndDate)) {
        const formattedEndDate = moment(value, "YYYY-MM-DD").format(
          "YYYY-MM-DD"
        );
        setEndDate(formattedEndDate);
      } else {
        WarningToast("End date must be within three months of the start date.");
      }
    } else {
      WarningToast("End date cannot be before start date");
    }
  };

  useEffect(() => {
    setSelectedOptions({
      id: 1,
      name: "All",
      selected: "true",
    });
  }, []);

  return (
    <>
      <div className="w-full sm:px-6 overflow-x-hidden overflow-y-auto">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <MasterTitle
              title="Dashboard"
              description="Below are all the details of dashboard."
            />
            {/* <Breadcrumbs title="/ Dashboard" /> */}
          </div>
          <div>
            <div className="flex items-center justify-end lg:space-x-2 gap-2">
              <div className="w-full py-6 flex items-center">
                <Typography
                  sx={{ pr: 1, pl: 1, color: "#000000", fontWeight: "600" }}
                >
                  From
                </Typography>
                <Box
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={(event) => handleClick(event)}
                  sx={{
                    background: "#FFFFFF", // Change background color to white
                    borderRadius: "8px",
                    width: "9rem",
                    padding: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                >
                  <Typography sx={{ color: "#000000", fontSize: "14px" }}>
                    {startDate || "YYYY-MM-DD"}
                  </Typography>
                  <img src={Calander} width="20" height="20" />
                </Box>
                <Typography
                  sx={{ pr: 1, pl: 1, color: "#000000", fontWeight: "600" }}
                >
                  To
                </Typography>
                <Box
                  aria-controls={openEnd ? "basic-menu-end" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openEnd ? "true" : undefined}
                  onClick={(event) => handleClickEnd(event)}
                  sx={{
                    background: "#FFFFFF",
                    borderRadius: "8px",
                    width: "9rem",
                    padding: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                >
                  <Typography sx={{ color: "#000000", fontSize: "14px" }}>
                    {endDate || "YYYY-MM-DD"}
                  </Typography>
                  <img src={Calander} width="20" height="20" />
                </Box>
                {open && (
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <DateEditCalendar
                      handleCloseCalendar={handleClose}
                      saveTheDate={handleSaveDateStart}
                      date={startDate}
                    />
                  </Menu>
                )}
                {openEnd && (
                  <Menu
                    id="basic-menu-end"
                    anchorEl={anchorElEnd}
                    open={openEnd}
                    onClose={handleCloseEnd}
                  >
                    <DateEditCalendar
                      handleCloseCalendar={handleCloseEnd}
                      saveTheDate={handleSaveDateEnd}
                    />
                  </Menu>
                )}
              </div>
              <div>
                <Selected
                  id=""
                  value={selectedOptions}
                  name="Search"
                  type="thisYear"
                  placeholder="This month"
                  autoComplete="given-name"
                  data={data}
                  onChange={(e) => handleSelectChange("thisYear", e)}
                  className="mb-2 font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3] w-full pr-24 mb-4"
                  isthisYear={true}
                />
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <div
            className="flex items-center justify-center"
            style={{
              height: "30rem",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="flex flex-col lg:flex-row lg:space-x-6">
              <div className="flex flex-row w-full lg:w-1/2 shadow-sm rounded-md overflow-hidden bg-white p-4">
                <span style={{ color: "#344767", marginRight: "3px" }}>
                  Total Book Order Count:
                </span>
                {graphData?.totalRevenue?.bookOrderCount.toFixed(2)}
              </div>
              <div className="flex flex-row w-full lg:w-1/2 shadow-sm rounded-md overflow-hidden bg-white p-4">
                <span style={{ color: "#344767", marginRight: "3px" }}>
                  Total Book Order Amount Counts:
                </span>
                {graphData?.totalRevenue?.amount.toFixed(2)}
              </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:space-x-6">
              <div className="flex flex-col w-full lg:w-1/2 mt-6 shadow-sm rounded-md overflow-hidden bg-white p-4">
                <div
                  id="container1"
                  style={{ width: "100%", height: "400px" }}
                ></div>
              </div>
              <div className="flex flex-col w-full lg:w-1/2 mt-6 shadow-sm rounded-md overflow-hidden bg-white p-4">
                <div
                  id="container2"
                  style={{ width: "100%", height: "400px" }}
                ></div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PartnerDashboard;
