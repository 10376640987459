import React, { useEffect, useRef, useState } from "react";
import logo from "../Image/logo.png";
import ProfilePicture from "../Image/ProfilePicture.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  ArrowLeftIcon,
  ArrowLongDownIcon,
  ArrowRightCircleIcon,
  BanknotesIcon,
  BellAlertIcon,
  BookOpenIcon,
  CalculatorIcon,
  ChartBarIcon,
  ClipboardDocumentListIcon,
  ClipboardIcon,
  ComputerDesktopIcon,
  DevicePhoneMobileIcon,
  DocumentPlusIcon,
  QuestionMarkCircleIcon,
  ShoppingBagIcon,
  SquaresPlusIcon,
  ClockIcon,
  UserIcon,
  CurrencyRupeeIcon,
  TagIcon,
  MegaphoneIcon,
} from "@heroicons/react/24/solid";
import ApproveRejectIcon from "../Image/ApproveQuestion.svg";
import { SuccessToast } from "./toast";
import { Tooltip } from "@mui/material";
import { getDecryptedDataFromLocalStorage } from "../Api/encryption";

let userData = getDecryptedDataFromLocalStorage("userData");

const ApproveRejectImageIcon = () => (
  <img src={ApproveRejectIcon} alt="Approve" className="w-5 h-5" />
);
let profile = [
  {
    name: "Equation",
    href: "/equation",
    icon: BanknotesIcon,
  },
  {
    name: "Maths",
    href: "/maths",
    icon: CalculatorIcon,
  },
];

let navigation = [
  {
    name: "Dashboard",
    href: "/dashboard",
    icon: SquaresPlusIcon,
  },
  {
    name: "Master",
    href: "/master/board",
    icon: ComputerDesktopIcon,
  },
  {
    name: "Promocode",
    href: "/promo-code",
    icon: TagIcon,
  },
  // {
  //   name: "Subscription Price",
  //   href: "/subscription-price",
  //   icon: CurrencyRupeeIcon,
  // },
  {
    name: "Topic",
    href: "/topic",
    icon: ArrowRightCircleIcon,
  },
  {
    name: "Question",
    href: "/question",
    icon: QuestionMarkCircleIcon,
  },
  {
    name: "Approve Question",
    href: "/all-question/pending-question",
    icon: ApproveRejectImageIcon,
  },
  {
    name: "Advertisement",
    href: "/advertisement/learning-images",
    icon: MegaphoneIcon,
  },
  {
    name: "Notification",
    href: "/notification",
    icon: BellAlertIcon,
  },
  {
    name: "Shop",
    href: userData?.type === "partner" ? "/shop/book-history" : "/shop/books",
    icon: ShoppingBagIcon,
  },
  // {
  //   name: "Add New History",
  //   href: "/addNewHistory/order-history-details",
  //   icon: DocumentPlusIcon,
  // },
  // {
  //   name: "Register User",
  //   href: "/registered-user/registered-user-comment",
  //   icon: UserIcon,
  // },
  // {
  //   name: "Notice",
  //   href: "/notice",
  //   icon: ClipboardDocumentListIcon,
  // },
  {
    name: "Paper",
    href: "/paper-generation/order-history",
    icon: ClipboardIcon,
  },
  // {
  //   name: "RTO",
  //   href: "/RTO",
  //   icon: NoSymbolIcon,
  // },

  // {
  //   name: "Student App",
  //   href: "/student-app/app-registered-users",
  //   icon: DevicePhoneMobileIcon,
  // },
  // {
  //   name: "Standard App",
  //   href: "/standard-app/notification",
  //   icon: DevicePhoneMobileIcon,
  // },
  {
    name: "Report",
    href: "/report",
    icon: ChartBarIcon,
  },
  {
    name: "Access",
    href: "/access/admin",
    icon: DevicePhoneMobileIcon,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DashboardLayout(props) {
  const location = useLocation();
  const navigate = useNavigate();

  function updateIsActiveStatusNavigation() {
    navigation.forEach((navItem) => {
      const matchingAccessItem = props?.access?.find(
        (accessItem) => accessItem?.name === navItem?.name
      );
      if (matchingAccessItem && matchingAccessItem.view) {
        navItem.isActive = true;
      } else {
        navItem.isActive = false;
      }
    });
  }

  // Call the function to update the 'isActive' property
  updateIsActiveStatusNavigation();

  function updateIsActiveStatusForProfile() {
    profile.forEach((navItem) => {
      const matchingAccessItem = props?.access?.find(
        (accessItem) => accessItem?.name === navItem?.name
      );
      if (matchingAccessItem && matchingAccessItem.view) {
        navItem.isActive = true;
      } else {
        navItem.isActive = false;
      }
    });
  }

  // Call the function to update the 'isActive' property
  updateIsActiveStatusForProfile();

  return (
    <>
      <div>
        {/* DESKTOP  */}
        <div className="flex w-24 flex-col fixed inset-y-0 overflow-y-auto mt-2 mb-2 ml-1 rounded-xl bg-gradient-to-b from-[#202020] to-[#202020]">
          <div className="flex justify-center bg-gradient-to-b from-[#202020] to-[#202020] h-full">
            <div className="mt-5 flex flex-col  justify-between">
              <div className="">
                <div className="flex justify-center mb-3 border-b py-4">
                  <Link to="/dashboard">
                    <img
                      src={logo}
                      className=""
                      alt=""
                      height={20}
                      width={50}
                    />
                  </Link>
                </div>
                <div className="">
                  <nav className="flex-1 px-2 pb-3 space-y-3 ">
                    {navigation.map((item) => {
                      const currentPathnameFirstWord =
                        location.pathname.split("/")[1]; // Get the first part of the pathname
                      const itemHrefFirstWord = item.href.split("/")[1]; // Get the first part of the item's href
                      return (
                        item?.isActive && (
                          <Tooltip
                            key={item?.name}
                            title={item?.name}
                            placement="right"
                          >
                            <Link
                              to={item.href}
                              // onClick={(e) => {
                              //   e.preventDefault(); // Prevent default behavior
                              //   navigate(item.href);
                              // }}
                              className={classNames(
                                currentPathnameFirstWord === itemHrefFirstWord
                                  ? "text-white bg-gradient-to-b from-[#4296ec] to-[#396de5] flex items-center justify-center px-2 py-3 text-sm font-medium rounded-md"
                                  : "text-white hover:bg-gradient-to-b from-[#4296ec] to-[#396de5] flex items-center justify-center px-2 py-3 text-sm font-medium rounded-md"
                              )}
                            >
                              <item.icon
                                className={classNames(
                                  "flex-shrink-0 justify-center text-white w-5"
                                )}
                                aria-hidden="true"
                              />
                            </Link>
                          </Tooltip>
                        )
                      );
                    })}
                  </nav>
                </div>
              </div>
              <div className="">
                <div className="flex border-t border-gray-200 py-2">
                  <Tooltip title="Profile" placement="right" key={"profile"}>
                    <p
                      style={{
                        color: "white",
                        fontSize: "10px",
                        fontFamily: "sans-serif",
                        textTransform: "Capitalize",
                      }}
                    >
                      {userData?.username}
                    </p>
                  </Tooltip>
                </div>
                <div className="flex  border-gray-200 px-4 py-4">
                  <div className="flex items-center">
                    <Tooltip title="LogOut" placement="right" key={"logout"}>
                      <div
                        className=" w-full"
                        onClick={() => {
                          localStorage.clear();
                          navigate("/login");
                          SuccessToast("Successfully Logged out");
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6 flex-shrink-0 justify-center text-white cursor-pointer"
                        >
                          <path
                            fillRule="evenodd"
                            d="M7.5 3.75A1.5 1.5 0 006 5.25v13.5a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5V15a.75.75 0 011.5 0v3.75a3 3 0 01-3 3h-6a3 3 0 01-3-3V5.25a3 3 0 013-3h6a3 3 0 013 3V9A.75.75 0 0115 9V5.25a1.5 1.5 0 00-1.5-1.5h-6zm5.03 4.72a.75.75 0 010 1.06l-1.72 1.72h10.94a.75.75 0 010 1.5H10.81l1.72 1.72a.75.75 0 11-1.06 1.06l-3-3a.75.75 0 010-1.06l3-3a.75.75 0 011.06 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </Tooltip>
                  </div>
                </div>
                {/* <div>
                  {profile.map((item) => {
                    return (
                      item?.isActive && (
                        <div className="flex-1 px-2 py-2" key={item.name}>
                          <a
                            href={item.href}
                            className={classNames(
                              location.pathname === item.href
                                ? "text-white bg-gradient-to-b from-[#4296ec] to-[#396de5]  group flex items-center justify-center px-2 py-3 text-sm font-medium rounded-md"
                                : "text-white hover:bg-gradient-to-b from-[#4296ec] to-[#396de5] group flex items-center justify-center px-2 py-3 text-sm font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className={classNames(
                                "flex justify-center text-white w-5 h-5"
                              )}
                              aria-hidden="true"
                            />
                          </a>
                        </div>
                      )
                    );
                  })}
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="pl-24 xl:pl-24 flex-col flex-1 h-full">
          <main>
            <div className="py-2 ">
              <div className="w-full mx-auto">{props.children}</div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
