import { ChapterActions } from "../action-types/chapterActionType";

export const getChapterList = (payload) => {
  return {
    type: ChapterActions.GET_CHAPTER_DATA,
    payload,
  };
};

export const setChapterList = (payload) => {
  return {
    type: ChapterActions.SET_CHAPTER_DATA,
    payload,
  };
};