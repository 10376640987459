import { QuestionActions } from "../action-types/QuestionActionType";

const initialState = {
  qtypeData: [],
  error: null,
};

const questionReducer = (state = initialState, action) => {
  switch (action.type) {
    case QuestionActions.FETCH_QTYPE_REQUEST:
      return {
        ...state,
        error: null,
      };
    case QuestionActions.FETCH_QTYPE_SUCCESS:
      return {
        ...state,
        qtypeData: action.payload,
      };
    case QuestionActions.FETCH_QTYPE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default questionReducer;
