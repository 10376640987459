import { HomeIcon } from "@heroicons/react/20/solid";
import React from "react";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ title = "", subTitle = " " }) => {
  return (
    <div>
      <nav className="flex">
        <ol className="inline-flex items-center space-x-1 md:space-x-3 text-[#7B809A] text-sm font-regular font-roboto">
          <li className="inline-flex items-center">
            <div className="inline-flex items-center">
              <HomeIcon className="w-4 h-4" />
            </div>
          </li>
          <li>
            <div className="flex items-center text-[#344767]">
              <div className="ml-1">
                {title}
              </div>
            </div>
          </li>
          <li aria-current="page">
            <div className="flex items-center">
              <span className="text-[#344767]">{subTitle}</span>
            </div>
          </li>
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumbs;
