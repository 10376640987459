import React from "react";

const MasterTitle = ({ title, description }) => {
  return (
    <div>
      <h1 className="text-[#344767] font-semibold text-xl font-poppins">
        {title}
      </h1>
      <p className="text-[#7B809A] font-regular text-sm mt-1 font-poppins">
        {description}
      </p>
    </div>
  );
};

export default MasterTitle;
