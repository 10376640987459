import loginImage from "../Image/loginimg.png";
export function AuthLayout({ children }) {
  return (
    <>
      <div className="relative flex min-h-screen justify-center md:px-12 lg:px-0">
        <div className="absolute inset-0 hidden w-full flex-1 sm:block lg:relative lg:w-0">
          <span className="box-border block overflow-hidden	w-[initial] h-[initial] bg-none	opacity-100	border-0 m-0 p-0 absolute top-0 left-0 bottom-0 right-0">
            <img
              className="absolute top-0 left-0 bottom-0 right-0 box-border p-0 border-0 m-auto block w-0 h-0 min-w-full max-w-full min-h-full max-h-full object-cover"
              src={loginImage}
              alt=""
            />
          </span>
        </div>
        <div className="relative z-10 flex flex-1 flex-col justify-center bg-white py-12 px-4 shadow-2xl md:flex-none md:px-28">
          <div className="mx-auto w-full max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0">
            {children}
          </div>
        </div>
      </div>
    </>
  );
}
