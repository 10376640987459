import { TopicActions } from "../action-types/topicActionType";

export const getTopicList = (payload) => {
  return {
    type: TopicActions.GET_TOPIC_DATA,
    payload,
  };
};

export const setTopicList = (payload) => {
  return {
    type: TopicActions.SET_TOPIC_DATA,
    payload,
  };
};