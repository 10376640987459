import Pagination from "./Pagination";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  ChevronUpDownIcon,
  RectangleStackIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Button } from "./Button";
import Model from "./Model";
import { Input } from "./Input";
import MasterTitle from "./MasterTitle";
import { Checkbox } from "./Checkbox";
import { useNavigate } from "react-router-dom";
import Records from "./Records";
import { SortData } from "./SortData";
import Select from "./Select";
import { PlusIcon } from "@heroicons/react/20/solid";
import DeletePopup from "./DeletePopup";
import ActionPopup from "./ActionPopup";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast } from "./toast";
import { Typography } from "@mui/material";
import { useDebounce } from "../hooks/use-debounce";
import NoDataFound from "./NoDataFound";
import CircularProgress from "@mui/material/CircularProgress";
import ActionButton from "./ActionButton";

const GenerateNotice = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Master")?.[0];
  const [isModelLoader, setisModelLoader] = useState(false);
  const [isModel, setIsModel] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [modelType, setModelType] = useState("");
  const cancelButtonRef = useRef(null);
  const [error, setError] = useState({});
  const [search, setSearch] = useState("");
  const debouncedValue = useDebounce(search, 800);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [noticeCount, setNoticeCount] = useState(0);
  const [boardData, setBoardData] = useState([]);
  const [noticeData, setNoticeData] = useState([]);
  const [selectedQue, setSelectedQue] = useState([]);

  const [qtypeLimit, setQtypeLimit] = useState(1);
  const [generateNoticeId, setGenerateNoticeId] = useState(null);
  const [qTypeData, setQTypeData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [deleteType, setDeleteType] = useState("single");
  const [generateNotice, setGenerateNotice] = useState({
    mark: "",
    questionType: "",
    position: "",
    boardNotices: [],
  });
const [getByQtype,setGetByQtype] = useState([])
  const validation = () => {
    let errors = {};
    let formIsValid = true;
    if (!generateNotice?.mark) {
      formIsValid = false;
      errors["mark"] = "Mark is required.";
    } else if (
      isNaN(Number(generateNotice?.mark)) ||
      Number(generateNotice?.mark) < 0
    ) {
      formIsValid = false;
      errors["markint"] = "Please Enter a non-negative number for The Mark";
    }
    if (!generateNotice?.questionType) {
      formIsValid = false;
      errors["qtype"] = "Please select Question-Type.";
    }

    if (!generateNotice?.position) {
      formIsValid = false;
      errors["position"] = "Position is required.";
    } else if (
      isNaN(Number(generateNotice.position)) ||
      Number(generateNotice.position) < 0
    ) {
      formIsValid = false;
      errors["numberposition"] =
        "Please Enter a non-negative number for Position";
    }

    generateNotice?.boardNotices.forEach((boardNotice, index) => {
      const boardNoticeKey = `boardNotices[${boardNotice?.medium_id}].notice`;

      if (!boardNotice?.notice && boardNotice?.medium_id) {
        formIsValid = false;
        errors[boardNoticeKey] = `This Notice is required.`;
      }
    });

    setError(errors);
    return formIsValid;
  };

  const getAllgeneratedNotice = async (search, limit, offset) => {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/random_generate_notice?offset=${offset}&limit=${limit}&search=${search}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setNoticeData(req?.data);
        setNoticeCount(req?.totalCount);
        setIsLoader(false);
      } else {
        ErrorToast(req?.message);
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const getAllBoards = async () => {
    try {
      const { data } = await Api.getRequest(`api/board/getAllWithMedium`);
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setBoardData(req?.data);
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    } finally {
    }
  };

  useEffect(() => {
    getAllBoards();
  }, []);
  
  useEffect(()=>{
    getQTypeData(1);
  },[isModel])

  useEffect(() => {
    if (modelType === "edit") {
      const getSingleGenerateNotice = async () => {
        setisModelLoader(true);
        try {
          const { data } = await Api.getRequest(
            `api/random_generate_notice/getRandomGenerateNoticeByID/${generateNoticeId}`
          );
          const req = JSON.parse(data);
          if (req?.status === 200) {
            setisModelLoader(false);
            let Notice;

            if (typeof req?.data?.notice_type === "string") {
              Notice = JSON.parse(req?.data?.notice_type);
            } else {
              Notice = req?.data?.notice_type;
            }
            const updatedBoardNotices = Notice.filter((notice) => {
              const board = boardData.find(
                (board) => board.id === notice.board_id
              );
              if (!board) return false;

              return board.medium_info.some(
                (medium) => medium.id === notice.medium_id
              );
            }).map((notice) => ({
              board_id: notice.board_id,
              notice: notice.notice,
              medium_id: notice.medium_id,
            }));

            setGenerateNotice((prevState) => ({
              ...prevState,
              mark: req?.data?.mark,
              position: req?.data?.position,
              questionType: req?.data?.qtypeInfo,
              boardNotices: updatedBoardNotices,
            }));
            setGetByQtype(req?.data?.qtypeInfo);
          } else {
            ErrorToast(req?.message);
          }
        } catch (error) {
          ErrorToast(error?.message);
        } finally {
          setModelType("");
        }
      };
      getSingleGenerateNotice();
    }
  }, [modelType, generateNoticeId]);

  useEffect(() => {
    getAllgeneratedNotice(search, recordsPerPage, currentPage);
  }, [debouncedValue, currentPage, recordsPerPage]);

  const getQTypeData = async (l) => {
    try {
      let body = `?offset=${l ? l : qtypeLimit}&limit=${10}`;
      setQtypeLimit(l ? l : qtypeLimit + 1);
      const { data } = await Api.getRequest(`api/qtype/Qtype${body}`);
      const req = JSON.parse(data);
      setQTypeData((prevData) => [...prevData, ...req?.data]);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const handleNoticeChange = (name, value, type) => {
    setGenerateNotice((oldValue) => ({
      ...oldValue,
      [name]: value,
      [type]: value,
    }));
  };

  const generatedBoardNotices = useMemo(() => {
    if (!boardData || boardData.length === 0) return [];

    return boardData.flatMap((board) =>
      board.medium_info.map((medium) => ({
        board_id: board.id,
        notice: "",
        medium_id: medium.id,
      }))
    );
  }, [boardData]);

  useEffect(() => {
    setGenerateNotice((prevGenerateNotice) => ({
      ...prevGenerateNotice,
      boardNotices: generatedBoardNotices,
    }));
  }, [generatedBoardNotices]);

  const handleBoardChange = (boardId, mediumId, value) => {
    const updatedBoardNotices = [...generateNotice.boardNotices];
    const index = updatedBoardNotices.findIndex(
      (boardNotice) =>
        boardNotice.board_id === boardId && boardNotice.medium_id === mediumId
    );
    if (index !== -1) {
      updatedBoardNotices[index] = {
        ...updatedBoardNotices[index],
        notice: value,
      };
    } else {
      updatedBoardNotices.push({
        notice: value,
        board_id: boardId,
        medium_id: mediumId,
      });
    }

    // Update the generateNotice state with the updated board notices
    setGenerateNotice((prevState) => ({
      ...prevState,
      boardNotices: updatedBoardNotices,
    }));
  };

  const addorEditnotice = async () => {
    let body = {
      mark: generateNotice.mark,
      qtype: generateNotice?.questionType?.id,
      position: generateNotice.position,
      notice_type: generateNotice?.boardNotices,
    };

    if (validation()) {
      if (modelType === "add") {
        try {
          const { data } = await Api.postRequest(
            `api/random_generate_notice/create`,
            body
          );
          const req = JSON.parse(data);
          if (req?.status === 200) {
            SuccessToast(req?.message);
            getAllgeneratedNotice(search, recordsPerPage, currentPage);
          } else {
            ErrorToast(req?.message);
          }
        } catch (error) {
          ErrorToast(error?.message);
        } finally {
        }
      } else {
        try {
          const { data } = await Api.putRequest(
            `api/random_generate_notice/update/${generateNoticeId}`,
            body
          );
          if (data?.status === 200) {
            SuccessToast(data?.message);
            getAllgeneratedNotice(search, recordsPerPage, currentPage);
          } else {
            ErrorToast(data?.message);
          }
        } catch (error) {
          ErrorToast(error?.message);
        } finally {
          setGenerateNoticeId(null);
        }
      }
      resetgenerateNotice();
    }
  };

  const resetgenerateNotice = () => {
    setGenerateNotice({
      mark: "",
      questionType: "",
      position: "",
      boardNotices: [],
      // boardNotices: boardData?.map((o) => ({ board_id: o?.id, notice: "" })),
    });

    setError({});
    setIsModel(false);
  };

  const [titleData, setTitleData] = useState([
    {
      title: "QUESTION TYPE",
      sort: 1,
      fieldName: "question type",
    },
    {
      title: "MARK",
      sort: 1,
      fieldName: "mark",
    },
    {
      title: "POSITION",
      sort: 1,
      fieldName: "position",
    },
    {
      title: access?.edit ? "EDIT" : "",
      sort: 1,
      fieldName: "edit",
    },
    {
      title: access?.delete ? "DELETE" : "",
      sort: 1,
      fieldName: "delete",
    },
  ]);

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) => {
      const isChecked = prevState.includes(id);

      const updatedItems = isChecked
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id];

      return updatedItems;
    });
  };

  const handleDeleteNotice = async () => {
    try {
      let data;
      // const { data } = await Api.postRequest(
      //   `api/random_generate_notice/delete/${generateNoticeId}`
      // );
      if (deleteType === "multi") {
        const body = {
          ids: checkedItems,
        };
        const response = await Api.postRequest(
          `api/random_generate_notice/delete`,
          body
        );
        data = response.data;
      } else {
        const body = {
          ids: [generateNoticeId],
        };
        const response = await Api.postRequest(
          `api/random_generate_notice/delete`,
          body
        );
        data = response.data;
      }

      const req = JSON.parse(data);
      if (req?.status === 200) {
        SuccessToast(req?.message);
        getAllgeneratedNotice(search, recordsPerPage, currentPage);
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error.message);
    } finally {
      setGenerateNoticeId(null);
    }
  };
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(noticeCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  return (
    <div>
      <div className="flex justify-between items-center mt-2">
        <div>
          <MasterTitle
            title="Random Generate Notice"
            description="Below are the list of all the random generation notice."
          />
        </div>

        <div className="flex items-center space-x-2">
          {selectedQue.length > 0 && (
            <div>
              <Button
                isLight
                id=""
                type="submit"
                icon={TrashIcon}
                label={"MULTI DELETE"}
                abc="bg-[#D11212]"
              />
            </div>
          )}

          {/* {access?.add && (
            <div>
              <Button
                id=""
                type="submit"
                icon={RectangleStackIcon}
                label={"MANAGE BOARDS"}
                onClick={() =>
                  navigate("/master/random-generate-notice/manage-board")
                }
              />
            </div>
          )} */}
          <div>
            <div style={{ display: "flex", gap: "5px" }}>
              {access?.delete && (
                <Button
                  id=""
                  type="submit"
                  icon={TrashIcon}
                  label={"DELETE BOARD"}
                  onClick={() => {
                    setDeleteType("multi");
                    if (checkedItems.length > 0) {
                      setOpenModel(true);
                    }
                  }}
                />
              )}
              {access?.add && (
                <Button
                  id=""
                  type="submit"
                  icon={PlusIcon}
                  label={"ADD NOTICE"}
                  onClick={() => {
                    setIsModel(true);
                    setModelType("add");
                  }}
                />
              )}
            </div>
            <Model
              title={
                modelType === "add"
                  ? "Add Random Generate Notice"
                  : "Edit Random Generate Notice"
              }
              show={isModel}
              onCancel={() => {
                // setGenerateNotice({
                //   mark: "",
                //   questionType: "",
                //   position: "",
                //   boardNotices: boardData?.map((o) => ({
                //     board_id: o?.id,
                //     notice: "",
                //   })),
                // });
                setIsModel(false);
              }}
              onClose={() => {
                setIsModel(false);
                resetgenerateNotice();
                setGetByQtype({});
              }}
              onOk={() => {
                addorEditnotice();
              }}
              okText="SUBMIT"
              cancelText="CLOSE"
              width="max-w-3xl"
            >
              {isModelLoader ? (
                <tr
                  style={{
                    height: "120px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <td>
                    <CircularProgress />
                  </td>
                </tr>
              ) : (
                <>
                  <div className="flex items-center  flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6 ">
                    <div className="w-full">
                      <Select
                        data={(Object.keys(getByQtype).length > 0  ? [getByQtype] : []).concat(qTypeData) || []}
                        label="Question Type"
                        value={generateNotice?.questionType}
                        onChange={(e) =>
                          handleNoticeChange("questionType", e, "questionType")
                        }
                        required
                        name="questionType"
                        type={"qType"}
                        placeholder="All Q.Type"
                        getQType={getQTypeData}
                      />

                      <Typography variant="caption" color={"error"}>
                        {!generateNotice?.questionType ? error?.qtype : ""}
                      </Typography>
                    </div>
                    <div className="w-full ">
                      <Input
                        label="Mark"
                        id="mark"
                        name="mark"
                        type="text"
                        onChange={(e) =>
                          handleNoticeChange("mark", e.target.value, "mark")
                        }
                        value={generateNotice?.mark}
                        autoComplete="given-name"
                        required
                        className="w-full mb-3"
                      />

                      <Typography variant="caption" color={"error"}>
                        {!generateNotice?.mark ? error?.mark : ""}
                      </Typography>
                      <Typography variant="caption" color={"error"}>
                        {isNaN(Number(generateNotice?.mark)) ||
                        Number(generateNotice.mark) < 0
                          ? error?.markint
                          : ""}
                      </Typography>
                    </div>
                  </div>

                  <div className="w-full">
                    <Input
                      label="Position"
                      id="position"
                      name="position"
                      onChange={(e) =>
                        handleNoticeChange(
                          "position",
                          e.target.value,
                          "position"
                        )
                      }
                      value={generateNotice?.position}
                      type="text"
                      autoComplete="given-name"
                      required
                      className="w-full"
                    />
                    <Typography variant="caption" color={"error"}>
                      {!generateNotice?.position ? error?.position : ""}
                    </Typography>
                    <Typography variant="caption" color={"error"}>
                      {isNaN(Number(generateNotice.position)) ||
                      Number(generateNotice.position) < 0
                        ? error?.numberposition
                        : ""}
                    </Typography>
                  </div>

                  <div className="mt-4">
                    <div className="flex flex-wrap justify-between mb-4  w-[98%]">
                      {boardData &&
                        boardData?.length > 0 &&
                        boardData?.map((board, index) => (
                          <div
                            key={board?.id}
                            className="w-full sm:w-1/2 mb-4 sm:mb-0 mt-4"
                          >
                            <label
                              htmlFor={index}
                              className="mb-3 block text-sm font-medium text-[#252F40] font-poppins"
                            >
                              {board?.board_name}
                              <span className="text-red-500"> *</span>
                            </label>

                            {board.medium_info &&
                              board.medium_info.length > 0 &&
                              board.medium_info.map((medium, mediumIndex) => (
                                <div
                                  key={medium?.id}
                                  className="mb-4"
                                  style={{ gap: 3, maxWidth: "340px" }}
                                >
                                  <div className="relative">
                                    <input
                                      name={medium.name}
                                      type="text"
                                      className="block w-full appearance-none rounded-md border border-gray-200 px-3 py-2 text-gray-900 placeholder-[#A0AEC0] focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                                      value={
                                        generateNotice?.boardNotices?.find(
                                          (notice) =>
                                            notice.board_id === board.id &&
                                            notice.medium_id === medium?.id
                                        )?.notice || ""
                                      }
                                      placeholder={`${medium.name} Notice here...`}
                                      onChange={(e) =>
                                        handleBoardChange(
                                          board?.id,
                                          medium?.id,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <Typography variant="caption" color={"error"}>
                                    {error?.[
                                      `boardNotices[${medium.id}].notice`
                                    ] || ""}
                                  </Typography>
                                </div>
                              ))}
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              )}
            </Model>
          </div>
        </div>
      </div>
      <div className="sm:mb-0 overflow-hidden ">
        <div className="flex flex-col mt-4">
          <section
            className="bg-white rounded-2xl flex flex-col shadow-sm "
            style={{
              width: "100%",
              height: "75vh",
              margin: "0 auto",
              padding: "20px",
            }}
          >
            {" "}
            <div className="flex justify-between items-center px-8 mb-3">
              <div>
                {/* <Input
                id="search"
                name="Search"
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Search Question type here..."
                autoComplete="given-name"
                required
                className="w-72"
              /> */}
              </div>
              <div>
                <Records
                  setCurrentPage={setCurrentPage}
                  recordsPerPage={recordsPerPage}
                  setRecordsPerPage={setRecordsPerPage}
                  dataCount={noticeCount}
                />
              </div>
            </div>
            <div
              style={{
                overflowY: "auto",
                maxHeight: "58vh",
                height: "60vh",
                width: "100%",
              }}
            >
              {" "}
              {!isLoader && noticeData?.length <= 0 ? (
                <NoDataFound />
              ) : (
                <table style={{ width: "100%" }}>
                  <thead
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#f4f7f8",
                      zIndex: 1,
                    }}
                  >
                    <tr
                      style={{
                        borderBottom: "1px solid #dddddd",
                        textAlign: "left",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#7B809A",
                        textTransform: "uppercase",
                        backgroundColor: "#f4f7f8",
                      }}
                    >
                      {" "}
                      <th
                        scope="col"
                        style={{ width: "3%", padding: "10px" }}
                      ></th>
                      {titleData
                        .filter((v) =>
                          ["question type", "mark", "position"].includes(
                            v.fieldName
                          )
                        )
                        .map((v, ind) => (
                          <th
                            key={ind}
                            // className="py-4 px-6"
                            style={{
                              padding: "12px",
                              backgroundColor: "#f4f7f8",
                              position:
                                v.title === "EDIT" || v.title === "DELETE"
                                  ? "sticky"
                                  : "",
                              right:
                                v.title === "EDIT"
                                  ? "68px"
                                  : v.title === "DELETE"
                                  ? "0"
                                  : "",
                            }}
                          >
                            <div className="flex justify-between">
                              <span>{v.title}</span>
                              {v.title !== "EDIT" && v.title !== "DELETE" && (
                                <span className="cursor-pointer">
                                  <ChevronUpDownIcon
                                    onClick={() => {
                                      let res = SortData(
                                        noticeData,
                                        v.fieldName,
                                        v.sort
                                      );
                                      let copyData = [...titleData];
                                      copyData[ind].sort = v.sort > 0 ? -1 : 1;
                                      setTitleData(copyData);
                                      setNoticeData(res);
                                    }}
                                    className="w-5 h-5"
                                  />
                                </span>
                              )}
                            </div>
                          </th>
                        ))}
                      {boardData?.map((board, index) => (
                        <th key={index} className="py-4 px-6">
                          <div className="flex justify-between">
                            <span>{board.board_name}</span>
                            <span className="cursor-pointer">
                              <ChevronUpDownIcon
                                onClick={() => {
                                  setBoardData((prevBoardData) => {
                                    const updatedBoardData = [...prevBoardData];
                                    const sortDirection =
                                      board.sort > 0 ? -1 : 1;

                                    updatedBoardData[index].sort =
                                      -sortDirection;

                                    const res = SortData(
                                      noticeData,
                                      `${board.board_name}`,
                                      updatedBoardData[index].sort
                                    );

                                    setNoticeData(res);
                                    return updatedBoardData;
                                  });
                                }}
                                className="w-5 h-5"
                              />
                            </span>
                          </div>
                        </th>
                      ))}
                      {titleData
                        .filter((v) => ["edit", "delete"].includes(v.fieldName))
                        .map(
                          (v, ind) =>
                            v.title && (
                              <th
                                key={ind}
                                className="py-2 px-6"
                                style={{
                                  backgroundColor: "#f4f7f8",
                                  position:
                                    v.title === "EDIT" || v.title === "DELETE"
                                      ? "sticky"
                                      : "",
                                  right:
                                    v.title === "EDIT"
                                      ? "68px"
                                      : v.title === "DELETE"
                                      ? "0"
                                      : "",
                                }}
                              >
                                <div className="flex justify-between">
                                  <span>{v.title}</span>
                                </div>
                              </th>
                            )
                        )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {isLoader ? (
                      <tr style={{ height: "25rem" }}>
                        <td colSpan={6} style={{ textAlign: "center" }}>
                          <CircularProgress />
                        </td>
                      </tr>
                    ) : (
                      noticeData &&
                      noticeData?.length > 0 &&
                      noticeData?.map((data) => (
                        <tr
                          key={data.id}
                          className="px-6 text-[#000] font-reguler text-xs font-medium"
                        >
                          {/* <td className="px-6 py-4 whitespace-no-wrap">
                            <Checkbox
                              type="checkbox"
                              checked={selectedQue.includes(data?.id)}
                              onChange={(e) =>
                                setSelectedQue(
                                  e.target.checked
                                    ? [...selectedQue, data?.id]
                                    : selectedQue.filter((o) => o !== data?.id)
                                )
                              }
                            />
                          </td> */}
                          <td className="px-6 py-3 whitespace-no-wrap">
                            <Checkbox
                              checked={checkedItems.includes(data.id)}
                              onChange={() => handleCheckboxChange(data.id)}
                            />
                          </td>
                          <td className=" lg:table-cell whitespace-nowrap px-6 py-2 ">
                            {data?.qtypeInfo?.name}
                          </td>
                          <td className=" lg:table-cell whitespace-nowrap px-6 py-2 ">
                            {data?.mark}
                          </td>
                          <td className=" lg:table-cell whitespace-nowrap px-6 py-2 ">
                            {data?.position}
                          </td>
                          {boardData &&
                            boardData?.map((board, boardIndex) => (
                              <td
                                key={boardIndex}
                                className=" lg:table-cell whitespace-nowrap px-6 py-2 "
                              >
                                {data?.notice_type?.map(
                                  (o, noticeIndex) =>
                                    o?.board_id === board?.id && (
                                      <>
                                        {o?.notice ? (
                                          <div key={noticeIndex}>
                                            {o?.notice}
                                          </div>
                                        ) : (
                                          <div>{"-"}</div>
                                        )}
                                      </>
                                    )
                                )}
                              </td>
                            ))}

                          {/* <td className="hidden lg:table-cell whitespace-nowrap px-6 py-2 "></td> */}

                          {access?.edit && (
                            <td
                              className="relative whitespace-nowrap p-6 py-2.5 text-left"
                              style={{
                                position: "sticky",
                                right: "68px",
                                backgroundColor: "white",
                              }}
                            >
                              <ActionButton
                                key={data.key}
                                action="edit"
                                onClick={() => {
                                  setIsModel(true);
                                  setModelType("edit");
                                  setGenerateNoticeId(data?.id);
                                }}
                              />
                            </td>
                          )}
                          {access?.delete && (
                            <td
                              className="relative whitespace-nowrap p-6 py-2.5 text-left"
                              style={{
                                position: "sticky",
                                right: "0",
                                backgroundColor: "white",
                              }}
                            >
                              <ActionButton
                                key={data.key}
                                action="delete"
                                onClick={() => {
                                  setOpenModel(true);
                                  setDeleteType("single");
                                  setGenerateNoticeId(data?.id);
                                }}
                              />
                            </td>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              recordsPerPage={recordsPerPage}
              data={noticeData}
              dataCount={noticeCount}
              numbers={numbers}
              npage={npage}
              lastIndex={lastIndex}
              firstIndex={firstIndex}
            />
          </section>
        </div>
        {/* Delete */}
        <DeletePopup
          title={"Delete"}
          show={openModel}
          description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
          onCancel={() => {
            setOpenModel(false);
          }}
          onClose={() => {
            setOpenModel(false);
          }}
          onDelete={() => {
            setOpenModel(false);
            handleDeleteNotice();
          }}
          cancelButtonRef={cancelButtonRef}
          okText="Delete"
          cancelText="Cancel"
        />
      </div>
    </div>
  );
};

export default GenerateNotice;
