import { Menu, Transition } from "@headlessui/react";
import Pagination from "./Pagination";
import { Fragment, useRef, useState, useEffect } from "react";
import {
  EllipsisHorizontalIcon,
  EyeIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import ads1 from "../Image/ads1.png";
import { Button } from "./Button";
import Model from "./Model";
import { Input, TextArea } from "./Input";
import MasterTitle from "./MasterTitle";
import { useNavigate } from "react-router-dom";
import Records from "./Records";
import FloatNotification from "./FloatNotification";
import Select from "./Select";
import { PlusIcon } from "@heroicons/react/20/solid";
import DeletePopup from "./DeletePopup";
import { Api } from "../Api/Api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ErrorToast, SuccessToast } from "./toast";
import NoDataFound from "./NoDataFound";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Product = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Shop")?.[0];
  const [isModel, setIsModel] = useState(false);
  const [modelType, setModelType] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const cancelButtonRef = useRef(null);
  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [dataCount, setDataCount] = useState(0);
  const [ProductsData, setProductsData] = useState([]);
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(dataCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [searchValue, setSearchValue] = useState("");
  const [isSelectIMG, setIsSelectIMG] = useState(false);
  const [SelectedData, setSelectedData] = useState([]);
  const [SelecteImagedData, setSelectedImageData] = useState([]);
  const [selectionDataBoard, setSelectionDataBoard] = useState([]);
  const [selectionDataMedium, setSelectionDataMedium] = useState([]);
  const [selectionDataStandard, setSelectionDataStandard] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [OrderHistoryModelData, setOrderHistoryModelData] = useState({
    board: "",
    medium: "",
    standard: "",
  });
  const [SelectProductData, setSelectProductDataData] = useState({
    standard: "",
    board: "",
  });

  const [standardData, setStandardData] = useState([]);
  const [boardData, setBoardData] = useState([]);

  const [standardLimit, setStandardLimit] = useState(1);
  const [error, setError] = useState({});
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const validation = () => {
    let errors = {};
    let formIsValid = true;
    if (!OrderHistoryModelData?.standard) {
      formIsValid = false;
      errors["standard"] = "Please select standard.";
    }
    if (!OrderHistoryModelData?.board) {
      formIsValid = false;
      errors["board"] = "Please select board.";
    }
    if (!OrderHistoryModelData?.medium) {
      formIsValid = false;
      errors["medium"] = "Please select medium.";
    }

    setError(errors);
    return formIsValid;
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    setCurrentPage(1);
  };

  const getBoardData = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setSelectionDataBoard(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getMediumData = async () => {
    try {
      let body = `?board=${OrderHistoryModelData?.board?.id}`;
      const { data } = await Api.getRequest(`api/medium${body}`);
      const req = JSON.parse(data);
      setSelectionDataMedium(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getStandardData = async () => {
    try {
      let body = `?board=${OrderHistoryModelData?.board?.id}&medium=${OrderHistoryModelData?.medium?.id}`;
      const { data } = await Api.getRequest(
        `api/standard/standardWithPrice${body}`
      );
      const req = JSON.parse(data);
      setSelectionDataStandard(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const handleSelecteData = (name, value) => {
    setSelectProductDataData((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
  };

  useEffect(() => {
    getBoardData();
    // getStandardData(1);
  }, []);

  useEffect(() => {
    getProductsData();
  }, [recordsPerPage, currentPage, searchValue]);

  const getProductsData = async () => {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/books?offset=${currentPage}&limit=${recordsPerPage}&search=${searchValue}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setProductsData(req?.data);
        setDataCount(req?.totalCount);
        setIsLoader(false);
      } else {
        ErrorToast(req?.message);
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const validationSchema = Yup.object({
    upload_img: Yup.array()
      .min(1, "At least one image is required")
      .test("fileType", "Only image files are allowed", (value) => {
        if (!value || value.length === 0) return false;
        return value.every((file) =>
          ["image/jpeg", "image/png", "image/gif"].includes(file.type)
        );
      }),
    name: Yup.string().required("Name is required"),
    price: Yup.number()
      .typeError("Price must be a number")
      .required("Price is required"),
    discount_price: Yup.number()
      .typeError("Discount Price must be a number")
      .required("Discount Price is required"),
    description: Yup.string().required("Description is required"),
  });

  const validationSchemaForEdit = Yup.object({
    name: Yup.string().required("Teacher Name is required"),
    price: Yup.number()
      .typeError("Price must be a number")
      .required("Price is required"),
    discount_price: Yup.number()
      .typeError("Discount Price must be a number")
      .required("Discount Price is required"),
    description: Yup.string().required("Description is required"),
  });

  const formik = useFormik({
    initialValues: {
      upload_img: [],
      name: "",
      price: "",
      discount_price: "",
      description: "",
    },
    validationSchema:
      modelType === "edit" && !isSelectIMG && SelecteImagedData?.length !== 0
        ? validationSchemaForEdit
        : validationSchema,
    onSubmit: async (values) => {
      if (validation()) {
        setIsLoading(true);
        if (modelType === "add") {
          let imgData = [];

          for (const row of values?.upload_img) {
            let formData = new FormData();
            formData.append("image", row);

            const { data } = await Api.axiospostRequest(
              "api/upload/single_file",
              formData
            );

            if (data.success) {
              imgData = [...imgData, data?.data];
            } else {
              // Handle unsuccessful response
            }
          }

          const payload = {
            upload_img: imgData,
            name: values?.name,
            standard: OrderHistoryModelData?.standard?.id,
            board: OrderHistoryModelData?.board?.id,
            medium: OrderHistoryModelData?.medium?.id,
            price: values?.price,
            discount_price: values?.discount_price,
            description: values?.description,
          };

          const response = await Api.postRequest("api/books/create", payload);

          const req2 = JSON.parse(response?.data);

          if (req2?.status === 200) {
            SuccessToast("Books Added Successfully");
            getProductsData();
            fileInputRef.current.value = "";
            resetProductsBook();
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
          } else {
            ErrorToast(req2?.message);
          }
        } else if (modelType === "edit") {
          if (isSelectIMG) {
            let imgData = [];
            for (const row of values?.upload_img) {
              let formData = new FormData();
              formData.append("image", row);

              const { data } = await Api.axiospostRequest(
                "api/upload/single_file",
                formData
              );

              if (data.success) {
                imgData = [...imgData, data?.data];
              } else {
                // Handle unsuccessful response
              }
            }
            const updatedUploadImg = [...SelecteImagedData, ...imgData];
            const payload = {
              upload_img: updatedUploadImg,
              name: values?.name,
              standard: OrderHistoryModelData?.standard?.id,
              board: OrderHistoryModelData?.board?.id,
              medium: OrderHistoryModelData?.medium?.id,
              price: values?.price,
              discount_price: values?.discount_price,
              description: values?.description,
            };

            const { data } = await Api.putRequest(
              `api/books/update/${SelectedData?.id}`,
              payload
            );

            if (data?.status === 200) {
              SuccessToast("Book Updated Successfully");
              getProductsData();
              resetEditProductsBook();
              setTimeout(() => {
                setIsLoading(false);
              }, 2000);
            } else {
              ErrorToast(data?.message);
              setIsLoading(false);
            }
          } else {
            const payload = {
              upload_img: SelecteImagedData,
              name: values?.name,
              standard: SelectProductData?.standard?.id,
              board: SelectProductData?.board?.id,
              price: values?.price,
              discount_price: values?.discount_price,
              description: values?.description,
            };

            const { data } = await Api.putRequest(
              `api/books/update/${SelectedData?.id}`,
              payload
            );

            if (data?.status === 200) {
              SuccessToast("Book Updated Successfully");
              getProductsData();
              resetEditProductsBook();
              setTimeout(() => {
                setIsLoading(false);
              }, 2000);
            } else {
              ErrorToast(data?.message);
              setIsLoading(false);
            }
          }
        }
      }
    },
  });

  const resetProductsBook = () => {
    setSelectProductDataData({
      standard: "",
      board: "",
    });
    setError({});
    // setIsModel(false);
    setSelectedData([]);
    setIsSelectIMG(false);
    formik.resetForm();
  };
  const resetEditProductsBook = () => {
    setSelectProductDataData({
      standard: "",
      board: "",
    });
    setError({});
    setIsModel(false);
    setSelectedData([]);
    setIsSelectIMG(false);
    formik.resetForm();
  };

  const deleteProductsData = async () => {
    try {
      const { data } = await Api.postRequest(
        `api/books/delete/${SelectedData?.id}`
      );

      const req = JSON.parse(data);
      if (req?.status === 200) {
        setOpenModel(false);
        setSelectedData([]);
        setSelectProductDataData({
          standard: "",
          board: "",
        });
        getProductsData();
        SuccessToast("Book Delete Successfully");
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    setDefaultValues();
  }, [SelectedData]);

  const setDefaultValues = () => {
    const uploadImg = SelectedData?.upload_img || "[]";
    setSelectedImageData(JSON.parse(uploadImg));
    formik.setValues({
      ...formik.values,
      upload_img: [],
      name: SelectedData?.name,
      price: SelectedData?.price,
      discount_price: SelectedData?.discount_price,
      description: SelectedData?.description,
    });
    setSelectProductDataData({
      standard: SelectedData?.standardaInfo,
      board: SelectedData?.boardnfo,
    });
  };

  const handleDelete = (id) => {
    const updatedUploadImg = SelecteImagedData?.filter((index) => {
      return index !== id;
    });
    setSelectedImageData([...updatedUploadImg]);
  };

  const handleChange = (name, value) => {
    setOrderHistoryModelData((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
    if (name === "board") {
      setOrderHistoryModelData((oldValue) => ({
        ...oldValue,
        medium: "",
        standard: "",
        [name]: value,
      }));
      setSelectionDataMedium([]);
      setSelectionDataStandard([]);
    }
    if (name === "medium") {
      setOrderHistoryModelData((oldValue) => ({
        ...oldValue,
        standard: "",
        [name]: value,
      }));
      setSelectionDataStandard([]);
    }
    if (name === "standard") {
      setOrderHistoryModelData((oldValue) => ({
        ...oldValue,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (OrderHistoryModelData?.board) {
      getMediumData();
    }
  }, [OrderHistoryModelData?.board]);

  useEffect(() => {
    if (OrderHistoryModelData?.medium) {
      getStandardData();
    }
  }, [OrderHistoryModelData?.medium]);

  return (
    <div className="w-full sm:px-6">
      <div>
        <div className="flex justify-between items-center mt-1 mb-2">
          <div>
            <MasterTitle
              title="Books"
              description="Below are the list of all the Books."
            />
          </div>
          <div>
            {access?.add && (
              <Button
                id=""
                type="submit"
                icon={PlusIcon}
                label={"ADD BOOKS"}
                onClick={() => {
                  setIsModel(true);
                  setModelType("add");
                }}
              />
            )}

            <Model
              title={modelType === "add" ? "Add Books" : "Edit Products"}
              show={isModel}
              onCancel={() => {
                // resetProductsBook();
                setIsModel(false);
                setIsLoading(false);
              }}
              onClose={() => {
                resetProductsBook();
                setIsModel(false);
                setIsLoading(false);
              }}
              onOk={() => {
                formik.handleSubmit();
                validation();
              }}
              okText="SUBMIT"
              cancelText="CLOSE"
              isLoading={isLoading}
              disabled={isLoading}
            >
              <label
                htmlFor="fileUpload"
                className="mb-3 block text-sm font-medium text-[#252F40] font-poppins"
              >
                Upload image file{" "}
                <span className="text-red-500 text-sm"> * </span>
              </label>
              <div className="mt-5 sm:mt-6 mb-3 flex flex-col space-y-10 sm:flex-row sm:space-y-0 sm:space-x-10 ">
                <input
                  ref={fileInputRef}
                  type="file"
                  multiple
                  id="upload_img"
                  name="upload_img"
                  onChange={(event) => {
                    const files = Array.from(event.currentTarget.files); // Convert FileList to array
                    formik.setFieldValue("upload_img", files);
                    formik.setFieldTouched("upload_img", false);
                    setIsSelectIMG(true);
                  }}
                />
                {SelectedData?.upload_img?.length > 0 ? (
                  <div
                    style={{
                      display: "flex",
                      gap: "2",
                      width: "60%",
                      overflow: "auto",
                    }}
                  >
                    {SelecteImagedData?.map((data, id) => {
                      return (
                        <div
                          className="shrink-0 w-110 h-10 pt-19"
                          key={id}
                          style={{
                            paddingLeft: "10px",
                            width: "25%",
                            position: "relative",
                          }}
                        >
                          <img className="w-full h-full" src={data} alt="" />
                          <CloseIcon
                            style={{
                              width: "20px",
                              height: "20px",
                              position: "absolute",
                              top: "1px",
                              right: "1px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDelete(data)}
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>
              {formik.touched.upload_img && formik.errors.upload_img && (
                <div className="text-red-500 text-sm">
                  {formik.errors.upload_img}
                </div>
              )}

              <Input
                id="name"
                name="name"
                type="text"
                label="Name"
                placeholder="Name here..."
                autoComplete="given-name"
                required
                className="mb-3"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="text-red-500 text-sm">{formik.errors.name}</div>
              )}

              <Select
                value={OrderHistoryModelData?.board}
                data={selectionDataBoard || []}
                onChange={(e) => handleChange("board", e)}
                className="font-regular mb-3"
                label="Board"
                required
                placeholder="All Board"
                type={"board"}
                getBoard={getBoardData}
                isPage={true}
              />
              <Typography variant="caption" color={"error"}>
                {!OrderHistoryModelData?.board ? error?.board : ""}
              </Typography>
              {/* <Select
                value={SelectProductData?.board}
                data={boardData || []}
                label="Board"
                required
                className="w-full mb-2"
                onChange={(e) => handleSelecteData("board", e)}
                placeholder="All Board"
                type="board"
                getBoard={getBoardData}
              />
              <div className="text-red-500 text-sm">
                {!SelectProductData?.board ? error?.board : ""}
              </div> */}

              <Select
                value={OrderHistoryModelData?.medium}
                data={selectionDataMedium || []}
                onChange={(e) => handleChange("medium", e)}
                className="font-regular mb-3"
                label="Medium"
                required
                placeholder="All Medium"
                type={"medium"}
                getMedium={getMediumData}
                isPage={true}
              />
              <Typography variant="caption" color={"error"}>
                {!OrderHistoryModelData?.medium ? error?.medium : ""}
              </Typography>

              <Select
                data={selectionDataStandard || []}
                className="font-regular mb-3"
                label="Standard"
                required
                placeholder="All Standard"
                value={OrderHistoryModelData.standard || ""}
                onChange={(e) => handleChange("standard", e)}
                type={"standard"}
                getStandard={getStandardData}
                isPage={true}
              />
              <Typography variant="caption" color={"error"}>
                {!OrderHistoryModelData?.standard ? error?.standard : ""}
              </Typography>

              <Input
                id="price"
                name="price"
                label="Price"
                type="text"
                placeholder="Price here..."
                autoComplete="given-name"
                required
                className="w-full mb-2"
                value={formik.values.price}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.price && formik.errors.price && (
                <div className="text-red-500 text-sm">
                  {formik.errors.price}
                </div>
              )}
              <Input
                id="discount_price"
                name="discount_price"
                label="Discount Price"
                placeholder="Discount Price here..."
                type="text"
                autoComplete="given-name"
                required
                className="w-full mb-2"
                value={formik.values.discount_price}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.discount_price &&
                formik.errors.discount_price && (
                  <div className="text-red-500 text-sm">
                    {formik.errors.discount_price}
                  </div>
                )}
              <TextArea
                id="description"
                name="description"
                label="Descriptions"
                placeholder="Descriptions here..."
                type="text"
                autoComplete="given-name"
                required
                className="w-full mb-2"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.description && formik.errors.description && (
                <div className="text-red-500 text-sm">
                  {formik.errors.description}
                </div>
              )}
            </Model>
          </div>
        </div>
        <div className="sm:mb-0 overflow-hidden">
          <div className="flex flex-col">
            <section
              className="bg-white rounded-2xl flex flex-col shadow-sm "
              style={{
                width: "100%",
                margin: "0 auto",
                padding: "20px",
              }}
            >
              <div className="flex justify-between items-center mt-3 px-8 mb-3">
                <Input
                  id=""
                  name="Search"
                  type="text"
                  placeholder="Search here..."
                  autoComplete="given-name"
                  required
                  className="w-72"
                  value={searchValue}
                  onChange={handleSearchChange}
                />
                <Records
                  recordsPerPage={recordsPerPage}
                  setCurrentPage={setCurrentPage}
                  setRecordsPerPage={setRecordsPerPage}
                  dataCount={dataCount}
                />
              </div>
              <div>
                <div
                  style={{
                    overflowY: "auto",
                    maxHeight: "58vh",
                    height: "60vh",
                    width: "100%",
                  }}
                >
                  {" "}
                  {!isLoader && ProductsData?.length <= 0 ? (
                    <NoDataFound />
                  ) : (
                    <div>
                      {isLoader ? (
                        <div
                          className="flex items-center justify-center"
                          style={{
                            height: "25rem",
                          }}
                        >
                          <CircularProgress />
                        </div>
                      ) : (
                        <div className="w-full grid  lg:grid-cols-3 xl:grid-cols-4 gap-4 px-8">
                          {ProductsData.map((data, id) => {
                            const uploadImages = data.upload_img
                              ? JSON.parse(data.upload_img)
                              : [];
                            return (
                              <div
                                className="bg-white border border-[#EDEEF0] p-4 rounded-xl tracking-wide"
                                key={id}
                              >
                                <div>
                                  <div className=" flex items-center justify-between">
                                    <div className="flex items-center">
                                      <img
                                        className="w-14 h-14 rounded-full mr-4"
                                        src={uploadImages[0]}
                                        alt="Avatar of Jonathan Reinink"
                                      />
                                      <div className="text-sm">
                                        <p className="text-[#1C1D21] leading-loose font-poppons font-medium text-sm">
                                          {data.name}
                                        </p>
                                      </div>
                                    </div>
                                    <div>
                                      <Menu
                                        as="div"
                                        className="relative inline-block text-left"
                                      >
                                        <div>
                                          <Menu.Button className="z-10 border border-gray-200 p-2 rounded-md flex items-center text-gray-400 ">
                                            <span className="sr-only">
                                              Open options
                                            </span>
                                            <EllipsisHorizontalIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </Menu.Button>
                                        </div>

                                        <Transition
                                          as={Fragment}
                                          enter="transition ease-out duration-100"
                                          enterFrom="transform opacity-0 scale-95"
                                          enterTo="transform opacity-100 scale-100"
                                          leave="transition ease-in duration-75"
                                          leaveFrom="transform opacity-100 scale-100"
                                          leaveTo="transform opacity-0 scale-95"
                                        >
                                          <Menu.Items className="origin-top-right absolute right-0 z-20 mt-0 w-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                                            <div className="py-1 w-full">
                                              <Menu.Item>
                                                {({ active }) => (
                                                  <button
                                                    type="button"
                                                    onClick={() =>
                                                      navigate(
                                                        "/shop/books/view-products",
                                                        {
                                                          state: {
                                                            ProductRowData:
                                                              data,
                                                          },
                                                        }
                                                      )
                                                    }
                                                    className={classNames(
                                                      active
                                                        ? "bg-gray-100/60 text-gray-900"
                                                        : "text-gray-700",
                                                      "group flex items-center px-4 py-2 text-sm  w-full"
                                                    )}
                                                  >
                                                    <EyeIcon
                                                      className="mr-3 h-5 w-5 text-gray-400 "
                                                      aria-hidden="true"
                                                    />
                                                    View
                                                  </button>
                                                )}
                                              </Menu.Item>
                                            </div>
                                            {access?.edit && (
                                              <div className="py-1 w-full">
                                                <Menu.Item>
                                                  {({ active }) => (
                                                    <button
                                                      type="button"
                                                      onClick={() => {
                                                        setIsModel(true);
                                                        setModelType("edit");
                                                        setSelectedData(data);
                                                      }}
                                                      className={classNames(
                                                        active
                                                          ? "bg-gray-100/60 text-gray-900"
                                                          : "text-gray-700",
                                                        "group flex items-center px-4 py-2 text-sm  w-full"
                                                      )}
                                                    >
                                                      <PencilIcon
                                                        className="mr-3 h-5 w-5 text-gray-400 "
                                                        aria-hidden="true"
                                                      />
                                                      Edit
                                                    </button>
                                                  )}
                                                </Menu.Item>
                                              </div>
                                            )}
                                            {access?.delete && (
                                              <div className="py-1 w-full">
                                                <Menu.Item>
                                                  {({ active }) => (
                                                    <button
                                                      type="button"
                                                      onClick={() => {
                                                        setOpenModel(true);
                                                        setSelectedData(data);
                                                      }}
                                                      className={classNames(
                                                        active
                                                          ? "bg-gray-100/60 text-gray-900"
                                                          : "text-gray-700",
                                                        "group flex items-center px-4 py-2 text-sm  w-full"
                                                      )}
                                                    >
                                                      <TrashIcon
                                                        className="mr-3 h-5 w-5 text-gray-400 "
                                                        aria-hidden="true"
                                                      />
                                                      Delete
                                                    </button>
                                                  )}
                                                </Menu.Item>
                                              </div>
                                            )}
                                          </Menu.Items>
                                        </Transition>
                                      </Menu>
                                    </div>
                                  </div>
                                  <div className="flex items-center justify-between mt-6">
                                    <div>
                                      <h1 className="text-[#1C1D21] leading-loose font-poppons font-medium text-sm">
                                        Standard
                                      </h1>
                                      <p className="text-[#6B7280] font-poppons font-medium text-sm">
                                        {data.standardaInfo?.name}
                                      </p>
                                    </div>
                                    <div>
                                      <h1 className="text-[#1C1D21] leading-loose font-poppons font-medium text-sm">
                                        Board
                                      </h1>
                                      <p className="text-[#6B7280] font-poppons font-medium text-sm">
                                        {data.boardnfo?.board_name}
                                      </p>
                                    </div>
                                    <div>
                                      <h1 className="text-[#1C1D21] leading-loose font-poppons font-medium text-sm">
                                        Price
                                      </h1>
                                      <p className="text-[#6B7280] font-poppons font-medium text-sm">
                                        ₹{data?.price}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                recordsPerPage={recordsPerPage}
                data={ProductsData}
                numbers={numbers}
                npage={npage}
                lastIndex={lastIndex}
                firstIndex={firstIndex}
                dataCount={dataCount}
              />
            </section>
          </div>

          {/* Delete */}
          <DeletePopup
            title={"Delete"}
            show={openModel}
            description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
            onCancel={() => {
              setOpenModel(false);
              setSelectedData([]);
              setSelectProductDataData({
                standard: "",
                board: "",
              });
            }}
            onClose={() => {
              setOpenModel(false);
              setSelectedData([]);
              setSelectProductDataData({
                standard: "",
                board: "",
              });
            }}
            onDelete={() => {
              deleteProductsData();
            }}
            cancelButtonRef={cancelButtonRef}
            okText="Delete"
            cancelText="Cancel"
          />
          {/* Delete */}
          <FloatNotification
            show={showFloatNotification}
            title={"Successfully deleted!"}
            type="success"
            description={"Notification Deleted."}
            onClick={() => {
              setShowFloatNotification(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Product;
