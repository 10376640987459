import SemesterSaga from "./handlers/SemesterHandler";
import QuestionSaga from "./handlers/questionTypeHandler";
import { call, all } from "redux-saga/effects";
import standardSaga from "./handlers/standardHandler";
import subjectSaga from "./handlers/subjectHandler";
import chapterSaga from "./handlers/chapterTypeHandler";
import topicSaga from "./handlers/topicHandler";

export default function* rootSaga() {
    yield all([
        call(QuestionSaga),
        call(SemesterSaga),
        call(standardSaga),
        call(subjectSaga),
        call(chapterSaga),
        call(topicSaga),
    ])
}