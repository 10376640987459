import React, { useEffect, useState } from "react";
import MasterTitle from "./MasterTitle";
import { Input } from "./Input";
import { Button } from "./Button";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast } from "./toast";

const SetExpiryDate = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Paper")?.[0];
  const [expiryData, setExpiryData] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");

  const formattedDate = expiryData?.[0]?.expiryDate
    ? new Date(expiryData[0].expiryDate).toISOString().split("T")[0]
    : "";

  const getExpiryDate = async () => {
    try {
      const data = await Api.getRequest(`api/expiryDate`);
      const req = JSON.parse(data.data);
      setExpiryData(req.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const handleExpChange = (newDate) => {
    setExpiryData([
      {
        ...expiryData[0],
        expiryDate: newDate,
      },
    ]);
  };

  const handleSubmit = async () => {
    try {
      const body = {
        expiryDate: selectedDate || formattedDate,
      };

      const response = await Api.postRequest(`api/expiryDate/create`, body);

      if (response.statusCode === 200) {
        getExpiryDate();
        SuccessToast("Expiry date successfully update");
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };
  const handleReset = () => {
    setSelectedDate(formattedDate);
  };

  useEffect(() => {
    getExpiryDate();
  }, []);

  return (
    <div className="w-full sm:px-6">
      <div>
        <div className="sm:mb-0 overflow-hidden ">
          <div className="flex flex-col mt-4">
            <section className="bg-white rounded-2xl flex flex-col shadow-sm min-h-[calc(100vh-40px-16px-24px-38px-50px-40px)]">
              <div className="px-8 ">
                <div className="mt-10 mb-3">
                  <MasterTitle
                    title="Expiry Date"
                    description="Below are the expire date."
                  />
                </div>

                <div className="max-w-xl">
                  <div className="w-full space-y-6">
                    <Input
                      label="Expiry Date"
                      type="date"
                      className=""
                      autoComplete="given-name"
                      name="title"
                      value={selectedDate || formattedDate}
                      onChange={(e) => setSelectedDate(e.target.value)}
                    />

                    <div className="flex items-center flex-col sm:flex-row sm:space-y-0 sm:space-x-6 mb-3">
                      {access?.edit && (
                        <div className="w-96">
                          <Button
                            id=""
                            type="button"
                            label={"SUBMIT"}
                            abc="w-full"
                            onClick={handleSubmit}
                          />
                        </div>
                      )}
                      {/* <div className="w-96">
                        <Button
                          isBordered
                          id=""
                          type="button"
                          label={"RESET"}
                          abc="w-full"
                          onClick={handleReset}
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetExpiryDate;
