import { call, put, takeLatest } from 'redux-saga/effects';
import { getChapterDataApi } from '../requests/chapterRequest';
import { ChapterActions } from '../../action-types/chapterActionType';
import { setChapterList } from '../../actions/chapterAction';

function* getChapterHandler(action) {
    // start loader here
    try {
      const { data } = yield call(getChapterDataApi, action.payload);
      yield put(setChapterList(data));
      // handle response here and set into reducer
    } catch (error) {
    } finally {
      //stop loader here
    }
  }

  export default function* chapterSaga() {
    yield takeLatest(ChapterActions.GET_CHAPTER_DATA, getChapterHandler);
  }