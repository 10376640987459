import React from "react";
import OrderHistory from "./OrderHistory";
import SetExpiryDate from "./SetExpiryDate";
import Product from "./Products";
import Tabs from "./Tabs";
import BookReport from "./BookReport";
import { getDecryptedDataFromLocalStorage } from "../Api/encryption";

const Shop = (props) => {
  const userData = getDecryptedDataFromLocalStorage("userData");
  const categories =
    userData?.type !== "partner"
      ? [
          {
            name: "Books",
            component: <Product access={props?.access} />,
            path: "/shop/books",
          },
          {
            name: "Book History",
            component: <BookReport access={props?.access} />,
            path: "/shop/book-history",
          },
          // {
          //   name: "Set Expiry Date",
          //   component: <SetExpiryDate access={props?.access} />,
          //   path: "/shop/set-expiry-date",
          // },
        ]
      : [
          {
            name: "Book History",
            component: <BookReport access={props?.access} />,
            path: "/shop/book-history",
          },
          // You can include other categories/components here if needed
        ];
  return <Tabs categories={categories} title="Shop" />;
};

export default Shop;
