import { call, put, takeLatest } from 'redux-saga/effects';
import { getStandardDataApi } from '../requests/standardRequest';
import { StandardActions } from '../../action-types/standardActionType';
import { setStandardList } from '../../actions/standardAction';

function* getStandardHandler(action) {
    // start loader here
    try {
      const { data } = yield call(getStandardDataApi, action.payload);
      yield put(setStandardList(data.data));
      // handle response here and set into reducer
    } catch (error) {
    } finally {
      //stop loader here
    }
  }

  export default function* standardSaga() {
    yield takeLatest(StandardActions.GET_STANDARD_DATA, getStandardHandler);
  }