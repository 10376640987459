import React, { useEffect, useRef, useState } from "react";
import MasterTitle from "./MasterTitle";
import { ArrowDownTrayIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Input, TextArea } from "./Input";
import Pagination from "./Pagination";
import { Checkbox } from "./Checkbox";
import { useNavigate } from "react-router-dom";
import Records from "./Records";
// import Select from "./Select";
import { Button } from "./Button";
import Breadcrumbs from "./Breadcrumbs";
import DeletePopup from "./DeletePopup";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast, WarningToast } from "./toast";
import moment from "moment";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid, Typography } from "@mui/material";
import NoDataFound from "./NoDataFound";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Selected from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Divider from "@mui/material/Divider";
import { getDecryptedDataFromLocalStorage } from "../Api/encryption";
import Model from "./Model";
import ActionButton from "./ActionButton";
import { SortData } from "./SortData";
import {
  PlusIcon,
  ChevronUpDownIcon,
  MinusIcon,
} from "@heroicons/react/24/outline";

const BookReport = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Shop")?.[0];
  const userData = getDecryptedDataFromLocalStorage("userData");
  const [selectedQue, setSelectedQue] = useState([]);
  const navigate = useNavigate();
  const cancelButtonRef = useRef(null);
  const [isModel, setIsModel] = useState(false);
  const [isModelForAddHistory, setIsModelForAddHistory] = useState(false);
  const [deleteType, setDeleteType] = useState("single");
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [userDataTable, setUserDataTable] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [SelectBookId, setSelectBookId] = useState();
  const [BookReportData, setBookReportData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [searchValue, setSearchValue] = useState("");
  const [searchPhone, setSearchPhone] = useState("");
  const [searchBook, setSearchBook] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(dataCount / recordsPerPage);
  const numbers = npage > 0 ? [...Array(npage + 1).keys()].slice(1) : [];
  const [isLoader, setIsLoader] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [bookLimit, setBookLimit] = useState(1);
  const [bookData, setBookData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(0); //setAmount
  const [isExportLoader, setIsExportLoader] = useState(false);

  const [titleData, setTitleData] = useState([
    {
      title: "SR.No",
      sort: 1,
      fieldName: "sr_no",
    },
    {
      title: "ORDER ID",
      sort: 1,
      fieldName: "order_id",
    },
    {
      title: "BOOK",
      sort: 1,
      fieldName: "book",
    },
    {
      title: "CUSTOMER NAME",
      sort: 1,
      fieldName: "customer_name",
    },
    {
      title: userData?.type !== "partner" ? "ADDRESS" : "",
      sort: 1,
      fieldName: "address",
    },
     {
      title: "PINCODE",
      sort: 1,
      fieldName: "pincode",
    },
    {
      title: "EMAIL",
      sort: 1,
      fieldName: "email",
    },
      
    {
      title: "DISCOUNT PRICE",
      sort: 1,
      fieldName: "discountPrice",
    },
    {
      title: "PARTNER DISCOUNT PRICE",
      sort: 1,
      fieldName: "partnerdiscount",
    },
    {
      title: "PROMOCODE NAME",
      sort: 1,
      fieldName: "promocode_name",
    },
    {
      title: "STATUS",
      sort: 1,
      fieldName: "status",
    },
   
    {
      title: userData?.type !== "partner" ? "COMMENT" : "",
      sort: 1,
      fieldName: "comment",
    },
    {
      title: "DATE",
      sort: 1,
      fieldName: "DATE",
    },
    {
      title: "AMOUNT",
      sort: 1,
      fieldName: "amount",
    },
    {
      title: "MOBILE",
      sort: 1,
      fieldName: "mobile",
    },
    {
      title: access?.view ? "VIEW" : "",
      sort: 1,
      fieldName: "",
    },
  ]);

  const [booktitleData, setBookTitleData] = useState([
    {
      title: "SR.No",
      sort: 1,
      fieldName: "sr_no",
    },
    {
      title: "BOOK",
      sort: 1,
      fieldName: "book",
    },
    {
      title: "QUANTITY",
      sort: 1,
      fieldName: "quantity",
    },
    {
      title: "PRICE",
      sort: 1,
      fieldName: "price",
    },
  ]);

  const [isModelLoader, setisModelLoader] = useState(false);
  const [selectBookData, setSelectBookData] = useState([]);
  const [isResetFilter, setIsResetFilter] = useState(false);
  const [error, setError] = useState({});
  const [BookHistoryData, setBookHistoryData] = useState({
    FullName: "",
    email: "",
    phoneNumber: "",
    paymentStatus: "",
    Address: "",
    finalAmount: "",
    pincode: "",
  });

  const handleChange = (name, value) => {
    setBookHistoryData((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
  };

  const [AddBookData, setAddBookData] = useState([
    {
      id: "",
      quantity: "1",
    },
  ]);

  const handleChangeAddBookData = (index, name, value) => {
    setAddBookData((oldData) => {
      const newData = [...oldData]; // Create a copy of the array
      newData[index] = { ...newData[index], [name]: value }; // Update the specific object at the given index
      return newData; // Return the updated array
    });
  };

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) => {
      const isChecked = prevState.includes(id);
      const updatedItems = isChecked
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id];
      return updatedItems;
    });
  };
  const handleAddBookdata = () => {
    const lastData = AddBookData[AddBookData.length - 1];

    if (lastData.id === "") {
      // Display an error message or handle the scenario as per your requirements
      WarningToast(
        "Cannot add a new Book when the previous entry's Book is empty."
      );
      return; // Exit the function early
    }

    // Create a new entry with default values
    const newEntry = {
      id: "",
      quantity: "",
    };

    // Append the new entry to the AddBookData array
    setAddBookData((oldData) => [...oldData, newEntry]);
  };

  const handleMinusBookdata = (id) => {
    setAddBookData((oldData) => {
      // Filter out the entry with the given id
      const updatedData = oldData.filter((data, index) => index !== id);
      return updatedData;
    });
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    setCurrentPage(1);
  };

  const handleSearchPhoneChange = (event) => {
    if (event.target.value >= 0) {
      setSearchPhone(event.target.value);
    }
  };

  const handleSearchBookChange = (event) => {
    setSearchBook(event.target.value);
  };

  const handleSearchStatusChange = (event) => {
    setSearchStatus(event.target.value);
  };

  const getBookData = async () => {
    try {
      const { data } = await Api.getRequest(`api/books`);
      const req = JSON.parse(data);
      setBookData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    getBookData();
  }, []);

  useEffect(() => {
    getBookReportData(currentPage, recordsPerPage);
  }, [recordsPerPage, currentPage]);

  const getBookReportData = async () => {
    try {
      setSelectAll(false)
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/order/getMultipleOrder?offset=${currentPage}&limit=${recordsPerPage}&searchByFullName=${searchValue}&bookId=${searchBook}&searchByOrderIdOrPhone=${searchPhone}&status=${searchStatus}&startDate=${startDate}&endDate=${endDate}`
      );
      const req = JSON.parse(data);
      if (req?.success === true) {
        setAmount(req?.totalPartnerRevenue);
        setBookReportData(req?.data);
        setDataCount(req?.totalCount);
        setIsLoader(false);
      } else {
        ErrorToast(req?.message);
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const onExportHandler = async () => {
    setIsExportLoader(true);
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    try {
      const { data } = await Api.getRequest(
        `api/order/getMultipleOrder?searchByFullName=${searchValue}&bookId=${searchBook}&searchByOrderIdOrPhone=${searchPhone}&status=${searchStatus}&startDate=${startDate}&endDate=${endDate}`
      );
      const req = JSON.parse(data);
      if (req?.success === true) {
        const exportFormShape = req?.data
          .sort((a, b) => b.id - a.id)
          .map((book, index) => {
            return {
              "Sr No.": index + 1,
              "Order ID": book?.id,
              Book:
                book.bookInfo?.length > 0
                  ? book.bookInfo?.map((row, index) => row.name).join(", ")
                  : book.bookInfo?.name,
              "Customer Name": book?.fullName ? book?.fullName : "-",
              Address: book?.fullAdress ? book?.fullAdress : "-",
              Email: book?.email || "-",
              Mobile: book?.mobileNo || "-",
              Amount: book?.price || "-",
              "Discount Price": book?.discountPrice?.toFixed(2) || "-",
              "Partner Discount": book?.partnerRevenue?.toFixed(2) || "-",
              "Promocode Name": book?.promocodeInfo?.name || "-",
              Status: book?.paymentStatus || "-",
              Pincode: book?.pincode || "-",
              "Created Date And Time":
                moment(book?.createdAt).format("D MMMM, YYYY | hh:mm A") || "-",
              Comment: book?.comment?.description
                ? book?.comment?.description
                : "-",
            };
          });

        const ws = XLSX.utils.json_to_sheet(exportFormShape);

        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, {
          bookType: "xlsx",
          type: "array",
        });

        const dataBlob = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(dataBlob, "All Book" + fileExtension);
        setIsExportLoader(false);
      } else {
        setIsExportLoader(false);
        // Handle error or other cases
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsExportLoader(false);
    }
  };

  const handleSearchFilter = () => {
    if (
      new Date(endDate) > new Date(startDate) ||
      (startDate === "" && endDate === "")
    ) {
      getBookReportData(currentPage, recordsPerPage);
    } else {
      ErrorToast("End date must be greater than start date");
    }
  };

    const handleSelectAll = () => {
    if (selectAll) {
      setCheckedItems([]);
    } else {
      const allIds = BookReportData.map((data) => data.id);
      setCheckedItems(allIds);
    }
    setSelectAll(!selectAll);
  };
  const resetFilter = () => {
    setIsResetFilter(true);
    setSearchPhone("");
    setSearchBook("");
    setSearchStatus("");
    setStartDate("");
    setEndDate("");
  };

  useEffect(() => {
    if (isResetFilter) {
      getBookReportData(currentPage, recordsPerPage);
      setIsResetFilter(false);
    }
  }, [isResetFilter]);

  const validation = () => {
    let errors = {};
    let formIsValid = true;
    const phoneNumberRegex = /^\d{10}$/;
    const emailPattern =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (!BookHistoryData?.FullName) {
      formIsValid = false;
      errors["FullName"] = "Please enter FullName.";
    }

    if (!BookHistoryData?.email) {
      formIsValid = false;
      errors["email"] = "Please Enter Email ID.";
    } else if (!emailPattern.test(BookHistoryData?.email)) {
      formIsValid = false;
      errors["email"] = "Please Valid Email ID.";
    }

    if (!BookHistoryData?.phoneNumber) {
      formIsValid = false;
      errors["phoneNumber"] = "Please Enter Phone Number.";
    } else if (!phoneNumberRegex.test(BookHistoryData?.phoneNumber)) {
      formIsValid = false;
      errors["phoneNumber"] = "Please Enter a 10-digit Phone Number.";
    }

    if (!BookHistoryData?.Address) {
      formIsValid = false;
      errors["Address"] = "Please enter Address.";
    }

    if (!BookHistoryData?.finalAmount) {
      formIsValid = false;
      errors["finalAmount"] = "Please Enter Final Amount.";
    } else if (
      isNaN(Number(BookHistoryData?.finalAmount)) ||
      Number(BookHistoryData?.finalAmount) <= 0 // Change the condition to check if it's less than or equal to zero
    ) {
      formIsValid = false;
      errors["finalAmount"] =
        "Please Enter a non-negative number for Final Amount";
    }

    if (!BookHistoryData?.pincode) {
      formIsValid = false;
      errors["pincode"] = "Please Enter Phone Number.";
    } else if (!/^\d{6}$/.test(BookHistoryData?.pincode)) {
      formIsValid = false;
      errors["pincode"] = "Please enter a 6-digit pincode.";
    }

    setError(errors);
    return formIsValid;
  };

  const handleAddModelSubmit = async () => {
    if (validation()) {
      const lastData = AddBookData[AddBookData.length - 1];
      if (lastData.id !== "") {
        setIsLoading(true);
        let body = {
          bookId: JSON.stringify(AddBookData),
          fullName: BookHistoryData?.FullName,
          email: BookHistoryData?.email,
          mobileNo: BookHistoryData?.phoneNumber,
          paymentStatus: BookHistoryData?.paymentStatus,
          fullAdress: BookHistoryData?.Address,
          finalAmount: BookHistoryData?.finalAmount || 0,
          pincode: BookHistoryData?.pincode,
          razorpayOrderId: "manual",
          courierCharge: 0,
        };
        try {
          const { data } = await Api.postRequest(
            `api/order/createManualOrder`,
            body
          );
          const res = JSON.parse(data);
          if (res?.status === 200) {
            SuccessToast(res?.message);
            getBookReportData(currentPage, recordsPerPage);
            setBookHistoryData({
              FullName: "",
              email: "",
              phoneNumber: "",
              paymentStatus: "",
              Address: "",
              finalAmount: "",
              pincode: "",
            });
            setAddBookData([
              {
                id: "",
                quantity: "",
              },
            ]);
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
            setIsModelForAddHistory(false);
          } else {
            ErrorToast(res?.message);
            setIsLoading(false);
          }
        } catch (error) {
          ErrorToast(error?.message);
          setIsLoading(false);
        }
      } else {
        WarningToast("Please select empty Book.");
      }
    }
  };


  const _deleteBook = async () => {
    try {
      let data;
      if (deleteType === "multi") {
        const body = {
          ids: checkedItems,
        };
        data = await Api.postRequest(`api/order/deleteorder`, body);
      } else {
        const body = {
          ids: [SelectBookId],
        };
        data = await Api.postRequest(`api/order/deleteorder`, body);
      }
      let newData = JSON.parse(data?.data)
      if (newData?.status === 200) {
        SuccessToast(newData?.message);
        getBookReportData(currentPage, recordsPerPage);
        setIsModel(false);
        setCheckedItems([]);
        setSelectBookId([]);
        setOpenModel(false);
      } else {
        ErrorToast(newData?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };
  return (
    <div>
      <div className="h-full flex items-center justify-center ">
        <div className="h-full flex-1 flex flex-col space-y-5 lg:space-y-0 lg:flex-row lg:space-x-10 w-full sm:px-1 ">
          <div className="flex-1 px-2 sm:px-0 w-full">
            <div>
              <div className="flex justify-between items-center">
                <div>
                  <MasterTitle
                    title="Book History"
                    description="Below are the list of all book history."
                  />
                </div>
                <div className="flex items-center space-x-2">
                  {selectedQue.length > 0 && (
                    <div>
                      <Button
                        isLight
                        id=""
                        type="submit"
                        icon={TrashIcon}
                        label={"MULTI DELETE"}
                        abc="bg-[#D11212]"
                      />
                    </div>
                  )}
                  {userData?.type === "superadmin" && (
                    <div>
                      {!isExportLoader ? (
                        <Button
                          isLight
                          id=""
                          type="submit"
                          icon={ArrowDownTrayIcon}
                          label={"EXPORT"}
                          onClick={() =>
                            BookReportData?.length > 0
                              ? onExportHandler()
                              : null
                          }
                        />
                      ) : (
                        <Button
                          isLight
                          id=""
                          type="submit"
                          icon={ArrowDownTrayIcon}
                          label={"Loading..."}
                        >
                          Loading...
                        </Button>
                      )}
                    </div>
                  )}
                  <div>
                    {userData?.type !== "partner" && access?.edit && (
                      <Button
                        id=""
                        type="button"
                        label={"BOOK COMMENT"}
                        onClick={() =>
                          navigate("/shop/book-history/book-comment")
                        }
                      />
                    )}
                  </div>
                  <div>
                    {userData?.type !== "partner" && access?.add && (
                      <Button
                        id=""
                        type="submit"
                        icon={PlusIcon}
                        label={"ADD BOOK HISTORY"}
                        onClick={() => setIsModelForAddHistory(true)}
                      />
                    )}
                  </div>
                  <div style={{ display: "flex", gap: "3px" }}>
                  {access?.delete && (
                    <Button
                      id=""
                      type="submit"
                      icon={TrashIcon}
                      label={"DELETE BOOKHISTORY"}
                      onClick={() => {
                        setDeleteType("multi");
                        if (checkedItems.length > 0) {
                          setOpenModel(true);
                        }
                      }}
                    />
                  )}
                </div>
                </div>
             
                <Model
                  title={"View Book Details"}
                  show={isModel}
                  onCancel={() => {
                    setIsModel(false);
                  }}
                  onClose={() => {
                    setIsModel(false);
                  }}
                  cancelText=""
                  okText=""
                  isLoading={isLoading}
                  disabled={isLoading}
                  width="max-w-3xl"
                >
                  <div>
                    <table className="w-full">
                      <thead className="sticky top-0 bg-white z-10">
                        
                        <tr
                          className="border-b border-gray-200 text-left text-xs leading-4 font-bold text-[#7B809A] tracking-wider"
                          style={{ backgroundColor: "#f4f7f8" }}
                        >
                          {booktitleData.map(
                            (v, ind) =>
                              v?.title && (
                                <th key={ind} className="py-4 px-8 ">
                                  <div className="flex justify-between">
                                    <span>{v.title}</span>
                                  </div>
                                </th>
                              )
                          )}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {selectBookData.map((data, id) => (
                          <tr
                            key={id}
                            className="my-4 px-8 text-[#7B809A] font-reguler text-sm align-top"
                          >
                            <td className="lg:table-cell whitespace-nowrap px-8  py-2.5 ">
                              {data?.id}
                            </td>
                            <td className="lg:table-cell whitespace-nowrap px-8 py-2.5">
                              <p>{data.name}</p>
                            </td>
                            <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                              {data?.quantity}
                            </td>
                            <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                              {data?.price}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Model>
                <Model
                  title={"Add Book History"}
                  show={isModelForAddHistory}
                  onCancel={() => {
                    setBookHistoryData({
                      FullName: "",
                      email: "",
                      phoneNumber: "",
                      paymentStatus: "",
                      Address: "",
                      finalAmount: "",
                      pincode: "",
                    });
                    setAddBookData([
                      {
                        id: "",
                        quantity: "",
                      },
                    ]);
                    setIsModelForAddHistory(false);
                  }}
                  onClose={() => {
                    setBookHistoryData({
                      FullName: "",
                      email: "",
                      phoneNumber: "",
                      paymentStatus: "",
                      Address: "",
                      finalAmount: "",
                      pincode: "",
                    });
                    setAddBookData([
                      {
                        id: "",
                        quantity: "",
                      },
                    ]);
                    setIsModelForAddHistory(false);
                  }}
                  onOk={() => {
                    handleAddModelSubmit();
                  }}
                  okText="SUBMIT"
                  cancelText="CLOSE"
                  isLoading={isLoading}
                  disabled={isLoading}
                  width="max-w-3xl"
                >
                  <>
                    <table className="w-full">
                      <tbody className="divide-y divide-gray-0">
                        {AddBookData?.map((data, id) => (
                          <tr
                            key={id}
                            className="my-4 px-8 text-[#7B809A] font-reguler text-sm align-top"
                          >
                            <td className="hidden lg:table-cell whitespace-nowrap">
                              <div className="w-full">
                                <FormControl fullWidth>
                                  <Selected
                                    displayEmpty
                                    size="small"
                                    labelId="select-placeholder"
                                    className="font-regular"
                                    value={data?.id || ""}
                                    onChange={(e) =>
                                      handleChangeAddBookData(
                                        id,
                                        "id",
                                        e?.target?.value
                                      )
                                    }
                                    sx={{
                                      border: "none",
                                      background: "white",
                                      mr: 1,
                                      height: "37px",
                                      width: "300px",
                                      borderColor: "gray",
                                      color: "gray",
                                      "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
                                        {
                                          border: "1px solid #E5F1EB",
                                        },
                                      "& .MuiSelect-select": {
                                        fontSize: "0.8rem",
                                      },
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  >
                                    <MenuItem value="" disabled>
                                      Select Book
                                    </MenuItem>
                                    {[
                                      ...new Set(
                                        bookData.map((item) => item.name)
                                      ),
                                    ].map((uniqueName) => (
                                      <MenuItem
                                        key={uniqueName}
                                        value={
                                          bookData.find(
                                            (item) => item.name === uniqueName
                                          ).id
                                        }
                                      >
                                        {uniqueName}
                                      </MenuItem>
                                    ))}
                                  </Selected>
                                </FormControl>
                              </div>
                            </td>
                            <td className="hidden lg:table-cell whitespace-nowrap">
                              <div
                                className="w-full"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <label> Quantity:</label>
                                <input
                                  className="block w-20 appearance-none rounded-md border border-gray-200 px-3 py-2 text-gray-900 placeholder-[#A0AEC0]focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                                  type="number"
                                  min="1"
                                  value={data?.quantity || "1"}
                                  onChange={(e) =>
                                    handleChangeAddBookData(
                                      id,
                                      "quantity",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            </td>
                            <td className="hidden lg:table-cell whitespace-nowrap">
                              <div
                                className="w-full"
                                style={{ marginLeft: "10px" }}
                              >
                                <Button
                                  id=""
                                  type="submit"
                                  icon={MinusIcon}
                                  onClick={() =>
                                    AddBookData?.length > 1
                                      ? handleMinusBookdata(id)
                                      : null
                                  }
                                />
                              </div>
                            </td>
                            <td className="hidden lg:table-cell whitespace-nowrap">
                              <div
                                className="w-full"
                                style={{ marginLeft: "10px" }}
                              >
                                <Button
                                  id=""
                                  type="submit"
                                  icon={PlusIcon}
                                  onClick={() => handleAddBookdata()}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                      <div className="w-full">
                        <Input
                          name="FullName"
                          type="text"
                          placeholder="Enter your full name"
                          label="Full Name"
                          required
                          onChange={(e) =>
                            handleChange("FullName", e?.target?.value)
                          }
                          value={BookHistoryData?.FullName || ""}
                        />
                        <Typography variant="caption" color={"error"}>
                          {!BookHistoryData?.FullName ? error?.FullName : ""}
                        </Typography>
                      </div>
                      <div className="w-full">
                        <Input
                          name="email"
                          type="email"
                          placeholder="Enter your email"
                          label="Email"
                          required
                          onChange={(e) =>
                            handleChange("email", e?.target?.value)
                          }
                          value={BookHistoryData?.email || ""}
                        />
                        <Typography variant="caption" color={"error"}>
                          {!BookHistoryData?.email || BookHistoryData?.email
                            ? error?.email
                            : ""}
                        </Typography>
                      </div>
                    </div>
                    <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                      <div className="w-full">
                        <Input
                          name="phoneNumber"
                          type="number"
                          placeholder="Enter your phone number"
                          label="Phone Number"
                          required
                          onChange={(e) =>
                            handleChange("phoneNumber", e?.target?.value)
                          }
                          value={BookHistoryData?.phoneNumber || ""}
                        />
                        <Typography variant="caption" color={"error"}>
                          {!BookHistoryData?.phoneNumber ||
                          BookHistoryData?.phoneNumber
                            ? error?.phoneNumber
                            : ""}
                        </Typography>
                      </div>
                      <div className="w-full">
                        <label className="mb-0 block text-sm font-medium text-[#252F40] font-poppins">
                          Select Payment Status
                          {/* <span className="text-red-500 text-sm"> * </span> */}
                        </label>
                        <FormControl fullWidth sx={{ marginTop: "12px" }}>
                          <Selected
                            size="small"
                            value={BookHistoryData?.paymentStatus || ""}
                            labelId="select-placeholder"
                            className="font-regular"
                            onChange={(e) =>
                              handleChange("paymentStatus", e?.target?.value)
                            }
                            displayEmpty
                            sx={{
                              border: "none",
                              background: "white",
                              mr: 1,
                              height: "37px",
                              borderColor: "gray",
                              color: "gray",
                              "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
                                {
                                  border: "1px solid #E5F1EB",
                                },
                              "& .MuiSelect-select": {
                                fontSize: "0.8rem",
                              },
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            <MenuItem value="" sx={{ fontSize: "0.8rem" }}>
                              Select payment status
                            </MenuItem>
                            <MenuItem
                              value={"COMPLETED"}
                              sx={{ fontSize: "0.8rem" }}
                            >
                              COMPLETED
                            </MenuItem>
                            <MenuItem
                              value={"PENDING"}
                              sx={{ fontSize: "0.8rem" }}
                            >
                              PENDING
                            </MenuItem>
                            <MenuItem
                              value={"SUCCESS"}
                              sx={{ fontSize: "0.8rem" }}
                            >
                              SUCCESS
                            </MenuItem>
                            <MenuItem
                              value={"FAILED"}
                              sx={{ fontSize: "0.8rem" }}
                            >
                              FAILED
                            </MenuItem>
                          </Selected>
                        </FormControl>
                        {/* <Typography variant="caption" color={"error"}>
                          {!BookHistoryData?.paymentStatus
                            ? error?.paymentStatus
                            : ""}
                        </Typography> */}
                      </div>
                    </div>
                    <div>
                      <TextArea
                        id="Address"
                        name="Address"
                        label="Address"
                        placeholder="Full address here..."
                        type="text"
                        autoComplete="given-name"
                        required
                        className="w-full mb-2"
                        onChange={(e) =>
                          handleChange("Address", e?.target?.value)
                        }
                        value={BookHistoryData?.Address || ""}
                      />
                      <Typography variant="caption" color={"error"}>
                        {!BookHistoryData?.Address ? error?.Address : ""}
                      </Typography>
                    </div>
                    <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                      <div className="w-full">
                        <Input
                          name="finalAmount"
                          type="number"
                          placeholder="Enter your final amount"
                          label="Final Amount"
                          required
                          onChange={(e) =>
                            handleChange("finalAmount", e?.target?.value)
                          }
                          value={BookHistoryData?.finalAmount || ""}
                        />
                        <Typography variant="caption" color={"error"}>
                          {!BookHistoryData?.finalAmount
                            ? error?.finalAmount
                            : ""}
                        </Typography>
                      </div>
                      <div className="w-full">
                        <Input
                          name="pincode"
                          type="number"
                          placeholder="Enter your pincode"
                          label="Pincode"
                          required
                          onChange={(e) =>
                            handleChange("pincode", e?.target?.value)
                          }
                          value={BookHistoryData?.pincode || ""}
                        />
                        <Typography variant="caption" color={"error"}>
                          {!BookHistoryData?.pincode || BookHistoryData?.pincode
                            ? error?.pincode
                            : ""}
                        </Typography>
                      </div>
                    </div>
                  </>
                </Model>
              </div>

              <div className="w-full py-2 sm:px-0 flex items-center gap-x-4 overflow-x-auto">
                <div className="w-full mt-7">
                  <Input
                    required
                    disabled={false}
                    name=""
                    type="number"
                    className="font-regular"
                    placeholder="Search Order Id/Phone here..."
                    value={searchPhone}
                    onChange={handleSearchPhoneChange}
                  />
                </div>
                <div className="w-full mt-6">
                  <FormControl fullWidth sx={{ marginTop: "5px" }}>
                    <Selected
                      displayEmpty
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="font-regular"
                      value={searchBook}
                      onChange={handleSearchBookChange}
                      size="small"
                      sx={{
                        border: "none",
                        background: "white",
                        height: "38px",
                        color: "grey",
                        mr: 1,
                        "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #E5F1EB",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem value="">Select Book</MenuItem>
                      {[...new Set(bookData.map((item) => item.name))].map(
                        (uniqueName) => (
                          <MenuItem
                            key={uniqueName}
                            value={
                              bookData.find((item) => item.name === uniqueName)
                                .id
                            }
                          >
                            {uniqueName}
                          </MenuItem>
                        )
                      )}
                    </Selected>
                  </FormControl>
                </div>
                <div className="w-full mt-6">
                  <FormControl fullWidth sx={{ marginTop: "5px" }}>
                    <Selected
                      displayEmpty
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={searchStatus}
                      className="font-regular"
                      onChange={handleSearchStatusChange}
                      size="small"
                      sx={{
                        border: "none",
                        background: "white",
                        color: "grey",
                        mr: 1,
                        height: "38px",
                        "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #E5F1EB",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem value="">Select Status</MenuItem>
                      <MenuItem value="COMPLETED">COMPLETED</MenuItem>
                      <MenuItem value="PENDING">PENDING</MenuItem>
                      <MenuItem value="SUCCESS">SUCCESS</MenuItem>
                      <MenuItem value="FAILED">FAILED</MenuItem>
                    </Selected>
                  </FormControl>
                </div>
                <div className="w-full">
                  <Input
                    disabled={false}
                    name=""
                    label="From Date :"
                    type="date"
                    className="font-regular"
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                </div>

                <div className="w-full">
                  <Input
                    disabled={false}
                    name=""
                    label="To Date :"
                    type="date"
                    className="font-regular"
                    placeholder="To Date"
                    value={endDate}
                    onChange={handleEndDateChange}
                  />
                </div>
                <div className="mt-10">
                  <Button
                    id=""
                    type="button"
                    label={"SEARCH"}
                    onClick={handleSearchFilter}
                  />
                </div>
                <div className="mt-10">
                  <Button
                    onClick={() => resetFilter()}
                    id=""
                    type="button"
                    label={"RESET"}
                  />
                </div>
              </div>

              <div className="sm:mb-0 overflow-hidden">
                <div className="flex flex-col mt-2">
                  <section
                    className="bg-white rounded-2xl flex flex-col shadow-sm "
                    style={{
                      width: "100%",
                      height: "75vh",
                      margin: "0 auto",
                      padding: "10px",
                    }}
                  >
                    {" "}
                    <div className="flex justify-between items-center mt-3 px-8 mb-2">
                      <div style={{ display: "flex", gap:'5px' }}>
                      <Input
                        id=""
                        name="Search"
                        type="text"
                        placeholder="Search here..."
                        autoComplete="given-name"
                        required
                        className="w-72"
                        value={searchValue}
                        onChange={handleSearchChange}
                      />
                      <Button
                          onClick={() => handleSearchFilter()}
                          id=""
                          type="button"
                          // label={"SEARCH"}
                          searchicon={true}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        {userData?.type === "partner" && (
                          <Typography>Total Amount:- {amount}</Typography>
                        )}
                        <Records
                          recordsPerPage={recordsPerPage}
                          setCurrentPage={setCurrentPage}
                          setRecordsPerPage={setRecordsPerPage}
                          dataCount={dataCount}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        overflowY: "auto",
                        maxHeight: "58vh",
                        height: "60vh",
                        width: "100%",
                      }}
                    >
                      {" "}
                      {!isLoader && BookReportData?.length <= 0 ? (
                        <NoDataFound />
                      ) : (
                        <table style={{ width: "100%" }}>
                          <thead
                            style={{
                              position: "sticky",
                              top: 0,
                              backgroundColor: "#f4f7f8",
                              zIndex: 1,
                            }}
                          >
                            <tr
                              style={{
                                borderBottom: "1px solid #dddddd",
                                textAlign: "left",
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "#7B809A",
                                textTransform: "uppercase",
                                backgroundColor: "#f4f7f8",
                              }}
                            >
                               <th
                            className="px-6 py-3 whitespace-no-wrap"
                            scope="col"
                            style={{
                              width: "3%",
                              padding: "25px",
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            <Checkbox
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                              {" "}
                              {titleData.map(
                                (v, ind) =>
                                  v?.title && (
                                    <th
                                      key={ind}
                                      className="py-4 px-8 "
                                      style={{
                                        padding: "10px",
                                        backgroundColor: "#f4f7f8",
                                        position:
                                          v.title === "VIEW"|| v.title === "MOBILE" || v.title === "AMOUNT" || v.title === "DATE"? "sticky" : "",
                                        right: v.title === "DATE" ? "315px" :v.title === "AMOUNT" ? "228px" :v.title === "MOBILE" ? "100px" :v.title === "VIEW" ? "0" : "",
                                      }}
                                    >
                                      <div className="flex justify-between">
                                        <span>{v.title}</span>
                                        {v.title !== "VIEW"&&v.title !== "DATE" &&v.title !== "AMOUNT" &&v.title !== "MOBILE" && (
                                          <span>
                                            <ChevronUpDownIcon
                                              onClick={() => {
                                                let res = SortData(
                                                  BookReportData,
                                                  v.fieldName,
                                                  v.sort
                                                );

                                                let copyData = [...titleData];
                                                copyData[ind].sort =
                                                  v.sort > 0 ? -1 : 1;
                                                setTitleData(copyData);
                                                setBookReportData(res);
                                              }}
                                              className="w-5 h-5"
                                            />
                                          </span>
                                        )}
                                      </div>
                                    </th>
                                  )
                              )}
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200">
                            {isLoader ? (
                              <tr style={{ height: "25rem" }}>
                                <td
                                  colSpan={14}
                                  style={{ textAlign: "center" }}
                                >
                                  <CircularProgress />
                                </td>
                              </tr>
                            ) : (
                              BookReportData.map((data, id) => (
                                <tr
                                  key={id}
                                  className="my-4 px-8 text-[#000] font-bold text-sm align-top"
                                  style={{
                                    backgroundColor:
                                      data?.paymentStatus === "COMPLETED" ||
                                      data?.paymentStatus === "SUCCESS"
                                      ? "#8cfb8c"
                                      : "#fb6868",
                                  }}
                                >
                                   <td className="px-6 py-3 whitespace-no-wrap">
                                <Checkbox
                                  checked={checkedItems.includes(data.id)}
                                  onChange={() => handleCheckboxChange(data.id)}
                                />
                              </td>
                                  <td className="lg:table-cell whitespace-nowrap px-8  py-2.5 ">
                                    {id + 1}
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap px-8 py-2.5">
                                    <p>{data.id}</p>
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 "
                                   style={{
                                    width: "200px",
                                    display: "inline-block",
                                    whiteSpace: "pre-line",
                                  }}>
                                    {data.bookInfo?.length > 0
                                      ? data.bookInfo
                                          ?.map((book, index) => book.name)
                                          .join(", ")
                                      : "-"}
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                                    {data?.fullName ? data?.fullName : "-"}
                                  </td>
                                  {userData?.type !== "partner" && (
                                    <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                                      <box
                                        style={{
                                          width: "200px",
                                          display: "inline-block",
                                          whiteSpace: "pre-line",
                                        }}
                                      >
                                        {data?.fullAdress
                                          ? data?.fullAdress
                                          : "-"}
                                      </box>
                                    </td>
                                  )}
                                    <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                                    {data.pincode}
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                                    {data.email}
                                  </td>
                                 
                                
                                  <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                                    {data.discountPrice
                                      ? data?.discountPrice?.toFixed(2)
                                      : "-"}
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                                    {data.partnerRevenue
                                      ? data?.partnerRevenue?.toFixed(2)
                                      : "-"}
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                                    {data.promocodeInfo?.name
                                      ? data.promocodeInfo?.name
                                      : "-"}
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                                    {data.paymentStatus}
                                  </td>
                                
                               
                                  {userData?.type !== "partner" && (
                                    <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                                      {data?.comment?.map((comment) => (
                                        <div
                                          style={{
                                            color:
                                              comment?.color === "BLUE PURPLE"
                                                ? "#6A5ACD"
                                                : comment?.color,
                                            width: "250px",
                                            whiteSpace: "normal",
                                          }}
                                        >
                                          {comment?.description}
                                          {comment?.description ? (
                                            <div
                                              style={{
                                                color: "#7B809A",
                                                paddingBottom: "20px",
                                                marginTop: "10px",
                                              }}
                                            >
                                              Note By :{" "}
                                              {comment?.userInfo?.username} at{" "}
                                              {moment(
                                                comment?.createdAt
                                              ).format(
                                                "D MMMM, YYYY | hh:mm A"
                                              )}
                                              <Divider />
                                            </div>
                                          ) : (
                                            "-"
                                          )}
                                        </div>
                                      ))}
                                    </td>
                                    
                                  )}
                                     <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 " style={{
                                    position: "sticky",
                                    right: "315px",

                                    backgroundColor:
                                    data?.paymentStatus === "COMPLETED" ||
                                    data?.paymentStatus === "SUCCESS"
                                    ? "#8cfb8c"
                                    : "#fb6868",
                                  }} >
                                    {data.createdAt !== null
                                      ? moment(data.createdAt).format(
                                          "D MMMM, YYYY | hh:mm A"
                                        )
                                      : "-"}
                                  </td>
                                    <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 " style={{
                                    position: "sticky",
                                    right: "228px",

                                    backgroundColor:
                                    data?.paymentStatus === "COMPLETED" ||
                                    data?.paymentStatus === "SUCCESS"
                                    ? "#8cfb8c"
                                    : "#fb6868",
                                  }} >
                                    {data.finalAmount}
                                  </td>
                               
                                  <td className="lg:table-cell whitespace-nowrap px-6 py-2.5"
                                   style={{
                                    position: "sticky",
                                    right: "100px",

                                    backgroundColor:
                                    data?.paymentStatus === "COMPLETED" ||
                                    data?.paymentStatus === "SUCCESS"
                                    ? "#8cfb8c"
                                    : "#fb6868",
                                  }} >
                               
                                    {data.mobileNo}
                                  </td>
                                 
                                  {access?.view && (
                                    <td
                                      className="relative whitespace-nowrap  p-6 py-2.5  text-left"
                                      style={{
                                        position: "sticky",
                                        right: "0px",
                                        backgroundColor:
                                          data?.paymentStatus === "COMPLETED" ||
                                          data?.paymentStatus === "SUCCESS"
                                          ? "#8cfb8c"
                                          : "#fb6868",
                                      }}
                                    >
                                      <ActionButton
                                        // key={data.key}
                                        action="view"
                                        onClick={() => {
                                          if (data?.bookInfo?.length !== 0) {
                                            setIsModel(true);
                                            setSelectBookData(data?.bookInfo);
                                          }
                                        }}
                                      />
                                    </td>
                                  )}
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      recordsPerPage={recordsPerPage}
                      data={BookReportData}
                      numbers={numbers}
                      npage={npage}
                      lastIndex={lastIndex}
                      firstIndex={firstIndex}
                      dataCount={dataCount}
                    />
                  </section>
                </div>
                <DeletePopup
              title={"Delete"}
              show={openModel}
              description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
              onCancel={() => {
                setOpenModel(false);
                setSelectBookId("");
              }}
              onClose={() => {
                setOpenModel(false);
                setSelectBookId("");
              }}
              onDelete={() => {
                _deleteBook();
              }}
              cancelButtonRef={cancelButtonRef}
              okText="Delete"
              cancelText="Cancel"
            />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookReport;
