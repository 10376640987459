import React, { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "./Pagination";
import { Fragment, useRef } from "react";
import {
  ArrowDownTrayIcon,
  ChevronUpDownIcon,
  ExclamationTriangleIcon,
  PencilIcon,
  TrashIcon,
  PlusIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import { Input, TextArea } from "./Input";
import MasterTitle from "./MasterTitle";
import { Checkbox } from "./Checkbox";
import Records from "./Records";
import { SortData } from "./SortData";
import FloatNotification from "./FloatNotification";
import Model from "./Model";
import Select from "./Select";
import Selected from "@mui/material/Select";
import { Button } from "./Button";
import CircularProgress from "@mui/material/CircularProgress";
import NoDataFound from "./NoDataFound";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast } from "./toast";
import moment from "moment";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { FormControl, InputLabel, MenuItem, Typography } from "@mui/material";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import ActionButton from "./ActionButton";
import { CKEditor } from "ckeditor4-react";
import { getDecryptedDataFromLocalStorage } from "../Api/encryption";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import App from '../App.css';
import dayjs from "dayjs";
import { editorUrl } from "./constants/CkeditorUrl";
const OrderHistory = (props) => {
  const navigate = useNavigate();
  const access = props?.access?.filter((e) => e?.name === "Paper")?.[0];
  const userData = getDecryptedDataFromLocalStorage("userData");
  const [modelType, setModelType] = useState("");
  const [isModel, setIsModel] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [selectedQue, setSelectedQue] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const cancelButtonRef = useRef(null);
  const [error, setError] = useState({});
  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModelLoader, setisModelLoader] = useState(false);
  const [selectionDataBoard, setSelectionDataBoard] = useState([]);
  const [selectionDataMedium, setSelectionDataMedium] = useState([]);
  const [selectionDataStandard, setSelectionDataStandard] = useState([]);
  const [selectionDataSemester, setSelectionDataSemester] = useState([]);
  const [selectionDataSubject, setSelectionDataSubject] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedSubcribption, setSelectedSubcribption] = useState(1);

  const [boardData, setBoardData] = useState([]);
  const [mediumData, setMediumData] = useState([]);
  const [standardData, setStandardData] = useState([]);
  const [semesterData, setSemesterData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);

  const [papergenfilter, setpapergenfilter] = useState({
    board: "",
    medium: "",
    standard: "",
    semester: "",
    subject: "",
  });
  const [isResetFilter, setIsResetFilter] = useState(false);
  const [isExportLoader, setIsExportLoader] = useState(false);

  const currentdatetime = moment().format("YYYY-MM-DDTHH:mm:ss");

  const [selectedManualTrxDateTime, setSelectedManualTrxDateTime] =
    useState(null);

  const handleChangeManualTrxDateTime = (newValue) => {
    setSelectedManualTrxDateTime(newValue);
  };

  const [titleData, setTitleData] = useState([
    {
      title: "MOBILE",
      sort: 1,
      fieldName: "mobile",
    },
    {
      title: "MEDIUM",
      sort: 1,
      fieldName: "medium",
    },
    {
      title: "STANDARD",
      sort: 1,
      fieldName: "standard",
    },
     
    {
      title: "SEMESTER",
      sort: 1,
      fieldName: "semester",
    },
    {
      title: "SUBJECT",
      sort: 1,
      fieldName: "subject",
    },
    {
      title: "EXPIRED AT",
      sort: 1,
      fieldName: "expires",
    },
    {
      title: "ORDER COMMENTS",
      sort: 1,
      fieldName: "order_comments",
    },
    {
      title: "USER COMMENTS",
      sort: 1,
      fieldName: "user_comments",
    },
    {
      title: "AMOUNT",
      sort: 1,
      fieldName: "amount",
    },
    {
      title: "STATUS",
      sort: 1,
      fieldName: "status",
    },
    {
      title: "FOLLOWUP STATUS",
      sort: 1,
      fieldName: "followup_status",
    },
    {
      title: "Added/Modified",
      sort: 1,
      fieldName: "adder",
    },
  

   

   
    {
      title: "ORDER STATUS",
      sort: 1,
      fieldName: "order_status",
    },
    
    {
      title: "PURCHASE AT",
      sort: 1,
      fieldName: "purchase",
    },

    // {
    //   title: "L.UPDATE AT",
    //   sort: 1,
    //   fieldName: "update",
    // },
   
    
   

    {
      title: "USER NAME",
      sort: 1,
      fieldName: "user_name",
    },
    {
      title: "SR.No",
      sort: 1,
      fieldName: "sr_no",
    },
    {
      title: "BOARD",
      sort: 1,
      fieldName: "board",
    },
    
    // {
    //   title: " ORDDER/ MODIFIEER",
    //   sort: 1,
    //   fieldName: "modifieer",
    // },
    // {
    //   title: "NOTE",
    //   sort: 1,
    //   fieldName: "note",
    // },

    { title: access?.edit ? "EDIT" : "", sort: 1, fieldName: "" },
  ]);

  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [OrderHistoryData, setOrderHistoryData] = useState([]);
  const [OrderHistoryModelData, setOrderHistoryModelData] = useState({
    mobile: "",
    expiryDate: "",
    board: "",
    medium: "",
    standard: "",
    semester: "",
    subject: "",
    Amount: "",
    O_Status: "SUCCESS",
    Status: "True",
  });
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(dataCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [isLoader, setIsLoader] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [O_Status, setO_Status] = useState("");
  const [isSubscriptionActive, setIsSubscriptionActive] = useState("");
  const [FollowupStatus, setFollowupStatus] = useState("");

  const ansRef = useRef(null);
  const [BookNotificationData, setBookNotificationData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const [boardModelData, setBoardModelData] = useState([]);
  const [mediumModelData, setMediumModelData] = useState([]);
  const [standardModelData, setStandardModelData] = useState([]);
  const [semesterModelData, setSemesterModelData] = useState([]);
  const [subjectModelData, setSubjectModelData] = useState([]);

  const [isModelNotification, setIsModelNotification] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false);
  const [errorNotification, setErrorNotification] = useState({});
  const [NotificationModelData, setNotificationModelData] = useState({
    title: "",
    description: "",
    type: "",
    htmldata: "",
    linkdata: "",
    bookdata: "",
  });

  const [NotificationFilter, setNotificationFilter] = useState({
    board: "",
    medium: "",
    standard: "",
    semester: "",
    subject: "",
  });

  const handleO_StatusChange = (event) => {
    setO_Status(event.target.value);
  };

  const handleIsSubscriptionActiveChange = (event) => {
    setIsSubscriptionActive(event.target.value);
  };

  const handleFollowupStatusChange = (event) => {
    setFollowupStatus(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    setCurrentPage(1);
  };

  function formatTimestamp(timestamp) {
    if (!timestamp) return "-"; // Return "-" if timestamp is falsy

    const date = moment.utc(timestamp).format("DD MMM YYYY hh:mm A");

    return date;
  }

  const [expiryData, setExpiryData] = useState([]);

  const getExpiryDate = async () => {
    try {
      const data = await Api.getRequest(`api/expiryDate`);
      const req = JSON.parse(data.data);
      const formattedDate = req.data?.[0]?.expiryDate
        ? new Date(req.data[0].expiryDate).toISOString().split("T")[0]
        : "";
      setExpiryData(formattedDate);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    getExpiryDate();
  }, []);

  useEffect(() => {
    getOrderHistoryData(currentPage, recordsPerPage);
  }, [recordsPerPage, currentPage]);

  const getOrderHistoryData = async () => {
    try {
      setSelectAll(false)
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/subscription/getSubjectPurchaseOrderHistory?offset=${currentPage}&limit=${recordsPerPage}&search=${searchValue}&board=${papergenfilter?.board?.id || ""
        }&medium=${papergenfilter?.medium?.id || ""}&standardId=${papergenfilter?.standard?.id || ""
        }&semesterId=${papergenfilter?.semester?.id || ""}&subjectId=${papergenfilter?.subject?.id || ""
        }&paymentStatus=${O_Status || ""
        }&isSubscriptionActive=${isSubscriptionActive}&startDate=${startDate || ""
        }&endDate=${endDate || ""}&followupStatus=${FollowupStatus || ""}`
      );
      const req = JSON.parse(data);
      if (req?.success === true) {
        setOrderHistoryData(req?.data);
        setDataCount(req?.totalCount);
        setCheckedItems([]);
        setIsLoader(false);
      } else {
        ErrorToast(req?.message);
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setCheckedItems([]);
    } else {
      const allIds = OrderHistoryData.map((data) => data.id);
      setCheckedItems(allIds);
    }
    setSelectAll(!selectAll);
  };


  const onExportHandler = async () => {
    setIsExportLoader(true);
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    try {
      const { data } = await Api.getRequest(
        `api/subscription/getSubjectPurchaseOrderHistory?search=${searchValue}&board=${papergenfilter?.board?.id || ""
        }&medium=${papergenfilter?.medium?.id || ""}&standardId=${papergenfilter?.standard?.id || ""
        }&semesterId=${papergenfilter?.semester?.id || ""}&subjectId=${papergenfilter?.subject?.id || ""
        }&paymentStatus=${O_Status || ""
        }&isSubscriptionActive=${isSubscriptionActive}&startDate=${startDate || ""
        }&endDate=${endDate || ""}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        const exportFormShape = req?.data
          .sort((a, b) => b.id - a.id)
          .map((Order) => {
            return {
             
              "Mobile No.": Order?.mobileNo || "-",
              "Board": Order?.SubscriptionPriceInfo?.StandardInfo?.boardInfo?.board_name || "-",
              "Medium": Order?.SubscriptionPriceInfo?.StandardInfo?.mediumInfo?.name || "-",
              "Standard": Order?.SubscriptionPriceInfo?.StandardInfo?.name || "-",
              Standard: Order?.SubscriptionPriceInfo?.StandardInfo?.name || "-",
              Semester:
                Order?.SubscriptionPriceInfo?.SemesterInfo?.semester || "-",
              Subject:
                Order?.SubscriptionPriceInfo?.SubjectInfo?.subject || "-",
              Amount: Order?.price || "-",
              "Followup Status": Order?.followupStatus || "-",
              "Added/Modified By": Order?.adminInfo?.username || "-",
              "Order Status": Order?.paymentStatus || "-",
              "Status" : Order?.isSubscriptionActive == 1 ? "TRUE" : "FALSE",
              "Order Comment" : Order?.SubscriptionCommentInfo.length > 0 ? Order?.SubscriptionCommentInfo?.[0].description : "-" || "-",
              "User Comment" : Order?.userInfo?.comment.length > 0 ? Order?.userInfo?.comment?.[0]?.description : "-" || "-",
              "Purchase At": formatTimestamp(Order?.createdAt) || "-",
              "Expired At": Order?.expiryDate || "-",
              Name: Order?.userInfo?.username || "-",
              "Sr No.": Order?.id,
            };
          });

        const ws = XLSX.utils.json_to_sheet(exportFormShape);

        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, {
          bookType: "xlsx",
          type: "array",
        });

        const dataBlob = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(dataBlob, "Order History" + fileExtension);
        setIsExportLoader(false);
      } else {
        setIsExportLoader(false);
        // Handle error or other cases
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsExportLoader(false);
    }
  };

  const validation = () => {
    let errors = {};
    let formIsValid = true;
    const phoneNumberRegex = /^\d{10}$/;
    if (!OrderHistoryModelData?.phoneNumber) {
      formIsValid = false;
      errors["phoneNumber"] = "Please Enter Phone Number.";
    } else if (!phoneNumberRegex.test(OrderHistoryModelData?.phoneNumber)) {
      formIsValid = false;
      errors["isValidPhoneNumber"] = "Please Enter a 10-digit Phone Number.";
    }
    // if (!OrderHistoryModelData?.expiryDate) {
    //   formIsValid = false;
    //   errors["expiryDate"] = "Please select expiry date.";
    // }
    if (!OrderHistoryModelData?.board) {
      formIsValid = false;
      errors["board"] = "Please select board.";
    }
    if (!OrderHistoryModelData?.medium) {
      formIsValid = false;
      errors["medium"] = "Please select medium.";
    }
    if (!OrderHistoryModelData?.standard) {
      formIsValid = false;
      errors["standard"] = "Please select standard.";
    }
    if (!OrderHistoryModelData?.semester) {
      formIsValid = false;
      errors["semester"] = "Please select semester.";
    }
    if (!OrderHistoryModelData?.subject) {
      formIsValid = false;
      errors["subject"] = "Please select subject.";
    }
    // if (!OrderHistoryModelData?.Amount) {
    //   formIsValid = false;
    //   errors["Amount"] = "Please Enter price.";
    // } else if (
    //   isNaN(Number(OrderHistoryModelData?.Amount)) ||
    //   Number(OrderHistoryModelData?.Amount) <= 0 // Change the condition to check if it's less than or equal to zero
    // ) {
    //   formIsValid = false;
    //   errors["Amount"] = "Please Enter a non-negative number for Amount";
    // }
    setError(errors);
    return formIsValid;
  };

  const getBoardData = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setSelectionDataBoard(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getMediumData = async () => {
    try {
      let body = `?board=${OrderHistoryModelData?.board?.id}`;
      const { data } = await Api.getRequest(`api/medium${body}`);
      const req = JSON.parse(data);
      setSelectionDataMedium(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getStandardData = async () => {
    try {
      let body = `?board=${OrderHistoryModelData?.board?.id}&medium=${OrderHistoryModelData?.medium?.id}`;
      const { data } = await Api.getRequest(
        `api/standard/standardWithPrice${body}`
      );
      const req = JSON.parse(data);
      setSelectionDataStandard(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSemesterData = async () => {
    try {
      let body = `?board=${OrderHistoryModelData?.board?.id}&medium=${OrderHistoryModelData?.medium?.id}&standard=${OrderHistoryModelData?.standard?.id}`;
      const { data } = await Api.getRequest(
        `api/semester/semesterWithPrice${body}`
      );
      const req = JSON.parse(data);
      setSelectionDataSemester(req?.data?.rows);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSubjectData = async () => {
    try {
      let body = `?board=${OrderHistoryModelData?.board?.id}&medium=${OrderHistoryModelData?.medium?.id}&standard=${OrderHistoryModelData?.standard?.id}&semester=${OrderHistoryModelData?.semester?.id}`;
      const { data } = await Api.getRequest(
        `api/subject/subjectWithPrice${body}`
      );
      const req = JSON.parse(data);
      setSelectionDataSubject(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    getBoardData();
    getAllBoard();
  }, []);

  useEffect(() => {
    if (isModelNotification) {
      getBoardModelData();
      getBookNotificationData();
    }
  }, [isModelNotification]);

  useEffect(() => {
    if (OrderHistoryModelData?.board) {
      getMediumData();
    }
  }, [OrderHistoryModelData?.board]);

  useEffect(() => {
    if (OrderHistoryModelData?.medium) {
      getStandardData();
    }
  }, [OrderHistoryModelData?.medium]);

  useEffect(() => {
    if (OrderHistoryModelData?.standard) {
      getSemesterData();
    }
  }, [OrderHistoryModelData?.standard]);

  useEffect(() => {
    if (OrderHistoryModelData?.semester) {
      getSubjectData();
    }
  }, [OrderHistoryModelData?.semester]);

  const handleChange = (name, value) => {
    setOrderHistoryModelData((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
    if (name === "board") {
      setOrderHistoryModelData((oldValue) => ({
        ...oldValue,
        medium: "",
        standard: "",
        semester: "",
        subject: "",
        [name]: value,
      }));
      setSelectionDataMedium([]);
      setSelectionDataStandard([]);
      setSelectionDataSemester([]);
      setSelectionDataSubject([]);
    }
    if (name === "medium") {
      setOrderHistoryModelData((oldValue) => ({
        ...oldValue,
        standard: "",
        semester: "",
        subject: "",
        [name]: value,
      }));
      setSelectionDataStandard([]);
      setSelectionDataSemester([]);
      setSelectionDataSubject([]);
    }
    if (name === "standard") {
      setOrderHistoryModelData((oldValue) => ({
        ...oldValue,
        semester: "",
        subject: "",
        [name]: value,
      }));
      setSelectionDataSemester([]);
      setSelectionDataSubject([]);
    }
    if (name === "semester") {
      setOrderHistoryModelData((oldValue) => ({
        ...oldValue,
        subject: "",
        [name]: value,
      }));
      setSelectionDataSubject([]);
    }
  };

  const _getById = async (id) => {
    setisModelLoader(true);
    if (id) {
      try {
        const { data } = await Api.getRequest(
          `api/subscription/getSubjectPurchaseOrderHistory/${id || ""}`
        );
        const req = JSON.parse(data);

        if (req?.status === 200) {
          setisModelLoader(false);
          const res = req.data;
          setOrderHistoryModelData((prevState) => ({
            ...prevState,
            board: res?.SubscriptionPriceInfo?.StandardInfo?.boardInfo,
            medium: res?.SubscriptionPriceInfo?.StandardInfo?.mediumInfo,
            standard: res?.SubscriptionPriceInfo?.StandardInfo,
            semester: res?.SubscriptionPriceInfo?.SemesterInfo,
            subject: res?.SubscriptionPriceInfo?.SubjectInfo,
            phoneNumber: res?.userInfo?.mobile,
            Amount: res?.price,
            O_Status: res?.paymentStatus,
            Status: res?.isSubscriptionActive === 1 ? "TRUE" : "FALSE",
            PurchaseAt: res?.createdAt,
            UpdateAt: res?.updatedAt,
            expiryDate: res?.expiryDate,
            adder: res?.adminInfo?.username,
          }));
          setSelectedSubcribption(res?.isSubscriptionActive);
          // setOrderHistoryData(req?.data);
        } else {
          ErrorToast(req.message);
        }
      } catch (error) {
        ErrorToast(error?.message);
      }
    }
  };

  useEffect(() => {
    if (selectedId) {
      _getById(selectedId);
    }
  }, [selectedId]);

  const formatDate = (dateTimeString) => {
    return dateTimeString
      ? format(new Date(dateTimeString), "MM/dd/yyyy, HH:mm:ss")
      : "";
  };

  const handleAddModelSubmit = async () => {
    if (validation()) {
      setIsLoading(true);
      if (modelType === "edit") {
        let body = {
          id: selectedId,
          mobile: OrderHistoryModelData?.phoneNumber,
          standard: OrderHistoryModelData?.standard?.id,
          semester: OrderHistoryModelData?.semester?.id,
          subject: OrderHistoryModelData?.subject?.id,
          price: OrderHistoryModelData?.Amount || 0,
          paymentStatus: OrderHistoryModelData?.O_Status,
          expiryDate: OrderHistoryModelData?.expiryDate,
          isSubscriptionActive: selectedSubcribption,
          adder: OrderHistoryModelData?.Adder,
        };
        try {
          const res = await Api.putRequest(
            `api/subscription/updateSubjectPurchaseOrderHistory`,
            body
          );
          if (res?.data?.status === 200) {
            SuccessToast(res?.data?.message);
            getOrderHistoryData(currentPage, recordsPerPage);
            setOrderHistoryModelData({
              mobile: "",
              expiryDate: "",
              board: "",
              medium: "",
              standard: "",
              semester: "",
              subject: "",
              Amount: "",
              O_Status: "",
              Status: "",
            });
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
            setIsModel(false);
            setSelectedId("");
            resetOrderHistory();
          } else {
            ErrorToast(res?.data?.message);
          }
        } catch (error) {
          ErrorToast(error?.message);
        }
      } else {
        let body = {
          mobile: OrderHistoryModelData?.phoneNumber,
          standard: OrderHistoryModelData?.standard?.id,
          semester: OrderHistoryModelData?.semester?.id,
          subject: OrderHistoryModelData?.subject?.id,
          price: OrderHistoryModelData?.Amount || 0,
          paymentStatus: OrderHistoryModelData?.O_Status,
          expiryDate: OrderHistoryModelData?.expiryDate || expiryData,
          isSubscriptionActive: 1,
          razorpayOrderId: "manual",
          adder: OrderHistoryModelData?.Adder,
        };
        try {
          const { data } = await Api.postRequest(
            `api/subscription/createManually`,
            body
          );
          const res = JSON.parse(data);

          if (res?.status === 200) {
            SuccessToast(res?.message);
            getOrderHistoryData(currentPage, recordsPerPage);
            setOrderHistoryModelData((prevState) => ({
              ...prevState,
              subject: "",
            }));
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
          } else {
            ErrorToast(res?.message);
            setIsLoading(false);
          }
        } catch (error) {
          ErrorToast(error?.message);
          setIsLoading(false);
        }
      }
    }
  };

  const resetOrderHistory = () => {
    setSelectedId("");
    setOrderHistoryModelData({
      mobile: "",
      expiryDate: "",
      board: "",
      medium: "",
      standard: "",
      semester: "",
      subject: "",
      Amount: "",
      O_Status: "SUCCESS",
      Status: "True",
    });
    setError({});
    setIsModel(false);
    setSelectionDataMedium([]);
    setSelectionDataStandard([]);
    setSelectionDataSemester([]);
    setSelectionDataSubject([]);
  };

  const getAllBoard = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setBoardData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getAllMedium = async () => {
    try {
      let body = `?board=${papergenfilter?.board?.id}`;
      const { data } = await Api.getRequest(`api/medium${body}`);
      const req = JSON.parse(data);
      setMediumData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getAllStandard = async () => {
    try {
      let body = `?board=${papergenfilter?.board?.id}&medium=${papergenfilter?.medium?.id}`;
      const { data } = await Api.getRequest(`api/standard${body}`);
      const req = JSON.parse(data);
      setStandardData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getAllSemester = async () => {
    try {
      let body = `?board=${papergenfilter?.board?.id}&medium=${papergenfilter?.medium?.id}&standard=${papergenfilter?.standard?.id}`;
      const { data } = await Api.getRequest(`api/semester${body}`);
      const req = JSON.parse(data);
      setSemesterData(req?.data?.rows);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getAllSubject = async () => {
    try {
      let body = `?board=${papergenfilter?.board?.id}&medium=${papergenfilter?.medium?.id}&standard=${papergenfilter?.standard?.id}&semester=${papergenfilter?.semester?.id}`;
      const { data } = await Api.getRequest(`api/subject${body}`);
      const req = JSON.parse(data);
      setSubjectData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    if (papergenfilter["board"] !== "") {
      getAllMedium();
    }
  }, [papergenfilter["board"]]);

  useEffect(() => {
    if (papergenfilter["medium"] !== "") {
      getAllStandard();
    }
  }, [papergenfilter["medium"]]);

  useEffect(() => {
    if (papergenfilter["standard"] !== "") {
      getAllSemester();
    }
  }, [papergenfilter["standard"]]);

  useEffect(() => {
    if (papergenfilter["semester"] !== "") {
      getAllSubject();
    }
  }, [papergenfilter["semester"]]);

  const handleFilter = (name, value) => {
    setpapergenfilter({
      ...papergenfilter,
      [name]: value,
    });
    if (name === "board") {
      setpapergenfilter((oldValue) => ({
        ...oldValue,
        medium: "",
        standard: "",
        semester: "",
        subject: "",
        [name]: value,
      }));
      setMediumData([]);
      setStandardData([]);
      setSemesterData([]);
      setSubjectData([]);
    }
    if (name === "medium") {
      setpapergenfilter((oldValue) => ({
        ...oldValue,
        standard: "",
        semester: "",
        subject: "",
        [name]: value,
      }));
      setStandardData([]);
      setSemesterData([]);
      setSubjectData([]);
    }
    if (name === "standard") {
      setpapergenfilter((oldValue) => ({
        ...oldValue,
        semester: "",
        subject: "",
        [name]: value,
      }));
      setSemesterData([]);
      setSubjectData([]);
    }
    if (name === "semester") {
      setpapergenfilter((oldValue) => ({
        ...oldValue,
        subject: "",
        [name]: value,
      }));
      setSubjectData([]);
    }
  };

  const applyFilter = () => {
    getOrderHistoryData(recordsPerPage, currentPage, papergenfilter);
  };

  const resetFilter = () => {
    setIsResetFilter(true);
    setpapergenfilter({
      board: "",
      medium: "",
      standard: "",
      semester: "",
      subject: "",
    });
    setO_Status("");
    setIsSubscriptionActive("");
    setFollowupStatus("");
    setStartDate("");
    setEndDate("");
    setMediumData([]);
    setStandardData([]);
    setSemesterData([]);
    setSubjectData([]);
  };

  useEffect(() => {
    if (isResetFilter) {
      getOrderHistoryData(recordsPerPage, currentPage, papergenfilter);
      setIsResetFilter(false);
    }
  }, [isResetFilter]);

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) => {
      const isChecked = prevState.includes(id);

      const updatedItems = isChecked
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id];

      return updatedItems;
    });
  };

  const getBookNotificationData = async () => {
    try {
      const { data } = await Api.getRequest(`api/books`);
      const req = JSON.parse(data);
      setBookNotificationData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getBoardModelData = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setBoardModelData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getMediumModelData = async () => {
    try {
      let body = `?board=${NotificationFilter?.board?.id}`;
      const { data } = await Api.getRequest(`api/medium${body}`);
      const req = JSON.parse(data);
      setMediumModelData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getStandardModelData = async () => {
    try {
      let body = `?board=${NotificationFilter?.board?.id}&medium=${NotificationFilter?.medium?.id}`;
      const { data } = await Api.getRequest(`api/standard${body}`);
      const req = JSON.parse(data);
      setStandardModelData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSemesterModelData = async () => {
    try {
      let body = `?board=${NotificationFilter?.board?.id}&medium=${NotificationFilter?.medium?.id}&standard=${NotificationFilter?.standard?.id}`;
      const { data } = await Api.getRequest(`api/semester${body}`);
      const req = JSON.parse(data);
      setSemesterModelData(req?.data?.rows);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSubjectModelData = async () => {
    try {
      let body = `?board=${NotificationFilter?.board?.id}&medium=${NotificationFilter?.medium?.id}&standard=${NotificationFilter?.standard?.id}&semester=${NotificationFilter?.semester?.id}`;
      const { data } = await Api.getRequest(`api/subject${body}`);
      const req = JSON.parse(data);
      setSubjectModelData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const handleFilterModel = (name, value) => {
    setNotificationFilter({
      ...NotificationFilter,
      [name]: value,
    });
    if (name === "board") {
      setNotificationFilter((oldValue) => ({
        ...oldValue,
        medium: "",
        standard: "",
        semester: "",
        subject: "",
        [name]: value,
      }));
      setMediumModelData([]);
      setStandardModelData([]);
      setSemesterModelData([]);
      setSubjectModelData([]);
    }
    if (name === "medium") {
      setNotificationFilter((oldValue) => ({
        ...oldValue,
        standard: "",
        semester: "",
        subject: "",
        [name]: value,
      }));
      setStandardModelData([]);
      setSemesterModelData([]);
      setSubjectModelData([]);
    }
    if (name === "standard") {
      setNotificationFilter((oldValue) => ({
        ...oldValue,
        semester: "",
        subject: "",
        [name]: value,
      }));
      setSemesterModelData([]);
      setSubjectModelData([]);
    }
    if (name === "semester") {
      setNotificationFilter((oldValue) => ({
        ...oldValue,
        subject: "",
        [name]: value,
      }));
      setSubjectModelData([]);
    }
  };

  useEffect(() => {
    if (NotificationFilter["board"] !== "") {
      getMediumModelData();
    }
  }, [NotificationFilter["board"]]);

  useEffect(() => {
    if (NotificationFilter["medium"] !== "") {
      getStandardModelData();
    }
  }, [NotificationFilter["medium"]]);

  useEffect(() => {
    if (NotificationFilter["standard"] !== "") {
      getSemesterModelData();
    }
  }, [NotificationFilter["standard"]]);

  useEffect(() => {
    if (NotificationFilter["semester"] !== "") {
      getSubjectModelData();
    }
  }, [NotificationFilter["semester"]]);

  const openNotificationModel = () => {
    setIsModelNotification(true);
  };

  const handleResetNotification = () => {
    setNotificationModelData({});
    setErrorNotification({});
    setSelectedManualTrxDateTime(null);
    setSelectedFile(null);
  };

  const validationNotification = () => {
    const linkPattern = /^(http|https):\/\/.+$/i;

    let errors = {};
    let formIsValid = true;

    if (!NotificationModelData?.title) {
      formIsValid = false;
      errors["title"] = "Please select title.";
    }
    if (!NotificationModelData?.description) {
      formIsValid = false;
      errors["description"] = "Please select description.";
    }
    if (!NotificationModelData?.type) {
      formIsValid = false;
      errors["type"] = "Please select type.";
    }

    if (NotificationModelData?.type === "html") {
      if (!NotificationModelData?.htmldata) {
        formIsValid = false;
        errors["htmldata"] = "Please enter Body.";
      }
    }

    if (NotificationModelData?.type === "link") {
      if (!NotificationModelData?.linkdata) {
        formIsValid = false;
        errors["linkdata"] = "Please enter link.";
      } else if (!linkPattern.test(NotificationModelData.linkdata.trim())) {
        formIsValid = false;
        errors["linkdata"] = "Please enter a valid HTTP or HTTPS link.";
      }
    }

    if (NotificationModelData?.type === "book") {
      if (!NotificationModelData?.bookdata) {
        formIsValid = false;
        errors["bookdata"] = "Please select book.";
      }
    }
    if (
      NotificationModelData?.type === "subject" ||
      NotificationModelData?.type === "subject_student"
    ) {
      if (!NotificationFilter?.board) {
        formIsValid = false;
        errors["board"] = "Please select board.";
      }
      if (!NotificationFilter?.medium) {
        formIsValid = false;
        errors["medium"] = "Please select medium.";
      }
      if (!NotificationFilter?.standard) {
        formIsValid = false;
        errors["standard"] = "Please select standard.";
      }
      if (!NotificationFilter?.semester) {
        formIsValid = false;
        errors["semester"] = "Please select semester.";
      }
      if (!NotificationFilter?.subject) {
        formIsValid = false;
        errors["subject"] = "Please select subject.";
      }
    }
    setErrorNotification(errors);
    return formIsValid;
  };

  const handleChangeNotification = (name, value) => {
    setNotificationModelData((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
    if (name === "type") {
      setNotificationModelData((oldValue) => ({
        ...oldValue,
        htmldata: "",
        linkdata: "",
        bookdata: "",
        [name]: value,
      }));
      setNotificationFilter({
        board: "",
        medium: "",
        standard: "",
        semester: "",
        subject: "",
      });
      setMediumModelData([]);
      setStandardModelData([]);
      setSemesterModelData([]);
      setSubjectModelData([]);
    }
  };

  const handleAnswerNotification = (e, name) => {
    const value = e?.editor?.getData();
    setNotificationModelData((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
  };

  const handleAddEditModelSubmit = async () => {
    if (validationNotification()) {
      setIsLoading(true);
      try {
        let imgData = [];
        if (selectedFile) {
          const formData = new FormData();
          formData.append("image", selectedFile);
          const responseimg = await Api.axiospostRequest(
            "api/upload/single_file",
            formData
          );

          if (responseimg.status === 200) {
            imgData = [responseimg?.data?.data];
          }
        }
        let body = {
          ...(imgData.length > 0 && {
            image: imgData[0],
          }),
          title: NotificationModelData?.title,
          body: NotificationModelData?.description,
          type: NotificationModelData?.type,
          data:
            NotificationModelData?.type === "html"
              ? NotificationModelData?.htmldata
              : NotificationModelData?.type === "link"
                ? NotificationModelData?.linkdata
                : NotificationModelData?.type === "book"
                  ? NotificationModelData?.bookdata.toString()
                  : NotificationModelData?.type === "subject" ||
                    NotificationModelData?.type === "subject_student"
                    ? JSON.stringify(NotificationFilter?.subject)
                    : "",
        };
        const response = await Api.postRequest("api/notification/create", body);
        const req = JSON.parse(response.data);
        if (req?.status === 200) {
          try {
            let body = {
              notificationId: req?.data?.id,
              customOrderIds: checkedItems,
              timescheduler: selectedManualTrxDateTime?.format(
                "YYYY-MM-DDTHH:mm:ss"
              ),
            };
            const { data } = await Api.postRequest(
              `api/notification/orderPushNotification?search=${searchValue || ""
              }&board=${papergenfilter?.board?.id || ""}&medium=${papergenfilter?.medium?.id || ""
              }&standardId=${papergenfilter?.standard?.id || ""}&semesterId=${papergenfilter?.semester?.id || ""
              }&subjectId=${papergenfilter?.subject?.id || ""}&paymentStatus=${O_Status || ""
              }&isSubscriptionActive=${isSubscriptionActive}&startDate=${startDate || ""
              }&endDate=${endDate || ""}&followupStatus=${FollowupStatus || ""
              }`,
              body
            );

            const req2 = JSON.parse(data);
            if (req2?.status === 200) {
              SuccessToast(req2?.message);
              // setIsModelNotification(false);
              // setNotificationModelData({});
              // setErrorNotification({});
              setSelectedFile(null);
              setNotificationFilter((prevState) => ({
                ...prevState,
                subject: "",
              }));
              // getOrderHistoryData(currentPage, recordsPerPage);
              setTimeout(() => {
                setIsLoading(false);
              }, 2000);
            } else {
              ErrorToast(req2?.message);
              setIsLoading(false);
            }
          } catch (error) {
            ErrorToast(error?.message);
            setIsLoading(false);
          }
        } else {
          console.log("hello🎈");
          ErrorToast("Something wrong please check");
        }
      } catch (error) {
        ErrorToast(error?.message);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleChooseFileClick = () => {
    document.getElementById("fileUpload").click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  return (
    <div className="w-full sm:px-0 ">
       {isCollapse && <EyeIcon className="w-7 h-7 text-black-700 cursor-pointer  hover:text-blue-500" onClick={()=>setIsCollapse(!isCollapse)} />}
        {!isCollapse &&<EyeSlashIcon className="w-7 h-7 text-black-700 cursor-pointer hover:text-red-500" onClick={()=>setIsCollapse(!isCollapse)} />}
      <div className="w-full sm:px-0 flex flex-col items-start gap-x-4 mr-4">
       {!isCollapse &&<> <div className="w-full flex flex-wrap sm:flex-nowrap gap-x-4">

          <div className="w-full">
            <Select
              data={boardData || []}
              value={papergenfilter?.board}
              onChange={(e) => handleFilter("board", e)}
              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
              placeholder="All Board"
              type="board"
              getBoard={getAllBoard}
            />
          </div>
          <div className="w-full">
            <Select
              data={mediumData || []}
              value={papergenfilter?.medium}
              onChange={(e) => handleFilter("medium", e)}
              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
              placeholder="All Medium"
              type="medium"
              getMedium={getAllMedium}
            />
          </div>
          <div className="w-full">
            <Select
              data={standardData || []}
              value={papergenfilter?.standard}
              onChange={(e) => handleFilter("standard", e)}
              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
              placeholder="All Standard"
              type={"standard"}
              getStandard={getAllStandard}
            />
          </div>
          <div className="w-full">
            <Select
              data={semesterData || []}
              value={papergenfilter?.semester}
              onChange={(e) => handleFilter("semester", e)}
              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
              placeholder="All Semester"
              type={"semester"}
              getSemester={getAllSemester}
            />
          </div>
          <div className="w-full">
            <Select
              data={subjectData || []}
              value={papergenfilter?.subject}
              onChange={(e) => handleFilter("subject", e)}
              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
              placeholder="All Subject"
              type={"subject"}
              getSubject={getAllSubject}
            />
          </div>
          <div className="w-full">
            <FormControl fullWidth>
              <Selected
                size="small"
                value={O_Status}
                labelId="select-placeholder"
                className="font-regular mt-3"
                name="O_Status"
                onChange={handleO_StatusChange}
                displayEmpty
                sx={{
                  border: "none",
                  background: "white",
                  mr: 1,
                  height: "37px",
                  borderColor: "gray",
                  color: "gray",
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #E5F1EB",
                  },
                  "& .MuiSelect-select": {
                    fontSize: "0.8rem",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem value="" sx={{ fontSize: "0.8rem" }}>
                  Select Order Status
                </MenuItem>
                <MenuItem value={"SUCCESS"} sx={{ fontSize: "0.8rem" }}>
                  SUCCESS
                </MenuItem>
                <MenuItem value={"FAILURE"} sx={{ fontSize: "0.8rem" }}>
                  FAILURE
                </MenuItem>
                <MenuItem value={"PENDING"} sx={{ fontSize: "0.8rem" }}>
                  PENDING
                </MenuItem>
              </Selected>
            </FormControl>
          </div>
          <div className="w-full">
            <FormControl fullWidth>
              <Selected
                size="small"
                value={isSubscriptionActive}
                labelId="select-placeholder"
                className="font-regular mt-3"
                name="isSubscriptionActive"
                onChange={handleIsSubscriptionActiveChange}
                displayEmpty
                sx={{
                  border: "none",
                  background: "white",
                  mr: 1,
                  height: "37px",
                  borderColor: "gray",
                  color: "gray",
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #E5F1EB",
                  },
                  "& .MuiSelect-select": {
                    fontSize: "0.8rem",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem value="" sx={{ fontSize: "0.8rem" }}>
                  Select Status
                </MenuItem>
                <MenuItem value={1} sx={{ fontSize: "0.8rem" }}>
                  True
                </MenuItem>
                <MenuItem value={0} sx={{ fontSize: "0.8rem" }}>
                  False
                </MenuItem>
              </Selected>
            </FormControl>
          </div>
        </div>
        <div className="w-full flex flex-wrap sm:flex-nowrap mt-3 gap-x-4">
          <div className="w-full">
            <FormControl fullWidth>
              <Selected
                size="small"
                value={FollowupStatus}
                labelId="select-placeholder"
                className="font-regular mt-3"
                name="FollowupStatus"
                onChange={handleFollowupStatusChange}
                displayEmpty
                sx={{
                  border: "none",
                  background: "white",
                  mr: 1,
                  height: "37px",
                  borderColor: "gray",
                  color: "gray",
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #E5F1EB",
                  },
                  "& .MuiSelect-select": {
                    fontSize: "0.8rem",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem value="" sx={{ fontSize: "0.8rem" }}>
                  Select Followup Status
                </MenuItem>
                <MenuItem value="F" sx={{ fontSize: "0.8rem" }}>
                  F
                </MenuItem>
                <MenuItem value="N" sx={{ fontSize: "0.8rem" }}>
                  N
                </MenuItem>
                <MenuItem value="D" sx={{ fontSize: "0.8rem" }}>
                  D
                </MenuItem>
              </Selected>
            </FormControl>
          </div>
          <div className="w-full flex items-end mt-3">
            <label
              style={{ whiteSpace: "nowrap" }}
              className="mb-3 mr-2 text-sm font-medium text-[#252F40] font-poppins"
            >
              From Date :
            </label>
            <div className="w-full">
              <Input
                disabled={false}
                name=""
                type="date"
                className="font-regular"
                value={startDate}
                onChange={handleStartDateChange}
              />
            </div>
          </div>
          <div className="w-full flex items-end mt-3">
            <label
              style={{ whiteSpace: "nowrap" }}
              className="mb-3 mr-2 text-sm font-medium text-[#252F40] font-poppins"
            >
              To Date :
            </label>
            <div className="w-full">
              <Input
                disabled={false}
                name=""
                // label="To Date :"
                type="date"
                className="font-regular"
                placeholder="To Date"
                value={endDate}
                onChange={handleEndDateChange}
              />
            </div>
          </div>
          <div className="mt-3">
            <Button
              onClick={() => applyFilter()}
              id="filterbtn"
              type="button"
              label={"SEARCH"}
            />
          </div>
          <div className="mt-3">
            <Button
              onClick={() => resetFilter()}
              id=""
              type="button"
              label={"RESET"}
            />
          </div>
        </div></>}
      </div>
      <div className="flex justify-between items-center mt-2">
        <div>
          <MasterTitle
            title="Order History"
            description="Below are the list of order history."
          />
        </div>
        <Model
          title={
            modelType === "add" ? "Add Order History" : "Edit Order History"
          }
          show={isModel}
          onCancel={() => {
            setIsModel(false);
            resetOrderHistory();
          }}
          onClose={() => {
            setSelectedId("");
            setIsModel(false);
            resetOrderHistory();
          }}
          onOk={() => {
            handleAddModelSubmit();
          }}
          okText="SUBMIT"
          cancelText="CLOSE"
          isLoading={isLoading}
          disabled={isLoading}
          width="max-w-3xl"
        >
          {isModelLoader ? (
            <tr
              style={{
                height: "120px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <td>
                <CircularProgress />
              </td>
            </tr>
          ) : (
            <>
              <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                <div className="w-full">
                  <Input
                    name="phoneNumber"
                    type="number"
                    placeholder="Enter your phone  number"
                    label="Phone Number"
                    required
                    onChange={(e) =>
                      handleChange("phoneNumber", e?.target?.value)
                    }
                    value={OrderHistoryModelData?.phoneNumber || ""}
                  />
                  <Typography variant="caption" color={"error"}>
                    {!OrderHistoryModelData?.phoneNumber
                      ? error?.phoneNumber
                      : ""}
                  </Typography>
                </div>
                <div className="w-full">
                  <Input
                    disabled={false}
                    name=""
                    required
                    label="Expired Date :"
                    type="date"
                    className="font-regular "
                    onChange={(e) =>
                      handleChange("expiryDate", e?.target?.value)
                    }
                    value={OrderHistoryModelData?.expiryDate || expiryData}
                  />
                  {/* <Typography variant="caption" color={"error"}>
                    {!OrderHistoryModelData?.expiryDate
                      ? error?.expiryDate
                      : ""}
                  </Typography> */}
                </div>
              </div>
              <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                <div className="w-full">
                  <Select
                    value={OrderHistoryModelData?.board}
                    data={selectionDataBoard || []}
                    onChange={(e) => handleChange("board", e)}
                    className="font-regular mb-3 font-semibold text-black"
                    label="Board"
                    required
                    placeholder="All Board"
                    type={"board"}
                    getBoard={getBoardData}
                    isPage={true}
                  />
                  <Typography variant="caption" color={"error"}>
                    {!OrderHistoryModelData?.board ? error?.board : ""}
                  </Typography>
                </div>
                <div className="w-full">
                  <Select
                    value={OrderHistoryModelData?.medium}
                    data={selectionDataMedium || []}
                    onChange={(e) => handleChange("medium", e)}
                    className="font-regular mb-3 font-semibold text-black"
                    label="Medium"
                    required
                    placeholder="All Medium"
                    type={"medium"}
                    getMedium={getMediumData}
                    isPage={true}
                  />
                  <Typography variant="caption" color={"error"}>
                    {!OrderHistoryModelData?.medium ? error?.medium : ""}
                  </Typography>
                </div>
              </div>
              <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                <div className="w-full">
                  <Select
                    data={selectionDataStandard || []}
                   className="font-regular mb-3 font-semibold text-black"
                    label="Standard"
                    required
                    placeholder="All Standard"
                    value={OrderHistoryModelData.standard || ""}
                    onChange={(e) => handleChange("standard", e)}
                    type={"standard"}
                    getStandard={getStandardData}
                    isPage={true}
                  />
                  <Typography variant="caption" color={"error"}>
                    {!OrderHistoryModelData?.standard ? error?.standard : ""}
                  </Typography>
                </div>
                <div className="w-full">
                  <Select
                    data={selectionDataSemester || []}
                    className="font-regular mb-3 font-semibold text-black"
                    label="Semester"
                    required
                    placeholder="All semester"
                    value={OrderHistoryModelData.semester || ""}
                    onChange={(e) => handleChange("semester", e)}
                    type={"semester"}
                    getSemester={getSemesterData}
                    isPage={true}
                  />
                  <Typography variant="caption" color={"error"}>
                    {!OrderHistoryModelData?.semester ? error?.semester : ""}
                  </Typography>
                </div>
              </div>
              <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                <div className="w-full">
                  <Select
                    data={selectionDataSubject || []}
                    className="font-regular mb-3 font-semibold text-black"
                    label="Subject"
                    required
                    placeholder="All subject"
                    value={OrderHistoryModelData.subject || ""}
                    onChange={(e) => handleChange("subject", e)}
                    type={"subject"}
                    getSubject={getSubjectData}
                    isPage={true}
                  />
                  <Typography variant="caption" color={"error"}>
                    {!OrderHistoryModelData?.subject ? error?.subject : ""}
                  </Typography>
                </div>
                <div className="w-full">
                  <Input
                    label="Amount"
                    type="text"
                    autoComplete="given-name"
                    // required
                    className="w-full"
                    onChange={(e) => handleChange("Amount", e?.target?.value)}
                    value={OrderHistoryModelData?.Amount || ""}
                  />
                  {/* <Typography variant="caption" color={"error"}>
                    {!OrderHistoryModelData?.Amount ? error?.Amount : ""}
                  </Typography> */}
                </div>
              </div>
              <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                <FormControl fullWidth>
                  <label className="mb-2 block text-sm font-medium text-[#252F40] font-poppins">
                    Order Status
                  </label>
                  <Selected
                    size="small"
                    value={OrderHistoryModelData?.O_Status || ""}
                    labelId="select-placeholder"
                    className="font-regular mb-3 font-semibold text-black"
                    onChange={(e) => handleChange("O_Status", e?.target?.value)}
                    displayEmpty
                    sx={{
                      border: "none",
                      background: "white",
                      // mr: 1,
                      height: "37px",
                      borderColor: "gray",
                      color: "gray",
                      "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #E5F1EB",
                      },
                      "& .MuiSelect-select": {
                        fontSize: "0.8rem",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem value="" disabled sx={{ fontSize: "0.8rem" }}>
                      Select Order Status
                    </MenuItem>
                    <MenuItem value={"SUCCESS"} sx={{ fontSize: "0.8rem" }}>
                      SUCCESS
                    </MenuItem>
                    <MenuItem value={"FAILURE"} sx={{ fontSize: "0.8rem" }}>
                      FAILURE
                    </MenuItem>
                  </Selected>
                </FormControl>
                <Typography variant="caption" color={"error"}>
                  {!OrderHistoryModelData?.O_Status ? error?.O_Status : ""}
                </Typography>
                <FormControl fullWidth>
                  <label className="mb-2 block text-sm font-medium text-[#252F40] font-poppins">
                    Status
                  </label>
                  <Selected
                    size="small"
                    value={selectedSubcribption}
                    labelId="select-placeholder"
                    className="font-regular mb-3"
                    name="isSubscriptionActive"
                    onChange={(e) => setSelectedSubcribption(e?.target?.value)}
                    displayEmpty
                    sx={{
                      border: "none",
                      background: "white",
                      // mr: 1,
                      height: "37px",
                      borderColor: "gray",
                      color: "gray",
                      "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #E5F1EB",
                      },
                      "& .MuiSelect-select": {
                        fontSize: "0.8rem",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem value="" disabled sx={{ fontSize: "0.8rem" }}>
                      Select Status
                    </MenuItem>
                    <MenuItem value={1} sx={{ fontSize: "0.8rem" }}>
                      True
                    </MenuItem>
                    <MenuItem value={0} sx={{ fontSize: "0.8rem" }}>
                      False
                    </MenuItem>
                  </Selected>
                </FormControl>
                <Typography variant="caption" color={"error"}>
                  {!OrderHistoryModelData?.O_Status ? error?.O_Status : ""}
                </Typography>
              </div>
              {/* <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                <div className="w-full">
                  <Input
                    label="Added By"
                    type="text"
                    autoComplete="given-name"
                    // required
                    className="w-full"
                    onChange={(e) => handleChange("Adder", e?.target?.value)}
                    value={OrderHistoryModelData?.Adder || ""}
                  />
                </div>
                <div className="w-full">
                </div>
              </div> */}
            </>
          )}
        </Model>
        <Model
          title={"Send Notification"}
          show={isModelNotification}
          onCancel={() => {
            setIsModelNotification(false);
            handleResetNotification();
          }}
          onClose={() => {
            setIsModelNotification(false);
            handleResetNotification();
          }}
          onOk={() => {
            handleAddEditModelSubmit();
          }}
          okText="SUBMIT"
          cancelText="CLOSE"
          isLoading={isLoading}
          disabled={isLoading}
          width="max-w-3xl"
        >
          <div className="w-full">
            <Input
              label="Notification Title"
              id="title"
              name="title"
              type="text"
              autoComplete="given-name"
              required
              className="w-full mb-3"
              value={NotificationModelData?.title}
              onChange={(e) =>
                handleChangeNotification("title", e?.target?.value)
              }
            />
            <Typography variant="caption" color={"error"}>
              {!NotificationModelData?.title ? errorNotification?.title : ""}
            </Typography>
          </div>
          <div className="w-full">
            <TextArea
              label="Description"
              id="description"
              name="description"
              type="text"
              autoComplete="given-name"
              required
              className="w-full mb-3"
              value={NotificationModelData?.description}
              onChange={(e) =>
                handleChangeNotification("description", e?.target?.value)
              }
            />
            <Typography variant="caption" color={"error"}>
              {!NotificationModelData?.description
                ? errorNotification?.description
                : ""}
            </Typography>
          </div>
          <div className="w-full">
            <label className="mb-2 block text-sm font-medium text-[#252F40] font-poppins">
              Type <span className="text-red-500 text-sm"> * </span>
            </label>
          </div>
          <div className="w-full">
            <FormControl fullWidth>
              <Selected
                size="small"
                labelId="select-placeholder"
                className="font-regular mb-3"
                value={NotificationModelData?.type || ""}
                onChange={(e) =>
                  handleChangeNotification("type", e?.target?.value)
                }
                displayEmpty
                sx={{
                  border: "none",
                  background: "white",
                  mr: 1,
                  height: "37px",
                  borderColor: "gray",
                  color: "gray",
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #E5F1EB",
                  },
                  "& .MuiSelect-select": {
                    fontSize: "0.8rem",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem value="" disabled sx={{ fontSize: "0.8rem" }}>
                  Select Type
                </MenuItem>
                <MenuItem value={"simple"} sx={{ fontSize: "0.8rem" }}>
                  SIMPLE
                </MenuItem>
                <MenuItem value={"html"} sx={{ fontSize: "0.8rem" }}>
                  HTML
                </MenuItem>
                <MenuItem value={"link"} sx={{ fontSize: "0.8rem" }}>
                  LINK
                </MenuItem>
                <MenuItem value={"book"} sx={{ fontSize: "0.8rem" }}>
                  BOOK
                </MenuItem>
                <MenuItem value={"subject"} sx={{ fontSize: "0.8rem" }}>
                  SUBJECT-TEACHER
                </MenuItem>
                <MenuItem value={"subject_student"} sx={{ fontSize: "0.8rem" }}>
                  SUBJECT-STUDENT
                </MenuItem>
              </Selected>
            </FormControl>
            <Typography variant="caption" color={"error"}>
              {!NotificationModelData?.type ? errorNotification?.type : ""}
            </Typography>
          </div>
          {NotificationModelData?.type === "html" ? (
            <div className="w-full mb-3">
              <label className="mb-3 block text-sm font-medium text-[#252F40] font-poppins">
                Body
                <span className="text-red-500"> *</span>
              </label>
              <CKEditor
                editorUrl= {editorUrl}
                initData={NotificationModelData?.htmldata}
                onInstanceReady={(instance) => {
                  ansRef.current = instance;
                }}
                onChange={(e) => handleAnswerNotification(e, "htmldata")}
                config={{
                  autoParagraph: false,
                  versionCheck: false,
                }}
              />
              <Typography variant="caption" color={"error"}>
                {!NotificationModelData?.htmldata
                  ? errorNotification?.htmldata
                  : ""}
              </Typography>
            </div>
          ) : (
            ""
          )}
          {NotificationModelData?.type === "link" ? (
            <div className="w-full">
              <Input
                label="Body"
                type="text"
                autoComplete="given-name"
                name="link"
                placeholder="Type here link..."
                required
                className="mb-2 "
                value={NotificationModelData?.linkdata || ""}
                onChange={(e) =>
                  handleChangeNotification("linkdata", e.target.value)
                }
              />
              <Typography className="my-2" variant="caption" color={"error"}>
                {!NotificationModelData?.linkdata ||
                  NotificationModelData?.linkdata
                  ? errorNotification?.linkdata
                  : ""}
              </Typography>
            </div>
          ) : (
            ""
          )}
          {NotificationModelData?.type === "book" ? (
            <>
              <div className="w-full">
                <label className="mb-2 block text-sm font-medium text-[#252F40] font-poppins">
                  Body <span className="text-red-500 text-sm"> * </span>
                </label>
              </div>
              <div className="w-full">
                <FormControl fullWidth sx={{ marginTop: "5px" }}>
                  <Selected
                    displayEmpty
                    size="small"
                    labelId="select-placeholder"
                    className="font-regular mb-3"
                    value={NotificationModelData?.bookdata || ""}
                    onChange={(e) =>
                      handleChangeNotification("bookdata", e?.target?.value)
                    }
                    sx={{
                      border: "none",
                      background: "white",
                      mr: 1,
                      height: "37px",
                      borderColor: "gray",
                      color: "gray",
                      "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #E5F1EB",
                      },
                      "& .MuiSelect-select": {
                        fontSize: "0.8rem",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select Book
                    </MenuItem>
                    {[
                      ...new Set(BookNotificationData.map((item) => item.name)),
                    ].map((uniqueName) => (
                      <MenuItem
                        key={uniqueName}
                        value={
                          BookNotificationData.find(
                            (item) => item.name === uniqueName
                          ).id
                        }
                      >
                        {uniqueName}
                      </MenuItem>
                    ))}
                  </Selected>
                </FormControl>
                <Typography className="my-2" variant="caption" color={"error"}>
                  {!NotificationModelData?.bookdata
                    ? errorNotification?.bookdata
                    : ""}
                </Typography>
              </div>
            </>
          ) : (
            ""
          )}
          {NotificationModelData?.type === "subject" ||
            NotificationModelData?.type === "subject_student" ? (
            <>
              <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                <div className="w-full">
                  <Select
                    data={boardModelData || []}
                    value={NotificationFilter?.board}
                    onChange={(e) => handleFilterModel("board", e)}
                    className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                    placeholder="All Board"
                    type="board"
                    getBoard={getBoardModelData}
                  />
                  <Typography variant="caption" color={"error"}>
                    {!NotificationFilter?.board ? errorNotification?.board : ""}
                  </Typography>
                </div>
                <div className="w-full">
                  <Select
                    data={mediumModelData || []}
                    value={NotificationFilter?.medium}
                    onChange={(e) => handleFilterModel("medium", e)}
                    className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                    placeholder="All Medium"
                    type="medium"
                    getMedium={getMediumModelData}
                  />
                  <Typography variant="caption" color={"error"}>
                    {!NotificationFilter?.medium
                      ? errorNotification?.medium
                      : ""}
                  </Typography>
                </div>
              </div>
              <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                <div className="w-full">
                  <Select
                    data={standardModelData || []}
                    value={NotificationFilter?.standard}
                    onChange={(e) => handleFilterModel("standard", e)}
                    className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                    placeholder="All Standard"
                    type={"standard"}
                    getStandard={getStandardModelData}
                  />
                  <Typography variant="caption" color={"error"}>
                    {!NotificationFilter?.standard
                      ? errorNotification?.standard
                      : ""}
                  </Typography>
                </div>
                <div className="w-full">
                  <Select
                    data={semesterModelData || []}
                    value={NotificationFilter?.semester}
                    onChange={(e) => handleFilterModel("semester", e)}
                    className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                    placeholder="All Semester"
                    type={"semester"}
                    getSemester={getSemesterModelData}
                  />
                  <Typography variant="caption" color={"error"}>
                    {!NotificationFilter?.semester
                      ? errorNotification?.semester
                      : ""}
                  </Typography>
                </div>
              </div>
              <div className="w-full mb-3">
                <Select
                  data={subjectModelData || []}
                  value={NotificationFilter?.subject}
                  onChange={(e) => handleFilterModel("subject", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Subject"
                  type={"subject"}
                  getSubject={getSubjectModelData}
                />
                <Typography variant="caption" color={"error"}>
                  {!NotificationFilter?.subject
                    ? errorNotification?.subject
                    : ""}
                </Typography>
              </div>
            </>
          ) : (
            ""
          )}
          <div className="w-full mb-3">
            <label
              htmlFor="fileUpload"
              className="mb-1 block text-sm font-medium text-[#252F40] font-poppins"
            >
              Scheduler Date Time (optional)
            </label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker", "DateTimePicker"]}>
                <DateTimePicker
                  value={selectedManualTrxDateTime}
                  disablePast
                  onChange={handleChangeManualTrxDateTime}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div className="w-full">
            <label
              htmlFor="fileUpload"
              className="mb-3 block text-sm font-medium text-[#252F40] font-poppins"
            >
              Upload photo (optional)
            </label>

            <input
              type="file"
              id="fileUpload"
              class="hidden"
              accept="image/*"
              onChange={handleImageChange}
            />

            <div className="flex items-center ">
              <button
                id=""
                type="button"
                label={"Choose file"}
                onClick={handleChooseFileClick}
                className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
              >
                Choose file
              </button>
              <div className="ml-1 block text-base font-medium text-[#252F40] font-poppins ">
                {selectedFile ? selectedFile.name : "No file chosen"}
              </div>
            </div>
          </div>
        </Model>
        <div className="flex items-center space-x-2 overflow-x-auto">
          {selectedQue.length > 0 && (
            <div>
              <Button
                isLight
                id=""
                type="submit"
                icon={TrashIcon}
                label={"MULTI DELETE"}
                abc="bg-[#D11212]"
              />
            </div>
          )}
          {userData?.type === "superadmin" && (
            <div>
              {!isExportLoader ? (
                <Button
                  isLight
                  id=""
                  type="submit"
                  icon={ArrowDownTrayIcon}
                  label={"EXPORT"}
                  abc="bg-[#118740]"
                  onClick={() =>
                    OrderHistoryData?.length > 0 ? onExportHandler() : null
                  }
                />
              ) : (
                <Button
                  isLight
                  id=""
                  type="submit"
                  icon={ArrowDownTrayIcon}
                  label={"Loading..."}
                >
                  Loading...
                </Button>
              )}
            </div>
          )}
          {access?.edit && (
            <div>
              <Button
                id=""
                type="button"
                label={"ORDER COMMENT"}
                onClick={() =>
                  navigate("/paper-generation/order-history/order-comment")
                }
              />
            </div>
          )}
          {access?.add && (
            <Button
              id=""
              type="submit"
              icon={PlusIcon}
              label={"ADD ORDER HISTORY"}
              onClick={() => {
                setIsModel(true);
                setModelType("add");
              }}
            />
          )}
          {access?.add && (
            <Button
              id=""
              type="button"
              label={
                checkedItems?.length === 0
                  ? "SEND NOTIFICATION TO ALL"
                  : "SEND NOTIFICATION"
              }
              onClick={() => openNotificationModel()}
            />
          )}
        </div>
      </div>
      <div className="sm:mb-0 overflow-hidden">
        <div className="flex flex-col mt-2">
          <section
            className="bg-white rounded-2xl flex flex-col shadow-sm "
            style={{
              width: "100%",
              height: !isCollapse ? '80vh' : "90vh",
              margin: "0 auto",
              padding: "10px",
            }}
          >
            <div className="flex justify-between items-center mt-3 px-8 mb-3">
              <div style={{ display: "flex", gap:'5px' }}>
              <Input
                id=""
                name="Search"
                type="text"
                placeholder="Search here..."
                autoComplete="given-name"
                required
                className="w-72"
                value={searchValue}
                onChange={handleSearchChange}
              />
               <Button
                onClick={() => applyFilter()}
                id=""
                type="button"
                // label={"SEARCH"}
                searchicon={true}
                />
              </div>
              <Records
                recordsPerPage={recordsPerPage}
                setCurrentPage={setCurrentPage}
                setRecordsPerPage={setRecordsPerPage}
                dataCount={dataCount}
              />
            </div>
            <div
              style={{
                overflowY: "auto",
                maxHeight: !isCollapse ? '80vh' : "900vh",
                height:  !isCollapse ? '80vh' : "90vh",
                width: "100%",
              }}
            >
              {" "}
              {!isLoader && OrderHistoryData?.length <= 0 ? (
                <NoDataFound />
              ) : (
                <table style={{ width: "100%" }}>
                  <thead
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#f4f7f8",
                      zIndex: 1,
                    }}
                  >
                    <tr
                      style={{
                        borderBottom: "1px solid #dddddd",
                        textAlign: "left",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#7B809A",
                        textTransform: "uppercase",
                        backgroundColor: "#f4f7f8",
                      }}
                    >
                        <th
                            className="px-6 py-3 whitespace-no-wrap"
                            scope="col"
                            style={{
                              width: "3%",
                              padding: "25px",
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            <Checkbox
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                              </th>
                      {titleData.map(
                        (v, ind) =>
                          v?.title && (
                            <th
                              key={ind}
                              // className="py-4 px-8 "
                              style={{
                                padding: "12px",
                                backgroundColor: "#f4f7f8",
                                position: v.title === "EDIT" ? "sticky" : "",
                                right: v.title === "EDIT" ? "0" : "",
                              }}
                            >
                              <div className="flex justify-between">
                                <span>{v.title}</span>
                                {v.title !== "EDIT" && (
                                  <span>
                                    <ChevronUpDownIcon
                                      onClick={() => {
                                        let res = SortData(
                                          OrderHistoryData,
                                          v.fieldName,
                                          v.sort
                                        );

                                        let copyData = [...titleData];
                                        copyData[ind].sort =
                                          v.sort > 0 ? -1 : 1;
                                        setTitleData(copyData);
                                        setOrderHistoryData(res);
                                      }}
                                      className="w-5 h-5"
                                    />
                                  </span>
                                )}
                              </div>
                            </th>
                          )
                      )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {isLoader ? (
                      <tr style={{ height: "25rem" }}>
                        <td colSpan={15} style={{ textAlign: "center" }}>
                          <CircularProgress />
                        </td>
                      </tr>
                    ) : (
                      OrderHistoryData.map((data, id) => (
                        <tr
                          key={id}
                          className="my-4 px-8 text-[#000] font-bold text-sm align-top"
                          style={{
                            backgroundColor:
                              data?.isSubscriptionActive
                                ? "#8cfb8c"
                                : "#fb6868",
                          }}
                        >
                          <td className="px-6 py-3 whitespace-no-wrap">
                            <Checkbox
                              checked={checkedItems.includes(data.id)}
                              onChange={() => handleCheckboxChange(data.id)}
                            />
                          </td>
                          <td className="hidden lg:table-cell whitespace-nowrap px-8 py-2.5  ">
                            {data?.mobileNo}
                          </td>
                       
                          <td className="hidden lg:table-cell whitespace-nowrap px-8 py-2.5  ">
                            {data?.SubscriptionPriceInfo?.StandardInfo?.mediumInfo?.name}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-8 py-2.5  ">
                            {data.SubscriptionPriceInfo?.StandardInfo?.name}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-8 py-2.5  ">
                            {data.SubscriptionPriceInfo?.SemesterInfo?.semester}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-8 py-2.5  ">
                            {data.SubscriptionPriceInfo?.SubjectInfo?.subject}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-8 py-2.5  ">
                            {data.expiryDate !== null ? data.expiryDate : "-"}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-8 py-2.5  ">
                            {data.SubscriptionCommentInfo?.map((comment) => (
                              <div
                                key={comment.id}
                                style={{
                                  width: "250px",
                                  whiteSpace: "normal", // Add this CSS property
                                  color:
                                    comment?.color === "BLUE PURPLE"
                                      ? "#6A5ACD"
                                      : comment?.color,
                                }}
                              >
                                {comment.description}
                                <br />
                                Note By: {comment.userInfo?.username} at{" "}
                                {moment(formatDate(comment.createdAt)).format(
                                  "D MMMM, YYYY | hh:mm A"
                                )}
                                <br />
                                -----------------------------
                              </div>
                            ))}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-8 py-2.5  ">
                            {data?.userInfo?.comment?.map((comment) => (
                              <div
                                key={comment.id}
                                style={{
                                  width: "250px",
                                  whiteSpace: "normal", // Add this CSS property
                                  color:
                                    comment?.color === "BLUE PURPLE"
                                      ? "#6A5ACD"
                                      : comment?.color,
                                }}
                              >
                                {comment.description}
                                <br />
                                Note By: {comment.userInfo?.username} at{" "}
                                {moment(formatDate(comment.createdAt)).format(
                                  "D MMMM, YYYY | hh:mm A"
                                )}
                                <br />
                                ------------------------------
                              </div>
                            ))}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-8 py-2.5  ">
                            {data?.price}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-8 py-2.5  ">
                            {data.isSubscriptionActive === 1 ? "True" : "False"}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-8 py-2.5  ">
                            {data.followupStatus ? data.followupStatus : "-"}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-8 py-2.5  ">
                            {data?.adminInfo?.username !== null
                              ? data?.adminInfo?.username
                              : "-"}
                          </td>

                       
                          <td className="lg:table-cell whitespace-nowrap px-8 py-2.5  ">
                            {data.paymentStatus ? data.paymentStatus : "-"}
                          </td>
                          
                          <td className="lg:table-cell whitespace-nowrap px-8 py-2.5  ">
                            {data.createdAt !== null
                              ? formatTimestamp(data.createdAt)
                              : "-"}
                          </td>

                          {/* <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                            {data.updatedAt !== null
                              ? formatTimestamp(data.updatedAt)
                              : "-"}
                          </td> */}
                         
                          <td className="lg:table-cell whitespace-nowrap px-8 py-2 ">
                            {data?.userInfo?.username}
                          </td>
                          
                          <td className="lg:table-cell whitespace-nowrap px-8  py-2.5 ">
                            {data.id}
                          </td>
                          <td className="hidden lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                            {data?.SubscriptionPriceInfo?.StandardInfo?.boardInfo?.board_name}
                          </td>
                         
                         
                          {access?.edit && (
                            <td
                              className="relative whitespace-nowrap  p-6 py-2.5  text-left "
                              style={{
                                position: "sticky",
                                right: "0",
                                backgroundColor: "white",
                              }}
                            >
                              <ActionButton
                                // key={data.key}
                                action="edit"
                                onClick={() => {
                                  setIsModel(true);
                                  setModelType("edit");
                                  setSelectedId(data.id);
                                }}
                              />
                            </td>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              recordsPerPage={recordsPerPage}
              data={OrderHistoryData}
              numbers={numbers}
              npage={npage}
              lastIndex={lastIndex}
              firstIndex={firstIndex}
              dataCount={dataCount}
              
            />
          </section>
        </div>
        {/* Delete */}
        <Transition.Root show={openModel} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={setOpenModel}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-14 w-14 rounded-full bg-[#FFEFEF] sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-medium text-gray-900"
                        >
                          Delete
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to delete this Data ? All data
                            of this Data will be permanently removed from our
                            servers forever. This action cannot be undone.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-700 text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => {
                          setOpenModel(false);
                          setShowFloatNotification(true);
                        }}
                      >
                        Delete
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => setOpenModel(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        {/* Delete */}
        <FloatNotification
          show={showFloatNotification}
          title={"Successfully deleted!"}
          type="success"
          description={"Notification Deleted."}
          onClick={() => {
            setShowFloatNotification(false);
          }}
        />
      </div>
    </div>
  );
};

export default OrderHistory;
