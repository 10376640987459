import Pagination from "./Pagination";
import { useEffect, useRef, useState } from "react";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import Model from "./Model";
import { Input } from "./Input";
import MasterTitle from "./MasterTitle";
import { SortData } from "./SortData";
import Records from "./Records";
import FloatNotification from "./FloatNotification";
import { PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import { Button } from "./Button";
import DeletePopup from "./DeletePopup";
import ActionPopup from "./ActionPopup";
import { Api } from "../Api/Api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ErrorToast, SuccessToast } from "./toast";
import Select from "./Select";
import NoDataFound from "./NoDataFound";
import CircularProgress from "@mui/material/CircularProgress";
import ActionButton from "./ActionButton";
import { Checkbox } from "./Checkbox";
import { FormControl, InputLabel, MenuItem, Typography } from "@mui/material";
import Selected from "@mui/material/Select";
import Switch from "@mui/material/Switch";

const AddSound = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Master")?.[0];
  const [isModel, setIsModel] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const cancelButtonRef = useRef(null);
  const [modelType, setModelType] = useState("");
  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [dataCount, setDataCount] = useState(0);
  const [error, setError] = useState({});
  const [AddSound, setAddSoundData] = useState([]);
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(dataCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [isLoader, setIsLoader] = useState(false);
  const [isModelLoader, setisModelLoader] = useState(false);
  const [SelectAddSoundId, setSelectAddSoundId] = useState();
  const [checkedItems, setCheckedItems] = useState([]);
  const [deleteType, setDeleteType] = useState("single");
  const [search, setSearch] = useState("");
  const [titleData, setTitleData] = useState([
    {
      title: "TYPE",
      sort: 1,
      fieldName: "type",
    },
    {
      title: "LINK",
      sort: 1,
      fieldName: "link",
    },
    {
      title: "ACTIVE",
      sort: 1,
      fieldName: "active",
    },
    {
      title: access?.edit ? "EDIT" : "",
      sort: 1,
      fieldName: "",
    },
    {
      title: access?.delete ? "DELETE" : "",
      sort: 1,
      fieldName: "",
    },
  ]);
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const [selectedId, setSelectedId] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [AudioData, setAudioData] = useState({
    link: "",
  });

  const [Type, setType] = useState("");

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  useEffect(() => {
    getAddSoundData(recordsPerPage, currentPage);
  }, [recordsPerPage, currentPage]);

  useEffect(() => {
    if (AudioData.link) {
      const existingFile = new File([], AudioData.link, {
        type: "audio/mpeg",
      });
      setSelectedFile(existingFile);
    }
  }, [AudioData.link]);

  const getAddSoundData = async (limit, offset) => {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/audio?offset=${offset}&limit=${limit}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setAddSoundData(req?.data?.audioData);
        setDataCount(req?.data?.totalCount);
        setIsLoader(false);
      } else {
        ErrorToast(req?.message);
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const validation = async () => {
    let errors = {};
    let formIsValid = true;
    if (!selectedFile) {
      formIsValid = false;
      errors["audio"] = "Please select Audio.";
    } else if (selectedFile?.name !== AudioData?.link) {
      const audio = new Audio();
      audio.src = URL.createObjectURL(selectedFile);

      // Wrap the asynchronous operation inside a promise
      await new Promise((resolve) => {
        audio.onloadedmetadata = () => {
          // Get the duration of the audio file in seconds
          const durationInSeconds = audio.duration;
          // Convert seconds to minutes
          if (durationInSeconds > 2) {
            formIsValid = false;
            errors["audio"] = "Audio must be under 2 seconds.";
          }
          // Resolve the promise once the asynchronous operation is complete
          resolve();
        };
      });
    }
    if (!Type) {
      formIsValid = false;
      errors["type"] = "Please select type.";
    }
    setError(errors);
    return formIsValid;
  };

  const handleResetImage = () => {
    setSelectedFile(null);
    setError({});
    setType("");
  };

  const handleChooseFileClick = () => {
    document.getElementById("fileUpload").click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handelOnsubmit = async (e) => {
    e.preventDefault();

    if (await validation()) {
      if (modelType === "edit") {
        let imgData = [];
        if (selectedFile?.name !== AudioData?.link) {
          const formData = new FormData();
          formData.append("image", selectedFile);
          const responseimg = await Api.axiospostRequest(
            "api/upload/single_file",
            formData
          );

          if (responseimg.status === 200) {
            imgData = [responseimg?.data?.data];
          }
        }

        const body = {
          ...(selectedFile?.name !== AudioData?.link && {
            link: selectedFile ? (imgData.length > 0 ? imgData[0] : "") : "",
          }),
          type: Type,
        };
        try {
          const response = await Api.putRequest(
            `api/audio/update/${selectedId}`,
            body
          );

          if (response?.statusCode === 200) {
            SuccessToast(response?.data?.message);
            getAddSoundData(recordsPerPage, currentPage);
            setIsModel(false);
            handleResetImage();
            setSelectedId("");
          } else {
            ErrorToast(response?.data?.message);
          }
        } catch (error) {
          ErrorToast(error?.message);
        }
      } else {
        try {
          setSelectedId("");
          let imgData = [];
          if (selectedFile) {
            const formData = new FormData();
            formData.append("image", selectedFile);
            const responseimg = await Api.axiospostRequest(
              "api/upload/single_file",
              formData
            );

            if (responseimg.status === 200) {
              imgData = [responseimg?.data?.data];
            }
          }

          const body = {
            link: selectedFile ? (imgData.length > 0 ? imgData[0] : "") : "",
            type: Type,
          };
          try {
            const response = await Api.postRequest("api/audio/create", body);

            const req2 = JSON.parse(response?.data);

            if (req2?.status === 200) {
              SuccessToast("Sound Successfully");
              getAddSoundData(recordsPerPage, currentPage);
              handleResetImage();
              setIsModel(false);
              setSelectedId("");
            } else {
              ErrorToast(req2?.message);
            }
          } catch (error) {
            ErrorToast(error?.message);
          }
        } catch (error) {
          ErrorToast(error?.message);
        }
      }
    }
  };

  const _getById = async (id) => {
    try {
      setisModelLoader(true);
      if (id) {
        const { data } = await Api.getRequest(
          `api/audio/getAudiobyID/${selectedId}`
        );
        const req = JSON.parse(data);

        if (req?.status === 200) {
          setisModelLoader(false);
          setAudioData({
            link: req?.data?.link || "",
          });
          setSelectedFile({
            name: req?.data?.link || "",
          });
          setType(req?.data?.type);
        } else {
          ErrorToast(req.message);
        }
      }
    } catch (error) {
      ErrorToast(error?.message);
      setisModelLoader(false);
    }
  };

  useEffect(() => {
    if (selectedId) {
      _getById(selectedId);
    }
  }, [selectedId]);

  const _deleteAddSound = async (id) => {
    try {
      const { data } = await Api.deleteRequest(
        `api/audio/delete/${SelectAddSoundId}`
      );

      const req = JSON.parse(data);
      if (req?.status === 200) {
        SuccessToast(req?.message);
        getAddSoundData(recordsPerPage, currentPage);
        setOpenModel(false);
        setSelectAddSoundId("");
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  // const handleCheckboxChange = (id) => {
  //   setCheckedItems((prevState) => {
  //     const isChecked = prevState.includes(id);

  //     const updatedItems = isChecked
  //       ? prevState.filter((itemId) => itemId !== id)
  //       : [...prevState, id];

  //     return updatedItems;
  //   });
  // };

  const toggleActiveStatus = async (AddSound) => {
    const body = {
      deleted: AddSound?.deleted === 0 ? 1 : 0,
    };
    try {
      const response = await Api.putRequest(
        `api/audio/update/${AddSound?.id}`,
        body
      );
      if (response?.statusCode === 200) {
        SuccessToast(response?.data?.message);
        getAddSoundData(recordsPerPage, currentPage);
      } else {
        ErrorToast(response?.data?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  return (

    <div>
      <div className="flex justify-between items-center mt-5">
        <div>
          <MasterTitle
            title="Add Sound"
            description="Below are the list of all the add sound."
          />
        </div>
        <div>
          <div style={{ display: "flex", gap: "5px" }}>
            {/* {access?.delete && (
              <Button
                id=""
                type="submit"
                icon={TrashIcon}
                label={"DELETE BOARD"}
                onClick={() => {
                  setDeleteType("multi");
                  if (checkedItems.length > 0) {
                    setOpenModel(true);
                  }
                }}
              />
            )} */}
            {access?.add && (
              <Button
                id=""
                type="submit"
                icon={PlusIcon}
                label={"ADD SOUND"}
                onClick={() => {
                  setIsModel(true);
                  setModelType("add");
                }}
              />
            )}
          </div>

          <Model
            title={modelType === "add" ? "Add Sound" : "Edit Sound"}
            show={isModel}
            onCancel={() => {
              handleResetImage();
              setIsModel(false);
            }}
            onClose={() => {
              setIsModel(false);
              setSelectedId("");
              handleResetImage();
            }}
            onOk={(e) => handelOnsubmit(e)}
            okText="SUBMIT"
            cancelText="CLOSE"
          >
            {isModelLoader ? (
              <tr
                style={{
                  height: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <td>
                  <CircularProgress />
                </td>
              </tr>
            ) : (
              <>
                <form className="mb-2">
                  <label
                    htmlFor="fileUpload"
                    className="mb-3 block text-sm font-medium text-[#252F40] font-poppins"
                  >
                    Upload Audio
                    <span className="text-red-500 text-sm"> * </span>
                  </label>

                  <input
                    type="file"
                    id="fileUpload"
                    class="hidden"
                    accept="audio/*"
                    onChange={handleImageChange}
                  />

                  <div className="flex items-center ">
                    <button
                      id=""
                      type="button"
                      label={"Choose file"}
                      onClick={handleChooseFileClick}
                      className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                    >
                      Choose file
                    </button>
                    <div className="ml-1 block text-base font-medium text-[#252F40] font-poppins ">
                      {selectedFile ? selectedFile.name : "No file chosen"}
                    </div>
                  </div>
                </form>
                <Typography className="my-2" variant="caption" color={"error"}>
                  {!selectedFile || selectedFile ? error?.audio : ""}
                </Typography>
                <div className="w-full">
                  <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                    <label className="block text-sm font-medium text-[#252F40] font-poppins">
                      Type <span className="text-red-500 text-sm"> * </span>
                    </label>
                  </div>
                  <FormControl fullWidth>
                    <Selected
                      size="small"
                      value={Type}
                      labelId="select-placeholder"
                      className="font-regular mb-3"
                      onChange={handleTypeChange}
                      displayEmpty
                      sx={{
                        border: "none",
                        background: "white",
                        mr: 1,
                        height: "37px",
                        borderColor: "gray",
                        color: "gray",
                        "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #E5F1EB",
                        },
                        "& .MuiSelect-select": {
                          fontSize: "0.8rem",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem value="" disabled sx={{ fontSize: "0.8rem" }}>
                        Select Type
                      </MenuItem>
                      <MenuItem value={"correct"} sx={{ fontSize: "0.8rem" }}>
                        Correct
                      </MenuItem>
                      <MenuItem value={"wrong"} sx={{ fontSize: "0.8rem" }}>
                        Wrong
                      </MenuItem>
                      <MenuItem value={"skip"} sx={{ fontSize: "0.8rem" }}>
                        Skip
                      </MenuItem>
                      <MenuItem value={"select"} sx={{ fontSize: "0.8rem" }}>
                        Select
                      </MenuItem>
                    </Selected>
                  </FormControl>
                  <Typography variant="caption" color={"error"}>
                    {!Type ? error?.type : ""}
                  </Typography>
                </div>
              </>
            )}
          </Model>
        </div>
      </div>
      <div className="sm:mb-0">
        <div className="flex flex-col mt-4">
          <section
            className="bg-white rounded-2xl flex flex-col shadow-sm "
            style={{
              width: "100%",
              height: "75vh",
              margin: "0 auto",
              padding: "20px",
              marginTop: "30px",
            }}
          >
            <div className="flex justify-between items-center px-8 mb-2">
              <div></div>
              <Records
                setCurrentPage={setCurrentPage}
                recordsPerPage={recordsPerPage}
                setRecordsPerPage={setRecordsPerPage}
                dataCount={dataCount}
              />
            </div>
            <div
              style={{
                overflowY: "auto",
                maxHeight: "58vh",
                height: "60vh",
                width: "100%",
              }}
            >
              {" "}
              {!isLoader && AddSound?.length <= 0 ? (
                <NoDataFound />
              ) : (
                <table style={{ width: "100%" }}>
                  <thead
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#f4f7f8",
                      zIndex: 1,
                    }}
                  >
                    <tr
                      style={{
                        borderBottom: "1px solid #dddddd",
                        textAlign: "left",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#7B809A",
                        textTransform: "uppercase",
                        backgroundColor: "#f4f7f8",
                      }}
                    >
                      {" "}
                      {/* <th
                        scope="col"
                        style={{ width: "3%", padding: "10px" }}
                      ></th> */}
                      {titleData.map(
                        (value, ind) =>
                          value.title && (
                            <th
                              key={ind}
                              // className="py-2 px-6"
                              style={{
                                padding: "12px",
                                backgroundColor: "#f4f7f8",
                                position:
                                  value.title === "EDIT" ||
                                  value.title === "DELETE"
                                    ? "sticky"
                                    : "",
                                right:
                                  value.title === "EDIT"
                                    ? "60px"
                                    : value.title === "DELETE"
                                    ? "0"
                                    : "",
                              }}
                            >
                              <div className="flex justify-between">
                                <span>{value.title}</span>
                                {value.title !== "EDIT" &&
                                  value.title !== "DELETE" && (
                                    <span>
                                      <ChevronUpDownIcon
                                        onClick={() => {
                                          let res = SortData(
                                            AddSound,
                                            value.fieldName,
                                            value.sort
                                          );

                                          let copyData = [...titleData];
                                          copyData[ind].sort =
                                            value.sort > 0 ? -1 : 1;
                                          setTitleData(copyData);
                                          setAddSoundData(res);
                                        }}
                                        className="w-5 h-5"
                                      />
                                    </span>
                                  )}
                              </div>
                            </th>
                          )
                      )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {isLoader ? (
                      <tr style={{ height: "25rem" }}>
                        <td colSpan={5} style={{ textAlign: "center" }}>
                          <CircularProgress />
                        </td>
                      </tr>
                    ) : (
                      AddSound.map((data) => (
                        <tr
                          key={data.key}
                          className="my-2 px-6 text-[#000] font-reguler text-sm"
                        >
                          {" "}
                          {/* <td className="px-6 py-3 whitespace-no-wrap">
                            <Checkbox
                              checked={checkedItems.includes(data.id)}
                              onChange={() => handleCheckboxChange(data.id)}
                            />
                          </td> */}
                          <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                            {data?.type ?? "-"}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                            {data?.link ?? "-"}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                            <box onClick={() => toggleActiveStatus(data)}>
                              <Switch
                                {...label}
                                checked={data.deleted === 0 ? 1 : 0}
                              />
                            </box>
                          </td>
                          {access?.edit && (
                            <td
                              className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                              style={{
                                position: "sticky",
                                right: "68px",
                                backgroundColor: "white",
                              }}
                            >
                              <ActionButton
                                key={data.key}
                                action="edit"
                                onClick={() => {
                                  setIsModel(true);
                                  setSelectedId(data.id);
                                  setModelType("edit");
                                }}
                              />
                            </td>
                          )}
                          {access?.delete && (
                            <td
                              className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                              style={{
                                position: "sticky",
                                right: "0",
                                backgroundColor: "white",
                              }}
                            >
                              <ActionButton
                                key={data.key}
                                action="delete"
                                onClick={() => {
                                  // setDeleteType("single");
                                  setOpenModel(true);
                                  setSelectAddSoundId(data?.id);
                                }}
                              />
                            </td>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              recordsPerPage={recordsPerPage}
              data={AddSound}
              numbers={numbers}
              npage={npage}
              lastIndex={lastIndex}
              firstIndex={firstIndex}
              dataCount={dataCount}
            />
          </section>
        </div>

        {/* Delete */}
        <DeletePopup
          title={"Delete"}
          show={openModel}
          description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
          onCancel={() => {
            setOpenModel(false);
            setSelectAddSoundId("");
          }}
          onClose={() => {
            setOpenModel(false);
            setSelectAddSoundId("");
          }}
          onDelete={() => {
            _deleteAddSound();
          }}
          cancelButtonRef={cancelButtonRef}
          okText="Delete"
          cancelText="Cancel"
        />
        {/* Delete */}

        <FloatNotification
          show={showFloatNotification}
          title={"Successfully deleted!"}
          type="success"
          description={"Data Deleted."}
          onClick={() => {
            setShowFloatNotification(false);
          }}
        />
      </div>
    </div>
  );
};

export default AddSound;
