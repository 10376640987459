import React, { useEffect, useRef, useState } from "react";
import { Input } from "./Input";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import Pagination from "./Pagination";
import Model from "./Model";
import MasterTitle from "./MasterTitle";
import { Button } from "./Button";
import { Checkbox } from "./Checkbox";
import Records from "./Records";
import { SortData } from "./SortData";
import FloatNotification from "./FloatNotification";
import { PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import Select from "./Select";
import DeletePopup from "./DeletePopup";
import ActionPopup from "./ActionPopup";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast } from "./toast";
import { useDebounce } from "../hooks/use-debounce";
import { Autocomplete, Chip, TextField, Typography } from "@mui/material";
import NoDataFound from "./NoDataFound";
import CircularProgress from "@mui/material/CircularProgress";
import ActionButton from "./ActionButton";

const AutoPaperGeneration = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Paper")?.[0];
  const [isModel, setIsModel] = useState(false);
  const [modelType, setModelType] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [showFloatNotification, setShowFloatNotification] = useState(false);

  const [selectedId, setSelectedId] = useState(null);

  const [search, setSearch] = useState("");
  const debouncedValue = useDebounce(search, 800);
  const [isLoader, setIsLoader] = useState(false);
  const [isModelLoader, setisModelLoader] = useState(false);
  const [papergenfilter, setpapergenfilter] = useState({
    board: "",
    medium: "",
    standard: "",
    semester: "",
    subject: "",
  });
  const [modelPaperGenerationData, SetModelPaperGenerationData] = useState({
    board: "",
    medium: "",
    standard: "",
    semester: "",
    subject: "",
    totalMarks: "",
    sectionLabel: "",
    sectionMarks: "",
    noticeTitle: "",
    questionType: "",
    totalQuestions: "",
    totalOrQuestions: "",
  });
  const [boardData, setBoardData] = useState([]);
  const [mediumData, setMediumData] = useState([]);
  const [standardData, setStandardData] = useState([]);
  const [semesterData, setSemesterData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);

  const [selectionDataBoard, setSelectionDataBoard] = useState([]);
  const [selectionDataMedium, setSelectionDataMedium] = useState([]);
  const [selectionDataStandard, setSelectionDataStandard] = useState([]);
  const [selectionDataSubject, setSelectionDataSubject] = useState([]);
  const [selectionDataSemester, setSelectionDataSemester] = useState([]);
  const [qTypeModalData, setQTypeModalData] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [deleteType, setDeleteType] = useState("single");
  const [SelectAutoPaperGenerationId, setSelectAutoPaperGenerationId] =
    useState();
  const cancelButtonRef = useRef(null);
  const [titleData, setTitleData] = useState([
    {
      title: "STANDARD",
      sort: 1,
      fieldName: "standard",
    },
    {
      title: "SEMESTER",
      sort: 1,
      fieldName: "semester",
    },
    {
      title: "SUBJECT",
      sort: 1,
      fieldName: "subject",
    },
    {
      title: "MARKS",
      sort: 1,
      fieldName: "marks",
    },
    {
      title: "SECTION",
      sort: 1,
      fieldName: "section",
    },
    {
      title: "S.MARK",
      sort: 1,
      fieldName: "SMark",
    },
    {
      title: "NOTICE TITLE",
      sort: 1,
      fieldName: "notice title",
    },
    {
      title: access?.edit ? "EDIT" : "",
      sort: 1,
      fieldName: "",
    },
    {
      title: access?.delete ? "DELETE" : "",
      sort: 1,
      fieldName: "",
    },
  ]);
  const TotalMarksData = [
    { id: 1, name: "25" },
    { id: 2, name: "30" },
    { id: 3, name: "35" },
    { id: 4, name: "40" },
    { id: 5, name: "50" },
    { id: 6, name: "80" },
    { id: 7, name: "100" },
  ];
  const [error, setError] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [dataCount, setDataCount] = useState(0);
  const [PaperGenerationData, setPaperGenerationData] = useState([]);
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(dataCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isResetFilter, setIsResetFilter] = useState(false);

  const getBoardData = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setBoardData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getMediumData = async () => {
    try {
      let body = `?board=${papergenfilter?.board?.id}`;
      const { data } = await Api.getRequest(`api/medium${body}`);
      const req = JSON.parse(data);
      setMediumData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getStandardData = async () => {
    try {
      let body = `?board=${papergenfilter?.board?.id}&medium=${papergenfilter?.medium?.id}`;
      const { data } = await Api.getRequest(`api/standard${body}`);
      const req = JSON.parse(data);
      setStandardData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSemesterData = async () => {
    try {
      let body = `?board=${papergenfilter?.board?.id}&medium=${papergenfilter?.medium?.id}&standard=${papergenfilter?.standard?.id}`;
      const { data } = await Api.getRequest(`api/semester${body}`);
      const req = JSON.parse(data);
      setSemesterData(req?.data?.rows);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSubjectData = async () => {
    try {
      let body = `?board=${papergenfilter?.board?.id}&medium=${papergenfilter?.medium?.id}&standard=${papergenfilter?.standard?.id}&semester=${papergenfilter?.semester?.id}`;
      const { data } = await Api.getRequest(`api/subject${body}`);
      const req = JSON.parse(data);
      setSubjectData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    if (papergenfilter["board"] !== "") {
      getMediumData();
    }
  }, [papergenfilter["board"]]);

  useEffect(() => {
    if (papergenfilter["medium"] !== "") {
      getStandardData();
    }
  }, [papergenfilter["medium"]]);

  useEffect(() => {
    if (papergenfilter["standard"] !== "") {
      getSemesterData();
    }
  }, [papergenfilter["standard"]]);

  useEffect(() => {
    if (papergenfilter["semester"] !== "") {
      getSubjectData();
    }
  }, [papergenfilter["semester"]]);

  const handleFilter = (name, value) => {
    setpapergenfilter({
      ...papergenfilter,
      [name]: value,
    });
    if (name === "board") {
      setpapergenfilter((oldValue) => ({
        ...oldValue,
        medium: "",
        standard: "",
        semester: "",
        subject: "",
        [name]: value,
      }));
      setMediumData([]);
      setStandardData([]);
      setSemesterData([]);
      setSubjectData([]);
    }
    if (name === "medium") {
      setpapergenfilter((oldValue) => ({
        ...oldValue,
        standard: "",
        semester: "",
        subject: "",
        [name]: value,
      }));
      setStandardData([]);
      setSemesterData([]);
      setSubjectData([]);
    }
    if (name === "standard") {
      setpapergenfilter((oldValue) => ({
        ...oldValue,
        semester: "",
        subject: "",
        [name]: value,
      }));
      setSemesterData([]);
      setSubjectData([]);
    }
    if (name === "semester") {
      setpapergenfilter((oldValue) => ({
        ...oldValue,
        subject: "",
        [name]: value,
      }));
      setSubjectData([]);
    }
  };

  const getBoardModelData = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setSelectionDataBoard(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getMediumModelData = async () => {
    try {
      let body = `?board=${modelPaperGenerationData?.board?.id}`;
      const { data } = await Api.getRequest(`api/medium${body}`);
      const req = JSON.parse(data);
      setSelectionDataMedium(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getStandardModelData = async () => {
    try {
      let body = `?board=${modelPaperGenerationData?.board?.id}&medium=${modelPaperGenerationData?.medium?.id}`;
      const { data } = await Api.getRequest(`api/standard${body}`);
      const req = JSON.parse(data);
      setSelectionDataStandard(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSemesterModelData = async () => {
    try {
      let body = `?board=${modelPaperGenerationData?.board?.id}&medium=${modelPaperGenerationData?.medium?.id}&standard=${modelPaperGenerationData?.standard?.id}`;
      const { data } = await Api.getRequest(`api/semester${body}`);
      const req = JSON.parse(data);
      setSelectionDataSemester(req?.data?.rows);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSubjectModelData = async () => {
    try {
      let body = `?board=${modelPaperGenerationData?.board?.id}&medium=${modelPaperGenerationData?.medium?.id}&standard=${modelPaperGenerationData?.standard?.id}&semester=${modelPaperGenerationData?.semester?.id}`;
      const { data } = await Api.getRequest(`api/subject${body}`);
      const req = JSON.parse(data);
      setSelectionDataSubject(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    getBoardData();
    getBoardModelData();
    getQTypeModalData();
  }, []);

  useEffect(() => {
    if (modelPaperGenerationData["board"] != "") {
      getMediumModelData();
    }
    if (modelPaperGenerationData["medium"] != "") {
      getStandardModelData();
    }
    if (modelPaperGenerationData["standard"] != "") {
      getSemesterModelData();
    }
    if (modelPaperGenerationData["semester"] != "") {
      getSubjectModelData();
    }
  }, [modelPaperGenerationData]);

  const handelModelData = (name, value) => {
    SetModelPaperGenerationData((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
    if (name === "board") {
      SetModelPaperGenerationData((oldValue) => ({
        ...oldValue,
        medium: "",
        standard: "",
        semester: "",
        subject: "",
        [name]: value,
      }));
      setSelectionDataMedium([]);
      setSelectionDataStandard([]);
      setSelectionDataSemester([]);
      setSelectionDataSubject([]);
    }
    if (name === "medium") {
      SetModelPaperGenerationData((oldValue) => ({
        ...oldValue,
        standard: "",
        semester: "",
        subject: "",
        [name]: value,
      }));
      setSelectionDataStandard([]);
      setSelectionDataSemester([]);
      setSelectionDataSubject([]);
    }
    if (name === "standard") {
      SetModelPaperGenerationData((oldValue) => ({
        ...oldValue,
        semester: "",
        subject: "",
        [name]: value,
      }));
      setSelectionDataSemester([]);
      setSelectionDataSubject([]);
    }
    if (name === "semester") {
      SetModelPaperGenerationData((oldValue) => ({
        ...oldValue,
        subject: "",
        [name]: value,
      }));
      setSelectionDataSubject([]);
    }
  };

  const getQTypeModalData = async () => {
    try {
      const { data } = await Api.getRequest(`api/qtype`);
      const req = JSON.parse(data);
      setQTypeModalData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const _getById = async (id) => {
    try {
      if (id) {
        setisModelLoader(true);
        const { data } = await Api.getRequest(
          `api/auto-paper-generation/getAutoPaper/${id || ""}`
        );
        const req = JSON.parse(data);
        if (req?.status === 200) {
          setisModelLoader(false);
          SetModelPaperGenerationData({
            board: req?.data?.standardInfo?.boardInfo || "",
            medium: req?.data?.standardInfo?.mediumInfo || "",
            standard: req?.data?.standardInfo || "",
            semester: req?.data?.semesterInfo || "",
            subject: req?.data?.subjectInfo || "",
            totalMarks: req?.data?.tmarks || "",
            sectionLabel: req?.data?.section || "",
            sectionMarks: req?.data?.smarks || "",
            noticeTitle: req?.data?.noticetitle || "",
            questionType: req?.data?.qtype || "",
            totalQuestions: req?.data?.tquestion || "",
            totalOrQuestions: req?.data?.orquestion || "",
          });
        } else {
          ErrorToast(req.message);
        }
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    if (selectedId) {
      _getById(selectedId);
    }
  }, [selectedId]);

  const validation = () => {
    let errors = {};
    let formIsValid = true;
    if (!modelPaperGenerationData?.board) {
      formIsValid = false;
      errors["board"] = "Please select board.";
    }
    if (!modelPaperGenerationData?.medium) {
      formIsValid = false;
      errors["medium"] = "Please select medium.";
    }
    if (!modelPaperGenerationData?.standard) {
      formIsValid = false;
      errors["standard"] = "Please select standard.";
    }
    if (!modelPaperGenerationData?.semester) {
      formIsValid = false;
      errors["semester"] = "Please select semester.";
    }
    if (!modelPaperGenerationData?.subject) {
      formIsValid = false;
      errors["subject"] = "Please select subject.";
    }
    if (!modelPaperGenerationData?.totalMarks) {
      formIsValid = false;
      errors["totalMarks"] = "Please select Total Marks.";
    }
    // if (!modelPaperGenerationData?.sectionLabel) {
    //   formIsValid = false;
    //   errors["sectionLabel"] = "Please enter section Label.";
    // }
    if (!modelPaperGenerationData?.sectionMarks) {
      formIsValid = false;
      errors["sectionMarks"] = "Please enter Section Marks.";
    }
    if (!modelPaperGenerationData?.noticeTitle) {
      formIsValid = false;
      errors["noticeTitle"] = "Please enter Notice Title.";
    }
    if (!modelPaperGenerationData?.questionType) {
      formIsValid = false;
      errors["questionType"] = "Please enter Question Type.";
    }
    if (!modelPaperGenerationData?.totalQuestions) {
      formIsValid = false;
      errors["totalQuestions"] = "Please enter Total Questions.";
    }
    // if (!modelPaperGenerationData?.board) {
    //   formIsValid = false;
    //   errors["board"] = "Please enter Board.";
    // }
    // if (!modelPaperGenerationData?.medium) {
    //   formIsValid = false;
    //   errors["medium"] = "Please enter Medium.";
    // }
    setError(errors);
    return formIsValid;
  };

  const _getAutoPaperGenerationData = async (limit, offset, papergenfilter) => {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/auto-paper-generation?limit=${limit || 10}&offset=${search ? 1 : offset || 1
        }&search=${search || ""}&board=${papergenfilter?.board?.id || ""
        }&medium=${papergenfilter?.medium?.id || ""}&standard=${papergenfilter?.standard?.id || ""
        }&semester=${papergenfilter?.semester?.id || ""}&subject=${papergenfilter?.subject?.id || ""
        }`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setPaperGenerationData(req?.data);
        setDataCount(req?.totalCount);
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const handleAddModelSubmit = async () => {
    if (validation()) {
      setIsLoading(true);
      if (modelType === "edit") {
        try {
          let body = {
            standard: modelPaperGenerationData?.standard?.id,
            semester: modelPaperGenerationData?.semester?.id,
            subject: modelPaperGenerationData?.subject?.id,
            tmarks: modelPaperGenerationData?.totalMarks?.name,
            section: modelPaperGenerationData?.sectionLabel,
            smarks: modelPaperGenerationData?.sectionMarks,
            noticetitle: modelPaperGenerationData?.noticeTitle,
            qtype: modelPaperGenerationData?.questionType,
            tquestion: modelPaperGenerationData?.totalQuestions,
            orquestion: modelPaperGenerationData?.totalOrQuestions || 0,
          };
          const res = await Api.putRequest(
            `api/auto-paper-generation/update/${selectedId}`,
            body
          );
          if (res?.data?.status === 200) {
            SuccessToast(res?.data?.message);
            _getAutoPaperGenerationData(
              recordsPerPage,
              currentPage,
              papergenfilter
            );
            SetModelPaperGenerationData({});
            setIsModel(false);
            setValue([]);
            setSelectedId("");
            setSelectionDataMedium([]);
            setSelectionDataStandard([]);
            setSelectionDataSemester([]);
            setSelectionDataSubject([]);
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
          } else {
            ErrorToast(res?.data);
          }
        } catch (error) {
          ErrorToast(error?.message);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      } else {
        try {
          let body = {
            standard: modelPaperGenerationData?.standard?.id,
            semester: modelPaperGenerationData?.semester?.id,
            subject: modelPaperGenerationData?.subject?.id,
            tmarks: modelPaperGenerationData?.totalMarks?.name,
            section: modelPaperGenerationData?.sectionLabel,
            smarks: modelPaperGenerationData?.sectionMarks,
            noticetitle: modelPaperGenerationData?.noticeTitle,
            qtype: modelPaperGenerationData?.questionType,
            tquestion: modelPaperGenerationData?.totalQuestions,
            orquestion: modelPaperGenerationData?.totalOrQuestions || 0,
          };
          const response = await Api.postRequest(
            "api/auto-paper-generation/create",
            body
          );
          const req = JSON.parse(response.data);
          if (req?.status === 200) {
            SuccessToast(req?.message);
            _getAutoPaperGenerationData(
              recordsPerPage,
              currentPage,
              papergenfilter
            );
            SetModelPaperGenerationData((prevState) => ({
              ...prevState,
              // standard: "",
              // semester: "",
              // subject: "",
              // totalMarks: "",
              sectionLabel: "",
              sectionMarks: "",
              noticeTitle: "",
              questionType: "",
              totalQuestions: "",
              totalOrQuestions: "",
            }));
            // setIsModel(false);
            setSelectedId("");
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
          } else {
            console.log("hello🎈");
            ErrorToast("Something wrong please check");
          }
        } catch (error) {
          ErrorToast(error?.message);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const _deletePaperGeneration = async (id) => {
    try {
      let data;
      if (deleteType === "multi") {
        const body = {
          ids: checkedItems,
        };
        const response = await Api.postRequest(
          `api/auto-paper-generation/delete`,
          body
        );
        data = response.data;
      } else {
        const body = {
          ids: [SelectAutoPaperGenerationId],
        };
        const response = await Api.postRequest(
          `api/auto-paper-generation/delete`,
          body
        );
        data = response.data;
      }
      // const { data } = await Api.postRequest(
      //   `api/auto-paper-generation/delete/${SelectAutoPaperGenerationId}`
      // );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        SuccessToast(req?.message);
        _getAutoPaperGenerationData(
          recordsPerPage,
          currentPage,
          papergenfilter
        );
        setOpenModel(false);
        setSelectAutoPaperGenerationId("");
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    _getAutoPaperGenerationData(recordsPerPage, currentPage, papergenfilter);
  }, [recordsPerPage, currentPage]);

  const handleSelectedItems = (selectedItems) => {
    const Array = selectedItems.map((a) => a.id);
    const selectedItemsString = Array.join(", ");
    SetModelPaperGenerationData((oldValue) => ({
      ...oldValue,
      questionType: selectedItemsString,
    }));
  };

  useEffect(() => {
    handleSelectedItems(selectedItems);
  }, [selectedItems]);

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) => {
      const isChecked = prevState.includes(id);

      const updatedItems = isChecked
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id];

      return updatedItems;
    });
  };

  const [value, setValue] = useState([]);
  const [values, setValues] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const idsString = newValue.map((option) => parseInt(option.id)).join(",");
    SetModelPaperGenerationData((oldValue) => ({
      ...oldValue,
      questionType: idsString,
    }));
  };

  useEffect(() => {
    if (modelPaperGenerationData?.questionType) {
      let qtypeIds;
      if (typeof modelPaperGenerationData.questionType === "string") {
        qtypeIds = modelPaperGenerationData.questionType.split(",").map(Number);
      } else if (Array.isArray(modelPaperGenerationData.questionType)) {
        qtypeIds = modelPaperGenerationData.questionType.map(Number);
      } else {
        console.error(
          "Unsupported type for questionType:",
          modelPaperGenerationData.questionType
        );
        return;
      }
      const selectedOptions = qTypeModalData.filter((option) =>
        qtypeIds.includes(option.id)
      );
      setValue(selectedOptions);
    }
  }, [modelPaperGenerationData, qTypeModalData]);

  const applyFilter = () => {
    _getAutoPaperGenerationData(recordsPerPage, currentPage, papergenfilter);
  };

  const resetFilter = () => {
    setIsResetFilter(true);
    setpapergenfilter({
      board: "",
      medium: "",
      standard: "",
      semester: "",
      subject: "",
    });
    setMediumData([]);
    setStandardData([]);
    setSemesterData([]);
    setSubjectData([]);
  };

  useEffect(() => {
    if (isResetFilter) {
      _getAutoPaperGenerationData(recordsPerPage, currentPage, papergenfilter);
      setIsResetFilter(false);
    }
  }, [isResetFilter]);

  return (
    <>
      <div className=" w-full sm:px-1 ">
        <div className="w-full sm:px-0 flex items-start gap-x-4 mr-4">
          <div className="w-full">
            <Select
              data={boardData || []}
              value={papergenfilter?.board}
              onChange={(e) => handleFilter("board", e)}
              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
              placeholder="All Board"
              type="board"
              getBoard={getBoardData}
            />
          </div>
          <div className="w-full">
            <Select
              data={mediumData || []}
              value={papergenfilter?.medium}
              onChange={(e) => handleFilter("medium", e)}
              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
              placeholder="All Medium"
              type="medium"
              getMedium={getMediumData}
            />
          </div>
          <div className="w-full">
            <Select
              data={standardData || []}
              value={papergenfilter?.standard}
              onChange={(e) => handleFilter("standard", e)}
              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
              placeholder="All Standard"
              type={"standard"}
              getStandard={getStandardData}
            />
          </div>
          <div className="w-full">
            <Select
              data={semesterData || []}
              value={papergenfilter?.semester}
              onChange={(e) => handleFilter("semester", e)}
              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
              placeholder="All Semester"
              type={"semester"}
              getSemester={getSemesterData}
            />
          </div>
          <div className="w-full">
            <Select
              data={subjectData || []}
              value={papergenfilter?.subject}
              onChange={(e) => handleFilter("subject", e)}
              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
              placeholder="All Subject"
              type={"subject"}
              getSubject={getSubjectData}
            />
          </div>
          <div className="mt-3">
            <Button
              onClick={() => applyFilter()}
              id="filterbtn"
              type="button"
              label={"SEARCH"}
            />
          </div>
          <div className="mt-3">
            <Button
              onClick={() => resetFilter()}
              id=""
              type="button"
              label={"RESET"}
            />
          </div>
        </div>
        <div className="flex-1 px-2 sm:px-0 w-full">
          <div>
            <div className="flex justify-between items-center mt-2">
              <div>
                <MasterTitle
                  title="Auto Generate Notice"
                  description="Below are the list of all the notice."
                />
              </div>
              <div className="flex items-center space-x-2 overflow-x-auto">
                <div>
                  <div style={{ display: "flex", gap: "5px" }}>
                    {access?.delete && (
                      <Button
                        id=""
                        type="submit"
                        icon={TrashIcon}
                        label={"DELETE AUTO GENERATE NOTICE"}
                        onClick={() => {
                          setDeleteType("multi");
                          if (checkedItems.length > 0) {
                            setOpenModel(true);
                          }
                        }}
                      />
                    )}
                    {access?.add && (
                      <Button
                        id=""
                        type="submit"
                        icon={PlusIcon}
                        label={"ADD AUTO GENERATE NOTICE"}
                        onClick={() => {
                          setIsModel(true);
                          setModelType("add");
                        }}
                      />
                    )}
                  </div>
                  <Model
                    title={
                      modelType === "add"
                        ? "Add Generate Notice"
                        : "Edit Generate Notice"
                    }
                    show={isModel}
                    onCancel={() => {
                      // SetModelPaperGenerationData({});
                      setIsModel(false);
                      setValue([]);
                      setError({});
                    }}
                    onClose={() => {
                      setIsModel(false);
                      setValue([]);
                      setError({});
                      SetModelPaperGenerationData({});
                      setSelectedId("");
                      setSelectionDataMedium([]);
                      setSelectionDataStandard([]);
                      setSelectionDataSemester([]);
                      setSelectionDataSubject([]);
                    }}
                    onOk={() => {
                      handleAddModelSubmit();
                    }}
                    okText="SUBMIT"
                    cancelText="CLOSE"
                    isLoading={isLoading}
                    disabled={isLoading}
                    width="max-w-3xl"
                  >
                    {isModelLoader ? (
                      <tr
                        style={{
                          height: "120px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <td>
                          <CircularProgress />
                        </td>
                      </tr>
                    ) : (
                      <>
                        <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6">
                          <div className="w-full">
                            <Select
                              value={modelPaperGenerationData?.board}
                              data={selectionDataBoard || []}
                              onChange={(e) => handelModelData("board", e)}
                              className="font-regular mb-3"
                              label="Board"
                              required
                              placeholder="All Board"
                              type={"board"}
                              getBoard={getBoardModelData}
                              isPage={true}
                            />
                            <Typography variant="caption" color={"error"}>
                              {!modelPaperGenerationData?.board
                                ? error?.board
                                : ""}
                            </Typography>
                          </div>
                          <div className="w-full">
                            <Select
                              value={modelPaperGenerationData?.medium}
                              data={selectionDataMedium || []}
                              onChange={(e) => handelModelData("medium", e)}
                              className="font-regular mb-3"
                              label="Medium"
                              required
                              placeholder="All Medium"
                              type={"medium"}
                              getMedium={getMediumModelData}
                              isPage={true}
                            />
                            <Typography variant="caption" color={"error"}>
                              {!modelPaperGenerationData?.medium
                                ? error?.medium
                                : ""}
                            </Typography>
                          </div>
                        </div>
                        <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                          <div className="w-full">
                            <Select
                              data={selectionDataStandard || []}
                              label="Standard"
                              required
                              placeholder="All Standard"
                              disabled={false}
                              type={"standard"}
                              value={modelPaperGenerationData?.standard || ""}
                              onChange={(e) => handelModelData("standard", e)}
                              getStandard={getStandardModelData}
                              isPage={true}
                            />
                            <Typography variant="caption" color={"error"}>
                              {!modelPaperGenerationData?.standard
                                ? error?.standard
                                : ""}
                            </Typography>
                          </div>
                          <div className="w-full">
                            <Select
                              data={selectionDataSemester || []}
                              label="Semester"
                              required
                              placeholder="All semester"
                              disabled={false}
                              type={"semester"}
                              value={modelPaperGenerationData?.semester || ""}
                              onChange={(e) => handelModelData("semester", e)}
                              getSemester={getSemesterModelData}
                              isPage={true}
                            />
                            <Typography variant="caption" color={"error"}>
                              {!modelPaperGenerationData?.semester
                                ? error?.semester
                                : ""}
                            </Typography>
                          </div>
                        </div>

                        <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                          <div className="w-full">
                            <Select
                              data={selectionDataSubject || []}
                              label="Subject"
                              required
                              disabled={false}
                              placeholder="All subject"
                              type={"subject"}
                              value={modelPaperGenerationData?.subject || ""}
                              onChange={(e) => handelModelData("subject", e)}
                              getSubject={getSubjectModelData}
                              isPage={true}
                            />
                            <Typography variant="caption" color={"error"}>
                              {!modelPaperGenerationData?.subject
                                ? error?.subject
                                : ""}
                            </Typography>
                          </div>

                          <div className="w-full">
                            <Select
                              label="Total Marks"
                              required
                              disabled={false}
                              placeholder="All Marks"
                              type={"totalMarks"}
                              value={modelPaperGenerationData?.totalMarks || ""}
                              onChange={(e) => handelModelData("totalMarks", e)}
                              data={TotalMarksData || []}
                            />
                            <Typography variant="caption" color={"error"}>
                              {!modelPaperGenerationData?.totalMarks
                                ? error?.totalMarks
                                : ""}
                            </Typography>
                          </div>
                        </div>
                        <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                          <div className="w-full flex flex-col">
                            <Input
                              placeholder="Enter Label"
                              label="Section Label"
                              id=""
                              name="School Type"
                              type="text"
                              autoComplete="given-name"
                              // required
                              className="w-full "
                              value={
                                modelPaperGenerationData.sectionLabel || ""
                              }
                              onChange={(e) =>
                                handelModelData("sectionLabel", e.target.value)
                              }
                            />
                            <Typography variant="caption" color={"error"}>
                              {!modelPaperGenerationData?.sectionLabel
                                ? error?.sectionLabel
                                : ""}
                            </Typography>
                          </div>
                          <div className="w-full flex flex-col">
                            <Input
                              placeholder="Section Marks"
                              label="Section Marks"
                              id=""
                              name="School Type"
                              type="text"
                              autoComplete="given-name"
                              required
                              className="w-full"
                              value={
                                modelPaperGenerationData.sectionMarks || ""
                              }
                              onChange={(e) =>
                                handelModelData("sectionMarks", e.target.value)
                              }
                            />
                            <Typography variant="caption" color={"error"}>
                              {!modelPaperGenerationData?.sectionMarks
                                ? error?.sectionMarks
                                : ""}
                            </Typography>
                          </div>
                        </div>
                        <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                          <div className="w-full flex flex-col">
                            <Input
                              placeholder="Notice Title"
                              label="Notice Title"
                              id=""
                              name="School Type"
                              type="text"
                              autoComplete="given-name"
                              required
                              className="w-full"
                              value={modelPaperGenerationData.noticeTitle || ""}
                              onChange={(e) =>
                                handelModelData("noticeTitle", e.target.value)
                              }
                            />
                            <Typography variant="caption" color={"error"}>
                              {!modelPaperGenerationData?.noticeTitle
                                ? error?.noticeTitle
                                : ""}
                            </Typography>
                          </div>{" "}
                        </div>
                        <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                          <div className="w-full flex flex-col">
                            {/* <Select
                              data={qTypeModalData || []}
                              label="Question Type"
                              value={modelPaperGenerationData?.questionType}
                              onChange={(e) => handelModelData("questionType", e)}
                              required
                              multipleSelect
                              type={"qType"}
                              placeholder="Q.Type"
                              getQType={getQTypeModalData}
                              // getSelectedItems={handleSelectedItems}
                            /> */}
                            <label className="mb-2 block text-sm font-medium text-[#252F40] font-poppins">
                              Question Type
                            </label>
                            <Autocomplete
                              multiple
                              disableCloseOnSelect
                              sx={{
                                background: "white",
                                borderRadius: "10px",
                                border: "0px solid #E2E8F0",
                                width: "full",
                                position: "relative",
                                zIndex: "0",
                              }}
                              id="tags-filled"
                              options={qTypeModalData.map((option) => ({
                                id: option.id.toString(),
                                name: option.name,
                                selected: value.some(
                                  (item) => item.id === option.id.toString()
                                ),
                              }))}
                              value={value}
                              defaultValue={[]}
                              onChange={handleChange}
                              renderTags={(values, getTagProps) => {
                                setValues(values);

                                return values.map((option, index) => (
                                  <Chip
                                    variant="outlined"
                                    sx={{
                                      backgroundColor: "#F0F1F2",
                                      borderRadius: "8px",
                                      border: "none",
                                    }}
                                    label={option?.name}
                                    {...getTagProps({ index })}
                                    key={option.id}
                                  />
                                ));
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  sx={{
                                    padding: "0px",
                                    margin: "0px",
                                  }}
                                  placeholder="Q.Type"
                                />
                              )}
                              getOptionLabel={(option) => option.name || ""}
                              renderOption={(props, option) => (
                                <li
                                  {...props}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                  key={option.id}
                                >
                                  {option.name}
                                  <Checkbox
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      "&.Mui-checked svg path": {
                                        color: "#001fff",
                                      },
                                    }}
                                    style={{ marginRight: 8 }}
                                    checked={values
                                      ?.map((item) => item?.id)
                                      ?.includes(parseInt(option.id))}
                                  />
                                </li>
                              )}
                            />
                            {/* <Select
                              data={qTypeModalData || []}
                              label="Question Type"
                              value={question?.questionType}
                              onChange={(e) =>
                                handleQuestion("questionType", e)
                              }
                              required
                              type={"qType"}
                              placeholder="Q.Type"
                              getQType={getQTypeModalData}
                            /> */}
                            <Typography variant="caption" color={"error"}>
                              {!modelPaperGenerationData?.questionType
                                ? error?.questionType
                                : ""}
                            </Typography>
                          </div>
                        </div>
                        <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                          <div className="w-full flex flex-col">
                            <Input
                              placeholder="Number of Question"
                              label="Total Questions"
                              id=""
                              name="School Type"
                              type="text"
                              autoComplete="given-name"
                              required
                              className="w-full"
                              value={
                                modelPaperGenerationData.totalQuestions || ""
                              }
                              onChange={(e) =>
                                handelModelData(
                                  "totalQuestions",
                                  e.target.value
                                )
                              }
                            />
                            <Typography variant="caption" color={"error"}>
                              {!modelPaperGenerationData?.totalQuestions
                                ? error?.totalQuestions
                                : ""}
                            </Typography>
                          </div>
                          <div className="w-full flex flex-col">
                            <Input
                              placeholder="Or Number Of Question"
                              label="Or Number Of Question"
                              id="firstname"
                              name="firstname"
                              type="text"
                              autoComplete="given-name"
                              className="w-full"
                              value={
                                modelPaperGenerationData.totalOrQuestions || ""
                              }
                              onChange={(e) =>
                                handelModelData(
                                  "totalOrQuestions",
                                  e.target.value
                                )
                              }
                            />
                            <Typography variant="caption" color={"error"}>
                              {!modelPaperGenerationData?.totalOrQuestions
                                ? error?.totalOrQuestions
                                : ""}
                            </Typography>
                          </div>
                        </div>
                      </>
                    )}
                  </Model>
                </div>
              </div>
            </div>

            <div className="sm:mb-0 overflow-hidden">
              <div className="flex flex-col mt-2">
                <section
                  className="bg-white rounded-2xl flex flex-col shadow-sm "
                  style={{
                    width: "100%",
                    height: "75vh",
                    margin: "0 auto",
                    padding: "20px",
                  }}
                >
                  {" "}
                  <div className="flex justify-between items-center mt-3 px-8 mb-2">
                    <div style={{ display: "flex", gap:'5px' }}>
                    <Input
                      id=""
                      name="Search"
                      type="text"
                      placeholder="Search here..."
                      autoComplete="given-name"
                      required
                      className="w-72"
                      onChange={(e) => {
                        setSearch(e?.target?.value);
                        setCurrentPage(1);
                      }}
                      value={search}
                    />
                    <Button
                        onClick={() => applyFilter()}
                        id=""
                        type="button"
                        // label={"SEARCH"}
                        searchicon={true}
                      />
                    </div>
                    <Records
                      setCurrentPage={setCurrentPage}
                      recordsPerPage={recordsPerPage}
                      setRecordsPerPage={setRecordsPerPage}
                      dataCount={dataCount}
                    />
                  </div>
                  <div
                    style={{
                      overflowY: "auto",
                      maxHeight: "58vh",
                      height: "60vh",
                      width: "100%",
                    }}
                  >
                    {" "}
                    {!isLoader && PaperGenerationData?.length <= 0 ? (
                      <NoDataFound />
                    ) : (
                      <table style={{ width: "100%" }}>
                        <thead
                          style={{
                            position: "sticky",
                            top: 0,
                            backgroundColor: "#f4f7f8",
                            zIndex: 1,
                          }}
                        >
                          <tr
                            style={{
                              borderBottom: "1px solid #dddddd",
                              textAlign: "left",
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: "#7B809A",
                              textTransform: "uppercase",
                              backgroundColor: "#f4f7f8",
                            }}
                          >
                            {" "}
                            <th
                              scope="col"
                              style={{ width: "3%", padding: "10px" }}
                            ></th>
                            {titleData.map(
                              (v, ind) =>
                                v?.title && (
                                  <th
                                    key={ind}
                                    style={{
                                      padding: "12px",
                                      backgroundColor: "#f4f7f8",
                                      position:
                                        v.title === "EDIT" ||
                                          v.title === "DELETE"
                                          ? "sticky"
                                          : "",
                                      right:
                                        v.title === "EDIT"
                                          ? "68px"
                                          : v.title === "DELETE"
                                            ? "0"
                                            : "",
                                    }}
                                  >
                                    <div className="flex justify-between">
                                      <span>{v.title}</span>
                                      {v.title !== "EDIT" &&
                                        v.title !== "DELETE" && (
                                          <span>
                                            <ChevronUpDownIcon
                                              onClick={() => {
                                                let res = SortData(
                                                  PaperGenerationData,
                                                  v.fieldName,
                                                  v.sort
                                                );

                                                let copyData = [...titleData];
                                                copyData[ind].sort =
                                                  v.sort > 0 ? -1 : 1;
                                                setTitleData(copyData);
                                                setPaperGenerationData(res);
                                              }}
                                              className="w-5 h-5"
                                            />
                                          </span>
                                        )}
                                    </div>
                                  </th>
                                )
                            )}
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                          {isLoader ? (
                            <tr style={{ height: "25rem" }}>
                              <td colSpan={9} style={{ textAlign: "center" }}>
                                <CircularProgress />
                              </td>
                            </tr>
                          ) : (
                            PaperGenerationData?.map((data, id) => (
                              <tr
                                key={id}
                                className="my-3 px-3 text-[#000] font-bold text-[13px]"
                              >
                                <td className="px-6 py-2.5 whitespace-no-wrap">
                                  <Checkbox
                                    checked={checkedItems.includes(data.id)}
                                    onChange={() =>
                                      handleCheckboxChange(data.id)
                                    }
                                  />
                                </td>
                                <td className="lg:table-cell whitespace-nowrap py-2.5 px-8">
                                  {data?.standardInfo?.name}
                                </td>
                                <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                                  {data?.semesterInfo?.semester}
                                </td>
                                <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                                  {data?.subjectInfo?.subject}
                                </td>
                                <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                                  {data?.tmarks}
                                </td>
                                <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                                  {data?.section}
                                </td>
                                <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                                  {data?.smarks}
                                </td>
                                <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                                  {data?.noticetitle}
                                </td>
                                {access?.edit && (
                                  <td
                                    className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                                    style={{
                                      position: "sticky",
                                      right: "68px",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <ActionButton
                                      key={data.key}
                                      action="edit"
                                      onClick={() => {
                                        setIsModel(true);
                                        setModelType("edit");
                                        setSelectedId(data.id);
                                      }}
                                    />
                                  </td>
                                )}
                                {access?.delete && (
                                  <td
                                    className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                                    style={{
                                      position: "sticky",
                                      right: "0",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <ActionButton
                                      key={data.key}
                                      action="delete"
                                      onClick={() => {
                                        setDeleteType("single");
                                        setOpenModel(true);
                                        setSelectAutoPaperGenerationId(
                                          data?.id
                                        );
                                      }}
                                    />
                                  </td>
                                )}
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    recordsPerPage={recordsPerPage}
                    data={PaperGenerationData}
                    dataCount={dataCount}
                    numbers={numbers}
                    npage={npage}
                    lastIndex={lastIndex}
                    firstIndex={firstIndex}
                  />
                </section>
              </div>

              {/* Delete */}
              <DeletePopup
                title={"Delete"}
                show={openModel}
                description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
                onCancel={() => {
                  setOpenModel(false);
                  setSelectAutoPaperGenerationId("");
                }}
                onClose={() => {
                  setOpenModel(false);
                  setSelectAutoPaperGenerationId("");
                }}
                onDelete={() => {
                  _deletePaperGeneration();
                }}
                cancelButtonRef={cancelButtonRef}
                okText="Delete"
                cancelText="Cancel"
              />
              {/* Delete */}
              <FloatNotification
                show={showFloatNotification}
                title={"Successfully deleted!"}
                type="success"
                description={"Data Deleted."}
                onClick={() => {
                  setShowFloatNotification(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AutoPaperGeneration;
