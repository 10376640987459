import React, { useState } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

const innerStyle = {
  ".Mui-selected": {
    backgroundColor: "#2973e8 !important",
    color: "#fff !important",
  },
};

const DateEditCalendar = (Props) => {
  const [value, setValue] = useState(Props?.date ? dayjs(Props?.date) : null);

  const handleOnChange = (newValue) => {
    const selectedDate = newValue ? dayjs(newValue) : null;

    setValue(selectedDate);
    const val = newValue?.format("YYYY-MM-DD");
    Props.saveTheDate(val);
    Props.handleCloseCalendar();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        sx={innerStyle}
        value={value}
        onChange={handleOnChange}
        autoFocus
        disableFuture
      />
    </LocalizationProvider>
  );
};

export default DateEditCalendar;
