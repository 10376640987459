import React from "react";
import MathJax from "react-mathjax";

const LatexTextRenderer = ({ text }) => {
  console.log("text-------------->",text);
  const answer = text?.answer;
  const finalAnswer = text?.finalAnswer;
  console.log("answer-------finalAnswer------->",answer,finalAnswer);


 
  const parts = parseText(text);


  return (
    <MathJax.Provider>
      <div className="items-center ml-2">
        {parts.map((part, index) => {
          console.log('part.content🎈🎈', part)
          if (part.type === "html") {
            return (
                <span
                  style={{ display: "flex" , flexDirection: "column", width: "max-content" }}
                  key={index}
                  dangerouslySetInnerHTML={{ __html: part.content }}
                />
            );
          } else if (part.type === "latex") {
            return (
              <span key={index}>
                <MathJax.Node formula={part.content} />
              </span>
            );
          } else {
            return null;
          }
        })}
      </div>
    </MathJax.Provider>
  );
};

const parseText = (text) => {
  const parts = [];
  let currentText = text;
  const replacements = {
    "&times;": "\\times",
    "&ang;": "∠",
    "&deg;": "^{\\circ}",
    "&nbsp;": " ",
    "&there4;": "∴",
    "&pi;": "𝝅",
    "&ndash;": "-",
    "&ne;": "≠",
    "&perp;": "⊥",
    "&cong;": "≅",
    "&#39;": "'",
    "&hellip;": "…",
    "&middot;": "·",
    "&sum;": "∑",
    "&theta;": "θ",
    "&fnof;": "ƒ",
    "&Delta;": "Δ",
    "&Alpha;": "Α",
    "&Epsilon;": "E",
    "&Rho;": "R",
    "&Beta;": "B",
    "&plusmn;": "±",

    // "&Eta;": "Η",
    // "&Phi;": "Φ",
    // "&Chi;": "Χ",
    // "&Gamma;": "Γ",
    // "&Iota;": "Ι",
    // "&Kappa;": "Κ",
    // "&Lambda;": "Λ",
    // "&Mu;": "Μ",
    // "&Nu;": "Ν",
    // "&Omicron;": "Ο",
    // "&Pi;": "Π",
    // "&Sigma;": "Σ",
    // "&Tau;": "Τ",
    // "&Omega;": "Ω",
    // "&Xi;": "Ξ",
    // "&alpha;": "α",
    // "&epsilon;": "ε",
    // "&rho;": "ρ",
    // "&beta;": "β",
    // "&eta;": "η",
    // "&phi;": "φ",
    // "&chi;": "χ",
    // "&gamma;": "γ",
    // "&iota;": "ι",
    // "&kappa;": "κ",
    // "&lambda;": "λ",
    // "&mu;": "μ",
    // "&nu;": "ν",
    // "&omicron;": "ο",
    // "&pi;": "π",
    // "&sigma;": "σ",
    // "&tau;": "τ",
    // "&omega;": "ω",
    // "&xi;": "ξ",
  };
  const latexRegex = /\$(.*?)\$/gs;

  let match;
  let lastIndex = 0;
  // while ((match = latexRegex.exec(currentText)) !== null) {
  //   if (match.index !== lastIndex) {
  //     parts.push({
  //       type: "html",
  //       content: currentText.substring(lastIndex, match.index),
  //     });
  //   }

  //   let latexContent = match[1];
  //   if (latexContent.includes("&times;")) {
  //     latexContent = latexContent.replace(/&times;/g, " x ");
  //   }
  //   if (latexContent.includes("&ang;")) {
  //     latexContent = latexContent.replace(/&ang;/g, "∠");
  //   }
  //   if (latexContent.includes("&deg;")) {
  //     latexContent = latexContent.replace(/&deg;/g, "^{\\circ}");
  //   }
  //   if (latexContent.includes("&nbsp;")) {
  //     latexContent = latexContent.replace(/&nbsp;/g, " ");
  //   }
  //   if (latexContent.includes("&there4;")) {
  //     latexContent = latexContent.replace(/&there4;/g, "∴");
  //   }
  //   if (latexContent.includes("&pi;")) {
  //     latexContent = latexContent.replace(/&pi;/g, "𝝅");
  //   }
  //   if (latexContent.includes("&ndash;")) {
  //     latexContent = latexContent.replace(/&ndash;/g, "-");
  //   }
  //   if (latexContent.includes("&ne;")) {
  //     latexContent = latexContent.replace(/&ne;/g, "≠");
  //   }
  //   if (latexContent.includes("&perp;")) {
  //     latexContent = latexContent.replace(/&perp;/g, "⊥");
  //   }
  //   if (latexContent.includes("&cong;")) {
  //     latexContent = latexContent.replace(/&cong;/g, "≅");
  //   }
  //   if (latexContent.includes("&#39;")) {
  //     latexContent = latexContent.replace(/&#39;/g, "'");
  //   }
  //   if (latexContent.includes("&hellip;")) {
  //     latexContent = latexContent.replace(/&hellip;/g, "…");
  //   }
  //   if (latexContent.includes("&middot;")) {
  //     latexContent = latexContent.replace(/&middot;/g, "·");
  //   }
  //   if (latexContent.includes("&sum;")) {
  //     latexContent = latexContent.replace(/&sum;/g, "∑");
  //   }
  //   if (latexContent.includes("&theta;")) {
  //     latexContent = latexContent.replace(/&theta;/g, "θ");
  //   }
  //   if (latexContent.includes("&fnof;")) {
  //     latexContent = latexContent.replace(/&fnof;/g, "ƒ");
  //   }

  //   // if (latexContent.includes("\\sqrt{")) {
  //   //   latexContent = latexContent.replace(/\\sqrt{/g, "√");
  //   // }

  //   parts.push({
  //     type: "latex",
  //     content: latexContent,
  //   });

  //   lastIndex = match.index + match[0].length;
  // }
  while ((match = latexRegex.exec(currentText)) !== null) {
    if (match.index !== lastIndex) {
      parts.push({
        type: "html",
        content: currentText.substring(lastIndex, match.index),
      });
    }

    let latexContent = match[1];
    for (const [key, value] of Object.entries(replacements)) {
      latexContent = latexContent.replace(new RegExp(key, "g"), value);
    }

    parts.push({
      type: "latex",
      content: latexContent,
    });

    lastIndex = match.index + match[0]?.length;
  }

  if (lastIndex < currentText?.length) {
    parts.push({
      type: "html",
      content: currentText.substring(lastIndex),
    });
  }

  return parts;
};
export default LatexTextRenderer;
