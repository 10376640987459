import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { ElipsVerticalIcon } from "../Icons/ElipsVerticalIcon";
import { EyeIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const ActionPopup = ({
  onClick = () => {},
  onDelete = () => {},
  onApprove = () => {},
  onPreview = () => {},
  accessEdit,
  accessDelete,
  accessApprove,
  accessPreview,
}) => {
  return (
    <div>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="z-10 flex items-center ">
            <span className="sr-only">Open options</span>
            <ElipsVerticalIcon />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 z-20 mt-0 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            {accessEdit && (
              <div className="py-1 w-full">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type="button"
                      onClick={onClick}
                      className={classNames(
                        active
                          ? "bg-gray-100/60 text-gray-900"
                          : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm  w-full"
                      )}
                    >
                      <PencilIcon
                        className="mr-3 h-5 w-5 text-gray-400 "
                        aria-hidden="true"
                      />
                      Edit
                    </button>
                  )}
                </Menu.Item>
              </div>
            )}
            {accessDelete && (
              <div className="py-1 w-full">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type="button"
                      onDelete
                      onClick={onDelete}
                      className={classNames(
                        active
                          ? "bg-gray-100/60 text-gray-900"
                          : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm  w-full"
                      )}
                    >
                      <TrashIcon
                        className="mr-3 h-5 w-5 text-gray-400 "
                        aria-hidden="true"
                      />
                      Delete
                    </button>
                  )}
                </Menu.Item>
              </div>
            )}
            {accessApprove && (
              <div className="py-1 w-full">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type="button"
                      onDelete
                      onClick={onApprove}
                      className={classNames(
                        active
                          ? "bg-gray-100/60 text-gray-900"
                          : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm  w-full"
                      )}
                    >
                      <PencilIcon
                        className="mr-3 h-5 w-5 text-gray-400 "
                        aria-hidden="true"
                      />
                      Approve
                    </button>
                  )}
                </Menu.Item>
              </div>
            )}
            {accessPreview && (
              <div className="py-1 w-full">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type="button"
                      onDelete
                      onClick={onPreview}
                      className={classNames(
                        active
                          ? "bg-gray-100/60 text-gray-900"
                          : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm  w-full"
                      )}
                    >
                      <EyeIcon
                        className="mr-3 h-5 w-5 text-gray-400 "
                        aria-hidden="true"
                      />
                      Preview
                    </button>
                  )}
                </Menu.Item>
              </div>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default ActionPopup;
