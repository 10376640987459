import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
  TextareaAutosize,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BookHeader from "./BookHeader";
import { Api } from "../Api/Api";
import * as yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../Image/logo.png";
import axios from "axios";
import { AuthLayout } from "./AuthLayout";
import loginImage from "../Image/loginimg.png";
import { TiTick } from "react-icons/ti";
import { MdClose } from "react-icons/md";
// import Razorpay from "razorpay";
import { ErrorToast, SuccessToast } from "./toast";
import { TextArea } from "./Input";
import Divider from "@mui/material/Divider";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import OrderPaymentSuccessModel from "./OrderPaymentSuccessModel";
const Book = ({
  isBuyModalOpen,
  closeBuyModal,
  // standardID,
  bookData,
  productCount,
  totalQTYCount,
  totalPrice,
  discount,
  finalAmount,
  referCode,
  courierCharge,
  verifyData,
  formData,
  setFormData,
  handleOrderPaymentSuccess,
}) => {
  const [errors, setErrors] = useState({});
  const [selectedBookId, setSelectedBookId] = useState({});
  const [loading, setLoading] = useState(false);

  const [isOrderPaymentSuccessModel, setIsOrderPaymentSuccessModel] =
    useState(false);
  //  const RAZORPAYKEY = 'rzp_test_zlnJL2IPz5C8L4'; // TEST
  const RAZORPAYKEY = "rzp_live_oJwJ4EBDWMmOmf"; // Live
  const resetForm = () => {
    setFormData({
      // standard: "",
      // book: "",
      fullName: "",
      email: "",
      fullAddress: "",
      pincode: "",
      mobileNo: "",
      // qty: "",
      // total: "",
      // discount: "",
      // courierCharge: "",
      // finalAmount: "",
      // refercode: "",
    });
  };
  const emailPattern =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const validationSchema = yup.object().shape({
    // standard: yup.string().required("Standard is required"),
    // book: yup.string().required("Book is required"),
    fullName: yup.string().required(" Name is required"),
    email: yup
      .string()
      .matches(emailPattern, "Invalid email format")
      .required("Email is required"),
    // lastName: yup.string().required("Last Name is required"),
    // houseNumber: yup.string().required("House Number is required"),
    fullAddress: yup.string().required("fullAddress is required"),
    // taluko: yup.string().required("Taluko is required"),
    // district: yup.string().required("District is required"),
    pincode: yup
      .string()
      .required("Pincode is required")
      .matches(/^\d{6}$/, "Invalid Pincode"),
    // city: yup.string().required("City is required"),
    // state: yup.string().required("State is required"),

    mobileNo: yup
      .string()
      .required("Mobile No. is required")
      .matches(/^\d{10}$/, "Invalid Mobile Number"),
    // price: yup.string().required("Price is required"),
    // qty: yup
    //   .number()
    //   .required("Quantity is required")
    //   .positive("Quantity should be a positive number"),
    // total: yup.string().required("Total is required"),
    // courierCharge: yup.string().required("Courier Charge is required"),
    // finalAmount: yup.string().required("Final Amount is required"),
  });
  const handleChange = (fieldName) => (event) => {
    const value = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  //   {
  //     "razorpay_payment_id": "pay_NMulBQAadBUpc6",
  //     "razorpay_order_id": "order_NMukBIoACOTt7F",
  //     "razorpay_signature": "48fc4a9efa8c9bd6d6e5834ef099f38d5fb06f3fb766157b05d09b0198eefb1d"
  // }

  // const finalAmount = formData.finalAmount * 100;

  function forceClearRazorpayLocalStorage() {
    try {
      const iframe = document.createElement("iframe");
      iframe.src = "https://api.razorpay.com/force-clear-localstorage-script";
      iframe.style.display = "none";

      document.body.appendChild(iframe);

      setTimeout(() => {
        document.body.removeChild(iframe);
      }, 1000);
    } catch (error) {
      console.error(
        "Error during Razorpay localStorage force clearing:",
        error
      );
    }
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    document.cookie.split(";").forEach((cookie) => {
      const [name] = cookie.split("=");
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    });
    // localStorage.clear();

    // sessionStorage.clear();
    forceClearRazorpayLocalStorage();
  }, []);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      let field;
      setLoading(true);

      const requiredFields = [
        // "standard",
        // "book",
        "fullName",
        "email",
        // "lastName",
        // "houseNumber",
        "fullAddress",
        // "taluko",
        // "district",
        "pincode",
        // "city",
        // "state",
        "mobileNo",
        // "price",
        // "qty",
        // "total",
        // "courierCharge",
        // "finalAmount",
      ];

      for (const field of requiredFields) {
        if (!formData[field]) {
          throw new Error(`${field} is required`);
        }
      }

      setErrors({});

      const bookId = bookData.map((item) => ({
        id: item?.id,
        quantity: item?.quantity,
      }));

      const body = {
        // standardId: formData.standard,
        // subjectId: selectedBookId,
        bookId: bookId,
        fullName: formData.fullName,
        // lastName: formData.lastName,
        // houseNo: formData.houseNumber,
        fullAdress: formData.fullAddress,
        // taluko: formData.taluko,
        // city: formData.city,
        // district: formData.district,
        // state: formData.state,
        pincode: formData.pincode,
        email: formData.email,
        mobileNo: formData.mobileNo,
        price: parseInt(totalPrice),
        quantity: parseInt(totalQTYCount),
        promocode: verifyData?.id,
        discount: parseInt(discount),
        courierCharge: parseInt(courierCharge),
        finalAmount: parseInt(finalAmount),
        discription: bookData?.[0]?.name,
      };

      const { data, statusCode } = await Api.postRequest(
        `api/order/multipleOrderCreate`,
        body
      );
      const req = JSON.parse(data);

      await validationSchema.validate(formData, { abortEarly: false });

      if (statusCode === 200) {
        const fullName = formData.fullName;
        const apiKey = "rzp_test_zlnJL2IPz5C8L4:cAZS3JWiouv1znQIIYUKQkhe";

        //Test key
        //  key: "rzp_test_zlnJL2IPz5C8L4",

        //Live key
        //  key: "rzp_live_oJwJ4EBDWMmOmf",

        const updatedOptions = {
          key: RAZORPAYKEY,
          amount: finalAmount,
          currency: "INR",
          name: "Youth",
          description: bookData?.[0]?.name,
          image: "https://patramkids.s3.ap-south-1.amazonaws.com/logo.png",
          order_id: req?.razorpayOrderId,
          handler: async function (response) {
            // window.location.reload();

            const param = {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: req?.razorpayOrderId,
              razorpay_signature: response.razorpay_signature,
            };
            const { data } = await Api.postRequest(
              `api/order/paymentSuccess`,
              param
            );
            
            JSON.parse(data);
            handleOrderPaymentSuccess();
            resetForm();
            setIsOrderPaymentSuccessModel(true);
            const paymentId = response.razorpay_payment_id;
          },
          prefill: {
            name: fullName,
            contact: formData.mobileNo,
            email: formData.email,
          },
          notes: {
            address: ` ${formData.fullAddress} ${formData.city}`,
          },
          theme: {
            color: "#138fc2",
            hide_topbar: false,
          },
          allow_international: true,
        };

        var rzp1 = new window.Razorpay(updatedOptions);
        rzp1.open();
        if (field === "discount" || field === "finalAmount") {
          setFormData((prevData) => ({
            ...prevData,
            [field]: event.target.value || "",
          }));
        }
      }
    } catch (validationErrors) {
      console.error(validationErrors, 1235);
      const errors = {};
      if (validationErrors && validationErrors.inner) {
        if (Array.isArray(validationErrors.inner)) {
          validationErrors.inner.forEach((error) => {
            errors[error.path] = error.message;
          });
        } else {
          console.error(validationErrors.inner);
          toast.error("An unexpected error occurred during form validation.");
        }
      } else {
        console.error(validationErrors);
      }

      setErrors(errors);
      toast.error("Please fill in required fields correctly.");
    } finally {
      setLoading(false);
      localStorage.removeItem("cart");
      localStorage.removeItem("productCount");
    }
  };

  const handleClosePaymentSuccessModel = (e) => {
    setIsOrderPaymentSuccessModel(false);
  };

  return (
    <>
      {isOrderPaymentSuccessModel && (
        <OrderPaymentSuccessModel
          isOrderPaymentSuccessModel={isOrderPaymentSuccessModel}
          handleClosePaymentSuccessModel={handleClosePaymentSuccessModel}
        />
      )}
      <div
        style={{
          display: isBuyModalOpen ? "flex" : "none",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            // padding: "20px",
            borderRadius: "8px",
            maxWidth: "600px",
            width: "100%",
            position: "relative",
            margin: "10px",
          }}
        >
          <Box
            sx={{
              // backgroundColor: "#ffffff",
              // borderRadius: "20px",
              width: "100%",
              maxHeight: "65vh",
              overflowY: "auto",
              // "@media (max-width: 425px)": { padding: "20px" },
              // "@media (max-width: 768px) and (min-width: 425px)": {
              //   padding: "20px",
              // },
              // "@media (max-width: 1024px) and (min-width: 768px)": {
              //   padding: "20px",
              // },
              // "@media (max-width: 1440px) and (min-width: 1024px)": {
              //   padding: "20px",
              // },
            }}
          >
            <Box
              sx={{
                backgroundColor: "#ffffff",
                borderRadius: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "30px",
                  textAlign: "center",
                  position: "sticky",
                }}
              >
                <div
                  style={{
                    color: "#000000",
                    fontSize: "20px",
                    fontWeight: "600",
                    // fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Personal Information
                </div>
                {/* <Typography
                sx={{
                  fontSize: 15,
                  color: "#515656",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                વધારે માહિતી માટે કોન્ટેક્ટ કરો +91 9712444548 / +91 9712444549
              </Typography> */}
              </Box>
              <div
                style={{
                  position: "absolute",
                  top: "30px",
                  right: "30px",
                  cursor: "pointer",
                }}
                onClick={closeBuyModal}
              >
                <MdClose size={24} />
              </div>

              <Divider sx={{ marginBottom: "5px" }} />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "20px",
              }}
            >
              <form onSubmit={(event) => handleSubmit(event)}>
                <Grid
                  container
                  spacing={0}
                  sx={{ width: "100%", maxWidth: "50rem" }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={12} mt={2} mb={2}>
                      <InputLabel
                        sx={{
                          fontWeight: "bold",
                          fontSize: 15,
                          color: "#515656",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Full Name
                      </InputLabel>
                      <TextField
                        size="small"
                        style={{ marginTop: "8px", backgroundColor: "#fff" }}
                        fullWidth
                        value={formData.fullName}
                        onChange={handleChange("fullName")}
                        id="outlined-basic"
                        placeholder="Full Name"
                        variant="outlined"
                      />
                      {errors["fullName"] && (
                        <Typography variant="caption" color="error">
                          {errors["fullName"]}
                        </Typography>
                      )}
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={6} lg={6} mt={2} mb={2}>
                      <InputLabel
                        sx={{
                          fontWeight: "bold",
                          fontSize: 15,
                          color: "#515656",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Last Name
                      </InputLabel>
                      <TextField
                        size="small"
                        style={{ marginTop: "8px", backgroundColor: "#fff" }}
                        className="font-regular mb-3"
                        fullWidth
                        value={formData.lastName}
                        onChange={handleChange("lastName")}
                        id="outlined-basic"
                        placeholder="Last Name"
                        variant="outlined"
                      />

                      {errors["lastName"] && (
                        <Typography variant="caption" color="error">
                          {errors["lastName"]}
                        </Typography>
                      )}
                    </Grid> */}
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} mt={0} mb={2}>
                      <InputLabel
                        sx={{
                          fontWeight: "bold",
                          fontSize: 15,
                          color: "#515656",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Email
                      </InputLabel>
                      <TextField
                        size="small"
                        style={{ marginTop: "8px", backgroundColor: "#fff" }}
                        className="font-regular mb-3"
                        fullWidth
                        value={formData.email}
                        onChange={handleChange("email")}
                        id="outlined-basic"
                        placeholder="E-mail address"
                        variant="outlined"
                      />

                      {errors["email"] && (
                        <Typography variant="caption" color="error">
                          {errors["email"]}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      mt={2}
                      mb={0}
                      sx={{ mt: 0, padding: 0 }}
                    >
                      <InputLabel
                        sx={{
                          fontWeight: "bold",
                          fontSize: 15,
                          color: "#515656",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Full Address
                      </InputLabel>
                      <TextArea
                        style={{
                          marginTop: "8px",
                          width: "100%",
                          borderRadius: "4px",
                          padding: "12px",
                        }}
                        className="font-regular mb-3"
                        value={formData.fullAddress}
                        onChange={handleChange("fullAddress")}
                        placeholder="Full Address"
                        // minRows={2}
                        // maxRows={4}
                      />

                      {errors["fullAddress"] && (
                        <Typography variant="caption" color="error">
                          {errors["fullAddress"]}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} mt={0} mb={3}>
                      <InputLabel
                        sx={{
                          fontWeight: "bold",
                          fontSize: 15,
                          color: "#515656",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Pincode (પિનકોડ)
                      </InputLabel>
                      <TextField
                        size="small"
                        style={{ marginTop: "8px", backgroundColor: "#fff" }}
                        className="font-regular mb-3"
                        fullWidth
                        value={formData.pincode}
                        onChange={handleChange("pincode")}
                        id="outlined-basic"
                        placeholder="Pincode (પિનકોડ)"
                        variant="outlined"
                      />

                      {errors["pincode"] && (
                        <Typography variant="caption" color="error">
                          {errors["pincode"]}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} mt={0} mb={3}>
                      <InputLabel
                        sx={{
                          fontWeight: "bold",
                          fontSize: 15,
                          color: "#515656",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Mobile no.
                      </InputLabel>
                      <TextField
                        size="small"
                        style={{ marginTop: "8px", backgroundColor: "#fff" }}
                        className="font-regular mb-3"
                        fullWidth
                        value={formData.mobileNo}
                        onChange={handleChange("mobileNo")}
                        id="outlined-basic"
                        placeholder="Mobile no."
                        variant="outlined"
                      />

                      {errors["mobileNo"] && (
                        <Typography variant="caption" color="error">
                          {errors["mobileNo"]}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  {/* <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6} lg={6} mt={2} mb={0}>
                    <InputLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: 15,
                        color: "#515656",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Total Price
                    </InputLabel>
                    <TextField
                      size="small"
                      style={{ marginTop: "8px", backgroundColor: "#eee" }}
                      className="font-regular mb-3"
                      fullWidth
                      value={formData.total}
                      onChange={handleChange("total")}
                      id="outlined-basic"
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                    />

                   
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} mt={2} mb={0}>
                    <InputLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: 15,
                        color: "#515656",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Total Qty
                    </InputLabel>
                    <TextField
                      size="small"
                      style={{ marginTop: "8px", backgroundColor: "#eee" }}
                      className="font-regular mb-3"
                      fullWidth
                      value={formData.qty}
                      onChange={handleChange("qty")}
                      id="outlined-basic"
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                   
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
               
                  <Grid item xs={12} sm={12} md={6} lg={12} mt={2} mb={0}>
                    <InputLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: 15,
                        color: "#515656",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Referral code
                    </InputLabel>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        size="small"
                        style={{
                          marginTop: "8px",
                          backgroundColor: "#fff",
                        }}
                        className="font-regular mb-3"
                        fullWidth
                        value={formData.refercode}
                        onChange={handleChange("refercode")}
                        onBlur={handleChange("refercode")}
                        id="outlined-basic"
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <React.Fragment>
                              {verifyData?.name === formData.refercode && (
                                <InputAdornment position="end">
                                  <TiTick
                                    style={{
                                      color: "#4CAF50",
                                      fontSize: "2em",
                                    }}
                                  />
                                </InputAdornment>
                              )}
                            </React.Fragment>
                          ),
                        }}
                      />
                    </Box>
                    <Typography variant="caption" color={"error"}>
                      {verifyData === undefined || verifyData === null
                        ? "Invalid promo code"
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6} lg={6} mt={2} mb={0}>
                    <InputLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: 15,
                        color: "#515656",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Discount
                    </InputLabel>
                    <TextField
                      size="small"
                      style={{ marginTop: "8px", backgroundColor: "#eee" }}
                      className="font-regular mb-3"
                      fullWidth
                      value={parseInt(formData.discount)}
                      onChange={handleChange("discount")}
                      id="outlined-basic"
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                    />

                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} mt={2} mb={0}>
                    <InputLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: 15,
                        color: "#515656",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Courier Charge
                    </InputLabel>
                    <TextField
                      size="small"
                      style={{ marginTop: "8px", backgroundColor: "#eee" }}
                      className="font-regular mb-3"
                      fullWidth
                      value={formData.courierCharge}
                      onChange={handleChange("courierCharge")}
                      id="outlined-basic"
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                    />

                 
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12} mt={2} mb={2}>
                    <InputLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: 15,
                        color: "#515656",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Final Amount
                    </InputLabel>
                    <TextField
                      size="small"
                      style={{ marginTop: "8px", backgroundColor: "#eee" }}
                      className="font-regular mb-3"
                      fullWidth
                      value={formData.finalAmount}
                      onChange={handleChange("finalAmount")}
                      id="outlined-basic"
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                    />

                    
                  </Grid>
                </Grid> */}

                  <div
                    style={{
                      width: "100%",
                      gap: "10px",
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      style={{
                        padding: "10px 30px 10px 30px",
                        borderRadius: "30px",
                      }}
                      onClick={closeBuyModal}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={loading}
                      sx={{
                        borderRadius: "30px",
                        padding: "10px 30px 10px 30px",
                      }}
                    >
                      {loading ? "Submitting..." : `Pay ₹${finalAmount}`}
                    </Button>
                  </div>
                </Grid>
              </form>
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};

export default Book;
