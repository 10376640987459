import { SemesterActions } from "../action-types/SemesterActionType";

const initialState = {
    semesterData: [],
    error: null,
};

const SemesterReducer = (state = initialState, action) => {
    switch (action.type) {
        case SemesterActions.FETCH_SEMESTER_REQUEST:
            return {
                ...state,
                error: null,
            };
        case SemesterActions.FETCH_SEMESTER_SUCCESS:
            return {
                ...state,
                semesterData: action.payload,
            };
        case SemesterActions.FETCH_SEMESTER_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default SemesterReducer;
