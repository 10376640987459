import React from "react";
import MathJax from "react-mathjax";

const renderOption = (optionText) => {
  const latexPattern = /\$(.*?)\$/g;
  const parts = optionText.split(latexPattern);

  return parts.map((part, index) => {
    if (index % 2 === 0) {
      return <span key={index} dangerouslySetInnerHTML={{ __html: part }} />;
    } else {
      return <MathJax.Node key={index} formula={part} />;
    }
  });
};

const LatexTextRendererMcq = ({ text }) => {
  const { option1, option1_img, option2, option2_img, option3, option3_img, option4, option4_img } = text;

  return (
    <MathJax.Provider>
      <div className="flex items-center">
        <ul>
          <li style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "5px" }}>A .</div>
            {option1_img ? 
            <img
              src={option1_img}
              alt=""
              style={{ width:  "40px", height: "40px", marginRight: "5px" }}
            /> :
            renderOption(option1)
          }
          </li>
          <li style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "5px" }}>B .</div>
            {option2_img ? 
            <img
              src={option2_img}
              alt=""
              style={{ width:  "40px", height: "40px", marginRight: "5px" }}
            /> :
            renderOption(option2)
          }
          </li>{" "}
          <li style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "5px" }}>C .</div>
            {option3_img ? 
            <img
              src={option3_img}
              alt=""
              style={{ width:  "40px", height: "40px", marginRight: "5px" }}
            /> :
            renderOption(option3)
          }
          </li>{" "}
          <li style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "5px" }}>D .</div>
            {option4_img ? 
            <img
              src={option4_img}
              alt=""
              style={{ width:  "40px", height: "40px", marginRight: "5px" }}
            /> :
            renderOption(option4)
          }
          </li>
        </ul>
      </div>
    </MathJax.Provider>
  );
};

export default LatexTextRendererMcq;

// LatexTextRendererMcq.jsx
// import React from "react";
// import MathJax from "react-mathjax";

// const renderOption = (optionText) => {
//   const latexPattern = /\$(.*?)\$/g;
//   const parts = optionText.split(latexPattern);

//   return parts.map((part, index) => {
//     if (index % 2 === 0) {
//       return <span key={index} dangerouslySetInnerHTML={{ __html: part }} />;
//     } else {
//       return <MathJax.Node key={index} formula={part} />;
//     }
//   });
// };

// const LatexTextRendererMcq = ({ option }) => {
//   return (
//     <li style={{ display: "flex", alignItems: "center" }}>
//       <div style={{ marginRight: "5px" }}>{option.label}</div>
//       {renderOption(option.text)}
//     </li>
//   );
// };

// export default LatexTextRendererMcq;
