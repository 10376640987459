import React from "react";
import { Grid, Typography,Box } from "@mui/material";
import NoDataFoundLogo from "../Image/NoDataFound.svg";

const NotFound = () => {
  return (
    <Grid sx={{ paddingTop: "200px" }}>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <Box>
          <Box
            component="img"
            sx={{
              height: "20rem",
              width: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            alt=""
            src={NoDataFoundLogo}
          />
        </Box> */}
        <div fontWeight="600" style={{ padding: "20px", fontWeight: "600" }}>
          <div style={{ fontWeight: "600", fontSize: "25px" }}>
            Page is not Found
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default NotFound;
