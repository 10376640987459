import Pagination from "./Pagination";
import { useRef, useState, useEffect } from "react";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { Button } from "./Button";
import Model from "./Model";
import { Input } from "./Input";
import MasterTitle from "./MasterTitle";
import { Checkbox } from "./Checkbox";
import image1 from "../Image/image1.png";
import image2 from "../Image/image2.png";
import image3 from "../Image/image3.png";
import image4 from "../Image/image4.png";
import image5 from "../Image/image5.png";
import image6 from "../Image/image6.png";
import Records from "./Records";
import { SortData } from "./SortData";
import FloatNotification from "./FloatNotification";
import { PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import DeletePopup from "./DeletePopup";
import ActionPopup from "./ActionPopup";
import { Api } from "../Api/Api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ErrorToast, SuccessToast } from "./toast";
import NoDataFound from "./NoDataFound";
import CircularProgress from "@mui/material/CircularProgress";
import ActionButton from "./ActionButton";
import { Avatar } from "@mui/material";

const Teacher = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Master")?.[0];
  const [isModel, setIsModel] = useState(false);
  const [isSelectIMG, setIsSelectIMG] = useState(false);

  const [modelType, setModelType] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const cancelButtonRef = useRef(null);
  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [dataCount, setDataCount] = useState(0);
  const [TeacherData, setTeacherData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(dataCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [SelectedData, setSelectedData] = useState([]);
  const [titleData, setTitleData] = useState([
    {
      title: "NAME",
      sort: 1,
      fieldName: "name",
    },
    {
      title: "TEACHER POST",
      sort: 1,
      fieldName: "teacher post",
    },
    {
      title: "EDUCATION",
      sort: 1,
      fieldName: "position",
    },
    {
      title: "EXPERIENCE",
      sort: 1,
      fieldName: "position",
    },
    {
      title: "MOBILE NO",
      sort: 1,
      fieldName: "position",
    },
    {
      title: access?.edit ? "EDIT" : "",
      sort: 1,
      fieldName: "",
    },
    {
      title: access?.delete ? "DELETE" : "",
      sort: 1,
      fieldName: "",
    },
  ]);
  const [searchValue, setSearchValue] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const [deleteType, setDeleteType] = useState("single");
  const fileInputRef = useRef(null);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    getTeacherData();
  }, [recordsPerPage, currentPage]);

  const applyFilter = () => {
    getTeacherData();
  };

  const getTeacherData = async () => {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/teacher?offset=${currentPage}&limit=${recordsPerPage}&search=${searchValue}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setTeacherData(req?.data);
        setDataCount(req?.totalCount);
        setIsLoader(false);
      } else {
        ErrorToast(req?.message);
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const validationSchema = Yup.object({
    photo: Yup.mixed()
      .required("File is required")
      .test("fileType", "Only image files are allowed", (value) => {
        return (
          value && ["image/jpeg", "image/png", "image/gif"].includes(value.type)
        );
      }),
    name: Yup.string().required("Teacher Name is required"),
    teacher_post: Yup.string().required("Teacher Post is required"),
    education: Yup.string().required("Education is required"),
    experience: Yup.string().required("Experience is required"),
    phone: Yup.number()
      .typeError("Mobile No must be a number")
      .required("Mobile No is required")
      .positive("Mobile No must be a positive number")
      .integer("Mobile No must be an integer"),
  });

  const validationSchemaForEdit = Yup.object({
    name: Yup.string().required("Teacher Name is required"),
    teacher_post: Yup.string().required("Teacher Post is required"),
    education: Yup.string().required("Education is required"),
    experience: Yup.string().required("Experience is required"),
    phone: Yup.number()
      .typeError("Mobile No must be a number")
      .required("Mobile No is required")
      .positive("Mobile No must be a positive number")
      .integer("Mobile No must be an integer"),
  });

  const formik = useFormik({
    initialValues: {
      photo: "",
      name: "",
      teacher_post: "",
      education: "",
      experience: "",
      phone: "",
    },

    validationSchema:
      modelType === "edit" && !isSelectIMG
        ? validationSchemaForEdit
        : validationSchema,
    onSubmit: async (values) => {
      try {
        if (modelType === "add") {
          let formData = new FormData();

          formData.append("image", values?.photo);

          const { data } = await Api.axiospostRequest(
            "api/upload/single_file",
            formData
          );
          if (data?.success) {
            const payload = {
              photo: data?.data,
              name: values?.name,
              teacher_post: values?.teacher_post,
              education: values?.education,
              experience: values?.experience,
              phone: values?.phone,
            };

            const response = await Api.postRequest(
              "api/teacher/create",
              payload
            );

            const req2 = JSON.parse(response?.data);

            if (req2?.status === 200) {
              SuccessToast("Teacher Added Successfully");
              // setIsModel(false);
              fileInputRef.current.value = "";
              formik.setFieldValue("photo", null);
              getTeacherData();
              setIsSelectIMG(false);
              formik.resetForm();
            } else {
              ErrorToast(req2?.message);
            }
          }
        } else if (modelType === "edit") {
          if (isSelectIMG) {
            let formData = new FormData();

            formData.append("image", values?.photo);

            const { data } = await Api.axiospostRequest(
              "api/upload/single_file",
              formData
            );
            if (data?.success) {
              const payload = {
                photo: data?.data,
                name: values?.name,
                teacher_post: values?.teacher_post,
                education: values?.education,
                experience: values?.experience,
                phone: values?.phone,
              };

              const response = await Api.putRequest(
                `api/teacher/update/${SelectedData?.id}`,
                payload
              );
              const req2 = response?.data;

              if (req2?.status === 200) {
                SuccessToast("Teacher Updated Successfully");
                setIsModel(false);
                setIsSelectIMG(false);
                setSelectedData([]);
                getTeacherData();
                formik.resetForm();
              } else {
                ErrorToast(req2?.message);
              }
            }
          } else {
            const payload = {
              name: values?.name,
              teacher_post: values?.teacher_post,
              education: values?.education,
              experience: values?.experience,
              phone: values?.phone,
            };

            const { data } = await Api.putRequest(
              `api/teacher/update/${SelectedData?.id}`,
              payload
            );

            if (data?.status === 200) {
              SuccessToast("Teacher Updated Successfully");
              setIsModel(false);
              setIsSelectIMG(false);
              setSelectedData([]);
              getTeacherData();
              formik.resetForm();
            } else {
              ErrorToast(data?.message);
            }
          }
        }
      } catch (error) {
        ErrorToast(error?.message);
      }
    },
  });

  useEffect(() => {
    setDefaultValues();
  }, [SelectedData]);

  const setDefaultValues = () => {
    formik.setValues({
      ...formik.values,
      // photo: SelectedData?.photo,
      name: SelectedData?.name,
      teacher_post: SelectedData?.teacher_post,
      education: SelectedData?.education,
      experience: SelectedData?.experience,
      phone: SelectedData?.phone,
    });
  };

  const deleteTeacherData = async () => {
    try {
      let data;
      if (deleteType === "multi") {
        const body = {
          ids: checkedItems,
        };
        const response = await Api.postRequest(`api/teacher/delete`, body);
        data = response.data;
      } else {
        const body = {
          ids: [SelectedData?.id],
        };
        const response = await Api.postRequest(`api/teacher/delete`, body);
        data = response.data;
      }
      // const { data } = await Api.postRequest(
      //   `api/teacher/delete/${SelectedData?.id}`
      // );

      const req = JSON.parse(data);
      if (req?.status === 200) {
        setOpenModel(false);
        setSelectedData([]);
        getTeacherData();
        SuccessToast("Teacher Delete Successfully");
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) => {
      const isChecked = prevState.includes(id);

      const updatedItems = isChecked
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id];

      return updatedItems;
    });
  };

  return (
    <div>
      <div className="flex justify-between items-center mt-4">
        <div>
          <MasterTitle
            title="Teacher"
            description="Below are all the list of Teacher."
          />
        </div>

        <div>
          <div style={{ display: "flex", gap: "5px" }}>
            {access?.delete && (
              <Button
                id=""
                type="submit"
                icon={TrashIcon}
                label={"DELETE TEACHER"}
                onClick={() => {
                  setDeleteType("multi");
                  if (checkedItems.length > 0) {
                    setOpenModel(true);
                  }
                }}
              />
            )}
            {access?.add && (
              <Button
                id=""
                type="submit"
                icon={PlusIcon}
                label={"ADD TEACHER"}
                onClick={() => {
                  setIsModel(true);
                  setModelType("add");
                }}
              />
            )}
          </div>

          <Model
            title={modelType === "add" ? "Add Teacher" : "Edit Teacher"}
            show={isModel}
            onCancel={() => {
              setIsModel(false);
              // setSelectedData([]);
              // setIsSelectIMG(false);
              // formik.resetForm();
            }}
            onClose={() => {
              setIsModel(false);
              setSelectedData([]);
              setIsSelectIMG(false);
              formik.resetForm();
            }}
            onOk={() => {
              formik.handleSubmit();
            }}
            okText="SUBMIT"
            cancelText="CLOSE"
          >
            <label
              htmlFor="fileUpload"
              className="mb-3 block text-sm font-medium text-[#252F40] font-poppins"
            >
              Upload image file{" "}
              <span className="text-red-500 text-sm"> * </span>
            </label>
            <div className="mt-5 sm:mt-6 mb-3 flex flex-col space-y-10 sm:flex-row sm:space-y-0 sm:space-x-10 ">
              <input
                ref={fileInputRef}
                id="photo"
                name="photo"
                type="file"
                onChange={(event) => {
                  formik.setFieldValue("photo", event.currentTarget.files[0]);
                  formik.setFieldTouched("photo", false);
                  setIsSelectIMG(true);
                }}
                // onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
              />
              {!isSelectIMG && SelectedData?.photo ? (
                <div className="flex-shrink-0 w-10 h-10 ">
                  <img
                    className="w-full h-full"
                    src={SelectedData?.photo}
                    alt=""
                  />
                </div>
              ) : formik.values.photo instanceof File ? (
                <div className="flex-shrink-0 w-10 h-10 ">
                  <img
                    className="w-full h-full"
                    src={URL.createObjectURL(formik.values.photo)}
                    alt=""
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            {formik.touched.photo && formik.errors.photo && (
              <div className="text-red-500 text-sm">{formik.errors.photo}</div>
            )}
            <Input
              id="name"
              name="name"
              type="text"
              label="Name"
              placeholder="Name here..."
              autoComplete="given-name"
              required
              className="w-full mt-2 mb-3"
              // {...formik.getFieldProps("name")}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.name && formik.errors.name && (
              <div className="text-red-500 text-sm">{formik.errors.name}</div>
            )}
            <Input
              id="teacher_post"
              name="teacher_post"
              label="Post"
              placeholder="Post here..."
              type="text"
              autoComplete="given-name"
              required
              className="w-full mb-3"
              value={formik.values.teacher_post}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.teacher_post && formik.errors.teacher_post && (
              <div className="text-red-500 text-sm">
                {formik.errors.teacher_post}
              </div>
            )}
            <Input
              id="education"
              name="education"
              label="Education"
              placeholder="Education here..."
              type="text"
              autoComplete="given-name"
              required
              className="w-full mb-3"
              value={formik.values.education}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.education && formik.errors.education && (
              <div className="text-red-500 text-sm">
                {formik.errors.education}
              </div>
            )}
            <Input
              id="experience"
              name="experience"
              label="Experience"
              placeholder="Experience here..."
              type="text"
              autoComplete="given-name"
              required
              className="w-full mb-3"
              value={formik.values.experience}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.experience && formik.errors.experience && (
              <div className="text-red-500 text-sm">
                {formik.errors.experience}
              </div>
            )}
            <Input
              id="phone"
              name="phone"
              label="Mobile No"
              placeholder="Mobile here..."
              type="text"
              autoComplete="given-name"
              required
              className="w-full mb-3"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.phone && formik.errors.phone && (
              <div className="text-red-500 text-sm">{formik.errors.phone}</div>
            )}
          </Model>
        </div>
      </div>
      <div className="sm:mb-0 overflow-hidden ">
        <div className="flex flex-col mt-4">
          <section
            className="bg-white rounded-2xl flex flex-col shadow-sm "
            style={{
              width: "100%",
              height: "75vh",
              margin: "0 auto",
              padding: "20px",
            }}
          >
            {" "}
            <div className="flex justify-between items-center mt-6 px-8 mb-3">
              <div  style={{ display: "flex", gap:'5px' }}>
              <Input
                id=""
                name="Search"
                type="text"
                placeholder="Search here..."
                autoComplete="given-name"
                required
                className="w-72"
                value={searchValue}
                onChange={handleSearchChange}
              />
              <Button
                onClick={() => applyFilter()}
                id=""
                type="button"
                // label={"SEARCH"}
                searchicon={true}
              />
              </div>
              <div>
                <Records
                  recordsPerPage={recordsPerPage}
                  setCurrentPage={setCurrentPage}
                  setRecordsPerPage={setRecordsPerPage}
                  dataCount={dataCount}
                />
              </div>
            </div>
            <div
              style={{
                overflowY: "auto",
                maxHeight: "58vh",
                height: "60vh",
                width: "100%",
              }}
            >
              {" "}
              {!isLoader && TeacherData?.length <= 0 ? (
                <NoDataFound />
              ) : (
                <table style={{ width: "100%" }}>
                  <thead
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#f4f7f8",
                      zIndex: 1,
                    }}
                  >
                    <tr
                      style={{
                        borderBottom: "1px solid #dddddd",
                        textAlign: "left",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#7B809A",
                        textTransform: "uppercase",
                        backgroundColor: "#f4f7f8",
                      }}
                    >
                      <th
                        scope="col"
                        style={{ width: "3%", padding: "10px" }}
                      ></th>
                      {titleData.map((v, ind) => (
                        <th
                          key={ind}
                          // className="py-2 px-6"
                          style={{
                            padding: "12px",
                            backgroundColor: "#f4f7f8",
                            position:
                              v.title === "EDIT" || v.title === "DELETE"
                                ? "sticky"
                                : "",
                            right:
                              v.title === "EDIT"
                                ? "68px"
                                : v.title === "DELETE"
                                ? "0"
                                : "",
                          }}
                        >
                          <div className="flex justify-between">
                            <span>{v.title}</span>
                            {v.title !== "EDIT" && v.title !== "DELETE" && (
                              <span>
                                <ChevronUpDownIcon
                                  onClick={() => {
                                    let res = SortData(
                                      TeacherData,
                                      v.fieldName,
                                      v.sort
                                    );

                                    let copyData = [...titleData];
                                    copyData[ind].sort = v.sort > 0 ? -1 : 1;
                                    setTitleData(copyData);
                                    setTeacherData(res);
                                  }}
                                  className="w-5 h-5"
                                />
                              </span>
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {isLoader ? (
                      <tr style={{ height: "25rem" }}>
                        <td colSpan={6} style={{ textAlign: "center" }}>
                          <CircularProgress />
                        </td>
                      </tr>
                    ) : (
                      TeacherData?.map((data) => (
                        <tr
                          key={data.key}
                          className="my-4 px-6 text-[#000] font-reguler text-sm font-medium"
                        >
                          <td className="px-6 py-2 whitespace-no-wrap">
                            {/* <Checkbox type="checkbox" /> */}
                            <Checkbox
                              checked={checkedItems.includes(data.id)}
                              onChange={() => handleCheckboxChange(data.id)}
                            />
                          </td>
                          <td className="lg:table-cell whitespace-nowrap py-2.5 px-6">
                            <div className="flex items-center">
                              <div className="flex-shrink-0 w-10 h-10 ">
                                {/* <img
                                  className="w-full h-full"
                                  src={data.photo}
                                  alt=""
                                /> */}
                                <Avatar
                                  // alt="Image"
                                  src={data?.photo}
                                  sx={{
                                    borderRadius: "0%",
                                  }}
                                />
                              </div>
                              <div className="ml-3">
                                <p className="text-[#344767] font-bold whitespace-no-wrap">
                                  {data.name}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                            {data.teacher_post}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                            {data.education}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                            {data.experience}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                            {data.phone}
                          </td>
                          {access?.edit && (
                            <td
                              className="relative whitespace-nowrap  px-6 py-2.5 text-left"
                              style={{
                                position: "sticky",
                                right: "68px",
                                backgroundColor: "white",
                              }}
                            >
                              <ActionButton
                                key={data.key}
                                action="edit"
                                onClick={() => {
                                  setIsModel(true);
                                  setSelectedData(data);
                                  setModelType("edit");
                                }}
                              />
                            </td>
                          )}
                          {access?.delete && (
                            <td
                              className="relative whitespace-nowrap  px-6 py-2.5 text-left"
                              style={{
                                position: "sticky",
                                right: "0",
                                backgroundColor: "white",
                              }}
                            >
                              <ActionButton
                                key={data.key}
                                action="delete"
                                onClick={() => {
                                  setDeleteType("single");
                                  setOpenModel(true);
                                  setSelectedData(data);
                                }}
                              />
                            </td>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              recordsPerPage={recordsPerPage}
              data={TeacherData}
              numbers={numbers}
              npage={npage}
              lastIndex={lastIndex}
              firstIndex={firstIndex}
              dataCount={dataCount}
            />
          </section>
        </div>
        {/* Delete */}
        <DeletePopup
          title={"Delete"}
          show={openModel}
          description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
          onCancel={() => {
            setOpenModel(false);
            setSelectedData([]);
          }}
          onClose={() => {
            setOpenModel(false);
            setSelectedData([]);
          }}
          onDelete={() => {
            deleteTeacherData();
            // setOpenModel(false);
            // setShowFloatNotification(true);
          }}
          cancelButtonRef={cancelButtonRef}
          okText="Delete"
          cancelText="Cancel"
        />

        {/* Delete */}
        <FloatNotification
          show={showFloatNotification}
          title={"Successfully deleted!"}
          type="success"
          description={"Data Deleted."}
          onClick={() => {
            setShowFloatNotification(false);
          }}
        />
      </div>
    </div>
  );
};

export default Teacher;
