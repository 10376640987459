import React, { useEffect, useState } from "react";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast } from "./toast";

const MiniRagisterUser = () => {
  const [userDataTable, setUserDataTable] = useState([]);

  const _getUser = async (limit, offset) => {
    try {
      const { data } = await Api.getRequest(
        `api/auth/getAllUser?limit=${4}&offset=${1}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        const modifyData = req?.data?.map((e) => {
          return {
            ...e,
            isShow: false,
          };
        });
        setUserDataTable(modifyData);
      } else {
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    _getUser();
  }, []);

  return (
    <div>
      <table className="w-full mt-3">
        <thead>
          <tr className="border-b-1 bg-[#F9FAFB] text-left text-xs font-bold text-[#252F40]">
            <th className="py-3 px-8">SR.No</th>
            <th className="py-3 lg:px-2 xl:px-8">USER NAME</th>
            <th className="py-3 lg:px-2 xl:px-8">EMAIL</th>
            <th className="py-3 lg:px-2 xl:px-8">MOBILE</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {userDataTable?.length > 0 &&
            userDataTable?.map((data, id) => (
              <tr
                key={id}
                className="my-4 px-8 text-[#7B809A] font-reguler text-sm align-top"
              >
                <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                  {data.id}
                </td>
                <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                  {data?.username || data?.userInfo?.first_name || "-"}
                </td>
                <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                  {data?.userInfo?.email ? data?.userInfo?.email : "-"}
                </td>
                <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                  {data.mobile}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default MiniRagisterUser;
