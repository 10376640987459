import React, { lazy } from "react";
import Tabs from "./Tabs";

import  LearningImages from "./LearningImages";
import GenerateBanners from "./GenerateBanners";
import BatchBanners from "./BatchBanners";
import LiveBanners from "./LiveBanners";

const UserAccess = (props) => {
  let categories = [
    {
      name: "Learning Images",
      component: <LearningImages access={props?.access} />,
      path: "/advertisement/learning-images",
    },
    {
      name: "Paper Generate Banners",
      component: <GenerateBanners access={props?.access} />,
      path: "/advertisement/paper-generate-banners",
    },
    {
      name: "Batch Banners",
      component: <BatchBanners access={props?.access} />,
      path: "/advertisement/batch-banners",
    },
    {
      name: "Live Batch Banners",
      component: <LiveBanners access={props?.access} />,
      path: "/advertisement/live-batch-banners",
    },
  ];
  return <Tabs categories={categories} title="Advertisement" />;
};

export default UserAccess;
