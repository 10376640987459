import Pagination from "./Pagination";
import { useEffect, useMemo, useRef, useState } from "react";
import { ChevronUpDownIcon, PencilIcon } from "@heroicons/react/24/outline";
import { Button } from "./Button";
import Model from "./Model";
import { Input } from "./Input";
import MasterTitle from "./MasterTitle";
import Records from "./Records";
import { SortData } from "./SortData";
import { PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast } from "./toast";
import { Typography } from "@mui/material";
import ActionPopup from "./ActionPopup";
import NoDataFound from "./NoDataFound";
import CircularProgress from "@mui/material/CircularProgress";
import DeletePopup from "./DeletePopup";
import ActionButton from "./ActionButton";
import { Checkbox } from "./Checkbox";

const QuestionType = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Master")?.[0];
  const [isModel, setIsModel] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const cancelButtonRef = useRef(null);
  const [modelType, setModelType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [QuestionType, setQuestionType] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [search, setSearch] = useState("");
  const [isModelLoader, setisModelLoader] = useState(false);
  const [noticeData, setNoticeData] = useState([]);
  const [question, setQuestion] = useState({
    questionType: "",
    position: "",
    worksheetSpace: "",
    boardQtype: [],
  });
  const [error, setError] = useState({});
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.min(
    Math.ceil(dataCount / recordsPerPage),
    Number.MAX_SAFE_INTEGER
  );
  const numbers = Array.from({ length: npage }, (_, index) => index + 1);
  const [isLoader, setIsLoader] = useState(false);
  const [SelectQuestionTypeId, setSelectQuestionTypeId] = useState();
  const [checkedItems, setCheckedItems] = useState([]);
  const [deleteType, setDeleteType] = useState("single");
  const [isLoading, setIsLoading] = useState(false);
  const [boardData, setBoardData] = useState([]);
  const [titleData, setTitleData] = useState([
    {
      title: "SR No.",
      sort: 1,
      fieldName: "sr No.",
    },
    {
      title: "QUESTION TYPE",
      sort: 1,
      fieldName: "question type",
    },
    {
      title: "POSITION",
      sort: 1,
      fieldName: "position",
    },
    {
      title: "WORKSHEET SPACE",
      sort: 1,
      fieldName: "worksheet space",
    },
    {
      title: access?.edit ? "EDIT" : "",
      sort: 1,
      fieldName: "edit",
    },
    {
      title: access?.delete ? "DELETE" : "",
      sort: 1,
      fieldName: "delete",
    },
  ]);

  const getQuestionType = async (search, limit, offset) => {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/qtype?offset=${offset}&limit=${limit}&search=${search || ""}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setQuestionType(req?.data);
        setDataCount(req?.totalCount);
        setIsLoader(false);
      } else ErrorToast(req?.message);
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const handleQuestionType = (name, value) => {
    setQuestion((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
  };

  const getAllBoards = async () => {
    try {
      const { data } = await Api.getRequest(`api/board/getAllWithMedium`);
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setBoardData(req?.data);
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    } finally {
    }
  };

  useEffect(() => {
    getAllBoards();
  }, []);

  const generatedBoardNotices = useMemo(() => {
    if (!boardData || boardData.length === 0) return [];

    return boardData.flatMap((board) =>
      board.medium_info.map((medium) => ({
        board_id: board.id,
        qtype: "",
        medium_id: medium.id,
      }))
    );
  }, [boardData]);

  useEffect(() => {
    setQuestion((prevGenerateNotice) => ({
      ...prevGenerateNotice,
      boardQtype: generatedBoardNotices,
    }));
  }, [generatedBoardNotices]);

  const handleBoardChange = (boardId, mediumId, value) => {
    // if (!question.boardQtype || !Array.isArray(question.boardQtype)) {
    //   setQuestion((prevState) => ({
    //     ...prevState,
    //     boardQtype: [],
    //   }));
    //   return; 
    // }

    const updatedBoardNotices = [...question?.boardQtype];
    const index = updatedBoardNotices.findIndex(
      (boardNotice) =>
        boardNotice.board_id === boardId && boardNotice.medium_id === mediumId
    );
    if (index !== -1) {
      updatedBoardNotices[index] = {
        ...updatedBoardNotices[index],
        qtype: value,
      };
    } else {
      updatedBoardNotices.push({
        qtype: value,
        board_id: boardId,
        medium_id: mediumId,
      });
    }

    // Update the generateNotice state with the updated board notices
    setQuestion((prevState) => ({
      ...prevState,
      boardQtype: updatedBoardNotices,
    }));
  };

  const validation = () => {
    let errors = {};
    let formIsValid = true;
    if (!question?.questionType) {
      formIsValid = false;
      errors["questionType"] = "Please select questionType.";
    }
    if (!question?.position) {
      formIsValid = false;
      errors["position"] = "Please select position.";
    }
    if (!question?.worksheetSpace) {
      formIsValid = false;
      errors["worksheetSpace"] = "Please select worksheet space.";
    }

    question?.boardQtype.forEach((boardNotice, index) => {

      const boardNoticeKey = `boardQtype[${boardNotice?.medium_id}].qtype`;

      if (!boardNotice?.qtype && boardNotice?.medium_id) {
        formIsValid = false;
        errors[boardNoticeKey] = `This Question Type is required.`;
      }
    });
    setError(errors);
    return formIsValid;
  };

  const _getById = async (id) => {
    try {
      setisModelLoader(true);
      if (id) {
        const { data } = await Api.getRequest(
          `api/qtype/getQTypeByID/${id || ""}`
        );
        const req = JSON.parse(data);
        if (req?.status === 200) {
          setisModelLoader(false);
          setQuestion({
            questionType: req?.data?.name || "",
            position: req?.data?.position || "",
            worksheetSpace: req?.data?.worksheet_space || "",
            boardQtype: req?.data?.boardQtype || "",
          });
        } else {
          ErrorToast(req.message);
        }
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const handleAddModelSubmit = async () => {
    try {
      if (validation()) {
        setIsLoading(true);
        if (modelType === "edit") {
          try {
            let body = {
              name: question?.questionType,
              position: question?.position,
              worksheet_space: question?.worksheetSpace,
              boardQtype: question?.boardQtype,
            };
            const data = await Api.putRequest(
              `api/qtype/update/${selectedId}`,
              body
            );

            if (data?.data?.status === 200) {
              SuccessToast(data?.data?.message);
              getQuestionType(search, recordsPerPage, currentPage);
              setQuestion({
                questionType: "",
                position: "",
                worksheetSpace: "",
              });
              setTimeout(() => {
                setIsLoading(false);
              }, 2000);
              setIsModel(false);
              setSelectedId("");
            } else {
              ErrorToast(data?.data?.message);
            }
          } catch (error) {
            ErrorToast(error);
            setIsLoading(false);
          } finally {
            setIsLoading(false);
          }
        } else {
          try {
            let body = {
              name: question?.questionType,
              position: question?.position,
              worksheet_space: question?.worksheetSpace,
              boardQtype: question?.boardQtype,
            };
            const { data } = await Api.postRequest("api/qtype/create", body);
            const req = JSON.parse(data);
            if (req?.status === 200) {
              SuccessToast(req?.message);
              getQuestionType(search, recordsPerPage, currentPage);
              setQuestion({
                questionType: "",
                position: "",
                worksheetSpace: "",
              });
              setTimeout(() => {
                setIsLoading(false);
              }, 2000);
              // setIsModel(false);
              setSelectedId("");
            } else {
              ErrorToast(req?.message);
            }
          } catch (error) {
            ErrorToast(error);
            setIsLoading(false);
          } finally {
            setIsLoading(false);
          }
        }
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const _deleteQuestionType = async (id) => {
    try {
      let data;
      if (deleteType === "multi") {
        const body = {
          ids: checkedItems,
        };
        const response = await Api.postRequest(`api/qtype/delete`, body);
        data = response.data;
      } else {
        const body = {
          ids: [SelectQuestionTypeId],
        };
        const response = await Api.postRequest(`api/qtype/delete`, body);
        data = response.data;
      }
      //  const { data } = await Api.postRequest(
      //         `api/qtype/delete/${SelectQuestionTypeId}`
      //       );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        SuccessToast(req?.message);
        getQuestionType(search, recordsPerPage, currentPage);
        setOpenModel(false);
        setSelectQuestionTypeId("");
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) => {
      const isChecked = prevState.includes(id);

      const updatedItems = isChecked
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id];

      return updatedItems;
    });
  };

  useEffect(() => {
    if (selectedId) {
      _getById(selectedId);
    }
  }, [selectedId]);

  useEffect(() => {
    getQuestionType(search, recordsPerPage, currentPage);
  }, [ recordsPerPage, currentPage]);

  const applyFilter = () => {
    getQuestionType(search, recordsPerPage, currentPage);
  };

  return (
    <div>
      <div className="flex justify-between items-center mt-4">
        <div>
          <MasterTitle
            title="Question Type"
            description="Below are the list of all the question type."
          />
        </div>
        <div>
          {" "}
          <div style={{ display: "flex", gap: "5px" }}>
            {access?.delete && (
              <Button
                id=""
                type="submit"
                icon={TrashIcon}
                label={"DELETE QUESTION TYPE"}
                onClick={() => {
                  setDeleteType("multi");
                  if (checkedItems.length > 0) {
                    setOpenModel(true);
                  }
                }}
              />
            )}
            {access?.add && (
              <Button
                id=""
                type="submit"
                icon={PlusIcon}
                label={"ADD QUESTION TYPE"}
                onClick={() => {
                  setIsModel(true);
                  setModelType("add");
                }}
              />
            )}
          </div>
          <Model
            selectedId={selectedId}
            title={
              modelType === "add" ? "Add Question Type" : "Edit Question Type"
            }
            show={isModel}
            onCancel={() => {
              // setQuestion({});
              setIsModel(false);
            }}
            onClose={() => {
              setIsModel(false);
              setQuestion({
                questionType: "",
                position: "",
                worksheetSpace: "",
                boardQtype: [],
              });
              setSelectedId("");
            }}
            onOk={() => handleAddModelSubmit()}
            // onOk={() => {
            //   modelType === "add"
            //     ? handleAddModelSubmit()
            //     : handleEditModelSubmit();
            // }}
            okText="SUBMIT"
            cancelText="CLOSE"
            isLoading={isLoading}
            disabled={isLoading}
          >
            {isModelLoader ? (
              <tr
                style={{
                  height: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <td>
                  <CircularProgress />
                </td>
              </tr>
            ) : (
              <>
                <Input
                  label="Question Type"
                  id=""
                  name="Question Type"
                  type="text"
                  autoComplete="given-name"
                  required
                  className="w-full mb-3"
                  value={question.questionType || ""}
                  onChange={(e) =>
                    handleQuestionType("questionType", e.target.value)
                  }
                />
                <Typography variant="caption" color={"error"}>
                  {!question?.questionType ? error?.questionType : ""}
                </Typography>
                <Input
                  label="Position"
                  id="firstname"
                  name="firstname"
                  type="text"
                  autoComplete="given-name"
                  required
                  className="w-full mb-3"
                  value={question.position || ""}
                  onChange={(e) =>
                    handleQuestionType("position", e.target.value)
                  }
                />
                <Typography variant="caption" color={"error"}>
                  {!question?.position ? error?.position : ""}
                </Typography>
                <Input
                  label="Worksheet Space"
                  id="firstname"
                  name="firstname"
                  type="text"
                  autoComplete="given-name"
                  required
                  className="w-full mb-3"
                  value={question.worksheetSpace || ""}
                  onChange={(e) =>
                    handleQuestionType("worksheetSpace", e.target.value)
                  }
                />
                <Typography variant="caption" color={"error"}>
                  {!question?.worksheetSpace ? error?.worksheetSpace : ""}
                </Typography>
                <div className="mt-4">
                  {/* <div className="flex flex-wrap justify-between mb-4  w-[98%]"> */}
                  {boardData &&
                    boardData?.length > 0 &&
                    boardData?.map((board, index) => (
                      <div key={board?.id} className="w-full mb-4 sm:mb-0 mt-4">
                        <label
                          htmlFor={index}
                          className="mb-3 block text-sm font-medium text-[#252F40] font-poppins"
                        >
                          {board?.board_name}
                          <span className="text-red-500"> *</span>
                        </label>

                        {board.medium_info &&
                          board.medium_info.length > 0 &&
                          board.medium_info.map((medium, mediumIndex) => (
                            <div
                              key={medium?.id}
                              className="mb-4"
                              style={{
                                gap: 3,
                                // maxWidth: "340px"
                              }}
                            >
                              <div className="relative">
                                <input
                                  name={medium.name}
                                  type="text"
                                  className="block w-full appearance-none rounded-md border border-gray-200 px-3 py-2 text-gray-900 placeholder-[#A0AEC0] focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                                  value={
                                    Array.isArray(question?.boardQtype) // Check if boardQtype is an array
                                      ? question.boardQtype.find(
                                          (qtype) =>
                                            qtype.board_id === board.id &&
                                            qtype.medium_id === medium?.id
                                        )?.qtype || ""
                                      : "" // Provide a default value if question.boardQtype is not an array
                                  }
                                  placeholder={`${medium.name} Question Type here...`}
                                  onChange={(e) =>
                                    handleBoardChange(
                                      board?.id,
                                      medium?.id,
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <Typography variant="caption" color={"error"}>
                                {error?.[`boardQtype[${medium.id}].qtype`] ||
                                  ""}
                              </Typography>
                            </div>
                          ))}
                      </div>
                    ))}
                  {/* </div> */}
                </div>
              </>
            )}
          </Model>
        </div>
      </div>
      <div className="sm:mb-0 overflow-hidden">
        <div className="flex flex-col mt-4">
          <section
            className="bg-white rounded-2xl flex flex-col shadow-sm "
            style={{
              width: "100%",
              height: "75vh",
              margin: "0 auto",
              padding: "20px",
            }}
          >
            {" "}
            <div className="flex justify-between items-center mt-6 px-8 mb-2">
              <div style={{ display: "flex", gap:'5px' }}>
              <Input
                id=""
                name="Search"
                type="text"
                placeholder="Search here..."
                autoComplete="given-name"
                required
                className="w-72"
                onChange={(e) => {
                  setSearch(e?.target?.value);
                  setCurrentPage(1);
                }}
                value={search}
              />
               <Button
                  onClick={() => applyFilter()}
                  id=""
                  type="button"
                  // label={"SEARCH"}
                  searchicon={true}
                />
              </div>
              <Records
                recordsPerPage={recordsPerPage}
                setRecordsPerPage={setRecordsPerPage}
                setCurrentPage={setCurrentPage}
                dataCount={dataCount}
              />
            </div>
            <div
              style={{
                overflowY: "auto",
                maxHeight: "58vh",
                height: "60vh",
                width: "100%",
              }}
            >
              {" "}
              {!isLoader && QuestionType?.length <= 0 ? (
                <NoDataFound />
              ) : (
                <table style={{ width: "100%" }}>
                  <thead
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#f4f7f8",
                      zIndex: 1,
                    }}
                  >
                    <tr
                      style={{
                        borderBottom: "1px solid #dddddd",
                        textAlign: "left",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#7B809A",
                        textTransform: "uppercase",
                        backgroundColor: "#f4f7f8",
                      }}
                    >
                      {" "}
                      <th
                        scope="col"
                        style={{ width: "3%", padding: "10px" }}
                      ></th>
                      {titleData
                        .filter((v) =>
                          [
                            "sr No.",
                            "question type",
                            "position",
                            "worksheet space",
                          ].includes(v.fieldName)
                        )
                        .map((v, ind) => (
                          <th
                            key={ind}
                            className="py-4 p-8 "
                            style={{
                              padding: "12px",
                              backgroundColor: "#f4f7f8",
                              position:
                                v.title === "EDIT" || v.title === "DELETE"
                                  ? "sticky"
                                  : "",
                              right:
                                v.title === "EDIT"
                                  ? "68px"
                                  : v.title === "DELETE"
                                  ? "0"
                                  : "",
                            }}
                          >
                            <div className="flex justify-between">
                              <span>{v.title}</span>
                              {v.title !== "EDIT" && v.title !== "DELETE" && (
                                <span>
                                  <ChevronUpDownIcon
                                    onClick={() => {
                                      let res = SortData(
                                        QuestionType,
                                        v.fieldName,
                                        v.sort
                                      );
                                      let copyData = [...titleData];
                                      copyData[ind].sort = v.sort > 0 ? -1 : 1;
                                      setTitleData(copyData);
                                      setQuestionType(res);
                                    }}
                                    className="w-5 h-5"
                                  />
                                </span>
                              )}
                            </div>
                          </th>
                        ))}
                      {/* {titleData?.map(
                        (v, ind) =>
                          v.title && (
                            <th
                              key={ind}
                              className="py-4 p-8 "
                              style={{
                                padding: "12px",
                                backgroundColor: "#f4f7f8",
                                position:
                                  v.title === "EDIT" || v.title === "DELETE"
                                    ? "sticky"
                                    : "",
                                right:
                                  v.title === "EDIT"
                                    ? "68px"
                                    : v.title === "DELETE"
                                    ? "0"
                                    : "",
                              }}
                            >
                              <div className="flex justify-between">
                                <span>{v.title}</span>
                                {v.title !== "EDIT" && v.title !== "DELETE" && (
                                  <span>
                                    <ChevronUpDownIcon
                                      onClick={() => {
                                        let res = SortData(
                                          QuestionType,
                                          v.fieldName,
                                          v.sort
                                        );
                                        let copyData = [...titleData];
                                        copyData[ind].sort =
                                          v.sort > 0 ? -1 : 1;
                                        setTitleData(copyData);
                                        setQuestionType(res);
                                      }}
                                      className="w-5 h-5"
                                    />
                                  </span>
                                )}
                              </div>
                            </th>
                          )
                      )} */}
                      {boardData?.map((board, index) => (
                        <th key={index} className="py-4 px-6">
                          <div className="flex justify-between">
                            <span>{board.board_name}</span>
                            <span className="cursor-pointer">
                              <ChevronUpDownIcon
                                onClick={() => {
                                  setBoardData((prevBoardData) => {
                                    const updatedBoardData = [...prevBoardData];
                                    const sortDirection =
                                      board.sort > 0 ? -1 : 1;

                                    updatedBoardData[index].sort =
                                      -sortDirection;

                                    const res = SortData(
                                      noticeData,
                                      `${board.board_name}`,
                                      updatedBoardData[index].sort
                                    );

                                    setNoticeData(res);
                                    return updatedBoardData;
                                  });
                                }}
                                className="w-5 h-5"
                              />
                            </span>
                          </div>
                        </th>
                      ))}
                      {titleData
                        .filter((v) => ["edit", "delete"].includes(v.fieldName))
                        .map(
                          (v, ind) =>
                            v.title && (
                              <th
                                key={ind}
                                className="py-2 px-6"
                                style={{
                                  backgroundColor: "#f4f7f8",
                                  position:
                                    v.title === "EDIT" || v.title === "DELETE"
                                      ? "sticky"
                                      : "",
                                  right:
                                    v.title === "EDIT"
                                      ? "68px"
                                      : v.title === "DELETE"
                                      ? "0"
                                      : "",
                                }}
                              >
                                <div className="flex justify-between">
                                  <span>{v.title}</span>
                                </div>
                              </th>
                            )
                        )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {isLoader ? (
                      <tr style={{ height: "25rem" }}>
                        <td colSpan={5} style={{ textAlign: "center" }}>
                          <CircularProgress />
                        </td>
                      </tr>
                    ) : (
                      QuestionType?.map((data, id) => (
                        <tr
                          key={id}
                          className="my-4 p-8 text-[#000] font-reguler text-sm font-medium"
                        >
                          <td className="px-6 py-3 whitespace-no-wrap">
                            <Checkbox
                              checked={checkedItems.includes(data.id)}
                              onChange={() => handleCheckboxChange(data.id)}
                            />
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-3 ">
                            {data.id}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-3 ">
                            {data.name}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-3 ">
                            {data.position}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-3 ">
                            {data.worksheet_space}
                          </td>
                          {boardData &&
                            boardData.map((board, boardIndex) => (
                              <td
                                key={boardIndex}
                                className=" lg:table-cell whitespace-nowrap px-6 py-2 "
                              >
                                {Array.isArray(data?.boardQtype) &&
                                  data.boardQtype.map((o, noticeIndex) =>
                                    o?.board_id === board?.id ? (
                                      <div key={noticeIndex}>
                                        {o?.qtype || "-"}
                                      </div>
                                    ) : null
                                  )}
                              </td>
                            ))}
                          {access?.edit && (
                            <td
                              className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                              style={{
                                position: "sticky",
                                right: "68px",
                                backgroundColor: "white",
                              }}
                            >
                              <ActionButton
                                key={data.key}
                                action="edit"
                                onClick={() => {
                                  setIsModel(true);
                                  setModelType("edit");
                                  setSelectedId(data.id);
                                }}
                              />
                            </td>
                          )}
                          {access?.delete && (
                            <td
                              className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                              style={{
                                position: "sticky",
                                right: "0",
                                backgroundColor: "white",
                              }}
                            >
                              <ActionButton
                                key={data.key}
                                action="delete"
                                onClick={() => {
                                  setOpenModel(true);
                                  setSelectQuestionTypeId(data?.id);
                                }}
                              />
                            </td>
                          )}
                          {/* {access?.edit && (
                          <td className="relative whitespace-nowrap  p-8 py-3  text-left">
                            <PencilIcon
                              onClick={() => {
                                setIsModel(true);
                                setModelType("edit");
                                setSelectedId(data.id);
                              }}
                              className="mr-3 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </td>
                        )} */}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              recordsPerPage={recordsPerPage}
              data={QuestionType}
              numbers={numbers}
              dataCount={dataCount}
              npage={npage}
              lastIndex={lastIndex}
              firstIndex={firstIndex}
            />
          </section>
        </div>

        {/* Delete */}
        <DeletePopup
          title={"Delete"}
          show={openModel}
          description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
          onCancel={() => {
            setOpenModel(false);
            setSelectQuestionTypeId("");
          }}
          onClose={() => {
            setOpenModel(false);
            setSelectQuestionTypeId("");
          }}
          onDelete={() => {
            _deleteQuestionType();
          }}
          cancelButtonRef={cancelButtonRef}
          okText="Delete"
          cancelText="Cancel"
        />
        {/* Delete */}
      </div>
    </div>
  );
};
export default QuestionType;
