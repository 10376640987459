import React, { useEffect, useState } from "react";
import { Api } from "../Api/Api";
import { ErrorToast } from "./toast";

const MiniPaperGeneration = () => {
  const PaperGenerationData = [
    {
      id: "1",
      standard: "1",
      semester: "Sem - 1",
      subject: "Gujarati",
      marks: "25",
    },
    {
      id: "2",
      standard: "8",
      semester: "Sem - 1",
      subject: "Maths",
      marks: "25",
    },
    {
      id: "3",
      standard: "6",
      semester: "Sem - 1",
      subject: "Gujarati",
      marks: "25",
    },
    {
      id: "4",
      standard: "1",
      semester: "Sem - 1",
      subject: "Science",
      marks: "25",
    },
  ];

  const [historyData, setHistoryData] = useState([]);

  const _getHistory = async () => {
    try {
      const { data } = await Api.getRequest(
        `api/paper-generation/paper-list?paper_type=&search=&limit=${4}&offset=${1}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setHistoryData(req?.data);
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };
  useEffect(() => {
    _getHistory();
  }, []);

  return (
    <div className="overflow-y-auto">
      <table className="w-full mt-3">
        <thead>
          <tr className="border-b-1 bg-[#F9FAFB] text-left text-xs font-bold text-[#252F40]">
            <th className="py-3 px-8 ">PAPER ID</th>
            <th className="py-3 lg:px-2 xl:px-8 ">PAPER TYPE</th>
            <th className="py-3 lg:px-2 xl:px-8 ">User Name</th>
            <th className="py-3 lg:px-2 xl:px-8 ">MOBILE</th>
            <th className="py-3 lg:px-2 xl:px-8 ">EXAM DATE</th>
            <th className="py-3 lg:px-2 xl:px-8 ">SCHOOL NAME</th>
            <th className="py-3 lg:px-2 xl:px-8 ">STANDARD NAME</th>
            {/* <th className="py-3 lg:px-2 xl:px-8 ">SUBJECT NAME</th> */}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {historyData.map((data, id) => (
            <tr
              key={id}
              className="my-4 px-3 text-[#7B809A] font-reguler text-xs"
            >
              <td className="lg:table-cell whitespace-nowrap py-2.5 px-8">
                {data.id}
              </td>
              <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                {data.paper_type}
              </td>
              <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                {data.user?.userInfo?.first_name}
              </td>
              <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                {data.user?.mobile}
              </td>
              <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                {data.exam_date}
              </td>
              <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                {data.school_name}
              </td>
              <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                {data.standard_name}
              </td>
              {/* <td className="hidden lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                {data.subject_name}
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MiniPaperGeneration;
