import { getDecryptedDataFromLocalStorage } from "./encryption";

export const token_key = "user";

export const setToken = (token) => {
  window.localStorage.setItem(token_key, JSON.stringify(token));
};
export const getToken = () => {
  let token = getDecryptedDataFromLocalStorage("token");
  if (!!token) return token;
  return false;
};

export const isLogin = () => {
  if (!!getToken()) {
    return true;
  }
  return false;
};

export const logout = () => {
  window.localStorage.clear();
};
