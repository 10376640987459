import React from "react";
import SchoolType from "./SchoolType";
import Medium from "./Medium";
import Standard from "./Standard";
import Subject from "./Subject";
import Chapter from "./Chapter";
import Teacher from "./Teacher";
import FileImage from "./FileImage";
import PaymentPopUp from "./PaymentPopUp";
import QuestionType from "./QuestionType";
import AdsYouth from "./AdsYouth";
import GenerateNotice from "./GenerateNotice";
import AdvertisementImages from "./LearningImages";
import GenerateBanners from "./GenerateBanners";
import Tabs from "./Tabs";
import ReferenceBook from "./ReferenceBook";
import Topic from "./Topic";
import Board from "./Board";
import Semester from "./Semester";
import AddSound from "./AddSound";
import GenerateQRCode from "./GenerateQRCode";

const Master = (props) => {
  let categories = [
    // {
    //   name: "School Type",
    //   component: <SchoolType access={props?.access} />,
    //   path: "/master/school-type",
    // },
    {
      name: "Board",
      component: <Board access={props?.access} />,
      path: "/master/board",
    },
    {
      name: "Medium",
      component: <Medium access={props?.access} />,
      path: "/master/medium",
    },
    {
      name: "Standard",
      component: <Standard access={props?.access} />,
      path: "/master/standard",
    },
    {
      name: "Semester",
      component: <Semester access={props?.access} />,
      path: "/master/semester",
    },
    {
      name: "Subject",
      component: <Subject access={props?.access} />,
      path: "/master/subject",
    },
    {
      name: "Chapter",
      component: <Chapter access={props?.access} />,
      path: "/master/chapter",
    },
    {
      name: "Teacher",
      component: <Teacher access={props?.access} />,
      path: "/master/teacher",
    },
    {
      name: "Reference Book",
      component: <ReferenceBook access={props?.access} />,
      path: "/master/reference-book",
    },
    {
      name: "Question Type",
      component: <QuestionType access={props?.access} />,
      path: "/master/question-type",
    },
    {
      name: "Random Generate Notice",
      component: <GenerateNotice access={props?.access} />,
      path: "/master/random-generate-notice",
    },
    {
      name: "Add Sound",
      component: <AddSound access={props?.access} />,
      path: "/master/add-sound",
    },
    {
      name: "Generate QR Code",
      component: <GenerateQRCode access={props?.access} />,
      path: "/master/generate-qr-code",
    },
    // {
    //   name: "File Image",
    //   component: <FileImage access={props?.access}/>,
    //   path: "/master/file-image",
    // },
    // {
    //   name: "Payment pop-Up info",
    //   component: <PaymentPopUp access={props?.access}/>,
    //   path: "/master/payment-pop-up-info",
    // },
    // {
    //   name: "Advertisement Images",
    //   component: <AdvertisementImages access={props?.access} />,
    //   path: "/master/advertisement-images",
    // },
    // {
    //   name: "3rd Party Ads Youth",
    //   component: <AdsYouth access={props?.access}/>,
    //   path: "/master/3rd-party-ads-youth",
    // },
  ];
  return <Tabs categories={categories} title="Master" />;
};

export default Master;
