import React, { useRef, useState, useEffect } from "react";
import { Button } from "./Button";
import { ErrorToast, SuccessToast } from "./toast";
import { Api } from "../Api/Api";
import { CKEditor } from "ckeditor4-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { editorUrl } from "./constants/CkeditorUrl";

const BatchBenifits = () => {
  const [error, setError] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [batchdesciption, setBatchdesciption] = useState({
    description: "",
  });
  const editorRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Api.getRequest("api/purchaseplan/");

        const purchaseplan = JSON.parse(response?.data);
        console.log("purchaseplan---", purchaseplan);
        if (purchaseplan?.status === 200) {
          if (purchaseplan.data?.url) {
            setDataLoaded(true);
            setSelectedFile(purchaseplan.data?.url);
          }
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        ErrorToast("Error fetching data");
      }
    };
    if (!dataLoaded) {
      fetchData();
    }
  }, [dataLoaded]);

  const handleDescription = (e, name) => {
    const value = e?.editor?.getData();
    console.log(e, "FFFFFFFFFFFFFvlaueer");
    setBatchdesciption((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));

    console.log("input-==--=-=-=-=-=", value);
  };

  const handleSubmit = async () => {
    try {
      let body = {
        message: batchdesciption.description,
      };
      const response = await Api.postRequest(
        "api/registration-message/create",
        body
      );

      console.log("response-=-==-=-=-=-=-=-", response);

      if (response.statusCode === 200) {
        SuccessToast("Message Added Successfully");
      } else {
        ErrorToast("Something went wrong");
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const handleGetApi = async () => {
    try {
      const response = await Api.getRequest("api/registration-message");
      console.log("response-=-==-=-=-=-=-=-12121", response);
      const data = JSON.parse(response.data);
      if (response.statusCode === 200) {
        const datas = data.data.message;
        console.log("response-=-==-=-00000", datas);
        editorRef.current?.editor?.setData("");
        setBatchdesciption({ description: datas });
      } else {
        ErrorToast("Something went wrong");
        console.log("response-=-==-=-=-=-=-=-ELSE", response);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };
  useEffect(() => {
    handleGetApi();
  }, []);
  return (
    <div className="w-full sm:px-6">
      <div>
        <div className="sm:mb-0 overflow-hidden ">
          <div className="flex flex-col mt-4">
            <section className="bg-white rounded-2xl flex flex-col shadow-sm min-h-[calc(100vh-40px-16px-24px-38px-50px-40px)]">
              <div className="px-8">
                <div className="mt-10 mb-3">
                  <h1 className="text-2xl font-bold">
                    Add Description of Batch Benifits
                  </h1>
                </div>

                <div>
                  <div className="w-full space-y-6 h-auto p-4">
                    <div className="mb-3">
                    

                      <div className="rounded-md w-full border border-gray-200 p-3 gap-4">
                        {console.log(
                          "batchdesciption",
                          batchdesciption?.description
                        )}
                        <CKEditor
                          ref={editorRef}
                          editorUrl= {editorUrl}
                          editor={ClassicEditor}
                     
                          onInstanceReady={(instance) => {
                            // Save editor instance to ref
                            editorRef.current = instance;
                          }}
                          value={batchdesciption?.description}
                          onChange={(e) =>
                      
                            handleDescription(e, "description")
                          }
                          config={{
                            extraPlugins: [
                              "mathjax",
                              "uploadimage",
                              "sourcearea",
                              // "filebrowser",
                            ],
                            removeDialogTabs: "image:advanced;link:advanced",
                            mathJaxLib:
                              "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-AMS_HTML",
                            autoParagraph: false,
                            filebrowserImageBrowseUrl: "/imageupload",
                            versionCheck: false,
                          }}
                        />
                      </div>

                    
                    </div>
                    {error.upload && (
                      <div className="text-red-500 text-sm">{error.upload}</div>
                    )}
                    <div className="flex items-center">
                      <Button
                        label={isLoading ? "Uploading..." : "Submit"}
                        type="button"
                        onClick={handleSubmit}
                      />
                    </div>
                    <p className="text-b text-black-500 ">Previous Data :</p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: batchdesciption?.description,
                      }}
                      className="border border-gray-200 p-3 rounded-2xl"
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatchBenifits;
