import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Button } from "./Button";

export default function Model({
  width = "sm:max-w-lg",
  show = false,
  onClose = () => {},
  onCancel = () => {},
  onOk = () => {},
  okText = "Submit",
  cancelText = "Cancel",
  title = "",
  isLoading,
  children = <></>,
}) {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative bg-white rounded-lg px-4 pb-4 text-left shadow-xl transform transition-all w-full ${width} sm:p-6`}
              >
                <div className="flex items-center justify-between">
                  <div className=" text-left ">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-semibold leading-6 text-black mb-3 py-3 font-poppins"
                    >
                      {title}
                    </Dialog.Title>
                  </div>
                  <div className="">
                    <XMarkIcon
                      className="w-6 h-6 cursor-pointer"
                      onClick={onClose}
                    />
                  </div>
                </div>
                {children}
                <div className="mt-5 sm:mt-6 mb-3 flex flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6 ">
                  <div className="w-full">
                    {okText && (
                      <>
                        {isLoading ? ( // Display loader if isLoading is true
                          <Button
                            id=""
                            type="button"
                            label={"Loading..."}
                            abc="w-full"
                            //  onClick={onOk}
                            //  disabled={isLoading}
                          >
                            Loading...
                          </Button>
                        ) : (
                          // Display submit button if isLoading is false
                          <Button
                            id=""
                            type="button"
                            label={"SUBMIT"}
                            abc="w-full"
                            onClick={onOk}
                            disabled={isLoading}
                          >
                            {okText}
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                  <div className="w-full">
                    {cancelText && (
                      <Button
                        isBordered
                        id=""
                        type="button"
                        label={"CLOSE"}
                        abc="w-full"
                        onClick={onCancel}
                      >
                        {cancelText}
                      </Button>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
