import { CircularProgress } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Api } from "../Api/Api";
import { ErrorToast } from "./toast";

const MiniOrderHistory = () => {
 
  const [titleData, setTitleData] = useState([
    {
      title: "SR.No",
      sort: 1,
      fieldName: "sr no",
    },
    {
      title: "NAME",
      sort: 1,
      fieldName: "name",
    },
    {
      title: "SUBSCRIPTION PRICE ID",
      sort: 1,
      fieldName: "order id",
    },
    {
      title: "STANDARD",
      sort: 1,
      fieldName: "standard",
    },
    {
      title: "SEMESTER",
      sort: 1,
      fieldName: "semester",
    },
    {
      title: "SUBJECT",
      sort: 1,
      fieldName: "subject",
    },
    {
      title: "AMOUNT",
      sort: 1,
      fieldName: "amount",
    },

    {
      title: "O.STATUS",
      sort: 1,
      fieldName: "o status",
    },
    {
      title: "PURCHASE AT",
      sort: 1,
      fieldName: "purchase",
    },

    {
      title: "L.UPDATE AT",
      sort: 1,
      fieldName: "update",
    },
    {
      title: "EXPIRED AT",
      sort: 1,
      fieldName: "exoired",
    },
  ]);

  const [OrderHistoryData, setOrderHistoryData] = useState([]);

  const getOrderHistoryData = async () => {
    try {
      const { data } = await Api.getRequest(
        `api/subscription/getSubjectPurchaseOrderHistory?offset=${1}&limit=${4}`
      );
      const req = JSON.parse(data);
      if (req?.success === true) {
        setOrderHistoryData(req?.data);
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    getOrderHistoryData();
  }, []);

  return (
    <div className="overflow-y-auto">
      <table className="w-full mt-3">
        <thead>
          <tr className="border-b-1 bg-[#F9FAFB] text-left text-xs font-bold text-[#252F40]">
            {titleData.map(
              (v, ind) =>
                v?.title && (
                  <th key={ind} className="py-4 px-8 ">
                    <div className="flex justify-between">
                      <span>{v.title}</span>
                    </div>
                  </th>
                )
            )}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {OrderHistoryData.map((data, id) => (
            <tr
              key={id}
              className="my-4 px-8 text-[#7B809A] font-reguler text-sm align-top"
            >
              <td className="lg:table-cell whitespace-nowrap px-8  py-2.5 ">
                {data.id}
              </td>
              <td className="lg:table-cell whitespace-nowrap px-8 py-2.5">
                {data.userInfo?.username}
              </td>
              <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                {data.subscriptionPriceId}
              </td>
              <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                {data.SubscriptionPriceInfo?.StandardInfo?.name}
              </td>
              <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                {data.SubscriptionPriceInfo?.SemesterInfo?.semester}
              </td>
              <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                {data.SubscriptionPriceInfo?.SubjectInfo?.subject}
              </td>
              <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                {data.SubscriptionPriceInfo?.price}
              </td>
              <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                {data.paymentStatus}
              </td>

              <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                {data.createdAt !== null
                  ? moment(data.createdAt).format("D MMMM, YYYY | hh:mm A")
                  : "-"}
              </td>

              <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                {data.updatedAt !== null
                  ? moment(data.updatedAt).format("D MMMM, YYYY | hh:mm A")
                  : "-"}
              </td>
              <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                {data.expiryDate !== null ? data.expiryDate : "-"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MiniOrderHistory;
