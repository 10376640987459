import Pagination from "./Pagination";
import { useEffect, useRef, useState } from "react";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import Model from "./Model";
import { Input } from "./Input";
import MasterTitle from "./MasterTitle";
import { SortData } from "./SortData";
import Records from "./Records";
import FloatNotification from "./FloatNotification";
import { PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import { Button } from "./Button";
import DeletePopup from "./DeletePopup";
import ActionPopup from "./ActionPopup";
import { Api } from "../Api/Api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ErrorToast, SuccessToast } from "./toast";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@mui/material";
import Select from "./Select";
import NoDataFound from "./NoDataFound";
import CircularProgress from "@mui/material/CircularProgress";
import ActionButton from "./ActionButton";
import { Checkbox } from "./Checkbox";

const Board = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Master")?.[0];
  const [isModel, setIsModel] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const cancelButtonRef = useRef(null);
  const [modelType, setModelType] = useState("");
  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [dataCount, setDataCount] = useState(0);
  const [boardName, setBoardName] = useState({
    boardName: "",
    position: "",
    status: 0
    // mediumName: "",
  });
  const [error, setError] = useState({});
  const [boardId, setBoardId] = useState(null);
  const [BoardData, setBoardData] = useState([]);
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(dataCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [schoolType, setSchoolType] = useState("");
  const [position, setPosition] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [isModelLoader, setisModelLoader] = useState(false);
  const [SelectBoardId, setSelectBoardId] = useState();
  const [checkedItems, setCheckedItems] = useState([]);
  const [deleteType, setDeleteType] = useState("single");
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [standardQuestion, setStandardQuestion] = useState({
    status: 0,
  });

  console.log('standardQuestion')
  const [titleData, setTitleData] = useState([
    {
      title: "BOARD",
      sort: 1,
      fieldName: "board",
    },
    {
      title: "POSITION",
      sort: 1,
      fieldName: "position",
    },
    {
      title: "BATCH REGISTRATION",
      sort: 1,
      fieldName: "batch_registration",
    },
    {
      title: access?.edit ? "EDIT" : "",
      sort: 1,
      fieldName: "",
    },
    {
      title: access?.delete ? "DELETE" : "",
      sort: 1,
      fieldName: "",
    },
  ]);

  const validation = () => {
    let errors = {};
    let formIsValid = true;
    if (!boardName?.boardName) {
      formIsValid = false;
      errors["boardName"] = "Please Enter BoardName.";
    }
    if (!boardName?.position) {
      formIsValid = false;
      errors["position"] = "Please Enter position.";
    }
    // if (!boardName?.mediumName) {
    //   formIsValid = false;
    //   errors["mediumName"] = "Please Enter mediumName.";
    // }

    setError(errors);
    return formIsValid;
  };

  const addorEditBoard = async () => {
    let body = {
      board_name: boardName.boardName,
      position: boardName.position,
      isregistration: +boardName.status
      // medium: boardName?.mediumName?.id,
    };
    if (validation()) {
      setIsLoading(true);
      if (modelType === "add") {
        try {
          const { data } = await Api.postRequest(`api/board/create`, body);
          const req = JSON.parse(data);
          if (req?.status === 200) {
            SuccessToast(req?.message);
            getBoardData(search, recordsPerPage, currentPage);
            setBoardName({
              boardName: "",
              position: "",
              status: 0
            });
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
            setIsModel(true);
          } else {
            ErrorToast(req?.message); //req?.message
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
          }
        } catch (error) {
          ErrorToast(error?.message);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      } else {
        try {
          const { data } = await Api.putRequest(
            `api/board/update/${boardId}`,
            body
          );
          if (data?.status === 200) {
            SuccessToast(data?.message);
            resetBoard();
            getBoardData(search, recordsPerPage, currentPage);
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
          } else {
            ErrorToast(data?.message);
          }
        } catch (error) {
          ErrorToast(error?.message);
          setIsLoading(false);
        } finally {
          setBoardId(null);
          setIsLoading(false);
        }
      }

      // resetBoard();
    }
  };

  const handleBoard = (name, value) => {
    setBoardName((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
  };

  const resetBoard = () => {
    setBoardName({
      boardName: "",
      position: "",
      status: 0
    });
    setError({});
    setIsModel(false);
    setBoardId(null);
  };

  useEffect(() => {
    getBoardData(search, recordsPerPage, currentPage);
  }, [ recordsPerPage, currentPage]);
  
  const applyFilter = () =>{
    getBoardData(search, recordsPerPage, currentPage)
  }
  useEffect(() => {
    if (modelType === "edit") {
      const getSingleBoard = async () => {
        setisModelLoader(true);
        try {
          const { data } = await Api.getRequest(
            `api/board/getBoardbyID/${boardId}`
          );
          const req = JSON.parse(data);
          if (req?.status === 200) {
            setBoardName({
              boardName: req?.data?.board_name,
              position: req?.data?.position,
              status: req?.data?.isregistration
            });
          } else {
            ErrorToast(req?.message);
          }
        } catch (error) {
          ErrorToast(error?.message);
        } finally {
          setModelType("");
          setisModelLoader(false);
        }
      };

      getSingleBoard();
    }
  }, [modelType, boardId]);

  const getBoardData = async (search, limit, offset) => {
    try {
      setIsLoader(true);
      const { data } =
        (await Api.getRequest(
          `api/board?offset=${offset}&limit=${limit}&search=${search || ""}`
        )) || {};
      if (data) {
        const req = JSON.parse(data);
        setBoardData(req?.data);
        setDataCount(req?.totalCount);
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("board is required"),
    position: Yup.number()
      .typeError("Position must be a number")
      .required("Position is required")
      .positive("Position must be a positive number")
      .integer("Position must be an integer"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      // medium: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        if (modelType === "add") {
          await Api.postRequest("api/board/create", values);
          SuccessToast("board Added Successfully");
        } else if (modelType === "edit") {
          await Api.putRequest(`api/board/update/${boardId}`, values);
          SuccessToast("board Updated Successfully");
        }
        // setIsModel(false);
        getBoardData(search, recordsPerPage, currentPage);
        formik.resetForm();
      } catch (error) {
        ErrorToast(error.message);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const _deleteBoard = async (id) => {
    try {
      let data;
      if (deleteType === "multi") {
        const body = {
          ids: checkedItems,
        };
        const response = await Api.postRequest(`api/board/delete`, body);
        data = response.data;
      } else {
        const body = {
          ids: [SelectBoardId],
        };
        const response = await Api.postRequest(`api/board/delete`, body);
        data = response.data;
      }
      const req = JSON.parse(data);
      if (req?.status === 200) {
        SuccessToast(req?.message);
        getBoardData(search, recordsPerPage, currentPage);
        setOpenModel(false);
        setSelectBoardId("");
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) => {
      const isChecked = prevState.includes(id);

      const updatedItems = isChecked
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id];

      return updatedItems;
    });
  };

  const handleQuestionType = (name, value) => {
    setBoardName((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
    if (name === "board") {
      setBoardName((oldValue) => ({
        ...oldValue,

        [name]: value,
      }));

    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mt-5">
        <div>
          <MasterTitle
            title="Board"
            description="Below are the list of all the board."
          />
        </div>
        <div>
          <div style={{ display: "flex", gap: "5px" }}>
            {access?.delete && (
              <Button
                id=""
                type="submit"
                icon={TrashIcon}
                label={"DELETE BOARD"}
                onClick={() => {
                  setDeleteType("multi");
                  if (checkedItems.length > 0) {
                    setOpenModel(true);
                  }
                }}
              />
            )}
            {access?.add && (
              <Button
                id=""
                type="submit"
                icon={PlusIcon}
                label={"ADD BOARD"}
                onClick={() => {
                  setIsModel(true);
                  setModelType("add");
                  formik.values.name = "";
                  // formik.values.medium = "";
                }}
              />
            )}
          </div>

          <Model
            title={modelType === "add" ? "Add Board" : "Edit Board"}
            show={isModel}
            onCancel={() => {
              // resetBoard();
              // setBoardName({
              //   boardName: "",
              //   // mediumName: "",
              // });
              setIsModel(false);
            }}
            onClose={() => {
              resetBoard();
            }}
            onOk={() => {
              addorEditBoard();
            }}
            okText="SUBMIT"
            cancelText="CLOSE"
            isLoading={isLoading}
            disabled={isLoading}
          >
            {isModelLoader ? (
              <tr
                style={{
                  height: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <td>
                  <CircularProgress />
                </td>
              </tr>
            ) : (
              <>
                <Input
                  label="Board Name"
                  type="text"
                  autoComplete="given-name"
                  name="boardName"
                  value={boardName?.boardName}
                  onChange={(e) => handleBoard("boardName", e.target.value)}
                  required
                />
                <Typography
                  cvariant="caption"
                  color={"error"}
                  style={{ fontSize: "12px", marginTop: "12px" }}
                >
                  {!boardName?.boardName ? error?.boardName : ""}
                </Typography>
                <Input
                  label="Position"
                  type="text"
                  autoComplete="given-name"
                  name="position"
                  value={boardName?.position}
                  onChange={(e) => handleBoard("position", e.target.value)}
                  required
                />
                <Typography
                  cvariant="caption"
                  color={"error"}
                  style={{ fontSize: "12px", marginTop: "12px" }}
                >
                  {!boardName?.position ? error?.position : ""}
                </Typography> <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                  <div className="w-full flex flex-col">
                    <FormControl>
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        style={{
                          fontFamily: "poppins",
                          color: "#252F40",
                          opacity: "1",
                        }}
                      >
                        Batch Registration*
                      </FormLabel>

                      <RadioGroup
                        row
                        required
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={(e) =>
                          handleQuestionType("status", e.target.value)
                        }
                        value={boardName.status}

                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Typography variant="caption" color={"error"}>
                      {!boardName?.status ? error?.status : ""}
                    </Typography>
                  </div>
                </div>

                <Typography
                  cvariant="caption"
                  color={"error"}
                  style={{ fontSize: "12px", marginTop: "12px" }}
                >
                  {!boardName?.position ? error?.position : ""}
                </Typography>
              </>
            )}
          </Model>
        </div>
      </div>
      <div className="sm:mb-0">
        <div className="flex flex-col mt-4">
          <section
            className="bg-white rounded-2xl flex flex-col shadow-sm "
            style={{
              width: "100%",
              height: "75vh",
              margin: "0 auto",
              padding: "20px",
            }}
          >
            {" "}
            <div className="flex justify-between items-center mt-6 px-8 mb-3">
              <div style={{ display: "flex", gap:'5px' }}>
              <Input
                id=""
                name="Search"
                type="text"
                placeholder="Search here..."
                autoComplete="given-name"
                required
                onChange={(e) => {
                  setSearch(e?.target?.value);
                  setCurrentPage(1);
                }}
                className="w-72"
                value={search}
              />
              <Button
                onClick={() => applyFilter()}
                id=""
                type="button"
                // label={"SEARCH"}
                searchicon={true}
              />
              </div>
              <Records
                recordsPerPage={recordsPerPage}
                setCurrentPage={setCurrentPage}
                setRecordsPerPage={setRecordsPerPage}
                dataCount={dataCount}
              />
            </div>
            <div
              style={{
                overflowY: "auto",
                maxHeight: "58vh",
                height: "60vh",
                width: "100%",
              }}
            >
              {" "}
              {!isLoader && BoardData?.length <= 0 ? (
                <NoDataFound />
              ) : (
                <table style={{ width: "100%" }}>
                  <thead
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#f4f7f8",
                      zIndex: 1,
                    }}
                  >
                    <tr
                      style={{
                        borderBottom: "1px solid #dddddd",
                        textAlign: "left",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#7B809A",
                        textTransform: "uppercase",
                        backgroundColor: "#f4f7f8",
                      }}
                    >
                      {" "}
                      <th
                        scope="col"
                        style={{ width: "3%", padding: "10px" }}
                      ></th>
                      {titleData.map(
                        (value, ind) =>
                          value.title && (
                            <th
                              key={ind}
                              // className="py-2 px-6"
                              style={{
                                padding: "12px",
                                backgroundColor: "#f4f7f8",
                                position:
                                  value.title === "EDIT" ||
                                    value.title === "DELETE"
                                    ? "sticky"
                                    : "",
                                right:
                                  value.title === "EDIT"
                                    ? "60px"
                                    : value.title === "DELETE"
                                      ? "0"
                                      : "",
                              }}
                            >
                              <div className="flex justify-between">
                                <span>{value.title}</span>
                                {value.title !== "EDIT" &&
                                  value.title !== "DELETE" && (
                                    <span>
                                      <ChevronUpDownIcon
                                        onClick={() => {
                                          let res = SortData(
                                            BoardData,
                                            value.fieldName,
                                            value.sort
                                          );

                                          let copyData = [...titleData];
                                          copyData[ind].sort =
                                            value.sort > 0 ? -1 : 1;
                                          setTitleData(copyData);
                                          setBoardData(res);
                                        }}
                                        className="w-5 h-5"
                                      />
                                    </span>
                                  )}
                              </div>
                            </th>
                          )
                      )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {isLoader ? (
                      <tr style={{ height: "25rem" }}>
                        <td colSpan={5} style={{ textAlign: "center" }}>
                          <CircularProgress />
                        </td>
                      </tr>
                    ) : (
                      BoardData.map((data) => (
                        <tr
                          key={data.key}
                          className="my-2 px-6 text-[#000] font-reguler  text-sm"
                        >
                          {" "}
                          <td className="px-6 py-3 whitespace-no-wrap">
                            <Checkbox
                              checked={checkedItems.includes(data.id)}
                              onChange={() => handleCheckboxChange(data.id)}
                            />
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                            {data?.board_name ?? "-"}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                            {data?.position ?? "-"}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                            {data?.isregistration ?? "-"}
                          </td>
                          {/* <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                            {data?.medium_info?.name ?? "-"}
                          </td> */}
                          {access?.edit && (
                            <td
                              className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                              style={{
                                position: "sticky",
                                right: "68px",
                                backgroundColor: "white",
                              }}
                            >
                              <ActionButton
                                key={data.key}
                                action="edit"
                                onClick={() => {
                                  setIsModel(true);
                                  setBoardId(data.id);
                                  setModelType("edit");
                                  formik.values.name = data?.name;
                                  // formik.values.medium = data?.medium;
                                }}
                              />
                            </td>
                          )}
                          {access?.delete && (
                            <td
                              className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                              style={{
                                position: "sticky",
                                right: "0",
                                backgroundColor: "white",
                              }}
                            >
                              <ActionButton
                                key={data.key}
                                action="delete"
                                onClick={() => {
                                  setDeleteType("single");
                                  setOpenModel(true);
                                  setSelectBoardId(data?.id);
                                }}
                              />
                            </td>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              recordsPerPage={recordsPerPage}
              data={BoardData}
              numbers={numbers}
              npage={npage}
              lastIndex={lastIndex}
              firstIndex={firstIndex}
              dataCount={dataCount}
            />
          </section>
        </div>

        {/* Delete */}
        <DeletePopup
          title={"Delete"}
          show={openModel}
          description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
          onCancel={() => {
            setOpenModel(false);
            setSelectBoardId("");
          }}
          onClose={() => {
            setOpenModel(false);
            setSelectBoardId("");
          }}
          onDelete={() => {
            _deleteBoard();
          }}
          cancelButtonRef={cancelButtonRef}
          okText="Delete"
          cancelText="Cancel"
        />
        {/* Delete */}

        <FloatNotification
          show={showFloatNotification}
          title={"Successfully deleted!"}
          type="success"
          description={"Data Deleted."}
          onClick={() => {
            setShowFloatNotification(false);
          }}
        />
      </div>
    </div>
  );
};

export default Board;
