import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import { Input } from "./Input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/20/solid";
import { capitalizeFirstLetter } from "./utils/helper";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Tabs = ({ categories = [], title }) => {
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    categories.map((v, i) => {
      if (location.pathname.includes(v.path)) setActiveTab(i);
    });
  }, [location]);
  return (
    <>
      <div className="h-full flex items-center justify-center ">
        <div className="w-full sm:px-6 ">
          <div className="flex-1 px-2 sm:px-0 mt-0">
            {/* <div className="flex justify-between items-center mt-2">
              <nav className="flex">
                <ol className="inline-flex items-center space-x-1 md:space-x-3 text-[#7B809A] text-sm font-regular font-roboto">
                  <li className="inline-flex items-center">
                    <div className="inline-flex items-center ">
                      <HomeIcon className="w-4 h-4" />
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <p>/</p>
                      <div  className="ml-1 ">
                        {title}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <p>/</p>
                      <span className="ml-1 text-[#344767] md:ml-2">
                        {capitalizeFirstLetter(location.pathname.split("/")[2])}
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>

              <Input
                id=""
                name="Search"
                type="text"
                placeholder="Search here"
                autoComplete="given-name"
                required
                className="w-56"
              />
            </div> */}

            <div className="w-full mt-0 sm:px-0">
              <Tab.Group selectedIndex={activeTab}>
                <Tab.List className="flex space-x-1 rounded-xl p-1 w-full overflow-x-auto ">
                  {categories.map((category, i) => (
                    <Tab
                      key={i}
                      onClick={() => navigate(category.path)}
                      className={({ selected }) =>
                        classNames(
                          "ui-not-selected: border border-[#138DE3] border-1",
                          "px-5 whitespace-nowrap rounded-lg py-2.5 text-sm font-medium leading-5  hover:border-[#138DE3] hover: border-1",
                          "ring-[#38DE3] ring-opacity-10 ring-offset-2 focus:outline-none focus:ring-2  hover:border-[#138DE3] hover: border-1",
                          selected
                            ? "bg-[#138DE3] text-white"
                            : "text-subtitle font-medium border-none"
                        )
                      }
                    >
                     <span className="hover:text-cyan-400"> {category.name}</span>
                    </Tab>
                  ))}
                </Tab.List>

                <Tab.Panels className="mt-2">
                  {categories.map((posts, idx) => (
                    <Tab.Panel key={idx}>{posts.component}</Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tabs;
