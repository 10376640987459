import React, { useState } from 'react';
export const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
  
    React.useEffect(() => {
      const timeoutId = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
  
      return () => {
        clearTimeout(timeoutId);
      };
    }, [value, delay]);
  
    return debouncedValue;
  };