import { config } from "./config";
import { getToken } from "./localstorage";
import axios from "axios";

// const getRequest = async (path) => {
//   try {
//     const params = {
//       method: "GET",
//       headers: {
//         Authorization: getToken(),
//       },
//     };
//     const res = await fetch(config.baseURL + path, params);
//     const data = await res.text();
//     if (
//       JSON.parse(data).message === "Invalid Token" ||
//       JSON.parse(data).message === "Token expired"
//     ) {
//       window.location.href = "/";
//       localStorage.removeItem("token");
//     }
//     return { statusCode: res.status, data };
//   } catch (e) {}
// };

const getRequest = async (path) => {
  try {
    const params = {
      method: "GET",
      headers: {
        Authorization: getToken(),
      },
    };
    const res = await fetch(config.baseURL + path, params);
    const data = await res.text();
    if (
      JSON.parse(data).message === "Invalid Token" ||
      JSON.parse(data).message === "Token expired"
    ) {
      window.location.href = "/";
      localStorage.removeItem("token");
    }

    if (!data || data.trim() === "") {
      throw new Error("Empty response or invalid JSON format");
    }
    return { statusCode: res.status, data };
  } catch (error) {
    console.error("Error in getRequest function:", error);
    throw error;
  }
};

const postRequest = async (path, body, isFormData) => {
  try {
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      body: isFormData ? body : JSON.stringify(body),
    };

    const res = await fetch(config.baseURL + path, params);
    const data = await res.text();
    if (
      JSON.parse(data).message === "Invalid Token" ||
      JSON.parse(data).message === "Token expired"
    ) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    return { statusCode: res.status, data };
  } catch (error) {
    const data = null;
    console.error("Error in getRequest function:", error);
    // return { statusCode: null, data };
    throw error;
  }
};

const putRequest = async (path, body) => {
  try {
    const params = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      body: JSON.stringify(body),
    };

    const res = await fetch(config.baseURL + path, params);

    const data = await res.json();
    if (
      data?.message === "Invalid Token" ||
      data?.message === "Token expired"
    ) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    return { statusCode: res.status, data };
  } catch (error) {
    console.error("Error in getRequest function:", error);
    throw error;
  }
};

const deleteRequest = async (path) => {
  try {
    const params = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
    };

    const res = await fetch(config.baseURL + path, params);

    const data = await res.text();

    return { statusCode: res.status, data };
  } catch (error) {
    console.error("Error in getRequest function:", error);
    throw error;
  }
};

const axiospostRequest = async (path, body) => {
  try {
    const res = await axios({
      url: config.baseURL + path,
      method: "POST",
      headers: {
        authorization: "Bearer " + getToken(),
      },

      data: body,
    });

    return res;
  } catch (error) {
    console.error("Error in getRequest function:", error);
    throw error;
  }
};

export const Api = {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  axiospostRequest,
};

export const ApiConfig = axios.create({
  baseURL: "https://youth-backend-stage.braincuber.com/",
});
