import Pagination from "./Pagination";
import { useEffect, useRef, useState } from "react";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import Model from "./Model";
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { Input } from "./Input";
import MasterTitle from "./MasterTitle";
import { SortData } from "./SortData";
import Records from "./Records";
import FloatNotification from "./FloatNotification";
import { PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import { Button } from "./Button";
import DeletePopup from "./DeletePopup";
import ActionPopup from "./ActionPopup";
import { Api } from "../Api/Api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ErrorToast, SuccessToast } from "./toast";
import CircularProgress from "@mui/material/CircularProgress";
import NoDataFound from "./NoDataFound";
import { getDecryptedDataFromLocalStorage } from "../Api/encryption";
import ActionButton from "./ActionButton";
import Select from "./Select";
import { Checkbox } from "./Checkbox";

const Medium = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Master")?.[0];
  const [isModel, setIsModel] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const cancelButtonRef = useRef(null);
  const [modelType, setModelType] = useState("");
  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [dataCount, setDataCount] = useState(0);
  const [MediumData, setMediumData] = useState([]);
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(dataCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [selectedId, setSelectedId] = useState(null);
  const [SelectMediumId, setSelectMediumId] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const [boardData, setBoardData] = useState([]);
  const [boardDataCount, setBoardDataCount] = useState("");
  const [search, setSearch] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const [deleteType, setDeleteType] = useState("single");
  const [isLoading, setIsLoading] = useState(false);
  const [titleData, setTitleData] = useState([
    {
      title: "BOARD",
      sort: 1,
      fieldName: "board",
    },
    {
      title: "MEDIUM",
      sort: 1,
      fieldName: "Medium",
    },
    {
      title: "POSITION",
      sort: 1,
      fieldName: "position",
    },
    {
      title: "BATCH REGISTRATION",
      sort: 1,
      fieldName: "batch registration",
    },
    {
      title: access?.edit ? "EDIT" : "",
      sort: 1,
      fieldName: "",
    },
    {
      title: access?.delete ? "DELETE" : "",
      sort: 1,
      fieldName: "",
    },
  ]);

  useEffect(() => {
    getMediumData(search, recordsPerPage, currentPage);
  }, [ recordsPerPage, currentPage]);

  const applyFilter =()=>{
    getMediumData(search, recordsPerPage, currentPage);
  }
  

  const getMediumData = async (search, limit, offset) => {
    try {
      setIsLoader(true);
      const { data } =
        (await Api.getRequest(
          `api/medium?offset=${offset}&limit=${limit}&search=${search || ""}`
        )) || {};
      const req = JSON.parse(data);
      if (req?.success) {
        setMediumData(req?.data);
        setDataCount(req?.totalCount);
        setIsLoader(false);
      } else {
        ErrorToast("Something wrong Please check");
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const getBoardData = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setBoardData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  useEffect(() => {
    getBoardData();
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string().required("Medium is required"),
    // board: Yup.string().required("Board is required"),
    position: Yup.number()
      .typeError("Position must be a number")
      .required("Position is required")
      .positive("Position must be a positive number")
      .integer("Position must be an integer"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      board: "",
      position: "",
      isregistration: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const postData = { ...values, board: values.board?.id || "" };
        if (modelType === "add") {
          await Api.postRequest("api/medium/create", postData);
          SuccessToast("Medium Added Successfully");
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
          formik.values.name = "";
          formik.values.board = "";
          formik.values.position = "";
          formik.values.isregistration = 0;
        } else if (modelType === "edit") {
          await Api.putRequest(`api/medium/update/${selectedId}`, postData);
          SuccessToast("Medium Updated Successfully");
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
          formik.resetForm();
          setIsModel(false);
        }
        getMediumData(search, recordsPerPage, currentPage);
      } catch (error) {
        ErrorToast(error.message);
        setIsLoading(false);
        console.error("API error:", error);
        if (error.response && error.response.data) {
          const errorMessage =
            error.response.data.message ||
            "An error occurred while processing your request. Please try again later.";
          ErrorToast(errorMessage);
        } else {
          ErrorToast(
            "An error occurred while processing your request. Please try again later."
          );
        }
      } finally {
        setIsLoading(false);
      }
    },
  });

  const _deleteMedium = async (id) => {
    try {
      let data;
      if (deleteType === "multi") {
        const body = {
          ids: checkedItems,
        };
        const response = await Api.postRequest(`api/medium/delete`, body);
        data = response.data;
      } else {
        const body = {
          ids: [SelectMediumId],
        };
        const response = await Api.postRequest(`api/medium/delete`, body);
        data = response.data;
      }
      const req = JSON.parse(data);
      if (req?.status === 200) {
        SuccessToast(req?.message);
        getMediumData(search, recordsPerPage, currentPage);
        setOpenModel(false);
        setSelectMediumId("");
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) => {
      const isChecked = prevState.includes(id);

      const updatedItems = isChecked
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id];

      return updatedItems;
    });
  };

  return (
    <div>
      <div className="flex justify-between items-center mt-4">
        <div>
          <MasterTitle
            title="Medium"
            description="Below are the list of all the medium."
          />
        </div>
        <div>
          <div style={{ display: "flex", gap: "5px" }}>
            {access?.delete && (
              <Button
                id=""
                type="submit"
                icon={TrashIcon}
                label={"DELETE MEDIUM"}
                onClick={() => {
                  setDeleteType("multi");
                  if (checkedItems.length > 0) {
                    setOpenModel(true);
                  }
                }}
              />
            )}
            {access?.add && (
              <Button
                id=""
                type="submit"
                icon={PlusIcon}
                label={"ADD MEDIUM"}
                onClick={() => {
                  setIsModel(true);
                  setModelType("add");
                  formik.values.name = "";
                  formik.values.board = "";
                  formik.values.position = "";
                  formik.values.isregistration = 0;
                }}
              />
            )}
          </div>
          <Model
            selectedId={selectedId}
            title={modelType === "add" ? "Add Medium" : "Edit Medium"}
            show={isModel}
            onCancel={() => {
              // formik.resetForm();
              setIsModel(false);
            }}
            onClose={() => {
              setIsModel(false);
              formik.resetForm();
            }}
            onOk={() => {
              formik.handleSubmit();
            }}
            isLoading={isLoading}
            disabled={isLoading}
            okText="SUBMIT"
            cancelText="CLOSE"
          >
            <form onSubmit={formik.handleSubmit}>
              <Input
                label="Medium"
                id="name"
                name="name"
                type="text"
                autoComplete="off"
                required
                className="w-full mb-3"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="text-red-500 text-sm">{formik.errors.name}</div>
              )}

              <div className="w-full flex flex-col">
                <Select
                  value={formik.values.board}
                  data={boardData || []}
                  onChange={(e) => {
                    const selectedBoardId = e;
                    formik.setFieldValue("board", e); // Pass the selected board ID
                  }}
                  onBlur={formik.handleBlur}
                  className="font-regular mb-3"
                  label="Board"
                  required
                  placeholder="All Board"
                  type={"board"}
                  getBoard={getBoardData}
                // isPage={true}
                />
                {formik.touched.board && formik.errors.board && (
                  <div className="text-red-500 text-sm">
                    {formik.errors.board}
                  </div>
                )}
              </div>

              <Input
                label="Position"
                id="position"
                name="position"
                type="text"
                autoComplete="off"
                required
                className="w-full"
                value={formik.values.position}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.position && formik.errors.position && (
                <div className="text-red-500 text-sm">
                  {formik.errors.position}
                </div>
              )}

              <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6 mt-2 mb-3">
                <div className="w-full flex flex-col">
                  <FormControl>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      style={{
                        fontFamily: "poppins",
                        color: "#252F40",
                        opacity: "1",
                      }}
                    >
                      Batch Registration*
                    </FormLabel>

                    <RadioGroup
                      row
                      required
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={formik.values.isregistration}
                      onChange={(e) => {
                        formik.setFieldValue("isregistration", e.target.value); // Pass the selected board ID
                      }}
                      onBlur={formik.handleBlur}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  {formik.touched.isregistration && formik.errors.isregistration && (
                <div className="text-red-500 text-sm">
                  {formik.errors.isregistration}
                </div>
              )}
                </div>
              </div>
            </form>
          </Model>
        </div>
      </div>
      <div className="sm:mb-0">
        <div className="flex flex-col mt-4">
          <section
            className="bg-white rounded-2xl flex flex-col shadow-sm "
            style={{
              width: "100%",
              height: "75vh",
              margin: "0 auto",
              padding: "20px",
            }}
          >
            {" "}
            <div className="flex justify-between items-center mt-6 px-8 mb-3">
              <div  style={{ display: "flex", gap:'5px' }}>
              <Input
                id=""
                name="Search"
                type="text"
                placeholder="Search here..."
                autoComplete="given-name"
                required
                onChange={(e) => {
                  setSearch(e?.target?.value);
                  setCurrentPage(1);
                }}
                className="w-72"
                value={search}
              />
              <Button
                onClick={() => applyFilter()}
                id=""
                type="button"
                // label={"SEARCH"}
                searchicon={true}
              />
              </div>
              <Records
                recordsPerPage={recordsPerPage}
                setCurrentPage={setCurrentPage}
                setRecordsPerPage={setRecordsPerPage}
                dataCount={dataCount}
              />
            </div>
            <div
              style={{
                overflowY: "auto",
                maxHeight: "58vh",
                height: "60vh",
                width: "100%",
              }}
            >
              {" "}
              {!isLoader && MediumData?.length <= 0 ? (
                <NoDataFound />
              ) : (
                <table style={{ width: "100%" }}>
                  <thead
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#f4f7f8",
                      zIndex: 1,
                    }}
                  >
                    <tr
                      style={{
                        borderBottom: "1px solid #dddddd",
                        textAlign: "left",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#7B809A",
                        textTransform: "uppercase",
                        backgroundColor: "#f4f7f8",
                      }}
                    >
                      <th
                        scope="col"
                        style={{ width: "3%", padding: "10px" }}
                      ></th>
                      {titleData.map(
                        (value, ind) =>
                          value.title && (
                            <th
                              key={ind}
                              className="py-2 px-6 "
                              style={{
                                padding: "12px",
                                backgroundColor: "#f4f7f8",
                                position:
                                  value.title === "EDIT" ||
                                    value.title === "DELETE"
                                    ? "sticky"
                                    : "",
                                right:
                                  value.title === "EDIT"
                                    ? "60px"
                                    : value.title === "DELETE"
                                      ? "0"
                                      : "",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>{value.title}</span>
                                {value.title !== "EDIT" &&
                                  value.title !== "DELETE" && (
                                    <span>
                                      <ChevronUpDownIcon
                                        onClick={() => {
                                          let res = SortData(
                                            MediumData,
                                            value.fieldName,
                                            value.sort
                                          );

                                          let copyData = [...titleData];
                                          copyData[ind].sort =
                                            value.sort > 0 ? -1 : 1;
                                          setTitleData(copyData);
                                          setMediumData(res);
                                        }}
                                        className="w-5 h-5"
                                      />
                                    </span>
                                  )}
                              </div>
                            </th>
                          )
                      )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {isLoader ? (
                      <tr style={{ height: "25rem" }}>
                        <td colSpan={6} style={{ textAlign: "center" }}>
                          <CircularProgress />
                        </td>
                      </tr>
                    ) : (
                      MediumData.map((data) => (
                        <tr
                          key={data.key}
                          className="my-2 px-6 text-[#000] font-poppins text-sm "
                        >
                          {" "}
                          <td className="px-6 py-3 whitespace-no-wrap">
                            {/* <Checkbox type="checkbox" /> */}
                            <Checkbox
                              checked={checkedItems.includes(data.id)}
                              onChange={() => handleCheckboxChange(data.id)}
                            />
                          </td>{" "}
                          <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                            {data?.board_info?.board_name ?? "-"}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                            {data?.name ?? "-"}
                          </td>{" "}
                          <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                            {data?.position ?? "-"}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                            {data?.isregistration ?? "-"}
                          </td>
                          {access?.edit && (
                            <td
                              className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                              style={{
                                position: "sticky",
                                right: "68px",
                                backgroundColor: "white",
                              }}
                            >
                              <ActionButton
                                key={data.key}
                                action="edit"
                                onClick={() => {
                                  setIsModel(true);
                                  setSelectedId(data.id);
                                  setModelType("edit");
                                  formik.values.name = data?.name;
                                  formik.values.board = data?.board_info;
                                  formik.values.position = data?.position;
                                  formik.values.isregistration = data?.isregistration;
                                }}
                              />
                            </td>
                          )}
                          {access?.delete && (
                            <td
                              className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                              style={{
                                position: "sticky",
                                right: "0",
                                backgroundColor: "white",
                              }}
                            >
                              <ActionButton
                                key={data.key}
                                action="delete"
                                onClick={() => {
                                  setDeleteType("single");
                                  setOpenModel(true);
                                  setSelectMediumId(data?.id);
                                }}
                              // onDelete={() => {
                              //   _deleteMedium(data?.id);
                              // }}
                              />
                            </td>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              recordsPerPage={recordsPerPage}
              data={MediumData}
              numbers={numbers}
              npage={npage}
              lastIndex={lastIndex}
              firstIndex={firstIndex}
              dataCount={dataCount}
            />
          </section>
        </div>

        {/* Delete */}
        <DeletePopup
          title={"Delete"}
          show={openModel}
          description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
          onCancel={() => {
            setOpenModel(false);
            setSelectMediumId("");
          }}
          onClose={() => {
            setOpenModel(false);
            setSelectMediumId("");
          }}
          onDelete={() => {
            _deleteMedium();
          }}
          cancelButtonRef={cancelButtonRef}
          okText="Delete"
          cancelText="Cancel"
        />
        {/* Delete */}

        <FloatNotification
          show={showFloatNotification}
          title={"Successfully deleted!"}
          type="success"
          description={"Data Deleted."}
          onClick={() => {
            setShowFloatNotification(false);
          }}
        />
      </div>
    </div>
  );
};

export default Medium;
