import { call, put, takeLatest } from "redux-saga/effects";
import { getTopicDataApi } from "../requests/topicRequest";
import { setTopicList } from "../../actions/topicAction";
import { TopicActions } from "../../action-types/topicActionType";

function* getTopicHandler(action) {
  // start loader here
  try {
    const { data } = yield call(getTopicDataApi, action.payload);
    yield put(setTopicList(data));
    // handle response here and set into reducer
  } catch (error) {
  } finally {
    //stop loader here
  }
}

export default function* topicSaga() {
  yield takeLatest(TopicActions.GET_TOPIC_DATA, getTopicHandler);
}
