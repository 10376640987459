import React, { useEffect, useState } from "react";
import MasterTitle from "./MasterTitle";
import { Input } from "./Input";
import { Button } from "./Button";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast } from "./toast";
import { Typography } from "@mui/material";

const HelpSection = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Paper")?.[0];

  const [paperLink, setPaperLink] = useState("");
  const [autoLink, setAutoLink] = useState("");
  const [randomLink, setRandomLink] = useState("");
  const [error, setError] = useState({});

  const getHelpLink = async () => {
    try {
      const data = await Api.getRequest(`api/help`);
      const req = JSON.parse(data.data);
      setPaperLink(req?.data[0]?.paper);
      setAutoLink(req?.data[0]?.Auto);
      setRandomLink(req?.data[0]?.random);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };
  useEffect(() => {
    getHelpLink();
  }, []);

  const validation = () => {
    const linkPattern = /^(http|https):\/\/.+$/i;
    let errors = {};
    let formIsValid = true;

    if (!paperLink) {
      formIsValid = false;
      errors["link"] = "Please enter paper generation link.";
    } else if (!linkPattern.test(paperLink.trim())) {
      formIsValid = false;
      errors["link"] = "Please enter a valid HTTP or HTTPS link.";
    }
    if (!autoLink) {
      formIsValid = false;
      errors["link"] = "Please enter Auto paper generationlink.";
    } else if (!linkPattern.test(autoLink.trim())) {
      formIsValid = false;
      errors["link"] = "Please enter a valid HTTP or HTTPS link.";
    }
    if (!randomLink) {
      formIsValid = false;
      errors["link"] = "Please enter Random paper generationlink.";
    } else if (!linkPattern.test(randomLink.trim())) {
      formIsValid = false;
      errors["link"] = "Please enter a valid HTTP or HTTPS link.";
    }

    setError(errors);
    return formIsValid;
  };

  const handleSubmit = async () => {
    if (validation()) {
      try {
        const body = {
          paper: paperLink,
          Auto: autoLink,
          random: randomLink,
        };

        const response = await Api.putRequest(
          `api/help/update/${1 || ""}`,
          body
        );
        if (response?.data?.status === 200) {
          getHelpLink();
          SuccessToast(response?.data?.message);
        }
      } catch (error) {
        ErrorToast(error?.message);
      }
    }
  };

  return (
    <div className="w-full sm:px-6">
      <div>
        <div className="sm:mb-0 overflow-hidden ">
          <div className="flex flex-col mt-4">
            <section className="bg-white rounded-2xl flex flex-col shadow-sm min-h-[calc(100vh-40px-16px-24px-38px-50px-40px)]">
              <div className="px-8 ">
                <div className="mt-10 mb-3">
                  <MasterTitle
                    title="Help Section"
                    description="Below are the help section."
                  />
                </div>

                <div className="max-w-xl">
                  <div className="w-full space-y-6">
                    <Input
                      label="Paper Link"
                      type="text"
                      autoComplete="given-name"
                      name="Link"
                      required
                      placeholder="link type here..."
                      className="mb-2 "
                      value={paperLink || ""}
                      onChange={(e) => setPaperLink(e.target.value)}
                    />
                    <Typography
                      className="my-2"
                      variant="caption"
                      color={"error"}
                    >
                      {!paperLink || paperLink ? error?.link : ""}
                    </Typography>

                    <Input
                      label="AutoLink"
                      type="text"
                      autoComplete="given-name"
                      name="Link"
                      required
                      placeholder="link type here..."
                      className="mb-2 "
                      value={autoLink || ""}
                      onChange={(e) => setAutoLink(e.target.value)}
                    />
                    <Typography
                      className="my-2"
                      variant="caption"
                      color={"error"}
                    >
                      {!autoLink || autoLink ? error?.link : ""}
                    </Typography>
                    <Input
                      label="Random Link"
                      type="text"
                      autoComplete="given-name"
                      name="Link"
                      required
                      placeholder="link type here..."
                      className="mb-2 "
                      value={randomLink || ""}
                      onChange={(e) => setRandomLink(e.target.value)}
                    />
                    <Typography
                      className="my-2"
                      variant="caption"
                      color={"error"}
                    >
                      {!randomLink || randomLink ? error?.link : ""}
                    </Typography>

                    <div className="flex items-center flex-col sm:flex-row sm:space-y-0 sm:space-x-6 mb-3">
                      {access?.edit && (
                        <div className="w-96">
                          <Button
                            id=""
                            type="button"
                            label={"SUBMIT"}
                            abc="w-full"
                            onClick={handleSubmit}
                          />
                        </div>
                      )}
                      {/* <div className="w-96">
                        <Button
                          isBordered
                          id=""
                          type="button"
                          label={"RESET"}
                          abc="w-full"
                          onClick={handleReset}
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpSection;
