import { SubjectActions } from "../action-types/subjectActionType";

export const getSubjectList = (payload) => {
  return {
    type: SubjectActions.GET_SUBJECT_DATA,
    payload,
  };
};

export const setSubjectList = (payload) => {
  return {
    type: SubjectActions.SET_SUBJECT_DATA,
    payload,
  };
};