import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

export default function Pagination({
  currentPage,
  setCurrentPage,
  data,
  numbers,
  npage,
  firstIndex,
  recordsPerPage,
  dataCount,
  setSelectAll,
  setCheckedItems,
}) {
  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);

    }
  };
  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);

    }
  };
  const changeCpage = (id) => {
    setCurrentPage(id);

  };
  // Determine how many page numbers to show on each side of the current page
  const maxPageNumbers = 10; // You can adjust this number
  const sidePageNumbers = Math.floor(maxPageNumbers / 2);
  let startPage = currentPage - sidePageNumbers;
  if (startPage < 1) startPage = 1;
  let endPage = startPage+2 + maxPageNumbers - 1;
  if (endPage > npage) {
    endPage = npage;
    startPage = Math.max(1, endPage - maxPageNumbers + 1);
  }
  return (
    <div className="px-4 mt-auto py-2 flex items-center justify-between sm:px-6 cursor-pointer">
      <div className="flex flex-col items-center mx-auto sm:flex-row sm:flex-1 sm:flex sm:items-center sm:justify-between ">
        <div className="mb-4 sm:mb-0">
          <p className="text-sm text-[#7B809A] font-regular font-poppins justify-center">
            <span>
              Showing {firstIndex + 1} to{" "}
              {data.length > firstIndex + recordsPerPage
                ? firstIndex + recordsPerPage
                : firstIndex + recordsPerPage}{" "}
              of {dataCount} entries
            </span>
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex space-x-2 "
            aria-label="Pagination"
          >
            <div
              onClick={prePage}
              className="relative inline-flex items-center px-2 py-2 rounded-full border border-gray-300 text-sm font-medium text-gray-500"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </div>
            {startPage > 1 && (
              <div
                onClick={() => changeCpage(1)}
                aria-current="page"
                className={`z-10 rounded-full relative inline-flex items-center px-4 py-2 text-sm font-medium border border-gray-300 rounded-full text-gray-500`}
              >
                1
              </div>
            )}
            {startPage > 2 && (
              <div
                className={`z-10 relative inline-flex items-center px-4 py-2 text-sm font-medium border border-gray-300 rounded-full text-gray-500`}
              >
                ...
              </div>
            )}
            {numbers.slice(startPage - 1, endPage).map((number, i) => (
              <div
                onClick={() => changeCpage(number)}
                aria-current="page"
                key={i}
                className={`z-10 rounded-full relative inline-flex items-center px-4 py-2 text-sm font-medium ${
                  currentPage === number
                    ? "bg-gradient-to-b from-[#1190E4] to-[#3552D8] text-white"
                    : "border border-gray-300 rounded-full text-black-500 bg-gradient-to-b hover:from-[#3552D8] hover:to-[#1190E4] hover:text-white"
                }`}
              >
                {number}
              </div>
            ))}
            {endPage < npage - 1 && (
              <div
                className={`z-10 relative inline-flex items-center px-4 py-2 text-sm font-medium border border-gray-300 rounded-full text-gray-500`}
              >
                ...
              </div>
            )}
            {endPage < npage && (
              <div
                onClick={() => changeCpage(npage)}
                aria-current="page"
                className={`z-10 rounded-full relative inline-flex items-center px-4 py-2 text-sm font-medium border border-gray-300 rounded-full text-gray-500`}
              >
                {npage}
              </div>
            )}
            <div
              onClick={nextPage}
              className="relative inline-flex items-center px-2 py-2 rounded-full border border-gray-300  text-sm font-medium text-gray-500"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
