import { ChevronDownIcon, CheckIcon } from "@heroicons/react/24/outline";
import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";

const Records = ({
  setRecordsPerPage,
  recordsPerPage,
  setCurrentPage,
  dataCount,
  setSelectAll,
  setCheckedItems
}) => {
  const data = [
    { name: 50, value: 50 },
    { name: 60, value: 60 },
    { name: 70, value: 70 },
    { name: 80, value: 80 },
    { name: 90, value: 90 },
    { name: 100, value: 100 },
    // { name: 70, value: 70 },
    // { name: 80, value: 80 },
    // { name: 90, value: 90 },
    // { name: 100, value: 100 },
    // { name: "All", value: dataCount === 0 ? 10 : dataCount },
  ];

  let selected = data.find((value) => value.value === recordsPerPage);

  return (
    <>
      <div className="flex items-center">
        <div className="w-20">
          <Listbox
            value={selected}
            onChange={(event) => {
              setRecordsPerPage(event.value);
              setCurrentPage(1);
            }}
          >
            <div className="relative mt-1">
              <Listbox.Button
                className="relative w-full cursor-default rounded-md py-2 pl-2 pr-10 text-left focus:outline-none sm:text-sm
            inline-flex justify-center border border-1 font-medium text-[#344767]"
              >
                <span className="block truncate">{selected?.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    className="h-4 w-4 text-[#344767]"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  className=" max-h-80 overflow-auto py-1 sm:text-sm
              absolute right-0 z-20 mt-2 w-full origin-top-right divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none
              "
                >
                  {data.map((person, personIdx) => (
                    <Listbox.Option
                      key={personIdx}
                      className={({ active }) =>
                        `relative cursor-default select-none py-1 pl-10 pr-4 ${
                          active ? " text-gray-400" : "text-gray-900"
                        }`
                      }
                      value={person}
                    >
                      {({ selected }) => {
                        return (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {person.name}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-black">
                                <CheckIcon
                                  className="h-4 w-4"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        );
                      }}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
        <div className="ml-3 text-[#7B809A] text-sm font-roboto">Records</div>
      </div>
    </>
  );
};

export default Records;
