import React, { useRef, useState, useEffect } from "react";
import { Input, TextArea } from "./Input";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import Pagination from "./Pagination";
import Model from "./Model";
import MasterTitle from "./MasterTitle";
import { Button } from "./Button";
import { Checkbox } from "./Checkbox";
import Records from "./Records";
import { SortData } from "./SortData";
import FloatNotification from "./FloatNotification";
import { PlusIcon, MinusIcon } from "@heroicons/react/20/solid";
import Select from "./Select";
import Breadcrumbs from "./Breadcrumbs";
import DeletePopup from "./DeletePopup";
import ActionPopup from "./ActionPopup";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast, WarningToast } from "./toast";
import NoDataFound from "./NoDataFound";
import CircularProgress from "@mui/material/CircularProgress";
import ActionButton from "./ActionButton";
import { Autocomplete, Chip, TextField, Typography } from "@mui/material";

const Notice = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Paper")?.[0];
  const [isModel, setIsModel] = useState(false);
  const [modelType, setModelType] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const cancelButtonRef = useRef(null);
  const [titleData, setTitleData] = useState([
    {
      title: "STANDARD",
      sort: 1,
      fieldName: "standard",
    },
    {
      title: "SEMESTER",
      sort: 1,
      fieldName: "semester",
    },
    {
      title: "SUBJECT",
      sort: 1,
      fieldName: "subject",
    },
    {
      title: access?.edit ? "EDIT" : "",
      sort: 1,
      fieldName: "",
    },
    {
      title: access?.delete ? "DELETE" : "",
      sort: 1,
      fieldName: "",
    },
  ]);

  const [NoticeTitleData, setNoticeTitleData] = useState([
    {
      title: "No.",
    },
    {
      title: "Notice Label",
    },
    {
      title: "Minus",
    },
    {
      title: "Plus",
    },
  ]);

  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [NoticeData, setNoticeData] = useState([]);
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(dataCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [isLoader, setIsLoader] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [SelectNoticeId, setSelectNoticeId] = useState();
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [papergenfilter, setpapergenfilter] = useState({
    board: "",
    medium: "",
    standard: "",
    semester: "",
    subject: "",
  });

  const [boardData, setBoardData] = useState([]);
  const [mediumData, setMediumData] = useState([]);
  const [standardData, setStandardData] = useState([]);
  const [semesterData, setSemesterData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);

  const [modelNoticeData, setModelNoticeData] = useState({
    board: "",
    medium: "",
    standard: "",
    semester: "",
    subject: "",
  });
  const [isModelLoader, setisModelLoader] = useState(false);

  const [AddNoticeData, setAddNoticeData] = useState([
    {
      label: "",
    },
  ]);

  const [selectionDataBoard, setSelectionDataBoard] = useState([]);
  const [selectionDataMedium, setSelectionDataMedium] = useState([]);
  const [selectionDataStandard, setSelectionDataStandard] = useState([]);
  const [selectionDataSubject, setSelectionDataSubject] = useState([]);
  const [selectionDataSemester, setSelectionDataSemester] = useState([]);
  const [isResetFilter, setIsResetFilter] = useState(false);

  useEffect(() => {
    getNoticeData(recordsPerPage, currentPage);
  }, [recordsPerPage, currentPage]);

  const getNoticeData = async (limit, offset) => {
    try {
      let body = {
        standard: papergenfilter?.standard?.id,
        semester: papergenfilter?.semester?.id,
        subject: papergenfilter?.subject?.id,
      };
      setIsLoader(true);
      const { data } = await Api.postRequest(
        `api/notice/mobile?offset=${offset || ""}&limit=${limit || ""}`,
        body
      );
      if (data) {
        const req = JSON.parse(data);
        setNoticeData(req?.data);
        setDataCount(req?.totalCount);
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  useEffect(() => {
    getBoardData();
    getBoardModelData();
  }, []);

  const getBoardData = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setBoardData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getMediumData = async () => {
    try {
      let body = `?board=${papergenfilter?.board?.id}`;
      const { data } = await Api.getRequest(`api/medium${body}`);
      const req = JSON.parse(data);
      setMediumData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getStandardData = async () => {
    try {
      let body = `?board=${papergenfilter?.board?.id}&medium=${papergenfilter?.medium?.id}`;
      const { data } = await Api.getRequest(`api/standard${body}`);
      const req = JSON.parse(data);
      setStandardData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSemesterData = async () => {
    try {
      let body = `?board=${papergenfilter?.board?.id}&medium=${papergenfilter?.medium?.id}&standard=${papergenfilter?.standard?.id}`;
      const { data } = await Api.getRequest(`api/semester${body}`);
      const req = JSON.parse(data);
      setSemesterData(req?.data?.rows);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSubjectData = async () => {
    try {
      let body = `?board=${papergenfilter?.board?.id}&medium=${papergenfilter?.medium?.id}&standard=${papergenfilter?.standard?.id}&semester=${papergenfilter?.semester?.id}`;
      const { data } = await Api.getRequest(`api/subject${body}`);
      const req = JSON.parse(data);
      setSubjectData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    if (papergenfilter["board"] !== "") {
      getMediumData();
    }
  }, [papergenfilter["board"]]);

  useEffect(() => {
    if (papergenfilter["medium"] !== "") {
      getStandardData();
    }
  }, [papergenfilter["medium"]]);

  useEffect(() => {
    if (papergenfilter["standard"] !== "") {
      getSemesterData();
    }
  }, [papergenfilter["standard"]]);

  useEffect(() => {
    if (papergenfilter["semester"] !== "") {
      getSubjectData();
    }
  }, [papergenfilter["semester"]]);

  const handleFilter = (name, value) => {
    setpapergenfilter({
      ...papergenfilter,
      [name]: value,
    });
    if (name === "board") {
      setpapergenfilter((oldValue) => ({
        ...oldValue,
        medium: "",
        standard: "",
        semester: "",
        subject: "",
        [name]: value,
      }));
      setMediumData([]);
      setStandardData([]);
      setSemesterData([]);
      setSubjectData([]);
    }
    if (name === "medium") {
      setpapergenfilter((oldValue) => ({
        ...oldValue,
        standard: "",
        semester: "",
        subject: "",
        [name]: value,
      }));
      setStandardData([]);
      setSemesterData([]);
      setSubjectData([]);
    }
    if (name === "standard") {
      setpapergenfilter((oldValue) => ({
        ...oldValue,
        semester: "",
        subject: "",
        [name]: value,
      }));
      setSemesterData([]);
      setSubjectData([]);
    }
    if (name === "semester") {
      setpapergenfilter((oldValue) => ({
        ...oldValue,
        subject: "",
        [name]: value,
      }));
      setSubjectData([]);
    }
  };

  const getBoardModelData = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setSelectionDataBoard(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getMediumModelData = async () => {
    try {
      let body = `?board=${modelNoticeData?.board?.id}`;
      const { data } = await Api.getRequest(`api/medium${body}`);
      const req = JSON.parse(data);
      setSelectionDataMedium(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getStandardModelData = async () => {
    try {
      let body = `?board=${modelNoticeData?.board?.id}&medium=${modelNoticeData?.medium?.id}`;
      const { data } = await Api.getRequest(`api/standard${body}`);
      const req = JSON.parse(data);
      setSelectionDataStandard(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSemesterModelData = async () => {
    try {
      let body = `?board=${modelNoticeData?.board?.id}&medium=${modelNoticeData?.medium?.id}&standard=${modelNoticeData?.standard?.id}`;
      const { data } = await Api.getRequest(`api/semester${body}`);
      const req = JSON.parse(data);
      setSelectionDataSemester(req?.data?.rows);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSubjectModelData = async () => {
    try {
      let body = `?board=${modelNoticeData?.board?.id}&medium=${modelNoticeData?.medium?.id}&standard=${modelNoticeData?.standard?.id}&semester=${modelNoticeData?.semester?.id}`;
      const { data } = await Api.getRequest(`api/subject${body}`);
      const req = JSON.parse(data);
      setSelectionDataSubject(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    if (modelNoticeData["board"] != "") {
      getMediumModelData();
    }
    if (modelNoticeData["medium"] != "") {
      getStandardModelData();
    }
    if (modelNoticeData["standard"] != "") {
      getSemesterModelData();
    }
    if (modelNoticeData["semester"] != "") {
      getSubjectModelData();
    }
  }, [modelNoticeData]);

  const handelModelData = (name, value) => {
    setModelNoticeData((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
    if (name === "board") {
      setModelNoticeData((oldValue) => ({
        ...oldValue,
        medium: "",
        standard: "",
        semester: "",
        subject: "",
        [name]: value,
      }));
      setSelectionDataMedium([]);
      setSelectionDataStandard([]);
      setSelectionDataSemester([]);
      setSelectionDataSubject([]);
    }
    if (name === "medium") {
      setModelNoticeData((oldValue) => ({
        ...oldValue,
        standard: "",
        semester: "",
        subject: "",
        [name]: value,
      }));
      setSelectionDataStandard([]);
      setSelectionDataSemester([]);
      setSelectionDataSubject([]);
    }
    if (name === "standard") {
      setModelNoticeData((oldValue) => ({
        ...oldValue,
        semester: "",
        subject: "",
        [name]: value,
      }));
      setSelectionDataSemester([]);
      setSelectionDataSubject([]);
    }
    if (name === "semester") {
      setModelNoticeData((oldValue) => ({
        ...oldValue,
        subject: "",
        [name]: value,
      }));
      setSelectionDataSubject([]);
    }
  };

  useEffect(() => {
    if (isResetFilter) {
      getNoticeData(recordsPerPage, currentPage);
      setIsResetFilter(false);
    }
  }, [isResetFilter]);

  const applyFilter = () => {
    getNoticeData(recordsPerPage, currentPage);
  };

  const resetFilter = () => {
    setIsResetFilter(true);
    setpapergenfilter({
      board: "",
      medium: "",
      standard: "",
      semester: "",
      subject: "",
    });
    setMediumData([]);
    setStandardData([]);
    setSemesterData([]);
    setSubjectData([]);
  };

  const handleChangeAddBookData = (index, name, value) => {
    setAddNoticeData((oldData) => {
      const newData = [...oldData]; // Create a copy of the array
      newData[index] = { ...newData[index], [name]: value }; // Update the specific object at the given index
      return newData; // Return the updated array
    });
  };

  const handleAddBookdata = () => {
    const lastData = AddNoticeData[AddNoticeData.length - 1];

    if (lastData.label === "") {
      // Display an error message or handle the scenario as per your requirements
      WarningToast(
        "Cannot add a new Notice Label when the previous entry's Notice Label is empty."
      );
      return; // Exit the function early
    }

    // Create a new entry with default values
    const newEntry = {
      label: "",
    };

    // Append the new entry to the AddNoticeData array
    setAddNoticeData((oldData) => [...oldData, newEntry]);
  };

  const handleMinusBookdata = (id) => {
    setAddNoticeData((oldData) => {
      // Filter out the entry with the given id
      const updatedData = oldData.filter((data, index) => index !== id);
      return updatedData;
    });
  };

  const validation = () => {
    let errors = {};
    let formIsValid = true;
    if (!modelNoticeData?.board) {
      formIsValid = false;
      errors["board"] = "Please select board.";
    }
    if (!modelNoticeData?.medium) {
      formIsValid = false;
      errors["medium"] = "Please select medium.";
    }
    if (!modelNoticeData?.standard) {
      formIsValid = false;
      errors["standard"] = "Please select standard.";
    }
    if (!modelNoticeData?.semester) {
      formIsValid = false;
      errors["semester"] = "Please select semester.";
    }
    if (!modelNoticeData?.subject) {
      formIsValid = false;
      errors["subject"] = "Please select subject.";
    }

    setError(errors);
    return formIsValid;
  };

  const handleAddModelSubmit = async () => {
    if (validation()) {
      const lastData = AddNoticeData[AddNoticeData.length - 1];
      if (lastData.label !== "") {
        setIsLoading(true);
        if (modelType === "edit") {
          try {
            let body = {
              standard: modelNoticeData?.standard?.id,
              semester: modelNoticeData?.semester?.id,
              subject: modelNoticeData?.subject?.id,
              notices: AddNoticeData,
            };
            const res = await Api.putRequest(
              `api/notice/update/${selectedId}`,
              body
            );
            if (res?.data?.status === 200) {
              SuccessToast(res?.data?.message);
              getNoticeData(recordsPerPage, currentPage);
              setIsModel(false);
              handleResetNotice();
              setSelectionDataMedium([]);
              setSelectionDataStandard([]);
              setSelectionDataSemester([]);
              setSelectionDataSubject([]);
              setTimeout(() => {
                setIsLoading(false);
              }, 2000);
            } else {
              ErrorToast(res?.data);
            }
          } catch (error) {
            ErrorToast(error?.message);
            setIsLoading(false);
          } finally {
            setIsLoading(false);
          }
        } else {
          let body = {
            standard: modelNoticeData?.standard?.id,
            semester: modelNoticeData?.semester?.id,
            subject: modelNoticeData?.subject?.id,
            notices: AddNoticeData,
          };
          try {
            const { data } = await Api.postRequest(`api/notice/create`, body);
            const res = JSON.parse(data);
            if (res?.status === 200) {
              SuccessToast(res?.message);
              getNoticeData(recordsPerPage, currentPage);
              handleResetNotice();
              setTimeout(() => {
                setIsLoading(false);
              }, 2000);
              setIsModel(false);
            } else {
              ErrorToast(res?.message);
              setIsLoading(false);
            }
          } catch (error) {
            ErrorToast(error?.message);
            setIsLoading(false);
          }
        }
      } else {
        WarningToast("Please enter empty Notice Label.");
      }
    }
  };

  const handleResetNotice = () => {
    setModelNoticeData({
      board: "",
      medium: "",
      standard: "",
      semester: "",
      subject: "",
    });
    setAddNoticeData([
      {
        label: "",
      },
    ]);
    setError({});
    setSelectedId("");
  };

  const _getById = async (id) => {
    try {
      if (id) {
        setisModelLoader(true);
        const { data } = await Api.getRequest(
          `api/notice/getNoticeByID/${id || ""}`
        );
        const req = JSON.parse(data);
        if (req?.status === 200) {
          setisModelLoader(false);
          setModelNoticeData({
            board: req?.data?.StandardInfo?.boardInfo || "",
            medium: req?.data?.StandardInfo?.mediumInfo || "",
            standard: req?.data?.StandardInfo || "",
            semester: req?.data?.SemesterInfo || "",
            subject: req?.data?.SubjectInfo || "",
          });
          if (req?.data?.noticeInfo?.length !== 0) {
            setAddNoticeData(req?.data?.noticeInfo);
          }
        } else {
          ErrorToast(req.message);
        }
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    if (selectedId) {
      _getById(selectedId);
    }
  }, [selectedId]);

  const _deleteAddSound = async (id) => {
    try {
      const { data } = await Api.getRequest(
        `api/notice/delete/${SelectNoticeId}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        SuccessToast(req?.message);
        getNoticeData(recordsPerPage, currentPage);
        setOpenModel(false);
        setSelectNoticeId("");
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  return (
    <>
      <div className="h-full flex items-center justify-center ">
        <div className="h-full flex-1 flex flex-col space-y-5 lg:space-y-0 lg:flex-row lg:space-x-10 w-full sm:px-6">
          <div className="flex-1 px-2 sm:px-0">
            <div className="w-full sm:px-0 flex items-start gap-x-4 mr-4">
              <div className="w-full">
                <Select
                  data={boardData || []}
                  value={papergenfilter?.board}
                  onChange={(e) => handleFilter("board", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Board"
                  type="board"
                  getBoard={getBoardData}
                />
              </div>
              <div className="w-full">
                <Select
                  data={mediumData || []}
                  value={papergenfilter?.medium}
                  onChange={(e) => handleFilter("medium", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Medium"
                  type="medium"
                  getMedium={getMediumData}
                />
              </div>
              <div className="w-full">
                <Select
                  data={standardData || []}
                  value={papergenfilter?.standard}
                  onChange={(e) => handleFilter("standard", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Standard"
                  type={"standard"}
                  getStandard={getStandardData}
                />
              </div>
              <div className="w-full">
                <Select
                  data={semesterData || []}
                  value={papergenfilter?.semester}
                  onChange={(e) => handleFilter("semester", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Semester"
                  type={"semester"}
                  getSemester={getSemesterData}
                />
              </div>
              <div className="w-full">
                <Select
                  data={subjectData || []}
                  value={papergenfilter?.subject}
                  onChange={(e) => handleFilter("subject", e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Subject"
                  type={"subject"}
                  getSubject={getSubjectData}
                />
              </div>
              <div className="mt-3">
                <Button
                  onClick={() => applyFilter()}
                  id="filterbtn"
                  type="button"
                  label={"SEARCH"}
                />
              </div>
              <div className="mt-3">
                <Button
                  onClick={() => resetFilter()}
                  id=""
                  type="button"
                  label={"RESET"}
                />
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center mt-4">
                <div>
                  <MasterTitle
                    title="Notice"
                    description="Below are the list of all the notice."
                  />
                </div>
                <div className="flex items-center space-x-2">
                  <div>
                    {access?.add && (
                      <Button
                        id=""
                        type="submit"
                        icon={PlusIcon}
                        label={"ADD NOTICE"}
                        onClick={() => {
                          setIsModel(true);
                          setModelType("add");
                        }}
                      />
                    )}
                    <Model
                      title={modelType === "add" ? "Add Notice" : "Edit Notice"}
                      show={isModel}
                      onCancel={() => {
                        setIsModel(false);
                        handleResetNotice();
                      }}
                      onClose={() => {
                        setIsModel(false);
                        handleResetNotice();
                      }}
                      onOk={() => {
                        handleAddModelSubmit();
                      }}
                      okText="SUBMIT"
                      cancelText="CLOSE"
                      isLoading={isLoading}
                      disabled={isLoading}
                      width="max-w-3xl"
                    >
                      {isModelLoader ? (
                        <tr
                          style={{
                            height: "120px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <td>
                            <CircularProgress />
                          </td>
                        </tr>
                      ) : (
                        <>
                          <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6">
                            <div className="w-full">
                              <Select
                                value={modelNoticeData?.board}
                                data={selectionDataBoard || []}
                                onChange={(e) => handelModelData("board", e)}
                                className="font-regular mb-3"
                                label="Board"
                                required
                                placeholder="All Board"
                                type={"board"}
                                getBoard={getBoardModelData}
                                isPage={true}
                              />
                              <Typography variant="caption" color={"error"}>
                                {!modelNoticeData?.board ? error?.board : ""}
                              </Typography>
                            </div>
                            <div className="w-full">
                              <Select
                                value={modelNoticeData?.medium}
                                data={selectionDataMedium || []}
                                onChange={(e) => handelModelData("medium", e)}
                                className="font-regular mb-3"
                                label="Medium"
                                required
                                placeholder="All Medium"
                                type={"medium"}
                                getMedium={getMediumModelData}
                                isPage={true}
                              />
                              <Typography variant="caption" color={"error"}>
                                {!modelNoticeData?.medium ? error?.medium : ""}
                              </Typography>
                            </div>
                          </div>
                          <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6">
                            <div className="w-full">
                              <Select
                                data={selectionDataStandard || []}
                                label="Standard"
                                required
                                placeholder="All Standard"
                                disabled={false}
                                type={"standard"}
                                value={modelNoticeData?.standard || ""}
                                onChange={(e) => handelModelData("standard", e)}
                                getStandard={getStandardModelData}
                                isPage={true}
                              />
                              <Typography variant="caption" color={"error"}>
                                {!modelNoticeData?.standard
                                  ? error?.standard
                                  : ""}
                              </Typography>
                            </div>
                            <div className="w-full">
                              <Select
                                data={selectionDataSemester || []}
                                label="Semester"
                                required
                                placeholder="All semester"
                                disabled={false}
                                type={"semester"}
                                value={modelNoticeData?.semester || ""}
                                onChange={(e) => handelModelData("semester", e)}
                                getSemester={getSemesterModelData}
                                isPage={true}
                              />
                              <Typography variant="caption" color={"error"}>
                                {!modelNoticeData?.semester
                                  ? error?.semester
                                  : ""}
                              </Typography>
                            </div>
                          </div>
                          <div className="w-full mt-3">
                            <Select
                              data={selectionDataSubject || []}
                              label="Subject"
                              required
                              disabled={false}
                              placeholder="All subject"
                              type={"subject"}
                              value={modelNoticeData?.subject || ""}
                              onChange={(e) => handelModelData("subject", e)}
                              getSubject={getSubjectModelData}
                              isPage={true}
                            />
                            <Typography variant="caption" color={"error"}>
                              {!modelNoticeData?.subject ? error?.subject : ""}
                            </Typography>
                          </div>
                          <div className="w-full mt-3">
                            <h1 className="mb-3 text-sm font-medium text-[#252F40] font-poppins">
                              Notice <span className="text-red-400">*</span>
                            </h1>
                          </div>
                          {/* <div className=" flex-row  md:flex items-center">
                          <div className="lg:mr-3 text-center w-24 py-2.5 font-regular text-black bg-[#A0AEC0]/20 rounded-md text-sm mb-3">
                            No.
                          </div>
                          <div className="relative w-full lg:mr-3 xl:max-w-lg ">
                            <div className="block w-full rounded-md bg-[#A0AEC0]/20 px-3 font-regular py-2.5 text-black sm:text-sm mb-3 text-center">
                              Notice Label
                            </div>
                          </div>
                          <div className="lg:mr-3 text-center w-24 py-2.5 font-regular text-black bg-[#A0AEC0]/20 rounded-md text-sm mb-3">
                            Plus
                          </div>
                          <div className="lg:mr-3 text-center w-24 py-2.5 font-regular text-black bg-[#A0AEC0]/20 rounded-md text-sm mb-3">
                            Minus
                          </div>
                        </div> */}
                          <div
                            style={{
                              overflowY: "auto",
                              maxHeight: "58vh",
                              height: "30vh",
                              width: "100%",
                            }}
                          >
                            <table className="w-full">
                              <thead className="sticky top-0 bg-white">
                                <tr className="border-b border-gray-200 text-left text-xs leading-4 font-bold text-[#7B809A] tracking-wider">
                                  {NoticeTitleData.map(
                                    (v, ind) =>
                                      v?.title && (
                                        <th
                                          key={ind}
                                          className=""
                                          // style={{ margin: "20px",}}
                                        >
                                          <div
                                            className="flex justify-between bg-[#A0AEC0]/20 rounded-md"
                                            style={{
                                              // backgroundColor: "#f4f7f8",
                                              padding: "10px",
                                              // borderRadius:"10px",
                                              margin: "0px 5px 0px 5px",
                                              color: "black",
                                            }}
                                          >
                                            <span>{v.title}</span>
                                          </div>
                                        </th>
                                      )
                                  )}
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-0">
                                {AddNoticeData?.map((data, id) => (
                                  <tr
                                    key={id}
                                    className="my-1 px-8 text-[#7B809A] font-reguler text-sm align-top"
                                  >
                                    <td className="hidden lg:table-cell whitespace-nowrap">
                                      <div
                                        className=""
                                        style={{
                                          padding: "10px",
                                          margin: "0px 5px 0px 5px",
                                        }}
                                      >
                                        <div className="py-5 font-regular text-[#A0AEC0] rounded-md text-sm border border-gray-200 bg-white">
                                          <span className="ml-2"> {id + 1}</span>
                                        </div>
                                        {/* <div className="block w-24 rounded-md border border-gray-200 px-3 py-2 text-gray-900 placeholder-[#A0AEC0]focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm">
                                          {id + 1}
                                        </div> */}
                                      
                                      </div>
                                    </td>
                                    <td className="hidden lg:table-cell whitespace-nowrap">
                                      <div
                                        className="w-full"
                                        style={{
                                          padding: "10px",
                                          margin: "0px 5px 0px 5px",
                                        }}
                                      >
                                        <TextArea
                                          id="label"
                                          name="label"
                                          placeholder="Type label here..."
                                          type="text"
                                          autoComplete="given-name"
                                          required
                                          // className="w-full mb-2"
                                          onChange={(e) =>
                                            handleChangeAddBookData(
                                              id,
                                              "label",
                                              e?.target?.value
                                            )
                                          }
                                          value={data?.label || ""}
                                        />
                                        <Typography
                                          variant="caption"
                                          color={"error"}
                                        >
                                          {!data?.label ? error?.label : ""}
                                        </Typography>
                                      </div>
                                    </td>
                                    <td className="hidden lg:table-cell whitespace-nowrap">
                                      <div
                                        className="w-full"
                                        // style={{ marginLeft: "10px" }}
                                        style={{
                                          padding: "10px",
                                          margin: "0px 5px 0px 5px",
                                        }}
                                      >
                                        <Button
                                          id=""
                                          type="submit"
                                          icon={MinusIcon}
                                          onClick={() =>
                                            AddNoticeData?.length > 1
                                              ? handleMinusBookdata(id)
                                              : null
                                          }
                                        />
                                      </div>
                                    </td>
                                    <td className="hidden lg:table-cell whitespace-nowrap">
                                      <div
                                        className="w-full"
                                        style={{
                                          padding: "10px",
                                          margin: "0px 5px 0px 5px",
                                        }}
                                      >
                                        <Button
                                          id=""
                                          type="submit"
                                          icon={PlusIcon}
                                          onClick={() => handleAddBookdata()}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </>
                      )}
                    </Model>
                  </div>
                </div>
              </div>

              <div className="sm:mb-0 overflow-hidden">
                <div className="flex flex-col">
                  <section
                    className="bg-white rounded-2xl flex flex-col shadow-sm "
                    style={{
                      width: "100%",
                      height: "75vh",
                      margin: "0 auto",
                      padding: "20px",
                      marginTop: "20px",
                    }}
                  >
                    {" "}
                    <div className="flex justify-between items-center px-8 mb-2">
                      <div></div>
                      <Records
                        setCurrentPage={setCurrentPage}
                        recordsPerPage={recordsPerPage}
                        setRecordsPerPage={setRecordsPerPage}
                        dataCount={dataCount}
                      />
                    </div>
                    <div
                      style={{
                        overflowY: "auto",
                        maxHeight: "58vh",
                        height: "60vh",
                        width: "100%",
                      }}
                    >
                      {" "}
                      {!isLoader && NoticeData?.length <= 0 ? (
                        <NoDataFound />
                      ) : (
                        <table style={{ width: "100%" }}>
                          <thead
                            style={{
                              position: "sticky",
                              top: 0,
                              backgroundColor: "#f4f7f8",
                              zIndex: 1,
                            }}
                          >
                            <tr
                              style={{
                                borderBottom: "1px solid #dddddd",
                                textAlign: "left",
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "#7B809A",
                                textTransform: "uppercase",
                                backgroundColor: "#f4f7f8",
                              }}
                            >
                              {" "}
                              <th
                                scope="col"
                                style={{ width: "3%", padding: "10px" }}
                              ></th>
                              {titleData.map(
                                (value, ind) =>
                                  value.title && (
                                    <th
                                      key={ind}
                                      // className="py-2 px-6"
                                      style={{
                                        padding: "12px",
                                        backgroundColor: "#f4f7f8",
                                        position:
                                          value.title === "EDIT" ||
                                          value.title === "DELETE"
                                            ? "sticky"
                                            : "",
                                        right:
                                          value.title === "EDIT"
                                            ? "60px"
                                            : value.title === "DELETE"
                                            ? "0"
                                            : "",
                                      }}
                                    >
                                      <div className="flex justify-between">
                                        <span>{value.title}</span>
                                        {value.title !== "EDIT" &&
                                          value.title !== "DELETE" && (
                                            <span>
                                              <ChevronUpDownIcon
                                                onClick={() => {
                                                  let res = SortData(
                                                    NoticeData,
                                                    value.fieldName,
                                                    value.sort
                                                  );

                                                  let copyData = [...titleData];
                                                  copyData[ind].sort =
                                                    value.sort > 0 ? -1 : 1;
                                                  setTitleData(copyData);
                                                  setNoticeData(res);
                                                }}
                                                className="w-5 h-5"
                                              />
                                            </span>
                                          )}
                                      </div>
                                    </th>
                                  )
                              )}
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200">
                            {isLoader ? (
                              <tr style={{ height: "25rem" }}>
                                <td colSpan={5} style={{ textAlign: "center" }}>
                                  <CircularProgress />
                                </td>
                              </tr>
                            ) : (
                              NoticeData.map((data) => (
                                <tr
                                  key={data.key}
                                  className="my-2 px-6 text-[#000] font-bold text-sm"
                                >
                                  {" "}
                                  <td className="px-6 py-3 whitespace-no-wrap">
                                    {/* <Checkbox
                              checked={checkedItems.includes(data.id)}
                              onChange={() => handleCheckboxChange(data.id)}
                            /> */}
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                    {data?.StandardInfo?.name || "-"}
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                    {data?.SemesterInfo?.semester || "-"}
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                                    {data?.SubjectInfo?.subject || "-"}
                                  </td>
                                  {access?.edit && (
                                    <td
                                      className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                                      style={{
                                        position: "sticky",
                                        right: "68px",
                                        backgroundColor: "white",
                                      }}
                                    >
                                      <ActionButton
                                        key={data.key}
                                        action="edit"
                                        onClick={() => {
                                          setIsModel(true);
                                          setSelectedId(data.id);
                                          setModelType("edit");
                                        }}
                                      />
                                    </td>
                                  )}
                                  {access?.delete && (
                                    <td
                                      className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                                      style={{
                                        position: "sticky",
                                        right: "0",
                                        backgroundColor: "white",
                                      }}
                                    >
                                      <ActionButton
                                        key={data.key}
                                        action="delete"
                                        onClick={() => {
                                          // setDeleteType("single");
                                          setOpenModel(true);
                                          setSelectNoticeId(data?.id);
                                        }}
                                      />
                                    </td>
                                  )}
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      recordsPerPage={recordsPerPage}
                      data={NoticeData}
                      numbers={numbers}
                      npage={npage}
                      lastIndex={lastIndex}
                      firstIndex={firstIndex}
                      dataCount={dataCount}
                    />
                  </section>
                </div>

                {/* Delete */}
                <DeletePopup
                  title={"Delete"}
                  show={openModel}
                  description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
                  onCancel={() => {
                    setOpenModel(false);
                    setSelectNoticeId("");
                  }}
                  onClose={() => {
                    setOpenModel(false);
                    setSelectNoticeId("");
                  }}
                  onDelete={() => {
                    _deleteAddSound();
                  }}
                  cancelButtonRef={cancelButtonRef}
                  okText="Delete"
                  cancelText="Cancel"
                />
                {/* Delete */}

                <FloatNotification
                  show={showFloatNotification}
                  title={"Successfully deleted!"}
                  type="success"
                  description={"Notice Deleted."}
                  onClick={() => {
                    setShowFloatNotification(false);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notice;
