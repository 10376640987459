import React, { lazy } from "react";
import Tabs from "./Tabs";
import ApproveQuestion from "./ApproveQuestion";
import ApproveRejectQuestion from "./approve-reject-question";
import QuestionReportData from "./QuestionreportData";


const QuestionAccess = (props) => {
  let categories = [
    {
      name: "Pending",
      component: <ApproveRejectQuestion access={props?.access} />,
      path: "/all-question/pending-question",
    },
    {
      name: "Approve",
      component: <ApproveQuestion access={props?.access} />,
      path: "/all-question/approve-question",
    },
    {
      name: "Question Report",
      component: <QuestionReportData access={props?.access} />,
      path: "/all-question/question-report",
    },
  ];
  return <Tabs categories={categories} title="Question" />;
};

export default QuestionAccess;
