import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { forwardRef } from "react";

export const Input = forwardRef(
  (
    {
      id,
      label,
      error,
      errorText,
      type = "",
      value = "",
      isPassword,
      showPassword,
      togglePasswordVisibility,
      removeFile,
      ...props
    },
    ref
  ) => {
    return (
      <div className={`${props.className ? props.className : ""}`}>
        {label && (
          <label
            htmlFor={id}
            className="mb-3 block text-sm font-medium text-[#252F40] font-poppins"
          >
            {label}
            {props.required && <span className="text-red-500"> *</span>}
          </label>
        )}
        <div className="relative">
          {type === "file" ? (
            <>
              <input
                ref={ref}
                id={id}
                type={type}
                {...props}
                style={{ display: "none" }}

              />
              <label
                for={id}
                className="block w-full appearance-none rounded-md border border-gray-200 px-3 py-2 text-gray-900 placeholder-[#A0AEC0] focus:border-blue-500k focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
              >Choose File</label>
              <div style={{ display: "flex" }}>
                {value && typeof value === "string" ? (
                  <span style={{ flex: 9 }} className=" inset-y-0 right-5 pr-3 flex items-center">
                    {value}
                  </span>
                ) : <span style={{ flex: 9 }}></span>}
                { value && typeof value === "string" && removeFile && <div style={{ flex: 1, display:'flex', justifyContent:'flex-end', padding:'5px' }} className=" inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className={`h-4 w-4 ${removeFile ? "text-red-500" : "text-gray-500"
                      }`}
                    onClick={removeFile}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                </div>}
              </div>
            </>
          ) : (
            <input
              ref={ref}
              id={id}
              type={type}
              value={value}
              placeholder={"Type here..."}
              {...props}
              className="block w-full appearance-none rounded-md border border-gray-200 px-3 py-2 text-gray-900 placeholder-[#A0AEC0] focus:border-blue-500k focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
            />
          )}
          {isPassword && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className={`h-4 w-4 ${showPassword ? "text-blue-500" : "text-gray-500"
                  }`}
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
              </svg>
            </div>
          )}
        </div>
        <div className="text-red-500 text-xs">{error && errorText}</div>
      </div>
    );
  }
);

export function TextArea({ id, label, type = "text", ...props }) {
  return (
    <div className={`${props.className ? props.className : ""}`}>
      {label && (
        <label
          htmlFor={id}
          className="mb-3 block text-sm font-medium text-gray-700"
        >
          {label}
          {props.required && <span className="text-red-400"> *</span>}
        </label>
      )}
      <textarea
        id={id}
        type={type}
        placeholder={"Type here..."}
        {...props}
        className="block w-full appearance-none rounded-md border border-gray-200 px-3 py-4 text-gray-900 placeholder-gray-400 focus:border-blue-500k focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
      />
    </div>
  );
}
