import { combineReducers } from "redux";
import questionReducer from "./reducers/questionReducer";
import standardReducer from "./reducers/standardReducer";
import subjectReducer from "./reducers/subjectReducer";
import chapterReducer from "./reducers/chapterReducer";
import SemesterReducer from "./reducers/semesterReducer";
import topicReducer from "./reducers/topicReducer";

const appReducers = combineReducers({
  question: questionReducer,
  standard: standardReducer,
  subject: subjectReducer,
  chapter: chapterReducer,
  semester: SemesterReducer,
  topic: topicReducer,
});

const rootReducer = (state, action) => {
  return appReducers(state, action);
};

export default rootReducer;
