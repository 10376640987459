import { StandardActions } from "../action-types/standardActionType";

const initialState = {
    standard: [],
};

export default function standardReducer(state = initialState, action) {
  switch (action.type) {
    case StandardActions.GET_STANDARD_DATA:
      return {
        ...state,
      };
    case StandardActions.SET_STANDARD_DATA:
      return {
        ...state,
        standard: action.payload,
      };
    default:
      return state;
  }
}
